import React, { Fragment } from 'react';
import clsx from 'clsx';
import RadioGroup from '@material-ui/core/RadioGroup';

import Radio from '../Radio';
import Checkbox from '../Checkbox';
import {
  isEnterKeyPressedOnCurrentTarget,
  isEnterKeyPressed,
} from '../../helpers/functions/utils/navigation';

import './index.scss';

const ListWrapper = ({
  children,
  multiValue,
  value,
  onChange,
}) => {
  if (multiValue) {
    return (
      <Fragment>{children}</Fragment>
    );
  }

  return (
    <RadioGroup value={value} onChange={onChange}>{children}</RadioGroup>
  );
};

const SelectList = ({
  className = '',
  multiValue = true,
  items = [],
  activeUuid,
  selectedUuids = [],
  itemRenderer,
  onClick,
  onSelect = () => {},
  onUnselect = () => {},
}) => {
  return (
    <ul className={`select-list ${className}`}>
      <ListWrapper
        multiValue={multiValue}
        onChange={(e) => onSelect([e.target.value])}
        value={selectedUuids[0]}
      >
        {
          items.map((item) => {
            return (
              multiValue
                ? (
                  <li key={item.uuid}>
                    <div
                      className={clsx('select-list__item', {
                        'select-list__item_active': item.uuid === activeUuid,
                      })}
                      role="checkbox"
                      aria-checked={item.uuid === activeUuid}
                      {...(onClick
                        ? {
                          tabIndex: 0,
                          onClick: () => onClick(item),
                          onKeyDown: (event) => {
                            if (isEnterKeyPressedOnCurrentTarget(event)) {
                              onClick?.(item);
                            }
                          },
                        }
                        : {})}
                    >
                      <Checkbox
                        value={selectedUuids.includes(item.uuid) ? 2 : 0}
                        onChange={(event) => {
                          if (event.target.checked) {
                            onSelect([item.uuid]);
                          } else {
                            onUnselect([item.uuid]);
                          }
                        }}
                      />
                      {itemRenderer(item)}
                    </div>
                  </li>
                )
                : (
                  <li key={item.uuid}>
                    <div
                      className={clsx('select-list__item', 'select-list__item-radio', {
                        'select-list__item_active': item.uuid === activeUuid,
                      })}
                      role="radio"
                      aria-checked={item.uuid === activeUuid}
                      {...(onClick
                        ? {
                          tabIndex: 0,
                          onClick: () => onClick(item),
                          onKeyDown: (event) => {
                            if (isEnterKeyPressed(event)) {
                              onClick(item);
                            }
                          },
                        }
                        : {})}
                    >
                      <Radio
                        value={item.uuid}
                        label={itemRenderer(item)}
                      />
                    </div>
                  </li>
                )
            );
          })
        }
      </ListWrapper>
    </ul>
  );
};

export default SelectList;

import React, { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EnhancedTextField from '../../../../../components/TextField';
import ComboBox, { Option } from '../../../../../components/ComboBox';
import {
  SORT_ORDER_TO_I18N_KEYS,
  SortOrder,
} from '../../helpers/constants/ui';
import Button from '../../../../../components/Button';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';
import {
  selectListFilterSearchTerm,
  selectListFilterFarmUuid,
  selectListFilterSortOrder,
  selectEquationMapsData,
  selectEquationMapsDataByStatuses,
} from '../../createBatchEquationBasedAnalysisSelectors';
import {
  setListFilterSearchTerm,
  setListFilterFarmUuid,
  setListFilterSortOrder,
  downloadSuccessEquationMaps,
} from '../../createBatchEquationBasedAnalysisSlice';
import {
  selectFarmsEntitiesListByFieldUuids,
} from '../../../../assets/assetsSelectors';
import { selectSelectedFieldsUuids } from '../../../batchAnalytics/batchAnalyticsSelectors';
import useGenerateEquationMaps from '../../hooks/useGenerateEquationMaps';
import { LoadStatus } from '../../../../../helpers/constants/utils/loadStatus';
import { isAssignedVariablesValid } from '../../helpers/functions/variables';
import { selectCurrentEquation } from '../../../../equations/equationsSelectors';

import './index.scss';

export default function PreviewToolbar() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const generateEquationMaps = useGenerateEquationMaps();

  const selectedFieldsUuids = useAppSelector(selectSelectedFieldsUuids);
  const farmsEntitiesList = useAppSelector((state) => {
    return selectFarmsEntitiesListByFieldUuids(state, selectedFieldsUuids);
  });
  const generatedEquationMaps = useAppSelector((state) => {
    return selectEquationMapsDataByStatuses(state);
  });
  const { dataVariables } = useAppSelector(selectCurrentEquation);
  const equationMapsData = useAppSelector(selectEquationMapsData);
  const filterSearchTerm = useAppSelector(selectListFilterSearchTerm);
  const filterFarmUuid = useAppSelector(selectListFilterFarmUuid);
  const filterSortOrder = useAppSelector(selectListFilterSortOrder);

  const sortOptions = useMemo(() => {
    return [
      {
        value: SortOrder.ascSum,
        title: t(SORT_ORDER_TO_I18N_KEYS[SortOrder.ascSum]),
      },
      {
        value: SortOrder.descSum,
        title: t(SORT_ORDER_TO_I18N_KEYS[SortOrder.descSum]),
      },
      {
        value: SortOrder.ascAvg,
        title: t(SORT_ORDER_TO_I18N_KEYS[SortOrder.ascAvg]),
      },
      {
        value: SortOrder.descAvg,
        title: t(SORT_ORDER_TO_I18N_KEYS[SortOrder.descAvg]),
      },
      {
        value: SortOrder.none,
        title: t(SORT_ORDER_TO_I18N_KEYS[SortOrder.none]),
      },
    ];
  }, [t]);

  const sortOrderValue = useMemo(() => {
    return sortOptions.find(({ value }) => value === filterSortOrder);
  }, [sortOptions, filterSortOrder]);

  const farmsOptions = useMemo(() => {
    return farmsEntitiesList.map((farmEntity) => {
      return {
        value: farmEntity.uuid,
        title: farmEntity.name || '',
      };
    });
  }, [farmsEntitiesList]);

  const farmOption = useMemo(() => {
    let result: Option<string> | null = null;
    const farmEntity = farmsEntitiesList.find(({ uuid }) => uuid === filterFarmUuid);

    if (farmEntity) {
      result = {
        value: farmEntity?.uuid,
        title: farmEntity?.name || '',
      };
    }

    return result;
  }, [farmsEntitiesList, filterFarmUuid]);

  const runAllDisabled = useMemo(() => {
    return Object.values(equationMapsData).some((equationMapData) => {
      return !isAssignedVariablesValid({
        assignedVariables: equationMapData.assignedVariables,
        dataVariables,
      });
    });
  }, [dataVariables, equationMapsData]);

  const downloadAllDisabled = useMemo(() => {
    if (!generatedEquationMaps.length) {
      return true;
    }

    return generatedEquationMaps.some((equationMap) => {
      return !equationMap.generationStatus
        || equationMap.generationStatus !== LoadStatus.success;
    });
  }, [generatedEquationMaps]);

  const handleSearchTermChange = (e: ChangeEvent<{ value: string }>) => {
    dispatch(setListFilterSearchTerm(e.target.value));
  };

  const handleFarmChange = (_e: unknown, option: Option<string> | null) => {
    dispatch(setListFilterFarmUuid(option?.value ?? null));
  };

  const handleSortOrderChange = (_e: unknown, option: Option<SortOrder>) => {
    dispatch(setListFilterSortOrder(option.value));
  };

  const handleRunAllClick = () => {
    generateEquationMaps(selectedFieldsUuids);
  };

  const handleDownloadAllClick = () => {
    dispatch(downloadSuccessEquationMaps());
  };

  return (
    <div className="equation-maps-preview-toolbar">
      <div className="equation-maps-preview-toolbar__list-filter">
        <EnhancedTextField
          classes={{
            wrapper: 'equation-maps-preview-toolbar__textfield',
          }}
          type="search"
          placeholder={t('general.shared.search')}
          value={filterSearchTerm}
          onChange={handleSearchTermChange}
        />
        <ComboBox
          classes={{
            root: 'equation-maps-preview-toolbar__combobox',
          }}
          disableCloseOnSelect={false}
          placeholder={t('batch-equation-based.steps.preview.filter-farm')}
          options={farmsOptions}
          value={farmOption}
          getOptionSelected={(option, value) => option.value === value.value}
          onChange={handleFarmChange}
        />
        <ComboBox
          classes={{
            root: 'equation-maps-preview-toolbar__combobox',
          }}
          disableCloseOnSelect={false}
          options={sortOptions}
          value={sortOrderValue}
          disableClearable
          getOptionSelected={(option, value) => option.value === value.value}
          onChange={handleSortOrderChange}
        />
      </div>
      <div className="equation-maps-preview-toolbar__actions">
        <Button
          variant="outlined"
          color="primary"
          disabled={downloadAllDisabled}
          onClick={handleDownloadAllClick}
        >
          {t('batch-equation-based.steps.preview.download-all')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={runAllDisabled}
          onClick={handleRunAllClick}
        >
          {t('batch-equation-based.steps.preview.run-all')}
        </Button>
      </div>
    </div>
  );
}

import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../app/store/helpers/functions';
import { toggleFieldSelection, setFieldsSelection } from '../../fieldProfilerSlice';
import { openPopup } from '../../../popups/popupsSlice';
import { getRowsSelection } from '../../../tableView/helpers/functions/rows';
import {
  selectEditMode,
  selectSelectedFields,
} from '../../fieldProfilerSelectors';
import { selectAreaUnit, selectApiKey } from '../../../../user/userSelectors';
import { useGetFarmsQuery } from '../../../../farms/farmsAPI';
import { selectProfileIsLoading } from '../../../../jdProfile/jdProfileSelectors';
import { DefaultFieldTableRow, FieldTableRow } from '../../../fieldsList/types/tableRow';
import { Label, TransformedField } from '../../../../field/types/field';
import { CollapsibleLabelsNestedTableRow, RowMenuItem } from '../../../tableView/types/row';
import { getTableColumns } from '../../helpers/functions/tableColumns';
import FieldProfilerTableComponent from '../../../fieldsList/components/Table';
import {
  isCollapsibleLabelsNestedTableRow,
  isDefaultFieldTableRow,
  getFieldsTableRows,
} from '../../../fieldsList/helpers/functions/tableRows';
import useInfiniteFieldsList from '../../../../fields/hooks/useInfiniteFieldsList';
import {
  useDeleteFieldsMutation,
  useRenameFieldMutation,
  useSetFieldLabelsMutation,
} from '../../../../fields/fieldsAPI';
import { selectLoading as selectLabelsLoading } from '../../../../labels/labelsSelectors';
import useFieldsListParams from '../../../fieldsList/hooks/useFieldsListParams';
import { FieldOperation } from '../../../fieldWorkflow/helpers/constants/operation';
import { POPUPS } from '../../../popups/helpers/constants/popups';

export default function FieldProfilerTable() {
  const dispatch = useAppDispatch();
  const {
    data: farms,
    isFetching: farmsFetching,
  } = useGetFarmsQuery();
  const [deleteFields] = useDeleteFieldsMutation();
  const [renameField] = useRenameFieldMutation();
  const [setFieldLabels] = useSetFieldLabelsMutation();

  const apiKey = useAppSelector(selectApiKey);
  const areaUnit = useAppSelector(selectAreaUnit);
  const selectedFields = useAppSelector(selectSelectedFields);
  const editMode = useAppSelector(selectEditMode);
  const johnDeereProfileFetching = useAppSelector(selectProfileIsLoading);
  const labelsLoading = useAppSelector(selectLabelsLoading);
  const fieldsListParams = useFieldsListParams();

  const {
    fields,
    fieldsLoading,
    fieldsLoadComplete,
    triggerNextPage,
  } = useInfiniteFieldsList({
    farmUuid: fieldsListParams?.farmUuid,
    fieldName: fieldsListParams?.fieldName,
    labels: fieldsListParams?.labels,
    skip: !fieldsListParams,
  });

  const handleLoadMoreRows = () => {
    triggerNextPage();
  };

  const openManageLabelsPopup = (row: FieldTableRow) => {
    let fieldName = '';
    let fieldUuid = '';

    if (isCollapsibleLabelsNestedTableRow(row)) {
      fieldName = row.fieldName || '';
      fieldUuid = row.fieldUuid;
    } else if (isDefaultFieldTableRow(row)) {
      fieldName = row.name.title || '';
      fieldUuid = row.id;
    }

    dispatch(openPopup({
      type: 'manage-labels',
      fieldLabels: row.labels,
      fieldName,
      onConfirm: (labels: Label[]) => {
        setFieldLabels({
          farmUuid: row.farmUuid || '',
          uuid: fieldUuid,
          labels,
        });
      },
    }));
  };

  const onMenuItemClick = ({ id }: RowMenuItem, row: DefaultFieldTableRow) => {
    if (id === FieldOperation.deleteField) {
      dispatch(openPopup({
        type: POPUPS.deleteField,
        entityName: row.name.title,
        onConfirm: () => {
          deleteFields([{
            farmUuid: row.farmUuid || '',
            uuid: row.id,
          }]);
        },
      }));
    } else if (id === FieldOperation.renameField) {
      dispatch(openPopup({
        type: 'rename-field',
        entityName: row.name.title,
        onConfirm: (name: string) => {
          renameField({
            farmUuid: row.farmUuid || '',
            uuid: row.id,
            name,
          });
        },
      }));
    }
  };

  const onDeleteFieldLabel = (row: CollapsibleLabelsNestedTableRow, labelIndex: number) => {
    const updatedLabels = [...row.labels];

    updatedLabels.splice(labelIndex, 1);
    setFieldLabels({
      farmUuid: row.farmUuid,
      uuid: row.fieldUuid,
      labels: updatedLabels,
    });
  };

  const onHeadCheckboxClick = (selected: boolean) => {
    const uuids = fields.map((field: TransformedField) => field.uuid);

    dispatch(setFieldsSelection({
      uuids,
      selected,
    }));
  };

  const onRowCheckboxClick = (row: DefaultFieldTableRow) => {
    dispatch(toggleFieldSelection(row.id));
  };

  const onRowDeleteClick = (row: DefaultFieldTableRow) => {
    dispatch(openPopup({
      type: POPUPS.deleteField,
      entityName: row.name.title,
      onConfirm: () => {
        deleteFields([{
          farmUuid: row.farmUuid || '',
          uuid: row.id,
        }]);
      },
    }));
  };

  const onStopNameEdit = (row: DefaultFieldTableRow, name: string) => {
    renameField({
      farmUuid: row.farmUuid || '',
      uuid: row.id,
      name,
    });
  };

  const rows = getFieldsTableRows({
    fields,
    farms: farms || [],
    apiKey,
    checkedUuids: selectedFields,
    onDeleteFieldLabel,
    onAddFieldLabel: openManageLabelsPopup,
  });
  const rowsSelection = getRowsSelection(rows);
  const columns = getTableColumns({
    areaUnit,
    editMode,
    rowsSelection,
    onHeadCheckboxClick,
    onMenuItemClick,
    openManageLabelsPopup,
    onRowCheckboxClick,
    onRowDeleteClick,
    onStopNameEdit,
  });

  return (
    <FieldProfilerTableComponent
      rows={rows}
      columns={columns}
      editMode={editMode}
      loadComplete={fieldsLoadComplete}
      loading={fieldsLoading || farmsFetching || johnDeereProfileFetching || labelsLoading}
      labelsExpanded={fieldsListParams?.labelsExpanded}
      onLoadMoreRows={handleLoadMoreRows}
    />
  );
}

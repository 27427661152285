import React from 'react';
import { useTranslation } from 'react-i18next';

import AuthenticationRedirector from '../router/redirectors/Authentication';
import UserDataRedirector from '../router/redirectors/UserData';
import PricingRedirector from '../router/redirectors/Pricing';
import BaseTemplate from './BaseTemplate.jsx';
import useDidMount from '../hooks/useDidMount';
import { getDocumentTitle } from '../helpers';
import HomepagePanel from '../features/ui/homepage/containers/HomepagePanel';

const Homepage = () => {
  const { t } = useTranslation();

  useDidMount(() => {
    document.title = getDocumentTitle(t('general.navigation.homepage'));
  });

  return (
    <AuthenticationRedirector>
      <UserDataRedirector>
        <PricingRedirector>
          <BaseTemplate
            headerProps={{
              text: t('general.navigation.homepage'),
            }}
          >
            <HomepagePanel key="panel" />
          </BaseTemplate>
        </PricingRedirector>
      </UserDataRedirector>
    </AuthenticationRedirector>
  );
};

export default Homepage;

import i18n from 'i18next';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  errorNotify,
  successNotify,
} from '../../notifications/helpers/functions/notify';
import { CustomError } from '../../../helpers/functions/utils/errorHandling';
import {
  updateOrganizationUser,
  addUsersToOrganization as addUsersToOrganizationUserSlice,
  deleteUsersFromOrganization as deleteUsersFromOrganizationUserSlice,
} from '../../user/userSlice';
import {
  shareFarms,
  addUsersToOrganization as addUsersToOrganizationAPI,
  deleteUsersFromOrganization as deleteUsersFromOrganizationAPI,
} from './userOrganizationsAPI';

export const shareFarmToUser = createAsyncThunk(
  'userOrganizations/shareFarmToUser',
  async ({
    allFarms,
    userUuid,
    organizationUuid,
  }, { dispatch }) => {
    try {
      await shareFarms({
        userUuid,
        organizationUuid,
        isAllFarms: allFarms,
      });
      dispatch(updateOrganizationUser({
        userUuid,
        organizationUuid,
        allFarms,
      }));
    } catch (error) {
      errorNotify({
        error: new CustomError('[UI User Organizations] Unable to share farm to user.', {
          cause: error,
        }),
        message: i18n.t('user-organizations.notifications.role-update-error'),
        dispatch,
      });
    }
  },
);

export const addUsersToOrganization = createAsyncThunk(
  'userOrganizations/addUsersToOrganization',
  async ({
    emails,
    organizationUuid,
  }, { dispatch }) => {
    try {
      const addedUsers = await addUsersToOrganizationAPI({
        emails,
        organizationUuid,
      });
      dispatch(addUsersToOrganizationUserSlice({
        organizationUuid,
        users: addedUsers,
      }));
      successNotify({
        message: i18n.t('user-organizations.notifications.users-added'),
      });
    } catch (error) {
      errorNotify({
        error: new CustomError('[UI User Organizations] Unable to add user to organization.', {
          cause: error,
        }),
        dispatch,
      });
    }
  },
);

export const deleteUsersFromOrganization = createAsyncThunk(
  'userOrganizations/deletUsersFromOrganization',
  async ({
    users,
    organizationUuid,
  }, { dispatch }) => {
    try {
      const usersToDelete = await deleteUsersFromOrganizationAPI({
        userUuids: users.map(({ userUuid }) => userUuid),
        organizationUuid,
      });

      dispatch(deleteUsersFromOrganizationUserSlice({
        organizationUuid,
        usersUuids: usersToDelete.map(({ userUuid }) => userUuid),
      }));
    } catch (error) {
      errorNotify({
        error: new CustomError('[UI User Organizations] Unable to delete user from organization.', {
          cause: error,
        }),
        dispatch,
      });
    }
  },
);

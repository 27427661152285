import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import i18n from 'i18next';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

import Link from '../../../../../components/Link';
import Button from '../../../../../components/Button';
import TextField from '../../../../../components/TextField';
import Tabs from '../../../../../components/Tabs';
import ToolsPanel from '../../../../../components/ToolsPanel';
import { PAGES_ROOTS, getRootLink } from '../../../../../helpers/navigation';
import { TABS } from '../../helpers/constants/ui';
import { openPopup } from '../../../popups/popupsSlice';
import { useGetFarmsQuery } from '../../../../farms/farmsAPI';
import { selectUserAdminOrAdvisor } from '../../../../user/userSelectors';
import {
  changeActiveTab,
  toggleSelectionMode,
  changeOwnFarmsQuery,
  changeSharedFarmsQuery,
} from '../../userFarmsSlice';
import {
  selectActiveTab,
  selectOwnFarmsQuery,
  selectSelectionMode,
  selectSharedFarmsQuery,
} from '../../userFarmsSelectors';

import './index.scss';

const isOwnFarmsActive = (activeTab) => {
  return activeTab === TABS.ownFarms;
};

const getTabs = () => {
  return [
    {
      label: {
        primary: i18n.t('user-farms.own-farms.title'),
      },
      value: TABS.ownFarms,
    },
    {
      label: {
        primary: i18n.t('user-farms.shared-farms.title'),
      },
      value: TABS.sharedFarms,
    },
  ];
};

const UserFarmsToolsPanel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    isFetching: isFarmsFetching,
  } = useGetFarmsQuery();
  const isAdminOrAdvisor = useSelector(selectUserAdminOrAdvisor);
  const selectionMode = useSelector(selectSelectionMode);
  const activeTab = useSelector(selectActiveTab);
  const ownFarmsQuery = useSelector(selectOwnFarmsQuery);
  const sharedFarmsQuery = useSelector(selectSharedFarmsQuery);
  const ownFarmsActive = isOwnFarmsActive(activeTab);
  const filterQuery = ownFarmsActive ? ownFarmsQuery : sharedFarmsQuery;

  const onCreateFarmClick = () => {
    dispatch(openPopup({
      type: 'create-farm',
    }));
  };

  const onShareFarmsClick = () => {
    dispatch(toggleSelectionMode());
  };

  const onTabChange = (_e, value) => {
    dispatch(changeActiveTab(value));
  };

  const onFilterChange = (value) => {
    if (ownFarmsActive) {
      dispatch(changeOwnFarmsQuery(value));
    } else {
      dispatch(changeSharedFarmsQuery(value));
    }
  };

  const getDescription = () => {
    let description = <>{t('user-farms.tools-panel.user-description')}</>;

    if (isAdminOrAdvisor) {
      description = (
        <Trans i18nKey="user-farms.tools-panel.admin-advisor-description">
          Text <Link to={getRootLink(PAGES_ROOTS.userOrganizations)}>link</Link>.
        </Trans>
      );
    }

    return description;
  };

  const getActionsLeftContent = () => {
    return (
      <Fragment>
        <div className="tools-panel__actions_top">
          <Button
            startIcon={<AddIcon />}
            onClick={onCreateFarmClick}
          >
            {t('field-profiler.tools-panel.create-farm')}
          </Button>
        </div>
        {
          !isFarmsFetching
            && (
              <Typography variant="body2">
                {getDescription()}
              </Typography>
            )
        }
      </Fragment>
    );
  };

  const getFiltersLeftContent = () => {
    return (
      <Fragment>
        <div className="tools-panel__filters_top">
          <Tabs
            value={activeTab}
            tabs={getTabs(t)}
            onTabChange={onTabChange}
          />
        </div>
        <TextField
          type="search"
          placeholder={t('user-farms.own-farms.name-filter')}
          value={filterQuery}
          classes={{
            wrapper: 'user-farms-tools-panel__filter',
          }}
          onChange={(e) => onFilterChange(e.target.value)}
          onEndAdornmentClick={() => onFilterChange('')}
        />
      </Fragment>
    );
  };

  const getFiltersRightContent = () => {
    const displayShareButton = !selectionMode && activeTab === TABS.ownFarms;

    return (
      displayShareButton
        && (
          <Button
            variant="contained"
            color="primary"
            startIcon={(
              <AddIcon
                classes={{
                  root: 'user-farms-tools-panel__button-icon',
                }}
              />
            )}
            onClick={onShareFarmsClick}
          >
            {t('user-farms.own-farms.share-farms')}
          </Button>
        )
    );
  };

  return (
    <ToolsPanel
      classes={{
        root: 'user-farms-tools-panel',
      }}
      actionsLeft={getActionsLeftContent()}
      filtersLeft={getFiltersLeftContent()}
      filtersRight={getFiltersRightContent()}
    />
  );
};

export default UserFarmsToolsPanel;

export const getDebouncer = (timeout = 0) => {
  let id;

  return (fn) => {
    if (id) {
      clearTimeout(id);
    }

    id = setTimeout(() => {
      fn();
      id = null;
    }, timeout);
  };
};

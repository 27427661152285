import i18n from 'i18next';

import {
  errorNotify,
  successNotify,
} from '../../notifications/helpers/functions/notify';
import type {
  CleanCalibrateYieldDatasetArg,
  CleanCalibrateYieldDatasetResponse,
  CleanCalibrateYieldDatasetResult,
} from './types/api';
import cleanCalibrateYieldDatasetMutation from './graphql/mutations/cleanCalibrateYieldDataset.gql';
import { emptyAPI } from '../../emptyApi/emptyAPI';
import { setIsProcessing } from './cleanCalibrateSlice';
import { CustomError } from '../../../helpers/functions/utils/errorHandling';

const cleanCalibrateAPI = emptyAPI.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    cleanCalibrateYieldDataset: builder.mutation<CleanCalibrateYieldDatasetResult, CleanCalibrateYieldDatasetArg>({
      queryFn: async (
        arg,
        { dispatch },
        _extraOptions,
        baseQuery,
      ) => {
        dispatch(setIsProcessing(true));

        const cleanCalibrateYieldDatasetResult = await baseQuery({
          document: cleanCalibrateYieldDatasetMutation,
          variables: {
            input: arg,
          },
        });

        if (cleanCalibrateYieldDatasetResult.error) {
          dispatch(setIsProcessing(false));
          errorNotify({
            error: new CustomError('[Clean Calibrate] Unable to clean and/or calibrate yield dataset.', {
              cause: cleanCalibrateYieldDatasetResult.error,
            }),
            dispatch,
          });

          return {
            error: cleanCalibrateYieldDatasetResult.error,
          };
        }

        dispatch(setIsProcessing(false));
        successNotify({
          message: i18n.t('clean-calibrate.notifications.in-progress'),
        });

        const { calibrateYieldDataset } = cleanCalibrateYieldDatasetResult.data as CleanCalibrateYieldDatasetResponse;

        return {
          data: calibrateYieldDataset.uuid,
        };
      },
    }),
  }),
});

export const {
  useCleanCalibrateYieldDatasetMutation,
} = cleanCalibrateAPI;

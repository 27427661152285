import i18n from 'i18next';

import Equation from '../../containers/Stepper/Steps/Equation';
import Preview from '../../containers/Stepper/Steps/Preview';
import { getCommonSteps } from '../../../batchAnalytics/helpers/functions/steps';
import { Steps } from '../constants/steps';
import { Step } from '../../../../../components/Stepper';

export const getSteps = (): Step[] => {
  return [
    ...getCommonSteps(),
    {
      name: i18n.t('zones-ops.equation-based.stepper.equation'),
      description: i18n.t('zones-ops.equation-based.steps.3.description'),
      id: Steps.equation,
      component: Equation,
    },
    {
      name: i18n.t('batch-equation-based.steps.preview.name'),
      description: i18n.t('batch-equation-based.steps.preview.description'),
      id: Steps.preview,
      component: Preview,
    },
  ];
};

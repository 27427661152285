import type { Field, TransformedField } from '../../types/field';

export const isInUnsupportedRegion = <T extends Field | TransformedField = Field>(field?: T | null): boolean => {
  return field?.status === 'TILES_IGNORED';
};

export const isInvalid = <T extends Field | TransformedField = Field>(field?: T): boolean => {
  return field?.status === 'INVALID';
};

export const isSameField = <T extends Field | TransformedField = Field>(
  oldField?: T | null,
  newField?: T | null,
): boolean => {
  return newField?.uuid === oldField?.uuid;
};

export const getJDFieldOrgId = (jdField: { orgId: string } | null | false) => {
  return jdField && typeof jdField === 'object' ? jdField.orgId : '';
};

export const getFieldThumbnailGeoMap = (geoMaps: { url: string, shortName: string }[] = []) => {
  return geoMaps.find(({ shortName }) => shortName === 'thumbnail');
};

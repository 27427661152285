import { API, graphqlOperation } from '@aws-amplify/api';

import saveUserDataMutation from './graphql/mutations/saveUserData.gql';
import saveUserDetailsMutation from './graphql/mutations/saveUserDetails.gql';
import getCombinedUserQuery from './graphql/queries/getCombinedUser.gql';
import getUserTotalAreaQuery from './graphql/queries/getUserTotalArea.gql';
import getUserOrdersQuery from './graphql/queries/getUserOrders.gql';
import saveOrganizationMutation from './graphql/mutations/saveOrganization.gql';
import saveStripeCustomerMutation from './graphql/mutations/saveStripeCustomer.gql';

export const saveUserData = async ({
  identity,
  language,
  areaUnit,
  country,
  companyType,
  phoneNumber,
  acceptedTermsAndConditions,
}) => {
  const response = await API.graphql(graphqlOperation(saveUserDataMutation, {
    input: {
      ...(identity ? { identity } : null),
      ...(language ? { language } : null),
      ...(areaUnit ? { areaUnit } : null),
      ...(country ? { country } : null),
      ...(companyType ? { companyType } : null),
      ...(phoneNumber ? { phoneNumber } : null),
      ...(acceptedTermsAndConditions ? { acceptedTermsAndConditions } : null),
    },
  }));

  return response.data.saveUserData;
};

export const saveStripeCustomer = async ({ phoneNumber }) => {
  const response = await API.graphql(graphqlOperation(saveStripeCustomerMutation, {
    input: { phoneNumber },
  }));

  return response.data.saveStripeCustomer;
};

export const saveDetails = async (details) => {
  return API.graphql(graphqlOperation(saveUserDetailsMutation, {
    input: details,
  }))
    .then(({ data }) => {
      return data.saveUserDetails;
    });
};

export const getCombinedUser = async () => {
  const response = await API.graphql(graphqlOperation(getCombinedUserQuery));

  return response.data;
};

export const getOrders = async () => {
  return API.graphql(graphqlOperation(getUserOrdersQuery))
    .then(({ data }) => {
      return data.getUserData.orders;
    });
};

export const saveOrganization = async (anOrganization) => {
  return API.graphql(graphqlOperation(saveOrganizationMutation, {
    input: anOrganization,
  }))
    .then(({ data }) => {
      return data.saveOrganization;
    });
};

export const getUserTotalArea = async () => {
  return API.graphql(graphqlOperation(getUserTotalAreaQuery))
    .then(({ data }) => {
      return data.getUserData;
    });
};

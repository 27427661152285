import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';

import CodeEditor from '../../../../../../components/CodeEditor';
import CodeEditorErrorsPanel from '../../../../../../components/CodeEditorErrorsPanel';
import VariablesList from '../../../components/VariablesList';
import TextField from '../../../../../../components/TextField';
import Button from '../../../../../../components/Button';
import Select from '../../../../../../components/Select';
import {
  setActiveVariable,
  resetActiveVariable,
  deleteDataVariable,
  initDataVariables,
  markAsChanged,
} from '../../../createEquationBasedAnalysisSlice';
import { openPopup } from '../../../../popups/popupsSlice';
import { POPUPS } from '../../../../popups/helpers/constants/popups';
import {
  selectDataVariables,
} from '../../../createEquationBasedAnalysisSelectors';
import {
  selectCurrentEquation,
  selectIsEquationTemplateSelected,
  selectVerifyEquation,
} from '../../../../../equations/equationsSelectors';
import { getDebouncer } from '../../../../../../helpers/functions/utils/debouncer';
import { getProductUnitOptions } from '../../../../../../helpers/functions/units/productUnit';
import { variableNameFormatter } from '../../../../../../helpers/functions/entities/equation';
import {
  selectAsAppliedDatasets,
  selectEquationMaps,
  selectSatelliteImages,
  selectSoilDatasets,
  selectTopographyMaps,
  selectVectorAnalysisMaps,
  selectYieldDatasets,
} from '../../../../../field/fieldSelectors';
import { getDataVariableItems } from '../../../helpers/functions/variables';
import useDidMount from '../../../../../../hooks/useDidMount';
import {
  updateCurrentEquation,
  updateSavedEquation,
  verifyEquation,
} from '../../../../../equations/equationsSlice';

import './index.scss';

const TIMEOUT = 500; // ms
const verificationDebouncer = getDebouncer(TIMEOUT);

const getDescription = (t, equationSelected) => {
  return equationSelected
    ? (
      <>
        <Typography
          className="equation-based-create-formula-panel__description-secondary-text"
          variant="body2"
        >
          {t('zones-ops.equation-based.steps.4.panel.description.adjustment.1')}
        </Typography>
        <Typography
          className="equation-based-create-formula-panel__description-secondary-text"
          variant="body2"
        >
          {t('zones-ops.equation-based.steps.4.panel.description.adjustment.2')}
        </Typography>
      </>
    )
    : (
      <>
        <Typography
          className="equation-based-create-formula-panel__description-title"
          variant="body2"
        >
          {t('zones-ops.equation-based.steps.4.panel.description.create-formula.title')}
        </Typography>
        <ol className="equation-based-create-formula-panel__description-list">
          <li>{t('zones-ops.equation-based.steps.4.panel.description.create-formula.1')}</li>
          <li>{t('zones-ops.equation-based.steps.4.panel.description.create-formula.2')}</li>
          <li>{t('zones-ops.equation-based.steps.4.panel.description.create-formula.3')}</li>
        </ol>
      </>
    );
};

const Body = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    equationAsText: equationFormula,
    title: equationTitle,
    sourceUrl: equationSourceUrl,
    equationResultVariable: resultVariable,
    productUnit,
    dataVariables: equationDataVariables,
    useNumpy,
  } = useSelector(selectCurrentEquation);
  const equationSelected = useSelector(selectIsEquationTemplateSelected);
  const dataVariables = useSelector(selectDataVariables);
  const soilDatasets = useSelector(selectSoilDatasets);
  const yieldDatasets = useSelector(selectYieldDatasets);
  const asAppliedDatasets = useSelector(selectAsAppliedDatasets);
  const topographyMaps = useSelector(selectTopographyMaps);
  const satelliteImages = useSelector(selectSatelliteImages);
  const vectorAnalysisMaps = useSelector(selectVectorAnalysisMaps);
  const equationMaps = useSelector(selectEquationMaps);
  const {
    errorMessage: verificationErrorMessage,
    inProgress: verificationInProgress,
  } = useSelector(selectVerifyEquation);

  useDidMount(() => {
    dispatch(initDataVariables());
  });

  const [productUnits] = useState(getProductUnitOptions(true));
  const testRunButtonDisabled = !equationFormula
    || !resultVariable
    || verificationInProgress
    || !!verificationErrorMessage
    || dataVariables.length === 0;
  const saveNewPresetButtonDisabled = !equationFormula
  || !resultVariable
  || verificationInProgress
  || !!verificationErrorMessage;
  const [fullScreenMode, setFullScreenMode] = useState(false);

  const dataVariableItems = useMemo(() => getDataVariableItems({
    dataVariables,
    soilDatasets,
    yieldDatasets,
    asAppliedDatasets,
    topographyMaps,
    satelliteImages,
    vectorAnalysisMaps,
    equationMaps,
  }), [
    dataVariables,
    soilDatasets,
    yieldDatasets,
    asAppliedDatasets,
    topographyMaps,
    satelliteImages,
    vectorAnalysisMaps,
    equationMaps,
  ]);

  const handleEquationChange = (newValue) => {
    dispatch(updateCurrentEquation({
      equationAsText: newValue,
    }));
    dispatch(markAsChanged());

    verificationDebouncer(() => {
      dispatch(verifyEquation({
        equationAsText: newValue,
        equationResultVariable: resultVariable,
        dataVariables: equationDataVariables,
        useNumpy,
      }));
    });
  };

  const handleProductUnitChange = (value) => {
    dispatch(updateCurrentEquation({
      productUnit: value,
    }));
  };

  const handleUseNumpyChange = (value) => {
    dispatch(updateCurrentEquation({
      useNumpy: value,
    }));
    dispatch(markAsChanged());
    verificationDebouncer(() => {
      dispatch(verifyEquation({
        equationAsText: equationFormula,
        equationResultVariable: resultVariable,
        dataVariables: equationDataVariables,
        useNumpy: value,
      }));
    });
  };

  const handleResultVariableChange = (e) => {
    const formattedValue = variableNameFormatter(e.target.value);

    dispatch(updateCurrentEquation({
      equationResultVariable: formattedValue,
    }));
    dispatch(markAsChanged());
    verificationDebouncer(() => {
      dispatch(verifyEquation({
        equationAsText: equationFormula,
        equationResultVariable: formattedValue,
        dataVariables: equationDataVariables,
        useNumpy,
      }));
    });
  };

  const handleTestRunButtonClick = () => {
    dispatch(openPopup({
      type: POPUPS.equationTestRun,
      equationAsText: equationFormula,
      equationResultVariable: resultVariable,
      dataVariables: equationDataVariables,
      useNumpy,
    }));
  };

  const handleSaveNewPresetButtonClick = () => {
    dispatch(openPopup({
      type: POPUPS.createEquation,
      equation: {
        dataVariables: equationDataVariables,
        equationResultVariable: resultVariable,
        equationAsText: equationFormula,
        productUnit,
        useNumpy,
      },
      onConfirm: (update) => {
        dispatch(updateSavedEquation({
          ...update,
        }));
      },
    }));
  };

  const handleFullScreenClick = () => {
    setFullScreenMode(!fullScreenMode);
  };

  const manageVariable = (variable = '') => {
    dispatch(setActiveVariable(variable));
    dispatch(openPopup({
      type: POPUPS.selectDataVariable,
      onCancel: () => {
        dispatch(resetActiveVariable());
      },
    }));
  };

  const deleteVariable = (variable) => {
    dispatch(deleteDataVariable(variable));
  };

  return (
    <div className="equation-based-create-formula-panel">
      {
        equationSelected
        && (
          <div
            className={clsx(
              'equation-based-create-formula-panel__header',
              {
                'equation-based-create-formula-panel__header_hidden': fullScreenMode,
              },
            )}
          >
            <Typography variant="h6">
              {equationTitle}
            </Typography>
            {
              equationSourceUrl
              && (
                <a
                  className="link equation-based-create-formula-panel__header-link"
                  href={equationSourceUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('zones-ops.equation-based.steps.4.panel.source-url')}
                </a>
              )
            }
          </div>
        )
      }
      <div
        className={clsx({
          'equation-based-create-formula-panel__description_hidden': fullScreenMode,
        })}
      >
        {getDescription(t, equationSelected)}
      </div>
      <div
        className={clsx({
          'equation-based-create-formula-panel__fullscreen-content': fullScreenMode,
        })}
      >
        <div className="equation-based-create-formula-panel__actions">
          <Select
            classes={{
              root: 'equation-based-create-formula-panel__action_select',
            }}
            displayEmpty
            label={t('zones-ops.equation-based.steps.4.panel.product-unit')}
            options={productUnits}
            value={productUnit}
            onChange={handleProductUnitChange}
          />
          <div>
            <Button
              classes={{
                root: 'equation-based-create-formula-panel__action',
              }}
              variant="contained"
              color="primary"
              disabled={saveNewPresetButtonDisabled}
              onClick={handleSaveNewPresetButtonClick}
            >
              {t('general.popups.create-equation.title')}
            </Button>
          </div>
        </div>
        <div className="equation-based-create-formula-panel__main">
          <div className="equation-based-create-formula-panel__main-left">
            <CodeEditor
              title={t('zones-ops.equation-based.steps.4.panel.textarea-title')}
              placeholder={t('zones-ops.equation-based.steps.4.panel.textarea-placeholder')}
              value={equationFormula}
              fullScreen={{
                mode: fullScreenMode,
                onClick: handleFullScreenClick,
              }}
              numpy={{
                mode: useNumpy,
                onClick: handleUseNumpyChange,
              }}
              testRunDisabled={testRunButtonDisabled}
              onChange={handleEquationChange}
              onTestRunClick={handleTestRunButtonClick}
            />
            <CodeEditorErrorsPanel
              errorMessage={verificationErrorMessage}
              loading={verificationInProgress}
            />
          </div>
          <div className="equation-based-create-formula-panel__main-right">
            <VariablesList
              title={t('zones-ops.equation-based.steps.4.panel.variables-list.title')}
              items={dataVariableItems}
              deleteVariable={deleteVariable}
              manageVariable={manageVariable}
            />
            <TextField
              title={t('zones-ops.equation-based.steps.4.panel.output-variable-title')}
              placeholder={t('zones-ops.equation-based.steps.4.panel.output-variable-placeholder')}
              value={resultVariable}
              onChange={handleResultVariableChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;

import type { AttributeStatistics } from '../../../../../helpers/types/dataset';
import type {
  MinMaxCalibrateCondition,
  MinMaxCleanCondition,
  NullableAvgTotalCalibrateCondition,
  NullableMinMaxCalibrateCondition,
  NullableMinMaxCleanCondition,
} from '../../types/actions';

export const isCleanParamsValid = (
  params: {
    targetAttribute: string | null,
    conditionMinMaxClean: NullableMinMaxCleanCondition[],
  },
  statistics: AttributeStatistics[] = [],
): params is {
  targetAttribute: string,
  conditionMinMaxClean: MinMaxCleanCondition[],
} => {
  return !!params.targetAttribute
    && isCleanMinMaxConditionsValid(params.conditionMinMaxClean, statistics);
};

const isCleanMinMaxConditionsValid = (
  conditions: NullableMinMaxCleanCondition[],
  statistics: AttributeStatistics[] = [],
): conditions is MinMaxCleanCondition[] => {
  return conditions.every((condition) => {
    const attributeStatistics = statistics.find((statistic) => {
      return statistic.attribute === condition.cleanAttribute;
    });
    let result = !!condition.cleanAttribute
      && (condition.min != null || condition.max != null);

    if (condition.min != null) {
      if (attributeStatistics?.min == null) {
        result = false;
      } else {
        const maxLimit = condition?.max || attributeStatistics.max;
        result = result && attributeStatistics.min <= condition.min && condition.min <= maxLimit;
      }
    }

    if (condition.max != null) {
      if (attributeStatistics?.max == null) {
        result = false;
      } else {
        const minLimit = condition?.min || attributeStatistics.min;
        result = result && attributeStatistics.max >= condition.max && condition.max >= minLimit;
      }
    }

    return result;
  });
};

export const isCalibratePathwiseParamsValid = (params: {
  calibrationAttributes: string[] | null,
  conditionPathwiseCalibration: {
    calibrationBasis: string | null,
  },
  conditionAvgTotalCalibration: NullableAvgTotalCalibrateCondition[],
}): params is {
  calibrationAttributes: string[],
  conditionPathwiseCalibration: {
    calibrationBasis: string,
  },
  conditionAvgTotalCalibration: {
    [K in keyof NullableAvgTotalCalibrateCondition]: Exclude<NullableAvgTotalCalibrateCondition[K], null>;
  }[],
} => {
  return params.calibrationAttributes?.length !== 0
    && !!params.conditionPathwiseCalibration.calibrationBasis
    && isCalibrateAvgTotalConditionsValid(params.conditionAvgTotalCalibration);
};

export const isCalibrateAvgTotalParamsValid = (params: {
  conditionAvgTotalCalibration: NullableAvgTotalCalibrateCondition[],
}): params is {
  conditionAvgTotalCalibration: {
    [K in keyof NullableAvgTotalCalibrateCondition]: Exclude<NullableAvgTotalCalibrateCondition[K], null>;
  }[],
} => {
  return params.conditionAvgTotalCalibration.length !== 0
    && isCalibrateAvgTotalConditionsValid(params.conditionAvgTotalCalibration);
};

const isCalibrateAvgTotalConditionsValid = (conditions: NullableAvgTotalCalibrateCondition[]) => {
  return conditions.every((condition) => {
    return condition.calibrationAttribute
      && (condition.average != null || condition.total != null);
  });
};

export const isCalibrateMinMaxParamsValid = (
  params: {
    conditionMinMaxCalibration: NullableMinMaxCalibrateCondition[],
  },
  statistics: AttributeStatistics[],
): params is {
  conditionMinMaxCalibration: MinMaxCalibrateCondition[],
} => {
  return params.conditionMinMaxCalibration.length !== 0
    && params.conditionMinMaxCalibration.every((condition) => {
      const attributeStatistics = statistics.find((statistic) => {
        return statistic.attribute === condition.calibrationAttribute;
      });
      let result = !!condition.calibrationAttribute
        && (condition.min != null || condition.max != null);

      if (condition.min != null) {
        if (attributeStatistics?.min == null) {
          result = false;
        } else {
          const maxLimit = condition?.max || attributeStatistics.max;
          result = result && attributeStatistics.min <= condition.min && condition.min <= maxLimit;
        }
      }

      if (condition.max != null) {
        if (attributeStatistics?.max == null) {
          result = false;
        } else {
          const minLimit = condition?.min || attributeStatistics.min;
          result = result && attributeStatistics.max >= condition.max && condition.max >= minLimit;
        }
      }

      return result;
    });
};

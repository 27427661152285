import React from 'react';
import { connect } from 'react-redux';

import Checkbox from '../../../../../../components/Checkbox';
import ScrollContainer from '../../../../../../components/ScrollContainer';
import ZonesMapTable from '../../../../../../components/Views/common/ZonesMapTable';
import VectorAnalysisMap from '../../../../../../components/Views/VectorAnalysisMap';
import ZonesMapItem from '../../../../dataLayersView/components/Items/ZonesMapItem';
import { filterVamaps } from '../../../../filters/helpers/functions/analysis';
import {
  setLegend,
  toggleVamap,
  toggleVamapZone,
  unselectVamap,
} from '../../../crossLayerSlice';
import { fetchVamapAttributesJson } from '../../../../../field/fieldSlice';
import {
  selectSelectedVamaps,
  selectSelectedVectorAnalysisMaps,
  selectVamapsFilter,
} from '../../../crossLayerSelectors';
import { selectVectorAnalysisMaps } from '../../../../../field/fieldSelectors';
import { selectApiKey, selectAreaUnit } from '../../../../../user/userSelectors';
import { isEnterKeyPressedOnCurrentTarget } from '../../../../../../helpers/functions/utils/navigation';

import './index.scss';

const SelectableZonesMapTable = (props) => (
  <ZonesMapTable
    includeSelects
    {...props}
  />
);

const CrossLayerPanel = ({
  filteredVamaps,
  selectedVamaps,
  apiKey,
  areaUnit,
  onVamapToggleSelect,
  onFetchAttributes,
  onVamapZoneToggle,
  onGetLegend,
  onVamapClose,
}) => {
  return (
    <div className="cross-layer-zones-panel">
      <ScrollContainer
        Comp="ul"
        classes={{ root: 'cross-layer-zones-panel__filtered-maps' }}
      >
        {filteredVamaps.map((vamap) => {
          return (
            <li
              key={vamap.uuid}
              className="list-item"
            >
              <div
                className="list-item__content"
                role="button"
                tabIndex={0}
                onClick={() => onVamapToggleSelect(vamap.uuid)}
                onKeyDown={(event) => {
                  if (isEnterKeyPressedOnCurrentTarget(event)) {
                    onVamapToggleSelect(vamap.uuid);
                  }
                }}
              >
                <Checkbox value={vamap.selected ? 2 : 0} />
                <ZonesMapItem zonesMap={vamap} />
              </div>
            </li>
          );
        })}
      </ScrollContainer>
      <ScrollContainer classes={{ root: 'cross-layer-zones-panel__selected-maps' }}>
        {selectedVamaps.map((vamap) => {
          return (
            <VectorAnalysisMap
              key={vamap.uuid}
              TableComponent={SelectableZonesMapTable}
              areaUnit={areaUnit}
              apiKey={apiKey}
              vamap={vamap}
              onZoneClick={onVamapZoneToggle}
              onFetchAttributes={onFetchAttributes}
              onGetDetails={onGetLegend}
              onClose={onVamapClose}
            />
          );
        })}
      </ScrollContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  const selectedVamaps = selectSelectedVamaps(state);
  const vectorAnalysisMaps = selectVectorAnalysisMaps(state);
  const vamapsFilter = selectVamapsFilter(state);

  return {
    filteredVamaps: filterVamaps(vamapsFilter, vectorAnalysisMaps)
      .map((vamap) => {
        return {
          ...vamap,
          selected: !!selectedVamaps[vamap.uuid],
        };
      }),
    selectedVamaps: selectSelectedVectorAnalysisMaps(state)
      .map((vamap) => {
        return {
          ...vamap,
          selectedZones: selectedVamaps[vamap.uuid],
        };
      }),
    areaUnit: selectAreaUnit(state),
    apiKey: selectApiKey(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onVamapToggleSelect: (uuid) => {
    dispatch(toggleVamap(uuid));
  },
  onFetchAttributes: (uuid) => {
    dispatch(fetchVamapAttributesJson({ uuid }));
  },
  onVamapZoneToggle: (uuid, zone) => {
    dispatch(toggleVamapZone({
      uuid,
      zone,
    }));
  },
  onGetLegend: (vamap) => {
    dispatch(setLegend(vamap));
  },
  onVamapClose: (uuid) => {
    dispatch(unselectVamap(uuid));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CrossLayerPanel);

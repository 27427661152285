import React from 'react';
import i18n from 'i18next';

import { getCommonSteps } from '../../../zonesOps/helpers/functions/steps';
import Zones from '../../containers/Stepper/Steps/Zones';
import Settings from '../../containers/Stepper/Steps/Settings';

export const getSteps = (withFieldSelection) => {
  let firstSteps = [];

  if (withFieldSelection) {
    firstSteps = getCommonSteps();
  }

  return [
    ...firstSteps,
    {
      name: i18n.t('zones-ops.cross-layer.stepper.zones'),
      description: i18n.t('zones-ops.cross-layer.steps.3.description'),
      id: 'zones',
      component: Zones,
    },
    {
      name: i18n.t('general.shared.settings'),
      description: (
        <>
          {i18n.t('zones-ops.common.processing-description')}
          {' '}
          {i18n.t('zones-ops.common.charge-description')}
        </>
      ),
      id: 'settings',
      component: Settings,
    },
  ];
};

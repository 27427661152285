import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from '../../../../../tableView/components/Table';
import { filterByKey } from '../../../../../../../helpers';
import { openPopup } from '../../../../../popups/popupsSlice';
import { selectSharedFarmsQuery } from '../../../../userFarmsSelectors';
import { ACTIONS } from '../../../../helpers/constants/ui';
import { getUserSharedTableRows } from '../../../../helpers/functions/tableRows';
import {
  selectEmail,
  selectUserAdminOrAdvisor,
} from '../../../../../../user/userSelectors';
import {
  useDeleteFarmMutation,
  useGetFarmsQuery,
} from '../../../../../../farms/farmsAPI';
import { POPUPS } from '../../../../../popups/helpers/constants/popups';

import './index.scss';

const prepareMenuItems = (t) => [
  {
    id: 'remove-farm',
    label: t('user-farms.shared-farms.table.remove-farm'),
  },
];

const getTableColumns = ({
  t,
  isAdminOrAdvisor,
  onActionClick,
  onFarmMenuItemClick,
}) => {
  return [
    {
      id: 'name',
      label: t('user-farms.own-farms.table.farm'),
      element: 'default',
      classes: [
        'shared-farms-table__name-cell',
      ],
    },
    {
      id: 'owner',
      label: t('user-farms.shared-farms.table.owner'),
      element: 'default',
      classes: [
        'shared-farms-table__owner-cell',
      ],
    },
    ...(
      isAdminOrAdvisor
        ? [
          {
            id: 'users',
            label: t('user-farms.own-farms.table.shared-with-user'),
            element: 'default',
          },
          {
            id: 'actions',
            element: 'actions',
            label: t('user-farms.own-farms.table.actions'),
            onActionClick,
          },
        ]
        : []
    ),
    {
      id: 'menu',
      label: '',
      element: 'menu',
      horizontalAlign: 'right',
      menuItems: prepareMenuItems(t),
      onMenuItemClick: onFarmMenuItemClick,
    },
  ];
};

const UserFarmsSharedFarmsPanel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filterQuery = useSelector(selectSharedFarmsQuery);
  const isAdminOrAdvisor = useSelector(selectUserAdminOrAdvisor);
  const userEmail = useSelector(selectEmail);
  const {
    data: farms,
  } = useGetFarmsQuery();
  const [deleteFarm] = useDeleteFarmMutation();
  const tableRows = getUserSharedTableRows(farms, userEmail);

  const onActionClick = (action, row) => {
    if (action === ACTIONS.share) {
      dispatch(openPopup({
        type: 'share-farms',
        farmUuids: [row.id],
      }));
    } else if (action === ACTIONS.stopSharing) {
      dispatch(openPopup({
        type: 'stop-farm-share',
        farmUuid: row.id,
        farmName: row.name,
      }));
    }
  };

  const onFarmMenuItemClick = ({ id }, row) => {
    if (id === 'remove-farm') {
      dispatch(openPopup({
        type: POPUPS.deleteSharedFarm,
        entityName: row.name,
        onConfirm: async () => {
          await deleteFarm({
            farmUuid: row.id,
          });
        },
      }));
    }
  };

  return (
    <Table
      classes={{
        root: 'shared-farms-table',
      }}
      columns={getTableColumns({
        t,
        isAdminOrAdvisor,
        onActionClick,
        onFarmMenuItemClick,
      })}
      rows={filterByKey('name', tableRows, filterQuery)}
      showPagination={false}
    />
  );
};

export default UserFarmsSharedFarmsPanel;

import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function SingleLayerIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12 19.2L19.36 13.3063L21 12L12 4.79999L3 12L4.63 13.3063L12 19.2Z" />
    </SvgIcon>
  );
}

import React from 'react';
import clsx from 'clsx';

import ZonesMapTable from '../../../../../components/Views/common/ZonesMapTable';
import ZonesMapResultsPlaceholder from '../../../../../components/ZonesMapResultsPlaceholder';

import { getHexColorCodes } from '../../../../../helpers/analysis';

import './index.scss';

const SettingsPanel = ({
  className,
  zonesMapGeojson,
  geoMaps,
  areaUnit,
}) => {
  return (
    <div className={clsx('settings-panel', className)}>
      {
        (zonesMapGeojson && geoMaps)
        && (
          <ZonesMapTable
            areaUnit={areaUnit}
            attributes={zonesMapGeojson}
            colors={getHexColorCodes(geoMaps)}
          />
        )
      }
      {
        (!zonesMapGeojson && !geoMaps)
        && <ZonesMapResultsPlaceholder />
      }
    </div>
  );
};

export default SettingsPanel;

import { API, graphqlOperation } from '@aws-amplify/api';

import getFarmBBoxesQuery from './graphql/queries/getFarmsBboxes.gql';

export const getFarmBBoxes = async (farmUuid) => {
  return API.graphql(graphqlOperation(getFarmBBoxesQuery, {
    farmUuids: [farmUuid],
  }))
    .then(({ data }) => {
      return data.getFarms;
    });
};

import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';

const JD_FIELD_ANALYZER_LINK = 'https://field-analyzer.deere.com';

export const getSteps = () => {
  return [
    {
      name: i18n.t('upload-data-john-deere.steps.selectOrganizations.title'),
      description: i18n.t('upload-data-john-deere.steps.selectOrganizations.description'),
      id: 'selectOrganization',
    },
    {
      name: i18n.t('upload-data-john-deere.steps.selectData.title'),
      description: (
        <Trans i18nKey='upload-data-john-deere.steps.selectData.description'>
          text <a className="link" href={JD_FIELD_ANALYZER_LINK} target="_blank">link</a>text
        </Trans>
      ),
      id: 'selectData',
    },
  ];
};

export enum AreaUnit {
  hectares = 'HECTARES',
  acres = 'ACRES',
  squareMeters = 'SQUARE_METERS',
}

// TODO: convert to enums
export const USER_ROLES = {
  user: 'user',
  advisor: 'advisor',
};

export const COGNITO_GROUPS = {
  origin: 'Origin-group',
  pfeiferLangen: 'PfeiferLangen-group',
  organization: 'Organization-group',
  planet: 'Planet-group',
};

export const LANGUAGES = {
  english: 'en',
  german: 'de',
  french: 'fr',
  dutch: 'nl',
  ukrainian: 'uk',
  russian: 'ru',
  portugueseBrazil: 'pt',
  hungarian: 'hu',
  slovenian: 'sl',
  bulgarian: 'bg',
  italian: 'it',
};

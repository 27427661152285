import { API, graphqlOperation } from '@aws-amplify/api';

import {
  JOHN_DEERE_FIRST_PAGE_PAGINATION,
  JOHN_DEERE_DEFAULT_PAGE_SIZE_PAGINATION,
} from './helpers/constants';
import getFieldsJohnDeereQuery from './graphql/queries/getFieldsJohnDeere.gql';
import getFieldsJohnDeereOrgIdQuery from './graphql/queries/getFieldsJohnDeereOrgId.gql';

export const getFieldsJohnDeere = async (
  page = JOHN_DEERE_FIRST_PAGE_PAGINATION,
  pageSize = JOHN_DEERE_DEFAULT_PAGE_SIZE_PAGINATION,
  organizationsIds = [],
  fieldName = '',
) => {
  return API.graphql(graphqlOperation(getFieldsJohnDeereQuery, {
    fieldFilter: {
      page,
      pageSize,
      organizationsIds,
      fieldName,
    },
  }))
    .then(({ data }) => {
      return {
        ...data.getFieldsJohnDeere,
        page,
        pageSize,
        organizationsIds,
        fieldName,
      };
    });
};

export const getFieldJohnDeereOrgId = async (
  fieldUuid,
) => {
  return API.graphql(graphqlOperation(getFieldsJohnDeereOrgIdQuery, {
    fieldFilter: {
      fieldUuid,
    },
  }))
    .then(({ data }) => {
      return data.getFieldsJohnDeere.fields[0];
    });
};

export const selectSelectedUuids = ({ createFieldPotentialZones }) => createFieldPotentialZones.selectedUuids;

export const selectActiveUuid = ({ createFieldPotentialZones }) => createFieldPotentialZones.activeUuid;

export const selectIndex = ({ createFieldPotentialZones }) => createFieldPotentialZones.index;

export const selectSettings = ({ createFieldPotentialZones }) => createFieldPotentialZones.settings;

export const selectChanged = ({ createFieldPotentialZones }) => createFieldPotentialZones._changed;

export const selectCloned = ({ createFieldPotentialZones }) => createFieldPotentialZones._cloned;

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  View,
  DEFAULT_VIEW,
} from '../../helpers/constants/authentication';
import { getCode } from '../../helpers/functions/navigation';
import useDidMount from '../../../../../hooks/useDidMount';
import views from './config';
import Panel from '../../components/AuthenticationPanel';

const AuthenticationPanel = () => {
  const { view = DEFAULT_VIEW } = useParams<{ view?: View }>();
  const [loading, setLoading] = useState(false);
  const Component = views[view];

  useDidMount(() => {
    const code = getCode(window.location.search);

    // Display loader while getting redirected by auth provider
    setLoading(!!code);
  });

  return (
    <Panel loading={loading}>
      <Component onLoading={setLoading} />
    </Panel>
  );
};

export default AuthenticationPanel;

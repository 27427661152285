export enum WorkPlanType {
  seeding = 'seeding',
  application = 'application',
}

export const WORK_PLAN_ID_NAME = 'workPlanId';

export const WORK_PLAN_EXPORT_ERROR_MESSAGES_I118KEY: Record<number, string> = {
  400: 'general.popups.export-work-plan.error-message.400',
};

export const JD_WORK_PLAN_PROCESSING_DELAY = 15000;

import React, {
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import EditIcon from '@material-ui/icons/Edit';
import { DialogActions } from '@material-ui/core';

import Button from '../../../../../components/Button';
import Tabs from '../../../../../components/Tabs';
import ImportIcon from '../../../../../components/Icons/import';
import IntegrationIcon from '../../../../../components/Icons/integration';
import PopupHeader from '../../../../../components/Popups/PopupHeader';
import {
  PAGES_ROOTS,
  getRootLink,
} from '../../../../../helpers/navigation';
import AmplitudeAnalytics from '../../../../../helpers/classes/amplitudeAnalytics';
import { CreateFieldOperation } from '../../helpers/constants/operation';
import useCurrentPage from '../../../../../hooks/useCurrentPage';

import './index.scss';

const CreateFieldPopup = ({
  onCancel = () => {},
}: {
  onCancel?: () => void;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const page = useCurrentPage();
  const [tab, setTab] = useState(CreateFieldOperation.drawManually);

  useEffect(() => {
    if (page) {
      AmplitudeAnalytics.trackCreateEntityPopupOpened({
        page,
        entityType: 'field',
      });
    }
  }, [page]);

  const onConfirm = () => {
    let trackLink = '';

    switch (tab) {
      case CreateFieldOperation.drawManually:
        trackLink = getRootLink(PAGES_ROOTS.createField);
        break;
      case CreateFieldOperation.importFiles:
        trackLink = getRootLink(PAGES_ROOTS.uploadFieldBoundaries);
        break;
      case CreateFieldOperation.jdIntegration:
        trackLink = getRootLink(PAGES_ROOTS.uploadDataJohnDeere);
        break;
      default:
        trackLink = '';
        break;
    }

    if (trackLink) {
      history.push(trackLink);
      AmplitudeAnalytics.trackCreateFieldOptionSelected({ operation: tab });
    }

    onCancel();
  };

  const tabs = [
    {
      value: CreateFieldOperation.drawManually,
      label: {
        primary: t('general.popups.create-field.tabs.draw-manually'),
        icon: <EditIcon className='create-field-popup__tab-icon'/>,
      },
    },
    {
      value: CreateFieldOperation.importFiles,
      label: {
        primary: t('general.popups.create-field.tabs.import-files'),
        icon: <ImportIcon className='create-field-popup__tab-icon'/>,
      },
    },
    {
      value: CreateFieldOperation.jdIntegration,
      label: {
        primary: t('general.popups.create-field.tabs.jd-integration'),
        icon: <IntegrationIcon className='create-field-popup__tab-icon'/>,
      },
    },
  ];

  return (
    <Dialog open>
      <PopupHeader
        classes={{
          root: 'create-field-popup__header',
        }}
        title={t('general.popups.create-field.title')}
        onCancel={onCancel}
      />
      <DialogContent className="create-field-popup__content">
        <DialogContentText>
          {t('general.popups.create-field.description')}
        </DialogContentText>
        <Tabs
          classes={{
            root: 'create-field-popup__tabs',
          }}
          tabClasses={{
            root: 'create-field-popup__tab',
            wrapper: 'create-field-popup__tab-wrapper',
          }}
          tabs={tabs}
          value={tab}
          centered
          variant='fullWidth'
          onTabChange={(_event: unknown, value: CreateFieldOperation) => setTab(value)}
        />
      </DialogContent>
      <DialogActions
        classes={{
          root: 'create-field-popup__actions',
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={onCancel}
        >
          {t('general.controls.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirm}
        >
          {t('general.controls.proceed')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateFieldPopup;

import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import useDidMount from '../../../hooks/useDidMount';
import BackdropLoading from '../../../components/Loading/BackdropLoading';
import { setupApp } from '../../../features/ui/applicationShell/applicationShellSlice';
import { authenticate } from '../../../features/user/userSlice';
import {
  selectAuthenticationFulfilled,
  selectAuthenticationRejected,
  selectIsLoaded,
  selectWithRedirect,
} from '../../../features/user/userSelectors';
import { View } from '../../../features/ui/authentication/helpers/constants/authentication';
import { getAuthLink } from '../../../helpers/navigation';
import { buildRedirectedFrom } from '../../../features/ui/authentication/helpers/functions/navigation';

const AuthenticationRedirector = ({
  children,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [mounted, setMounted] = useState(false);
  const isUserDataLoaded = useSelector(selectIsLoaded);
  const authenticationFulfilled = useSelector(selectAuthenticationFulfilled);
  const authenticationRejected = useSelector(selectAuthenticationRejected);
  const withRedirect = useSelector(selectWithRedirect);

  const getCurrentPath = (location) => {
    return location.pathname + location.search;
  };

  useDidMount(async () => {
    setMounted(true);
    const { error } = await dispatch(authenticate());

    if (!error && !isUserDataLoaded) {
      dispatch(setupApp());
    }

    return () => {
      setMounted(false);
    };
  });

  let result;

  // wait for authentication started
  if (!mounted) {
    result = <BackdropLoading />;
  } else if (authenticationFulfilled && isUserDataLoaded) {
    result = <Fragment>{children}</Fragment>;
  } else if (authenticationRejected) {
    result = (
      <Redirect
        to={{
          pathname: getAuthLink(View.logIn),
          search: withRedirect && buildRedirectedFrom(getCurrentPath(history.location)),
        }}
      />
    );
  } else {
    result = <BackdropLoading />;
  }

  return result;
};

export default AuthenticationRedirector;

export const selectIsProcessing = ({ userSettings }) => {
  return userSettings.isProcessing;
};

export const selectUpdates = ({ userSettings }) => {
  return userSettings.updates;
};

export const selectHasUpdates = (state) => {
  const {
    language,
    areaUnit,
  } = selectUpdates(state);

  return language !== ''
    || areaUnit !== '';
};

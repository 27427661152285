import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function CloneIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.5 1H16.5V3H4.5V17H2.5V1ZM6.5 5H21.5V23H6.5V5Z"/>
    </SvgIcon>
  );
}

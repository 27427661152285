import {
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { useGetAllFieldsQuery } from '../fieldsAPI';
import { LoadStatus } from '../../../helpers/constants/utils/loadStatus';
import { FieldFragment } from '../helpers/constants/fragments';
import { selectAreaUnit } from '../../user/userSelectors';
import { ALL_FIELDS_PAGE_SIZE } from '../helpers/constants';

export default function useAllFields(fieldFragment: FieldFragment) {
  const areaUnit = useSelector(selectAreaUnit);
  const [status, setStatus] = useState(LoadStatus.idle);

  const {
    data,
    isFetching,
    isSuccess,
    isError,
    refetch,
  } = useGetAllFieldsQuery({
    fieldFragment,
    areaUnit,
    pageSize: ALL_FIELDS_PAGE_SIZE,
  });

  useEffect(() => {
    if (isFetching) {
      setStatus(LoadStatus.loading);
    } else if (isSuccess) {
      setStatus(LoadStatus.success);
    } if (isError) {
      setStatus(LoadStatus.error);
    }
  }, [data, isSuccess, isError, isFetching]);

  return {
    refetch,
    allFields: data ?? [],
    status,
  };
}

import { useRef, useEffect } from 'react';

type IntervalCallback = (id: NodeJS.Timeout) => void;

export default function useInterval(callback: IntervalCallback, delay: number) {
  const callbackRef = useRef<IntervalCallback | undefined>();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    let id: NodeJS.Timeout;
    const tick = () => callbackRef.current?.(id);

    id = setInterval(tick, delay);

    return () => clearInterval(id);
  }, [delay]);
}

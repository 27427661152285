import React from 'react';
import { useSelector } from 'react-redux';

import FieldLegend from '../common';
import { getSelectedItem } from '../../../../../../helpers/components/legend';
import { STORE_NAMES } from '../../../../../../helpers/constants/keyValueBrowserStore';
import { extractMinMax } from '../../../../../../helpers/functions/entities/3dMap';
import useKeyValueBrowserStore from '../../../../../../hooks/useKeyValueBrowserStore';
import { selectLegend } from '../../../fieldWorkflowSelectors';
import {
  selectField,
  selectThreeDimensionalMapAttribute,
  selectThreeDimensionalMapDataset,
} from '../../../../../field/fieldSelectors';

const View3dLegend = () => {
  const field = useSelector(selectField);
  const { uuid } = useSelector(selectLegend);
  const map3d = getSelectedItem(uuid, field);
  const map3dAttribute = useSelector((state) => selectThreeDimensionalMapAttribute(state, uuid));
  const map3dDataset = useSelector((state) => selectThreeDimensionalMapDataset(state, uuid));
  const points = useKeyValueBrowserStore(STORE_NAMES.fieldAssets, uuid, map3d?.pointsStored);

  let legendItem;

  if (map3d) {
    const { min, max } = extractMinMax(points, map3dAttribute);
    const vectorAnalysisMap = getSelectedItem(map3d?.vectorAnalysisMap.uuid, field);
    const dataset = getSelectedItem(map3dDataset?.uuid, field);
    legendItem = {
      ...map3d,
      min,
      max,
      dataset,
      attribute: map3dAttribute,
      vectorAnalysisMap,
    };
  }

  return (
    <FieldLegend legend={legendItem} />
  );
};

export default View3dLegend;

import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../../../../../../components/Loading';
import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import Stepper from '../../../../../../../components/Stepper';
import ZonesOperation from '../../../../../../../components/OpsPanel/ZonesOperation';
import { updateSettings } from '../../../../crossLayerSlice';
import {
  goToPrevStep,
} from '../../../../../zonesOps/zonesOpsSlice';
import {
  updateVectorAnalysisMap,
} from '../../../../../../createAnalysis/createAnalysisSlice';
import Panel from '../../../Panel/Settings';
import {
  selectSettings,
} from '../../../../crossLayerSelectors';
import {
  selectSaveInProgress,
  selectGeneratedVectorAnalysisMap,
} from '../../../../../../createAnalysis/createAnalysisSelectors';
import useGenerateCrossLayerVamap from '../../../../hooks/useGenerateCrossLayerVamap';

const Map = React.lazy(() => import('../../../Map'));

const SettingsStepper = ({
  steps,
  stepIndex,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    name,
    operation,
    polygonMinArea,
  } = useSelector(selectSettings);
  const generatedVectorAnalysisMap = useSelector(selectGeneratedVectorAnalysisMap);
  const saveInProgress = useSelector(selectSaveInProgress);
  const generateVamap = useGenerateCrossLayerVamap();

  const onClickBack = () => {
    dispatch(goToPrevStep());
  };
  const onClickNext = () => {
    dispatch(updateVectorAnalysisMap({ name }));
  };
  const onSettingsPropChange = (prop, value) => {
    dispatch(updateSettings({
      prop,
      value,
    }));
  };
  const onRunOperation = () => {
    generateVamap();
  };

  return (
    <>
      <Stepper
        activeStep={stepIndex}
        steps={steps}
        nextDisabled={!generatedVectorAnalysisMap}
        nextLabel={t('zones-ops.common.finish')}
        onClickBack={onClickBack}
        onClickNext={onClickNext}
      >
        <ZonesOperation
          key="tools-panel"
          name={name}
          operation={operation}
          polygonMinArea={polygonMinArea}
          runAnalysisDisabled={!name}
          onPropChange={onSettingsPropChange}
          onRunAnalysis={onRunOperation}
        />
        <Panel key="panel" />
        <Suspense
          key="map"
          fallback={<Loading />}
        >
          <Map />
        </Suspense>
      </Stepper>
      {saveInProgress && <BackdropLoading />}
    </>
  );
};

export default SettingsStepper;

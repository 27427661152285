import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import PopupHeader from '../../../../../components/Popups/PopupHeader';
import Table from '../../../tableView/components/Table';

import './index.scss';

const ViewDetailsPopup = ({
  tableRows = [],
  onCancel = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open classes={{ root: 'view-details-john-deere-popup' }}>
      <PopupHeader
        title={t('general.popups.view-details-john-deere.title')}
        onCancel={onCancel}
      />
      <DialogContent classes={{ root: 'view-details-john-deere-popup__content' }} >
        <Table
          showPagination={false}
          columns={[
            {
              id: 'name',
              element: 'default',
              label: t('general.popups.view-details-john-deere.table.name'),
            },
            {
              id: 'message',
              element: 'default',
              label: t('general.popups.view-details-john-deere.table.description'),
            },
          ]}
          rows={tableRows}
          classes={{
            tablePaper: 'view-details-john-deere-popup__content-table-paper',
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ViewDetailsPopup;

import React from 'react';
import { useSelector } from 'react-redux';

import FieldLegend from '../common';
import { selectField } from '../../../../../field/fieldSelectors';
import { selectLegend } from '../../../fieldWorkflowSelectors';
import { getSelectedItem } from '../../../../../../helpers/components/legend';

const MapLegend = () => {
  const field = useSelector(selectField);
  const { uuid } = useSelector(selectLegend);
  const legendItem = getSelectedItem(uuid, field);

  return (
    <FieldLegend legend={legendItem} />
  );
};

export default MapLegend;

import { API, graphqlOperation } from '@aws-amplify/api';

import shareFarmsMutation from '../../farms/graphql/mutations/shareFarms.gql';
import addUsersToOrganizationMutation from './graphql/mutations/addUsersToOrganization.gql';
import deleteUsersFromOrganizationMutation from './graphql/mutations/deleteUsersFromOrganization.gql';

export const shareFarms = async ({
  userUuid,
  organizationUuid,
  isAllFarms,
}) => {
  return API.graphql(graphqlOperation(shareFarmsMutation, {
    input: {
      userUuids: [userUuid],
      organizationUuid,
      allFarms: isAllFarms,
    },
  }))
    .then(({ data }) => {
      return data.shareFarms;
    });
};

export const addUsersToOrganization = async ({
  emails,
  organizationUuid,
}) => {
  return API.graphql(graphqlOperation(addUsersToOrganizationMutation, {
    input: {
      emails,
      organizationUuid,
    },
  }))
    .then(({ data }) => {
      return data.addUsersToOrganization;
    });
};

export const deleteUsersFromOrganization = async ({
  userUuids,
  organizationUuid,
}) => {
  return API.graphql(graphqlOperation(deleteUsersFromOrganizationMutation, {
    input: {
      userUuids,
      organizationUuid,
    },
  }))
    .then(({ data }) => {
      return data.deleteUsersFromOrganization;
    });
};

import { getSearchParam } from '../../../../../helpers/navigation';
import { AutocompleteItem } from '../../../autocomplete/types/autocomplete';
import { FieldsListSearchParam } from '../constants/filters';

export const parseFieldsListSearchParams = (search: string) => {
  try {
    const parsedValues = Object.values(FieldsListSearchParam).reduce<{
      farm?: string;
      autocomplete?: AutocompleteItem[];
      labelsExpanded?: boolean;
    }>((acc, param) => {
      const paramValue = getSearchParam(search, param);

      if (!paramValue) {
        return acc;
      }

      if (param === FieldsListSearchParam.autocomplete) {
        const decodedValue = decodeURIComponent(paramValue);
        acc[param] = JSON.parse(decodedValue);
      } else if (param === FieldsListSearchParam.labelsExpanded) {
        acc[param] = paramValue === 'true';
      } else {
        acc[param] = paramValue;
      }

      return acc;
    }, {});

    return Object.values(parsedValues).length ? parsedValues : null;
  } catch {
    return null;
  }
};

export const prepareFieldsListSearchParams = (
  {
    autocomplete,
    farmUuid,
    labelsExpanded,
  }: {
    autocomplete: AutocompleteItem[];
    farmUuid?: string;
    labelsExpanded: boolean;
  },
) => {
  const searchParams = new URLSearchParams();

  if (farmUuid) {
    searchParams.set(FieldsListSearchParam.farm, farmUuid);
  }

  if (autocomplete.length) {
    searchParams.set(
      FieldsListSearchParam.autocomplete,
      encodeURIComponent(JSON.stringify(autocomplete)),
    );
  }

  if (labelsExpanded) {
    searchParams.set(
      FieldsListSearchParam.labelsExpanded,
      labelsExpanded.toString(),
    );
  }

  return searchParams.toString();
};

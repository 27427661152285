import React, {
  useEffect,
  useState,
} from 'react';
import { Trans, withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import PopupHeader from '../PopupHeader';
import Button from '../../Button';
import TextField from '../../TextField';
import useCurrentPage from '../../../hooks/useCurrentPage';
import AmplitudeAnalytics from '../../../helpers/classes/amplitudeAnalytics';

import './index.scss';

const DeleteFields = ({
  t,
  fieldNames = [],
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  const confirmWord = t('general.popups.delete-confirm-word');
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);
  const page = useCurrentPage();

  useEffect(() => {
    if (page) {
      AmplitudeAnalytics.trackBatchEntitiesDeletePopupOpened({
        page,
        entityType: 'field',
      });
    }
  }, [page]);

  const handleConfirm = () => {
    AmplitudeAnalytics.trackBatchEntitiesDeleted({
      page,
      entityType: 'field',
      entitiesAmount: fieldNames.length,
    });
    onConfirm();
  };

  const handleConfirmInputChange = (e) => {
    setConfirmButtonDisabled(e.target.value !== confirmWord);
  };

  return (
    <Dialog open>
      <PopupHeader
        title={t('general.popups.delete-fields.title')}
        onCancel={onCancel}
      />
      <DialogContent>
        <DialogContentText
          component="div"
          classes={{
            root: 'delete-fields-popup__content-text',
          }}
        >
          <span>
            <Trans i18nKey="general.popups.delete-fields.description" values={{ confirmWord }} />
          </span>
          <ul className="delete-fields-popup__fields-list">
            {
              fieldNames.map((name, fieldIndex) => (
                <li key={fieldIndex}>- {name}</li>
              ))
            }
          </ul>
        </DialogContentText>
        <TextField
          placeholder={confirmWord}
          onChange={handleConfirmInputChange}
        />
      </DialogContent>
      <DialogActions
        classes={{
          root: 'delete-field-popup__actions',
        }}
      >
        <Button
          onClick={onCancel}
          variant="outlined"
        >
          {t('general.controls.cancel')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={confirmButtonDisabled}
          onClick={handleConfirm}
        >
          {t('general.controls.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withTranslation()(DeleteFields);

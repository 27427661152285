import React from 'react';

import FiltersPanel from '../../../../../filters/components/FiltersPanel';
import { DEFAULT_ASSET_GROUP_FILTER } from '../../../../../filters/helpers/constants/assetGroup';
import { DefaultAssetGroupFilters } from '../../../../../filters/types/assetGroup';
import DefaultAssetGroupFilter from '../../../../../filters/components/DefaultAssetGroupFilter';

export default function DatasetFilters({
  filters,
  filterExpanded,
  onChangeFilters,
}: {
  filters: DefaultAssetGroupFilters,
  filterExpanded: boolean,
  onChangeFilters: (filters: DefaultAssetGroupFilters) => void,
}) {
  return (
    <FiltersPanel
      value={filters}
      isExpanded={filterExpanded}
      onFiltersClear={() => onChangeFilters(DEFAULT_ASSET_GROUP_FILTER)}
    >
      <DefaultAssetGroupFilter
        value={filters}
        onFiltersClear={() => onChangeFilters(DEFAULT_ASSET_GROUP_FILTER)}
        onChange={onChangeFilters}
      />
    </FiltersPanel>
  );
}

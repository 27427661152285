import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../../../app/store/helpers/functions';
import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import Loading from '../../../../../../../components/Loading';
import Stepper, { Step } from '../../../../../../../components/Stepper';
import ToolsPanel from '../../../ToolsPanel';
import Panel from '../../../Panel/Preview';
import { save, updateSettings } from '../../../../createThreeDMapSlice';
import { selectLoading, selectSettings } from '../../../../createThreeDMapSelectors';
import { goToPrevStep } from '../../../../../zonesOps/zonesOpsSlice';

const View = React.lazy(() => import('../../../3dView'));

const Preview = ({
  steps,
  stepIndex,
}: {
  steps: Step[],
  stepIndex: number,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const loading = useAppSelector(selectLoading);
  const { name } = useAppSelector(selectSettings);

  const handleClickBack = () => {
    dispatch(goToPrevStep());
  };

  const handleClickNext = () => {
    dispatch(save());
  };

  const handleNameChange = (value: string) => {
    dispatch(updateSettings({ prop: 'name', value }));
  };

  return (
    <Stepper
      nextLabel={t('create-3d.stepper.save-and-finish')}
      activeStep={stepIndex}
      steps={steps}
      nextDisabled={loading || !name}
      onClickBack={handleClickBack}
      onClickNext={handleClickNext}
    >
      <ToolsPanel
        key="tools-panel"
        name={name}
        onNameChange={handleNameChange}
      />
      <Panel key="panel" />
      <Suspense key="map" fallback={<Loading />}>
        <View />
      </Suspense>
      {loading && <BackdropLoading key="loader"/>}
    </Stepper>
  );
};

export default Preview;

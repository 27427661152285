import React, { useCallback } from 'react';

import { selectEquationMaps, selectVectorAnalysisMaps } from '../../../../field/fieldSelectors';
import { selectApiKey } from '../../../../user/userSelectors';
import { TransformedEquationMap } from '../../../../../helpers/types/equationMap';
import { TransformedVectorAnalysisMap } from '../../../../../helpers/types/vectorAnalysisMap';
import AssetsSelect from '../../../../../components/AssetsSelect';
import {
  isJDExportAsWorkPlanDisabled,
  isVamapJDExportAsWorkPlanDisabled,
} from '../../helpers/functions/ui';
import { hasRates } from '../../../../../helpers/functions/entities/vectorAnalysisMap';
import ExportToJohnDeereTooltip from '../../../dataLayersView/components/DataLayersTree/ExportToJohnDeereTooltip';
import { isVectorAnalysis } from '../../../../../helpers/functions/entities/assets';
import { selectJDIntegrationData } from '../../../../jdProfile/jdProfileSelectors';
import { isInvalid as isEquationMapInvalid } from '../../../../../helpers/functions/entities/equationMap';
import { useAppSelector } from '../../../../../app/store/helpers/functions';

const AssetToExportSelect = ({
  selectedAssetUuid,
  onAssetSelect,
}: {
  selectedAssetUuid?: string,
  onAssetSelect: (asset: TransformedEquationMap | TransformedVectorAnalysisMap | null) => void,
}) => {
  const equationMaps = useAppSelector(selectEquationMaps);
  const vectorAnalysisMaps = useAppSelector(selectVectorAnalysisMaps);
  const apiKey = useAppSelector(selectApiKey);
  const jdIntegrationData = useAppSelector(selectJDIntegrationData);

  const isAssetExportDisabled = useCallback((asset: TransformedEquationMap | TransformedVectorAnalysisMap) => {
    return isVectorAnalysis(asset)
      ? isVamapJDExportAsWorkPlanDisabled({
        assetHasNoRatesAssigned: !hasRates(asset),
        ...jdIntegrationData,
      })
      : (isJDExportAsWorkPlanDisabled(jdIntegrationData) || isEquationMapInvalid(asset));
  }, [jdIntegrationData]);

  const getVamapIcon = useCallback((asset: TransformedEquationMap | TransformedVectorAnalysisMap) => {
    return (
      <ExportToJohnDeereTooltip
        {...jdIntegrationData}
        assetHasNoRatesAssigned={isVectorAnalysis(asset) && !hasRates(asset)}
      />
    );
  }, [jdIntegrationData]);

  return (
    <AssetsSelect
      apiKey={apiKey}
      assets={{
        equationMaps,
        vectorAnalysisMaps,
      }}
      selectedAssetUuid={selectedAssetUuid}
      onAssetSelect={onAssetSelect}
      isAssetDisabled={isAssetExportDisabled}
      getAssetIcon={getVamapIcon}
    />
  );
};

export default AssetToExportSelect;

import React, {
  Suspense,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Panel from '../../../Panel/Settings';
import Loading from '../../../../../../../components/Loading';
import Stepper from '../../../../../../../components/Stepper';
import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import ZonesCreation from '../../../../../../../components/OpsPanel/ZonesCreation';
import {
  goToPrevStep,
} from '../../../../../zonesOps/zonesOpsSlice';
import {
  updateVectorAnalysisMap,
} from '../../../../../../createAnalysis/createAnalysisSlice';
import {
  selectGeneratedVectorAnalysisMap,
  selectSaveInProgress,
} from '../../../../../../createAnalysis/createAnalysisSelectors';
import { selectSettings } from '../../../../createFieldPotentialZonesSelectors';
import { updateSettings } from '../../../../createFieldPotentialZonesSlice';
import { selectAreaUnit } from '../../../../../../user/userSelectors';
import { selectArea } from '../../../../../../field/fieldSelectors';
import useGenerateFieldPotentialVamap from '../../../../hooks/useGenerateFieldPotentialVamap';

const Map = React.lazy(() => import('../../../Map'));

const SettingsStepper = ({
  steps,
  stepIndex,
  step,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    name,
    numberOfZones,
    polygonMinArea,
    dataClassificationType,
    useInterpolatedData,
  } = useSelector(selectSettings);
  const generatedVectorAnalysisMap = useSelector(selectGeneratedVectorAnalysisMap);
  const saveInProgress = useSelector(selectSaveInProgress);
  const areaUnit = useSelector(selectAreaUnit);
  const fieldArea = useSelector(selectArea);
  const generateVamap = useGenerateFieldPotentialVamap();
  const runAnalysisDisabled = !name || !numberOfZones;

  const onClickBack = () => {
    dispatch(goToPrevStep());
  };
  const onClickNext = () => {
    dispatch(updateVectorAnalysisMap({ name }));
  };
  const onZonesOpsPropChange = useCallback((prop, value) => {
    dispatch(updateSettings({
      prop,
      value,
    }));
  }, [dispatch]);
  const onRunAnalysis = () => {
    generateVamap();
  };

  return (
    <>
      <Stepper
        activeStep={stepIndex}
        steps={steps}
        nextDisabled={!generatedVectorAnalysisMap}
        nextLabel={t('zones-ops.common.finish')}
        onClickBack={onClickBack}
        onClickNext={onClickNext}
      >
        <ZonesCreation
          key="tools-panel"
          name={name}
          areaUnit={areaUnit}
          fieldArea={fieldArea}
          numberOfZones={numberOfZones}
          polygonMinArea={polygonMinArea}
          dataClassificationType={dataClassificationType}
          useInterpolatedData={useInterpolatedData}
          runAnalysisDisabled={runAnalysisDisabled}
          onPropChange={onZonesOpsPropChange}
          onRunAnalysis={onRunAnalysis}
        />
        <Panel key="panel" />
        <Suspense
          key="map"
          fallback={<Loading />}
        >
          <Map step={step} />
        </Suspense>
      </Stepper>
      {saveInProgress && <BackdropLoading />}
    </>
  );
};

export default SettingsStepper;

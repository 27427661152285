import { EXPORT_ASSET_TYPE } from '../constants/assets';

export const getSelectedAssets = (
  selectedAssets,
  {
    fieldsEntities = {},
    vectorAnalysisMapsEntities = {},
    pinsGroupsEntities = {},
  },
) => {
  const result = [];

  selectedAssets[EXPORT_ASSET_TYPE.fieldBoundary].forEach((uuid) => {
    const field = fieldsEntities[uuid];

    if (field) {
      result.push({
        uuid: field.uuid,
        type: EXPORT_ASSET_TYPE.fieldBoundary,
        value: field,
      });
    }
  });

  selectedAssets[EXPORT_ASSET_TYPE.zonesMap].forEach((uuid) => {
    const vamap = vectorAnalysisMapsEntities[uuid];

    if (vamap) {
      result.push({
        uuid: vamap.uuid,
        type: EXPORT_ASSET_TYPE.zonesMap,
        value: vamap,
      });
    }
  });

  selectedAssets[EXPORT_ASSET_TYPE.pinsGroup].forEach((uuid) => {
    const pinsGroup = pinsGroupsEntities[uuid];

    if (pinsGroup) {
      result.push({
        uuid: pinsGroup.uuid,
        type: EXPORT_ASSET_TYPE.pinsGroup,
        value: pinsGroup,
      });
    }
  });

  return result;
};

export const prepareSelectedAssets = (selectedAssets) => {
  return selectedAssets.reduce((acc, asset) => {
    if (asset.type === EXPORT_ASSET_TYPE.zonesMap) {
      if (!acc.vectorAnalysisMaps) {
        acc.vectorAnalysisMaps = [];
      }

      acc.vectorAnalysisMaps.push(asset.value);
    } else if (asset.type === EXPORT_ASSET_TYPE.fieldBoundary) {
      if (!acc.fieldBoundaries) {
        acc.fieldBoundaries = [];
      }

      acc.fieldBoundaries.push({
        uuid: asset.value.farmUuid,
        fieldUuid: asset.value.uuid,
      });
    } else if (asset.type === EXPORT_ASSET_TYPE.pinsGroup) {
      if (!acc.pinsGroups) {
        acc.pinsGroups = [];
      }

      acc.pinsGroups.push({
        uuid: asset.value.uuid,
        fieldUuid: asset.value.fieldUuid,
        entity: asset.value.entity._type,
      });
    }

    return acc;
  }, {});
};

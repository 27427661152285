import { STORE_NAMES, THREE_D_MAP_STORE_KEY } from '../../../helpers/constants/keyValueBrowserStore';
import store from '../stores/keyValueBrowserStore';
import {
  fetchShapePoints,
  reset as resetCreate3d,
} from '../../../features/ui/createThreeDMap/createThreeDMapSlice';
import { fetch3dMapShapePoints } from '../../../features/field/fieldSlice';
import { reset as resetFieldWorkflow } from '../../../features/ui/fieldWorkflow/fieldWorkflowSlice';

const keyValueBrowserStore = () => (next) => async (action) => {
  let result;

  switch (action.type) {
    case fetch3dMapShapePoints.fulfilled.type: {
      const {
        points,
        ...cleanedPayload
      } = action.payload;

      await store.setItem(STORE_NAMES.fieldAssets, action.payload.uuid, points);

      result = next({
        ...action,
        payload: cleanedPayload,
      });
      break;
    }
    case fetchShapePoints.fulfilled.type: {
      const {
        points,
        ...cleanedPayload
      } = action.payload;

      await store.setItem(STORE_NAMES.create3d, THREE_D_MAP_STORE_KEY, points);

      result = next({
        ...action,
        payload: cleanedPayload,
      });
      break;
    }
    case resetCreate3d.type: {
      await store.drop(STORE_NAMES.create3d);

      result = next(action);
      break;
    }
    case resetFieldWorkflow.type: {
      await store.drop(STORE_NAMES.fieldAssets);

      result = next(action);
      break;
    }
    default:
      result = next(action);
  }

  return result;
};

export default keyValueBrowserStore;

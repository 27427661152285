import React from 'react';
import { connect } from 'react-redux';

import { setSelectedVamapUuid } from '../../../cloneZonesMapSlice';
import { selectSelectedVamapUuid } from '../../../cloneZonesMapSelectors';
import { selectVectorAnalysisMaps } from '../../../../../field/fieldSelectors';
import { ASSET_TYPE_TO_ASSET_GROUP } from '../../../../../../helpers/constants/entities/asset';
import useDataLayersTree from '../../../../dataLayersView/hooks/useDataLayersTree';

import './index.scss';

const CloneZonesMapPanel = ({
  vectorAnalysisMaps,
  selectedVamapUuid,
  onVamapClick,
}) => {
  const handleAssetItemClick = (_type, item) => {
    onVamapClick(item.uuid);
  };

  const { dataLayersTreeComponent } = useDataLayersTree({
    assets: {
      vectorAnalysisMaps,
    },
    selectedAssetUuid: selectedVamapUuid,
    selectedAssetGroupType: ASSET_TYPE_TO_ASSET_GROUP.VectorAnalysisMap,
    hasGroupPlaceholderAction: false,
    withAssetGroupNode: false,
    onAssetNodeClick: handleAssetItemClick,
  });

  return (
    <div className="clone-zones-map-panel">
      {dataLayersTreeComponent}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    vectorAnalysisMaps: selectVectorAnalysisMaps(state),
    selectedVamapUuid: selectSelectedVamapUuid(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onVamapClick: (uuid) => {
    dispatch(setSelectedVamapUuid(uuid));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CloneZonesMapPanel);

import type { Dictionary } from '@reduxjs/toolkit';

const isNotEmpty = <T>(value: T | undefined | null): value is T => {
  return value != null;
};

export const addFieldUuid = <E>(entities: E[], fieldUuid: string) => {
  return entities.map((entity) => {
    return {
      ...entity,
      fieldUuid,
    };
  });
};

export const mapUuidsToEntities = <E>(entities: Dictionary<E>, uuids: string[] = []) => {
  return uuids
    .map((uuid) => entities[uuid])
    .filter(isNotEmpty);
};

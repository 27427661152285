import React, {
  ChangeEvent,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '../../../../../components/TextField';
import ComboBox from '../../../../../components/ComboBox';
import Button from '../../../../../components/Button';
import UseInterpolationCheckbox from '../../../../../components/OpsPanel/UseInterpolationCheckbox';
import { getPurposeItems } from '../../../../../helpers/analysis';

import './index.scss';

const ToolsPanel = ({
  name,
  purpose,
  gridXSize,
  gridYSize,
  useInterpolatedData,
  onPropChange = () => {},
  onRunAnalysis = () => {},
}:{
  name: string,
  purpose: string,
  gridXSize: number | null,
  gridYSize: number | null,
  useInterpolatedData: boolean,
  onPropChange: (prop: string, value: string | number | boolean) => void,
  onRunAnalysis: () => void,
}) => {
  const { t } = useTranslation();
  const [purposeItems] = useState(getPurposeItems());
  const purposeOption = purposeItems.find((option) => {
    return option.value === purpose;
  });
  const isRunEquationDisabled = !name || !gridXSize || !gridYSize;

  return (
    <div className="ops-equation-based-panel">
      <TextField
        required
        value={name}
        className="ops-equation-based-panel__title"
        title={t('zones-ops.common.title')}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onPropChange('name', e.target.value)}
      />
      <ComboBox
        classes={{
          root: 'ops-equation-based-panel__select',
        }}
        disableClearable
        disableCloseOnSelect={false}
        title={t('general.controls.purpose')}
        options={purposeItems}
        value={purposeOption}
        getOptionSelected={(option, value) => {
          return option.value === value.value;
        }}
        onChange={(_event, { value }) => onPropChange('type', value)}
      />
      <TextField
        required
        type="number"
        className="ops-equation-based-panel__grid-input"
        title={`${t('zones-ops.equation-based.steps.5.grid-size-x')}, ${t('zones-ops.equation-based.steps.5.postfix.m')}`}
        value={gridXSize}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onPropChange('gridX', +e.target.value)}
      />
      <TextField
        required
        type="number"
        className="ops-equation-based-panel__grid-input"
        title={`${t('zones-ops.equation-based.steps.5.grid-size-y')}, ${t('zones-ops.equation-based.steps.5.postfix.m')}`}
        value={gridYSize}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onPropChange('gridY', +e.target.value)}
      />
      <UseInterpolationCheckbox
        checked={useInterpolatedData}
        onChange={(value) => onPropChange('useInterpolatedData', value)}
      />
      <Button
        className="ops-equation-based-panel__run-btn"
        variant="contained"
        color="primary"
        onClick={onRunAnalysis}
        disabled={isRunEquationDisabled}
      >
        {t('zones-ops.equation-based.steps.5.run-equation')}
      </Button>
    </div>
  );
};

export default ToolsPanel;

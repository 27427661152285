import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Tree from '../../../../../components/Tree';
import ScrollContainer from '../../../../../components/ScrollContainer';
import {
  fetchFarmFields,
  fetchFieldWithExportAssets,
  setFarms,
} from '../../../../exportData/exportDataSlice';
import { toggleAsset } from '../../downloadFilesSlice';
import { generateTree } from '../../helpers/functions/tree';
import {
  selectAllFarms,
  selectFieldsToFarmUuids,
  selectPinsGroupsToFieldUuids,
  selectVamapsToFieldUuids,
} from '../../../../exportData/exportDataSelectors';
import { selectSelectedAssets } from '../../downloadFilesSelectors';
import { useGetFarmsQuery } from '../../../../farms/farmsAPI';

import './index.scss';

const DownloadFilesPanel = () => {
  const dispatch = useDispatch();
  const selectedAssets = useSelector(selectSelectedAssets);
  const {
    data: farms,
    isFetching: isFarmsFetching,
  } = useGetFarmsQuery();
  const allFarms = useSelector(selectAllFarms);
  const fieldsToFarmUuids = useSelector(selectFieldsToFarmUuids);
  const vamapsToFieldUuids = useSelector(selectVamapsToFieldUuids);
  const pinsGroupsToFieldUuids = useSelector(selectPinsGroupsToFieldUuids);
  const assetsTree = generateTree(
    selectedAssets,
    {
      farms: allFarms,
      fields: fieldsToFarmUuids,
      vectorAnalysisMaps: vamapsToFieldUuids,
      pinsGroups: pinsGroupsToFieldUuids,
    },
  );

  useEffect(() => {
    if (isFarmsFetching) {
      return;
    }

    dispatch(setFarms(farms));
  }, [isFarmsFetching, farms, dispatch]);

  const handleAssetClick = (item) => {
    if (item.isFarm && !item.loaded && !item.loading) {
      dispatch(fetchFarmFields({
        uuid: item.uuid,
      }));
    } else if (item.isField && !item.loaded && !item.loading) {
      dispatch(fetchFieldWithExportAssets({
        farmUuid: item.farmUuid,
        fieldUuid: item.uuid,
      }));
    } else if (item.isLeaf) {
      const { uuid, type } = item;

      dispatch(toggleAsset(uuid, type));
    }
  };

  return (
    <ScrollContainer classes={{ root: 'panel-export' }}>
      <Tree
        items={assetsTree}
        onClick={handleAssetClick}
      />
    </ScrollContainer>
  );
};

export default DownloadFilesPanel;

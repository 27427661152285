import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Loading from '../../../../../../components/Loading';
import GeneralPopup from '../../../../../../components/Popups/GeneralPopup';
import useDataLayersMap from '../../../../../../hooks/useDataLayersMap';

import Panel from './Panel';
import ToolsPanel from './ToolsPanel';

import './index.scss';

const Map = React.lazy(() => import('./Map'));

const AddDataLayersPopup = ({
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState();

  const {
    onRawProcessedClick,
    onViewTypeControlChange,
    ...mapProps
  } = useDataLayersMap(selectedItem, {
    isCompareView: true,
  });

  const handleAssetItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <GeneralPopup
      title={t('compare-layers.title')}
      classes={{
        paper: 'compare-layers-popup',
      }}
      withActions={false}
      onCancel={onCancel}
    >
      <ToolsPanel key="tools-panel" />
      <Panel
        key="panel"
        selectedItem={selectedItem}
        onAssetItemClick={handleAssetItemClick}
        onRawProcessedClick={onRawProcessedClick}
        onViewTypeControlChange={onViewTypeControlChange}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
      <Suspense
        key="map"
        fallback={<Loading />}
      >
        <Map
          {...mapProps}
          selectedItem={selectedItem}
          onViewTypeControlChange={onViewTypeControlChange}
          onRawProcessedClick={onRawProcessedClick}
          onCancel={onCancel}
        />
      </Suspense>
    </GeneralPopup>
  );
};

export default AddDataLayersPopup;

import { createSlice } from '@reduxjs/toolkit';

import { TABS } from './helpers/constants/ui';

const initialState = {
  selectedFarms: [],
  selectionMode: false,
  activeTab: TABS.ownFarms,
  ownFarmsQuery: '',
  sharedFarmsQuery: '',
};

export const userFarmsSlice = createSlice({
  name: 'userFarms',
  initialState,
  reducers: {
    toggleSelectionMode(state) {
      state.selectionMode = !state.selectionMode;
      state.selectedFarms = [];
    },
    changeActiveTab(state, action) {
      state.activeTab = action.payload;
    },
    changeOwnFarmsQuery(state, action) {
      state.ownFarmsQuery = action.payload;
    },
    changeSharedFarmsQuery(state, action) {
      state.sharedFarmsQuery = action.payload;
    },
    toggleFarmSelect(state, action) {
      const selectedSet = new Set(state.selectedFarms);

      if (selectedSet.has(action.payload)) {
        selectedSet.delete(action.payload);
      } else {
        selectedSet.add(action.payload);
      }

      state.selectedFarms = Array.from(selectedSet);
    },
    selectFarms(state, action) {
      state.selectedFarms = action.payload;
    },
  },
});

export const {
  changeActiveTab,
  toggleSelectionMode,
  changeOwnFarmsQuery,
  changeSharedFarmsQuery,
  toggleFarmSelect,
  selectFarms,
} = userFarmsSlice.actions;

export default userFarmsSlice.reducer;

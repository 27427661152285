import React from 'react';
import i18n from 'i18next';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

// TODO: refactor to avoid passing functions to store

export const getPdfReportSuccessActions = (handler: () => void) => {
  return [
    {
      title: i18n.t('general.controls.open'),
      endIcon: <OpenInNewIcon fontSize="small" />,
      handler,
    },
  ];
};

export const getJdProfileDisconnectWarningActions = (url: string, handler: () => void) => {
  return [
    {
      title: i18n.t('integrations.marketplace.connect'),
      href: url,
      handler,
    },
  ];
};

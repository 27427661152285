import { updateFeatures } from '../../../../../helpers/analysis';
import { convertStringToNumber } from '../../../../../helpers/markup';

export const prepareFeatures = (features) => {
  return updateFeatures(
    features || [],
    (attributes) => {
      return {
        ...attributes,
        ratesHeaders: attributes.ratesHeaders.map((header) => header.trim()),
        rates: attributes.rates.map((rate) => convertStringToNumber(rate)),
        ratesPrices: attributes.ratesPrices.map((price) => convertStringToNumber(price)),
      };
    },
  );
};

import React, { ReactNode } from 'react';
import SinglePanelContainer from '../../../../../components/Layout/SinglePanelContainer';

import './index.scss';

export default function PanelLayout({
  children,
}: { children: ReactNode }) {
  return (
    <SinglePanelContainer className="integrations-panel-layout">
      {children}
    </SinglePanelContainer>
  );
}

import React from 'react';

import { warningNotify } from '../../../../notifications/helpers/functions/notify';
import AreaLimitMessage from '../../containers/AreaLimitMessage';

export const displayAreaLimitNotification = () => {
  warningNotify({
    messageElement: <AreaLimitMessage />,
  });
};

import {
  getRootLink,
  getSearchParam,
  PAGES_ROOTS,
} from '../../../../../helpers/navigation';
import { View } from '../constants/authentication';
import { SearchParam } from '../constants/navigation';

export const getDefaultRoute = () => {
  return getRootLink(PAGES_ROOTS.homepage);
};

export const getCode = (search: string) => {
  return getSearchParam(search, SearchParam.code);
};

export const getEmail = (search: string) => {
  return getSearchParam(search, SearchParam.email);
};

export const getRedirectedFrom = (search: string) => {
  return getSearchParam(search, SearchParam.redirectedFrom);
};

export const buildRedirectedFrom = (value: string) => {
  return `?${SearchParam.redirectedFrom}=${value}`;
};

export const isSignUpRelatedView = (view:View) => {
  return view === View.signUp
    || view === View.signUpSent
    || view === View.signUpConfirm;
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { selectPermissionedSmartSampling } from '../../../features/user/userSelectors';
import {
  getRootLink,
  PAGES_ROOTS,
} from '../../../helpers/navigation';

const SmartSamplingRedirector = ({
  children,
}) => {
  const isRestricted = !useSelector(selectPermissionedSmartSampling);

  if (isRestricted) {
    return (
      <Redirect to={getRootLink(PAGES_ROOTS.fields)} />
    );
  }

  return children;
};

export default SmartSamplingRedirector;

import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import SaveEquationPopup from '../../../components/Popups/SaveEquation';
import { updateEquation } from '../../../equationsSlice';
import { Equation } from '../../../types/equation';
import { useAppDispatch } from '../../../../../app/store/helpers/functions';

export default function UpdateEquationPopup({
  equation,
  onCancel = () => {},
  onConfirm = () => {},
}: {
  equation: Equation,
  onCancel: () => void,
  onConfirm: () => void,
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const initialTitle = equation.title;
  const initialDescription = equation.description || '';
  const initialSourceUrl = equation.sourceUrl || '';
  const [inProgress, setInProgress] = useState(false);

  const handleSaveClick = async (title: string, description: string, sourceUrl: string) => {
    setInProgress(true);

    try {
      await dispatch(updateEquation({
        equation,
        update: {
          title: title.trim(),
          description: description.trim(),
          sourceUrl: sourceUrl.trim(),
        },
      })).unwrap();

      onConfirm();
    } catch {
      setInProgress(false);
    }
  };

  return (
    <SaveEquationPopup
      initialValues={{
        title: initialTitle,
        description: initialDescription,
        sourceUrl: initialSourceUrl,
      }}
      popup={{
        title: t('general.popups.update-equation.title'),
        description: (
          <Trans i18nKey={'general.popups.update-equation.description'}>
            Text <span className="save-equation-popup__content-text_highlighted">{{ equationTitle: equation.title }}</span> text.
          </Trans>
        ),
      }}
      inProgress={inProgress}
      onConfirm={handleSaveClick}
      onCancel={onCancel}
    />
  );
}

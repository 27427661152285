import localforage from 'localforage';

import { STORE_NAMES_ARRAY } from '../../../../helpers/constants/keyValueBrowserStore';
import { getKeyValueDBName } from '../../../../helpers/functions/utils/appConfig';

class KeyValueBrowserStore {
  static getInstance(dbName, storeNames) {
    if (!this.instance) {
      this.instance = new KeyValueBrowserStore(dbName, storeNames);
    }

    return this.instance;
  }

  constructor(dbName, storeNames = []) {
    this.stores = storeNames.reduce((acc, storeName) => {
      acc[storeName] = localforage.createInstance({
        name: dbName,
        storeName,
      });

      return acc;
    }, {});
  }

  getItem(storeName, key) {
    return this.stores[storeName].getItem(key);
  }

  setItem(storeName, key, value) {
    return this.stores[storeName].setItem(key, value);
  }

  removeItem(storeName, key) {
    return this.stores[storeName].removeItem(key);
  }

  drop(storeName) {
    return this.stores[storeName].dropInstance();
  }
}

const instance = KeyValueBrowserStore.getInstance(getKeyValueDBName(), STORE_NAMES_ARRAY);

export default {
  getItem: instance.getItem.bind(instance),
  setItem: instance.setItem.bind(instance),
  removeItem: instance.removeItem.bind(instance),
  drop: instance.drop.bind(instance),
};

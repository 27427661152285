import { reset } from '../../createMultiLayerAnalysisSlice';
import { getSteps } from '../../helpers/functions/steps';
import { useAppDispatch } from '../../../../../app/store/helpers/functions';
import useStepComponent from '../../../zonesOps/hooks/useStepComponent';

export default function StepperCreateMultiLayerAnalysis() {
  const dispatch = useAppDispatch();

  const stepComponent = useStepComponent({
    getStepsFn: getSteps,
    resetFn: () => {
      dispatch(reset());
    },
  });

  return stepComponent;
}

import { getType } from '@turf/invariant';
import { featureCollection, multiPolygon } from '@turf/helpers';
import type {
  Feature,
  FeatureCollection,
  MultiPolygon,
  Position,
} from '@turf/helpers';

type Features = Feature<{ coordinates: Position[][][] }>[];

export const isFeatureTypesCorrect = (features: Features = []): boolean => {
  const hasCorrectFeatureTypes = features.every((feature) => {
    const featureType = getType(feature);

    return featureType === 'Polygon' || featureType === 'MultiPolygon';
  });

  return features.length !== 0 && hasCorrectFeatureTypes;
};

export const prepareBoundaryGeojson = (features: Features): string => {
  const geojson: MultiPolygon = {
    coordinates: [],
    type: 'MultiPolygon',
  };

  features.forEach(((feature) => {
    geojson.coordinates.push(...feature.geometry.coordinates);
  }));

  return JSON.stringify(geojson);
};

export const transformBoundaryFeatures = (features: Features): FeatureCollection<MultiPolygon> => {
  const liftedFeatures: Feature<MultiPolygon>[] = [];

  features.forEach(({ geometry, properties }) => {
    geometry.coordinates.forEach((coords) => {
      liftedFeatures.push(multiPolygon([coords], properties));
    });
  });

  return featureCollection(liftedFeatures);
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  getSubscriptions,
  createFieldOperationSubscription,
  disableSubscription,
  enableSubscription,
  deleteSubscription,
} from './jdIntegrationAPI';
import { ISubscriptionJohnDeere } from './types/subscriptionJohnDeere';
import { JohnDeereOperationType } from './helpers/constants';

interface JDIntegrationState {
  subscription: {
    subscriptions: ISubscriptionJohnDeere[],
    isLoading: boolean;
    isExecutingOperation: boolean;
  };
}

const initialState: JDIntegrationState = {
  subscription: {
    subscriptions: [],
    isLoading: false,
    isExecutingOperation: false,
  },
};

export const fetchSubscriptionsJohnDeere = createAsyncThunk(
  'jdIntegration/getSubscriptions',
  () => {
    return getSubscriptions();
  },
);

export const createFieldOperationSubscriptionJohnDeere = createAsyncThunk(
  'jdIntegration/createFieldOperationSubscription',
  ({
    orgIds,
    fieldOperationTypes,
  }: {
    orgIds: string[],
    fieldOperationTypes: JohnDeereOperationType[],
  }) => {
    return createFieldOperationSubscription({ orgIds, fieldOperationTypes });
  },
);

export const disableSubscriptionJohnDeere = createAsyncThunk(
  'jdIntegration/disableSubscription',
  ({
    id,
  }: { id: string }) => {
    return disableSubscription({ id });
  },
);

export const enableSubscriptionJohnDeere = createAsyncThunk(
  'jdIntegration/enableSubscription',
  ({
    id,
  }: { id: string }) => {
    return enableSubscription({ id });
  },
);

export const deleteSubscriptionJohnDeere = createAsyncThunk(
  'jdIntegration/deleteSubscription',
  ({
    id,
  }: { id: string }) => {
    return deleteSubscription({ id });
  },
);

export const jdIntegrationSlice = createSlice({
  name: 'jdIntegration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptionsJohnDeere.pending, (state) => {
        state.subscription.isLoading = true;
        state.subscription.subscriptions = [];
      })
      .addCase(fetchSubscriptionsJohnDeere.fulfilled, (state, action) => {
        state.subscription.isLoading = false;
        state.subscription.subscriptions = action.payload.subscriptions;
      })
      .addCase(createFieldOperationSubscriptionJohnDeere.fulfilled, (state, action) => {
        state.subscription.isExecutingOperation = false;
        state.subscription.subscriptions.push(action.payload);
      })
      .addMatcher(({ type }) => {
        return type === createFieldOperationSubscriptionJohnDeere.pending.type
        || type === disableSubscriptionJohnDeere.pending.type
        || type === enableSubscriptionJohnDeere.pending.type
        || type === deleteSubscriptionJohnDeere.pending.type;
      }, (state) => {
        state.subscription.isExecutingOperation = true;
      })
      .addMatcher(({ type }) => {
        return type === disableSubscriptionJohnDeere.fulfilled.type
        || type === enableSubscriptionJohnDeere.fulfilled.type
        || type === deleteSubscriptionJohnDeere.fulfilled.type;
      }, (state, action) => {
        state.subscription.isExecutingOperation = false;

        const subscriptions = action.payload.status === 'Terminated'
          ? state.subscription.subscriptions.filter((subscription) => (subscription.id !== action.payload.id))
          : state.subscription.subscriptions.map((subscription) => (
            subscription.id === action.payload.id ? action.payload : subscription));
        state.subscription.subscriptions = [...subscriptions];
      });
  },
});

export default jdIntegrationSlice.reducer;

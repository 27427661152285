import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import { isNewEquation } from '../../../../equations/helpers/functions/equations';

import './index.scss';

export default function FormulaEditorInstructions({
  uuid,
}: {
  uuid: string,
}) {
  const { t } = useTranslation();

  return (
    <div className="formula-editor-instructions">
      {
        isNewEquation(uuid)
          ? (
            <>
              <Typography className="formula-editor-instructions__secondary formula-editor-instructions__header">
                <Trans i18nKey="zones-ops.equation-based.steps.4.description.create-formula">
                  Text
                  <a
                    className="link"
                    target="_blank"
                    href="https://docs.python.org/3/tutorial/"
                  >
                    link
                  </a>
                  text
                </Trans>
              </Typography>
              <Typography className="formula-editor-instructions__title">
                {t('zones-ops.equation-based.steps.4.panel.description.create-formula.title')}
              </Typography>
              <ol className="formula-editor-instructions__list">
                <li>{t('zones-ops.equation-based.steps.4.panel.description.create-formula.1')}</li>
                <li>{t('zones-ops.equation-based.steps.4.panel.description.create-formula.2')}</li>
                <li>{t('zones-ops.equation-based.steps.4.panel.description.create-formula.3')}</li>
              </ol>
            </>
          )
          : (
            <>
              <Typography className="formula-editor-instructions__secondary formula-editor-instructions__header">
                <Trans i18nKey="zones-ops.equation-based.steps.4.description.adjustment">
                  Text
                  <a
                    className="link"
                    target="_blank"
                    href="https://docs.python.org/3/tutorial/"
                  >
                    link
                  </a>
                  text
                </Trans>
              </Typography>
              <Typography className="formula-editor-instructions__secondary">
                {t('zones-ops.equation-based.steps.4.panel.description.adjustment.1')}
              </Typography>
              <Typography className="formula-editor-instructions__secondary">
                {t('zones-ops.equation-based.steps.4.panel.description.adjustment.2')}
              </Typography>
            </>
          )
      }
    </div>
  );
}

import React, {
  Fragment,
  useState,
  useMemo,
} from 'react';
import {
  useTranslation,
} from 'react-i18next';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Stepper from '../../../../../../../components/Stepper';
import AutocompleteSingle from '../../../../../../../components/AutocompleteSingle';
import ComboBox from '../../../../../../../components/ComboBox';
import Table from '../../../FieldsTable';
import {
  fetchFieldsJohnDeereWithNewOrganizationsIds,
  fetchFieldsJohnDeereWithNewOrganizationsIdsAndFieldName,
} from '../../../../../../jdFields/jdFieldsSlice';
import {
  nextStepImportFromJohnDeere,
  importAllFromJohnDeere,
  importFromJohnDeere,
} from '../../../../jdImportSlice';
import { openPopup } from '../../../../../popups/popupsSlice';
import {
  selectFieldsIsEmpty,
  selectFieldsIsLoading,
  selectFieldsFilterFieldName,
  selectFieldsHasAvailable,
} from '../../../../../../jdFields/jdFieldsSelectors';
import {
  selectImportSelectedOrganizationsIds,
  selectImportComboBoxSelectedOrganizations,
  selectImportSelectedFields,
  selectImportHasSelectedFields,
  selectImportCountSelectedFields,
  selectImportIsLoading,
} from '../../../../jdImportSelectors';
import { getSteps } from '../common';
import useDidMount from '../../../../../../../hooks/useDidMount';
import {
  getRootLink,
  PAGES_ROOTS,
} from '../../../../../../../helpers/navigation';
import { getSum } from '../../../../../../../helpers/functions/utils/number';
import { FilterParam } from '../../../../../operationsWorkflow/helpers/constants/filters';
import {
  selectMaxArea,
  selectTotalArea,
  selectAreaUnit,
} from '../../../../../../user/userSelectors';
import { isValidSynchronizedFieldJohnDeereAccount } from '../../../../../../jdFields/helpers/functions';
import { getUserUnitArea } from '../../../../../../../helpers';

import './index.scss';

const StepperSelectData = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const isEmptyFields = useSelector(selectFieldsIsEmpty);
  const isLoadingFields = useSelector(selectFieldsIsLoading);
  const filterFieldName = useSelector(selectFieldsFilterFieldName);
  const hasAvailableFields = useSelector(selectFieldsHasAvailable);
  const areaUnit = useSelector(selectAreaUnit);
  const maxArea = useSelector(selectMaxArea);
  const totalArea = useSelector(selectTotalArea);

  const selectedOrganizations = useSelector(selectImportSelectedOrganizationsIds);
  const comboBoxSelectedOrganizations = useSelector(selectImportComboBoxSelectedOrganizations);
  const selectedFields = useSelector(selectImportSelectedFields);
  const hasSelectedFields = useSelector(selectImportHasSelectedFields);
  const countSelectedFields = useSelector(selectImportCountSelectedFields);
  const isLoadingImport = useSelector(selectImportIsLoading);

  const isExecutingOperation = isLoadingFields || isLoadingImport;
  const isAvailableOperation = hasAvailableFields || hasSelectedFields;

  const [filterOrganization, setFilterOrganization] = useState(null);

  const steps = getSteps();

  const usedArea = useMemo(() => {
    const notImportedFields = selectedFields.filter((field) => {
      return !isValidSynchronizedFieldJohnDeereAccount(field.synchronized);
    });

    const notImportedFieldsArea = notImportedFields.reduce((acc, field) => {
      return getSum(acc, getUserUnitArea(field.area, areaUnit, field.areaUnit));
    }, 0);

    return totalArea + notImportedFieldsArea;
  }, [areaUnit, totalArea, selectedFields]);

  const onOrganizationFilterChange = (_event, organization) => {
    setFilterOrganization(organization);
    if (organization) {
      dispatch(fetchFieldsJohnDeereWithNewOrganizationsIds({ organizationsIds: [organization.id] }));
    } else {
      dispatch(fetchFieldsJohnDeereWithNewOrganizationsIds({ organizationsIds: selectedOrganizations }));
    }
  };

  const onFieldNameFilterChange = (newFilterFieldName) => {
    if (filterOrganization) {
      dispatch(fetchFieldsJohnDeereWithNewOrganizationsIdsAndFieldName({
        organizationsIds: [filterOrganization.id],
        fieldName: newFilterFieldName,
      }));
    } else {
      dispatch(fetchFieldsJohnDeereWithNewOrganizationsIdsAndFieldName({
        organizationsIds: selectedOrganizations,
        fieldName: newFilterFieldName,
      }));
    }
  };

  const onClickNext = () => {
    dispatch(openPopup({
      type: 'import-settings-john-deere',
      usedArea,
      maxArea,
      onConfirm: (importSettings) => {
        const trackLink = `${getRootLink(PAGES_ROOTS.operations)}?${FilterParam.timeRange}=12`;
        if (hasSelectedFields) {
          dispatch(importFromJohnDeere({
            fields: selectedFields.map(({ id, orgId }) => ({ id, orgId })),
            importType: importSettings.importType,
            fieldOperationTypes: importSettings.filterType,
            cropSeasons: importSettings.filterPeriod,
          })).then(() => {
            history.push(trackLink);
          });
        } else {
          dispatch(importAllFromJohnDeere({
            organizationsIds: selectedOrganizations,
            fieldName: filterFieldName,
            importType: importSettings.importType,
            fieldOperationTypes: importSettings.filterType,
            cropSeasons: importSettings.filterPeriod,
          })).then(() => {
            history.push(trackLink);
          });
        }
      },
    }));
  };

  const onClickBack = () => {
    dispatch(nextStepImportFromJohnDeere({ step: 'selectOrganizations' }));
  };

  useDidMount(() => {
    if (isEmptyFields) {
      dispatch(fetchFieldsJohnDeereWithNewOrganizationsIdsAndFieldName({
        organizationsIds: selectedOrganizations,
        fieldName: filterFieldName,
      }));
    }
  });

  return (
    <Stepper
      activeStep={1}
      steps={steps}
      backDisabled={isExecutingOperation}
      nextDisabled={isExecutingOperation || !isAvailableOperation}
      nextLabel={hasSelectedFields
        ? t('upload-data-john-deere.steps.selectData.buttons.import-selected', { count: countSelectedFields })
        : t('upload-data-john-deere.steps.selectData.buttons.import-all')}
      onClickNext={onClickNext}
      onClickBack={onClickBack}
    >
      <Fragment key="panel">
        <div className="john-deere__authorized-nav">
          <ComboBox
            disabled={isExecutingOperation}
            title={t('upload-data-john-deere.steps.selectData.filter.organization.title')}
            disableCloseOnSelect={false}
            placeholder={t('general.controls.select')}
            options={comboBoxSelectedOrganizations}
            classes={{
              root: 'john-deere__authorized-nav-organization',
            }}
            getOptionSelected={(option, value) => (option.id === value.id)}
            value={filterOrganization}
            onChange={onOrganizationFilterChange}
          />
          <AutocompleteSingle
            disabled={isExecutingOperation}
            placeholder={t('upload-data-john-deere.steps.selectData.filter.organization.search')}
            classes={{
              root: 'john-deere__authorized-nav-field-name',
            }}
            value={filterFieldName}
            onChange={onFieldNameFilterChange}
          />
        </div>
        { isExecutingOperation && <div className="john-deere__authorized-loader"><CircularProgress /></div>}
        { !isExecutingOperation && (!isEmptyFields
          ? <Table />
          : (
            <div className="table__list-empty">
              <span>{t('upload-data-john-deere.steps.selectData.table.no-fields')}</span>
            </div>
          )
        )}
      </Fragment>
    </Stepper>
  );
};

export default StepperSelectData;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import PopupHeader from '../../../../../components/Popups/PopupHeader';
import { selectTab } from '../../cleanCalibrateSelectors';
import { resetUserInput, setTab } from '../../cleanCalibrateSlice';
import InfoPanel from '../../components/InfoPanel';
import ProcessingTypesTabs from '../../components/ProcessingTypesTabs';
import { ProcessingType } from '../../helpers/constants/ui';
import useDidMount from '../../../../../hooks/useDidMount';

import './index.scss';

const TAB_TO_INFO_TEXT_MAP = {
  [ProcessingType.fast]: 'clean-calibrate.yield-popup.tabs.fast.processing-type.description',
  [ProcessingType.clean]: 'clean-calibrate.yield-popup.tabs.clean.processing-type.description',
  [ProcessingType.calibrateClean]: 'clean-calibrate.yield-popup.tabs.calibrate-clean.processing-type.description',
  [ProcessingType.calibrate]: 'clean-calibrate.yield-popup.tabs.calibrate.processing-type.description',
};

export default function ProcessingTypesStepContent({
  onCancel,
}: {
  onCancel: () => void,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const tab = useSelector(selectTab);
  const infoText = t(TAB_TO_INFO_TEXT_MAP[tab]);

  const handleTabChange = (v: ProcessingType) => {
    dispatch(setTab(v));
  };

  useDidMount(() => {
    dispatch(resetUserInput());
  });

  return (
    <>
      <PopupHeader
        classes={{
          root: 'processing-types-step-content__header',
        }}
        title={t('clean-calibrate.yield-popup.clean-calibrate-title')}
        onCancel={onCancel}
      />
      <DialogContent className="processing-types-step-content">
        <DialogContentText className="processing-types-step-content__content-description">
          {t('clean-calibrate.yield-popup.select-processing-type')}
        </DialogContentText>
        <ProcessingTypesTabs
          tab={tab}
          onTabChange={handleTabChange}
        />
        <InfoPanel>
          {infoText}
        </InfoPanel>
      </DialogContent>
    </>
  );
}

import { captureException as captureExceptionToSentry } from '@sentry/react';

export class CustomError extends Error {
  constructor(message: string, options?: ErrorOptions) {
    super(message, options);
    this.name = message; // Set error name to display the issue title in Sentry
  }
}

const hasCause = (error: unknown): error is { cause: unknown } => {
  return typeof error === 'object' && error != null && 'cause' in error;
};

const hasErrors = (error: unknown): error is { errors: unknown } => {
  return typeof error === 'object' && error != null && 'errors' in error;
};

const hasMessage = (error: unknown): error is { message: string } => {
  return typeof error === 'object' && error != null && 'message' in error;
};

const getErrorMessage = (error: unknown): string => {
  return hasMessage(error) ? error.message : '';
};

export const captureException = ({
  message = '',
  error,
}: {
  message?: string,
  error: unknown,
}): void => {
  console.error(message || getErrorMessage(error), error);

  if (hasCause(error)) {
    console.error('Cause:', error.cause);
  }

  captureExceptionToSentry(error, (scope) => {
    let errorData;

    if (hasErrors(error)) {
      errorData = JSON.stringify(error.errors);
    } else if (hasCause(error)) {
      errorData = JSON.stringify(error.cause);
    } else {
      errorData = error;
    }

    return scope.setExtra('Error Object', errorData);
  });
};

import { MapAttributes } from '../../types/mapAttributes';
import type { TransformedAsset } from '../../../../../helpers/types';
import {
  isAsAppliedDataset,
  isEquationMap,
  isSatelliteImage,
  isSoilDataset,
  isTopographyMap,
  isYieldDataset,
} from '../../../../../helpers/functions/entities/assets';

export const prepareAssetMapAttributes = (
  item: TransformedAsset,
  {
    attribute,
    rawProcessed,
  }: {
    attribute?: string,
    rawProcessed?: string,
  },
): MapAttributes[keyof MapAttributes] => {
  let result;

  if (
    isSatelliteImage(item)
    || isTopographyMap(item)
  ) {
    result = {
      viewType: attribute,
    };
  } else if (
    isSoilDataset(item)
    || isYieldDataset(item)
    || isAsAppliedDataset(item)
  ) {
    result = {
      ...(rawProcessed && { rawProcessed }),
      ...(attribute && { attribute }),
    };
  } else if (isEquationMap(item)) {
    result = {
      geoMap: attribute,
    };
  }

  return result;
};

import Area14Icon from '../../../components/Icons/area14.svg';
import Area12Icon from '../../../components/Icons/area12.svg';
import Area34Icon from '../../../components/Icons/area34.svg';
import AreaFullIcon from '../../../components/Icons/areaFull.svg';

export const getFieldCoverageIcon = (
  fieldCoverage: number,
): React.FC<React.SVGProps<SVGSVGElement>> => {
  let AreaIcon;

  if (fieldCoverage < 0.25) {
    AreaIcon = Area14Icon;
  } else if (fieldCoverage >= 0.25 && fieldCoverage < 0.5) {
    AreaIcon = Area12Icon;
  } else if (fieldCoverage >= 0.5 && fieldCoverage < 0.75) {
    AreaIcon = Area34Icon;
  } else {
    AreaIcon = AreaFullIcon;
  }

  return AreaIcon;
};

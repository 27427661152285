import React, {
  Fragment,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Typography from '@material-ui/core/Typography';

import { getAreaUnitLabel } from '../../../../helpers';
import {
  calculateTotalProductVolumes,
  calculateCostPerProduct,
  calculateTotalProductCost,
  getRatesData,
  PURPOSES_I18N_KEY_LABEL_MAP,
} from '../../../../helpers/analysis';
import { PRODUCT_UNIT_TO_I18N_LABEL } from '../../../../helpers/constants/units/productUnit';

import './index.scss';

const getDefaultHeaders = (t, areaUnit) => [
  {
    id: 'color',
    label: t('zones-ops.multi-layer.steps.4.results-table.color'),
    align: 'left',
  },
  {
    id: 'id',
    label: 'ID',
    align: 'right',
  },
  {
    id: 'areaAbs',
    label: getAreaUnitLabel(areaUnit),
    align: 'right',
  },
];

const getFixedTableHead = (
  t,
  areaUnit,
) => (
  <TableRow className="rates-table__header-row">
    {
      getDefaultHeaders(t, areaUnit).map((headCell) => {
        return (
          <TableCell
            key={headCell.id}
            className="rates-table__cell rates-table__text_dark"
            align={headCell.align}
          >
            {headCell.label}
          </TableCell>
        );
      })
    }
  </TableRow>
);

const getScrollableTableHead = (ratesHeaders = []) => (
  <TableRow className="rates-table__header-row">
    {
      ratesHeaders.map((header, rateIndex) => {
        return (
          <TableCell
            key={`rate_${rateIndex}`}
            className="rates-table__cell rates-table__text_dark"
            align='right'
          >
            {header}
          </TableCell>
        );
      })
    }
  </TableRow>
);

const getFixedTableBody = ({
  data,
  colors,
  totalArea,
}) => {
  return data.features.map((entry, featureIndex) => {
    const {
      zone,
      attributes: {
        area,
      },
    } = entry.properties;

    return (
      <TableRow
        key={`fixed_${featureIndex}`}
      >
        <TableCell
          className="rates-table__cell"
          align='left'
        >
          <div
            className="rates-table__color"
            style={{ backgroundColor: colors[zone - 1] }}
          >
          </div>
        </TableCell>
        <TableCell
          className="rates-table__cell"
          align='right'
        >
          {zone}
        </TableCell>
        <TableCell
          align="right"
          className="rates-table__cell"
        >
          {area.toFixed(2)}
          <span className="rates-table__text_secondary">{` ${((100 * area) / totalArea).toFixed(2)}%`}</span>
        </TableCell>
      </TableRow>
    );
  });
};

const getScrollableTableBody = (data) => {
  return data.features.map((entry, featureIndex) => {
    const {
      attributes: {
        rates = [],
      },
    } = entry.properties;

    return (
      <TableRow
        key={`scrollable_${featureIndex}`}
      >
        {
          rates.map((rate, rateIndex) => {
            return (
              <TableCell
                key={rateIndex}
                align="right"
                className="rates-table__cell"
              >
                {rate}
              </TableCell>
            );
          })
        }
      </TableRow>
    );
  });
};

const getTableTitleFooterRow = ({
  t,
  title,
  index,
}) => {
  return (
    <TableRow
      className="rates-table__footer-row"
      key={`data_footer_row_${index}`}
    >
      <TableCell
        colSpan={3}
        classes={{
          root: 'rates-table__cell rates-table__text_dark',
        }}
        align='left'
      >
        {t(title)}
      </TableCell>
    </TableRow>
  );
};

const getTableDataFooterRow = ({
  data,
  getCellContent,
}) => {
  return (
    <TableRow
      className="rates-table__footer-row"
    >
      {
        data.map((value, index) => {
          return (
            <TableCell
              key={index}
              className="rates-table__cell rates-table__text_dark"
              align="right"
            >
              {
                getCellContent
                  ? getCellContent(value, index)
                  : value.toFixed(2)
              }
            </TableCell>
          );
        })
      }
    </TableRow>
  );
};

const tableFooterTitles = [
  'zones-map.rates.total-product-volume',
  'zones-map.rates.average-product-rate',
  'general.labels.unit',
  'zones-map.rates.price-per-unit',
  'zones-map.rates.cost-per-product',
  'zones-map.rates.total-product-cost',
];

const getFixedTableFooter = (t) => (
  <Fragment>
    {
      tableFooterTitles.map((title, index) => getTableTitleFooterRow({
        t,
        title,
        index,
      }))
    }
  </Fragment>
);

const getScrollableTableFooter = ({
  t,
  data,
  totalArea,
}) => {
  const {
    ratesPrices,
    ratesUnits,
  } = data.features[0].properties.attributes;
  const totalProductVolumes = calculateTotalProductVolumes(data);
  const averageProductRates = totalProductVolumes.map((total) => {
    return total / totalArea;
  });
  const costsPerProduct = calculateCostPerProduct(ratesPrices, totalProductVolumes);
  const totalProductCost = calculateTotalProductCost(costsPerProduct);

  return (
    <Fragment>
      {
        getTableDataFooterRow({
          data: totalProductVolumes,
        })
      }
      {
        getTableDataFooterRow({
          data: averageProductRates,
        })
      }
      {
        getTableDataFooterRow({
          data: ratesUnits,
          getCellContent: (value) => {
            return t(PRODUCT_UNIT_TO_I18N_LABEL[value]);
          },
        })
      }
      {
        getTableDataFooterRow({
          data: ratesPrices,
        })
      }
      {
        getTableDataFooterRow({
          data: costsPerProduct,
        })
      }
      <TableRow
        classes={{
          root: 'rates-table__footer-row',
        }}
      >
        <TableCell
          className="rates-table__cell rates-table__text_dark"
          align="right"
          colSpan={totalProductVolumes.length}
        >
          {(totalProductCost && totalProductCost.toFixed(2)) || 0}
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

const RatesTable = ({
  areaUnit,
  attributes,
  colors,
  totalArea,
  type,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState(attributes);

  useEffect(() => {
    setData(attributes);
  }, [attributes]);

  if (!data) {
    return null;
  }

  const ratesHeaders = getRatesData(data);

  return (
    <>
      <Typography className="rates-table__header">
        {
          `${t('zones-map.tabs.rates')}: ${t(PURPOSES_I18N_KEY_LABEL_MAP[type])?.toLowerCase()}`
        }
      </Typography>
      <div className="rates-table" >
        <div className="rates-table_fixed">
          <Table>
            <TableHead>
              {
                getFixedTableHead(t, areaUnit)
              }
            </TableHead>
            <TableBody>
              {
                getFixedTableBody({
                  data,
                  colors,
                  totalArea,
                })
              }
            </TableBody>
            <TableFooter>
              {
                getFixedTableFooter(t)
              }
            </TableFooter>
          </Table>
        </div>
        <div className="rates-table_scrollable">
          <Table>
            <TableHead>
              {
                getScrollableTableHead(ratesHeaders)
              }
            </TableHead>
            <TableBody>
              {
                getScrollableTableBody(data)
              }
            </TableBody>
            <TableFooter>
              {
                getScrollableTableFooter({
                  t,
                  data,
                  totalArea,
                })
              }
            </TableFooter>
          </Table>
        </div>
      </div>
    </>
  );
};

export default RatesTable;

export enum OperationType {
  soilDataset = 'soil-dataset',
  yieldDataset = 'yield-dataset',
  asAppliedDataset = 'as-applied-dataset',
  elevation = 'elevation',
  fieldSatelliteImage = 'field-satellite-image',
  planetMask = 'planet-mask',
  planetImage = 'planet-image',
  vectorAnalysisMap = 'vector-analysis-map',
  equationMap = 'equation-map',
  threeDimensionalMap = 'three-dimensional-map',
}

export const OPERATION_TYPES = Object.values(OperationType);

export enum OperationStatus {
  all = 'all',
  inProgress = 'inProgress',
  error = 'error',
  done = 'done',
  ignored = 'ignored',
}

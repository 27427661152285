import React, {
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { BackgroundLayer } from '../../../types/backgroundLayer';
import {
  selectSatelliteLoading,
  selectVectorAnalysisMaps,
  selectBackgroundLayerAssets,
  selectField,
} from '../../../../../field/fieldSelectors';
import useDataLayersTree from '../../../../dataLayersView/hooks/useDataLayersTree';
import {
  ASSET_TYPE_TO_ASSET_GROUP,
  AssetGroupType,
  AssetType,
} from '../../../../../../helpers/constants/entities/asset';
import { getSelectedItem } from '../../../../../../helpers/components/legend';
import { TransformedAsset } from '../../../../../../helpers/types';

import './index.scss';

const ChangeBackgroundLayerPanel = ({
  backgroundUuid,
  onBackgroundChange,
} : {
  backgroundUuid?: string;
  onBackgroundChange: (background: BackgroundLayer) => void;
}) => {
  const field = useSelector(selectField);
  const backgroundItem = getSelectedItem(backgroundUuid, field);
  const fieldAssets = useSelector(selectBackgroundLayerAssets);
  const vectorAnalysisMaps = useSelector(selectVectorAnalysisMaps);
  const isSatelliteImagesLazyLoading = useSelector(selectSatelliteLoading);
  const backgroundItemAssetType = backgroundItem?._type;
  const [activeAssetGroup, setActiveAssetGroup] = useState(
    ASSET_TYPE_TO_ASSET_GROUP[backgroundItemAssetType as AssetType],
  );

  const onAssetItemClick = <T extends TransformedAsset>(type: AssetGroupType, item: T) => {
    if (item.uuid === backgroundUuid) {
      return;
    }

    onBackgroundChange({ uuid: item.uuid });
    setActiveAssetGroup(type);
  };

  const { dataLayersTreeComponent } = useDataLayersTree({
    assets: {
      ...fieldAssets,
      vectorAnalysisMaps,
    },
    collapsible: true,
    hasGroupPlaceholderAction: false,
    satelliteImagesLoading: isSatelliteImagesLazyLoading,
    selectedAssetUuid: backgroundUuid,
    selectedAssetGroupType: activeAssetGroup,
    onAssetNodeClick: onAssetItemClick,
  });

  return (
    <div className="change-background-layer-panel">
      {dataLayersTreeComponent}
    </div>
  );
};

export default ChangeBackgroundLayerPanel;

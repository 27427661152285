import { GeoMapType } from '../../types/satelliteImage';

export default function generateQuery(geoMapTypes: GeoMapType[], imageUuids: string[]): string {
  const fragments = geoMapTypes.map((type) => {
    const alias = `${type.toLowerCase()}SatelliteImages`;
    const imageUuidsString = imageUuids.map((uuid) => `"${uuid}"`).join(', ');

    return `
      ${alias}: satelliteImages(threshold: 0, geoMapType: ${type}, satelliteImageUuids: [${imageUuidsString}]) {
        satelliteImage {
          uuid
        }
        geoMaps {
          shortName
          url
          classes
          hexColorCodes
        }
      }
    `;
  }).join('\n');

  return `
    query getSatelliteImagesGeoMaps($farmUuids: [ID], $fieldUuids: [ID]) {
      getFarms(farmUuids: $farmUuids) {
        fields(fieldUuids: $fieldUuids) {
          ${fragments}
        }
      }
    }
  `;
}

import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { MapUpdateEntry } from '../../../types/ui';
import GeneralPopup from '../../../../../../components/Popups/GeneralPopup';
import Loading from '../../../../../../components/Loading';
import ToolsPanel from './ToolsPanel';
import Panel from './Panel';
import { selectClonePolygonsLoading } from '../../../drawManuallyWorkflowSelectors';
import ClonePolygonsActions from './Actions';

const Map = React.lazy(() => import('./Map'));

export default function ClonePolygonsPopup({
  onCancel,
  onConfirm,
}: {
  onCancel: () => void,
  onConfirm: (r: MapUpdateEntry | null) => void,
}) {
  const { t } = useTranslation();
  const loading = useSelector(selectClonePolygonsLoading);

  return (
    <GeneralPopup
      title={t('zones-ops.draw-manually.steps.3.clone-polygons.title')}
      loading={loading}
      onCancel={onCancel}
    >
      <ToolsPanel key="tools-panel" />
      <Panel key="panel" />
      <Suspense
        key="map"
        fallback={<Loading />}
      >
        <Map />
      </Suspense>
      <ClonePolygonsActions
        key="actions"
        onConfirm={onConfirm}
      />
    </GeneralPopup>
  );
}

import React from 'react';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';

import { getComponent } from '../../helpers/markup';

import './index.scss';

const PersistentPopup = ({
  initExpanded = true,
  children = [],
  classes = {},
  onClose = () => {},
}) => {
  const [expanded, setExpanded] = React.useState(initExpanded);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleCloseClick = (e) => {
    e.stopPropagation();
    onClose(e);
  };

  return (
    <div className={clsx('persistent-popup', classes.root)}>
      <Accordion
        expanded={expanded}
        classes={{
          root: clsx('persistent-popup__accordion', classes.accordion),
          expanded: clsx('persistent-popup__accordion_expanded', classes.accordionExpanded),
        }}
        onChange={handleExpandClick}
      >
        <AccordionSummary
          classes={{
            root: clsx('accordion__summary', classes.accordionSummary),
            expanded: clsx('accordion__summary_expanded', classes.accordionSummaryExpanded),
            content: clsx('accordion__summary-content', classes.accordionSummaryContent),
          }}
        >
          {getComponent('header', children)}
          <div className="accordion-summary__actions">
            <IconButton
              edge="end"
              classes={{
                root: clsx('accordion-summary__actions-button', classes.accordionSummaryExpandActionButton),
              }}
            >
              <ExpandMoreIcon
                fontSize="small"
                classes={{
                  root: 'accordion-summary__actions-button-expand-icon',
                }}
              />
            </IconButton>
            <IconButton
              edge="end"
              classes={{
                root: clsx('accordion-summary__actions-button', classes.accordionSummaryCloseActionButton),
              }}
              onClick={handleCloseClick}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: clsx('accordion__details', classes.accordionDetails),
          }}
        >
          {getComponent('content', children)}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PersistentPopup;

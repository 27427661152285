export enum OrderStatus {
  Draft = 'DRAFT',
  PackageOrdered = 'PACKAGE_ORDERED',
  InvoiceSent = 'INVOICE_SENT',
  InvoicePaid = 'INVOICE_PAID',
  Cancelled = 'CANCELLED',
}

export enum PricingPlan {
  Start = 'START', // deprecated
  Grow = 'GROW', // deprecated
  Expand = 'EXPAND', // deprecated
  Enterprise = 'ENTERPRISE',
  Trial = 'TRIAL', // deprecated
  FreeTrial = 'FreeTrial',
  AnnualSubscription = 'AnnualSubscription',
  MonthlyPayAsYouGo = 'MonthlyPayAsYouGo',
}

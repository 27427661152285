import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AuthenticationRedirector from '../router/redirectors/Authentication';
import UserDataRedirector from '../router/redirectors/UserData';
import PricingRedirector from '../router/redirectors/Pricing';
import Table from '../features/ui/zonesMaps/containers/Table';
import ToolsPanel from '../features/ui/zonesMaps/containers/ToolsPanel';
import TablePanel from '../features/ui/zonesMaps/containers/TablePanel';
import BaseTemplate from './BaseTemplate.jsx';
import { getDocumentTitle } from '../helpers';
import { selectSelectionMode } from '../features/ui/zonesMaps/zonesMapsSelectors';
import useDidMount from '../hooks/useDidMount';

const ZonesMaps = () => {
  const { t } = useTranslation();
  const selectionMode = useSelector(selectSelectionMode);

  useDidMount(() => {
    document.title = getDocumentTitle(t('general.navigation.zones-maps'));
  });

  return (
    <AuthenticationRedirector>
      <UserDataRedirector>
        <PricingRedirector>
          <BaseTemplate
            headerProps={{
              text: t('general.navigation.zones-maps'),
            }}
          >
            <ToolsPanel key="tools-panel" />
            { selectionMode && <TablePanel key="instrument-panel" /> }
            <Table key="panel" />
          </BaseTemplate>
        </PricingRedirector>
      </UserDataRedirector>
    </AuthenticationRedirector>
  );
};

export default ZonesMaps;

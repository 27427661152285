import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import TablePanel from '../../../../../components/TablePanel';
import Button from '../../../../../components/Button';
import { switchSelectionMode } from '../../zonesMapsSlice';
import { openExportZonesMapPopup } from '../../../../exportData/exportDataSlice';
import { selectSelectedZonesMaps } from '../../zonesMapsSelectors';
import { ExportType } from '../../../../exportData/helpers/constants/exportTypes';
import AmplitudeAnalytics from '../../../../../helpers/classes/amplitudeAnalytics';
import useCurrentPage from '../../../../../hooks/useCurrentPage';

import './index.scss';

const ZonesMapsTablePanel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const page = useCurrentPage();

  const selectedZonesMaps = useSelector(selectSelectedZonesMaps);

  const handleCancelClick = () => {
    dispatch(switchSelectionMode());
  };

  const handleExportClick = () => {
    dispatch(openExportZonesMapPopup({
      zonesMaps: selectedZonesMaps,
      onExport: (type) => {
        if (type === ExportType.exportToJDAsFiles) {
          AmplitudeAnalytics.trackBatchEntitiesExportedToJD({
            page,
            entityType: 'zonesMap',
            exportType: 'asFiles',
            entitiesAmount: selectedZonesMaps.length,
          });
        }
      },
    }));
  };

  return (
    <TablePanel
      elementsLeft={[
        <Typography
          key="description"
          classes={{
            root: 'zones-maps-table-panel__description',
          }}
        >
          {t('zones-maps.table-panel.description')}
        </Typography>,
      ]}
      elementsRight={[
        <Button
          key="cancel"
          variant="outlined"
          classes={{
            root: 'zones-maps-table-panel__button zones-maps-table-panel__button_cancel',
          }}
          onClick={handleCancelClick}
        >
          {t('general.controls.cancel')}
        </Button>,
        <Button
          key="export"
          variant="contained"
          color="primary"
          disabled={selectedZonesMaps.length === 0}
          classes={{
            root: 'zones-maps-table-panel__button',
          }}
          onClick={handleExportClick}
        >
          {t('export.export')}
        </Button>,
      ]}
      classes={{
        root: 'zones-maps-table-panel',
      }}
    />
  );
};

export default ZonesMapsTablePanel;

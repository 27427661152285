import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';

import { CONFIG } from './config';
import type { CalibrationType } from '../../../../../helpers/constants/ui';
import PopupHeader from '../../../../../../../../components/Popups/PopupHeader';
import {
  selectCalibrateSmoothWindowSize,
  selectCalibrationType,
} from '../../../../../cleanCalibrateSelectors';
import {
  setCalibrateSmoothWindowSize,
  changeCalibrationType,
} from '../../../../../cleanCalibrateSlice';
import WindowSizeSlider from '../../../../../components/WindowSizeSlider';
import { useAppSelector } from '../../../../../../../../app/store/helpers/functions';
import CalibrationTypeSelector from '../../../../../components/CalibrationTypeSelector';

import './index.scss';

export default function CalibrateTabContent({
  onCancel,
}: {
  onCancel: () => void,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const smoothWindowSize = useAppSelector(selectCalibrateSmoothWindowSize);
  const calibrationType = useAppSelector(selectCalibrationType);

  const CalibrationTypeContent = CONFIG[calibrationType];

  const handleSmoothWindowSizeChange = (value: number) => {
    dispatch(setCalibrateSmoothWindowSize(value));
  };

  const handleCalibrationTypeChange = (value: CalibrationType) => {
    dispatch(changeCalibrationType(value));
  };

  return (
    <>
      <PopupHeader
        classes={{
          root: 'calibrate-tab-content__header',
        }}
        title={t('clean-calibrate.yield-popup.calibrate-title')}
        onCancel={onCancel}
      />
      <DialogContent className="calibrate-tab-content__content">
        <DialogContentText>
          {t('clean-calibrate.yield-popup.tabs.calibrate.configure.window-size.instruction')}
        </DialogContentText>
        <WindowSizeSlider
          value={smoothWindowSize}
          onChange={handleSmoothWindowSizeChange}
        />
        <Divider className="calibrate-tab-content__content-divider" />
        <CalibrationTypeSelector
          value={calibrationType}
          onTypeChange={handleCalibrationTypeChange}
        />
        <Divider className="calibrate-tab-content__content-divider" />
        <DialogContentText>
          {t('clean-calibrate.yield-popup.tabs.calibrate.configure.select-attributes-instruction')}
        </DialogContentText>
        <CalibrationTypeContent />
      </DialogContent>
    </>
  );
}

import { createSelector } from '@reduxjs/toolkit';

export const selectFieldsHasError = ({ jdFields }) => jdFields.fields === null
    && jdFields.isLoading === false;

export const selectFieldsIsEmpty = ({ jdFields }) => jdFields.fields === null || jdFields.fields.length === 0;

export const selectFieldsIsLoading = ({ jdFields }) => jdFields?.isLoading === true;

export const selectFieldsPage = ({ jdFields }) => jdFields.page;

export const selectFieldsPageSize = ({ jdFields }) => jdFields.pageSize;

export const selectFieldsTotalCount = ({ jdFields }) => jdFields.totalCount;

export const selectFieldsFilterOrganizationsIds = ({ jdFields }) => jdFields.organizationsIds;

export const selectFieldsFilterFieldName = ({ jdFields }) => jdFields.fieldName;

export const selectFieldsList = createSelector(
  ({ jdFields }) => jdFields.fields || [],
  (fields) => [...fields].sort((jdFieldA, jdFieldB) => {
    if (jdFieldA.area === 0 && jdFieldB.area > 0) {
      return 1;
    }
    if (jdFieldA.area > 0 && jdFieldB.area === 0) {
      return -1;
    }
    return 0;
  }),
);

export const selectFieldsAvailable = ({ jdFields }) => (jdFields.fields || [])
  .filter((field) => field.area > 0);

export const selectFieldsHasAvailable = createSelector(
  selectFieldsAvailable,
  (fields) => fields.length > 0,
);

import { productFruits } from 'product-fruits';

import {
  getWorkspaceCode,
} from '../../../../../helpers/functions/utils/vendorConfigs/productFruits';
import { isProductFruitsEnabled } from '../../../../../helpers/functions/utils/appConfig';

export const initialize = ({
  userUuid,
  userEmail,
  language,
}: {
  userUuid: string,
  userEmail: string,
  language: string,
}): void => {
  if (!isProductFruitsEnabled()) {
    return;
  }

  const workspaceCode = getWorkspaceCode();
  const userInfo: ProductFruitsUserObject = {
    username: userUuid,
    email: userEmail,
  };

  productFruits.init(
    workspaceCode,
    language,
    userInfo,
  );
};

export const destroy = (): void => {
  window.productFruits?.services.destroy();
};

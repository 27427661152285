import React from 'react';

import OperationsListComponent from '../../components/OperationsList';
import useActiveOperationType from '../../hooks/useActiveOperationType';
import useOperations from '../../hooks/useOperations';

export default function OperationsList() {
  const activeOperationType = useActiveOperationType();
  const { operations, isLoading } = useOperations();
  const activeOperations = activeOperationType ? operations[activeOperationType] : [];
  const lastUpdate = activeOperations?.[0]?.subOperations?.[0]?.executionDate;

  return (
    <OperationsListComponent
      type={activeOperationType}
      lastUpdate={lastUpdate}
      operations={activeOperations}
      loading={isLoading}
    />
  );
}

import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import Button from '../../../../../../../components/Button';
import RadioGroup from '../../../../../../../components/RadioGroup';
import { ImportType } from '../../../../helpers/constants/importType';
import AreaLimitReachedNotification from '../../../AreaLimitReachedNotification';

import './index.scss';

export default function SelectTypeStep({
  type,
  usedArea,
  maxArea,
  onTypeChange,
  onCancel,
  onNext,
  onConfirm,
}: {
  type: ImportType,
  usedArea: number,
  maxArea: number,
  onTypeChange: (v: ImportType) => void,
  onCancel: () => void,
  onNext: () => void,
  onConfirm: () => void,
}) {
  const { t } = useTranslation();

  const isImportDisabled = usedArea > maxArea && type !== ImportType.fieldOperationFiles;

  let nextLabel = t('general.stepper.next');
  let nextHandler = onNext;

  if (type === ImportType.fieldBoundaries) {
    nextLabel = t('general.popups.done');
    nextHandler = onConfirm;
  }

  return (
    <Fragment>
      <DialogContent classes={{ root: clsx('import-settings-john-deere-popup__content', 'select-type-popup') }} >
        <Typography variant="body2">{t('general.popups.import-settings-john-deere.select-type.description')}</Typography>
        {isImportDisabled
        && (
          <AreaLimitReachedNotification
            usedArea={usedArea}
            maxArea={maxArea}
          />
        )}
        <RadioGroup
          options={[
            {
              value: ImportType.fieldBoundaries,
              title: t('general.popups.import-settings-john-deere.select-type.types.field-boundaries.label'),
              description: t('general.popups.import-settings-john-deere.select-type.types.field-boundaries.description'),
            },
            {
              value: ImportType.dataFiles,
              title: t('general.popups.import-settings-john-deere.select-type.types.data-files.label'),
              description: t('general.popups.import-settings-john-deere.select-type.types.data-files.description'),
            },
            {
              value: ImportType.fieldOperationFiles,
              title: t('general.popups.import-settings-john-deere.select-type.types.field-operation-files.label'),
              description: t('general.popups.import-settings-john-deere.select-type.types.field-operation-files.description'),
            },
          ]}
          value={type}
          onChange={onTypeChange}
        />
      </DialogContent>
      <DialogActions classes={{ root: 'import-settings-john-deere-popup__actions' }}>
        <Button
          variant="outlined"
          onClick={onCancel}
        >
          {t('general.controls.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isImportDisabled}
          onClick={nextHandler}
        >
          {nextLabel}
        </Button>
      </DialogActions>
    </Fragment>
  );
}

import React from 'react';

import { DataLayersListNode } from '../../../../types/dataLayersList';
import SimpleItem from '../../../ListView/SimpleItem';
import NamedItem from '../../../Items/NamedItem';
import { TransformedTopographyMap } from '../../../../../../../helpers/types/dataset';

export default function TopographyNode({
  listNode,
  topographyMap,
  selectedItemUuid,
  onClick,
}: {
  listNode: DataLayersListNode;
  topographyMap: TransformedTopographyMap;
  selectedItemUuid: string;
  onClick: () => void;
}) {
  const isItemSelected = listNode.uuid === selectedItemUuid;

  return (
    <SimpleItem isSelected={isItemSelected} onClick={onClick}>
      <NamedItem item={topographyMap} />
    </SimpleItem>
  );
}

import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  selectDatasetUuid,
  selectStep,
} from '../../../../../cleanCalibrateSelectors';
import Button from '../../../../../../../../components/Button';
import { selectFieldUuid, selectYieldDataset } from '../../../../../../../field/fieldSelectors';
import { useCleanCalibrateYieldDatasetMutation } from '../../../../../cleanCalibrateAPI';
import { useAppSelector } from '../../../../../../../../app/store/helpers/functions';
import { setStep } from '../../../../../cleanCalibrateSlice';
import useCalibrateParams from '../../../../../hooks/useCalibrateParams';
import useCleanParams from '../../../../../hooks/useCleanParams';

export default function FastTabRightActions({
  onCancel,
}: {
  onCancel: () => void,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [cleanCalibrateYieldDataset] = useCleanCalibrateYieldDatasetMutation();
  const step = useAppSelector(selectStep);
  const fieldUuid = useAppSelector(selectFieldUuid);
  const datasetUuid = useAppSelector(selectDatasetUuid);
  const dataset = useAppSelector((state) => selectYieldDataset(state, datasetUuid));
  const calibrateParams = useCalibrateParams();
  const cleanParams = useCleanParams();

  const handleConfirm = async () => {
    if (!calibrateParams || !cleanParams || !fieldUuid || !dataset?.uuid) {
      return;
    }

    await cleanCalibrateYieldDataset({
      fieldUuid,
      yieldDatasetUuid: dataset.uuid,
      actions: {
        actions: ['CLEAN', 'CALIBRATE'],
        calibrateAction: calibrateParams,
        cleanAction: cleanParams,
      },
    });

    onCancel();
  };

  const handleBack = () => {
    dispatch(setStep(step - 1));
  };

  return (
    <>
      {
        step === 0
          ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={onCancel}
            >
              {t('general.controls.cancel')}
            </Button>
          )
          : (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleBack}
            >
              {t('general.stepper.back')}
            </Button>
          )
      }
      <Button
        variant="contained"
        color="primary"
        disabled={!cleanParams || !calibrateParams}
        onClick={handleConfirm}
      >
        {t('general.controls.run')}
      </Button>
    </>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import RadioGroup from '../../../../../../components/RadioGroup';
import { setAssetType } from '../../../createThreeDMapSlice';
import {
  selectSoilDatasets,
  selectTopographyMaps,
  selectYieldDatasets,
} from '../../../../../field/fieldSelectors';
import { AssetType } from '../../../../../../helpers/constants/entities/asset';
import { selectAssetType } from '../../../createThreeDMapSelectors';

const ShapeDataTypePanel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const soilDatasets = useSelector(selectSoilDatasets);
  const yieldDatasets = useSelector(selectYieldDatasets);
  const topographyMaps = useSelector(selectTopographyMaps);
  const assetType = useSelector(selectAssetType);

  const handleAssetTypeChange = (value) => {
    dispatch(setAssetType(value));
  };

  return (
    <RadioGroup
      options={[
        {
          value: AssetType.soilDataset,
          disabled: soilDatasets.length === 0,
          title: t('general.shared.soil-data'),
        },
        {
          value: AssetType.yieldDataset,
          disabled: yieldDatasets.length === 0,
          title: t('general.shared.yield-data'),
        },
        {
          value: AssetType.topographyMap,
          disabled: topographyMaps.length === 0,
          title: t('general.shared.topography'),
        },
      ]}
      value={assetType}
      onChange={handleAssetTypeChange}
    />
  );
};

export default ShapeDataTypePanel;

import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import { ASSET_TYPE_TO_I18N_KEY, AssetType } from '../../../../../helpers/constants/entities/asset';
import DataLayersPreviewItem from '../DataLayersPreviewItem';
import { AssetsLayer } from '../../types/previews';
import { hasNotEmptyLayer } from '../../helpers/functions/previews';

import './index.scss';

const getRefKey = (layerIndex: number, uuid?: string) => `${layerIndex}_${uuid}`;

export default function DataLayersPreviews({
  apiKey,
  layers,
  selectedUuid,
  selectedLayerIndex = 0,
  onItemRemove,
  onItemSelect,
}: {
  apiKey: string,
  layers: AssetsLayer[],
  selectedUuid: string,
  selectedLayerIndex?: number,
  onItemRemove: (uuid: string, layer: number) => void,
  onItemSelect: (uuid: string, layer: number) => void,
}) {
  const { t } = useTranslation();

  const itemRefs = useRef(new Map());

  useEffect(() => {
    const selectedRef = itemRefs.current.get(getRefKey(selectedLayerIndex, selectedUuid));

    if (selectedRef) {
      selectedRef.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    }
  }, [selectedUuid, selectedLayerIndex]);

  const handleItemSelect = (uuid: string, layerIndex: number) => {
    onItemSelect(uuid, layerIndex);
  };

  const handleItemRemove = (uuid: string, layerIndex: number) => {
    onItemRemove(uuid, layerIndex);
  };

  const getLayerItems = (layer: AssetsLayer, layerIndex: number) => {
    let result;

    if (layer.assetType === AssetType.satelliteImage) {
      result = layer.assets.map((asset) => {
        const refKey = getRefKey(layerIndex, asset.uuid);
        return (
          <DataLayersPreviewItem
            key={refKey}
            ref={(el) => itemRefs.current.set(refKey, el)}
            apiKey={apiKey}
            assetType={layer.assetType}
            asset={asset}
            index={layer.index}
            selected={selectedUuid === asset.uuid && selectedLayerIndex === layerIndex}
            onItemSelect={(uuid) => handleItemSelect(uuid, layerIndex)}
            onItemRemove={(uuid) => handleItemRemove(uuid, layerIndex)}
          />
        );
      });
    } else if (
      (
        layer.assetType === AssetType.soilDataset
        || layer.assetType === AssetType.yieldDataset
        || layer.assetType === AssetType.asAppliedDataset
      ) && layer.asset && layer.attribute
    ) {
      const refKey = getRefKey(layerIndex, layer.asset?.uuid);
      result = (
        <DataLayersPreviewItem
          key={refKey}
          ref={(el) => itemRefs.current.set(refKey, el)}
          apiKey={apiKey}
          assetType={layer.assetType}
          asset={layer.asset}
          attribute={layer.attribute}
          selected={selectedUuid === layer.asset?.uuid && selectedLayerIndex === layerIndex}
          onItemSelect={(uuid) => handleItemSelect(uuid, layerIndex)}
          onItemRemove={(uuid) => handleItemRemove(uuid, layerIndex)}
        />
      );
    } else if (layer.assetType === AssetType.topographyMap && layer.asset && layer.attribute) {
      const refKey = getRefKey(layerIndex, layer.asset?.uuid);
      result = (
        <DataLayersPreviewItem
          key={refKey}
          ref={(el) => itemRefs.current.set(refKey, el)}
          apiKey={apiKey}
          assetType={layer.assetType}
          asset={layer.asset}
          attribute={layer.attribute}
          selected={selectedUuid === layer.asset?.uuid && selectedLayerIndex === layerIndex}
          onItemSelect={(uuid) => handleItemSelect(uuid, layerIndex)}
          onItemRemove={(uuid) => handleItemRemove(uuid, layerIndex)}
        />
      );
    }

    return result;
  };

  let result;

  if (hasNotEmptyLayer(layers)) {
    result = (
      <div className="data-layers-previews">
        {
          layers.map((layer, layerIndex) => {
            let layerTitle;
            if (layer.assetType === AssetType.satelliteImage) {
              layerTitle = `${t(ASSET_TYPE_TO_I18N_KEY[layer.assetType])} (${layer.assets.length}) | ${layer.index}`;
            } else {
              layerTitle = t(ASSET_TYPE_TO_I18N_KEY[layer.assetType]);
              layerTitle = layer.attribute ? `${layerTitle} | ${layer.attribute}` : layerTitle;
            }

            return (
              <div key={layerIndex} className="data-layers-previews__layer">
                <Typography className="data-layers-previews__layer-title">
                  {layerTitle}
                </Typography>
                <div className="data-layers-previews__layer-items">
                  {getLayerItems(layer, layerIndex)}
                </div>
              </div>
            );
          })
        }
      </div>
    );
  } else {
    result = (
      <div className="data-layers-previews">
        <Typography className="data-layers-previews__empty">
          {t('data-layers-previews.empty')}
        </Typography>
      </div>
    );
  }

  return result;
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AssetLoadStatus } from '../../../helpers/constants/entities/asset';
import { BATCH_SIZE } from '../../../helpers/constants/utils/batchOperations';
import { LoadStatus } from '../../../helpers/constants/utils/loadStatus';
import { batchOperations } from '../../../helpers/functions/utils/batchOperations';
import {
  captureException,
  CustomError,
} from '../../../helpers/functions/utils/errorHandling';
import { fetchBoundary } from '../../field/fieldAPI';
import { fetchMinifiedSatelliteImages } from '../../satelliteImages/satelliteImagesAPI';
import { selectApiKey } from '../../user/userSelectors';
import {
  selectFieldBoundaryMap,
  selectFieldSatelliteImagesStatus,
} from './mapViewSelectors';

const initialState = {
  /**
   * @type {{
   *   [fieldUuid: string]: {
   *     status: LoadStatus,
   *     data: SatelliteImage[],
   *   },
   * }}
   */
  satelliteImagesMap: {},
  /**
   * @type {{
   *   [fieldUuid: string]: {
   *     status: LoadStatus,
   *     data: FeatureCollection,
   *   },
   * }}
   */
  fieldBoundaryMap: {},
  chartScale: null,
  /**
   * @type {Field}
   */
  legend: null,
};

export const fetchFieldsBoundaries = (fields) => (dispatch, getState) => {
  const state = getState();
  const fieldBoundaryMap = selectFieldBoundaryMap(state);
  const apiKey = selectApiKey(state);
  const unrequestedBoundariesFields = fields.filter(({ uuid }) => {
    return !fieldBoundaryMap[uuid];
  });

  if (unrequestedBoundariesFields.length === 0) {
    return;
  }

  dispatch(markRequestedBoundaries(unrequestedBoundariesFields));

  batchOperations(
    (field) => {
      return fetchBoundary(field.boundaryUrl, apiKey)
        .then((boundaryFc) => {
          dispatch(setupFieldBoundary({
            fieldUuid: field.uuid,
            boundary: boundaryFc,
          }));
        })
        .catch((error) => {
          dispatch(invalidateFieldBoundary({
            fieldUuid: field.uuid,
          }));
          captureException({
            error: new CustomError('[MapView] Unable to setup field boundary', {
              cause: error,
            }),
          });
        });
    },
    unrequestedBoundariesFields,
    BATCH_SIZE,
  );
};

export const fetchNDVIGraphSatelliteImages = createAsyncThunk(
  'mapView/fetchNDVIGraphSatelliteImages',
  ({ fieldUuid, farmUuid }) => {
    return fetchMinifiedSatelliteImages(farmUuid, fieldUuid);
  },
  {
    condition: (fieldUuid, { getState }) => {
      const state = getState();
      const status = selectFieldSatelliteImagesStatus(state, fieldUuid);

      if (
        status === AssetLoadStatus.loading
        || status === AssetLoadStatus.error
        || status === AssetLoadStatus.success
      ) {
        return false;
      }
    },
  },
);

export const mapViewSlice = createSlice({
  name: 'mapView',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    changeChartScale(state, action) {
      state.chartScale = action.payload;
    },
    openLegend(state, action) {
      state.legend = action.payload;
    },
    closeLegend(state) {
      state.legend = initialState.legend;
    },
    markRequestedBoundaries(state, action) {
      action.payload.forEach(({ uuid }) => {
        state.fieldBoundaryMap[uuid] = {
          status: LoadStatus.loading,
          data: null,
        };
      });
    },
    setupFieldBoundary(state, action) {
      state.fieldBoundaryMap[action.payload.fieldUuid] = {
        status: LoadStatus.success,
        data: action.payload.boundary,
      };
    },
    invalidateFieldBoundary(state, action) {
      state.fieldBoundaryMap[action.payload.fieldUuid] = {
        status: LoadStatus.error,
        data: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNDVIGraphSatelliteImages.pending, (state, action) => {
        const { fieldUuid } = action.meta.arg;

        state.satelliteImagesMap[fieldUuid] = {
          status: AssetLoadStatus.loading,
          data: [],
        };
      })
      .addCase(fetchNDVIGraphSatelliteImages.rejected, (state, action) => {
        const { fieldUuid } = action.meta.arg;

        if (!state.satelliteImagesMap[fieldUuid]) {
          return state;
        }

        state.satelliteImagesMap[fieldUuid].status = AssetLoadStatus.error;
      })
      .addCase(fetchNDVIGraphSatelliteImages.fulfilled, (state, action) => {
        const { fieldUuid } = action.meta.arg;

        if (!state.satelliteImagesMap[fieldUuid]) {
          return state;
        }

        state.satelliteImagesMap[fieldUuid].status = AssetLoadStatus.success;
        state.satelliteImagesMap[fieldUuid].data = action.payload;
      });
  },
});

export const {
  reset,
  changeChartScale,
  openLegend,
  closeLegend,
  markRequestedBoundaries,
  setupFieldBoundary,
  invalidateFieldBoundary,
} = mapViewSlice.actions;

export default mapViewSlice.reducer;

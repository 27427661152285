import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../../../app/store/helpers/functions';
import { createEquation } from '../../../equationsSlice';
import SaveEquationPopup from '../../../components/Popups/SaveEquation';
import { Equation } from '../../../types/equation';

export default function CreateEquationPopup({
  equation,
  onCancel = () => {},
  onConfirm = () => {},
}: {
  equation: Required<Omit<Equation, 'uuid'>>,
  onCancel: () => void,
  onConfirm: (u: {
    uuid: string,
    title: string,
    description: string,
    sourceUrl: string,
  }) => void,
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [inProgress, setInProgress] = useState(false);

  const handleSaveClick = async (title: string, description: string, sourceUrl: string) => {
    setInProgress(true);

    try {
      const res = await dispatch(createEquation({
        ...equation,
        title,
        description,
        sourceUrl,
      })).unwrap();

      onConfirm({
        uuid: res?.uuid || '',
        title,
        description,
        sourceUrl,
      });
    } catch {
      setInProgress(false);
    }
  };

  return (
    <SaveEquationPopup
      popup={{
        title: t('general.popups.create-equation.title'),
        description: t('general.popups.create-equation.description'),
      }}
      initialValues={{
        title: equation.title,
        description: equation.description,
        sourceUrl: equation.sourceUrl,
      }}
      inProgress={inProgress}
      onConfirm={handleSaveClick}
      onCancel={onCancel}
    />
  );
}

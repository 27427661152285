import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import { getCellClasses } from '../../../helpers/functions/cell';
import Button from '../../../../../../components/Button';

import './index.scss';

const ActionsCell = ({
  row = {},
  headCell = {},
}) => {
  const actions = row[headCell.id];

  return (
    <TableCell
      align={headCell.horizontalAlign || 'left'}
      className={getCellClasses('actions-cell', headCell, row)}
    >
      <div className="actions-cell__button-container">
        {
          actions.map(({ label, value }) => (
            <Button
              key={value}
              className="actions-cell__button"
              onClick={() => headCell.onActionClick(value, row)}
            >
              {label}
            </Button>
          ))
        }
      </div>
    </TableCell>
  );
};

export default ActionsCell;

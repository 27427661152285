import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from 'i18next';

import { getUserDataFetcher } from '../applicationShell/applicationShellSlice';
import { selectFieldUuid, selectVectorAnalysisMap } from '../../field/fieldSelectors';
import { selectUpdates, selectUuid } from './zonesMapSelectors';
import { selectAreaUnit } from '../../user/userSelectors';
import { prepareFeatures } from './helpers/functions/geojson';
import { hasColorsUpdates, hasGeojsonUpdates } from './helpers/functions/updates';
import {
  saveVamap as saveVamapAPI,
} from './zonesMapAPI';
import {
  errorNotify,
  successNotify,
  warningNotify,
} from '../../notifications/helpers/functions/notify';
import { CustomError } from '../../../helpers/functions/utils/errorHandling';
import { isTimeoutError } from './helpers/functions/api';

export const saveVamap = createAsyncThunk(
  'zonesMap/saveVamap',
  async (_payload, { getState, dispatch, rejectWithValue }) => {
    try {
      await getUserDataFetcher();

      const state = getState();
      const uuid = selectUuid(state);
      const {
        zonesMapGeojson: zonesMapGeojsonUpdate,
        type: typeUpdate,
        colors: colorsUpdate,
      } = selectUpdates(state);
      const zonesMap = selectVectorAnalysisMap(state, uuid);
      const fieldUuid = selectFieldUuid(state);
      const areaUnit = selectAreaUnit(state);

      let zonesMapGeojson;
      let type;
      let colors;

      if (typeUpdate) {
        type = typeUpdate;
      }

      if (hasGeojsonUpdates(zonesMap, zonesMapGeojsonUpdate)) {
        zonesMapGeojson = {
          ...zonesMapGeojsonUpdate,
          features: prepareFeatures(zonesMapGeojsonUpdate.features),
        };
      }

      if (hasColorsUpdates(zonesMap, colorsUpdate)) {
        colors = colorsUpdate;
      }

      const {
        zonesMapGeojson: updatedZonesMapGeojson,
        attributesJson: updatedAttributesJson,
        geoMaps: updatedGeoMaps,
      } = await saveVamapAPI({
        fieldUuid,
        uuid,
        name: zonesMap.name,
        zonesMapGeojson,
        type,
        colors,
        areaUnit,
      });

      successNotify({
        message: i18n.t('zones-map.notifications.zones-map-saved'),
      });

      return {
        zonesMapGeojson: updatedZonesMapGeojson,
        attributesJson: updatedAttributesJson,
        uuid,
        type,
        geoMaps: updatedGeoMaps,
      };
    } catch (error) {
      if (isTimeoutError(error)) {
        const state = getState();
        const uuid = selectUuid(state);

        warningNotify({
          key: uuid,
          message: i18n.t('zones-map.notifications.save-zones-map-timeout'),
        });
      } else {
        errorNotify({
          error: new CustomError('[UI Zones Map] Unable to save zones map.', {
            cause: error,
          }),
          dispatch,
        });
      }

      return rejectWithValue(error);
    }
  },
);

import { getRatesData } from '../../../../../helpers/analysis';
import { ProductUnit } from '../../../../../helpers/constants/units/productUnit';
import { isVectorAnalysis } from '../../../../../helpers/functions/entities/assets';
import { TransformedEquationMap } from '../../../../../helpers/types/equationMap';
import { TransformedVectorAnalysisMap } from '../../../../../helpers/types/vectorAnalysisMap';
import { PRODUCT_UNIT_TO_WORK_PLAN_UNIT } from '../constants/workPlanUnits';

export const getWorkPlanUint = (workPlanTypeUnits: string[] = [], productUnit?: ProductUnit | null) => {
  if (!productUnit) {
    return null;
  }

  const workPlanUnit = PRODUCT_UNIT_TO_WORK_PLAN_UNIT[productUnit];

  if (workPlanUnit) {
    return workPlanTypeUnits.find((unit) => unit === workPlanUnit) ?? null;
  }

  return null;
};

export const getAssetUnit = (asset: TransformedEquationMap | TransformedVectorAnalysisMap | null) => {
  if (!asset) {
    return null;
  }

  if (isVectorAnalysis(asset)) {
    const ratesUnits = getRatesData(asset.attributes, 'ratesUnits');
    return ratesUnits[0];
  }

  return asset.productUnit;
};

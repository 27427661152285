import React, { Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import Loading from '../../../../../../../components/Loading';
import Stepper from '../../../../../../../components/Stepper';
import Panel from '../../../Panel/DataLayers';
import {
  goBackFromDataLayers,
  navigateToSettings,
} from '../../../../createSingleLayerAnalysisSlice';
import { ASSET_TYPE_TO_ASSET_GROUP } from '../../../../../../../helpers/constants/entities/asset';
import { selectIsCategoryLoaded } from '../../../../../../field/fieldSelectors';
import { selectDataLayer, selectAssetType } from '../../../../createSingleLayerAnalysisSelectors';

const Map = React.lazy(() => import('../../../Map'));

const StepperDataLayers = ({
  steps,
  stepIndex,
  step,
}) => {
  const dispatch = useDispatch();
  const {
    satelliteImageUuid,
    datasetAttribute,
    datasetUuid,
  } = useSelector(selectDataLayer);
  const assetType = useSelector(selectAssetType);
  const isLoaded = useSelector((state) => selectIsCategoryLoaded(state, ASSET_TYPE_TO_ASSET_GROUP[assetType]));
  const nextDisabled = !satelliteImageUuid && !(datasetUuid && datasetAttribute);
  let content;

  if (isLoaded) {
    content = [
      <Panel key="panel" />,
      <Suspense
        key="map"
        fallback={<Loading />}
      >
        <Map step={step} />
      </Suspense>,
    ];
  } else {
    content = (
      <BackdropLoading key="loader" />
    );
  }

  return (
    <Stepper
      activeStep={stepIndex}
      steps={steps}
      nextDisabled={nextDisabled}
      onClickNext={() => dispatch(navigateToSettings())}
      onClickBack={() => dispatch(goBackFromDataLayers())}
    >
      { content }
    </Stepper>
  );
};

export default StepperDataLayers;

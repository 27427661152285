import { useRouteMatch } from 'react-router-dom';

import { getRootLink, PAGES_ROOTS } from '../../../../helpers/navigation';
import type { OperationType } from '../helpers/constants/operation';

export default function useActiveOperationType() {
  const url = `${getRootLink(PAGES_ROOTS.operations)}/:operationType`;
  const match = useRouteMatch<{ operationType: OperationType }>(url);

  return match?.params?.operationType as OperationType | undefined;
}

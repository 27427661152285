import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import { ASSET_TYPE_TO_I18N_KEY } from '../../../helpers/constants/entities/asset';
import { AssetsSelectOption } from '../types';

import './index.scss';

export default function AssetsSelectItem(
  {
    item,
  }:{
    item: AssetsSelectOption;
  },
) {
  const { t } = useTranslation();
  const {
    name, type, src, label, icon,
  } = item;

  return (
    <div className='assets-select-item'>
      {src && (
        <div className="assets-select-item__preview-wrapper">
          <img
            className="assets-select-item__preview"
            src={src}
            alt={t('general.shared.preview')}
          />
        </div>
      )}
      <div className="assets-select-item__content">
        <div className="assets-select-item__description">
          <Typography variant="body1">
            {name}
          </Typography>
          <Typography
            variant="caption"
            className="assets-select-item__type"
          >
            {type ? t(ASSET_TYPE_TO_I18N_KEY[type]) : ''}
          </Typography>
        </div>
        <span
          role="button"
          tabIndex={0}
          className="assets-select-item__icon"
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
        >
          {icon}
        </span>
      </div>
      <div className="assets-select-item__label">
        {label}
      </div>
    </div>
  );
}

import i18n from 'i18next';

import { AssetType } from '../../constants/entities/asset';

export const getAssetTypeLabel = (type: AssetType): string => {
  if (type === AssetType.satelliteImage) {
    return i18n.t('general.shared.satellite-monitoring');
  }

  if (type === AssetType.yieldDataset) {
    return i18n.t('general.shared.yield-data');
  }

  if (type === AssetType.asAppliedDataset) {
    return i18n.t('general.shared.as-applied-data');
  }

  if (type === AssetType.soilDataset) {
    return i18n.t('general.shared.soil-data');
  }

  if (type === AssetType.topographyMap) {
    return i18n.t('general.shared.topography');
  }

  if (type === AssetType.vectorAnalysisMap) {
    return i18n.t('general.shared.zones-map');
  }

  if (type === AssetType.equationMap) {
    return i18n.t('general.shared.equation-map');
  }

  return 'UNKNOWN TYPE';
};

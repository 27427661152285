import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  getRootLink,
  PAGES_ROOTS,
} from '../../../helpers/navigation';
import { selectAdditionalUserDataProvided } from '../../../features/user/userSelectors';

const UserDataRedirector = ({
  children,
}: {
  children: ReactNode;
}) => {
  const additionalUserDataProvided = useSelector(selectAdditionalUserDataProvided);

  if (!additionalUserDataProvided) {
    return (
      <Redirect to={getRootLink(PAGES_ROOTS.userData)} />
    );
  }

  return <>{children}</>;
};

export default UserDataRedirector;

import i18n from 'i18next';
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  executeImportFromJohnDeere,
  executeImportAllFromJohnDeere,
} from './jdImportAPI';
import { selectFieldsList } from '../../jdFields/jdFieldsSelectors';
import {
  errorNotify,
  successNotify,
} from '../../notifications/helpers/functions/notify';
import { getBrandName } from '../../../helpers/functions/utils/appConfig';
import { CustomError } from '../../../helpers/functions/utils/errorHandling';

const initialState = {
  step: 'selectOrganizations',
  selectedOrganizations: [],
  selectedFields: [],
  isLoading: null,
};

export const nextStepImportFromJohnDeere = createAction('jdImport/nextStep');
export const resetStateImportJohnDeere = createAction('jdImport/resetState');
export const toggleOrganizationImportFromJohnDeere = createAction('jdImport/toggleOrganization');
export const toggleAllOrganizationsImportFromJohnDeere = createAction('jdImport/toggleAllOrganizations');
export const receiveSubscriptionSynchronizedFieldImportFromJohnDeere = createAction('jdImport/receiveSubscriptionSynchronizedField');
export const selectFieldImportFromJohnDeere = createAction('jdImport/selectField');
export const selectFieldsImportFromJohnDeere = createAction('jdImport/selectFields');

const transformResponseImportFromJohnDeere = (promise, dispatch) => {
  return promise
    .then(({ status }) => {
      if (status === '201') {
        successNotify({
          message: i18n.t('upload-data-john-deere.steps.selectData.notifications.start-import', {
            brandName: getBrandName(),
          }),
        });
      } else {
        errorNotify({
          error: new CustomError('[UI JD Import] Incorrect status.', {
            cause: {
              status,
            },
          }),
          message: i18n.t('upload-data-john-deere.steps.selectData.notifications.not-imported'),
          dispatch,
        });
      }
      return {
        status,
      };
    })
    .catch((error) => {
      errorNotify({
        error: new CustomError('[UI JD Import] Unable to execute import from JD.', {
          cause: error,
        }),
        message: i18n.t('upload-data-john-deere.steps.selectData.notifications.not-imported'),
        dispatch,
      });
    });
};

export const importFromJohnDeere = createAsyncThunk(
  'jdImport/importFromJohnDeere',
  ({
    fields,
    importType,
    fieldOperationTypes,
    cropSeasons,
  }, { dispatch }) => {
    return transformResponseImportFromJohnDeere(executeImportFromJohnDeere({
      fields,
      importType,
      fieldOperationTypes,
      cropSeasons,
    }), dispatch);
  },
);

export const importAllFromJohnDeere = createAsyncThunk(
  'jdImport/importAllFromJohnDeere',
  ({
    organizationsIds,
    fieldName,
    importType,
    fieldOperationTypes,
    cropSeasons,
  }, { dispatch }) => {
    return transformResponseImportFromJohnDeere(executeImportAllFromJohnDeere({
      organizationsIds,
      fieldName,
      importType,
      fieldOperationTypes,
      cropSeasons,
    }), dispatch);
  },
);

export const subscriptionImportDataFromJohnDeere = (parsedEvent) => (dispatch, getState) => {
  const {
    action,
    pathLength,
    farmUuid,
    fieldUuid,
  } = parsedEvent;
  if (action === 'REMOVE' && (pathLength === 1 || pathLength === 2)) {
    const fields = selectFieldsList(getState());

    if (fields.length > 0) {
      if (fieldUuid) {
        dispatch(receiveSubscriptionSynchronizedFieldImportFromJohnDeere({ fieldUuid }));
      } else if (farmUuid) {
        dispatch(receiveSubscriptionSynchronizedFieldImportFromJohnDeere({ farmUuid }));
      }
    }
  }
};

const reducerImportFromJohnDeerePending = (state) => {
  state.isLoading = true;
};

const reducerImportFromJohnDeereFulfilled = (state) => {
  state.step = 'selectOrganizations';
  state.selectedOrganizations = [];
  state.selectedFields = [];
  state.isLoading = false;
};

export const jdImportSlice = createSlice({
  name: 'jdImport',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(resetStateImportJohnDeere, (state) => (state.isLoading ? state : { ...initialState }))
      .addCase(nextStepImportFromJohnDeere, (state, action) => {
        state.step = action.payload.step;
      })
      .addCase(toggleOrganizationImportFromJohnDeere, (state, action) => {
        const selectedOrganizations = state.selectedOrganizations.filter((org) => {
          return org.id !== action.payload.selectOrganization.id;
        });

        if (state.selectedOrganizations.length === selectedOrganizations.length) {
          selectedOrganizations.push(action.payload.selectOrganization);
        }

        state.selectedOrganizations = selectedOrganizations;
      })
      .addCase(toggleAllOrganizationsImportFromJohnDeere, (state, action) => {
        let newSelectedOrganizations = [...state.selectedOrganizations];

        (action.payload.selectedOrganizations || []).forEach((org) => {
          const newValidSelectedField = newSelectedOrganizations.find((newSelectedOrganization) => {
            return newSelectedOrganization.id === org.id;
          });

          if (action.payload.selected) {
            if (!newValidSelectedField) {
              newSelectedOrganizations.push(org);
            }
          } else if (newValidSelectedField) {
            newSelectedOrganizations = newSelectedOrganizations.filter((newSelectedOrganization) => {
              return newSelectedOrganization.id !== org.id;
            });
          }
        });

        state.selectedOrganizations = newSelectedOrganizations;
      })
      .addCase(selectFieldImportFromJohnDeere, (state, action) => {
        const currentSelectedFields = state.selectedFields || [];
        const selectedFields = currentSelectedFields.filter((field) => (field.id !== action.payload.field.id));

        if (currentSelectedFields.length !== selectedFields.length) {
          state.selectedFields = selectedFields;
        } else {
          state.selectedFields = [...selectedFields, action.payload.field];
        }
      })
      .addCase(selectFieldsImportFromJohnDeere, (state, action) => {
        let newSelectedFields = [...state.selectedFields];

        (action.payload.selectedFields || []).forEach((field) => {
          const newValidSelectedField = newSelectedFields.find((newSelectedField) => {
            return newSelectedField.id === field.id;
          });

          if (action.payload.selected) {
            if (!newValidSelectedField) {
              newSelectedFields.push(field);
            }
          } else if (newValidSelectedField) {
            newSelectedFields = newSelectedFields.filter((newSelectedField) => {
              return newSelectedField.id !== field.id;
            });
          }
        });

        state.selectedFields = newSelectedFields;
      })
      .addCase(importFromJohnDeere.pending, reducerImportFromJohnDeerePending)
      .addCase(importAllFromJohnDeere.pending, reducerImportFromJohnDeerePending)
      .addCase(importFromJohnDeere.fulfilled, reducerImportFromJohnDeereFulfilled)
      .addCase(importAllFromJohnDeere.fulfilled, reducerImportFromJohnDeereFulfilled);
  },
});

export default jdImportSlice.reducer;

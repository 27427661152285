import { useCallback, useEffect, useState } from 'react';

export default function useLabelsExpanded(allExpanded: boolean) {
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (!allExpanded) {
      setExpanded({});
    }
  }, [allExpanded]);

  const handleExpandedChange = useCallback((id: string, isExpanded:boolean) => {
    setExpanded((prevState) => ({ ...prevState, [id]: isExpanded }));
  }, [setExpanded]);

  return {
    expanded,
    handleExpandedChange,
  };
}

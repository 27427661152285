import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function CloudEmptyIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 4C15.64 4 18.67 6.59 19.35 10.04C21.95 10.22 24 12.36 24 15C24 17.76 21.76 20 19 20H6C2.69 20 0 17.31 0 14C0 10.91 2.34 8.36 5.35 8.04C6.6 5.64 9.11 4 12 4ZM6 18H19C20.66 18 22 16.66 22 15C22 13.34 20.66 12 19 12H17.5V11.5C17.5 8.46 15.04 6 12 6C9.48 6 7.37 7.69 6.71 10H6C3.79 10 2 11.79 2 14C2 16.21 3.79 18 6 18Z"/>
    </SvgIcon>
  );
}

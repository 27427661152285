import { DefaultTableRow, TableRow } from '../../types/row';

type MaybeSelectableRow = TableRow & { _selected?: boolean };
type SelectableRow = TableRow & { _selected: boolean };

export const getRowsSelection = (rows: MaybeSelectableRow[]): number => {
  const selectableRows = rows.filter((row): row is SelectableRow => {
    return '_selected' in row;
  });
  const {
    partialRowsSelection,
    fullRowsSelection,
  } = selectableRows.reduce((acc, { _selected }) => {
    return {
      partialRowsSelection: acc.partialRowsSelection || _selected,
      fullRowsSelection: acc.fullRowsSelection && _selected,
    };
  }, {
    partialRowsSelection: false,
    fullRowsSelection: true,
  });
  let rowsSelection = 0;

  if (fullRowsSelection) {
    rowsSelection = 2;
  } else if (partialRowsSelection) {
    rowsSelection = 1;
  }

  return rowsSelection;
};

export const isDefaultTableRow = (row: TableRow | null): row is DefaultTableRow => {
  return row?.rowType === 'default';
};

export const isRowExpanded = <T extends { rowType: string; id: string }>(
  row: T,
  expandedRows: Record<string, boolean>,
  allExpanded: boolean,
) => {
  return row.rowType === 'nested' ? expandedRows[row.id] ?? allExpanded : false;
};

import {
  API,
  graphqlOperation,
  GraphQLSubscription,
} from '@aws-amplify/api';
import { Auth } from '@aws-amplify/auth';
import { Observable } from 'zen-observable-ts';

import subscriptionGQL from './graphql/subscriptions/subscription.gql';
import { SubscriptionEvent } from './types/api';
import {
  captureException,
  CustomError,
} from '../../helpers/functions/utils/errorHandling';

export const subscribe = async (
  userUuid: string,
): Promise<Observable<GraphQLSubscription<SubscriptionEvent> | void>> => {
  if (!userUuid) {
    const { attributes } = await Auth.currentUserInfo();

    try {
      userUuid = attributes.sub;
    } catch (error) {
      captureException({
        error: new CustomError('[Subscription] Unable to read attributes.sub', {
          cause: error,
        }),
      });

      return Observable.of<void>();
    }
  }

  // FIXME: double assertion is required until https://github.com/aws-amplify/amplify-js/issues/7589 is fixed
  return (
    API.graphql(graphqlOperation(subscriptionGQL, {
      userUuid,
    })) as unknown as Observable<GraphQLSubscription<SubscriptionEvent>>
  );
};

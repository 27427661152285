import React from 'react';
import { useTranslation } from 'react-i18next';

import { MAX_GEOMETRIES } from '../../../../helpers/constants/instruments';

import './index.scss';

export default function ClonePolygonsToolsPanel() {
  const { t } = useTranslation();

  return (
    <div className="clone-polygons-tools-panel">
      {t('zones-ops.draw-manually.steps.3.clone-polygons.description', {
        geometries: MAX_GEOMETRIES,
      })}
    </div>
  );
}

import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function FilterIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 6V8H21V6H3ZM10 18H14V16H10V18ZM18 13H6V11H18V13Z"
        />
      </g>
    </SvgIcon>
  );
}

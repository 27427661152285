import React from 'react';
import i18n from 'i18next';

import type { TransformedField } from '../../../../field/types/field';
import type { TransformedFarm } from '../../../../farms/types/farm';
import {
  getFieldLink,
  PAGES_ROOTS,
} from '../../../../../helpers/navigation';
import {
  getFieldThumbnailGeoMap,
  isInUnsupportedRegion,
} from '../../../../field/helpers/functions/field';
import Label from '../../../../../components/Label';
import { formatDate } from '../../../../../helpers/functions/utils/date';
import { convertNumberToFormattedString } from '../../../../../helpers/markup';
import { getOwnedText } from '../../../../../helpers/data';
import { getThumbnailUrl } from '../../../../../helpers/functions/utils/url';
import type { FieldTableRow, DefaultFieldTableRow } from '../../types/tableRow';
import type { CollapsibleLabelsNestedTableRow } from '../../../tableView/types/row';
import AmplitudeAnalytics from '../../../../../helpers/classes/amplitudeAnalytics';

export const getFieldsTableRows = ({
  fields,
  farms,
  apiKey,
  checkedUuids = [],
  onDeleteFieldLabel = () => {},
  onAddFieldLabel = () => {},
}: {
  fields: TransformedField[],
  farms: TransformedFarm[],
  apiKey: string,
  checkedUuids?: string[],
  onAddFieldLabel?: (r: FieldTableRow) => void,
  onDeleteFieldLabel?: (r: CollapsibleLabelsNestedTableRow, i: number) => void,
}): FieldTableRow[] => {
  const checkedUuidsSet = new Set(checkedUuids);
  const farmsMap = new Map(farms.map((farm) => {
    return [farm.uuid, farm];
  }));

  return fields.reduce<FieldTableRow[]>((acc, field) => {
    const thumbnail = getFieldThumbnailGeoMap(field.geoMaps || []);
    let farm: TransformedFarm | null = null;

    if (field.farmUuid) {
      farm = farmsMap.get(field.farmUuid) || null;
    }

    const row: DefaultFieldTableRow = {
      rowType: 'default',
      name: {
        title: field.name,
        link: getFieldLink(field.farmUuid, field.uuid),
        label: (
          isInUnsupportedRegion(field)
            ? (
              <Label
                classes={{
                  label: 'not-supported-region',
                }}
                text={i18n.t('field-profiler.fields-list.not-supported-region')}
              />
            )
            : null
        ),
        onLinkClick: () => {
          AmplitudeAnalytics.trackFieldLinkClicked({
            page: PAGES_ROOTS.fields,
          });
        },
      },
      id: field.uuid,
      farmUuid: field.farmUuid,
      farmName: farm?.name || '',
      labels: field.labels || [],
      area: field.area
        ? convertNumberToFormattedString(parseFloat(field.area?.toFixed(2)).toString())
        : null,
      dateCreated: formatDate(field.createdDate),
      dateUpdated: formatDate(field.updatedDate),
      ownTypeLabel: getOwnedText(farm?.farmOwnership),
      _selected: checkedUuidsSet.has(field.uuid),
      preview: getThumbnailUrl({ thumbnail, size: 80, apiKey }),
    };

    acc.push(row);

    if (field.labels?.length) {
      row.hasNestedRow = true;
      acc.push({
        id: `nested-row-${field.uuid}`,
        rowType: 'nested',
        cellType: 'collapsible-labels',
        fieldUuid: field.uuid,
        farmUuid: field.farmUuid || '',
        fieldName: field.name,
        labels: field.labels,
        onDeleteLabelClick: onDeleteFieldLabel,
        onAddLabelClick: onAddFieldLabel,
      });
    }

    return acc;
  }, []);
};

export const isDefaultFieldTableRow = (row: FieldTableRow): row is DefaultFieldTableRow => {
  return row.rowType === 'default' && 'name' in row;
};

export const isCollapsibleLabelsNestedTableRow = (row: FieldTableRow): row is CollapsibleLabelsNestedTableRow => {
  return row.rowType === 'nested' && 'labels' in row;
};

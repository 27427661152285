import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Select, { SelectOption } from '../../../../../../../components/Select';
import RecommendedSatelliteImagesPicker from '../../../../../../../components/RecommendedSatelliteImagesPicker';
import { DataLayersListContext } from '../../../../hooks/useDataLayersList';
import { TransformedSatelliteImage } from '../../../../../../satelliteImages/types/satelliteImage';

import './index.scss';

interface BaseProps {
  selectedIndex: string;
}

interface WithIndexControlProps extends BaseProps {
  indexes: SelectOption<string>[];
  onIndexChange: (index: string | null) => void;
}

interface MultiSelectProps extends BaseProps {
  onRecommendedSelect: (uuids: string[]) => void;
}

type MultiSelectWithIndexControlProps = WithIndexControlProps & MultiSelectProps;

export default function CreateAnalysisSatelliteImagesPanel(
  props: MultiSelectWithIndexControlProps | WithIndexControlProps | MultiSelectProps,
) {
  const { t } = useTranslation();

  const context = useContext(DataLayersListContext);
  const multiSelectMode = 'onRecommendedSelect' in props;
  const withIndexControl = 'indexes' in props;
  const images = context.assets as TransformedSatelliteImage[];
  const filteredImages = context.filteredAssets as TransformedSatelliteImage[];

  return (
    <div className="create-analysis-satellite-images-panel">
      {
        withIndexControl
          && (
            <Select
              id="satellite-index-selector"
              required
              labelPosition="left"
              label={t('zones-ops.multi-layer.steps.3.satellite-index')}
              value={props.selectedIndex}
              options={props.indexes}
              onChange={props.onIndexChange}
            />
          )
      }
      {
        multiSelectMode
          && (
            <RecommendedSatelliteImagesPicker
              allImages={images}
              filteredImages={filteredImages}
              onSelect={props.onRecommendedSelect}
            />
          )
      }
    </div>
  );
}

import React from 'react';

import OperationTypeItem from '../../components/OperationTypeItem';
import OperationTypesPanelComponent from '../../components/OperationTypesPanel';
import { OPERATION_TYPES } from '../../helpers/constants/operation';
import { countOperations } from '../../helpers/functions/operations';
import useOperations from '../../hooks/useOperations';

export default function OperationTypesPanel() {
  const { operations } = useOperations();

  return (
    <OperationTypesPanelComponent>
      {OPERATION_TYPES.map((type) => {
        const ops = operations[type];
        const lastUpdate = ops?.[0]?.subOperations?.[0]?.executionDate;

        return (
          <OperationTypeItem
            key={type}
            type={type}
            lastUpdate={lastUpdate}
            operations={countOperations(ops)}
          />
        );
      })}
    </OperationTypesPanelComponent>
  );
}

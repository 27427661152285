import React from 'react';
import Typography from '@material-ui/core/Typography';

import './index.scss';

const LegendHeader = ({
  text,
  shrinked,
}) => {
  if (!text) {
    return null;
  }

  return (
    <Typography
      variant="subtitle2"
      classes={{
        root: `legend-header ${shrinked ? 'legend-header_shrinked' : ''}`,
      }}
    >
      {text}
    </Typography>
  );
};

export default LegendHeader;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import PlaceholderImage from './resultsPlaceholder.svg';

import './index.scss';

const ZonesMapResultsPlaceholder = () => {
  const { t } = useTranslation();

  return (
    <div className="zones-map-results-placeholder">
      <PlaceholderImage />
      <Typography
        variant="body2"
        className="zones-map-results-placeholder__text"
      >
        {t('zones-map.results-panel-placeholder')}
      </Typography>
    </div>
  );
};

export default ZonesMapResultsPlaceholder;

import { AssetType } from '../../../../../helpers/constants/entities/asset';
import { AssetsLayer } from '../../types/previews';

const isLayerEmpty = (layer: AssetsLayer): boolean => {
  let result;

  if (layer.assetType === AssetType.satelliteImage) {
    result = layer.assets.length === 0 || !layer.index;
  } else {
    result = !layer.asset || !layer.attribute;
  }

  return result;
};

export const hasNotEmptyLayer = (layers: AssetsLayer[]): boolean => {
  return layers.some((layer) => !isLayerEmpty(layer));
};

import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFieldsJohnDeere } from './jdFieldsAPI';
import {
  selectFieldsFilterFieldName,
  selectFieldsFilterOrganizationsIds,
  selectFieldsPage,
  selectFieldsPageSize,
} from './jdFieldsSelectors';
import {
  JOHN_DEERE_FIRST_PAGE_PAGINATION,
  JOHN_DEERE_DEFAULT_PAGE_SIZE_PAGINATION,
} from './helpers/constants';

const initialState = {
  fields: null,
  totalCount: 0,
  page: JOHN_DEERE_FIRST_PAGE_PAGINATION,
  pageSize: JOHN_DEERE_DEFAULT_PAGE_SIZE_PAGINATION,
  organizationsIds: null,
  fieldName: null,
  isLoading: null,
};

export const fetchFieldsJohnDeere = createAsyncThunk(
  'jdFields/getFields',
  (payload, {
    getState,
  }) => {
    const page = selectFieldsPage(getState());
    const pageSize = selectFieldsPageSize(getState());
    const organizationsIds = selectFieldsFilterOrganizationsIds(getState());
    const fieldName = selectFieldsFilterFieldName(getState());
    return getFieldsJohnDeere(page, pageSize, organizationsIds, fieldName);
  },
);

export const fetchFieldsJohnDeereWithNewPage = createAsyncThunk(
  'jdFields/getFieldsJohnDeereWithNewPage',
  ({
    page,
  }, {
    getState,
  }) => {
    const pageSize = selectFieldsPageSize(getState());
    const organizationsIds = selectFieldsFilterOrganizationsIds(getState());
    const fieldName = selectFieldsFilterFieldName(getState());
    return getFieldsJohnDeere(page, pageSize, organizationsIds, fieldName);
  },
);

export const fetchFieldsJohnDeereWithNewPageSize = createAsyncThunk(
  'jdFields/getFieldsJohnDeereWithNewPageSize',
  ({
    pageSize,
  }, {
    getState,
  }) => {
    const organizationsIds = selectFieldsFilterOrganizationsIds(getState());
    const fieldName = selectFieldsFilterFieldName(getState());
    return getFieldsJohnDeere(JOHN_DEERE_FIRST_PAGE_PAGINATION, pageSize, organizationsIds, fieldName);
  },
);

export const fetchFieldsJohnDeereWithNewOrganizationsIds = createAsyncThunk(
  'jdFields/getFieldsJohnDeereWithNewOrganizationsIds',
  ({
    organizationsIds,
  }, {
    getState,
  }) => {
    const pageSize = selectFieldsPageSize(getState());
    const fieldName = selectFieldsFilterFieldName(getState());
    return getFieldsJohnDeere(JOHN_DEERE_FIRST_PAGE_PAGINATION, pageSize, organizationsIds, fieldName);
  },
);

export const fetchFieldsJohnDeereWithNewOrganizationsIdsAndFieldName = createAsyncThunk(
  'jdFields/getFieldsJohnDeereWithNewOrganizationsIdsAndFieldName',
  ({
    organizationsIds,
    fieldName,
  }, {
    getState,
  }) => {
    const pageSize = selectFieldsPageSize(getState());
    return getFieldsJohnDeere(JOHN_DEERE_FIRST_PAGE_PAGINATION, pageSize, organizationsIds, fieldName);
  },
);

export const receiveSubscriptionSynchronizedFieldJohnDeere = createAction('jdFields/receiveSubscriptionSynchronizedField');

export const resetFieldsJohnDeere = createAction('jdFields/resetFieldsJohnDeere');

export const resetStateJohnDeere = createAction('jdFields/resetStateJohnDeere');

const reducerFetchFieldsJohnDeerePending = (state) => {
  state.isLoading = true;
  state.fields = [];
  state.totalCount = 0;
};

const reducerFetchFieldsJohnDeereFulfilled = (state, action) => {
  state.isLoading = false;
  state.fields = action.payload.fields;
  state.totalCount = action.payload.totalCount;
  state.page = action.payload.page;
  state.pageSize = action.payload.pageSize;
  state.organizationsIds = action.payload.organizationsIds;
  state.fieldName = action.payload.fieldName;
};

export const JdFieldsSlice = createSlice({
  name: 'jdFields',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(resetStateJohnDeere, () => ({ ...initialState }))
      .addCase(resetFieldsJohnDeere, (state) => { state.fields = []; })
      .addCase(fetchFieldsJohnDeere.pending, reducerFetchFieldsJohnDeerePending)
      .addCase(fetchFieldsJohnDeereWithNewPage.pending, reducerFetchFieldsJohnDeerePending)
      .addCase(fetchFieldsJohnDeereWithNewPageSize.pending, reducerFetchFieldsJohnDeerePending)
      .addCase(fetchFieldsJohnDeereWithNewOrganizationsIds.pending, reducerFetchFieldsJohnDeerePending)
      .addCase(fetchFieldsJohnDeereWithNewOrganizationsIdsAndFieldName.pending, reducerFetchFieldsJohnDeerePending)
      .addCase(fetchFieldsJohnDeere.fulfilled, reducerFetchFieldsJohnDeereFulfilled)
      .addCase(fetchFieldsJohnDeereWithNewPage.fulfilled, reducerFetchFieldsJohnDeereFulfilled)
      .addCase(fetchFieldsJohnDeereWithNewPageSize.fulfilled, reducerFetchFieldsJohnDeereFulfilled)
      .addCase(fetchFieldsJohnDeereWithNewOrganizationsIds.fulfilled, reducerFetchFieldsJohnDeereFulfilled)
      .addCase(fetchFieldsJohnDeereWithNewOrganizationsIdsAndFieldName.fulfilled, reducerFetchFieldsJohnDeereFulfilled)
      .addCase(receiveSubscriptionSynchronizedFieldJohnDeere, (state, action) => {
        return {
          ...state,
          fields: (state.fields || []).map((field) => {
            if (
              field.synchronized && (field.synchronized.farmUuid === action.payload.farmUuid
                            || field.synchronized.fieldUuid === action.payload.fieldUuid)
            ) {
              return {
                ...field,
                synchronized: null,
              };
            }
            return field;
          }),
        };
      });
  },
});

export default JdFieldsSlice.reducer;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import AuthenticationRedirector from '../router/redirectors/Authentication';
import UserDataRedirector from '../router/redirectors/UserData';
import PricingRedirector from '../router/redirectors/Pricing';
import Panel from '../features/ui/integrations/containers/Panel';
import { getDocumentTitle } from '../helpers';
import BaseTemplate from './BaseTemplate.jsx';
import useDidMount from '../hooks/useDidMount';
import { getHeaderProps } from '../features/ui/integrations/helpers/functions/integrations';

export default function UserIntegrations() {
  const { t } = useTranslation();
  const location = useLocation();

  useDidMount(() => {
    document.title = getDocumentTitle(t('general.navigation.integrations'));
  });

  return (
    <AuthenticationRedirector>
      <UserDataRedirector>
        <PricingRedirector>
          <BaseTemplate
            headerProps={getHeaderProps(location.pathname)}
          >
            <Panel key="panel" />
          </BaseTemplate>
        </PricingRedirector>
      </UserDataRedirector>
    </AuthenticationRedirector>
  );
}

import { API, graphqlOperation } from '@aws-amplify/api';

import getSubscriptionsQuery from './graphql/queries/getSubscriptionsJohnDeere.gql';
import createFieldOperationSubscriptionMutation from './graphql/mutations/createFieldOperationSubscriptionJohnDeere.gql';
import disableSubscriptionMutation from './graphql/mutations/disableSubscriptionJohnDeere.gql';
import enableSubscriptionMutation from './graphql/mutations/enableSubscriptionJohnDeere.gql';
import deleteSubscriptionMutation from './graphql/mutations/deleteSubscriptionJohnDeere.gql';
import {
  CreateFieldOperationSubscriptionResponse,
  DeleteSubscriptionJohnDeereResponse,
  DisableSubscriptionJohnDeereResponse,
  EnableSubscriptionJohnDeereResponse,
  GetSubscriptionsJohnDeereResponse,
} from './types/api';
import { JohnDeereOperationType } from './helpers/constants';

export const getSubscriptions = async () => {
  const { data } = (await API.graphql(graphqlOperation(getSubscriptionsQuery)) as GetSubscriptionsJohnDeereResponse);
  return data!.getSubscriptionsJohnDeere;
};

export const createFieldOperationSubscription = async ({
  orgIds,
  fieldOperationTypes,
}: {
  orgIds: string[],
  fieldOperationTypes: JohnDeereOperationType[],
}) => {
  const { data } = (await API.graphql(graphqlOperation(createFieldOperationSubscriptionMutation, {
    input: {
      orgIds,
      fieldOperationTypes,
    },
  })) as CreateFieldOperationSubscriptionResponse);
  return data!.createFieldOperationSubscriptionJohnDeere;
};

export const disableSubscription = async ({ id }: { id: string }) => {
  const { data } = (await API.graphql(graphqlOperation(disableSubscriptionMutation, {
    input: {
      id,
    },
  })) as DisableSubscriptionJohnDeereResponse);
  return data!.disableSubscriptionJohnDeere;
};

export const enableSubscription = async ({ id }: { id: string }) => {
  const { data } = (await API.graphql(graphqlOperation(enableSubscriptionMutation, {
    input: {
      id,
    },
  })) as EnableSubscriptionJohnDeereResponse);
  return data!.enableSubscriptionJohnDeere;
};

export const deleteSubscription = async ({ id }: { id: string }) => {
  const { data } = (await API.graphql(graphqlOperation(deleteSubscriptionMutation, {
    input: {
      id,
    },
  })) as DeleteSubscriptionJohnDeereResponse);
  return data!.deleteSubscriptionJohnDeere;
};

import i18n from 'i18next';

import { getOwnedText } from '../data';
import {
  isField,
  isSatelliteVectorAnalysis,
  isSoilVectorAnalysis,
  isYieldVectorAnalysis,
  isAsAppliedVectorAnalysis,
  isTopographyVectorAnalysis,
  isMultiLayerVectorAnalysis,
  isZonesOperationsIntersectVectorAnalysis,
  isDrawnManuallyVectorAnalysis,
  isEquationMap,
  is3dMap,
} from '../functions/entities/assets';
import { formatAcquisitionDate } from '../satellite';
import {
  getHexColorCodes,
  TYPE_I18N_KEY_FULL_NAME_MAP,
  getRatesData,
} from '../analysis';
import { ASSET_TYPE_TO_I18N_KEY, AssetGroupType, AssetType } from '../constants/entities/asset';
import { getFieldAssetItemLink } from '../navigation';
import { getEquationMapThumbnailGeoMap, getPixelsGeoMap } from '../functions/entities/equationMap';
import {
  DEFAULT_POLYGON_MIN_AREA,
  getAreaUnitLabel,
  getI18nAreaUnit,
} from '..';
import { getThumbnailUrl } from '../functions/utils/url';
import { comparator } from '../functions/entities/satelliteImage';
import { convertDateToDisplayFormat } from '../functions/utils/date';
import { isNumber } from '../functions/utils/number';
import { convertNumberToFormattedString } from '../markup';
import {
  DataClassificationType,
  ZonesOperationType,
} from '../constants/entities/vectorAnalysisMap';
import { PRODUCT_UNIT_TO_I18N_LABEL } from '../constants/units/productUnit';
import {
  getDatasetAttributeNameHandler,
  getDatasetThumbnailGeoMap,
  getFullAttribute,
} from '../functions/entities/dataset';
import {
  getTopographyMapAttributeNameHandler,
  getTopographyMapThumbnailGeoMap,
} from '../functions/entities/topographyMap';
import { getVamapThumbnailGeoMap } from '../functions/entities/vectorAnalysisMap';
import { DatasetViewType } from '../constants/entities/dataset';
import { getFieldThumbnailGeoMap } from '../../features/field/helpers/functions/field';

const getZonesOperationLabel = (operation) => {
  let result = '';

  if (operation === ZonesOperationType.intersect) {
    result = i18n.t('zones-ops.cross-layer.steps.4.operations.intersection');
  }

  return result;
};

const getDataClassificationLabel = (dataClassification) => {
  let result = '';

  if (dataClassification === DataClassificationType.naturalBreaks) {
    result = i18n.t('zones-ops.common.data-classification-types.natural-breaks');
  } else if (dataClassification === DataClassificationType.equalInterval) {
    result = i18n.t('zones-ops.common.data-classification-types.equal-interval');
  } else if (dataClassification === DataClassificationType.equalCount) {
    result = i18n.t('zones-ops.common.data-classification-types.equal-count');
  } else if (dataClassification === DataClassificationType.spatiallyLocalizedBalanced) {
    result = i18n.t('zones-ops.common.data-classification-types.spatially-localized-balanced');
  } else if (dataClassification === DataClassificationType.spatiallyLocalizedSpatial) {
    result = i18n.t('zones-ops.common.data-classification-types.spatially-localized-spatial');
  } else if (dataClassification === DataClassificationType.spatiallyLocalizedValue) {
    result = i18n.t('zones-ops.common.data-classification-types.spatially-localized-value');
  }

  return result;
};

const getAnalysisOverview = ({
  createdDate,
  updatedDate,
  dataClassificationType,
  polygonMinArea,
  numberOfZones,
  variabilityFactor,
}) => {
  return {
    items: [
      {
        title: i18n.t('zones-map.description.creation-date'),
        value: convertDateToDisplayFormat(createdDate),
      },
      {
        title: i18n.t('zones-map.description.updated-date'),
        value: convertDateToDisplayFormat(updatedDate),
      },
      {
        title: i18n.t('zones-ops.common.data-classification-type'),
        value: getDataClassificationLabel(dataClassificationType),
      },
      {
        title: i18n.t('general.controls.map-legend.polygon-min-area'),
        value: polygonMinArea || DEFAULT_POLYGON_MIN_AREA,
      },
      {
        title: i18n.t('general.controls.map-legend.number-of-zones'),
        value: numberOfZones,
      },
      {
        title: i18n.t('general.controls.map-legend.heterogeneity-factor'),
        value: variabilityFactor ? variabilityFactor.toFixed(3) : '-',
      },
    ],
  };
};

const getFieldLabels = (labels) => {
  return {
    header: i18n.t('general.controls.map-legend.field-labels'),
    labels,
  };
};

const getChartScaleOptions = () => [
  { value: 'yearly', label: i18n.t('general.controls.map-legend.chart.yearly') },
  { value: 'monthly', label: i18n.t('general.controls.map-legend.chart.monthly') },
];

const getNdviChartData = (satelliteImages, scale) => {
  const currentDate = new Date();
  const monthlyPeriodStartDate = new Date(currentDate.setMonth(currentDate.getMonth() - 6));

  const data = (satelliteImages || [])
    .reduce((acc, image) => {
      if (image.ndviAverage) {
        const acquisitionDate = new Date(image.satelliteImage.acquisitionDate);
        const dateString = formatAcquisitionDate(image.satelliteImage.acquisitionDate);

        // images within last 6 months
        if (
          scale === 'yearly'
          || (scale === 'monthly' && acquisitionDate >= monthlyPeriodStartDate)
        ) {
          acc.push({
            name: dateString,
            data: image.ndviAverage,
          });
        }
      }

      return acc;
    }, []);

  return [...data].reverse();
};

const getZonesMapTableData = (item, areaUnit) => {
  const {
    geoMaps,
    attributes,
    zonesMapGeojson,
  } = item;

  return {
    areaUnit,
    attributes: attributes || zonesMapGeojson,
    colors: getHexColorCodes(geoMaps),
  };
};

const getRatesTableData = (item, areaUnit, area) => {
  const {
    geoMaps,
    attributes,
    type,
  } = item;
  const ratesHeaders = getRatesData(attributes, 'ratesHeaders');
  if (!ratesHeaders.length) {
    return null;
  }
  return {
    areaUnit,
    attributes,
    colors: getHexColorCodes(geoMaps),
    totalArea: area,
    type,
  };
};

const prepareDatasetZonesMapLegendGroup = (item, dataset, areaUnit) => {
  const {
    analysisType,
    parameters: {
      attribute,
      numberOfZones,
      polygonMinArea,
      dataClassificationType,
    },
    variabilityFactor,
    createdDate,
    updatedDate,
    area,
  } = item;
  const {
    name,
    createdDate: datasetCreatedDate,
  } = dataset || {};

  return {
    zonesMapTable: getZonesMapTableData(item, areaUnit),
    ratesTable: getRatesTableData(item, areaUnit, area),
    overview: getAnalysisOverview({
      createdDate,
      updatedDate,
      dataClassificationType,
      numberOfZones,
      polygonMinArea,
      variabilityFactor,
    }),
    dataSource: {
      header: i18n.t('zones-map.description.data-source'),
      items: [
        {
          title: i18n.t('zones-map.description.data-type'),
          value: i18n.t(TYPE_I18N_KEY_FULL_NAME_MAP[analysisType]),
        },
        {
          title: i18n.t('general.controls.map-legend.dataset-name'),
          value: name,
        },
        {
          title: i18n.t('general.controls.map-legend.dataset-attribute'),
          value: attribute,
        },
        {
          title: i18n.t('zones-map.description.upload-date'),
          value: convertDateToDisplayFormat(datasetCreatedDate),
        },
      ],
    },
  };
};

export const prepareFieldLegendGroup = ({
  field,
  farm,
  satelliteImages,
  chartScale,
  areaUnit,
  apiKey,
}) => {
  const thumbnail = getFieldThumbnailGeoMap(field.geoMaps);
  const chartScaleOptions = getChartScaleOptions();
  const chartScaleValue = chartScale || chartScaleOptions[0].value;

  return {
    preview: {
      url: getThumbnailUrl({ thumbnail, size: 192, apiKey }),
      alt: i18n.t('field-profiler.map-view.field-border-preview'),
    },
    overview: {
      items: [
        {
          title: i18n.t('general.controls.map-legend.creation-date'),
          value: convertDateToDisplayFormat(field.createdDate),
        },
        {
          title: i18n.t('general.shared.farm'),
          value: farm?.name || '-',
        },
        {
          title: getAreaUnitLabel(areaUnit),
          value: field.area.toFixed(3),
        },
        {
          title: i18n.t('general.shared.ownership'),
          value: farm ? getOwnedText(farm.farmOwnership) : '-',
        },
      ],
    },
    labels: field.labels,
    lineChart: {
      data: getNdviChartData(satelliteImages, chartScaleValue),
      scaleOptions: chartScaleOptions,
      scale: chartScaleValue,
      title: 'NDVI',
    },
  };
};

export const prepareSatelliteImageGallery = (images, field) => {
  const {
    uuid: fieldUuid,
    farmUuid,
  } = field;
  return [...images]
    .sort(comparator)
    .map((image) => {
      return {
        url: '', // TODO add after back is implemented
        date: formatAcquisitionDate(image.satelliteImage.acquisitionDate),
        ndvi: image.ndviAverage,
        provider: image.satelliteImage.provider,
        link: getFieldAssetItemLink(
          farmUuid,
          fieldUuid,
          AssetGroupType.satelliteImages,
          image.uuid,
        ),
      };
    });
};

const enrichMultiAssetData = (anAssets, field) => {
  return anAssets.map((anAsset) => {
    if (anAsset.satelliteImages) {
      const satelliteImages = Object.fromEntries((field.satelliteImages || []).map((image) => {
        return [image.uuid, image.satelliteImage.acquisitionDate];
      }));
      const images = anAsset.satelliteImages.map(({ uuid }) => {
        return formatAcquisitionDate(satelliteImages[uuid]);
      });

      return {
        type: AssetType.satelliteImage,
        items: [
          {
            label: i18n.t('general.controls.map-legend.data-layers.index'),
            value: anAsset.index,
          },
          {
            label: i18n.t('general.controls.map-legend.data-layers.satellite-images', { amount: images.length }),
            value: images.join(', '),
          },
        ],
      };
    }

    if (anAsset.yieldAttribute && anAsset.yieldDataset) {
      const dataset = (field.yieldDatasets || []).find(({ uuid }) => uuid === anAsset.yieldDataset.uuid);
      const nameHandler = getDatasetAttributeNameHandler(dataset?.fullAttributes);
      const fullAttribute = getFullAttribute(anAsset.yieldAttribute, dataset?.fullAttributes);
      const geoMap = getDatasetThumbnailGeoMap({
        geoMaps: dataset?.geoMaps,
        view: DatasetViewType.processed,
        attribute: anAsset.yieldAttribute,
      });

      return {
        type: AssetType.yieldDataset,
        items: [
          {
            label: i18n.t('general.controls.map-legend.data-layers.dataset'),
            value: dataset?.name,
            dataset: {
              link: getFieldAssetItemLink(
                field.farmUuid,
                field.uuid,
                AssetGroupType.yieldDatasets,
                anAsset.yieldDataset.uuid,
              ),
              number: dataset?.number,
              fieldCoverage: dataset?.fieldCoverage,
              geoMap,
              fullAttribute,
            },
          },
          {
            label: i18n.t('general.controls.map-legend.data-layers.attribute'),
            value: nameHandler(anAsset.yieldAttribute),
          },
        ],
      };
    }

    if (anAsset.asAppliedAttribute && anAsset.asAppliedDataset) {
      const dataset = (field.asAppliedDatasets || []).find(({ uuid }) => uuid === anAsset.asAppliedDataset.uuid);
      const nameHandler = getDatasetAttributeNameHandler(dataset?.fullAttributes);
      const fullAttribute = getFullAttribute(anAsset.asAppliedAttribute, dataset?.fullAttributes);
      const geoMap = getDatasetThumbnailGeoMap({
        geoMaps: dataset?.geoMaps,
        view: DatasetViewType.processed,
        attribute: anAsset.asAppliedAttribute,
      });

      return {
        type: AssetType.asAppliedDataset,
        items: [
          {
            label: i18n.t('general.controls.map-legend.data-layers.dataset'),
            value: dataset?.name,
            dataset: {
              link: getFieldAssetItemLink(
                field.farmUuid,
                field.uuid,
                AssetGroupType.asAppliedDatasets,
                anAsset.asAppliedDataset.uuid,
              ),
              number: dataset?.number,
              fieldCoverage: dataset?.fieldCoverage,
              geoMap,
              fullAttribute,
            },
          },
          {
            label: i18n.t('general.controls.map-legend.data-layers.attribute'),
            value: nameHandler(anAsset.asAppliedAttribute),
          },
        ],
      };
    }

    if (anAsset.soilAttribute && anAsset.soilDataset) {
      const dataset = (field.soilDatasets || []).find(({ uuid }) => uuid === anAsset.soilDataset.uuid);
      const nameHandler = getDatasetAttributeNameHandler(dataset?.fullAttributes);
      const fullAttribute = getFullAttribute(anAsset.soilAttribute, dataset?.fullAttributes);
      const geoMap = getDatasetThumbnailGeoMap({
        geoMaps: dataset?.geoMaps,
        view: DatasetViewType.processed,
        attribute: anAsset.soilAttribute,
      });

      return {
        type: AssetType.soilDataset,
        items: [
          {
            label: i18n.t('general.controls.map-legend.data-layers.dataset'),
            value: dataset?.name,
            dataset: {
              link: getFieldAssetItemLink(
                field.farmUuid,
                field.uuid,
                AssetGroupType.soilDatasets,
                anAsset.soilDataset.uuid,
              ),
              number: dataset?.number,
              fieldCoverage: dataset?.fieldCoverage,
              geoMap,
              fullAttribute,
            },
          },
          {
            label: i18n.t('general.controls.map-legend.data-layers.attribute'),
            value: nameHandler(anAsset.soilAttribute),
          },
        ],
      };
    }

    if (anAsset.topographyAttribute && anAsset.topographyMap) {
      const topographyMap = (field.topographyMaps || []).find(({ uuid }) => uuid === anAsset.topographyMap.uuid);
      const nameHandler = getTopographyMapAttributeNameHandler(topographyMap?.fullAttributes);
      const fullAttribute = getFullAttribute(anAsset.topographyAttribute, topographyMap?.fullAttributes);
      const geoMap = getTopographyMapThumbnailGeoMap({
        geoMaps: topographyMap?.geoMaps,
        attribute: anAsset.topographyAttribute,
      });

      return {
        type: AssetType.topographyMap,
        items: [
          {
            label: i18n.t('general.controls.map-legend.data-layers.dataset'),
            value: topographyMap?.name,
            dataset: {
              link: getFieldAssetItemLink(
                field.farmUuid,
                field.uuid,
                AssetGroupType.topographyMaps,
                anAsset.topographyMap.uuid,
              ),
              geoMap,
              fullAttribute,
            },
          },
          {
            label: i18n.t('general.controls.map-legend.data-layers.attribute'),
            value: nameHandler(anAsset.topographyAttribute),
          },
        ],
      };
    }

    if (anAsset.vectorAnalysisMap) {
      const vectorAnalysisMap = (field.vectorAnalysisMaps || []).find(({ uuid }) => {
        return uuid === anAsset.vectorAnalysisMap.uuid;
      });
      const geoMap = getVamapThumbnailGeoMap(vectorAnalysisMap?.geoMaps);

      return {
        type: AssetType.vectorAnalysisMap,
        items: [
          {
            value: vectorAnalysisMap?.name,
            analysisMap: {
              link: getFieldAssetItemLink(
                field.farmUuid,
                field.uuid,
                AssetGroupType.vectorAnalysisMaps,
                anAsset.vectorAnalysisMap.uuid,
              ),
              geoMap,
            },
          },
        ],
      };
    }

    if (anAsset.equationMap) {
      const equationMap = (field.equationMaps || []).find(({ uuid }) => {
        return uuid === anAsset.equationMap.uuid;
      });
      const geoMap = getEquationMapThumbnailGeoMap(equationMap?.geoMaps);

      return {
        type: AssetType.equationMap,
        items: [
          {
            value: equationMap?.name,
            analysisMap: {
              link: getFieldAssetItemLink(
                field.farmUuid,
                field.uuid,
                AssetGroupType.equationMaps,
                anAsset.equationMap.uuid,
              ),
              geoMap,
            },
          },
        ],
      };
    }

    // TODO: add intersect handling

    return null;
  });
};

export const prepareSatelliteZonesMapLegendGroup = (item, field, areaUnit) => {
  const {
    satelliteImages,
    variabilityFactor,
    createdDate,
    updatedDate,
    area,
    parameters: {
      index,
      numberOfZones,
      polygonMinArea,
      dataClassificationType,
    },
  } = item;
  const legendSatelliteUuids = new Set(satelliteImages.map((image) => {
    return image.uuid;
  }));
  const images = (field.satelliteImages || []).filter((image) => {
    return legendSatelliteUuids.has(image.uuid);
  });

  return {
    indexHeader: {
      text: index,
      shrinked: true,
    },
    zonesMapTable: getZonesMapTableData(item, areaUnit),
    ratesTable: getRatesTableData(item, areaUnit, area),
    overview: getAnalysisOverview({
      createdDate,
      updatedDate,
      dataClassificationType,
      polygonMinArea,
      numberOfZones,
      variabilityFactor,
    }),
    labels: getFieldLabels(field.labels),
    satelliteGallery: {
      images: prepareSatelliteImageGallery(images, field),
    },
  };
};

export const prepareSoilZonesMapLegendGroup = (item, field, areaUnit) => {
  const dataset = (field.soilDatasets || []).find(({ uuid }) => {
    return uuid === item.soilDataset.uuid;
  }) || {};
  const processedItem = {
    ...item,
    parameters: {
      ...item.parameters,
      attribute: item.parameters.soilAttribute,
    },
  };

  return {
    ...prepareDatasetZonesMapLegendGroup(processedItem, dataset, areaUnit),
    labels: getFieldLabels(field.labels),
  };
};

export const prepareYieldZonesMapLegendGroup = (item, field, areaUnit) => {
  const dataset = (field.yieldDatasets || []).find(({ uuid }) => {
    return uuid === item.yieldDataset.uuid;
  }) || {};
  const processedItem = {
    ...item,
    parameters: {
      ...item.parameters,
      attribute: item.parameters.yieldAttribute,
    },
  };

  return {
    ...prepareDatasetZonesMapLegendGroup(processedItem, dataset, areaUnit),
    labels: getFieldLabels(field.labels),
  };
};

export const prepareAsAppliedZonesMapLegendGroup = (item, field, areaUnit) => {
  const dataset = (field.asAppliedDatasets || []).find(({ uuid }) => {
    return uuid === item.asAppliedDataset.uuid;
  }) || {};
  const processedItem = {
    ...item,
    parameters: {
      ...item.parameters,
      attribute: item.parameters.asAppliedAttribute,
    },
  };

  return {
    ...prepareDatasetZonesMapLegendGroup(processedItem, dataset, areaUnit),
    labels: getFieldLabels(field.labels),
  };
};

export const prepareTopographyZonesMapLegendGroup = (item, field, areaUnit) => {
  const dataset = (field.topographyMaps || []).find(({ uuid }) => {
    return uuid === item.topographyMap.uuid;
  });
  const processedItem = {
    ...item,
    parameters: {
      ...item.parameters,
      attribute: item.parameters.topographyAttribute,
    },
  };

  return {
    ...prepareDatasetZonesMapLegendGroup(processedItem, dataset, areaUnit),
    labels: getFieldLabels(field.labels),
  };
};

export const prepareMultiLayerZonesMapLegendGroup = (item, field, areaUnit, apiKey) => {
  const {
    parameters: {
      numberOfZones,
      polygonMinArea,
      dataClassificationType,
    },
    variabilityFactor,
    createdDate,
    updatedDate,
    dataLayers,
    area,
  } = item;

  return {
    apiKey,
    zonesMapTable: getZonesMapTableData(item, areaUnit),
    ratesTable: getRatesTableData(item, areaUnit, area),
    overview: getAnalysisOverview({
      createdDate,
      updatedDate,
      dataClassificationType,
      numberOfZones,
      polygonMinArea,
      variabilityFactor,
    }),
    dataLayersList: {
      layers: enrichMultiAssetData(dataLayers, field)
        .map((anAsset, ind) => {
          return {
            ...anAsset,
            factor: dataLayers[ind].factor,
          };
        }),
    },
    labels: getFieldLabels(field.labels),
  };
};

export const prepareDrawnManuallyZoneMapLegendGroup = (item, _field, areaUnit) => {
  const {
    attributes,
    zonesMapGeojson,
    area,
  } = item;

  return {
    zonesMapTable: getZonesMapTableData(item, areaUnit),
    ratesTable: getRatesTableData(item, areaUnit, area),
    overview: {
      items: [
        {
          title: i18n.t('zones-map.description.creation-date'),
          value: convertDateToDisplayFormat(item.createdDate),
        },
        {
          title: i18n.t('zones-map.description.updated-date'),
          value: convertDateToDisplayFormat(item.updatedDate),
        },
        {
          title: i18n.t('general.controls.map-legend.number-of-zones'),
          value: (attributes || zonesMapGeojson)?.totalFeatures,
        },
      ],
    },
  };
};

export const prepareEquationMapLegendGroup = (item, field, areaUnit, apiKey) => {
  let statisticItems;

  if (item.statistics) {
    statisticItems = Object.entries(item.statistics)
      .reduce((acc, [key, value]) => {
        if (isNumber(value)) {
          acc.push({
            title: i18n.t(`general.controls.map-legend.statistics.${key}`),
            value: parseFloat(value.toFixed(3)),
          });
        }

        return acc;
      }, []);
  }

  const unitLabel = PRODUCT_UNIT_TO_I18N_LABEL[item.productUnit];

  return {
    apiKey,
    geoMapTable: {
      geoMap: getPixelsGeoMap(item),
    },
    ...(
      statisticItems
        ? {
          statistics: {
            header: i18n.t('general.controls.map.statistics'),
            subheader: unitLabel ? `(${i18n.t(unitLabel)})` : '',
            items: statisticItems,
          },
        }
        : {}
    ),
    overview: {
      items: [
        {
          title: i18n.t('zones-map.description.creation-date'),
          value: convertDateToDisplayFormat(item.createdDate),
        },
        {
          title: i18n.t('zones-map.description.updated-date'),
          value: convertDateToDisplayFormat(item.updatedDate),
        },
        {
          title: i18n.t('general.controls.map-legend.heterogeneity-factor'),
          value: item.variabilityFactor?.toFixed(3) || '-',
        },
        {
          title: i18n.t('zones-ops.equation-based.steps.5.grid-size-x'),
          value: item.parameters.gridPixelSizeX,
        },
        {
          title: i18n.t('zones-ops.equation-based.steps.5.grid-size-y'),
          value: item.parameters.gridPixelSizeY,
        },
        {
          title: i18n.t('general.controls.map-legend.covered-area'),
          value: item.area != null
            ? `${convertNumberToFormattedString(item.area.toFixed(3))} ${getI18nAreaUnit(areaUnit)}`
            : '-',
        },
      ],
    },
    equationDetails: {
      useNumpy: item.useNumpy,
      equation: item.equationAsText,
      result: item.equationResultVariable,
      variables: enrichMultiAssetData(item.dataVariables, field)
        .map((anAsset, index) => {
          return {
            ...anAsset,
            variable: item.dataVariables[index].variable,
          };
        }),
    },
  };
};

export const prepare3DMapLegendGroup = (item, areaUnit) => {
  return {
    zonesMapTable: getZonesMapTableData(item.vectorAnalysisMap, areaUnit),
    overview: {
      items: [
        {
          title: i18n.t('zones-map.description.creation-date'),
          value: convertDateToDisplayFormat(item.createdDate),
        },
        {
          title: i18n.t('zones-map.description.updated-date'),
          value: convertDateToDisplayFormat(item.updatedDate),
        },
        {
          title: i18n.t('general.controls.map-legend.dataset-type'),
          value: i18n.t(ASSET_TYPE_TO_I18N_KEY[item.dataset?._type]),
        },
        {
          title: i18n.t('general.controls.map-legend.dataset-name'),
          value: item.dataset?.name,
        },
        {
          title: i18n.t('general.controls.map-legend.dataset-attribute'),
          value: item.attribute,
        },
        {
          title: i18n.t('general.controls.map-legend.zones'),
          value: item.vectorAnalysisMap?.name,
        },
      ],
    },
    shapeParameters: {
      header: i18n.t('create-3d.viewer-3d.shape-parameters'),
      items: [
        {
          title: i18n.t('zones-ops.multi-layer.steps.4.results-table.min'),
          value: isNumber(item.min) ? parseFloat(item.min.toFixed(3)) : item.min,
        },
        {
          title: i18n.t('zones-ops.multi-layer.steps.4.results-table.max'),
          value: isNumber(item.max) ? parseFloat(item.max.toFixed(3)) : item.max,
        },
      ],
    },
  };
};

export const prepareZonesOperationsIntersectZonesMapLegendGroup = (item, field, areaUnit) => {
  const {
    area,
    zonesOperationMaps,
    parameters: {
      polygonMinArea,
      zonesOperation,
    },
  } = item;
  const sources = zonesOperationMaps.map((map) => {
    const zonesMap = (field.vectorAnalysisMaps || []).find((vamap) => {
      return vamap.uuid === map.map.uuid;
    });

    const result = {
      name: '',
      zones: [],
    };

    if (zonesMap) {
      const zonesColors = getHexColorCodes(zonesMap.geoMaps);

      result.name = zonesMap.name;
      result.zones = [...map.zoneNumbers]
        .sort((a, b) => a - b)
        .map((zoneNumber) => {
          return zonesColors[zoneNumber - 1];
        });
    }

    return result;
  });

  return {
    header: {
      text: i18n.t('general.controls.map-legend.sources'),
      shrinked: true,
    },
    overview: {
      items: [
        {
          title: i18n.t('zones-ops.cross-layer.steps.4.operation'),
          value: getZonesOperationLabel(zonesOperation),
        },
        {
          title: i18n.t('general.controls.map-legend.polygon-min-area'),
          value: polygonMinArea || DEFAULT_POLYGON_MIN_AREA,
        },
        {
          title: getAreaUnitLabel(areaUnit),
          value: area ? area.toFixed(3) : '-',
        },
      ],
    },
    sourcesTable: {
      items: sources,
    },
    ratesTable: getRatesTableData(item, areaUnit, area),
    labels: getFieldLabels(field.labels),
  };
};

export const isLegendAttributesLoaded = (item) => {
  const {
    attributes,
    zonesMapGeojson,
  } = item || {};

  return isField(item) || !!(attributes || zonesMapGeojson);
};

export const isSatelliteImagesRequired = (item) => {
  let result = false;

  if (isField(item)) {
    result = true;
  } else if (isSatelliteVectorAnalysis(item)) {
    result = true;
  } else if (isMultiLayerVectorAnalysis(item)) {
    result = item.dataLayers.some((layer) => layer.satelliteImages);
  }

  return result;
};

const checkMultiAssetDataLoaded = (anAssets, field, {
  satelliteLoaded,
  soilLoaded,
  yieldLoaded,
  asAppliedLoaded,
  topographyLoaded,
  vamapsLoaded,
}) => {
  return anAssets.every((anAsset) => {
    if (anAsset.satelliteImages) {
      return satelliteLoaded
        || anAsset.satelliteImages.every(({ uuid }) => {
          return (field.satelliteImages || []).find((img) => {
            return uuid === img.uuid;
          });
        });
    }

    if (anAsset.soilDataset) {
      return soilLoaded
        || (field.soilDatasets || []).find((dataset) => {
          return dataset.uuid === anAsset.soilDataset.uuid;
        });
    }

    if (anAsset.yieldDataset) {
      return yieldLoaded
        || (field.yieldDatasets || []).find((dataset) => {
          return dataset.uuid === anAsset.yieldDataset.uuid;
        });
    }

    if (anAsset.asAppliedDataset) {
      return asAppliedLoaded
        || (field.asAppliedDatasets || []).find((dataset) => {
          return dataset.uuid === anAsset.asAppliedDataset.uuid;
        });
    }

    if (anAsset.topographyMap) {
      return topographyLoaded
        || (field.topographyMaps || []).find((dataset) => {
          return dataset.uuid === anAsset.topographyMap.uuid;
        });
    }

    if (anAsset.vectorAnalysisMap) {
      return vamapsLoaded
        || (field.vectorAnalysisMaps || []).find((vamap) => {
          return vamap.uuid === anAsset.vectorAnalysisMap.uuid;
        });
    }

    return true;
  });
};

export const isRequiredDataLoaded = (legend, fieldData, fieldInfo) => {
  const field = fieldData || {};
  const {
    metadata: fieldLoaded,
    [AssetGroupType.satelliteImages]: satelliteLoaded,
    [AssetGroupType.soilDatasets]: soilLoaded,
    [AssetGroupType.yieldDatasets]: yieldLoaded,
    [AssetGroupType.asAppliedDatasets]: asAppliedLoaded,
    [AssetGroupType.topographyMaps]: topographyLoaded,
    [AssetGroupType.vectorAnalysisMaps]: vamapsLoaded,
  } = fieldInfo || {};

  if (isField(legend)) {
    return Boolean(fieldLoaded && satelliteLoaded);
  }

  if (isSatelliteVectorAnalysis(legend)) {
    return isLegendAttributesLoaded(legend)
      && (
        satelliteLoaded
        || legend.satelliteImages.every(({ uuid }) => {
          return (field.satelliteImages || []).find((img) => {
            return img.uuid === uuid;
          });
        })
      );
  }

  if (isSoilVectorAnalysis(legend)) {
    return isLegendAttributesLoaded(legend)
      && (
        soilLoaded
        || !!(field.soilDatasets || []).find((dataset) => {
          return dataset.uuid === legend.soilDataset.uuid;
        })
      );
  }

  if (isYieldVectorAnalysis(legend)) {
    return isLegendAttributesLoaded(legend)
      && (
        yieldLoaded
        || !!(field.yieldDatasets || []).find((dataset) => {
          return dataset.uuid === legend.yieldDataset.uuid;
        })
      );
  }

  if (isAsAppliedVectorAnalysis(legend)) {
    return isLegendAttributesLoaded(legend)
      && (
        asAppliedLoaded
        || !!(field.asAppliedDatasets || []).find((dataset) => {
          return dataset.uuid === legend.asAppliedDataset.uuid;
        })
      );
  }

  if (isTopographyVectorAnalysis(legend)) {
    return isLegendAttributesLoaded(legend)
      && (
        topographyLoaded
        || !!(field.topographyMaps || []).find((dataset) => {
          return dataset.uuid === legend.topographyMap.uuid;
        })
      );
  }

  if (isMultiLayerVectorAnalysis(legend)) {
    return isLegendAttributesLoaded(legend)
      && checkMultiAssetDataLoaded(legend.dataLayers, field, {
        satelliteLoaded,
        soilLoaded,
        yieldLoaded,
        asAppliedLoaded,
        topographyLoaded,
      });
  }

  if (isZonesOperationsIntersectVectorAnalysis(legend)) {
    return isLegendAttributesLoaded(legend)
    && (
      vamapsLoaded
      || legend.zonesOperationMaps.every((item) => {
        return !!(field.vectorAnalysisMaps || []).find((vamap) => {
          return vamap.uuid === item.map.uuid;
        });
      })
    );
  }

  if (isDrawnManuallyVectorAnalysis(legend)) {
    return isLegendAttributesLoaded(legend);
  }

  if (is3dMap(legend)) {
    const map3Dvamap = (field.vectorAnalysisMaps || []).find((vamap) => {
      return vamap.uuid === legend.vectorAnalysisMap.uuid;
    });

    return checkMultiAssetDataLoaded(
      [
        legend,
        legend.dataSourceLayer,
      ],
      field,
      {
        satelliteLoaded,
        soilLoaded,
        yieldLoaded,
        asAppliedLoaded,
        topographyLoaded,
        vamapsLoaded,
      },
    )
    && isLegendAttributesLoaded(map3Dvamap);
  }

  if (isEquationMap(legend)) {
    return checkMultiAssetDataLoaded(legend.dataVariables, field, {
      satelliteLoaded,
      soilLoaded,
      yieldLoaded,
      asAppliedLoaded,
      topographyLoaded,
    });
  }

  return false;
};

export const getSelectedItem = (uuid, field) => {
  if (!uuid || !field) {
    return null;
  }

  if (field.uuid === uuid) {
    return field;
  }

  const {
    satelliteImages = [],
    vectorAnalysisMaps = [],
    soilDatasets = [],
    yieldDatasets = [],
    asAppliedDatasets = [],
    topographyMaps = [],
    threeDimensionalMaps = [],
    equationMaps = [],
  } = field;
  const assets = [
    ...satelliteImages,
    ...vectorAnalysisMaps,
    ...soilDatasets,
    ...yieldDatasets,
    ...asAppliedDatasets,
    ...topographyMaps,
    ...threeDimensionalMaps,
    ...equationMaps,
  ];

  return assets.find((asset) => {
    return asset.uuid === uuid;
  }) || null;
};

export enum ProcessingType {
  fast = 'fast',
  clean = 'clean',
  calibrateClean = 'calibrateClean',
  calibrate = 'calibrate',
}

export enum Sequence {
  calibrateFirst = 'calibrateFirst',
  cleanFirst = 'cleanFirst',
}

export enum CalibrationType {
  pathwise = 'pathwise',
  averageTotal = 'averageTotal',
  conditional = 'conditional',
}

export enum AvgTotalCalibrationType {
  avg = 'avg',
  total = 'total',
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import useDidMount from '../hooks/useDidMount';
import AuthenticationRedirector from '../router/redirectors/Authentication';
import AdditionalUserData from '../features/ui/authentication/containers/AdditionalUserData';
import BackdropLoading from '../components/Loading/BackdropLoading';
import { getDocumentTitle } from '../helpers';
import { useAppSelector } from '../app/store/helpers/functions';
import {
  selectAdditionalUserDataProvided,
  selectIsLoaded,
} from '../features/user/userSelectors';
import {
  PAGES_ROOTS,
  getRootLink,
} from '../helpers/navigation';

const UserData = () => {
  const { t } = useTranslation();
  const isUserLoaded = useAppSelector(selectIsLoaded);
  const additionalUserDataProvided = useAppSelector(selectAdditionalUserDataProvided);

  useDidMount(() => {
    document.title = getDocumentTitle(t('general.navigation.user-data'));
  });

  const content = additionalUserDataProvided
    ? <Redirect to={getRootLink(PAGES_ROOTS.homepage)} />
    : <AdditionalUserData />;

  return (
    <AuthenticationRedirector>
      {isUserLoaded ? content : <BackdropLoading />}
    </AuthenticationRedirector>
  );
};

export default UserData;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import clsx from 'clsx';

import './index.scss';

export default function ResetFiltersButton({
  classes,
  onClick,
}: {
  classes?: { root?: string }
  onClick: () => void
}) {
  const { t } = useTranslation();

  return (
    <Button
      classes={{
        root: clsx('reset-filters-button', classes?.root),
      }}
      onClick={onClick}
    >
      {t('general.controls.filters.clear-filters')}
    </Button>
  );
}

import React, {
  ChangeEvent,
  KeyboardEventHandler,
  ReactNode,
  useRef,
} from 'react';
import clsx from 'clsx';

import './index.scss';

export default function Textarea({
  header: {
    title,
    asterisk,
    content: headerContent,
  },
  placeholder,
  value = '',
  content,
  classes,
  onChange = () => {},
  onKeyDown = () => {},
}: {
  header: {
    title: string,
    asterisk?: boolean,
    content?: ReactNode,
  },
  placeholder?: string,
  value: string,
  content?: {
    topRight?: ReactNode,
  },
  classes?: {
    wrapper?: string,
    label?: string,
    element?: string,
  },
  onChange: (v: string) => void,
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>,
}) {
  const idRef = useRef(Math.random());
  const textareaId = `textarea_${idRef.current}`;

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className={clsx('textarea__wrapper', classes?.wrapper)}>
      <div className="textarea__header">
        {
          title
          && (
            <label
              htmlFor={textareaId}
              className={clsx('textarea__label', classes?.label)}
            >
              {title}
              {asterisk && <span className="required-asterisk">*</span>}
            </label>
          )
        }
        {headerContent}
      </div>
      <div className="textarea__content">
        <div className="textarea__content-top-right">
          {content?.topRight}
        </div>
        <textarea
          id={textareaId}
          className={clsx('textarea__element', classes?.element, {
            'textarea__element_with-top-right': !!content?.topRight,
          })}
          placeholder={placeholder}
          value={value}
          onKeyDown={onKeyDown}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BackdropLoading from '../../../../../components/Loading/BackdropLoading';
import HomepagePanelComponent from '../../components/HomepagePanel';
import { toggleSelectionMode } from '../../../userFarms/userFarmsSlice';
import useHomepageData from '../../hooks/useHomepageData';
import useHomepageOperations from '../../hooks/useHomepageOperations';
import {
  selectProfileAccountName,
  selectProfileIsLoading,
} from '../../../../jdProfile/jdProfileSelectors';
import useDidMount from '../../../../../hooks/useDidMount';
import { fetchProfileJohnDeere } from '../../../../jdProfile/jdProfileSlice';

export default function HomepagePanel() {
  const dispatch = useDispatch();
  const {
    isFetching: isHomepageDataFetching,
    zonesMaps,
  } = useHomepageData();
  const operations = useHomepageOperations();
  const jdAccountName = useSelector(selectProfileAccountName);
  const isJdProfileFetching = useSelector(selectProfileIsLoading);

  useDidMount(() => {
    dispatch(fetchProfileJohnDeere());
  });

  if (isHomepageDataFetching) {
    return <BackdropLoading />;
  }

  const handleShareFarmsClick = () => {
    dispatch(toggleSelectionMode());
  };

  return (
    <HomepagePanelComponent
      zonesMaps={zonesMaps}
      operations={operations}
      jdData={{
        accountName: jdAccountName,
        isFetching: isJdProfileFetching,
      }}
      onShareFarmsClick={handleShareFarmsClick}
    />
  );
}

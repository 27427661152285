import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';

import { getCommonSteps } from '../../../zonesOps/helpers/functions/steps';
import DataLayers from '../../containers/Stepper/Steps/DataLayers';
import Settings from '../../containers/Stepper/Steps/Settings';
import Link from '../../../../../components/Link';
import { PAGES_ROOTS, getRootLink } from '../../../../../helpers/navigation';

export const getSteps = (withFieldSelection) => {
  let firstSteps = [];

  if (withFieldSelection) {
    firstSteps = getCommonSteps();
  }

  return [
    ...firstSteps,
    {
      name: i18n.t('zones-ops.common.stepper.data-layers'),
      description: (
        <Trans i18nKey="zones-ops.multi-layer.steps.3.description">
          text <Link to={getRootLink(PAGES_ROOTS.upload)}>link</Link> text
        </Trans>
      ),
      id: 'dataLayers',
      component: DataLayers,
    },
    {
      name: i18n.t('general.shared.settings'),
      description: (
        <>
          {i18n.t('zones-ops.multi-layer.steps.4.description')}
          {' '}
          {i18n.t('zones-ops.common.charge-description')}
        </>
      ),
      id: 'settings',
      component: Settings,
    },
  ];
};

import i18n from 'i18next';

import {
  ProductUnit,
  PRODUCT_UNIT_TO_I18N_LABEL,
} from '../../constants/units/productUnit';

export const getProductUnitOptions = (withEmpty = false): { value: ProductUnit | '', title: string }[] => [
  ...(
    withEmpty
      ? [{
        value: '' as const,
        title: i18n.t('general.controls.rate-units.none'),
      }]
      : []
  ),
  {
    value: ProductUnit.kgHa,
    title: i18n.t(PRODUCT_UNIT_TO_I18N_LABEL[ProductUnit.kgHa]),
  },
  {
    value: ProductUnit.kgAc,
    title: i18n.t(PRODUCT_UNIT_TO_I18N_LABEL[ProductUnit.kgAc]),
  },
  {
    value: ProductUnit.kgM2,
    title: i18n.t(PRODUCT_UNIT_TO_I18N_LABEL[ProductUnit.kgM2]),
  },
  {
    value: ProductUnit.lbAc,
    title: i18n.t(PRODUCT_UNIT_TO_I18N_LABEL[ProductUnit.lbAc]),
  },
  {
    value: ProductUnit.lHa,
    title: i18n.t(PRODUCT_UNIT_TO_I18N_LABEL[ProductUnit.lHa]),
  },
  {
    value: ProductUnit.lAc,
    title: i18n.t(PRODUCT_UNIT_TO_I18N_LABEL[ProductUnit.lAc]),
  },
  {
    value: ProductUnit.galAc,
    title: i18n.t(PRODUCT_UNIT_TO_I18N_LABEL[ProductUnit.galAc]),
  },
  {
    value: ProductUnit.seedsHa,
    title: i18n.t(PRODUCT_UNIT_TO_I18N_LABEL[ProductUnit.seedsHa]),
  },
  {
    value: ProductUnit.seedsAc,
    title: i18n.t(PRODUCT_UNIT_TO_I18N_LABEL[ProductUnit.seedsAc]),
  },
  {
    value: ProductUnit.seedsM2,
    title: i18n.t(PRODUCT_UNIT_TO_I18N_LABEL[ProductUnit.seedsM2]),
  },
  {
    value: ProductUnit.buAc,
    title: i18n.t(PRODUCT_UNIT_TO_I18N_LABEL[ProductUnit.buAc]),
  },
  {
    value: ProductUnit.tHa,
    title: i18n.t(PRODUCT_UNIT_TO_I18N_LABEL[ProductUnit.tHa]),
  },
  {
    value: ProductUnit.eurHa,
    title: i18n.t(PRODUCT_UNIT_TO_I18N_LABEL[ProductUnit.eurHa]),
  },
  {
    value: ProductUnit.eurAc,
    title: i18n.t(PRODUCT_UNIT_TO_I18N_LABEL[ProductUnit.eurAc]),
  },
  {
    value: ProductUnit.eurM2,
    title: i18n.t(PRODUCT_UNIT_TO_I18N_LABEL[ProductUnit.eurM2]),
  },
  {
    value: ProductUnit.usdHa,
    title: i18n.t(PRODUCT_UNIT_TO_I18N_LABEL[ProductUnit.usdHa]),
  },
  {
    value: ProductUnit.usdAc,
    title: i18n.t(PRODUCT_UNIT_TO_I18N_LABEL[ProductUnit.usdAc]),
  },
  {
    value: ProductUnit.usdM2,
    title: i18n.t(PRODUCT_UNIT_TO_I18N_LABEL[ProductUnit.usdM2]),
  },
];

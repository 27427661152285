import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '../../../../../components/Button';
import PopupHeader from '../../../../../components/Popups/PopupHeader';
import SelectOrganization from '../SelectOrganization';
import { exportToJohnDeereAsFiles } from '../../jdExportSlice';
import { TransformedEquationMap } from '../../../../../helpers/types/equationMap';
import { TransformedVectorAnalysisMap } from '../../../../../helpers/types/vectorAnalysisMap';
import useCurrentPage from '../../../../../hooks/useCurrentPage';
import AmplitudeAnalytics from '../../../../../helpers/classes/amplitudeAnalytics';

import '../../components/common/index.scss';

const ExportToJohnDeerePopup = ({
  vectorAnalysisMaps = [],
  equationMaps = [],
  ratesOnly = false,
  onCancel = () => {},
  onConfirm = () => {},
}: {
  vectorAnalysisMaps?: TransformedVectorAnalysisMap[];
  equationMaps?:TransformedEquationMap[];
  ratesOnly?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const page = useCurrentPage();
  const [org, onOrganizationChange] = React.useState(0);

  useEffect(() => {
    if (page) {
      AmplitudeAnalytics.trackExportToJDPopupOpened({
        page,
        type: 'asFiles',
      });
    }
  }, [page]);

  const handleConfirm = () => {
    // @ts-expect-error
    dispatch(exportToJohnDeereAsFiles({
      orgId: org,
      vectorAnalysisMaps,
      equationMaps,
      ratesOnly,
    }));

    onConfirm();
  };

  return (
    <Dialog open>
      <PopupHeader
        title={t('general.popups.export-to-john-deere.title')}
        onCancel={onCancel}
      />
      <DialogContent
        classes={{ root: 'export-to-john-deere-popup__body' }}
      >
        <SelectOrganization
          organizationId={org}
          onOrganizationChange={(orgId) => onOrganizationChange(orgId)}
        />
      </DialogContent>
      <DialogActions
        classes={{ root: 'export-to-john-deere-popup__actions' }}
      >
        <Button
          variant="outlined"
          onClick={onCancel}
        >
          {t('general.controls.cancel')}
        </Button>
        <Button
          disabled={!org}
          variant="contained"
          color="primary"
          onClick={handleConfirm}
        >
          {t('general.popups.done')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportToJohnDeerePopup;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import ExportData from '..';
import { ISOXML_STATUSES } from '../../../../helpers/analysis';
import { ExportType } from '../../../../features/exportData/helpers/constants/exportTypes';
import { openPopupExportToJohnDeereAsFiles } from '../../../../features/ui/jdExport/jdExportSlice';
import RadioGroup from '../../../RadioGroup';
import Checkbox from '../../../Checkbox';
import { getExportRatesOnlyDefaultValue, getExportRatesOnlyShowUI, isJohnDeereExportWorkflowEnabled } from '../../../../helpers/functions/utils/appConfig';

import './index.scss';

const getIsoxmlItemDescription = (t, isoxmlStatus) => {
  let description = t('general.popups.export-zones-map.items.isoxml.descriptions.valid');

  if (
    isoxmlStatus.has(ISOXML_STATUSES.INVALID)
    && isoxmlStatus.has(ISOXML_STATUSES.VALID)
    && isoxmlStatus.has(ISOXML_STATUSES.UNSUPPORTED_ASSET)
  ) {
    description = (
      <>
        <span>{t('general.popups.export-zones-map.items.isoxml.descriptions.unsupported-asset')}</span>
        <br/>
        <span>{t('general.popups.export-zones-map.items.isoxml.descriptions.partial-invalid')}</span>
      </>
    );
  } else if (
    isoxmlStatus.has(ISOXML_STATUSES.INVALID)
    && isoxmlStatus.has(ISOXML_STATUSES.VALID)
  ) {
    description = t('general.popups.export-zones-map.items.isoxml.descriptions.partial-invalid');
  } else if (isoxmlStatus.has(ISOXML_STATUSES.INVALID)) {
    description = t('general.popups.export-zones-map.items.isoxml.descriptions.invalid');
  } else if (isoxmlStatus.has(ISOXML_STATUSES.UNSUPPORTED_ASSET)) {
    description = t('general.popups.export-zones-map.items.isoxml.descriptions.unsupported-asset');
  }

  return description;
};

const getTitle = (t, itemsCount) => {
  return itemsCount > 1
    ? t('general.popups.export-zones-map.title-multiple')
    : t('general.popups.export-zones-map.title');
};

const ExportZonesMap = ({
  isoxmlStatus,
  itemsCount,
  preparedZonesMaps,
  ...props
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const options = [
    ...(
      isJohnDeereExportWorkflowEnabled()
        ? [
          {
            value: ExportType.exportToJDAsFiles,
            title: t('general.popups.export-zones-map.items.export-to-jd.title'),
            description: t('general.popups.export-zones-map.items.export-to-jd.description'),
          },
        ]
        : []
    ),
    {
      value: ExportType.multipolygons,
      title: t('general.popups.export-zones-map.items.shapefile-multipolygons.title'),
      description: t('general.popups.export-zones-map.items.shapefile-multipolygons.description'),
    },
    {
      value: ExportType.polygons,
      title: t('general.popups.export-zones-map.items.shapefile-polygons.title'),
      description: t('general.popups.export-zones-map.items.shapefile-polygons.description'),
    },
    {
      value: ExportType.isoxml,
      disabled: !isoxmlStatus.has(ISOXML_STATUSES.VALID),
      title: t('general.popups.export-zones-map.items.isoxml.title'),
      description: getIsoxmlItemDescription(t, isoxmlStatus),
    },
  ];
  const [type, setType] = useState(options[0].value);
  const [ratesOnly, setRatesOnly] = useState(getExportRatesOnlyDefaultValue());

  const confirmLabel = type === ExportType.exportToJDAsFiles ? t('general.controls.export') : '';

  const onConfirmHandler = type === ExportType.exportToJDAsFiles
    ? () => {
      dispatch(openPopupExportToJohnDeereAsFiles({
        vectorAnalysisMaps: preparedZonesMaps.map(({ uuid, name, fieldUuid }) => ({ uuid, name, fieldUuid })),
        ratesOnly,
        onConfirm: () => props.onExport?.(type),
      }));

      props.onCancel();
    }
    : () => {
      props.onConfirm(type, ratesOnly);
    };

  return (
    <ExportData
      {...props}
      title={getTitle(t, itemsCount)}
      content={(
        <>
          <RadioGroup
            options={options}
            value={type}
            onChange={(newType) => setType(newType)}
          />
          {
            (
              getExportRatesOnlyShowUI()
              && type !== ExportType.isoxml
            )
            && (
              <>
                <Divider className="export-zones-map__divider" />
                <Typography
                  gutterBottom
                  className="export-zones-map__description"
                  variant="body1"
                >
                  {t('general.popups.export-zones-map.rates-only-description')}
                </Typography>
                <FormControlLabel
                  classes={{
                    root: 'export-zones-map__checkbox',
                    label: 'export-zones-map__label',
                  }}
                  control={(
                    <Checkbox
                      value={ratesOnly ? 2 : 0}
                      onChange={(e) => setRatesOnly(e.target.checked)}
                    />
                )}
                  label={t('general.popups.export-zones-map.rates-only')}
                />
              </>
            )
          }
        </>
      )}
      confirmLabel={confirmLabel}
      onConfirm={onConfirmHandler}
    />
  );
};

export default ExportZonesMap;

import { RootState } from '../../../app/store/helpers/types';

export const selectTab = ({ uploadData }: RootState) => uploadData.tab;

export const selectFarm = ({ uploadData }: RootState) => uploadData.farm;

export const selectFiles = ({ uploadData }: RootState) => uploadData.files;

export const selectProcessingPanelStep = ({ uploadData }: RootState) => uploadData.processingPanel.step;

export const selectProcessingPanelFiles = ({ uploadData }: RootState) => uploadData.processingPanel.files;

export const selectIsMachineryFilesUploading = ({ uploadData }: RootState) => uploadData.isMachineryFilesUploading;

import React, {
  useState,
  useCallback,
  useEffect,
  Suspense,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import ButtonMenu from '../../../../components/ButtonMenu';
import Loading from '../../../../components/Loading';
import PopupHeader from '../../../../components/Popups/PopupHeader';
import BackdropLoading from '../../../../components/Loading/BackdropLoading';
import ReadMode from './ReadMode';
import useImagePreloader from '../../hooks/useImagePreloader';
import { openPopup } from '../../../ui/popups/popupsSlice';
import { getPhotosToPreload } from '../../helpers/functions/pin';
import {
  deletePin,
  savePin,
  savePinComment,
} from '../../pinsSlice';

import './index.scss';

const EditMode = React.lazy(() => import('./EditMode'));

const ACTIONS = {
  edit: 'EDIT',
  delete: 'DELETE',
  changeCompleted: 'CHANGE_COMPLETED',
};

const WARNINGS = {
  error: 'ERROR',
  info: 'INFO',
};

const PinDetailsPopup = ({
  pin,
  onSave,
  onSaveComment,
  onDelete,
  onCancel,
  onConfirm,
  isNewPin,
  farmUuid,
}) => {
  const { t } = useTranslation();
  const PIN_WARNING_MESSAGE = t('general.popups.pin-details.photo-limit-warning');
  const COMMENT_WARNING_MESSAGE = t('general.popups.pin-details.comment-limit-warning');
  const COMMENT_SAVE_FAIL_MESSAGE = t('general.popups.pin-details.comment-save-fail-warning');
  const [data, setData] = useState(pin);
  const [processing, setProcessing] = useState(false);
  const [editMode, setEditMode] = useState(isNewPin);
  const [warningVisible, setWarningVisible] = useState(false);
  const [warningMessage, setWarningMessage] = useState(PIN_WARNING_MESSAGE);
  const [warningType, setWarningType] = useState(WARNINGS.info);
  const dispatch = useDispatch();
  const photosToPreload = useMemo(() => getPhotosToPreload(data), [data]);
  const { imagesPreloaded } = useImagePreloader(photosToPreload);
  const popupTitle = isNewPin
    ? t('general.popups.pin-details.new-pin-title')
    : t('general.popups.pin-details.title');

  useEffect(() => {
    setProcessing(!imagesPreloaded);
  }, [imagesPreloaded, pin]);

  const handlePinSave = (p) => {
    setProcessing(true);
    dispatch(savePin({
      pin: p,
      farmUuid,
    }))
      .then(({ payload: savedPin }) => {
        if (!savedPin) {
          return;
        }

        onSave(savedPin);
        if (isNewPin) {
          onConfirm();
        } else {
          setData(savedPin);
          setEditMode(false);
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };
  const handlePinDelete = () => {
    dispatch(openPopup({
      type: 'remove-pin',
      onConfirm: () => {
        setProcessing(true);
        dispatch(deletePin({
          uuid: data.uuid,
          fieldUuid: data.fieldUuid,
        }))
          .then(() => {
            onDelete(data.uuid);
          })
          .finally(onCancel);
      },
    }));
  };
  const handleMenuItemClick = ({ id }) => {
    if (id === ACTIONS.edit) {
      setEditMode(true);
    } else if (id === ACTIONS.delete) {
      handlePinDelete();
    }
  };
  const handleEditFinish = (p) => {
    if (p) {
      handlePinSave(p);
    } else if (isNewPin) {
      onCancel();
    } else {
      setEditMode(false);
    }
  };
  const handleCancelClick = useCallback(
    () => {
      if (!editMode || isNewPin) {
        onCancel();
      } else {
        setEditMode(false);
      }
    },
    [editMode, isNewPin, onCancel],
  );

  const handleChangePinCompleted = (isCompleted) => {
    handlePinSave({
      ...data,
      completed: isCompleted,
    });
  };

  const handlePinWarning = () => {
    setWarningMessage(PIN_WARNING_MESSAGE);
    setWarningType(WARNINGS.info);
    setWarningVisible(true);
  };

  const handleCommentWarning = () => {
    setWarningMessage(COMMENT_WARNING_MESSAGE);
    setWarningType(WARNINGS.info);
    setWarningVisible(true);
  };

  const handleCommentSave = useCallback(({
    comment,
    photos,
  }) => {
    setProcessing(true);
    dispatch(savePinComment({
      pinUuid: pin.uuid,
      fieldUuid: pin.fieldUuid,
      comment,
      photos,
      farmUuid,
    }))
      .then(({ payload: savedPinComment }) => {
        if (!savedPinComment) {
          return;
        }

        onSaveComment(savedPinComment);
        setData((prev) => ({
          ...prev,
          comments: [
            ...(prev.comments || []),
            savedPinComment,
          ],
        }));
      })
      .catch((e) => {
        setWarningMessage(COMMENT_SAVE_FAIL_MESSAGE);
        setWarningType(WARNINGS.error);
        setWarningVisible(true);
        console.error('[Container] Unable to save pin comment', e);
      })
      .finally(() => {
        setProcessing(false);
      });
  }, [farmUuid, onSaveComment, pin, COMMENT_SAVE_FAIL_MESSAGE, dispatch]);

  return (
    <Dialog
      open
      classes={{
        paper: 'pin-details-popup',
      }}
      onClose={onCancel}
    >
      {warningVisible
        && (
          <div className={clsx(
            'pin-details-popup__warning',
            {
              'pin-details-popup__warning_info': warningType === WARNINGS.info,
              'pin-details-popup__warning_error': warningType === WARNINGS.error,
            },
          )}
          >
            <div className="pin-details-popup__warning-content">
              {
              warningType === WARNINGS.info
              && <InfoIcon className="pin-details-popup__icon" />
            }
              {
              warningType === WARNINGS.error
              && <ReportProblemOutlinedIcon className="pin-details-popup__icon pin-details-popup__icon_error" />
            }

              <span className="pin-details-popup__warning-text">
                {warningMessage}
              </span>
            </div>
            <IconButton
              classes={{
                sizeSmall: 'pin-details-popup__warning-button',
              }}
              size="small"
              onClick={() => setWarningVisible(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        )}
      <PopupHeader
        title={popupTitle}
        actions={(
          !editMode
          && (
            <ButtonMenu
              isIconButton
              classes={{
                iconButton: {
                  root: 'pin-details-popup__menu',
                },
              }}
              items={[
                {
                  id: ACTIONS.edit,
                  label: t('general.popups.edit'),
                },
                {
                  id: ACTIONS.delete,
                  label: t('general.controls.delete'),
                },
              ]}
              onItemClick={handleMenuItemClick}
            >
              <MoreVertIcon />
            </ButtonMenu>
          )
        )}
        onCancel={handleCancelClick}
      />
      {
        editMode
          ? (
            <Suspense fallback={<Loading />}>
              <EditMode
                pin={data}
                onFinish={handleEditFinish}
                onWarning={handlePinWarning}
              />
            </Suspense>
          )
          : (
            <ReadMode
              pin={data}
              assetsLoaded={imagesPreloaded}
              onChangePinCompleted={handleChangePinCompleted}
              onWarning={handleCommentWarning}
              onSaveComment={handleCommentSave}
            />
          )
      }
      {
        processing
        && <BackdropLoading />
      }
    </Dialog>
  );
};

export default PinDetailsPopup;

import React from 'react';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Typography from '@material-ui/core/Typography';

import Button from '../../Button';

import './index.scss';

const InfoMessage = ({
  classes = {
    root: '',
  },
  children,
  buttonLabel,
  onButtonClick = () => {},
}) => {
  return (
    <div className={`info-message ${classes.root}`}>
      <InfoIcon className="info-message__info-icon" />
      <Typography className="info-message__text">
        {children}
      </Typography>
      {buttonLabel && buttonLabel.length > 0
        && (
          <Button
            variant="outlined"
            onClick={onButtonClick}
            classes={{
              root: 'info-message__button',
            }}
          >
            {buttonLabel}
          </Button>
        )}
    </div>
  );
};

export default InfoMessage;

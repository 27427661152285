import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInYears from 'date-fns/differenceInYears';

import {
  dateStringToMillis,
  millisToDateString,
} from '../../../../helpers/functions/utils/date';
import { Period, Periods } from '../../types/periods';
import { DEFAULT_OFFSET } from '../constants/generatePeriods';
import { CustomError } from '../../../../helpers/functions/utils/errorHandling';

export const generatePeriods = ({
  before: initBefore,
  after: initAfter,
}: Period): Periods => {
  const MIN_PERIOD_IN_MONTHS = 6;
  const MAX_BEFORE_DIFF_IN_YEARS = 10;
  const ERROR_PREFIX = 'Unable to generate periods for satellite images request:';
  let result: Periods;

  if (
    initBefore
    && differenceInYears(Date.now(), new Date(initBefore)) >= MAX_BEFORE_DIFF_IN_YEARS
  ) {
    throw new CustomError(`${ERROR_PREFIX} initial "before" period (${initBefore}) is too far in past`);
  }

  if (
    initAfter
    && initBefore
    && differenceInMonths(new Date(initBefore), new Date(initAfter)) < MIN_PERIOD_IN_MONTHS
  ) {
    throw new CustomError(
      `${ERROR_PREFIX} [${initAfter}, ${initBefore}] period is less than ${MIN_PERIOD_IN_MONTHS} months`,
    );
  }

  if (initAfter && initBefore) {
    const upperLimit = millisToDateString(
      (dateStringToMillis(initAfter) + dateStringToMillis(initBefore)) / 2,
    );

    result = [
      { after: upperLimit, before: initBefore },
      { after: initAfter, before: upperLimit },
    ];
  } else if (initAfter && !initBefore) {
    const upperLimit = millisToDateString(
      (dateStringToMillis(initAfter) + Date.now()) / 2,
    );

    result = [
      { after: upperLimit },
      { after: initAfter, before: upperLimit },
    ];
  } else if (!initAfter && initBefore) {
    const lowerLimit = millisToDateString(
      dateStringToMillis(initBefore) - DEFAULT_OFFSET,
    );

    result = [
      { after: lowerLimit, before: initBefore },
      { before: lowerLimit },
    ];
  } else {
    throw new CustomError(`${ERROR_PREFIX} initial period is invalid`);
  }

  return result;
};

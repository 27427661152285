import React from 'react';
import { useSelector } from 'react-redux';

import { getCommonSteps } from '../../../helpers/functions/steps';
import { selectStepIndex } from '../../../batchAnalyticsSelectors';

export default function Stepper() {
  const stepIndex = useSelector(selectStepIndex);
  const steps = getCommonSteps();
  const step = steps[stepIndex].id;
  const activeSteps = steps.slice(0, stepIndex + 1);
  const StepComponent = steps[stepIndex].component;

  return (
    <StepComponent
      steps={activeSteps}
      stepIndex={stepIndex}
      step={step}
    />
  );
}

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../app/store/helpers/types';
import { selectJDField } from '../field/fieldSelectors';

export const selectProfileHasError = ({ jdProfile }: RootState) => jdProfile.loaded === null && !jdProfile.isLoading;

export const selectProfileLoaded = ({ jdProfile }: RootState) => jdProfile.loaded;

export const selectProfileIsLoading = ({ jdProfile }: RootState) => jdProfile.isLoading;

export const selectProfileIsDisconnecting = ({ jdProfile }: RootState) => jdProfile.isDisconnecting;

export const selectProfileIsReadyToAuthorization = ({ jdProfile }: RootState) => {
  return jdProfile.acceptUrl && jdProfile.providerHealthCheck;
};

export const selectProfileIsAuthorizedAndReady = ({ jdProfile }: RootState) => {
  return jdProfile.hasRefreshToken && jdProfile.providerHealthCheck;
};

export const selectProfileAcceptUrl = ({ jdProfile }: RootState) => jdProfile.acceptUrl;

export const selectProfileHasRefreshToken = ({ jdProfile }: RootState) => jdProfile.hasRefreshToken;

export const selectProfileUpdateAcceptUrl = ({ jdProfile }: RootState) => jdProfile.updateAcceptUrl;

export const selectProfileAccountName = ({ jdProfile }: RootState) => jdProfile.accountName;

export const selectAutoImportAllFieldsOps = ({ jdProfile }: RootState) => jdProfile.autoImportAllFieldsOps;

export const selectAutoUpdateFieldBoundaries = ({ jdProfile }: RootState) => jdProfile.autoUpdateFieldBoundaries;

export const selectAutoUpdateFieldStructure = ({ jdProfile }: RootState) => jdProfile.autoUpdateFieldStructure;

export const selectAutoCreateZonesMapLayers = ({ jdProfile }: RootState) => {
  return jdProfile.autoCreateZonesMapLayers;
};

export const selectAutoCreateSoilMapLayers = ({ jdProfile }: RootState) => {
  return jdProfile.autoCreateSoilMapLayers;
};

export const selectAutoCreateYieldMapLayers = ({ jdProfile }: RootState) => {
  return jdProfile.autoCreateYieldMapLayers;
};

export const selectAutoCreateTopographyMapLayers = ({ jdProfile }: RootState) => {
  return jdProfile.autoCreateTopographyMapLayers;
};

export const selectAutoCreateEquationMapLayers = ({ jdProfile }: RootState) => {
  return jdProfile.autoCreateEquationMapLayers;
};

export const selectFlushDateRefreshToken = ({ jdProfile }: RootState) => {
  return jdProfile.flushDateRefreshToken;
};

export const selectIsDisconnectedBySystem = ({ jdProfile }: RootState) => {
  return jdProfile.flushUserRefreshToken === false;
};

export const selectIsJdHealth = ({ jdProfile }: RootState) => {
  return jdProfile.providerHealthCheck === true;
};

export const selectJDIntegrationData = createSelector(
  selectProfileHasRefreshToken,
  selectProfileUpdateAcceptUrl,
  selectIsJdHealth,
  selectProfileLoaded,
  selectJDField,
  (
    jdProfileIsAuthorized,
    jdProfileUpdateAcceptUrl,
    jdProfileIsHealth,
    jdProfileLoaded,
    jdField,
  ) => {
    return {
      jdProfileIsHealth,
      jdProfileIsAuthorized,
      jdWorkPlanExportAvailable: jdProfileIsAuthorized && !jdProfileUpdateAcceptUrl,
      isSynchronizedJohnDeereField: !!jdField,
      jdProfileLoaded,
      jdProfileReady: jdProfileIsAuthorized && jdProfileIsHealth,
    };
  },
);

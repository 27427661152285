import { API, graphqlOperation } from '@aws-amplify/api';

import { transformThreeDimensionalMaps } from '../../field/helpers/functions/assets';
import save3dMapMutation from '../../field/graphql/mutations/save3dMap.gql';

export const save3dMap = async ({
  fieldUuid,
  vectorAnalysisMapUuid,
  name,
  dataSourceLayer,
}) => {
  return API.graphql(graphqlOperation(save3dMapMutation, {
    input: {
      fieldUuid,
      name,
      vectorAnalysisMapUuid,
      dataSourceLayer,
    },
  }))
    .then((response) => {
      const {
        data: {
          saveThreeDimensionalMap,
        },
      } = response;

      return transformThreeDimensionalMaps([saveThreeDimensionalMap])[0];
    });
};

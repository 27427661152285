import React from 'react';

const TreeText = ({
  item,
}) => {
  return (
    <div className="tree-text">
      {item.content}
    </div>
  );
};

export default TreeText;

import React, {
  Fragment,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import Select from '../Select';
import TextField from '../TextField';
import Button from '../Button';
import { getAreaUnitLabel } from '../../helpers';
import {
  calculateTotalProductVolumes,
  calculateCostPerProduct,
  calculateTotalProductCost,
  updateFeature,
  updateFeatures,
  getRatesData,
} from '../../helpers/analysis';
import {
  convertNumberToFormattedString,
  removeTrailingLeadingZeros,
} from '../../helpers/markup';
import { getProductUnitOptions } from '../../helpers/functions/units/productUnit';
import {
  MAX_RATES_VALUE,
  MAX_RATE_NAME_LENGTH,
} from '../../helpers/constants/entities/vectorAnalysisMap';

import './index.scss';

const getValueOnBlur = (value) => {
  if (value === '') {
    return 0;
  }

  if (typeof value === 'string') {
    return removeTrailingLeadingZeros(value);
  }

  return value;
};

const getRateValue = (stringValue) => {
  const cleanedValue = stringValue.replace(/\D/g, '');
  const parsedValue = parseFloat(cleanedValue) || 0;
  let result = null;

  if (parsedValue <= MAX_RATES_VALUE) {
    result = convertNumberToFormattedString(stringValue);
  }

  return result;
};

const getRatePriceValue = (stringValue) => {
  // 9 requirement from rates editor mockups
  const RATE_INPUT_LENGTH = 9;
  const cleanedValue = stringValue.replace(/\D/g, '');
  let result = null;

  if (cleanedValue.length <= RATE_INPUT_LENGTH) {
    result = convertNumberToFormattedString(stringValue);
  }

  return result;
};

const getDefaultHeaders = (t, areaUnit) => [
  {
    id: 'color',
    label: t('zones-ops.multi-layer.steps.4.results-table.color'),
    align: 'left',
  },
  {
    id: 'id',
    label: 'ID',
    align: 'right',
  },
  {
    id: 'areaAbs',
    label: getAreaUnitLabel(areaUnit),
    align: 'right',
  },
];

const prepareRateValue = (value) => {
  return typeof value === 'number' ? convertNumberToFormattedString(value.toString()) : value;
};

const getTableHead = (
  t,
  ratesHeaders,
  areaUnit,
  onRateHeaderChange,
  onRateRemove,
) => {
  const handleRateHeaderChange = (e, rateIndex) => {
    const { value } = e.target;
    const regexp = /^[0-9a-zA-Z _]+$/;

    if (
      (value.match(regexp) && value.length <= MAX_RATE_NAME_LENGTH)
        || value === ''
    ) {
      onRateHeaderChange(e, rateIndex);
    }
  };

  return (
    <TableRow className="header-row">
      {
        [
          ...getDefaultHeaders(t, areaUnit).map((headCell, headCellIndex, headCells) => {
            return (
              <TableCell
                key={headCell.id}
                className={clsx('header-cell', {
                  'rates-editor__cell_last-default': headCellIndex === headCells.length - 1,
                })}
                align={headCell.align}
              >
                {headCell.label}
              </TableCell>
            );
          }),
          ...(ratesHeaders || []).map((header, rateIndex) => {
            return (
              <TableCell
                key={`rate_${rateIndex}`}
                className="rates-editor__rate-cell"
              >
                <div className="rates-editor__rate-cell-content">
                  <TextField
                    value={header}
                    variant="outlined"
                    classes={{
                      wrapper: 'rate-cell__input',
                    }}
                    InputProps={{
                      classes: {
                        root: 'rate-cell__input-root',
                        input: 'rate-cell__input-input',
                      },
                    }}
                    onChange={(e) => handleRateHeaderChange(e, rateIndex)}
                  />
                  <IconButton
                    size="small"
                    classes={{
                      root: 'rates-editor__rate-cell-header-delete-button',
                    }}
                    onClick={(e) => onRateRemove(e, rateIndex)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              </TableCell>
            );
          }),
        ]
      }
    </TableRow>
  );
};

const getTableRows = ({
  data,
  colors,
  selectedZone,
  totalArea,
  onRowClick,
  onChange,
  onFocus,
  onBlur,
}) => {
  return data.features.map((entry, featureIndex) => {
    const {
      zone,
      attributes: {
        area,
        rates = [],
      },
    } = entry.properties;

    return (
      <TableRow
        key={featureIndex}
        className={clsx('row', {
          row_selected: zone === selectedZone,
        })}
        onClick={() => onRowClick(zone)}
      >
        <TableCell className="cell color-cell">
          <div
            className="color"
            style={{ backgroundColor: colors[zone - 1] }}
          >
          </div>
        </TableCell>
        <TableCell
          align="right"
          className="cell"
        >
          {zone}
        </TableCell>
        <TableCell
          align="right"
          className="cell rates-editor__cell_last-default"
        >
          {
            `${area.toFixed(2)} (${((100 * area) / totalArea).toFixed(2)}%)`
          }
        </TableCell>
        {
          rates.map((rate, rateIndex) => {
            return (
              <TableCell
                key={rateIndex}
                className="rates-editor__rate-cell"
              >
                <div className="rates-editor__rate-cell-content">
                  <TextField
                    value={prepareRateValue(rate)}
                    variant="outlined"
                    classes={{
                      wrapper: 'rate-cell__input',
                    }}
                    InputProps={{
                      classes: {
                        root: 'rate-cell__input-root',
                        input: 'rate-cell__input-input',
                      },
                    }}
                    onChange={(e) => onChange(e, featureIndex, rateIndex)}
                    onFocus={() => onFocus(featureIndex, rateIndex)}
                    onBlur={() => onBlur(featureIndex, rateIndex)}
                  />
                </div>
              </TableCell>
            );
          })
        }
      </TableRow>
    );
  });
};

const getTableFooterRow = ({
  title,
  data,
  getCellContent,
}) => {
  return (
    <TableRow
      classes={{
        root: 'row footer-row',
      }}
    >
      <TableCell
        colSpan={3}
        classes={{
          root: 'cell footer-cell',
        }}
      >
        {title}
      </TableCell>
      {
        data.map((value, index) => {
          return (
            <TableCell
              key={index}
              className="cell footer-cell rates-editor__rate-cell"
            >
              {
                getCellContent
                  ? getCellContent(value, index)
                  : (
                    <div className="rates-editor__footer-cell-content">
                      {convertNumberToFormattedString(value.toFixed(2))}
                    </div>
                  )
              }
            </TableCell>
          );
        })
      }
    </TableRow>
  );
};

const getTableFooterRows = ({
  t,
  data,
  totalArea,
  units,
  onChange,
  onFocus,
  onBlur,
}) => {
  const {
    ratesPrices,
    ratesUnits,
  } = data.features[0].properties.attributes;
  const totalProductVolumes = calculateTotalProductVolumes(data);
  const averageProductRates = totalProductVolumes.map((total) => {
    return total / totalArea;
  });
  const costsPerProduct = calculateCostPerProduct(ratesPrices, totalProductVolumes);
  const totalProductCost = calculateTotalProductCost(costsPerProduct);

  return (
    <Fragment>
      {
        getTableFooterRow({
          title: t('zones-map.rates.total-product-volume'),
          data: totalProductVolumes,
        })
      }
      {
        getTableFooterRow({
          title: t('zones-map.rates.average-product-rate'),
          data: averageProductRates,
        })
      }
      {
        getTableFooterRow({
          title: t('general.labels.unit'),
          data: ratesUnits,
          getCellContent: (value, index) => (
            <Select
              classes={{
                root: 'rate-cell__select',
              }}
              options={units}
              value={value}
              onChange={(e) => onChange(e, index, 'unit')}
            />
          ),
        })
      }
      {
        getTableFooterRow({
          title: t('zones-map.rates.price-per-unit'),
          data: ratesPrices,
          getCellContent: (value, index) => (
            <TextField
              value={prepareRateValue(value)}
              variant="outlined"
              classes={{
                wrapper: 'rate-cell__input',
              }}
              InputProps={{
                classes: {
                  root: 'rate-cell__input-root',
                  input: 'rate-cell__input-input',
                },
              }}
              onChange={(e) => onChange(e.target.value, index, 'price')}
              onFocus={() => onFocus(index)}
              onBlur={() => onBlur(index)}
            />
          ),
        })
      }
      {
        getTableFooterRow({
          title: t('zones-map.rates.cost-per-product'),
          data: costsPerProduct,
        })
      }
      <TableRow
        classes={{
          root: 'sum-row row',
        }}
      >
        <TableCell
          colSpan={ratesPrices.length + 2}
          align={ratesPrices.length <= 1 ? 'left' : 'right'}
          classes={{
            root: 'footer-cell cell',
          }}
        >
          {t('zones-map.rates.total-product-cost')}
        </TableCell>
        {
          <TableCell className="footer-cell cell rates-editor__rate-cell">
            <div className="rates-editor__footer-cell-content">
              {convertNumberToFormattedString(totalProductCost.toFixed(2))}
            </div>
          </TableCell>
        }
      </TableRow>
    </Fragment>
  );
};

const RatesEditor = ({
  forwardedRef,
  areaUnit,
  attributes,
  colors,
  totalArea,
  selectedZone,
  classes = {},
  onAttributesChange,
  onSelectedZoneChange = () => {},
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState(attributes);
  const units = getProductUnitOptions();

  useEffect(() => {
    setData(attributes);
  }, [attributes]);

  if (!data) {
    return null;
  }

  const ratesHeaders = getRatesData(data);

  const updateData = (features) => {
    setData({
      ...data,
      features,
    });
  };

  const submitNewFeatures = (newFeatures) => {
    onAttributesChange({
      ...data,
      features: newFeatures,
    });
  };

  const trySubmitNewFeatures = (newFeatures, featureIndex, rateIndex, prop) => {
    const ftrInd = featureIndex || 0;
    const newFtrValue = newFeatures[ftrInd].properties.attributes[prop][rateIndex];
    const oldFtrValue = attributes.features[ftrInd].properties.attributes[prop][rateIndex];

    if (newFtrValue === oldFtrValue) {
      updateData(newFeatures);

      return;
    }

    submitNewFeatures(newFeatures);
  };

  const onRateInputFocus = (featureIndex, rateIndex) => {
    const updatedFeatures = updateFeature(
      data.features,
      featureIndex,
      (rate, index) => {
        return index === rateIndex && rate === 0 ? '' : rate;
      },
    );

    updateData(updatedFeatures);
  };

  const onRateInputBlur = (featureIndex, rateIndex) => {
    const updatedFeatures = updateFeature(
      data.features,
      featureIndex,
      (rate, index) => {
        let result = rate;

        if (index === rateIndex) {
          result = getValueOnBlur(rate);
        }

        return result;
      },
    );

    trySubmitNewFeatures(updatedFeatures, featureIndex, rateIndex, 'rates');
  };

  const onRatePriceInputFocus = (rateIndex) => {
    const updatedFeatures = updateFeatures(
      data.features,
      (attrs) => {
        return {
          ...attrs,
          ratesPrices: attrs.ratesPrices.map((price, index) => {
            let result = price;

            if (index === rateIndex) {
              result = price === 0 ? '' : price;
            }

            return result;
          }),
        };
      },
    );

    updateData(updatedFeatures);
  };

  const onRatePriceInputBlur = (rateIndex) => {
    const updatedFeatures = updateFeatures(
      data.features,
      (attrs) => {
        return {
          ...attrs,
          ratesPrices: attrs.ratesPrices.map((price, index) => {
            let result = price;

            if (index === rateIndex) {
              result = getValueOnBlur(price);
            }

            return result;
          }),
        };
      },
    );

    trySubmitNewFeatures(updatedFeatures, 0, rateIndex, 'ratesPrices');
  };

  const onRateHeaderChange = (e, rateIndex) => {
    const updatedFeatures = updateFeatures(
      data.features,
      (attrs) => {
        return {
          ...attrs,
          ratesHeaders: attrs.ratesHeaders.map((header, index) => {
            return index === rateIndex ? e.target.value : header;
          }),
        };
      },
    );

    trySubmitNewFeatures(updatedFeatures, 0, rateIndex, 'ratesHeaders');
  };

  const onRateChange = (e, featureIndex, rateIndex) => {
    const { value } = e.target;
    const rate = getRateValue(value);

    if (rate != null) {
      const updatedFeatures = updateFeature(
        data.features,
        featureIndex,
        (prevRate, index) => {
          return index === rateIndex ? rate : prevRate;
        },
      );

      updateData(updatedFeatures);
    }
  };

  const onRateDataChange = (value, rateIndex, type) => {
    let prop;
    let rateDataValue;

    if (type === 'unit') {
      prop = 'ratesUnits';
      rateDataValue = value;
    } else if (type === 'price') {
      prop = 'ratesPrices';
      rateDataValue = getRatePriceValue(value);
    } else {
      return;
    }

    if (rateDataValue != null) {
      const updatedFeatures = updateFeatures(
        data.features,
        (attrs) => {
          return {
            ...attrs,
            [prop]: attrs[prop].map((prevRateDataValue, index) => {
              return index === rateIndex ? rateDataValue : prevRateDataValue;
            }),
          };
        },
      );

      trySubmitNewFeatures(updatedFeatures, 0, rateIndex, prop);
    }
  };

  const onClickAddRate = () => {
    const updatedFeatures = updateFeatures(
      data.features,
      (attrs) => {
        return {
          ...attrs,
          ratesPrices: [
            ...attrs.ratesPrices,
            0,
          ],
          ratesUnits: [
            ...attrs.ratesUnits,
            units[0].value,
          ],
          ratesHeaders: [
            ...attrs.ratesHeaders,
            `Product ${attrs.ratesHeaders.length + 1}`,
          ],
          rates: [
            ...attrs.rates,
            0,
          ],
        };
      },
    );

    submitNewFeatures(updatedFeatures);
  };

  const onRateRemove = (_e, rateIndex) => {
    const updatedFeatures = updateFeatures(
      data.features,
      (attrs) => {
        const filterPredicat = (_v, ind) => ind !== rateIndex;

        return {
          ...attrs,
          ratesPrices: attrs.ratesPrices.filter(filterPredicat),
          ratesHeaders: attrs.ratesHeaders.filter(filterPredicat),
          rates: attrs.rates.filter(filterPredicat),
          ratesUnits: attrs.ratesUnits.filter(filterPredicat),
        };
      },
    );

    submitNewFeatures(updatedFeatures);
  };

  return (
    <div
      className={clsx('rates-editor', classes.root)}
      ref={forwardedRef}
    >
      <Table
        classes={{
          root: 'rates-editor__root',
        }}
      >
        <TableHead>
          {
            getTableHead(
              t,
              ratesHeaders,
              areaUnit,
              onRateHeaderChange,
              onRateRemove,
            )
          }
        </TableHead>
        <TableBody>
          {
            getTableRows({
              data,
              colors,
              totalArea,
              selectedZone,
              onRowClick: onSelectedZoneChange,
              onChange: onRateChange,
              onFocus: onRateInputFocus,
              onBlur: onRateInputBlur,
            })
          }
        </TableBody>
        <TableFooter>
          {
            ratesHeaders.length !== 0
              && getTableFooterRows({
                t,
                data,
                totalArea,
                units,
                onChange: onRateDataChange,
                onFocus: onRatePriceInputFocus,
                onBlur: onRatePriceInputBlur,
              })
          }
        </TableFooter>
      </Table>
      {
        // 3 - max amount of custom rates
        ratesHeaders.length < 3
        && (
          <div className="rates-editor__add-button-wrapper">
            <Button
              startIcon={<AddIcon />}
              classes={{
                root: 'rates-editor__add-button',
              }}
              onClick={onClickAddRate}
            >
              {t('zones-map.rates.product')}
            </Button>
          </div>
        )
      }
    </div>
  );
};

export default React.forwardRef((props, ref) => (
  <RatesEditor
    forwardedRef={ref}
    {...props}
  />
));

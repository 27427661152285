import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function RemoveLabelsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M11.2757 5L13.2775 7H10V13H19.283L19.4174 13.1343L12.1343 20.41L4 12.2757V5H11.2757ZM17.2812 11H20V9H17H15.2794H12V11H17H17.2812ZM5.48031 7.59054C5.48031 8.20487 5.97621 8.70077 6.59054 8.70077C7.20487 8.70077 7.70077 8.20487 7.70077 7.59054C7.70077 6.97621 7.20487 6.48031 6.59054 6.48031C5.97621 6.48031 5.48031 6.97621 5.48031 7.59054Z"/>
    </SvgIcon>
  );
}

import React, { useState } from 'react';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Checkbox from '../../../Checkbox';

import './index.scss';

const getTableHead = (headers) => {
  return (
    <TableRow className="header-row">
      {headers.map((headCell) => {
        return (
          <TableCell
            key={headCell.id}
            className={clsx('header-cell', headCell.class)}
            align={headCell.align}
          >
            {headCell.label}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const getCell = (cell) => {
  let content;
  let className = '';

  if (cell.isCheckbox) {
    content = (
      <Checkbox value={cell.checked} />
    );
  } else if (cell.color) {
    className = 'color-cell';
    content = (
      <span
        className="color"
        style={{ backgroundColor: cell.color }}
      ></span>
    );
  } else if (cell.text) {
    content = (
      <span className="text">{cell.text} {
        cell.secondaryText
        && <span className="text_secondary">({cell.secondaryText})</span>
      }
      </span>
    );
  }

  return {
    content,
    className,
  };
};

const getTableRows = ({
  rows,
  headers,
  activeRow,
  highlightedRow,
  onRowClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  return rows.map((row) => {
    const {
      id,
      data,
    } = row;

    return (
      <TableRow
        key={id}
        className={clsx('row', {
          row_active: activeRow === id,
          row_highlighted: highlightedRow === id,
        })}
        onClick={() => onRowClick(id)}
        onMouseLeave={onMouseLeave}
        onMouseEnter={() => onMouseEnter(id)}
      >
        {headers.map((header, ind) => {
          const {
            content,
            className,
          } = getCell(data[header.id]);

          return (
            <TableCell
              key={ind}
              className={`cell ${className}`}
            >
              {content}
            </TableCell>
          );
        })}
      </TableRow>
    );
  });
};

const getTable = ({
  headers,
  rows,
  activeRow,
  highlightedRow,
  onRowClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <Table>
      <TableHead>
        {getTableHead(headers)}
      </TableHead>
      <TableBody>
        {getTableRows({
          rows,
          headers,
          activeRow,
          highlightedRow,
          onRowClick,
          onMouseEnter,
          onMouseLeave,
        })}
      </TableBody>
    </Table>
  );
};

const GeoMapsTable = ({
  forwardedRef,
  activeRow,
  includeSelects,
  headers,
  rows,
  onRowClick = () => {},
}) => {
  const [highlightedRow, setHighlightedRow] = useState(null);

  if (!headers || !rows) {
    return null;
  }

  const onMouseEnter = (id) => {
    setHighlightedRow(id);
  };

  const onMouseLeave = () => {
    setHighlightedRow(null);
  };

  const sliceInd = includeSelects ? 2 : 1;
  const fixedHeaders = headers.slice(0, sliceInd);
  const scrollHeaders = headers.slice(sliceInd);

  return (
    <div
      className="geo-maps-table"
      ref={forwardedRef}
    >
      <div className="fixed-container">
        {getTable({
          headers: fixedHeaders,
          rows,
          activeRow,
          highlightedRow,
          onRowClick,
          onMouseLeave,
          onMouseEnter,
        })}
      </div>
      <div className="scroll-container">
        {getTable({
          headers: scrollHeaders,
          rows,
          activeRow,
          highlightedRow,
          onRowClick,
          onMouseLeave,
          onMouseEnter,
        })}
      </div>
    </div>
  );
};

export default React.forwardRef((props, ref) => (
  <GeoMapsTable
    forwardedRef={ref}
    {...props}
  />
));

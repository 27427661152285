import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import '../Autocomplete/index.scss';

class AppAutocomplete extends Component {
  state = {
    value: this.props.value,
  };

  handleChange = (_event, newValue, reason) => {
    const { onChange } = this.props;
    const { value } = this.state;

    if (newValue !== value) {
      if (reason === 'clear' || newValue) {
        this.setState({ value: newValue });
        onChange(newValue);
      }
    }
  };

  render() {
    const {
      disabled = false,
      classes = {},
      options = [],
      placeholder,
    } = this.props;
    const { value } = this.state;

    return (
      <Autocomplete
        disabled={disabled}
        freeSolo
        multiple={false}
        options={options}
        disableCloseOnSelect
        value={value}
        classes={{
          root: `autocomplete ${classes.root || ''}`,
          endAdornment: 'autocomplete__clear-icon',
          noOptions: 'autocomplete__no-options',
          option: 'autocomplete__option',
        }}
        onChange={this.handleChange}
        popupIcon={<Fragment />}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              classes={{
                root: 'autocomplete__text-field-root',
              }}
              InputProps={{
                ...params.InputProps,
                classes: {
                  root: 'autocomplete__input-root',
                  input: 'autocomplete__input-input',
                  focused: 'autocomplete__input_focused',
                  notchedOutline: 'autocomplete__input-outline',
                },
                startAdornment: (
                  <Fragment>
                    <SearchIcon className="autocomplete__search-icon"/>
                    {params.InputProps.startAdornment}
                  </Fragment>
                ),
              }}
              placeholder={placeholder}
              size="small"
              variant="outlined"
            />
          );
        }}
      />
    );
  }
}

export default withTranslation()(AppAutocomplete);

import { API, graphqlOperation } from '@aws-amplify/api';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { DocumentNode } from 'graphql';
import type { GraphQLOptions } from '@aws-amplify/api-graphql';

export const graphqlBaseQuery = (): BaseQueryFn<
{ document: string | DocumentNode; variables?: any; withApiKey?: boolean },
unknown,
unknown | undefined,
{}
> => {
  return async ({ document, variables, withApiKey }) => {
    let result;

    try {
      const options: GraphQLOptions = {
        ...graphqlOperation(document, variables),
      };

      if (withApiKey) {
        options.authMode = 'API_KEY';
      }

      result = await API.graphql(options);
    } catch (error) {
      result = {
        error,
      };
    }

    return result;
  };
};

export const tryGetApiErrors = (error: unknown): Error[] => {
  let typedErrors: Error[];

  if (
    error
    && typeof error === 'object'
    && 'errors' in error
    && Array.isArray(error.errors)
  ) {
    typedErrors = error.errors as Error[];
  } else {
    typedErrors = [error] as [Error];
  }

  return typedErrors;
};

import gql from 'graphql-tag';

import { AreaUnit } from '../../../user/helpers/constants/user';
import { QueryFragment } from '../../types/api';
import { FieldFragment } from '../../helpers/constants/fragments';

export const FIELD_FRAGMENTS = {
  [FieldFragment.filterField]: (): QueryFragment => {
    const FRAGMENT_NAME = FieldFragment.filterField;

    return {
      name: FRAGMENT_NAME,
      fragment: gql`
        fragment ${FRAGMENT_NAME} on Field {
          uuid
          farmUuid
          name
        }`,
    };
  },
  [FieldFragment.mapViewField]: (areaUnit: AreaUnit): QueryFragment => {
    const FRAGMENT_NAME = FieldFragment.mapViewField;

    return {
      name: FRAGMENT_NAME,
      fragment: gql`
        fragment ${FRAGMENT_NAME} on Field {
          uuid
          farmUuid
          area(unit: ${areaUnit})
          status
          name
          labels {
            name
            value
          }
          createdDate
          updatedDate
          centroid {
            latitude
            longitude
          }
          geoMaps {
            shortName
            url
          }
          boundaryUrl
        }`,
    };
  },
  [FieldFragment.tableViewField]: (areaUnit: AreaUnit): QueryFragment => {
    const FRAGMENT_NAME = FieldFragment.tableViewField;

    return {
      name: FRAGMENT_NAME,
      fragment: gql`
        fragment ${FRAGMENT_NAME} on Field {
          uuid
          farmUuid
          status
          name
          labels {
            name
            value
          }
          createdDate
          updatedDate
          area(unit: ${areaUnit})
          centroid {
            latitude
            longitude
          }
          geoMaps {
            shortName
            url
          }
        }`,
    };
  },
};

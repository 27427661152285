import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import { getCellClasses } from '../../../helpers/functions/cell';

import './index.scss';

const ColorCell = ({
  row = {},
  headCell = {},
}) => {
  return (
    <TableCell
      align={headCell.horizontalAlign || 'left'}
      className={getCellClasses('color-cell', headCell, row)}
    >
      <div
        className="color-cell__thumbnail"
        style={{
          backgroundColor: row[headCell.id],
        }}
      >
      </div>
    </TableCell>
  );
};

export default ColorCell;

import React from 'react';
import Typography from '@material-ui/core/Typography';

import './index.scss';

export default function KeyValue({
  title,
  value,
}: {
  title: string,
  value: string,
}) {
  return (
    <div className="key-value">
      <Typography className="key-value__title">{title}</Typography>
      <Typography className="key-value__value">{value}</Typography>
    </div>
  );
}

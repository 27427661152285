export enum SatelliteImageIndex {
  EVI2 = 'EVI2',
  NDVI = 'NDVI',
  RVI = 'RVI',
  LAI = 'LAI',
  OSAVI = 'OSAVI',
  SAVI = 'SAVI',
  GNDVI = 'GNDVI',
  IPVI = 'IPVI',
  GCI = 'GCI',
  WDRVI = 'WDRVI',
  RCI = 'RCI',
  SBI = 'SBI',
  GFPI = 'GFPI',
  NDMI = 'NDMI',
  MSI = 'MSI',
  CCCI = 'CCCI',
  MCARI = 'MCARI',
  TCARI = 'TCARI',
  MCARI_OSAVI = 'MCARI_OSAVI',
  TCARI_OSAVI = 'TCARI_OSAVI',
  MCARI1 = 'MCARI1',
}

export const DEFAULT_SATELLITE_INDEX = 'nir';

import i18n from 'i18next';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { selectLanguage } from '../../user/userSelectors';
import { selectUpdates } from './userSettingsSelectors';
import { updateData } from '../../user/userSlice';
import { errorNotify } from '../../notifications/helpers/functions/notify';
import { CustomError } from '../../../helpers/functions/utils/errorHandling';

const initialState = {
  isProcessing: false,
  updates: {
    language: '',
    areaUnit: '',
  },
};

export const saveUpdates = createAsyncThunk(
  'userSettings/saveUpdates',
  async (_, { getState, dispatch }) => {
    try {
      const state = getState();
      const oldLanguage = selectLanguage(state);
      const updates = selectUpdates(state);

      const {
        payload: {
          language,
        },
      } = await dispatch(updateData(updates));

      if (language && language !== oldLanguage) {
        i18n.changeLanguage(language);
      }
    } catch (error) {
      errorNotify({
        error: new CustomError('Unable to save update for user settings.', {
          cause: error,
        }),
        dispatch,
      });
    }
  },
);

export const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    updateLanguage(state, action) {
      state.updates.language = action.payload;
    },
    updateAreaUnit(state, action) {
      state.updates.areaUnit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveUpdates.pending, (state) => {
        state.isProcessing = true;
      })
      .addCase(saveUpdates.fulfilled, (state) => {
        state.isProcessing = false;
        state.updates = initialState.updates;
      });
  },
});

export const {
  updateAreaUnit,
  updateLanguage,
} = userSettingsSlice.actions;

export default userSettingsSlice.reducer;

import React, {
  Ref,
  forwardRef,
  memo,
} from 'react';
import ListElement, {
  ListProps,
} from '@material-ui/core/List';

const List = (
  { children, ...props } : ListProps,
  ref: Ref<HTMLDivElement>,
) => {
  return (
    <ListElement
      {...props}
      ref={ref as Ref<HTMLUListElement>}
    >
      {children}
    </ListElement>
  );
};

export default memo(forwardRef(List));

import { TreeNode } from '../../types/node';

/**
 * Calculates the selection status of the parent node based on the selection status of its children.
 * The function iterates over the children of the node and checks if any child node is selected.
 * If at least one child node is selected, it calculates the partialRowsSelection and fullRowsSelection
 * values based on the selection status of the children.
 * If no child node is selected, it returns false for both partialRowsSelection and fullRowsSelection.
 *
 * @param {TreeNode} options.node - The parent node.
 * @param {Record<string, number>} options.checked - The checked child nodes.
 * @returns {Object} The parent selection state.
 * @property {boolean} [partialRowsSelection] - Indicates if there is partial rows selection.
 * @property {boolean} [fullRowsSelection] - Indicates if there is full rows selection.
 */
const getParentSelection = ({ node, checked }: {
  node?: TreeNode;
  checked: Record<string, number>;
}): {
  partialRowsSelection?: boolean;
  fullRowsSelection?: boolean;
} => {
  let isChildNodeSelected = false;

  const selection = node?.children?.reduce(
    (acc, childNode) => {
      if (!childNode.selectable) {
        return acc;
      }

      isChildNodeSelected = true;

      return {
        partialRowsSelection:
          acc.partialRowsSelection || checked[childNode.id] === 1 || checked[childNode.id] === 2,
        fullRowsSelection:
          acc.fullRowsSelection && checked[childNode.id] === 2,
      };
    },
    {
      partialRowsSelection: false,
      fullRowsSelection: true,
    },
  ) || {};

  if (!isChildNodeSelected) {
    return {
      partialRowsSelection: false,
      fullRowsSelection: false,
    };
  }

  return selection;
};

/**
 * Gets the selected value of the provided node based on the node and the checked state.
 *
 * @param node - The current node.
 * @param checked - The checked state of the tree nodes.
 * @returns The selected value of the parent node:
 * - 2 if all child nodes are selected.
 * - 1 if some child nodes are selected.
 * - 0 if no child nodes are selected.
 */
export const getParentSelectedValue = (node: TreeNode, checked: Record<string, number>): number => {
  const { partialRowsSelection, fullRowsSelection } = getParentSelection({ node, checked });

  if (fullRowsSelection) {
    return 2;
  }

  if (partialRowsSelection) {
    return 1;
  }

  return 0;
};

import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function ExportMapLayerIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.6803 15.8534V14.3883C18.3724 14.4433 18.9772 14.8118 19.3523 15.3516C19.6055 15.716 19.7541 16.1585 19.7541 16.6352C19.7541 17.0153 19.66 17.3752 19.4919 17.6854L19.4277 17.8037L20.2745 18.6504L20.3951 18.4603C20.729 17.9337 20.9262 17.308 20.9262 16.6352C20.9262 15.8865 20.6864 15.1941 20.2793 14.6306C19.8681 14.0613 19.2862 13.6236 18.6086 13.3922C18.3151 13.292 18.0037 13.2304 17.6803 13.2137V11.7368L15.622 13.7951L16.702 14.8751L17.6803 15.8534Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.3197 21.5337V20.0568C16.3377 20.006 15.4654 19.5421 14.873 18.8355C14.4293 18.3062 14.1427 17.6408 14.0847 16.9108C14.0775 16.8199 14.0738 16.728 14.0738 16.6352C14.0738 15.9625 14.271 15.3368 14.6049 14.8102L14.7255 14.6201L15.5723 15.4668L15.5081 15.5851C15.4512 15.6901 15.4028 15.8008 15.3636 15.9161C15.2871 16.1413 15.2459 16.3839 15.2459 16.6352C15.2459 17.2009 15.455 17.7183 15.8001 18.1144C16.1765 18.5465 16.7148 18.8342 17.3197 18.8822V17.4171L19.378 19.4754L17.3197 21.5337Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M18.3404 11.0638L19.36 10.27L21 9L12 2L3 9L4.63 10.27L12 16L12.0241 15.9812C12.2856 13.1869 14.6374 11 17.5 11C17.7857 11 18.0664 11.0218 18.3404 11.0638ZM11.99 18.54L12.3007 18.2983C12.5254 18.948 12.8684 19.5424 13.3039 20.0558L12 21.07L3 14.07L4.62 12.81L11.99 18.54Z" />
    </SvgIcon>
  );
}

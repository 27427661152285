import React from 'react';

import { AssetGroupType } from '../../../../../../../helpers/constants/entities/asset';
import FiltersPanel from '../../../../../filters/components/FiltersPanel';
import SatelliteFilter from '../../../../../filters/components/SatelliteFilter';
import {
  DEFAULT_CREATE_ANALYSIS_SATELLITE_FILTER as DEFAULT_SAT_IMAGES_FILTER,
} from '../../../../../filters/helpers/constants/satelliteImage';
import { SatelliteFilters as ISatelliteFilters } from '../../../../../filters/types/satelliteImage';
import { TransformedSatelliteImage } from '../../../../../../satelliteImages/types/satelliteImage';

export default function SatelliteFilters({
  satelliteImages,
  filters,
  filterExpanded,
  onChangeFilters,
}: {
  satelliteImages: TransformedSatelliteImage[],
  filters: ISatelliteFilters,
  filterExpanded: boolean,
  onChangeFilters: (filters: ISatelliteFilters) => void,
}) {
  return (
    <FiltersPanel
      type={AssetGroupType.satelliteImages}
      value={filters}
      isExpanded={filterExpanded}
      onFiltersClear={() => onChangeFilters(DEFAULT_SAT_IMAGES_FILTER)}
    >
      <SatelliteFilter
        value={filters}
        images={satelliteImages}
        onFiltersClear={() => onChangeFilters(DEFAULT_SAT_IMAGES_FILTER)}
        onChange={onChangeFilters}
      />
    </FiltersPanel>
  );
}

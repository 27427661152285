export const UNITLESS_ATTRIBUTE_UNIT = 'UNITLESS';

export const ATTRIBUTE_UNIT_TO_I18N_LABEL = {
  PCT: 'general.units.pct',
  per_PCT: 'general.units.per-pct',
  PPM: 'general.units.ppm',
  COUNT: 'general.units.count',
  IN: 'general.units.in',
  FT: 'general.units.ft',
  YD: 'general.units.yd',
  MI: 'general.units.mi',
  MM: 'general.units.mm',
  CM: 'general.units.cm',
  M: 'general.units.m',
  KM: 'general.units.km',
  KG: 'general.units.kg',
  LB: 'general.units.lb',
  TON: 'general.units.ton',
  T: 'general.units.t',
  CWT: 'general.units.cwt',
  DG: 'general.units.dg',
  G: 'general.units.g',
  OZM: 'general.units.ozm',
  MG: 'general.units.mg',
  HR: 'general.units.hr',
  SEC: 'general.units.sec',
  MS: 'general.units.ms',
  L: 'general.units.l',
  LB_per_AC: 'general.units.lb-per-ac',
  T_per_AC: 'general.units.t-per-ac',
  LB_per_FT2: 'general.units.lb-per-ft2',
  KG_per_HA: 'general.units.kg-per-ha',
  T_per_HA: 'general.units.t-per-ha',
  KG_per_M2: 'general.units.kg-per-m2',
  KG_per_CM2: 'general.units.kg-per-cm2',
  BU_per_AC: 'general.units.bu-per-ac',
  BALE_per_M2: 'general.units.bale-per-m2',
  BALE_per_AC: 'general.units.bale-per-ac',
  BALE_per_HA: 'general.units.bale-per-ha',
  SEEDS_per_AC: 'general.units.seeds-per-ac',
  THNDSEEDS_per_AC: 'general.units.thndseeds-per-ac',
  SEEDS_per_FT2: 'general.units.seeds-per-ft2',
  SEEDS_per_HA: 'general.units.seeds-per-ha',
  THNDSEEDS_per_HA: 'general.units.thndseeds-per-ha',
  SEEDS_per_M2: 'general.units.seeds-per-m2',
  OZM_per_AC: 'general.units.ozm-per-ac',
  OZM_per_FT2: 'general.units.ozm-per-ft2',
  G_per_HA: 'general.units.g-per-ha',
  MG_per_HA: 'general.units.mg-per-ha',
  DG_per_HA: 'general.units.dg-per-ha',
  DG_per_MM2: 'general.units.dg-per-mm2',
  MG_per_M2: 'general.units.mg-per-m2',
  G_per_M2: 'general.units.g-per-m2',
  GAL_per_AC: 'general.units.gal-per-ac',
  QT_per_AC: 'general.units.qt-per-ac',
  PT_per_AC: 'general.units.pt-per-ac',
  FLOZ_per_AC: 'general.units.floz-per-ac',
  L_per_HA: 'general.units.l-per-ha',
  ML_per_HA: 'general.units.ml-per-ha',
  KL_per_HA: 'general.units.kl-per-ha',
  UL_per_HA: 'general.units.ul-per-ha',
  DL_per_HA: 'general.units.dl-per-ha',
  M3_per_HA: 'general.units.m3-per-ha',
  ML_per_M2: 'general.units.ml_per_m2',
  L_per_M2: 'general.units.l_per_m2',
  MG_per_KG: 'general.units.mg-per-kg',
  MEQ_per_100G: 'general.units.meq-per-100g',
  MS_per_CM: 'general.units.ms-per-cm',
  ARCDEG: 'general.units.arcdeg',
  MM_per_SEC: 'general.units.mm_per_sec',
  M_per_SEC: 'general.units.m_per_sec',
  KM_per_HR: 'general.units.km_per_hr',
  MI_per_HR: 'general.units.mi_per_hr',
  FT_per_HR: 'general.units.ft_per_hr',
  M_per_HR: 'general.units.m_per_hr',
  M2: 'general.units.m2',
  AC: 'general.units.ac',
  HA: 'general.units.ha',
  FT2: 'general.units.ft2',
  BU: 'general.units.bu',
  POINTS_per_SEC: 'general.units.points_per_sec',
  MG_per_SEC: 'general.units.mg_per_sec',
  AC_per_HR: 'general.units.ac_per_hr',
  L_per_HR: 'general.units.l_per_hr',
  LB_per_HR: 'general.units.lb_per_hr',
  MG_per_L: 'general.units.mg_per_l',
  KG_per_M3: 'general.units.kg_per_m3',
  LB_per_BU: 'general.units.lb_per_bu',
  BAR: 'general.units.bar',
  C: 'general.units.c',
  K: 'general.units.k',
  F: 'general.units.f',
  LBF: 'general.units.lbf',
  EUR_per_HA: 'general.units.eur_per_ha',
  EUR_per_AC: 'general.units.eur_per_ac',
  USD_per_HA: 'general.units.usd_per_ha',
  USD_per_AC: 'general.units.usd_per_ac',
  GAL: 'general.units.gal',
} as const;

export const UNTRANSLATABLE_ATTRIBUTE_UNITS = [
  UNITLESS_ATTRIBUTE_UNIT,
] as const;

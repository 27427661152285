import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { VectorAnalysisMapType } from '../../../helpers/constants/entities/vectorAnalysisMap';
import { selectCurrentEquation } from '../../equations/equationsSelectors';
import { updateCurrentEquation, verifyEquation } from '../../equations/equationsSlice';
import { selectActiveDataVariable, selectDataVariables } from './createEquationBasedAnalysisSelectors';

const initialState = {
  activeVariable: '',
  // TODO: manage data variables via equations slice
  dataVariables: [], /* InputDataVariable[] */
  type: VectorAnalysisMapType.general,
  name: '',
  gridSize: {
    x: 30,
    y: 30,
  },
  useInterpolatedData: false,
  _changed: false,
};

// Workaround, will be removed when data variables are managed via equations slice
export const initDataVariables = () => (dispatch, getState) => {
  const state = getState();
  const { dataVariables } = selectCurrentEquation(state);

  dispatch(setDataVariables(dataVariables));
};

export const upsertDataVariable = (dataVariable) => (dispatch, getState) => {
  const state = getState();
  const currentEquation = selectCurrentEquation(state);
  const activeDataVariable = selectActiveDataVariable(state);

  if (activeDataVariable?.variable) {
    dispatch(updateActiveDataVariable(dataVariable));
  } else {
    dispatch(pushDataVariable(dataVariable));
  }

  const updatedState = getState();
  const updatedDataVariables = selectDataVariables(updatedState);
  const variablesNames = updatedDataVariables.map(({ variable }) => variable);

  dispatch(updateCurrentEquation({
    dataVariables: variablesNames,
  }));
  dispatch(verifyEquation({
    equationAsText: currentEquation.equationAsText,
    equationResultVariable: currentEquation.equationResultVariable,
    dataVariables: variablesNames,
    useNumpy: currentEquation.useNumpy,
  }));
};

export const deleteDataVariable = createAsyncThunk(
  'createEquationBasedAnalysis/deleteVariable',
  async (payload, { dispatch, getState }) => {
    const { dataVariables } = selectCurrentEquation(getState());
    dispatch(removeDataVariable(payload));
    dispatch(updateCurrentEquation({
      dataVariables: dataVariables.filter((d) => d !== payload),
    }));

    const state = getState();
    const currentEquation = selectCurrentEquation(state);

    dispatch(verifyEquation({
      equationAsText: currentEquation.equationAsText,
      equationResultVariable: currentEquation.equationResultVariable,
      dataVariables: currentEquation.dataVariables,
      useNumpy: currentEquation.useNumpy,
    }));
  },
);

export const createEquationBasedAnalysisSlice = createSlice({
  name: 'createEquationBasedAnalysis',
  initialState,
  reducers: {
    markAsChanged(state) {
      state._changed = true;
    },
    setActiveVariable(state, action) {
      state.activeVariable = action.payload;
    },
    resetActiveVariable(state) {
      state.activeVariable = initialState.activeVariable;
    },
    setDataVariables(state, action) {
      const newVariables = action.payload.map((variable) => ({ variable }));
      const areVariablesEqual = state.dataVariables.length === newVariables.length
        && state.dataVariables.every((item, index) => {
          return item.variable === newVariables[index].variable;
        });

      if (!areVariablesEqual) {
        state.dataVariables = newVariables;
      }
    },
    pushDataVariable(state, action) {
      state.dataVariables.push(action.payload);
      state._changed = true;
    },
    removeDataVariable(state, action) {
      state.dataVariables = state.dataVariables.filter(({ variable }) => variable !== action.payload);
      state._changed = true;
    },
    updateActiveDataVariable(state, action) {
      state.dataVariables = state.dataVariables.map((item) => {
        return item.variable === state.activeVariable ? action.payload : item;
      });
      state._changed = true;
      state.activeVariable = initialState.activeVariable;
    },
    setGridXSize(state, action) {
      state.gridSize.x = action.payload;
    },
    setGridYSize(state, action) {
      state.gridSize.y = action.payload;
    },
    setType(state, action) {
      state.type = action.payload;
    },
    setName(state, action) {
      state.name = action.payload;
    },
    setUseInterpolatedData(state, action) {
      state.useInterpolatedData = action.payload;
    },
    goToResults(state, action) {
      state._changed = false;
      state.useInterpolatedData = action.payload.useInterpolatedData;
    },
    resetTestRun(state) {
      state.testRun = initialState.testRun;
    },
    reset() {
      return initialState;
    },
  },
});

const {
  removeDataVariable,
  pushDataVariable,
} = createEquationBasedAnalysisSlice.actions;

export const {
  markAsChanged,
  setActiveVariable,
  setDataVariables,
  resetActiveVariable,
  updateActiveDataVariable,
  setGridXSize,
  setGridYSize,
  setType,
  setName,
  setUseInterpolatedData,
  goToResults,
  resetTestRun,
  reset,
} = createEquationBasedAnalysisSlice.actions;

export default createEquationBasedAnalysisSlice.reducer;

import { PreloadedState, configureStore } from '@reduxjs/toolkit';

import type { RootState } from './store/helpers/types';
import applicationReducer from './store/reducer';
import keyValueBrowserStoreMiddleware from './store/middleware/keyValueBrowserStore';
import { emptyAPI } from '../features/emptyApi/emptyAPI';

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: applicationReducer as any,
    preloadedState,
    middleware(getDefaultMiddleware) {
      return getDefaultMiddleware()
        .concat(
          emptyAPI.middleware,
          keyValueBrowserStoreMiddleware,
        );
    },
  });
};

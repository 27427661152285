import React, { ReactElement, ReactNode } from 'react';
import clsx from 'clsx';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

import { ElementClasses } from '../../helpers/types/shared';

import './index.scss';

export default function EnhancedTooltip({
  interactive = false,
  tooltip = '',
  children,
  enterDelay = 100,
  enterNextDelay,
  placement = 'top',
  classes = {},
  disableFocusListener = false,
}: {
  interactive?: boolean,
  tooltip?: ReactNode | string,
  children: ReactElement,
  enterDelay?: number,
  enterNextDelay?: number,
  placement?: TooltipProps['placement'],
  classes?: ElementClasses<'tooltip' | 'arrow' | 'label'>,
  disableFocusListener?: boolean,
}) {
  return (
    <Tooltip
      arrow
      placement={placement}
      title={tooltip ?? ''}
      classes={{
        tooltip: clsx('tooltip', classes.tooltip),
        arrow: clsx('tooltip__arrow', classes.arrow),
      }}
      enterDelay={enterDelay}
      enterNextDelay={enterNextDelay}
      interactive={interactive}
      disableFocusListener={disableFocusListener}
    >
      <span className={clsx('tooltip__label', classes.label)}>
        {children}
      </span>
    </Tooltip>
  );
}

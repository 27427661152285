import React from 'react';
import { useTranslation } from 'react-i18next';

import AuthenticationRedirector from '../router/redirectors/Authentication';
import UserDataRedirector from '../router/redirectors/UserData';
import PricingRedirector from '../router/redirectors/Pricing';
import BaseTemplate from './BaseTemplate.jsx';
import useDidMount from '../hooks/useDidMount';
import { getDocumentTitle } from '../helpers';
import OperationsFilters from '../features/ui/operationsWorkflow/containers/OperationsFilters';
import OperationsList from '../features/ui/operationsWorkflow/containers/OperationsList';

export default function OperationsWorkflow() {
  const { t } = useTranslation();

  useDidMount(() => {
    document.title = getDocumentTitle(t('general.navigation.operations'));
  });

  return (
    <AuthenticationRedirector>
      <UserDataRedirector>
        <PricingRedirector>
          <BaseTemplate
            headerProps={{
              text: t('general.navigation.operations'),
            }}
          >
            <OperationsFilters key="panel"/>
            <OperationsList key="map" />
          </BaseTemplate>
        </PricingRedirector>
      </UserDataRedirector>
    </AuthenticationRedirector>
  );
}

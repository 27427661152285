import React from 'react';
import { useSelector } from 'react-redux';

import { selectActiveTab } from '../../userFarmsSelectors';
import config from './config';

const UserFarmsTable = () => {
  const activeTab = useSelector(selectActiveTab);
  const Component = config[activeTab];

  return (
    <Component />
  );
};

export default UserFarmsTable;

import { useAppSelector } from '../../../../app/store/helpers/functions';
import { selectYieldDataset } from '../../../field/fieldSelectors';
import {
  selectCleanExcludedAttributes,
  selectCleanMinMaxConditions,
  selectCleanTargetAttribute,
  selectDatasetUuid,
} from '../cleanCalibrateSelectors';
import {
  isCleanParamsValid,
} from '../helpers/functions/actions';
import { CleanDatasetActionParams } from '../types/actions';

export default function useCleanParams(): CleanDatasetActionParams | null {
  const datasetUuid = useAppSelector(selectDatasetUuid);
  const dataset = useAppSelector((state) => selectYieldDataset(state, datasetUuid));
  const targetAttribute = useAppSelector(selectCleanTargetAttribute);
  const excludedAttributes = useAppSelector(selectCleanExcludedAttributes);
  const minMaxConditions = useAppSelector(selectCleanMinMaxConditions);

  let params: CleanDatasetActionParams | null = null;
  const requiredParams = {
    targetAttribute,
    conditionMinMaxClean: minMaxConditions,
  };

  if (isCleanParamsValid(requiredParams, dataset?.statistics || [])) {
    params = {
      conditionAutoClean: {
        targetAttribute: requiredParams.targetAttribute,
        excludedAttributes: excludedAttributes || [],
      },
      conditionMinMaxClean: requiredParams.conditionMinMaxClean,
    };
  }

  return params;
}

import React, { Suspense } from 'react';

import Stepper, { Step } from '../../../../../../../components/Stepper';
import Loading from '../../../../../../../components/Loading';
import Panel from '../../../Panel/ZonesMap';
import { selectZonesMapUuid } from '../../../../createThreeDMapSelectors';
import { goToNextStep, goToPrevStep } from '../../../../../zonesOps/zonesOpsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../../app/store/helpers/functions';

const Map = React.lazy(() => import('../../../Map'));

const ZonesMap = ({
  steps,
  stepIndex,
  step,
}: {
  steps: Step[],
  stepIndex: number,
  step: string,
}) => {
  const dispatch = useAppDispatch();
  const zonesMapUuid = useAppSelector(selectZonesMapUuid);

  const handleClickNext = () => {
    dispatch(goToNextStep());
  };

  const handleClickBack = () => {
    dispatch(goToPrevStep());
  };

  return (
    <Stepper
      activeStep={stepIndex}
      steps={steps}
      nextDisabled={!zonesMapUuid}
      onClickNext={handleClickNext}
      onClickBack={handleClickBack}
    >
      <Panel key="panel"/>
      <Suspense key="map" fallback={<Loading />}>
        <Map step={step} />
      </Suspense>
    </Stepper>
  );
};

export default ZonesMap;

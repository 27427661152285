import i18n from 'i18next';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import type { DefaultFieldTableRow } from '../../../fieldsList/types/tableRow';
import type { TableColumn } from '../../../tableView/types/column';
import { getAreaUnitLabel } from '../../../../../helpers';
import { AreaUnit } from '../../../../user/helpers/constants/user';

export const getTableColumns = ({
  areaUnit,
  onRowStartButtonClick,
}: {
  areaUnit: AreaUnit,
  onRowStartButtonClick: (r: DefaultFieldTableRow) => void,
}): TableColumn<DefaultFieldTableRow>[] => {
  return [
    {
      id: 'name',
      label: i18n.t('field-profiler.fields-list.columns.field-name'),
      element: 'editable-link',
      leading: true,
      rowSpan: 2,
      onStopEdit: () => {},
    },
    {
      id: 'status',
      label: 'Status',
      element: 'default',
      secondary: true,
    },
    {
      id: 'farmName',
      label: i18n.t('general.shared.farm'),
      element: 'default',
      secondary: true,
    },
    {
      id: 'area',
      label: getAreaUnitLabel(areaUnit),
      element: 'default',
      secondary: true,
    },
    {
      id: 'ownTypeLabel',
      label: i18n.t('field-profiler.fields-list.columns.own-type'),
      element: 'default',
      secondary: true,
    },
    {
      id: 'preview',
      label: i18n.t('general.shared.preview'),
      element: 'image',
    },
    {
      id: 'startButton',
      label: '',
      element: 'icon-button',
      horizontalAlign: 'right',
      tooltip: i18n.t('smart-sampling.run-pipeline'),
      iconComponent: PlayArrowIcon,
      onButtonClick: onRowStartButtonClick,
    },
  ];
};

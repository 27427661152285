import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function BalanceIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M10.8333 6.94168C11.5417 6.69168 12.1083 6.12501 12.3583 5.41668H15L12.5 11.25C12.5 12.6333 13.8083 13.75 15.4167 13.75C17.025 13.75 18.3333 12.6333 18.3333 11.25L15.8333 5.41668H17.5V3.75001H12.3583C12.0167 2.77501 11.0917 2.08334 10 2.08334C8.90833 2.08334 7.98333 2.77501 7.64167 3.75001H2.5V5.41668H4.16666L1.66666 11.25C1.66666 12.6333 2.975 13.75 4.58333 13.75C6.19167 13.75 7.5 12.6333 7.5 11.25L5 5.41668H7.64167C7.89167 6.12501 8.45833 6.69168 9.16667 6.94168V16.25H1.66666V17.9167H18.3333V16.25H10.8333V6.94168ZM16.975 11.25H13.8583L15.4167 7.61668L16.975 11.25ZM6.14166 11.25H3.025L4.58333 7.61668L6.14166 11.25ZM10 5.41668C9.54167 5.41668 9.16667 5.04168 9.16667 4.58334C9.16667 4.12501 9.54167 3.75001 10 3.75001C10.4583 3.75001 10.8333 4.12501 10.8333 4.58334C10.8333 5.04168 10.4583 5.41668 10 5.41668Z" />
    </SvgIcon>
  );
}

import React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Typography from '@material-ui/core/Typography';

import './index.scss';

export default function WarningPanel({
  text,
}: {
  text: string,
}) {
  return (
    <div className="clean-calibrate-warning-panel">
      <ErrorOutlineIcon
        className="clean-calibrate-warning-panel__icon"
        fontSize="small"
      />
      <Typography className="clean-calibrate-warning-panel__text">
        {text}
      </Typography>
    </div>
  );
}

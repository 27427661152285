import { CustomError } from '../../helpers/functions/utils/errorHandling';
import { emptyAPI } from '../emptyApi/emptyAPI';
import { errorNotify } from '../notifications/helpers/functions/notify';
import getManageConfigQuery from './graphql/queries/getManageConfig.gql';
import {
  GetCountiesListResult,
  GetManageConfigResponse,
} from './types/api';
import { Country } from './types/country';

const countriesAPI = emptyAPI.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getCountiesList: builder.query<GetCountiesListResult, void>({
      queryFn: async (
        _arg,
        { dispatch },
        _extraOptions,
        baseQuery,
      ) => {
        const getManageConfigResult = await baseQuery({
          document: getManageConfigQuery,
          withApiKey: true,
        });

        if (getManageConfigResult.error) {
          errorNotify({
            error: new CustomError('[Countries] Unable to fetch countries list.', {
              cause: getManageConfigResult.error,
            }),
            dispatch,
          });

          return {
            error: getManageConfigResult.error,
          };
        }

        const { getManageConfig } = getManageConfigResult.data as GetManageConfigResponse;
        const countries = JSON.parse(getManageConfig) as Country[];

        return {
          data: {
            countries,
          },
        };
      },
    }),
  }),
});

export const { useGetCountiesListQuery } = countriesAPI;

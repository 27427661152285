import React, { useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import ShareIcon from '@material-ui/icons/Share';

import ImportIcon from '../../../../../components/Icons/import';
import NewVamapIcon from '../Icons/new-vamap.svg';
import NewFieldIcon from '../Icons/new-field.svg';
import Button from '../../../../../components/Button';
import { getRootLink, PAGES_ROOTS } from '../../../../../helpers/navigation';
import { getWorkflowOptions } from '../../../zonesOps/helpers/functions/workflowOptions';
import ButtonMenu from '../../../../../components/ButtonMenu';
import AmplitudeAnalytics from '../../../../../helpers/classes/amplitudeAnalytics';
import { ZonesOpsWorkflow } from '../../../zonesOps/helpers/constants/workflows';

import './index.scss';

export default function QuickActions({
  onShareFarmsClick,
}: {
  onShareFarmsClick: () => void,
}) {
  const { t } = useTranslation();

  const handleCreateMapItemClick = ({ id }: { id: string | ZonesOpsWorkflow }) => {
    AmplitudeAnalytics.trackCreateAnalysisMapWorkflowSelected({
      workflow: id as ZonesOpsWorkflow,
      page: PAGES_ROOTS.homepage,
    });
  };

  const workflowOptions = useMemo(() => {
    return getWorkflowOptions();
  }, []);

  return (
    <div className="quick-actions">
      <Typography
        variant="h3"
        className="quick-actions__title"
      >
        {t('homepage.quick-actions.title')}
      </Typography>
      <div className="quick-actions__container">
        <Button
          to={getRootLink(PAGES_ROOTS.createField)}
          startIcon={<NewFieldIcon />}
          classes={{
            root: 'action',
            label: 'action__label',
            startIcon: 'action__icon',
          }}
          component={Link}
        >
          <Trans i18nKey={t('homepage.quick-actions.create-field')}/>
        </Button>
        <ButtonMenu
          startIcon={<NewVamapIcon />}
          items={workflowOptions}
          classes={{
            button: {
              root: 'action',
              label: 'action__label',
              startIcon: 'action__icon',
            },
          }}
          horizontalAnchor='center'
          onItemClick={handleCreateMapItemClick}
        >
          <span className="action__label-text">
            <Trans i18nKey={t('homepage.quick-actions.create-zones-map')}/>
          </span>
        </ButtonMenu>
        <Button
          to={getRootLink(PAGES_ROOTS.upload)}
          startIcon={<ImportIcon />}
          classes={{
            root: 'action',
            label: 'action__label',
            startIcon: 'action__icon',
          }}
          component={Link}
        >
          <Trans i18nKey={t('homepage.quick-actions.upload-data')}/>
        </Button>
        <Button
          component={Link}
          to={getRootLink(PAGES_ROOTS.userFarms)}
          startIcon={<ShareIcon />}
          classes={{
            root: 'action',
            label: 'action__label',
            startIcon: 'action__icon',
          }}
          onClick={onShareFarmsClick}
        >
          <Trans i18nKey={t('homepage.quick-actions.share-farms')}/>
        </Button>
      </div>
    </div>
  );
}

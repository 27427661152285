import React from 'react';
import i18n from 'i18next';

import { getCommonSteps } from '../../../zonesOps/helpers/functions/steps';
import DataLayers from '../../containers/Stepper/Steps/DataLayers';
import Settings from '../../containers/Stepper/Steps/Settings';
import { getFieldStabilityBlogPostLink } from '../../../../../helpers/functions/utils/appConfig';

export const getSteps = (withFieldSelection) => {
  let firstSteps = [];

  if (withFieldSelection) {
    firstSteps = getCommonSteps();
  }

  return [
    ...firstSteps,
    {
      name: i18n.t('zones-ops.common.stepper.data-layers'),
      description: (
        <>
          {i18n.t('zones-ops.field-stability-zones.steps.3.description')} {
            getFieldStabilityBlogPostLink()
              && (
                <a
                  className="link"
                  target="_blank"
                  href={getFieldStabilityBlogPostLink()}
                  rel="noopener noreferrer"
                >
                  {i18n.t('general.shared.read-in-our-blog')}
                </a>
              )
          }
        </>
      ),
      id: 'dataLayers',
      component: DataLayers,
    },
    {
      name: i18n.t('general.shared.settings'),
      description: (
        <>
          {i18n.t('zones-ops.common.processing-description')}
          {' '}
          {i18n.t('zones-ops.common.charge-description')}
        </>
      ),
      id: 'settings',
      component: Settings,
    },
  ];
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import TextFieldPopup from '../TextFieldPopup';

import './index.scss';

const CreateEntity = ({
  i18nKey = '',
  ...popup
}) => {
  const { t } = useTranslation();

  return (
    <TextFieldPopup
      {...popup}
      title={t(`general.popups.create-entity.title.${i18nKey}`)}
      description={t(`general.popups.create-entity.description.${i18nKey}`)}
      placeholder={t(`general.popups.create-entity.placeholder.${i18nKey}`)}
      confirmText={t(`general.popups.create-entity.confirm-text.${i18nKey}`)}
      classes={{
        dialogPaper: 'create-entity-popup',
      }}
    />
  );
};

export default CreateEntity;

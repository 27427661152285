import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function RemoveLabelsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M17 7H20V9H17V10.7215V12H15V9H12V7H13.2767H15V4H17V7ZM10 11V5H4V12.2757L12.1343 20.41L18.5 14H13V11H10ZM5.48031 7.59054C5.48031 8.20487 5.97621 8.70078 6.59054 8.70078C7.20487 8.70078 7.70077 8.20487 7.70077 7.59054C7.70077 6.97621 7.20487 6.48031 6.59054 6.48031C5.97621 6.48031 5.48031 6.97621 5.48031 7.59054Z"/>
    </SvgIcon>
  );
}

import React from 'react';
import { Trans } from 'react-i18next';

import './index.scss';

const ZonesMapsFilterTooltip = (name) => {
  return (
    <p className="zones-maps-filter-type-tooltip">
      <Trans i18nKey="zones-maps.tools-panel.filters.tooltip" name={name}>
        text <span className="text_highlight">{name}</span> text <span className="text_highlight">highlight</span> test
      </Trans>
    </p>
  );
};

export default ZonesMapsFilterTooltip;

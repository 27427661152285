import {
  useCallback,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import {
  prepareToDisplayBorderLayer,
  prepareToDisplaySelectedItem,
} from '../helpers/functions/components/Map';
import {
  isAsAppliedDataset,
  isSatelliteImage,
  isSoilDataset,
  isTopographyMap,
  isYieldDataset,
  isEquationMap,
} from '../helpers/functions/entities/assets';
import { getRasterSources } from '../helpers/mapbox/map';
import {
  selectField,
  selectAssetsByType,
} from '../features/field/fieldSelectors';
import {
  selectAreaUnit,
  selectApiKey,
} from '../features/user/userSelectors';

// TODO: decouple from the store
export default function useDataLayersMap(
  selectedItem,
  {
    isCreationView = false,
    isCompareView = false,
    showTopographyViewTypeControl = false,
  } = {},
  {
    fieldSelector = selectField,
    assetsByTypeSelector = (state) => selectAssetsByType(state, selectedItem?._type),
  } = {},
) {
  const field = useSelector(fieldSelector);
  const [hideLayers, setHideLayers] = useState(false);
  const [activeSatelliteViewType, setActiveSatelliteViewType] = useState(null);
  const [activeSoilAttribute, setActiveSoilAttribute] = useState(null);
  const [activeSoilRawProcessed, setActiveSoilRawProcessed] = useState(null);
  const [activeYieldAttribute, setActiveYieldAttribute] = useState(null);
  const [activeYieldRawProcessed, setActiveYieldRawProcessed] = useState(null);
  const [activeAsAppliedAttribute, setActiveAsAppliedAttribute] = useState(null);
  const [activeAsAppliedRawProcessed, setActiveAsAppliedRawProcessed] = useState(null);
  const [activeTopographyMapViewType, setActiveTopographyMapViewType] = useState(null);
  const [activeEquationMapViewType, setActiveEquationMapViewType] = useState(null);
  const areaUnit = useSelector(selectAreaUnit);

  const {
    rasterSources: borderRasterSource = [],
    ...otherBorderLayerProps
  } = prepareToDisplayBorderLayer(field, !isSatelliteImage(selectedItem));
  const groupTypeAssets = useSelector(assetsByTypeSelector);
  const {
    rasterSources = [],
    tileSize,
    attribution,
    datasetViewControl,
    indexesControl = {},
    miniLegendControl = {},
    showHideControl = {},
    legendControl = {},
    geoMapsControl = {},
  } = prepareToDisplaySelectedItem(selectedItem, {
    isCreationView,
    isCompareView,
    showTopographyViewTypeControl,
    satelliteViewType: activeSatelliteViewType,
    soilAttribute: activeSoilAttribute,
    soilRawProcessed: activeSoilRawProcessed,
    yieldAttribute: activeYieldAttribute,
    yieldRawProcessed: activeYieldRawProcessed,
    asAppliedAttribute: activeAsAppliedAttribute,
    asAppliedRawProcessed: activeAsAppliedRawProcessed,
    topographyMapViewType: activeTopographyMapViewType,
    equationMapGeoMap: activeEquationMapViewType,
    items: groupTypeAssets,
    areaUnit,
  });
  const mapProps = {
    ...otherBorderLayerProps,
    uuid: field.uuid,
    apiKey: useSelector(selectApiKey),
    rasterSources: getRasterSources({ rasterSources, hideLayers }),
    tileSize,
    borderRasterSource,
    attribution,
  };
  const targetControl = {
    fitBounds: otherBorderLayerProps.map.fitBounds,
    fitBoundsOptions: otherBorderLayerProps.map.fitBoundsOptions,
  };

  const handleShowHideChange = (hide) => {
    setHideLayers(hide);
  };

  const handleViewTypeControlChange = useCallback((viewType, newSelectedItem) => {
    const assetItem = newSelectedItem || selectedItem;

    if (isSatelliteImage(assetItem)) {
      setActiveSatelliteViewType(viewType);
    } else if (isSoilDataset(assetItem)) {
      setActiveSoilAttribute(viewType);
    } else if (isYieldDataset(assetItem)) {
      setActiveYieldAttribute(viewType);
    } else if (isAsAppliedDataset(assetItem)) {
      setActiveAsAppliedAttribute(viewType);
    } else if (isTopographyMap(assetItem)) {
      setActiveTopographyMapViewType(viewType);
    } else if (isEquationMap(assetItem)) {
      setActiveEquationMapViewType(viewType);
    }
  }, [selectedItem]);

  const handleRawProcessedClick = (item, newSelectedItem) => {
    const assetItem = newSelectedItem || selectedItem;

    if (isSoilDataset(assetItem)) {
      setActiveSoilRawProcessed(item);
    } else if (isYieldDataset(assetItem)) {
      setActiveYieldRawProcessed(item);
    } else if (isAsAppliedDataset(assetItem)) {
      setActiveAsAppliedRawProcessed(item);
    }
  };

  return {
    targetControl,
    datasetViewControl,
    indexesControl,
    miniLegendControl,
    showHideControl,
    legendControl,
    geoMapsControl,
    mapProps,
    onRawProcessedClick: handleRawProcessedClick,
    onViewTypeControlChange: handleViewTypeControlChange,
    onShowHideChange: handleShowHideChange,
  };
}

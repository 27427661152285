export enum DefaultAssetGroupFilterKey {
  nameFilterValue = 'nameFilterValue',
  createdFrom = 'createdFrom',
  createdTo = 'createdTo',
}

export const DEFAULT_ASSET_GROUP_FILTER_KEYS_I18N_MAP = {
  [DefaultAssetGroupFilterKey.nameFilterValue]: 'general.controls.filters.name-filter',
  [DefaultAssetGroupFilterKey.createdFrom]: 'general.controls.filters.created-from',
  [DefaultAssetGroupFilterKey.createdTo]: 'general.controls.filters.created-to',
};

export const DEFAULT_ASSET_GROUP_FILTER = {
  [DefaultAssetGroupFilterKey.nameFilterValue]: '',
  [DefaultAssetGroupFilterKey.createdFrom]: null,
  [DefaultAssetGroupFilterKey.createdTo]: null,
};

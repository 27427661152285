import i18n from 'i18next';

import { CommonSteps } from '../constants/steps';
import { Step } from '../../../../../components/Stepper';
import SelectFieldWorkflow from '../../containers/Stepper/Steps/SelectField';

export const getCommonSteps = (): Step[] => {
  return [
    {
      name: i18n.t('zones-ops.common.steps.select-field.title'),
      description: i18n.t('zones-ops.common.steps.select-field.description'),
      id: CommonSteps.selectField,
      component: SelectFieldWorkflow,
    },
  ];
};

import type { FlattenNode, TreeNode } from '../../types/node';
import {
  CHILD_TREE_VIEW_ITEM_OFFSET,
  TREE_VIEW_ITEM_OFFSET,
} from '../constants/dataLayersTree';

/**
 * Depth-first tree traverse.
 * @param nodes
 * @param childrenCondition - if returns true, children are traversed
 */
export function* traverseTree<T extends TreeNode>(
  nodes: T[],
  childrenCondition: (n: T) => boolean = () => true,
): Generator<T> {
  for (const node of nodes) {
    yield node;

    if (node.children?.length && childrenCondition(node)) {
      yield* traverseTree(node.children as T[], childrenCondition);
    }
  }
}

const calculateNodeLevel = (node: TreeNode): number => {
  let level = 0;
  let currentNode = node;

  while (currentNode.parent) {
    level++;
    currentNode = currentNode.parent;
  }

  return level;
};

export const flattenTree = (
  tree: TreeNode[],
  expanded: Record<string, boolean>,
): FlattenNode[] => {
  const result: FlattenNode[] = [];
  const iterator = traverseTree(
    tree,
    ({ id }) => expanded[id],
  );

  for (const node of iterator) {
    result.push({ ...node, level: calculateNodeLevel(node) });
  }

  return result;
};

export const findNodeById = <T extends TreeNode>(
  rootNodes: T[],
  id: string,
): T | null => {
  for (const node of traverseTree(rootNodes)) {
    if (node.id === id) {
      return node;
    }
  }

  return null;
};

export const getTreeViewItemOffset = (treeOffset: number, isChild = false) => {
  return treeOffset + (isChild ? CHILD_TREE_VIEW_ITEM_OFFSET : TREE_VIEW_ITEM_OFFSET);
};

import { API, graphqlOperation } from '@aws-amplify/api';

import getLabelsQuery from './graphql/queries/getLabels.gql';
import saveLabelsMutation from './graphql/mutations/saveLabels.gql';
import deleteLabelMutation from './graphql/mutations/deleteLabel.gql';

export const getLabels = async () => {
  return API.graphql(graphqlOperation(getLabelsQuery))
    .then(({ data }) => {
      return data.getLabels;
    });
};

export const saveLabels = async (labels) => {
  return API.graphql(graphqlOperation(saveLabelsMutation, {
    input: {
      labels,
    },
  }))
    .then(({ data }) => {
      return data.saveLabels;
    });
};

export const deleteLabel = async (label) => {
  return API.graphql(graphqlOperation(deleteLabelMutation, {
    input: label,
  }))
    .then(({ data }) => {
      return data.deleteLabel;
    });
};

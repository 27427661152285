import i18n from 'i18next';
import { createSlice } from '@reduxjs/toolkit';
import { closeSnackbar } from 'notistack';

import {
  errorNotify,
  warningNotify,
} from '../notifications/helpers/functions/notify';
import {
  getProfile,
  manageProfile,
  deleteJDConnection,
} from './jdProfileAPI';
import {
  selectProfileIsLoading,
  selectProfileLoaded,
} from './jdProfileSelectors';
import { RootState } from '../../app/store/helpers/types';
import { getJdProfileDisconnectWarningActions } from '../notifications/helpers/functions/actions';
import { createAppAsyncThunk } from '../../app/store/helpers/functions';
import { CustomError } from '../../helpers/functions/utils/errorHandling';

interface JDProfileState {
  acceptUrl: string;
  updateAcceptUrl: string;
  hasRefreshToken: boolean;
  flushDateRefreshToken: string | null;
  flushUserRefreshToken: boolean | null;
  providerHealthCheck: boolean;
  autoImportAllFieldsOps: boolean;
  autoUpdateFieldBoundaries: boolean;
  autoUpdateFieldStructure: boolean;
  autoCreateZonesMapLayers: boolean;
  autoCreateSoilMapLayers: boolean;
  autoCreateYieldMapLayers: boolean;
  autoCreateTopographyMapLayers: boolean;
  autoCreateEquationMapLayers: boolean;
  accountName: string;
  isLoading: boolean;
  isDisconnecting: boolean;
  loaded: boolean | null;
}

const initialState: JDProfileState = {
  acceptUrl: '',
  updateAcceptUrl: '',
  hasRefreshToken: false,
  flushDateRefreshToken: null,
  flushUserRefreshToken: null,
  providerHealthCheck: false,
  autoImportAllFieldsOps: false,
  autoUpdateFieldBoundaries: false,
  autoUpdateFieldStructure: false,
  autoCreateZonesMapLayers: false,
  autoCreateSoilMapLayers: false,
  autoCreateYieldMapLayers: false,
  autoCreateTopographyMapLayers: false,
  autoCreateEquationMapLayers: false,
  accountName: '',
  isLoading: false,
  isDisconnecting: false,
  loaded: null,
};

export const disconnectJD = createAppAsyncThunk(
  'jdProfile/disconnectJD',
  async (_, { dispatch, rejectWithValue }) => {
    const result = await deleteJDConnection();

    if (result.status !== '201') {
      errorNotify({
        error: new CustomError('[JD Integration] Disconnect error', {
          cause: result,
        }),
        message: i18n.t('integrations.jd.disconnect-error'),
        dispatch,
      });
      rejectWithValue(null);
    } else {
      dispatch(fetchProfileJohnDeere());
    }
  },
);

export const fetchProfileJohnDeere = createAppAsyncThunk(
  'jdProfile/getProfile',
  async (_, { dispatch }) => {
    const profile = await getProfile();

    if (profile.providerHealthCheck === false) {
      errorNotify({
        error: new CustomError('[JD Integration] Provider health check failed.'),
        message: i18n.t('integrations.jd.notifications.health-check-failed'),
        dispatch,
      });
    } else if (profile.flushUserRefreshToken === false) {
      const notificationId = warningNotify({
        message: i18n.t('integrations.jd.notifications.profile-disconnected'),
        actions: getJdProfileDisconnectWarningActions(
          profile.acceptUrl,
          () => {
            closeSnackbar(notificationId);
          },
        ),
      });
    }

    return profile;
  },
  {
    condition: (_, { getState }) => {
      const state = getState() as RootState;
      const profileLoaded = selectProfileLoaded(state);
      const profileLoading = selectProfileIsLoading(state);

      return !(profileLoaded || profileLoading);
    },
  },
);

export const toggleAutoImportAllFieldsOps = createAppAsyncThunk<boolean, { value: boolean }>(
  'jdProfile/toggleAutoImportAllFieldsOps',
  async ({
    value,
  }, { dispatch }) => {
    let result: boolean;

    try {
      const { hasAutoImportAllFieldOperations } = await manageProfile({
        hasAutoImportAllFieldOperations: value,
      });
      result = hasAutoImportAllFieldOperations;
    } catch (error) {
      errorNotify({
        error: new CustomError('[JD Integration] Unable to toggle auto-import all fields ops.', {
          cause: error,
        }),
        message: i18n.t('integrations.jd.field-operations.ops-import.error'),
        dispatch,
      });

      throw error;
    }

    return result;
  },
);

export const toggleAutoUpdateFieldsBoundaries = createAppAsyncThunk<boolean, { value: boolean }>(
  'jdProfile/toggleAutoUpdateFieldsBoundaries',
  async ({
    value,
  }, { dispatch }) => {
    let result: boolean;

    try {
      const { hasAutoUpdateFieldBoundaries } = await manageProfile({
        hasAutoUpdateFieldBoundaries: value,
      });
      result = hasAutoUpdateFieldBoundaries;
    } catch (error) {
      errorNotify({
        error: new CustomError('[JD Integration] Unable to toggle auto-update fields boundaries.', {
          cause: error,
        }),
        message: i18n.t('integrations.jd.field-boundaries-and-structure.boundaries.error'),
        dispatch,
      });

      throw error;
    }

    return result;
  },
);

export const toggleAutoUpdateFieldStructure = createAppAsyncThunk<boolean, { value: boolean }>(
  'jdProfile/toggleAutoUpdateFieldStructure',
  async ({
    value,
  }, { dispatch }) => {
    let result: boolean;

    try {
      const { hasAutoUpdateFieldStructure } = await manageProfile({
        hasAutoUpdateFieldStructure: value,
      });
      result = hasAutoUpdateFieldStructure;
    } catch (error) {
      errorNotify({
        error: new CustomError('[JD Integration] Unable to toggle auto-update fields structure.', {
          cause: error,
        }),
        message: i18n.t('integrations.jd.field-boundaries-and-structure.structure.error'),
        dispatch,
      });

      throw error;
    }

    return result;
  },
);

export const toggleAutoCreateZonesMapLayers = createAppAsyncThunk<boolean, { value: boolean }>(
  'jdProfile/toggleAutoCreateZonesMapLayers',
  async ({
    value,
  }, { dispatch }) => {
    let result: boolean;

    try {
      const { hasAutoCreateZonesMapLayers } = await manageProfile({
        hasAutoCreateZonesMapLayers: value,
      });
      result = hasAutoCreateZonesMapLayers;
    } catch (error) {
      errorNotify({
        error: new CustomError('[JD Integration] Unable to toggle auto-create zones map layers.', {
          cause: error,
        }),
        message: i18n.t('integrations.jd.map-layers.error'),
        dispatch,
      });

      throw error;
    }

    return result;
  },
);

export const toggleAutoCreateSoilMapLayers = createAppAsyncThunk<boolean, { value: boolean }>(
  'jdProfile/toggleAutoCreateSoilMapLayers',
  async ({
    value,
  }, { dispatch }) => {
    let result: boolean;

    try {
      const { hasAutoCreateSoilMapLayers } = await manageProfile({
        hasAutoCreateSoilMapLayers: value,
      });
      result = hasAutoCreateSoilMapLayers;
    } catch (error) {
      errorNotify({
        error: new CustomError('[JD Integration] Unable to toggle auto-create soil map layers.', {
          cause: error,
        }),
        message: i18n.t('integrations.jd.map-layers.error'),
        dispatch,
      });

      throw error;
    }

    return result;
  },
);

export const toggleAutoCreateYieldMapLayers = createAppAsyncThunk<boolean, { value: boolean }>(
  'jdProfile/toggleAutoCreateYieldMapLayers',
  async ({
    value,
  }, { dispatch }) => {
    let result: boolean;

    try {
      const { hasAutoCreateYieldMapLayers } = await manageProfile({
        hasAutoCreateYieldMapLayers: value,
      });
      result = hasAutoCreateYieldMapLayers;
    } catch (error) {
      errorNotify({
        error: new CustomError('[JD Integration] Unable to toggle auto-create yield map layers.', {
          cause: error,
        }),
        message: i18n.t('integrations.jd.map-layers.error'),
        dispatch,
      });

      throw error;
    }

    return result;
  },
);

export const toggleAutoCreateTopographyMapLayers = createAppAsyncThunk<boolean, { value: boolean }>(
  'jdProfile/toggleAutoCreateTopographyMapLayers',
  async ({
    value,
  }, { dispatch }) => {
    let result: boolean;

    try {
      const { hasAutoCreateTopographyMapLayers } = await manageProfile({
        hasAutoCreateTopographyMapLayers: value,
      });
      result = hasAutoCreateTopographyMapLayers;
    } catch (error) {
      errorNotify({
        error: new CustomError('[JD Integration] Unable to toggle auto-create topo map layers.', {
          cause: error,
        }),
        message: i18n.t('integrations.jd.map-layers.error'),
        dispatch,
      });

      throw error;
    }

    return result;
  },
);

export const toggleAutoCreateEquationMapLayers = createAppAsyncThunk<boolean, { value: boolean }>(
  'jdProfile/toggleAutoCreateEquationMapLayers',
  async ({
    value,
  }, { dispatch }) => {
    let result: boolean;

    try {
      const { hasAutoCreateEquationMapLayers } = await manageProfile({
        hasAutoCreateEquationMapLayers: value,
      });
      result = hasAutoCreateEquationMapLayers;
    } catch (error) {
      errorNotify({
        error: new CustomError('[JD Integration] Unable to toggle auto-create equation map layers.', {
          cause: error,
        }),
        message: i18n.t('integrations.jd.map-layers.error'),
        dispatch,
      });

      throw error;
    }

    return result;
  },
);

export const jdProfileSlice = createSlice({
  name: 'jdProfile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(disconnectJD.pending, (state) => {
        state.isDisconnecting = true;
        state.loaded = false;
      })
      .addCase(disconnectJD.fulfilled, (state) => {
        state.isDisconnecting = false;
      })
      .addCase(disconnectJD.rejected, (state) => {
        state.isDisconnecting = false;
        state.loaded = true;
      })
      .addCase(fetchProfileJohnDeere.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProfileJohnDeere.fulfilled, (state, action) => {
        state.isLoading = false;
        state.loaded = true;
        state.acceptUrl = action.payload.acceptUrl;
        state.updateAcceptUrl = action.payload.updateAcceptUrl;
        state.hasRefreshToken = action.payload.hasRefreshToken;
        state.autoImportAllFieldsOps = action.payload.hasAutoImportAllFieldOperations;
        state.autoUpdateFieldBoundaries = action.payload.hasAutoUpdateFieldBoundaries;
        state.autoUpdateFieldStructure = action.payload.hasAutoUpdateFieldStructure;
        state.autoCreateZonesMapLayers = action.payload.hasAutoCreateZonesMapLayers;
        state.autoCreateSoilMapLayers = action.payload.hasAutoCreateSoilMapLayers;
        state.autoCreateYieldMapLayers = action.payload.hasAutoCreateYieldMapLayers;
        state.autoCreateTopographyMapLayers = action.payload.hasAutoCreateTopographyMapLayers;
        state.autoCreateEquationMapLayers = action.payload.hasAutoCreateEquationMapLayers;
        state.accountName = action.payload.accountName;
        state.flushDateRefreshToken = action.payload.flushDateRefreshToken;
        state.flushUserRefreshToken = action.payload.flushUserRefreshToken;
        state.providerHealthCheck = action.payload.providerHealthCheck;
      })
      .addCase(toggleAutoImportAllFieldsOps.pending, (state, action) => {
        state.autoImportAllFieldsOps = action.meta.arg.value;
      })
      .addCase(toggleAutoUpdateFieldsBoundaries.pending, (state, action) => {
        state.autoUpdateFieldBoundaries = action.meta.arg.value;
      })
      .addCase(toggleAutoUpdateFieldStructure.pending, (state, action) => {
        state.autoUpdateFieldStructure = action.meta.arg.value;
      })
      .addCase(toggleAutoCreateZonesMapLayers.pending, (state, action) => {
        state.autoCreateZonesMapLayers = action.meta.arg.value;
      })
      .addCase(toggleAutoCreateSoilMapLayers.pending, (state, action) => {
        state.autoCreateSoilMapLayers = action.meta.arg.value;
      })
      .addCase(toggleAutoCreateYieldMapLayers.pending, (state, action) => {
        state.autoCreateYieldMapLayers = action.meta.arg.value;
      })
      .addCase(toggleAutoCreateTopographyMapLayers.pending, (state, action) => {
        state.autoCreateTopographyMapLayers = action.meta.arg.value;
      })
      .addCase(toggleAutoCreateEquationMapLayers.pending, (state, action) => {
        state.autoCreateEquationMapLayers = action.meta.arg.value;
      });
  },
});

export default jdProfileSlice.reducer;

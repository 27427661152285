import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../../../app/store/helpers/functions';
import Stepper, { Step } from '../../../../../../../components/Stepper';
import { goToNextStep, goToPrevStep } from '../../../../../batchAnalytics/batchAnalyticsSlice';
import EquationsPanel from '../../../../../../equations/containers/EquationsPanel';
import EquationPanel from '../../../EquationPanel';
import FieldsSummary from '../../../FieldsSummary';
import { selectIsCurrentEquationValid } from '../../../../../../equations/equationsSelectors';
import { selectEditMode, selectFullScreenEditor } from '../../../../createBatchEquationBasedAnalysisSelectors';

import './index.scss';

export default function EquationStepStepper({
  steps,
  stepIndex,
}: {
  steps: Step[],
  stepIndex: number,
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentEquationValid = useAppSelector(selectIsCurrentEquationValid);
  const editMode = useAppSelector(selectEditMode);
  const fullScreenEditor = useAppSelector(selectFullScreenEditor);

  const handleClickBack = () => {
    dispatch(goToPrevStep());
  };

  const handleClickNext = () => {
    dispatch(goToNextStep());
  };

  return (
    <Stepper
      activeStep={stepIndex}
      steps={steps}
      nextDisabled={!currentEquationValid || editMode}
      nextLabel={t('batch-equation-based.steps.go-to-preview')}
      onClickNext={handleClickNext}
      onClickBack={handleClickBack}
    >
      <div
        key="panel"
        className="equation-step"
      >
        <div className="equation-step__panel">
          {!fullScreenEditor && <EquationsPanel classes={{ root: 'equation-step__equations-panel' }}/>}
          <EquationPanel/>
        </div>
        <FieldsSummary />
      </div>
    </Stepper>
  );
}

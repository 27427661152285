import React from 'react';
import clsx from 'clsx';
import TableCell from '@material-ui/core/TableCell';

import { getCellClasses } from '../../../helpers/functions/cell';

import './index.scss';

const StatusCell = ({
  row = {},
  headCell = {},
}) => {
  const className = clsx('status-cell__item', `status-cell__item-${row[headCell.id].type}`);

  return (
    <TableCell
      align={headCell.horizontalAlign || 'left'}
      className={getCellClasses('status-cell', headCell, row)}
    >
      {
        row[headCell.id] && row[headCell.id].value && row[headCell.id].type
          ? <span className={className}>{ row[headCell.id].value }</span>
          : <span></span>
      }
    </TableCell>
  );
};

export default StatusCell;

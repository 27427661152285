import React from 'react';
import {
  Trans,
  useTranslation,
} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import PopupHeader from '../../../../../components/Popups/PopupHeader';
import Link from '../../../../../components/Link';
import Button from '../../../../../components/Button';
import {
  PAGES_ROOTS,
  getRootLink,
} from '../../../../../helpers/navigation';

import './index.scss';

const UploadClose = ({
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open>
      <PopupHeader
        title={t('general.popups.upload-close.title')}
        onCancel={onCancel}
      />
      <DialogContent>
        <DialogContentText
          classes={{
            root: 'upload-close-popup__content-text',
          }}
        >
          <Trans i18nKey="general.popups.upload-close.description">
            text <Link to={getRootLink(PAGES_ROOTS.operations)} target="_blank">Link</Link> text
          </Trans>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        classes={{
          root: 'upload-close-popup__actions',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirm}
        >
          {t('general.controls.proceed')}
        </Button>
        <Button
          variant="outlined"
          onClick={onCancel}
        >
          {t('general.controls.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadClose;

import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function EquationIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M10 18V16H11.55L14.175 13L11.55 10H9.9L8.3 18.6C8.16667 19.35 7.85833 19.9377 7.375 20.363C6.89167 20.7877 6.275 21 5.525 21C4.775 21 4.16667 20.8 3.7 20.4C3.23333 20 3 19.4667 3 18.8C3 18.2667 3.14167 17.8373 3.425 17.512C3.70833 17.1873 4.06667 17.025 4.5 17.025C4.91667 17.025 5.27067 17.1667 5.562 17.45C5.854 17.7333 6 18.075 6 18.475C6 18.5583 5.996 18.6333 5.988 18.7C5.97933 18.7667 5.96667 18.8417 5.95 18.925C6.03333 18.9083 6.104 18.8627 6.162 18.788C6.22067 18.7127 6.26667 18.6083 6.3 18.475L7.85 10H5V8H8.225L8.75 5.15C8.86667 4.51667 9.17933 4 9.688 3.6C10.196 3.2 10.8 3 11.5 3C12.2333 3 12.8333 3.21667 13.3 3.65C13.7667 4.08333 14 4.625 14 5.275C14 5.775 13.8583 6.18733 13.575 6.512C13.2917 6.83733 12.9333 7 12.5 7C12.0833 7 11.7293 6.85833 11.438 6.575C11.146 6.29167 11 5.94167 11 5.525C11 5.44167 11.004 5.36667 11.012 5.3C11.0207 5.23333 11.0333 5.15833 11.05 5.075C10.95 5.10833 10.875 5.15833 10.825 5.225C10.775 5.29167 10.7333 5.39167 10.7 5.525L10.275 8H15V10H14.2L15.5 11.475L16.8 10H16V8H21V10H19.45L16.825 13L19.45 16H21V18H16V16H16.8L15.5 14.5L14.2 16H15V18H10Z" />
    </SvgIcon>
  );
}

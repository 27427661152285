import React from 'react';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import PopupHeader from '../../../../components/Popups/PopupHeader';
import Button from '../../../../components/Button';

import './index.scss';

const RemovePinPopup = ({
  t,
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  return (
    <Dialog
      open
      classes={{
        paper: 'remove-pin__paper',
      }}
    >
      <PopupHeader
        title={t('general.popups.remove-pin.title')}
        onCancel={onCancel}
      />
      <DialogContent>
        <DialogContentText
          component="div"
          classes={{
            root: 'remove-pin__text',
          }}
        >
          {t('general.popups.remove-pin.description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        classes={{
          root: 'remove-pin__actions',
        }}
      >
        <Button
          className="remove-pin__button"
          onClick={onCancel}
          variant="outlined"
        >
          {t('general.controls.cancel')}
        </Button>
        <Button
          className="remove-pin__button remove-pin__button_confirm"
          variant="contained"
          color="secondary"
          onClick={onConfirm}
        >
          {t('general.popups.remove')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withTranslation()(RemovePinPopup);

import { prettifyNumber } from '../../../../../helpers/markup';
import { getFieldLink } from '../../../../../helpers/navigation';
import type { Organization } from '../../../../user/types/api';
import type { UserAction } from '../../types/api';

const ACTIONS_WITHOUT_CONSUMED_AREA = ['COMMENT_PHOTO', 'NOTE_PHOTO', 'FIELD_NOTE', 'NOTE_COMMENT'];

const getActionFieldLink = ({ farmUuid, fieldUuid, operation }: UserAction) => {
  if (!farmUuid || !fieldUuid || operation === 'FIELD_DELETION') {
    return null;
  }

  return getFieldLink(farmUuid, fieldUuid);
};

const getActionConsumedArea = ({ operation, area }: UserAction) => {
  if (ACTIONS_WITHOUT_CONSUMED_AREA.includes(operation)) {
    return null;
  }

  return prettifyNumber(area, 2);
};

export const transformAction = (
  actions: UserAction[],
  organizations: Organization[],
  currentUser: {
    uuid: string,
    email: string,
  },
) => {
  return actions.map((action) => {
    const organization = organizations.find(({ uuid }) => uuid === action.organizationUuid);
    let userEmail: string | undefined;

    if (action.userUuid === currentUser.uuid) {
      userEmail = currentUser.email;
    } else {
      userEmail = organization?.users?.find(({ userUuid }) => userUuid === action.userUuid)?.email;
    }

    return {
      ...action,
      organization: organization?.name,
      user: userEmail || '',
      fieldLink: getActionFieldLink(action),
      area: getActionConsumedArea(action),
    };
  });
};

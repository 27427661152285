import React, { ReactElement } from 'react';
import clsx from 'clsx';

import { AssetType } from '../../../../helpers/constants/entities/asset';
import DatasetItem, { AssetItemDataset } from './DatasetItem';
import DefaultItem from './DefaultItem';
import AnalysisMapItem, { AssetItemAnalysisMap } from './AnalysisMapItem';

import './index.scss';

type AssetItem = {
  label: string;
  value: string;
} | {
  label: string;
  value: string;
  dataset: AssetItemDataset;
} | {
  label: string;
  value: string;
  analysisMap: AssetItemAnalysisMap;
};

interface Asset {
  type: AssetType;
  items: AssetItem[];
}

export default function SourceAssetsList({
  assets,
  apiKey,
  assetHeaderRenderer,
}: {
  assets: Asset[],
  apiKey: string,
  assetHeaderRenderer: (a: Asset) => ReactElement,
}) {
  return (
    <div className="source-assets-list">
      {
        assets.map((asset, assetIndex) => {
          return (
            <div key={assetIndex} className="source-assets-list__item">
              <div
                className={clsx('source-assets-list__item-header', {
                  satellite: asset.type === AssetType.satelliteImage,
                  yield: asset.type === AssetType.yieldDataset,
                  'as-applied': asset.type === AssetType.asAppliedDataset,
                  soil: asset.type === AssetType.soilDataset,
                  topo: asset.type === AssetType.topographyMap,
                  'multi-layer': asset.type === AssetType.vectorAnalysisMap
                    || asset.type === AssetType.equationMap,
                })}
              >
                {assetHeaderRenderer(asset)}
              </div>
              <div className="source-assets-list__item-content">
                {
                  asset.items.map((item, index) => {
                    let result;

                    if ('dataset' in item) {
                      result = (
                        <DatasetItem
                          key={index}
                          label={item.label}
                          value={item.value}
                          dataset={item.dataset}
                          apiKey={apiKey}
                        />
                      );
                    } else if ('analysisMap' in item) {
                      result = (
                        <AnalysisMapItem
                          key={index}
                          label={item.label}
                          value={item.value}
                          analysisMap={item.analysisMap}
                          apiKey={apiKey}
                        />
                      );
                    } else {
                      result = (
                        <DefaultItem
                          key={index}
                          label={item.label}
                          value={item.value}
                        />
                      );
                    }

                    return result;
                  })
                }
              </div>
            </div>
          );
        })
      }
    </div>
  );
}

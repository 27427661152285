export const selectUpdates = ({ zonesMap }) => zonesMap.updates;

export const selectSelectedZone = ({ zonesMap }) => zonesMap.selectedZone;

export const selectStatisticsOutdated = ({ zonesMap }) => zonesMap.statisticsOutdated;

export const selectUpdateInProgress = ({ zonesMap }) => zonesMap.updateInProgress;

export const selectInstrument = ({ zonesMap }) => zonesMap.instrument;

export const selectIsInstrumentEmpty = (state) => !selectInstrument(state);

export const selectBackTo = ({ zonesMap }) => zonesMap.backTo;

export const selectLoading = ({ zonesMap }) => zonesMap.loading;

export const selectIsLoaded = ({ zonesMap }) => zonesMap.isLoaded;

export const selectNotFound = ({ zonesMap }) => zonesMap.notFound;

export const selectUuid = ({ zonesMap }) => zonesMap.uuid;

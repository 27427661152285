import React from 'react';
import './index.scss';

const BootstrapLoading = () => {
  return (
    <div className="bootstrap-loading">Loading...</div>
  );
};

export default BootstrapLoading;

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../app/store/helpers/functions';
import Stepper, { Step } from '../../../../../../../components/Stepper';
import { goToPrevStep } from '../../../../../batchAnalytics/batchAnalyticsSlice';
import { selectFieldsAnyAssetsLoading } from '../../../../../../assets/assetsSelectors';
import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import EquationSummary from '../../../EquationSummary';
import PreviewPanel from '../../../PreviewPanel';
import PreviewSummary from '../../../PreviewSummary';
import { LoadStatus } from '../../../../../../../helpers/constants/utils/loadStatus';
import { openPopup } from '../../../../../popups/popupsSlice';
import { POPUPS } from '../../../../../popups/helpers/constants/popups';
import { selectEquationMapsDataByStatuses } from '../../../../createBatchEquationBasedAnalysisSelectors';
import { selectSelectedFieldsUuids } from '../../../../../batchAnalytics/batchAnalyticsSelectors';

import './index.scss';

export default function PreviewStepStepper({
  steps,
  stepIndex,
}: {
  steps: Step[],
  stepIndex: number,
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const uuids = useAppSelector(selectSelectedFieldsUuids);
  const loading = useAppSelector((state) => selectFieldsAnyAssetsLoading(state, uuids));
  const generatedEquationMaps = useAppSelector((state) => selectEquationMapsDataByStatuses(state));

  const finishDisabled = useMemo(() => {
    if (!generatedEquationMaps.length) {
      return true;
    }

    return generatedEquationMaps.some((equationMap) => {
      return equationMap.generationStatus === LoadStatus.loading
        || equationMap.generationStatus === LoadStatus.error;
    });
  }, [generatedEquationMaps]);

  const handleClickFinish = () => {
    dispatch(openPopup({
      type: POPUPS.finishGeneration,
    }));
  };

  const handleClickBack = () => {
    dispatch(goToPrevStep());
  };

  return (
    <Stepper
      activeStep={stepIndex}
      steps={steps}
      nextDisabled={finishDisabled}
      nextLabel={t('zones-ops.common.finish')}
      onClickNext={handleClickFinish}
      onClickBack={handleClickBack}
    >
      {
        loading
          ? <BackdropLoading key="loader" />
          : (
            <div key="panel" className="preview-step-panel">
              <EquationSummary />
              <PreviewPanel />
              <PreviewSummary />
            </div>
          )
      }
    </Stepper>
  );
}

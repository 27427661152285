import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './index.scss';

const CodeEditorErrorsPanel = ({
  errorMessage = '',
  loading = false,
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(!!errorMessage);

  useEffect(() => {
    setExpanded(!!errorMessage);
  }, [errorMessage]);

  const handlePanelChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      disabled={!errorMessage}
      expanded={expanded}
      classes={{
        root: 'errors-panel',
        expanded: 'errors-panel_expanded',
        disabled: 'errors-panel_disabled',
      }}
      onChange={handlePanelChange}
    >
      <AccordionSummary
        classes={{
          root: 'errors-panel__summary',
          expanded: 'errors-panel__summary_expanded',
          disabled: 'errors-panel__summary_disabled',
          content: 'errors-panel__summary-content',
        }}
        IconButtonProps={{
          size: 'small',
          classes: {
            root: 'errors-panel__summary-button',
          },
        }}
        expandIcon={<ExpandMoreIcon fontSize="small" />}
      >
        {loading && <CircularProgress size={20}/>}
        <Typography
          className={clsx(
            'errors-panel__summary-text',
            errorMessage && 'errors-panel__summary-text_invalid',
          )}
          variant="body2"
        >
          {
            errorMessage
              ? t('general.controls.errors.errors-detected')
              : t('general.controls.errors.no-errors')
          }
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: 'errors-panel__details',
        }}
      >
        <Typography variant="body2">{errorMessage}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default CodeEditorErrorsPanel;

import React from 'react';
import { connect } from 'react-redux';

import Stepper from '../../../../../../../components/Stepper';
import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import Panel from '../../../Panel/DataType';
import { goToNextStep, goToPrevStep } from '../../../../../zonesOps/zonesOpsSlice';
import Message from '../../../../../../../components/Messages/Message';
import { fetchAllAssets } from '../../../../../../field/fieldSlice';
import {
  selectIsAllAssetsLoaded,
  selectIsFieldHasNoAssets,
} from '../../../../../../field/fieldSelectors';
import { selectSelectedField } from '../../../../../zonesOps/zonesOpsSelectors';
import useDidMount from '../../../../../../../hooks/useDidMount';

const StepperDataType = ({
  isFieldLoaded,
  steps,
  stepIndex,
  step,
  fieldHasNoAssets,
  onClickBack,
  onClickNext,
  requestDataLayers,
}) => {
  useDidMount(requestDataLayers);

  let content;

  if (isFieldLoaded) {
    if (fieldHasNoAssets) {
      content = (
        <Message
          key="panel"
          messageTextLocation="zones-ops.common.no-assets-message"
        />
      );
    } else {
      content = (
        <Panel key="panel" step={step} />
      );
    }
  } else {
    content = (
      <BackdropLoading key="loader" />
    );
  }

  return (
    <Stepper
      activeStep={stepIndex}
      steps={steps}
      onClickNext={onClickNext}
      onClickBack={onClickBack}
      nextDisabled={!isFieldLoaded}
    >
      { content }
    </Stepper>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    farmUuid,
    fieldUuid,
  } = selectSelectedField(state);
  const isFieldLoaded = selectIsAllAssetsLoaded(state);
  const fieldHasNoAssets = selectIsFieldHasNoAssets(state);

  return {
    ...ownProps,
    isFieldLoaded,
    fieldHasNoAssets,
    farmUuid,
    fieldUuid,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClickBack: () => {
    dispatch(goToPrevStep());
  },
  onClickNext: () => {
    dispatch(goToNextStep());
  },
  requestDataLayers: (farmUuid, fieldUuid) => {
    dispatch(fetchAllAssets({
      farmUuid,
      fieldUuid,
    }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,
  requestDataLayers: () => propsFromDispatch.requestDataLayers(
    propsFromState.farmUuid,
    propsFromState.fieldUuid,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(StepperDataType);

import { createSelector } from '@reduxjs/toolkit';
import { hasConnectionLinkToOrganization } from '../../jdOrganizations/helpers/functions';
import { selectFieldsAvailable } from '../../jdFields/jdFieldsSelectors';

export const selectImportStep = ({ jdImport }) => jdImport.step;

export const selectImportHasSelectedOrganizations = ({ jdImport }) => jdImport.selectedOrganizations.length > 0;

export const selectImportSelectedOrganizations = ({ jdImport }) => jdImport.selectedOrganizations;

export const selectImportSelectedFields = ({ jdImport }) => jdImport.selectedFields;

export const selectImportHasSelectedFields = ({ jdImport }) => (
  jdImport.selectedFields && jdImport.selectedFields.length > 0);

export const selectImportCountSelectedFields = ({ jdImport }) => (
  jdImport.selectedFields ? jdImport.selectedFields.length : 0
);

export const selectImportSelectedOrganizationsIds = ({ jdImport }) => (
  (jdImport.selectedOrganizations || []).map((org) => org.id)
);

export const selectImportComboBoxSelectedOrganizations = ({ jdImport }) => (
  (jdImport.selectedOrganizations || []).map((org) => ({ id: org.id, title: org.name }))
);

export const selectImportIsSelectedConnectedOrganizations = createSelector(
  [
    ({ jdOrganizations }) => jdOrganizations.orgs || [],
    ({ jdImport }) => jdImport.selectedOrganizations || [],
  ],
  (orgs, selectedOrganizations) => {
    const connectedOrganizations = orgs.filter((org) => !hasConnectionLinkToOrganization(org)).map((org) => org.id);
    return connectedOrganizations
      .filter((orgId) => !selectedOrganizations.find((selectedOrg) => selectedOrg.id === orgId)).length === 0;
  },
);

export const selectImportIsSelectedAvailableFields = createSelector(
  [
    selectFieldsAvailable,
    selectImportSelectedFields,
  ],
  (availableFields, selectedFields) => {
    let isSelectedAvailableFields = availableFields.length !== 0;
    availableFields.forEach((availableFieldSelection) => {
      if (!selectedFields.find((selectedField) => selectedField.id === availableFieldSelection.id)) {
        isSelectedAvailableFields = false;
      }
    });
    return isSelectedAvailableFields;
  },
);

export const selectImportIsLoading = ({ jdImport }) => {
  return jdImport?.isLoading === true;
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BackgroundLayer } from './types/backgroundLayer';

interface MainLayer {
  transparency?: number,
}

interface OverlayLayersState {
  mainLayer: MainLayer;
  backgroundLayer: BackgroundLayer;
}

const initialState: OverlayLayersState = {
  mainLayer: {},
  backgroundLayer: {},
};

export const overlayLayersSlice = createSlice({
  name: 'overlayLayers',
  initialState,
  reducers: {
    setBackgroundLayer(
      state,
      { payload }: PayloadAction<BackgroundLayer>,
    ) {
      state.backgroundLayer = payload;
    },
    setHideBackgroundLayer(
      state,
      { payload }: PayloadAction<boolean>,
    ) {
      state.backgroundLayer = {
        ...state.backgroundLayer,
        hideBackground: payload,
      };
    },
    updateMainLayerTransparency(
      state,
      { payload }: PayloadAction<number>,
    ) {
      state.mainLayer.transparency = payload;
    },
    resetOverlayLayers() {
      return initialState;
    },
  },
});

export const {
  setBackgroundLayer,
  setHideBackgroundLayer,
  updateMainLayerTransparency,
  resetOverlayLayers,
} = overlayLayersSlice.actions;

export default overlayLayersSlice.reducer;

import React, {
  useEffect,
} from 'react';

import useDidMount from '../../../../../hooks/useDidMount';
import usePreviewSetup from '../../hooks/usePreviewSetup';
import PreviewEquationMaps from '../PreviewEquationMaps';
import PreviewToolbar from '../PreviewToolbar';

export default function PreviewPanel() {
  const { setupPreview, setEquationsMapsTitles } = usePreviewSetup();

  useDidMount(() => {
    setupPreview();
  });

  useEffect(() => {
    setEquationsMapsTitles();
  }, [setEquationsMapsTitles]);

  return (
    <>
      <PreviewToolbar />
      <PreviewEquationMaps />
    </>
  );
}

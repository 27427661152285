import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from 'i18next';

import { EQUATIONS_PAGE_SIZE, EquationCategory } from './helpers/constants/equations';
import { updateEntity } from '../../helpers/functions/entities';
import {
  selectCustomOrganizationEquations,
  selectCustomUserEquations,
  selectFilterCategory,
  selectFilterSearch,
  selectMasterEquations,
} from './equationsSelectors';
import {
  deleteEquation,
  fetchEquations,
  saveEquation,
  verifyEquation as verifyEquationAPI,
} from './equationsAPI';
import { selectDebitedOrganization } from '../user/userSelectors';
import type {
  Equation,
  CurrentEquation,
} from './types/equation';
import { createAppAsyncThunk } from '../../app/store/helpers/functions';
import { ProductUnit } from '../../helpers/constants/units/productUnit';
import { errorNotify, successNotify } from '../notifications/helpers/functions/notify';
import { CustomError } from '../../helpers/functions/utils/errorHandling';
import { AppDispatch, AppGetState } from '../../app/store/helpers/types';
import {
  filterEmptyVariables,
  isEquationChanged,
} from './helpers/functions/equations';

export interface EquationsState {
  equations: {
    loading: boolean;
    customUser: {
      records: Equation[];
      nextToken?: string | null;
    };
    customOrganization: {
      records: Equation[];
      nextToken?: string | null;
    };
    master: {
      records: Equation[];
      nextToken?: string | null;
    };
  };
  filter: {
    search: string;
    category: EquationCategory;
  };
  currentEquation: CurrentEquation;
  equationTemplateUuid: string | null;
  verifyEquation: {
    result: number | null,
    errorMessage: string,
    inProgress: boolean,
  };
}

export const initialState: EquationsState = {
  equations: {
    loading: false,
    customUser: {
      records: [],
      nextToken: null,
    },
    customOrganization: {
      records: [],
      nextToken: null,
    },
    master: {
      records: [],
      nextToken: null,
    },
  },
  filter: {
    search: '',
    category: EquationCategory.master,
  },
  currentEquation: {
    uuid: null,
    title: '',
    description: '',
    equationAsText: '',
    equationResultVariable: '',
    dataVariables: [],
    sourceUrl: '',
    productUnit: '',
    useNumpy: false,
  },
  equationTemplateUuid: null,
  verifyEquation: {
    result: null,
    errorMessage: '',
    inProgress: false,
  },
};

export const fetchCustomUserEquations = createAppAsyncThunk(
  'equations/fetchCustomUserEquations',
  async (payload: { reset?: boolean }, { getState }) => {
    const state = getState();
    const { nextToken } = selectCustomUserEquations(state);

    return fetchEquations({
      nextToken: payload?.reset ? null : nextToken,
      pageSize: EQUATIONS_PAGE_SIZE,
      title: selectFilterSearch(state),
    });
  },
);

export const fetchCustomOrganizationEquations = createAppAsyncThunk(
  'equations/fetchCustomOrganizationEquations',
  async (payload: { reset?: boolean }, { getState }) => {
    const state = getState();
    const { nextToken } = selectCustomOrganizationEquations(state);
    // @ts-expect-error
    const debitedOrganization = selectDebitedOrganization(state);

    return fetchEquations({
      organizationUuid: debitedOrganization?.uuid,
      nextToken: payload?.reset ? null : nextToken,
      pageSize: EQUATIONS_PAGE_SIZE,
      title: selectFilterSearch(state),
    });
  },
);

export const fetchMasterEquations = createAppAsyncThunk(
  'equations/fetchMasterEquations',
  async (payload: { reset?: boolean }, { getState }) => {
    const state = getState();
    const { nextToken } = selectMasterEquations(state);

    return fetchEquations({
      showMaster: true,
      nextToken: payload?.reset ? null : nextToken,
      pageSize: EQUATIONS_PAGE_SIZE,
      title: selectFilterSearch(state),
    });
  },
);

export const fetchCurrentCategoryEquations = (args?: { reset: boolean }) => (
  dispatch: AppDispatch,
  getState: AppGetState,
) => {
  const state = getState();
  const category = selectFilterCategory(state);

  if (category === EquationCategory.customUser) {
    dispatch(fetchCustomUserEquations({
      reset: args?.reset,
    }));
  } else if (category === EquationCategory.customOrganization) {
    dispatch(fetchCustomOrganizationEquations({
      reset: args?.reset,
    }));
  } else {
    dispatch(fetchMasterEquations({
      reset: args?.reset,
    }));
  }
};

export const createEquation = createAppAsyncThunk(
  'equations/createEquation',
  async (
    payload: {
      uuid?: string,
      title: string,
      description: string,
      sourceUrl: string,
      dataVariables: string[],
      equationResultVariable: string,
      equationAsText: string,
      productUnit: ProductUnit | null,
      useNumpy: boolean,
    },
    { dispatch, getState },
  ) => {
    const state = getState();
    const {
      uuid: organizationUuid,
      // @ts-expect-error
    } = selectDebitedOrganization(state) ?? {};

    try {
      const equation = await saveEquation({
        organizationUuid,
        title: payload.title,
        description: payload.description,
        sourceUrl: payload.sourceUrl,
        dataVariables: payload.dataVariables,
        equationResultVariable: payload.equationResultVariable,
        equationAsText: payload.equationAsText,
        productUnit: payload.productUnit,
        useNumpy: payload.useNumpy,
      });

      successNotify({
        message: i18n.t('general.notifications.equation-saved'),
      });

      return equation;
    } catch (error) {
      errorNotify({
        error: new CustomError('[Equations] Unable to save equation.', {
          cause: error,
        }),
        message: i18n.t('equations.notifications.unable-save'),
        dispatch,
      });

      throw error;
    }
  },
);

export const updateEquation = createAppAsyncThunk(
  'equations/updateEquation',
  async (
    payload: {
      equation: {
        title: string,
        uuid: string,
        dataVariables: string[],
        equationResultVariable: string,
        equationAsText: string,
        productUnit: ProductUnit | null,
        useNumpy: boolean,
      },
      update: {
        title?: string,
        description?: string,
        sourceUrl?: string,
      },
    },
    { dispatch },
  ) => {
    try {
      const equation = await saveEquation({
        title: payload.equation.title,
        uuid: payload.equation.uuid,
        dataVariables: payload.equation.dataVariables,
        equationResultVariable: payload.equation.equationResultVariable,
        equationAsText: payload.equation.equationAsText,
        productUnit: payload.equation.productUnit,
        useNumpy: payload.equation.useNumpy,
        ...payload.update,
      });

      return equation;
    } catch (error) {
      errorNotify({
        error: new CustomError('[Equations] Unable to update equation.', {
          cause: error,
        }),
        dispatch,
      });

      throw error;
    }
  },
);

export const removeEquation = createAppAsyncThunk(
  'equations/removeEquation',
  async (payload: {
    uuid: string,
  }) => {
    await deleteEquation({
      uuid: payload.uuid,
    });

    successNotify({
      message: i18n.t('general.notifications.equation-deleted'),
    });
  },
);

export const verifyEquation = createAppAsyncThunk(
  'equations/verifyEquation',
  async (payload: {
    equationAsText: string,
    equationResultVariable: string,
    dataVariables: string[],
    useNumpy: boolean,
    sampleValues?: Record<string, string>,
  }) => {
    return verifyEquationAPI({
      ...payload,
      dataVariables: payload.dataVariables.map((variable) => {
        return {
          variable,
          sampleValue: payload.sampleValues?.[variable],
        };
      }),
    });
  },
);

export const equationsSlice = createSlice({
  name: 'equations',
  initialState,
  reducers: {
    resetVerifyEquation(state) {
      state.verifyEquation = initialState.verifyEquation;
    },
    setFilterSearch(state, action: PayloadAction<string>) {
      state.filter.search = action.payload;
    },
    setFilterCategory(state, action: PayloadAction<EquationCategory>) {
      state.filter.category = action.payload;
    },
    setCurrentEquation(state, action: PayloadAction<Equation>) {
      state.currentEquation = {
        ...action.payload,
        productUnit: action.payload.productUnit || '',
      };
      state.equationTemplateUuid = action.payload.uuid;
      state.verifyEquation = initialState.verifyEquation;
    },
    resetCurrentEquation(state) {
      state.currentEquation = initialState.currentEquation;
      state.equationTemplateUuid = initialState.equationTemplateUuid;
      state.verifyEquation = initialState.verifyEquation;
    },
    updateCurrentEquation(state, action: PayloadAction<Partial<EquationsState['currentEquation']>>) {
      const preparedUpdate = {
        ...action.payload,
        dataVariables: action.payload.dataVariables
          ? filterEmptyVariables(action.payload.dataVariables)
          : state.currentEquation.dataVariables,
      };

      const updatedEquation = {
        ...state.currentEquation,
        ...preparedUpdate,
      };

      if (isEquationChanged(state.currentEquation, updatedEquation)) {
        updatedEquation.uuid = null;
      }

      state.currentEquation = updatedEquation;
    },
    updateSavedEquation(state, action: PayloadAction<Partial<EquationsState['currentEquation']>>) {
      state.currentEquation = {
        ...state.currentEquation,
        ...action.payload,
      };
      state.equationTemplateUuid = action.payload.uuid ?? state.equationTemplateUuid;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomUserEquations.fulfilled, (state, action) => {
        let records: Equation[];

        state.equations.loading = false;

        if (action.meta.arg?.reset) {
          records = [];
          state.equations.master = initialState.equations.master;
          state.equations.customOrganization = initialState.equations.customOrganization;
        } else {
          records = state.equations.customUser.records;
        }

        state.equations.customUser.nextToken = action.payload?.nextToken;
        state.equations.customUser.records = [
          ...records,
          ...(action.payload?.equations || []),
        ];
      })
      .addCase(fetchCustomOrganizationEquations.fulfilled, (state, action) => {
        let records: Equation[];

        state.equations.loading = false;

        if (action.meta.arg?.reset) {
          records = [];
          state.equations.master = initialState.equations.master;
          state.equations.customUser = initialState.equations.customUser;
        } else {
          records = state.equations.customOrganization.records;
        }

        state.equations.customOrganization.nextToken = action.payload?.nextToken;
        state.equations.customOrganization.records = [
          ...records,
          ...(action.payload?.equations || []),
        ];
      })
      .addCase(fetchMasterEquations.fulfilled, (state, action) => {
        let records: Equation[];

        state.equations.loading = false;

        if (action.meta.arg?.reset) {
          records = [];
          state.equations.customOrganization = initialState.equations.customOrganization;
          state.equations.customUser = initialState.equations.customUser;
        } else {
          records = state.equations.master.records;
        }

        state.equations.master.nextToken = action.payload?.nextToken;
        state.equations.master.records = [
          ...records,
          ...(action.payload?.equations || []),
        ];
      })
      .addCase(createEquation.fulfilled, (state, action) => {
        state.equations.customUser = initialState.equations.customUser;

        if (action.payload?.organizationUuid) {
          state.equations.customOrganization = initialState.equations.customOrganization;
        }
      })
      .addCase(updateEquation.fulfilled, (state, action) => {
        state.equations.customUser.records = updateEntity(
          action.meta.arg.update,
          state.equations.customUser.records,
          ({ uuid }) => uuid === action.meta.arg.equation.uuid,
        );

        state.equations.customOrganization.records = updateEntity(
          action.meta.arg.update,
          state.equations.customOrganization.records,
          ({ uuid }) => uuid === action.meta.arg.equation.uuid,
        );
      })
      .addCase(removeEquation.fulfilled, (state, action) => {
        if (state.filter.category === EquationCategory.customUser) {
          const customOrganizationEquationIndex = state.equations.customOrganization.records.findIndex((equation) => {
            return equation.uuid === action.meta.arg.uuid;
          });

          state.equations.customUser = initialState.equations.customUser;

          if (customOrganizationEquationIndex >= 0) {
            state.equations.customOrganization = initialState.equations.customOrganization;
          }
        } else if (state.filter.category === EquationCategory.customOrganization) {
          const customUserEquationIndex = state.equations.customUser.records.findIndex((equation) => {
            return equation.uuid === action.meta.arg.uuid;
          });

          state.equations.customOrganization = initialState.equations.customOrganization;

          if (customUserEquationIndex >= 0) {
            state.equations.customUser = initialState.equations.customUser;
          }
        }
      })
      .addCase(verifyEquation.pending, (state) => {
        state.verifyEquation.inProgress = true;
      })
      .addCase(verifyEquation.fulfilled, (state, action) => {
        state.verifyEquation = {
          result: action.payload?.result != null ? action.payload.result : null,
          errorMessage: action.payload?.errorMessage || '',
          inProgress: false,
        };
      })
      .addMatcher(({ type }) => {
        return type === fetchCustomUserEquations.pending.type
          || type === fetchCustomOrganizationEquations.pending.type
          || type === fetchMasterEquations.pending.type;
      }, (state) => {
        state.equations.loading = true;
      })
      .addMatcher(({ type }) => {
        return type === fetchCustomUserEquations.rejected.type
          || type === fetchCustomOrganizationEquations.rejected.type
          || type === fetchMasterEquations.rejected.type;
      }, (state) => {
        state.equations.loading = false;
      });
  },
});

export const {
  resetVerifyEquation,
  setFilterSearch,
  setFilterCategory,
  setCurrentEquation,
  resetCurrentEquation,
  updateCurrentEquation,
  updateSavedEquation,
} = equationsSlice.actions;

export default equationsSlice.reducer;

import React from 'react';
import { Trans } from 'react-i18next';

import './index.scss';

const SatelliteIndexesTooltip = ({
  withGFPI,
}) => {
  return (
    <div className="satellite-indexes-tooltip">
      <ul className="list">
        <li>
          <Trans i18nKey="zones-ops.common.satellite-indexes-tooltip.evi2-ndvi-lai-description">
            - <span className="text_primary">EV2, NDVI</span> or <span className="text_primary">LAI</span> shows crop development pattern;
          </Trans>
        </li>
        <li>
          <Trans i18nKey="zones-ops.common.satellite-indexes-tooltip.osavi-description">
            - <span className="text_primary">OSAVI</span> shows canopy density;
          </Trans>
        </li>
        <li>
          <Trans i18nKey="zones-ops.common.satellite-indexes-tooltip.wdrvi-description">
            - <span className="text_primary">WDRVI</span> description
          </Trans>
        </li>
        <li>
          <Trans i18nKey="zones-ops.common.satellite-indexes-tooltip.gci-rci-description">
            - <span className="text_primary">GCI</span> and <span className="text_primary">RCI</span> description.
          </Trans>
        </li>
        <li>
          <Trans i18nKey="zones-ops.common.satellite-indexes-tooltip.sbi-description">
            - <span className="text_primary">SBI</span> description
          </Trans>
        </li>
        {
          withGFPI
          && (
            <li>
              <Trans i18nKey="zones-ops.common.satellite-indexes-tooltip.gfpi-description">
                - <span className="text_primary">GFPI</span> description
              </Trans>
            </li>
          )
        }
        <li>
          <Trans i18nKey="zones-ops.common.satellite-indexes-tooltip.mcari1-description">
            - <span className="text_primary">MCARI1</span> description
          </Trans>
        </li>
        <li>
          <Trans i18nKey="zones-ops.common.satellite-indexes-tooltip.ndmi-description">
            - <span className="text_primary">NDMI</span> description
          </Trans>
        </li>
        <li>
          <Trans i18nKey="zones-ops.common.satellite-indexes-tooltip.msi-description">
            - <span className="text_primary">MSI</span> description
          </Trans>
        </li>
        <li>
          <Trans i18nKey="zones-ops.common.satellite-indexes-tooltip.ccci-description">
            - <span className="text_primary">CCCI</span> description
          </Trans>
        </li>
        <li>
          <Trans i18nKey="zones-ops.common.satellite-indexes-tooltip.mcari-description">
            - <span className="text_primary">MCARI</span> description
          </Trans>
        </li>
        <li>
          <Trans i18nKey="zones-ops.common.satellite-indexes-tooltip.tcari-description">
            - <span className="text_primary">TCARI</span> description
          </Trans>
        </li>
        <li>
          <Trans i18nKey="zones-ops.common.satellite-indexes-tooltip.mcari-osavi-tcari-osavi-description">
            - <span className="text_primary">MCARI/OSAVI</span> and <span className="text_primary">TCARI/OSAVI</span> description
          </Trans>
        </li>
      </ul>
    </div>
  );
};

export default SatelliteIndexesTooltip;

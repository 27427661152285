import type { FunctionComponent } from 'react';

import Common from './common';
import EquationBased from '../../../createBatchEquationBasedAnalysis/containers/Stepper';
import { BatchOperationWorkflow } from '../../helpers/constants/workflows';

const config: Record<BatchOperationWorkflow, FunctionComponent> = {
  equationBased: EquationBased,
} as const;

const getStepper = (stepIndex: number, workflow: BatchOperationWorkflow) => {
  if (stepIndex < 1) {
    return Common;
  }

  return config[workflow];
};

export default getStepper;

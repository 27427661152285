import { API, graphqlOperation } from '@aws-amplify/api';

import getEquationsQuery from './graphql/queries/getEquations.gql';
import saveEquationMutation from './graphql/mutations/saveEquation.gql';
import deleteEquationMutation from './graphql/mutations/deleteEquation.gql';
import verifyEquationMutation from './graphql/mutations/verifyEquation.gql';
import type {
  DeleteEquationResponse,
  EquationFilter,
  GetEquationsResponse,
  SaveEquationResponse,
  VerifyEquationResponse,
} from './types/api';
import type { ProductUnit } from '../../helpers/constants/units/productUnit';
import { escapeMultilineText } from '../../helpers/functions/utils/string';

export const fetchEquations = async (filter: EquationFilter) => {
  const response = await API.graphql(graphqlOperation(getEquationsQuery, {
    filter,
  })) as GetEquationsResponse;

  return response.data?.getEquations;
};

export const saveEquation = async ({
  uuid,
  organizationUuid,
  title,
  description,
  sourceUrl,
  dataVariables,
  equationResultVariable,
  equationAsText,
  productUnit,
  useNumpy,
}: {
  uuid?: string,
  organizationUuid?: string,
  title: string,
  description?: string,
  sourceUrl?: string,
  dataVariables: string[],
  equationResultVariable: string,
  equationAsText: string,
  productUnit?: ProductUnit | null,
  useNumpy: boolean,
}) => {
  const response = await API.graphql(graphqlOperation(saveEquationMutation, {
    input: {
      uuid,
      organizationUuid,
      title,
      description: escapeMultilineText(description),
      sourceUrl,
      dataVariables,
      equationResultVariable,
      equationAsText: escapeMultilineText(equationAsText),
      productUnit: productUnit || null,
      useNumpy,
    },
  })) as SaveEquationResponse;

  return response.data?.saveEquation;
};

export const deleteEquation = async ({ uuid }: { uuid: string }) => {
  const response = await API.graphql(graphqlOperation(deleteEquationMutation, {
    input: {
      uuid,
    },
  })) as DeleteEquationResponse;

  return response.data?.deleteEquation;
};

export const verifyEquation = async ({
  equationAsText,
  equationResultVariable,
  dataVariables,
  useNumpy,
}: {
  equationAsText: string,
  equationResultVariable: string,
  dataVariables: {
    variable: string,
    sampleValue?: string,
  }[],
  useNumpy: boolean,
}) => {
  const response = await API.graphql(graphqlOperation(verifyEquationMutation, {
    input: {
      equationAsText,
      equationResultVariable,
      dataVariables,
      useNumpy,
    },
  })) as VerifyEquationResponse;

  return response.data?.verifyEquation;
};

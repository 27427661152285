import React, { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../../app/store/helpers/functions';
import {
  selectActiveDataLayer,
  selectActiveDataLayerIndex,
  selectActiveImageUuid,
} from '../../../createMultiLayerAnalysisSelectors';
import useDataLayersList from '../../../../dataLayersView/hooks/useDataLayersList';
import { AssetType } from '../../../../../../helpers/constants/entities/asset';
import { SATELLITE_INDEXES } from '../../../../../../helpers/satellite';
import CreateAnalysisDatasetsPanel from '../../../../dataLayersView/components/DataLayersList/Panels/CreateAnalysisDatasetsPanel';
import CreateAnalysisSatelliteImagesPanel from '../../../../dataLayersView/components/DataLayersList/Panels/CreateAnalysisSatelliteImagesPanel';
import CreateAnalysisTopographyMapsPanel from '../../../../dataLayersView/components/DataLayersList/Panels/CreateAnalysisTopographyMapsPanel';
import { AssignableAsset } from '../../../../dataLayersView/types/dataLayersList';
import { activateImage, updateDataLayer } from '../../../createMultiLayerAnalysisSlice';
import { selectCreateAnalysisAssets } from '../../../../../field/fieldSelectors';

import './index.scss';

export default function DataLayersPanel() {
  const dispatch = useAppDispatch();

  const activeDataLayer = useAppSelector(selectActiveDataLayer) || {};
  const activeDataLayerIndex = useAppSelector(selectActiveDataLayerIndex);
  const selectedSatelliteImageUuid = useAppSelector(selectActiveImageUuid);
  const {
    satelliteImages,
    soilDatasets,
    yieldDatasets,
    asAppliedDatasets,
    topographyMaps,
  } = useAppSelector(selectCreateAnalysisAssets);

  const handleAssetClick = (uuid: string) => {
    if (activeDataLayer.type === AssetType.satelliteImage) {
      dispatch(activateImage(uuid));
    } else {
      dispatch(updateDataLayer({
        dataLayerIndex: activeDataLayerIndex,
        updates: {
          datasetUuid: uuid,
        },
      }));
    }
  };

  const handleImageSelectionToggle = (value: boolean, uuidToToggle: string) => {
    const { satelliteImageUuids = [] }: {
      satelliteImageUuids: string[];
    } = activeDataLayer;
    const updatedUuids = value
      ? [...satelliteImageUuids, uuidToToggle]
      : satelliteImageUuids.filter((uuid) => uuid !== uuidToToggle);

    dispatch(updateDataLayer({
      dataLayerIndex: activeDataLayerIndex,
      updates: {
        satelliteImageUuids: updatedUuids,
      },
    }));
  };

  const handleAllSelectionToggle = (value: boolean, uuids: string[]) => {
    const updatedUuids = value ? uuids : [];

    dispatch(updateDataLayer({
      dataLayerIndex: activeDataLayerIndex,
      updates: {
        satelliteImageUuids: updatedUuids,
      },
    }));
  };

  const handleRecommendedSelect = (uuids: string[]) => {
    dispatch(updateDataLayer({
      dataLayerIndex: activeDataLayerIndex,
      updates: {
        satelliteImageUuids: uuids,
      },
    }));
  };

  const handleSatelliteIndexChange = (index: string | null) => {
    if (!index) {
      return;
    }

    dispatch(updateDataLayer({
      dataLayerIndex: activeDataLayerIndex,
      updates: { index },
    }));
  };

  const handleDatasetAttributeChange = (attribute: string | null) => {
    if (!attribute) {
      return;
    }

    dispatch(updateDataLayer({
      dataLayerIndex: activeDataLayerIndex,
      updates: { attribute },
    }));
  };

  const {
    assets,
    selectedUuid,
    checkedUuids,
  } = useMemo(() => {
    let assignedAssets: AssignableAsset[] = [];
    let activeLayerSelectedUuid: string = '';
    let selectedLayerCheckedUuids: string[] = [];

    if (activeDataLayer.type === AssetType.satelliteImage) {
      assignedAssets = satelliteImages;
      activeLayerSelectedUuid = selectedSatelliteImageUuid;
      selectedLayerCheckedUuids = activeDataLayer.satelliteImageUuids;
    } else if (activeDataLayer.type === AssetType.soilDataset) {
      assignedAssets = soilDatasets;
      activeLayerSelectedUuid = activeDataLayer.datasetUuid;
    } else if (activeDataLayer.type === AssetType.yieldDataset) {
      assignedAssets = yieldDatasets;
      activeLayerSelectedUuid = activeDataLayer.datasetUuid;
    } else if (activeDataLayer.type === AssetType.asAppliedDataset) {
      assignedAssets = asAppliedDatasets;
      activeLayerSelectedUuid = activeDataLayer.datasetUuid;
    } else if (activeDataLayer.type === AssetType.topographyMap) {
      assignedAssets = topographyMaps;
      activeLayerSelectedUuid = activeDataLayer.datasetUuid;
    }

    return {
      assets: assignedAssets,
      selectedUuid: activeLayerSelectedUuid,
      checkedUuids: selectedLayerCheckedUuids,
    };
  }, [
    activeDataLayer.type,
    activeDataLayer.datasetUuid,
    activeDataLayer.satelliteImageUuids,
    satelliteImages,
    soilDatasets,
    yieldDatasets,
    asAppliedDatasets,
    topographyMaps,
    selectedSatelliteImageUuid,
  ]);

  let panels;

  if (activeDataLayer.type === AssetType.satelliteImage) {
    panels = [
      <CreateAnalysisSatelliteImagesPanel
        key="top-panel"
        selectedIndex={activeDataLayer.index}
        indexes={SATELLITE_INDEXES}
        onIndexChange={handleSatelliteIndexChange}
        onRecommendedSelect={handleRecommendedSelect}
      />,
    ];
  } else if (
    activeDataLayer.type === AssetType.soilDataset
    || activeDataLayer.type === AssetType.yieldDataset
    || activeDataLayer.type === AssetType.asAppliedDataset
  ) {
    panels = [
      <CreateAnalysisDatasetsPanel
        key="top-panel"
        selectedAttribute={activeDataLayer.attribute}
        onAttributeChange={handleDatasetAttributeChange}
      />,
    ];
  } else if (activeDataLayer.type === AssetType.topographyMap) {
    panels = [
      <CreateAnalysisTopographyMapsPanel
        key="top-panel"
        selectedAttribute={activeDataLayer.attribute}
        onAttributeChange={handleDatasetAttributeChange}
      />,
    ];
  }

  const { dataLayersListComponent } = useDataLayersList({
    mode: activeDataLayer.type === AssetType.satelliteImage ? 'multiSelect' : 'default',
    assets,
    selectedUuid,
    checkedUuids,
    panels,
    onAssetClick: handleAssetClick,
    onAssetCheckboxClick: handleImageSelectionToggle,
    onAllCheckboxClick: handleAllSelectionToggle,
  });

  return (
    <div className="create-multi-year-analysis-data-layers-panel">
      <div className="create-multi-year-analysis-data-layers-panel__content">
        {dataLayersListComponent}
      </div>
    </div>
  );
}

import React, {
  useCallback,
} from 'react';

import ProductSelector from '../../components/ProductSelector';
import { selectJDSeedingWorkPlanSettings } from '../../jdWorkPlanSelectors';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';
import { setSeedingWorkPlanSettings } from '../../jdWorkPlanSlice';
import { selectJDField } from '../../../../field/fieldSelectors';
import { getJDFieldOrgId } from '../../../../field/helpers/functions/field';
import { resetJohnDeereProductsCache } from '../../../../jdProducts/jdExportAPI';
import { getJohnDeereProductsManagerLink } from '../../helpers/functions/products';
import { useJohnDeereProducts } from '../../../../jdProducts/hooks/useJohnDeereProducts';

const VarietySelector = () => {
  const dispatch = useAppDispatch();

  const { varietyId, productType } = useAppSelector(selectJDSeedingWorkPlanSettings);
  const jdField = useAppSelector(selectJDField);
  const orgId = getJDFieldOrgId(jdField);

  const {
    products, isSuccess, isLoading,
  } = useJohnDeereProducts({ orgId, productType });

  const handleProductChange = (id: string | null) => {
    dispatch(setSeedingWorkPlanSettings({ varietyId: id }));
  };

  const handleProductsLinkClick = useCallback(() => {
    dispatch(resetJohnDeereProductsCache());
  }, [dispatch]);

  return (
    <ProductSelector
      products={products}
      selectedProductId={varietyId}
      isProductsLoading={isLoading}
      isProductsLoaded={isSuccess}
      productType={productType}
      productManagerLink={getJohnDeereProductsManagerLink({
        orgId,
        productType,
      })}
      onProductsLinkClick={handleProductsLinkClick}
      onProductChange={handleProductChange}
    />
  );
};

export default VarietySelector;

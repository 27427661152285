import { useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';

import { updateSearchParam } from '../../../../helpers/navigation';
import {
  FilterParam,
  CUSTOM_TIME_RANGE_FILTER,
  TIME_RANGE_DATE_FORMAT,
} from '../helpers/constants/filters';
import {
  extractFilters,
  getDefaultCustomTimeRangeFilter,
} from '../helpers/functions/filters';
import { TimeRange } from '../types/filters';
import { Field } from '../../../asyncOperations/types/api';
import { TransformedFarm } from '../../../farms/types/farm';

export default function useOperationsFilters() {
  const location = useLocation();
  const history = useHistory();

  const {
    timeRange,
    farmUuid,
    fieldUuid,
    dateFrom,
    dateTo,
  } = extractFilters(location.search);

  const onTimeRangeChange = (value: number | string) => {
    const isCustomTimeRange = value === CUSTOM_TIME_RANGE_FILTER;
    const { from, to } = getDefaultCustomTimeRangeFilter();

    updateSearchParam(location, history, [
      { name: FilterParam.timeRange, value },
      { name: FilterParam.dateFrom, value: isCustomTimeRange ? from : '' },
      { name: FilterParam.dateTo, value: isCustomTimeRange ? to : '' },
    ]);
  };

  const onCustomTimeRangeChange = ({
    dateFrom: from,
    dateTo: to,
  }: TimeRange) => {
    updateSearchParam(location, history, [
      { name: FilterParam.dateFrom, value: from ? format(from, TIME_RANGE_DATE_FORMAT) : '' },
      { name: FilterParam.dateTo, value: to ? format(to, TIME_RANGE_DATE_FORMAT) : '' },
    ]);
  };

  const onFarmChange = (selectedFarm?: TransformedFarm, selectedField?: Field) => {
    const fieldParams = selectedFarm && selectedFarm.uuid !== selectedField?.farmUuid
      ? [{ name: FilterParam.field, value: '' }]
      : [];

    updateSearchParam(location, history, [
      ...fieldParams,
      { name: FilterParam.farm, value: selectedFarm?.uuid },
    ]);
  };

  const onFieldChange = (field?: Field) => {
    const farmParams = farmUuid && field && field?.farmUuid !== farmUuid
      ? [{ name: FilterParam.farm, value: '' }]
      : [];
    updateSearchParam(location, history, [
      ...farmParams,
      { name: FilterParam.field, value: field?.uuid },
    ]);
  };

  return {
    timeRange,
    farmUuid,
    fieldUuid,
    dateFrom,
    dateTo,
    onTimeRangeChange,
    onCustomTimeRangeChange,
    onFarmChange,
    onFieldChange,
  };
}

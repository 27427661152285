import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ZonesMapEditorPanel from '../../../../../../components/ZonesMapEditorPanel';
import { getHexColorCodes } from '../../../../../../helpers/analysis';
import {
  setTypeUpdate,
  setZonesMapGeojsonUpdate,
  setSelectedZone,
} from '../../../zonesMapSlice';
import { selectAreaUnit } from '../../../../../user/userSelectors';
import {
  selectSelectedZone,
  selectUpdates,
} from '../../../zonesMapSelectors';
import useZonesMap from '../../../hooks/useZonesMap';

const Rates = () => {
  const dispatch = useDispatch();
  const {
    farmUuid,
    fieldUuid,
    uuid,
  } = useParams();
  const {
    type: updatedType,
    zonesMapGeojson: updatedZonesMapGeojson,
    colors: updatedColors,
  } = useSelector(selectUpdates);
  const areaUnit = useSelector(selectAreaUnit);
  const selectedZone = useSelector(selectSelectedZone);
  const zonesMap = useZonesMap(farmUuid, fieldUuid, uuid);
  const type = updatedType || zonesMap?.type;
  const colors = updatedColors || getHexColorCodes(zonesMap?.geoMaps);
  const zonesMapGeojson = updatedZonesMapGeojson || zonesMap?.zonesMapGeojson;

  if (!zonesMap) {
    return null;
  }

  const onTypeChange = (value) => {
    dispatch(setTypeUpdate(value));
  };

  const onAttributesChange = (geojson) => {
    dispatch(setZonesMapGeojsonUpdate(geojson));
  };

  const onSelectedZoneChange = (zone) => {
    dispatch(setSelectedZone(zone));
  };

  return (
    <ZonesMapEditorPanel
      areaUnit={areaUnit}
      colors={colors}
      zonesMapGeojson={zonesMapGeojson}
      type={type}
      area={zonesMap?.area}
      selectedZone={selectedZone}
      onTypeChange={onTypeChange}
      onAttributesChange={onAttributesChange}
      onSelectedZoneChange={onSelectedZoneChange}
    />
  );
};

export default Rates;

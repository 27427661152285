import React from 'react';
import { withTranslation } from 'react-i18next';

import { getAreaUnitLabel } from '../../../../helpers';
import KeyValue from '../../../KeyValue';
import Button from '../../../Button';

import './index.scss';

const ExportDetails = ({
  t,
  farmName,
  fieldName,
  area,
  areaUnit,
  exportButtonLabel,
  exportButtonDisabled,
  onExportClick,
}) => {
  return (
    <div className="export-details">
      <div className="export-details__info">
        <KeyValue
          title={t('general.shared.farm')}
          value={farmName}
        />
        <KeyValue
          title={t('general.shared.field')}
          value={fieldName}
        />
        {
          area
          && (
            <KeyValue
              title={getAreaUnitLabel(areaUnit)}
              value={(+area).toFixed(2)}
            />
          )
        }
      </div>
      <Button
        variant="contained"
        color="primary"
        disabled={exportButtonDisabled}
        onClick={onExportClick}
      >
        {exportButtonLabel || t('export.download')}
      </Button>
    </div>
  );
};

export default withTranslation()(ExportDetails);

import React, { useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../app/store/helpers/functions';
import DataLayersPreviewsComponent from '../../../zonesOps/components/DataLayersPreviews';
import { selectCreateAnalysisSatelliteImages } from '../../../../field/fieldSelectors';
import { AssetType } from '../../../../../helpers/constants/entities/asset';
import { selectApiKey } from '../../../../user/userSelectors';
import { AssetsLayer } from '../../../zonesOps/types/previews';
import { fetchSatelliteImagesGeoMaps } from '../../../../field/fieldSlice';
import { GeoMapTypeOption } from '../../../../satelliteImages/helpers/constants/geoMapType';
import {
  selectActiveUuid,
  selectSelectedUuids,
} from '../../createStabilityZonesSelectors';
import { activateImage, unselectImages } from '../../createStabilityZonesSlice';
import { DEFAULT_PREVIEW_INDEX } from '../../../../../helpers/satellite';

export default function DataLayersPreviews() {
  const dispatch = useAppDispatch();

  const apiKey = useAppSelector(selectApiKey);
  const satelliteImages = useAppSelector(selectCreateAnalysisSatelliteImages);
  const activeSatelliteImageUuid = useAppSelector(selectActiveUuid);
  const selectedSatelliteImagesUuids = useAppSelector(selectSelectedUuids);

  useEffect(() => {
    dispatch(fetchSatelliteImagesGeoMaps({
      uuids: selectedSatelliteImagesUuids,
      geoMapTypes: [GeoMapTypeOption.contrast, GeoMapTypeOption.crop],
    }));
  }, [dispatch, selectedSatelliteImagesUuids]);

  const handleItemSelect = (uuid: string) => {
    dispatch(activateImage(uuid));
  };

  const handleItemRemove = (uuid: string) => {
    dispatch(unselectImages([uuid]));
  };

  const previewsLayers: AssetsLayer[] = useMemo(() => {
    return [{
      assetType: AssetType.satelliteImage,
      assets: satelliteImages.filter((image) => selectedSatelliteImagesUuids.includes(image.uuid)),
      index: DEFAULT_PREVIEW_INDEX,
    }];
  }, [satelliteImages, selectedSatelliteImagesUuids]);

  return (
    <DataLayersPreviewsComponent
      apiKey={apiKey}
      layers={previewsLayers}
      selectedUuid={activeSatelliteImageUuid}
      onItemSelect={handleItemSelect}
      onItemRemove={handleItemRemove}
    />
  );
}

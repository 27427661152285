import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import CloseIcon from '@material-ui/icons/Close';

import {
  updateDataLayer,
  updateDataLayersAndSettings,
} from '../../../createMultiLayerAnalysisSlice';
import {
  prepareDataLayers,
  getHexColorCodes,
} from '../../../../../../helpers/analysis';
import {
  ASSET_TYPE_TO_ANALYSIS_MAP_TYPE,
  AssetType,
} from '../../../../../../helpers/constants/entities/asset';
import Table from '../../../../tableView/components/Table';
import ZonesMapTable from '../../../../../../components/Views/common/ZonesMapTable';
import Tabs from '../../../../../../components/Tabs';
import { selectAreaUnit } from '../../../../../user/userSelectors';
import { selectAssets } from '../../../../../field/fieldSelectors';
import { selectGeneratedVectorAnalysisMap } from '../../../../../createAnalysis/createAnalysisSelectors';
import { getUseInterpolatedDataValue } from '../../../../../createAnalysis/helpers/functions/settings';
import { selectDataLayers } from '../../../createMultiLayerAnalysisSelectors';

import './index.scss';

const TABS = {
  settings: 0,
  result: 1,
};

const getTabs = (isGenerateVectorAnalysisMapEmpty) => {
  return [
    {
      label: {
        primary: i18n.t('general.shared.settings'),
      },
    },
    {
      label: {
        primary: i18n.t('zones-ops.multi-layer.steps.4.tabs.analysis-results'),
      },
      disabled: isGenerateVectorAnalysisMapEmpty,
    },
  ];
};

const getSettingsTableRows = (dataLayers) => {
  return dataLayers.map((dataLayer, ind) => {
    const row = {
      rowType: 'default',
      type: dataLayer.type,
      weight: dataLayer.weight,
      data: {
        _type: dataLayer.type,
        analysisType: ASSET_TYPE_TO_ANALYSIS_MAP_TYPE[dataLayer.type],
      },
      id: ind,
    };

    switch (dataLayer.type) {
      case AssetType.satelliteImage: {
        const {
          index,
          dateStrings,
        } = dataLayer;

        return {
          ...row,
          details: [
            `${i18n.t('zones-ops.multi-layer.steps.4.settings-table.index')}: ${index}`,
            `${i18n.t('zones-ops.multi-layer.steps.4.settings-table.used-images')} (${dateStrings.length}): ${dateStrings.join(', ')}`,
          ],
        };
      }
      case AssetType.topographyMap:
      case AssetType.yieldDataset:
      case AssetType.soilDataset:
      case AssetType.asAppliedDataset:
        return {
          ...row,
          details: [
            `${i18n.t('zones-ops.multi-layer.steps.4.settings-table.dataset')}: ${dataLayer.name}`,
            `${i18n.t('zones-ops.multi-layer.steps.4.settings-table.attribute')}: ${dataLayer.attribute}`,
          ],
        };
      default:
        return {};
    }
  });
};

const getSettingsTableColumns = ({
  onWeightChange,
  onDeleteRowButtonClick,
}) => [
  {
    id: 'details',
    label: i18n.t('zones-ops.multi-layer.steps.4.settings-table.details'),
    element: 'multiline',
    verticalAlign: 'middle',
  },
  {
    id: 'type',
    label: i18n.t('zones-ops.multi-layer.steps.4.settings-table.type'),
    element: 'zone-type',
    verticalAlign: 'middle',
  },
  {
    id: 'weight',
    label: i18n.t('zones-ops.multi-layer.steps.4.settings-table.weight'),
    element: 'input',
    variant: 'small',
    type: 'number',
    verticalAlign: 'middle',
    onInputChange: onWeightChange,
  },
  {
    id: 'action',
    label: '',
    element: 'icon-button',
    horizontalAlign: 'right',
    verticalAlign: 'middle',
    iconComponent: CloseIcon,
    onButtonClick: onDeleteRowButtonClick,
  },
];

const Body = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();
  const areaUnit = useSelector(selectAreaUnit);
  const dataLayers = useSelector(selectDataLayers);
  const assets = useSelector(selectAssets);
  const dataLayersRows = getSettingsTableRows(prepareDataLayers(
    dataLayers,
    assets,
  ));
  const generatedVectorAnalysisMap = useSelector(selectGeneratedVectorAnalysisMap);

  const onTabChange = (_event, index) => {
    setTabIndex(index);
  };

  const onWeightChange = (e, id) => {
    dispatch(updateDataLayer({
      dataLayerIndex: id,
      updates: {
        weight: e.target.value,
      },
    }));
  };

  const onDeleteRowButtonClick = ({ id }) => {
    const restDataLayers = dataLayers.filter((_dataLayer, index) => {
      return index !== id;
    });

    const useInterpolatedDataValue = getUseInterpolatedDataValue(
      prepareDataLayers(restDataLayers, assets),
    );

    dispatch(updateDataLayersAndSettings({
      dataLayers: restDataLayers,
      settings: {
        useInterpolatedData: useInterpolatedDataValue,
      },
    }));
  };

  useEffect(() => {
    if (generatedVectorAnalysisMap?.zonesMapGeojson) {
      setTabIndex(TABS.result);
    }
  }, [generatedVectorAnalysisMap]);

  return (
    <div className="settings-panel">
      <Tabs
        tabs={getTabs(!generatedVectorAnalysisMap)}
        value={tabIndex}
        onTabChange={onTabChange}
      />
      <div className="settings-panel__tab-content">
        {
          tabIndex === TABS.settings
          && (
            <Table
              showPagination={false}
              columns={getSettingsTableColumns({
                onWeightChange,
                onDeleteRowButtonClick,
              })}
              rows={dataLayersRows}
            />
          )
        }
        {
          tabIndex === TABS.result
          && (
            <ZonesMapTable
              areaUnit={areaUnit}
              attributes={generatedVectorAnalysisMap?.zonesMapGeojson}
              colors={getHexColorCodes(generatedVectorAnalysisMap?.geoMaps)}
            />
          )
        }
      </div>
    </div>
  );
};

export default Body;

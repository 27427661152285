import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';

import { getCellClasses } from '../../../helpers/functions/cell';

import './index.scss';

const IconButtonCell = ({
  row = {},
  headCell = {},
}) => {
  const Icon = headCell.iconComponent;

  return (
    <TableCell
      align={headCell.horizontalAlign || 'left'}
      className={getCellClasses('icon-button-cell', headCell, row)}
    >
      <IconButton
        size="small"
        title={headCell.tooltip}
        onClick={() => headCell.onButtonClick(row)}
      >
        <Icon fontSize="small" />
      </IconButton>
    </TableCell>
  );
};

export default IconButtonCell;

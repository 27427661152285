import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import clsx from 'clsx';

import StatusTabLabel from '../StatusTabLabel';
import { OperationStatus } from '../../helpers/constants/operation';

import './index.scss';

export default function StatusTabs({
  inProgressCount,
  doneCount,
  errorCount,
  ignoredCount,
  status,
  onStatusChange,
}: {
  inProgressCount: number,
  doneCount: number,
  errorCount: number,
  ignoredCount: number,
  status: OperationStatus,
  onStatusChange: (v: OperationStatus) => void,
}) {
  const { t } = useTranslation();

  const totalCount = inProgressCount + doneCount + errorCount + ignoredCount;

  const handleStatusChange = (_e: ChangeEvent<{}>, v: OperationStatus) => {
    onStatusChange(v);
  };

  return (
    <Tabs
      className="status-tabs"
      textColor="primary"
      indicatorColor="primary"
      value={status}
      onChange={handleStatusChange}
    >
      <Tab
        label={(
          <StatusTabLabel
            name={t('operations.statuses.total')}
            count={totalCount}
            className={clsx({
              'status-tabs__item-count': totalCount > 0,
            })}
          />
        )}
        value={OperationStatus.all}
        classes={{
          selected: 'status-tabs__item_selected',
        }}
      />
      <Tab
        label={(
          <StatusTabLabel
            name={t('operations.statuses.in-progress')}
            count={inProgressCount}
            className={clsx({
              'status-tabs__item-count': inProgressCount > 0,
            })}
          />
        )}
        value={OperationStatus.inProgress}
        classes={{
          selected: 'status-tabs__item_selected',
        }}
      />
      <Tab
        label={(
          <StatusTabLabel
            name={t('operations.statuses.success')}
            count={doneCount}
            className={clsx({
              'status-tabs__item-count': doneCount > 0,
            })}
          />
        )}
        value={OperationStatus.done}
        classes={{
          selected: 'status-tabs__item_selected',
        }}
      />
      <Tab
        label={(
          <StatusTabLabel
            name={t('operations.statuses.ignored')}
            count={ignoredCount}
            className={clsx({
              'status-tabs__item-count_ignored': ignoredCount > 0,
            })}
          />
        )}
        value={OperationStatus.ignored}
        classes={{
          selected: 'status-tabs__item_selected',
        }}
      />
      <Tab
        label={(
          <StatusTabLabel
            name={t('operations.statuses.error')}
            count={errorCount}
            className={clsx({
              'status-tabs__item-count_error': errorCount > 0,
            })}
          />
        )}
        value={OperationStatus.error}
        classes={{
          selected: 'status-tabs__item_selected',
        }}
      />
    </Tabs>
  );
}

import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Stepper from '../../../../../../../components/Stepper';
import AutocompleteSingle from '../../../../../../../components/AutocompleteSingle';
import OrganizationsTable from '../../../OrganizationsTable';
import { fetchOrganizationsJohnDeereWithNewOrgName } from '../../../../../../jdOrganizations/jdOrganizationsSlice';
import { resetFieldsJohnDeere } from '../../../../../../jdFields/jdFieldsSlice';
import {
  toggleOrganizationImportFromJohnDeere,
  toggleAllOrganizationsImportFromJohnDeere,
  nextStepImportFromJohnDeere,
} from '../../../../jdImportSlice';
import {
  selectOrganizationsIsLoading,
  selectOrganizationsOrgName,
  selectOrganizationsList,
  selectConnectedOrganizations,
} from '../../../../../../jdOrganizations/jdOrganizationsSelectors';
import {
  selectImportHasSelectedOrganizations,
  selectImportSelectedOrganizations,
  selectImportIsSelectedConnectedOrganizations,
} from '../../../../jdImportSelectors';
import { getSteps } from '../common';
import useDidMount from '../../../../../../../hooks/useDidMount';
import { getUserTotalArea } from '../../../../../../user/userSlice';

import './index.scss';

const StepperSelectOrganization = () => {
  const dispatch = useDispatch();

  const isLoadingOrganizations = useSelector(selectOrganizationsIsLoading);
  const orgName = useSelector(selectOrganizationsOrgName);
  const organizations = useSelector(selectOrganizationsList);
  const connectedOrganizations = useSelector(selectConnectedOrganizations);

  const hasSelectedOrganizations = useSelector(selectImportHasSelectedOrganizations);
  const selectedOrganizations = useSelector(selectImportSelectedOrganizations);
  const isSelectedConnectedOrganizations = useSelector(selectImportIsSelectedConnectedOrganizations);

  useDidMount(() => {
    dispatch(getUserTotalArea());
  });

  const { t } = useTranslation();
  const steps = getSteps().slice(0, 1);

  const onOrganizationChange = (row) => {
    const organization = organizations.find((org) => org.id === row.id);
    dispatch(toggleOrganizationImportFromJohnDeere({ selectOrganization: organization }));
    dispatch(resetFieldsJohnDeere());
  };

  const onAllOrganizationsChange = (selected) => {
    dispatch(toggleAllOrganizationsImportFromJohnDeere({ selected, selectedOrganizations: connectedOrganizations }));
    dispatch(resetFieldsJohnDeere());
  };

  const onChangeFilterByOrgName = (newOrgName) => {
    dispatch(fetchOrganizationsJohnDeereWithNewOrgName({ orgName: newOrgName }));
  };

  const onClickNext = () => {
    dispatch(nextStepImportFromJohnDeere({ step: 'selectData' }));
  };

  return (
    <Stepper
      classes={{
        root: 'john-deere__authorized-select-organization-step',
        backButton: 'back-button',
      }}
      activeStep={0}
      steps={steps}
      backHidden
      nextDisabled={!hasSelectedOrganizations || isLoadingOrganizations}
      onClickNext={onClickNext}
    >
      <Fragment key="panel">
        <AutocompleteSingle
          key='filter-by-name'
          placeholder={t('upload-data-john-deere.steps.selectOrganizations.search-by-name')}
          classes={{
            root: 'john-deere__authorized-search',
          }}
          disabled={isLoadingOrganizations}
          value={orgName}
          onChange={onChangeFilterByOrgName}
        />
        {
          isLoadingOrganizations
            ? <div className="stepper-john-deere-panel__loader"><CircularProgress /></div>
            : (
              <OrganizationsTable
                typeSelection={{
                  type: 'multiple',
                  onRowCheckboxClick: onOrganizationChange,
                  onHeadCheckboxClick: onAllOrganizationsChange,
                  allRowsSelection: isSelectedConnectedOrganizations ? 2 : 0,
                  selectedOrganizations: hasSelectedOrganizations ? selectedOrganizations : [],
                }}
              />
            )
        }
      </Fragment>
    </Stepper>
  );
};

export default StepperSelectOrganization;

import React, { Ref, forwardRef } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

import type { ListNode } from '../../types/node';

const ListView = ({
  nodes = [],
}: {
  nodes: ListNode[],
}, ref: Ref<VirtuosoHandle>) => {
  const itemRenderer = (node: ListNode) => {
    return node.getElement?.();
  };

  return (
    <Virtuoso
      className="list-view"
      ref={ref}
      data={nodes}
      itemContent={(_i, node) => itemRenderer(node)}
    />
  );
};

export default forwardRef(ListView);

import { useEffect, useState } from 'react';
import {
  SnackbarKey,
  useSnackbar,
} from 'notistack';

export default function useNotify({
  condition,
  notifyOnce = false,
  notifier,
}: {
  condition: boolean,
  notifyOnce?: boolean,
  notifier: () => SnackbarKey,

}) {
  const [notificationId, setNotificationId] = useState<null | SnackbarKey>(null);
  const { closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (condition && !notificationId) {
      const id = notifier();
      setNotificationId(id);
    } else if (!condition && notificationId && !notifyOnce) {
      closeSnackbar(notificationId);
      setNotificationId(null);
    }
  }, [
    notificationId,
    condition,
    notifyOnce,
    notifier,
    closeSnackbar,
  ]);

  useEffect(() => {
    return () => {
      if (notificationId) {
        closeSnackbar(notificationId);
      }
    };
  }, [notificationId, closeSnackbar]);
}

export const prepare = ({
  addedPhotos = [],
  photos = [],
  deletedPhotos = [],
}) => {
  let processedPhotos = photos;

  if (deletedPhotos?.length) {
    processedPhotos = photos.filter((photo) => !deletedPhotos.includes(photo.uuid));
  }

  if (addedPhotos?.length) {
    processedPhotos = [
      ...processedPhotos,
      ...addedPhotos.map(({
        uuid,
        name,
        arrayBuffer,
        noteUuid,
        commentUuid,
        type,
      }) => {
        return {
          uuid,
          name,
          noteUuid,
          commentUuid,
          isRecentlySaved: true,
          url: arrayBuffer ? URL.createObjectURL(new Blob([arrayBuffer], { type: `image/${type}` })) : '',
        };
      }),
    ];
  }
  return processedPhotos;
};

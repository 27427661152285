import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { IconButton, Typography } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import InsertDriveFileSharpIcon from '@material-ui/icons/InsertDriveFileSharp';
import CloseIcon from '@material-ui/icons/Close';

import Button from '../../../../../../components/Button';
import { bytesToMegabytes } from '../../../../../../helpers/functions/utils/uploadFiles';

import './index.scss';

export default function IssueAttachments({
  files,
  sizeLimit,
  error,
  onFilesChanged,
  onFileRemoved,
}: {
  files: File[],
  sizeLimit: number,
  error?: string,
  onFilesChanged: (f: File[]) => void,
  onFileRemoved: (i: number) => void,
}) {
  const { t } = useTranslation();
  const {
    getInputProps,
    open,
  } = useDropzone({
    minSize: 1,
    onDrop: (acceptedFiles: File[]) => {
      onFilesChanged(acceptedFiles);
    },
  });

  return (
    <div className="issue-attachments">
      <input {...getInputProps()} />
      <Typography className="issue-attachments__title">
        {t('general.popups.report-issue.attachments-label')}
      </Typography>
      <Typography className="issue-attachments__description">
        {t(
          'general.popups.report-issue.attachments-description',
          { sizeLimit: bytesToMegabytes(sizeLimit) },
        )}
      </Typography>
      {
        files.length !== 0
        && (
          <div className="issue-attachments__files">
            {
              files.map((file, index) => {
                return (
                  <div key={index} className="issue-attachments__file">
                    <InsertDriveFileSharpIcon
                      color="primary"
                      fontSize="small"
                    />
                    <Typography
                      className="issue-attachments__file-name"
                      color="primary"
                    >
                      {file.name}
                    </Typography>
                    <IconButton
                      size="small"
                      className="issue-attachments__file-remove-button"
                      onClick={() => onFileRemoved(index)}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </div>
                );
              })
            }
          </div>
        )
      }
      {
        error
          && (
            <Typography className="issue-attachments__error">
              {error}
            </Typography>
          )
      }
      <Button
        className="issue-attachments__add-file"
        color="primary"
        startIcon={<AttachFileIcon />}
        onClick={open}
      >
        {t('general.popups.report-issue.add-attachment')}
      </Button>
    </div>
  );
}

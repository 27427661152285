export enum ProductUnit {
  kgHa = 'KG_HA',
  kgAc = 'KG_AC',
  kgM2 = 'KG_M2',
  lbAc = 'LB_AC',
  lHa = 'L_HA',
  lAc = 'L_AC',
  galAc = 'GAL_AC',
  seedsHa = 'SEEDS_HA',
  seedsAc = 'SEEDS_AC',
  seedsM2 = 'SEEDS_M2',
  buAc = 'BU_AC',
  tHa = 'T_HA',
  eurHa = 'EUR_HA',
  eurAc = 'EUR_AC',
  eurM2 = 'EUR_M2',
  usdHa = 'USD_HA',
  usdAc = 'USD_AC',
  usdM2 = 'USD_M2',
}

export const PRODUCT_UNIT_TO_I18N_LABEL = {
  [ProductUnit.kgHa]: 'general.controls.rate-units.kg-ha',
  [ProductUnit.kgAc]: 'general.controls.rate-units.kg-ac',
  [ProductUnit.kgM2]: 'general.controls.rate-units.kg-m2',
  [ProductUnit.lbAc]: 'general.controls.rate-units.lb-ac',
  [ProductUnit.lHa]: 'general.controls.rate-units.l-ha',
  [ProductUnit.lAc]: 'general.controls.rate-units.l-ac',
  [ProductUnit.galAc]: 'general.controls.rate-units.gal-ac',
  [ProductUnit.seedsHa]: 'general.controls.rate-units.seeds-ha',
  [ProductUnit.seedsAc]: 'general.controls.rate-units.seeds-ac',
  [ProductUnit.seedsM2]: 'general.controls.rate-units.seeds-m2',
  [ProductUnit.buAc]: 'general.controls.rate-units.bu-ac',
  [ProductUnit.tHa]: 'general.controls.rate-units.t-ha',
  [ProductUnit.eurHa]: 'general.controls.rate-units.eur-ha',
  [ProductUnit.eurAc]: 'general.controls.rate-units.eur-ac',
  [ProductUnit.eurM2]: 'general.controls.rate-units.eur-m2',
  [ProductUnit.usdAc]: 'general.controls.rate-units.usd-ac',
  [ProductUnit.usdHa]: 'general.controls.rate-units.usd-ha',
  [ProductUnit.usdM2]: 'general.controls.rate-units.usd-m2',
};

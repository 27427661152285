import React from 'react';

import { isJohnDeereQuickStartEnabled } from '../../../../../helpers/functions/utils/appConfig';
import { OperationType } from '../../../operationsWorkflow/helpers/constants/operation';
import type { Operation } from '../../../operationsWorkflow/types/operation';
import type { ZonesMapData } from '../../types/ui';
import LastOperations from '../LastOperations';
import LastUpdatedZonesMaps from '../LastUpdatedZonesMaps';
import QuickActions from '../QuickActions';
import JdQuickStart from '../JdQuickStart';

import './index.scss';

export default function HomepagePanel({
  zonesMaps,
  operations,
  jdData,
  onShareFarmsClick,
}: {
  operations: {
    records: Record<OperationType, Operation[]>,
    isFetching: boolean,
  },
  jdData: {
    accountName: string,
    isFetching: boolean,
  },
  zonesMaps: ZonesMapData[],
  onShareFarmsClick: () => void,
}) {
  return (
    <div className="homepage-panel">
      {
        isJohnDeereQuickStartEnabled()
          && (
            <JdQuickStart
              accountName={jdData.accountName}
              isFetching={jdData.isFetching}
            />
          )
      }
      <div className="homepage-panel__navigation">
        <QuickActions
          onShareFarmsClick={onShareFarmsClick}
        />
      </div>
      <LastOperations
        operations={operations.records}
        isFetching={operations.isFetching}
      />
      <LastUpdatedZonesMaps zonesMaps={zonesMaps} />
    </div>
  );
}

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../app/store/helpers/types';

export const selectIsLegendOpen = ({ compareLayers }: RootState) => !!compareLayers.legend.uuid;

export const selectLegendUuid = ({ compareLayers }: RootState) => compareLayers.legend.uuid;

export const selectLayers = ({ compareLayers }: RootState) => compareLayers.layers;

export const selectLayer = createSelector(
  selectLayers,
  (_: unknown, layerId: string) => layerId,
  (layers, layerId) => layers.find((layer) => layer.layerId === layerId),
);

export const selectIs3d = ({ compareLayers }: RootState) => compareLayers.mapControls.is3d;

export const selectMapProvider = ({ compareLayers }: RootState) => compareLayers.mapControls.mapProvider;

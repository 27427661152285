import React from 'react';
import { Trans } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import Link from '../../../../../components/Link';
import { PAGES_ROOTS, getRootLink } from '../../../../../helpers/navigation';

export default function OpsLogTooltip() {
  return (
    <Typography>
      <Trans i18nKey="batch-equation-based.steps.preview.summary.ops-log-tooltip">
        text
        <Link
          className="link"
          target="_blank"
          to={getRootLink(PAGES_ROOTS.operations)}
        >
          link
        </Link>
        text
      </Trans>
    </Typography>
  );
}

import { prepareZonesMapJson } from '../../../../helpers/analysis';
import { getSelectedItem } from '../../../../helpers/components/legend';
import { AssetType } from '../../../../helpers/constants/entities/asset';
import { prepareEquationMapJson } from '../../../../helpers/functions/entities/equationMap';
import { createPinsGroup } from '../../../pins/helpers/functions/pinsGroup';
import {
  remapUuid as remapSatelliteImageUuid,
  transform as transformSatelliteImages,
} from '../../../satelliteImages/helpers/functions/satelliteImages';
import {
  Dataset,
  TopographyMap,
  TransformedAsAppliedDataset,
  TransformedSoilDataset,
  TransformedTopographyMap,
  TransformedYieldDataset,
} from '../../../../helpers/types/dataset';
import {
  VectorAnalysisMap,
  TransformedVectorAnalysisMap,
} from '../../../../helpers/types/vectorAnalysisMap';
import { EquationMap, TransformedEquationMap } from '../../../../helpers/types/equationMap';
import {
  ThreeDimensionalMap,
  TransformedThreeDimensionalMap,
} from '../../../../helpers/types/threeDimensionalMap';
import { Pin, PinsGroup } from '../../../pins/types';
import { Field, TransformedField } from '../../types/field';
import { Farm, TransformedFarm } from '../../../farms/types/farm';

export const transformFarms = (farms: Farm[] = []): TransformedFarm[] => {
  return farms.map((farm) => {
    const {
      usersEmails,
      ...cleanedFarm
    } = farm;

    const result: TransformedFarm = {
      ...cleanedFarm,
      _type: AssetType.farm,
      fields: transformFields(farm.fields),
    };

    if (usersEmails) {
      result.usersEmails = [...new Set(usersEmails)];
    }

    return result;
  });
};

export const transformFields = (fields: Field[] = []): TransformedField[] => {
  return fields.map((field) => {
    const {
      satelliteImages,
      soilDatasets,
      yieldDatasets,
      asAppliedDatasets,
      topographyMaps,
      vectorAnalysisMaps,
      threeDimensionalMaps,
      equationMaps,
      notes: pins,
      farmUuid,
      ...cleanedField
    } = field;
    const result: TransformedField = {
      _type: AssetType.field,
      ...cleanedField,
    };

    if (farmUuid) {
      result.farmUuid = farmUuid;
    }

    if (Array.isArray(satelliteImages)) {
      result.satelliteImages = transformSatelliteImages(satelliteImages);
    }

    if (Array.isArray(soilDatasets)) {
      result.soilDatasets = transformSoilDatasets(soilDatasets);
    }

    if (Array.isArray(vectorAnalysisMaps)) {
      result.vectorAnalysisMaps = transformVectorAnalysisMaps(vectorAnalysisMaps);
    }

    if (Array.isArray(equationMaps)) {
      result.equationMaps = transformEquationMaps(equationMaps);
    }

    if (Array.isArray(threeDimensionalMaps)) {
      result.threeDimensionalMaps = transformThreeDimensionalMaps(threeDimensionalMaps);
    }

    if (Array.isArray(yieldDatasets)) {
      result.yieldDatasets = transformYieldDatasets(yieldDatasets);
    }

    if (Array.isArray(asAppliedDatasets)) {
      result.asAppliedDatasets = transformAsAppliedDatasets(asAppliedDatasets);
    }

    if (Array.isArray(topographyMaps)) {
      result.topographyMaps = transformTopographyMaps(topographyMaps);
    }

    if (Array.isArray(pins)) {
      result.pinsGroups = groupPins(pins, result);
    }

    return result;
  });
};

export const transformTopographyMaps = (topographyMaps?: TopographyMap[]): TransformedTopographyMap[] => {
  return (topographyMaps || [])
    .map((topographyMap) => {
      return {
        _type: AssetType.topographyMap,
        ...topographyMap,
      };
    });
};

export const transformAsAppliedDatasets = (asAppliedDatasets?: Dataset[]): TransformedAsAppliedDataset[] => {
  return (asAppliedDatasets || []).map((dataset) => {
    return {
      _type: AssetType.asAppliedDataset,
      ...dataset,
    };
  });
};

export const transformYieldDatasets = (yieldDatasets?: Dataset[]): TransformedYieldDataset[] => {
  return (yieldDatasets || []).map((dataset) => {
    return {
      _type: AssetType.yieldDataset,
      ...dataset,
    };
  });
};

export const transformSoilDatasets = (soilDatasets?: Dataset[]): TransformedSoilDataset[] => {
  return (soilDatasets || []).map((dataset) => {
    return {
      _type: AssetType.soilDataset,
      ...dataset,
    };
  });
};

export const transformThreeDimensionalMaps = (threeDMaps?: ThreeDimensionalMap[]): TransformedThreeDimensionalMap[] => {
  return (threeDMaps || []).map((threeDMap) => {
    return {
      _type: AssetType.threeDimensionalMap,
      pointsStored: false,
      ...threeDMap,
    };
  });
};

export const transformEquationMaps = (equationMaps?: EquationMap[]): TransformedEquationMap[] => {
  return (equationMaps || [])
    .map((equationMap) => {
      const {
        dataVariables,
        equationMapGeojson,
        attributesJson,
        ...cleanedEquationMap
      } = equationMap;

      return {
        _type: AssetType.equationMap,
        ...cleanedEquationMap,
        ...(
          equationMapGeojson
            ? { equationMapGeojson: prepareEquationMapJson(JSON.parse(equationMapGeojson)) }
            : null
        ),
        ...(
          attributesJson
            ? { attributes: prepareEquationMapJson(JSON.parse(attributesJson)) }
            : null
        ),
        ...(
          dataVariables
            ? {
              dataVariables: dataVariables.map((dataVariable) => {
                const {
                  satelliteImages: dataVariableSatelliteImages,
                  ...cleanedDataVariable
                } = dataVariable;

                return {
                  ...cleanedDataVariable,
                  ...(
                    dataVariableSatelliteImages
                      ? { satelliteImages: remapSatelliteImageUuid(dataVariableSatelliteImages) }
                      : null
                  ),
                };
              }),
            }
            : null
        ),
      };
    });
};

export const transformVectorAnalysisMaps = (
  vectorAnalysisMaps?: VectorAnalysisMap[],
): TransformedVectorAnalysisMap[] => {
  return (vectorAnalysisMaps || [])
    .map((vamap) => {
      const {
        satelliteImages,
        dataLayers,
        zonesMapGeojson,
        attributesJson,
        ...cleanedVamap
      } = vamap;

      return {
        _type: AssetType.vectorAnalysisMap,
        ...cleanedVamap,
        ...(
          satelliteImages
            ? { satelliteImages: remapSatelliteImageUuid(satelliteImages) }
            : null
        ),
        ...(
          dataLayers
            ? {
              dataLayers: dataLayers.map((dataLayer) => {
                const {
                  satelliteImages: dataLayerSatelliteImages,
                  ...cleanedDataLayer
                } = dataLayer;

                return {
                  ...cleanedDataLayer,
                  ...(
                    dataLayerSatelliteImages
                      ? { satelliteImages: remapSatelliteImageUuid(dataLayerSatelliteImages) }
                      : null
                  ),
                };
              }),
            }
            : null
        ),
        ...(
          zonesMapGeojson
            ? { zonesMapGeojson: prepareZonesMapJson(JSON.parse(zonesMapGeojson)) }
            : null
        ),
        ...(
          attributesJson
            ? { attributes: prepareZonesMapJson(JSON.parse(attributesJson)) }
            : null
        ),
      };
    });
};

export const groupPins = (pins: Pin[], field: TransformedField): PinsGroup[] => {
  const pinsGroups: PinsGroup[] = [];

  (pins || []).forEach((pin) => {
    const {
      fieldUuid,
      vectorAnalysisMap,
      soilDataset,
    } = pin;
    const subAssetUuid = vectorAnalysisMap?.uuid
      || soilDataset?.uuid
      || fieldUuid;

    let pinsGroup = pinsGroups.find((pGroup) => {
      return pGroup.fieldUuid === fieldUuid && pGroup.uuid === subAssetUuid;
    });

    if (!pinsGroup) {
      const asset = getSelectedItem(subAssetUuid, field);

      if (!asset) {
        // skip points without asset (case when asset was deleted)
        return;
      }

      pinsGroup = createPinsGroup(asset, fieldUuid);
      pinsGroups.push(pinsGroup);
    }

    pinsGroup.pins.push(pin);
  });

  return pinsGroups;
};

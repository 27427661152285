import React from 'react';
import { useTranslation } from 'react-i18next';

import Select from '../../../../../../../components/Select';

import './index.scss';

export default function SelectAttributePanel({
  attributes,
  selectedAttribute = '',
  onAttributeChange,
}: {
  attributes: { value: string, title: string }[],
  selectedAttribute: string;
  onAttributeChange: (v: string) => void;
}) {
  const { t } = useTranslation();

  const handleAttributeChange = (value: string | null) => {
    if (value == null) {
      return;
    }

    onAttributeChange(value);
  };

  return (
    <Select
      id="equation-based-select-attribute"
      classes={{
        root: 'select-attribute-panel',
        label: 'select-attribute-panel__label',
        selectInput: 'select-attribute-panel__select',
      }}
      placeholder={t('zones-ops.equation-based.steps.4.popup.select-attribute')}
      disabled={!attributes.length}
      required
      value={selectedAttribute}
      label={t('zones-map.description.attribute')}
      options={attributes}
      onChange={handleAttributeChange}
    />
  );
}

import { AssetType } from '../../../../../helpers/constants/entities/asset';
import { FieldDatasetEntity } from '../../../../assets/types/state';
import { getUseInterpolatedDataValue } from '../../../../createAnalysis/helpers/functions/settings';
import { EquationMapData } from '../../types/equationMap';
import {
  AssetVariableData,
  AssignedVariables,
} from '../../types/variables';

const populateVariablesWithDatasetGeometryType = (
  variables: AssetVariableData[],
  datasets?: FieldDatasetEntity[],
) => {
  return variables.map((variable) => {
    const { type } = variable;

    if (type === AssetType.yieldDataset || type === AssetType.asAppliedDataset) {
      const variableDataset = datasets?.find((dataset) => dataset.uuid === variable.uuid);

      return {
        ...variable,
        geometryType: variableDataset && 'geometryType' in variableDataset
          ? variableDataset.geometryType
          : null,
      };
    }

    return variable;
  });
};

export const getFieldUseInterpolatedDataValue = ({
  assignedVariables,
  fieldDatasets,
  currentUseInterpolatedData,
}: {
  assignedVariables: AssignedVariables;
  fieldDatasets: FieldDatasetEntity[];
  currentUseInterpolatedData?: boolean;
}) => {
  if (currentUseInterpolatedData != null) {
    return currentUseInterpolatedData;
  }

  const assetVariableData = Object.values(assignedVariables).filter((variable) => !!variable);
  const variablesWithGeometryTypeValue = populateVariablesWithDatasetGeometryType(
    assetVariableData as AssetVariableData[],
    fieldDatasets,
  );

  return getUseInterpolatedDataValue(variablesWithGeometryTypeValue);
};

export const getFieldsUseInterpolatedDataValues = ({
  equationMapsData,
  fieldsAssignedVariables,
  fieldsDatasets,
}: {
  equationMapsData: Record<string, EquationMapData>;
  fieldsAssignedVariables: Record<string, AssignedVariables>;
  fieldsDatasets: Record<string, FieldDatasetEntity[]>;
}) => {
  return Object.entries(fieldsAssignedVariables)
    .reduce((acc, [fieldUuid, fieldAssignedVariables]) => {
      const useInterpolatedData = getFieldUseInterpolatedDataValue({
        assignedVariables: {
          ...equationMapsData[fieldUuid]?.assignedVariables,
          ...fieldAssignedVariables,
        },
        fieldDatasets: fieldsDatasets[fieldUuid],
        currentUseInterpolatedData: equationMapsData[fieldUuid]?.useInterpolatedData,
      });

      return {
        ...acc,
        [fieldUuid]: useInterpolatedData,
      };
    }, {});
};

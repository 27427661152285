import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardDatePicker } from '@material-ui/pickers';
import clsx from 'clsx';

import TextField from '../TextField';

import './index.scss';

interface DatePickerProps {
  title?: string;
  value?: Date | string | null;
  minDate?: Date | string | null;
  maxDate?: Date | string | null;
  classes?: { root?: string };
  placeholder?: string;
  helperText?: string;
  onChange?: (date: Date | null) => void;
  onError?: (e: ReactNode) => void;
}

const Datepicker = ({
  value = null,
  title = '',
  minDate,
  maxDate,
  classes = {},
  placeholder = '',
  helperText,
  onChange = () => {},
  onError = () => {},
}: DatePickerProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState<ReactNode>();

  const handleError = (e: ReactNode) => {
    if (error !== e) {
      setError(e);
      onError(e);
    }
  };

  return (
    <div className={clsx('datepicker', classes.root)}>
      <KeyboardDatePicker
        autoOk
        disableFuture
        variant="inline"
        inputVariant="outlined"
        format="dd/MM/yyyy"
        title={title}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        placeholder={placeholder}
        invalidDateMessage={t('general.controls.datepicker.error')}
        error={!!helperText}
        helperText={helperText}
        maxDateMessage={t('general.controls.datepicker.error')}
        minDateMessage={t('general.controls.datepicker.error')}
        TextFieldComponent={TextField}
        InputAdornmentProps={{
          classes: {
            root: 'datepicker__input-adornment',
          },
        }}
        KeyboardButtonProps={{
          size: 'small',
        }}
        onChange={onChange}
        onError={handleError}
      />
    </div>
  );
};

export default Datepicker;

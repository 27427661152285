import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

import PersistentPopup from '../../../../../components/PersistentPopup';
import Link from '../../../../../components/Link';
import OperationsIcon from '../../../../../components/Icons/operations';
import { openPopup } from '../../../popups/popupsSlice';
import { resetProcessingPanel } from '../../uploadDataSlice';
import { UploadStep } from '../../helpers/constants/upload';
import { selectProcessingPanelStep } from '../../uploadDataSelectors';
import {
  PAGES_ROOTS,
  getRootLink,
} from '../../../../../helpers/navigation';
import { POPUPS } from '../../../popups/helpers/constants/popups';
import UploadFilesPopupHeader from './UploadFilesPopupHeader';
import UploadFilesPopupTable from './UploadFilesPopupTable';
import UploadFilesPopupStatusList from './UploadFilesPopupStatusList';

import './index.scss';

export default function UploadFilesPopup({
  onCancel,
}: {
  onCancel: () => void,
}) {
  const dispatch = useDispatch();

  const step = useSelector(selectProcessingPanelStep);

  const handleClose = () => {
    if (step === UploadStep.uploading) {
      dispatch(openPopup({
        type: POPUPS.closeUpload,
        onConfirm: () => {
          onCancel();
          dispatch(resetProcessingPanel());
        },
      }));
    } else {
      onCancel();
      dispatch(resetProcessingPanel());
    }
  };

  return (
    <PersistentPopup
      classes={{
        root: 'upload-files-popup',
        accordionDetails: 'upload-files-popup__accordion-details',
      }}
      onClose={handleClose}
    >
      <UploadFilesPopupHeader key="header" />
      <div
        key="content"
        className="upload-files-popup__content"
      >
        <div className="upload-files-popup__description">
          <OperationsIcon className="upload-files-popup__description-icon"/>
          <div>
            <Trans i18nKey="general.popups.upload-data.description">
              text <Link to={getRootLink(PAGES_ROOTS.operations)} target="_blank">Link</Link> text
            </Trans>
          </div>
        </div>
        <UploadFilesPopupStatusList />
        <UploadFilesPopupTable/>
      </div>
    </PersistentPopup>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import './index.scss';

export default function EquationPlaceholder() {
  const { t } = useTranslation();

  return (
    <div className="equation-placeholder">
      <Typography className="equation-placeholder__label">
        {t('batch-analytics.steps.equation.equation-placeholder')}
      </Typography>
    </div>
  );
}

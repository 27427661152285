import { getRatesData } from '../../analysis';
import { GeoMap } from '../../types/api';
import type { TransformedVectorAnalysisMap } from '../../types/vectorAnalysisMap';

export const isVamapInvalid = (vamap: TransformedVectorAnalysisMap): boolean => {
  const {
    geoMaps,
    status,
  } = vamap || {};

  return status === 'ERROR' || (!geoMaps || geoMaps.length === 0);
};

export const hasRates = (vamap: TransformedVectorAnalysisMap) => {
  const attributes = vamap.zonesMapGeojson || vamap.attributes;
  const ratesHeaders = getRatesData(attributes);

  return !!ratesHeaders.length;
};

export const getVamapThumbnailGeoMap = (geoMaps: GeoMap[] = []) => {
  return geoMaps.find(({ shortName }) => shortName === 'thumbnail');
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

import useDidMount from '../../../../../hooks/useDidMount';
import { fetchProfileJohnDeere } from '../../../../jdProfile/jdProfileSlice';
import { fetchOrganizationsJohnDeereWithNewOrgName } from '../../../../jdOrganizations/jdOrganizationsSlice';
import {
  selectOrganizationsIsLoading,
  selectOrganizationsOrgName,
} from '../../../../jdOrganizations/jdOrganizationsSelectors';
import OrganizationsTable from '../../../jdImport/containers/OrganizationsTable';
import AutocompleteSingle from '../../../../../components/AutocompleteSingle';
import AmplitudeAnalytics from '../../../../../helpers/classes/amplitudeAnalytics';
import useCurrentPage from '../../../../../hooks/useCurrentPage';

import '../../components/common/index.scss';
import './index.scss';

const SelectOrganization = ({
  organizationId,
  onOrganizationChange,
}: {
  organizationId: number | null,
  onOrganizationChange: (v: number) => void,
}) => {
  const dispatch = useDispatch();
  const page = useCurrentPage();
  const { t } = useTranslation();

  const isLoadingOrganizations = useSelector(selectOrganizationsIsLoading);
  const orgName = useSelector(selectOrganizationsOrgName);

  const onChangeFilterByOrgName = (newOrgName: string) => {
    dispatch(fetchOrganizationsJohnDeereWithNewOrgName({ orgName: newOrgName }));
    if (newOrgName) {
      AmplitudeAnalytics.trackOrganizationNameFilterChanged({ page });
    }
  };

  useDidMount(() => {
    dispatch(fetchProfileJohnDeere());
    dispatch(fetchOrganizationsJohnDeereWithNewOrgName({ orgName }));
  });

  return (
    <div className="export-to-john-deere-popup__content select-organization">
      <p className="export-to-john-deere-popup__content-description">
        {t('general.popups.export-to-john-deere.description-select-organization')}
      </p>
      <AutocompleteSingle
        key='filter-by-name'
        placeholder={t('general.popups.export-to-john-deere.search-by-name')}
        classes={{
          root: 'john-deere__authorized-search',
        }}
        disabled={isLoadingOrganizations}
        value={orgName}
        onChange={onChangeFilterByOrgName}
      />
      {
        isLoadingOrganizations
          ? <div className="stepper-john-deere-panel__loader"><CircularProgress /></div>
          : (
            <OrganizationsTable
              typeSelection={{
                type: 'single',
                selectedOrganization: organizationId,
                onOrganizationChange: (newSelectedOrganization: { id: number }) => {
                  onOrganizationChange(newSelectedOrganization.id);
                },
              }}
            />
          )
      }
    </div>
  );
};

export default SelectOrganization;

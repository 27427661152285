import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import PowerIcon from '@material-ui/icons/Power';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

import './index.scss';

export default function NoIntegartions() {
  const { t } = useTranslation();

  return (
    <Container className="no-integrations">
      <div>
        <PowerIcon className="no-integrations__icon" />
        <NotInterestedIcon className="no-integrations__icon" />
      </div>
      <Typography variant="h2">{t('integrations.marketplace.no-integrations')}</Typography>
    </Container>
  );
}

import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';

import { AssignableAsset, DataLayersListFilters, Mode } from '../../../types/dataLayersList';
import Checkbox from '../../../../../../components/Checkbox';
import ExpandFiltersButton from '../../../../filters/components/ExpandFiltersButton';
import SatelliteFilters from '../Filters/SatelliteFilters';
import DatasetFilters from '../Filters/DatasetFilters';
import TopographyFilters from '../Filters/TopographyFilters';
import {
  areAsAppliedDatasets,
  areSatelliteImages,
  areSoilDatasets,
  areTopographyMaps,
  areYieldDatasets,
} from '../../../../../../helpers/functions/entities/assets';
import { AssetType } from '../../../../../../helpers/constants/entities/asset';

import './index.scss';

interface BaseProps {
  mode: Mode;
  assets: AssignableAsset[];
  filteredAssets: AssignableAsset[];
  filters: DataLayersListFilters;
  onFiltersChange: (filters: Partial<DataLayersListFilters>) => void;
}

interface DefaultHeaderProps extends BaseProps {
  mode: 'default';
}

interface MultiSelectHeaderProps extends BaseProps {
  mode: 'multiSelect';
  checkedUuids: string[];
  onSelectAllClick: (checked: boolean, uuids: string[]) => void;
}

export default function ListHeader(props: DefaultHeaderProps | MultiSelectHeaderProps) {
  const { t } = useTranslation();

  const {
    mode,
    assets,
    filteredAssets,
    filters,
    onFiltersChange,
  } = props;
  const multiSelectMode = mode === 'multiSelect';

  const [filterExpanded, setFilterExpanded] = useState(false);

  let title;
  let filterPanel;
  let result;

  const handleCheckboxClick = () => {
    if (multiSelectMode) {
      const uuids = filteredAssets.map(({ uuid }) => uuid);
      props.onSelectAllClick(!isChecked, uuids);
    }
  };

  const isChecked = useMemo(() => {
    if (!multiSelectMode || props.checkedUuids.length === 0) {
      return 0;
    }

    const checkedUuidsSet = new Set(props.checkedUuids);
    const hasUnchecked = props.filteredAssets.some(({ uuid }) => !checkedUuidsSet.has(uuid));

    return hasUnchecked ? 1 : 2;
  }, [props, multiSelectMode]);

  if (areSatelliteImages(assets)) {
    title = t('general.shared.satellite-monitoring');
    filterPanel = (
      <SatelliteFilters
        satelliteImages={assets}
        filterExpanded={filterExpanded}
        filters={filters[AssetType.satelliteImage]}
        onChangeFilters={(f) => onFiltersChange({ [AssetType.satelliteImage]: f })}
      />
    );
  } else if (areSoilDatasets(assets)) {
    title = t('general.shared.soil-data');
    filterPanel = (
      <DatasetFilters
        filterExpanded={filterExpanded}
        filters={filters[AssetType.soilDataset]}
        onChangeFilters={(f) => onFiltersChange({ [AssetType.soilDataset]: f })}
      />
    );
  } else if (areYieldDatasets(assets)) {
    title = t('general.shared.yield-data');
    filterPanel = (
      <DatasetFilters
        filterExpanded={filterExpanded}
        filters={filters[AssetType.yieldDataset]}
        onChangeFilters={(f) => onFiltersChange({ [AssetType.yieldDataset]: f })}
      />
    );
  } else if (areAsAppliedDatasets(assets)) {
    title = t('general.shared.as-applied-data');
    filterPanel = (
      <DatasetFilters
        filterExpanded={filterExpanded}
        filters={filters[AssetType.asAppliedDataset]}
        onChangeFilters={(f) => onFiltersChange({ [AssetType.asAppliedDataset]: f })}
      />
    );
  } else if (areTopographyMaps(assets)) {
    title = t('general.shared.topography');
    filterPanel = (
      <TopographyFilters
        topographyMaps={assets}
        filterExpanded={filterExpanded}
        filters={filters[AssetType.topographyMap]}
        onChangeFilters={(f) => onFiltersChange({ [AssetType.topographyMap]: f })}
      />
    );
  }

  if (assets.length === 0) {
    result = null;
  } else {
    result = (
      <div className="data-layers-list-header">
        <div className={clsx('data-layers-list-header__content', {
          'data-layers-list-header__content_default': mode === 'default',
        })}
        >
          {mode === 'multiSelect' && <Checkbox value={isChecked} onClick={handleCheckboxClick}/>}
          <Typography className="data-layers-list-header__title">{title}</Typography>
          <ExpandFiltersButton
            isExpanded={filterExpanded}
            onClick={() => setFilterExpanded(!filterExpanded)}
          />
        </div>
        {filterPanel}
      </div>
    );
  }

  return result;
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import Button from '../../../../../components/Button';

import './index.scss';

export default function ToggleAdvancedConfigButton({
  value,
  showLabelI18nKey,
  hideLabelI18nKey,
  onClick,
}: {
  value: boolean,
  showLabelI18nKey: string,
  hideLabelI18nKey: string,
  onClick: () => void,
}) {
  const { t } = useTranslation();

  return (
    <Button
      className={clsx('toggle-advanced-config-button', {
        'toggle-advanced-config-button_expanded': value,
      })}
      variant="text"
      color="primary"
      endIcon={(
        value
          ? <ExpandLessIcon />
          : <ExpandMoreIcon />
      )}
      onClick={onClick}
    >
      {
        value
          ? t(hideLabelI18nKey)
          : t(showLabelI18nKey)
      }
    </Button>
  );
}

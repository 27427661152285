export const extractMinMax = (points, attribute) => {
  let min;
  let max;

  if (points) {
    min = Infinity;
    max = -Infinity;
    points.features.forEach((point) => {
      const value = point.properties[attribute];

      if (value == null) {
        return;
      }

      if (value < min) {
        min = value;
      } else if (value > max) {
        max = value;
      }
    });
  }

  return {
    min,
    max,
  };
};

import React, { ReactNode } from 'react';
import clsx from 'clsx';

import './index.scss';

export default function SinglePanelContainer({
  children,
  className,
}: { children: ReactNode, className?: string }) {
  return (
    <div className={clsx('single-panel-container', className)}>{children}</div>
  );
}

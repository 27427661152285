import { AssetType } from '../../../../helpers/constants/entities/asset';
import type { AnalysisMapType } from '../../../../helpers/constants/entities/vectorAnalysisMap';
import type { PinsGroup } from '../../types';

const getPinsGroupEntity = ({
  _type,
  uuid,
  analysisType,
}: {
  _type: AssetType,
  uuid: string,
  analysisType?: AnalysisMapType,
}): PinsGroup['entity'] => {
  const result: PinsGroup['entity'] = {
    _type,
    uuid,
  };

  if (analysisType) {
    result.analysisType = analysisType;
  }

  return result;
};

export const createPinsGroup = (
  entity: {
    _type: AssetType,
    uuid: string,
    name: string,
    analysisType?: AnalysisMapType,
  },
  fieldUuid: string,
): PinsGroup => {
  return {
    _type: AssetType.pinsGroup,
    uuid: entity.uuid,
    name: entity.name,
    fieldUuid,
    entity: getPinsGroupEntity(entity),
    pins: [],
  };
};

export const getAssetHasPins = (uuid: string, pinsGroups: PinsGroup[] = []): boolean => {
  const pinsGroup = pinsGroups.find((p) => {
    return p.uuid === uuid;
  });

  return !!pinsGroup?.pins?.length;
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import AddIcon from '@material-ui/icons/Add';

import BackdropLoading from '../../Loading/BackdropLoading';
import PopupHeader from '../PopupHeader';
import Button from '../../Button';
import TextField from '../../TextField';

import './index.scss';

const InputsListPopup = ({
  title,
  description,
  placeholder,
  inputType,
  addItemLabel,
  confirmLabel,
  onCancel,
  onConfirm,
}) => {
  const [processing, setProcessing] = React.useState(false);
  const [items, setItems] = React.useState(['']);
  const [confirmButtonDisabled, setConfirmButtonDisabled] = React.useState(true);
  const [addItemButtonDisabled, setAddItemButtonDisabled] = React.useState(true);
  const { t } = useTranslation();

  const handleItemChange = (e, valueIndex) => {
    let someInputFilled = false;
    let hasEmptyInput = false;

    const trimmedValue = e.target.value.trim();
    const updatedItems = items.map((value, index) => {
      const result = index === valueIndex ? trimmedValue : value;

      someInputFilled = !!result || someInputFilled;
      hasEmptyInput = !result || hasEmptyInput;

      return result;
    });

    setItems(updatedItems);
    setConfirmButtonDisabled(!someInputFilled);
    setAddItemButtonDisabled(hasEmptyInput);
  };

  const handleAddItemClick = () => {
    setItems([...items, '']);
    setAddItemButtonDisabled(true);
  };

  const handleConfirmClick = () => {
    const filledItems = items.filter((value) => value);

    setProcessing(true);
    onConfirm(filledItems);
  };

  return (
    <Dialog
      open
      classes={{
        paper: 'items-list-popup',
      }}
    >
      <PopupHeader
        title={title}
        onCancel={onCancel}
      />
      <DialogContent>
        <DialogContentText
          classes={{
            root: 'items-list-popup__content-text',
          }}
        >
          {description}
        </DialogContentText>
        <div className="items-list-popup__input-container">
          {
            items.map((value, index) => (
              <TextField
                classes={{
                  wrapper: 'items-list-popup__input',
                }}
                key={index}
                type={inputType}
                value={value}
                placeholder={placeholder}
                onChange={(e) => handleItemChange(e, index)}
              />
            ))
          }
        </div>
        <Button
          classes={{
            root: 'items-list-popup__add-button',
          }}
          startIcon={<AddIcon />}
          disabled={addItemButtonDisabled}
          onClick={handleAddItemClick}
        >
          {addItemLabel}
        </Button>
      </DialogContent>
      <DialogActions
        classes={{
          root: 'items-list-popup__actions',
        }}
      >
        <Button
          variant="outlined"
          onClick={onCancel}
        >
          {t('general.controls.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={confirmButtonDisabled}
          onClick={handleConfirmClick}
        >
          {confirmLabel}
        </Button>
      </DialogActions>
      { processing && <BackdropLoading /> }
    </Dialog>
  );
};

export default InputsListPopup;

import React from 'react';
import { useTranslation } from 'react-i18next';

import './index.scss';

const ChangeBackgroundLayerToolsPanel = () => {
  const { t } = useTranslation();

  return (
    <div className="change-background-layer-tools-panel">
      {t('change-background.description')}
    </div>
  );
};

export default ChangeBackgroundLayerToolsPanel;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Auth } from '@aws-amplify/auth';
import { setUser } from '@sentry/react';

import {
  AreaUnit,
  LANGUAGES,
} from './helpers/constants/user';
import {
  saveUserData,
  getCombinedUser,
  getOrders as getOrdersAPI,
  saveDetails,
  saveOrganization as saveOrganizationAPI,
  getUserTotalArea as getUserTotalAreaAPI,
  saveStripeCustomer,
} from './userAPI';
import { selectAuthenticationInProgress, selectIdentity } from './userSelectors';
import {
  destroy as destroyProductFruits,
} from '../ui/applicationShell/helpers/functions/productFruits';
import { CustomError } from '../../helpers/functions/utils/errorHandling';
import Amplitude from '../../helpers/classes/amplitude';

const initialState = {
  isLoaded: false,
  data: {
    uuid: '',
    email: '',
    vatNumber: '',
    language: LANGUAGES.english,
    areaUnit: AreaUnit.acres,
    totalArea: 0,
    maxArea: 0,
    orders: [],
    details: {},
    identity: null,
    country: null,
    companyType: null,
    // TODO: replace with 'simpler' data for application bootstrap, all data move to separate reducer
    organizations: [],
    acceptedTermsAndConditions: false,
    apiKey: '',
    reachedAreaLimit: false,
    actualCreditsLimit: '',
    phoneNumber: '',
    stripeCustomerId: '',
  },
  cognitoGroups: [],
  auth: {
    authenticated: false,
    inProgress: false,
    withRedirect: true,
  },
};

// `identity` needed when user uploads files
export const setupUserIdentity = createAsyncThunk(
  'user/setupUserIdentity',
  async () => {
    // TODO in case of error need to configure application to prevent upload until `identity` setted up correctly
    const userInfo = await Auth.currentUserInfo();

    return saveUserData({
      identity: userInfo.id,
    })
      .then(({ identity }) => {
        return identity;
      });
  },
  {
    condition: (_, { getState }) => {
      if (selectIdentity(getState())) {
        return false;
      }
    },
  },
);

export const getUserData = createAsyncThunk(
  'user/getData',
  async () => {
    const [
      userData,
      { signInUserSession },
    ] = await Promise.all([
      getCombinedUser(),
      Auth.currentAuthenticatedUser(),
    ]);
    const cognitoGroups = signInUserSession.accessToken.payload['cognito:groups'];
    const {
      orders,
      packages,
      ...otherUserData
    } = userData.getUserData;
    const packagesMap = new Map(packages.map((pckg) => {
      return [pckg.orderUuid, pckg];
    }));
    const ordersWithPackages = orders.map((order) => {
      return {
        ...order,
        packageInfo: packagesMap.get(order.uuid),
      };
    });

    const user = {
      id: otherUserData.uuid,
      email: otherUserData.email,
    };

    setUser(user);
    Amplitude.initUser(user);

    return {
      data: {
        ...otherUserData,
        orders: ordersWithPackages,
        phoneNumber: userData.getStripeCustomer?.phone || '',
        stripeCustomerId: userData.getStripeCustomer?.id || '',
      },
      cognitoGroups,
    };
  },
);

export const updateData = createAsyncThunk(
  'user/updateData',
  (updates) => {
    return saveUserData(updates);
  },
);

export const updateAdditionalUserData = createAsyncThunk(
  'user/updateAdditionalUserData',
  async (updates, { rejectWithValue }) => {
    const { phoneNumber } = updates;

    try {
      return await Promise.all([
        saveUserData(updates),
        saveStripeCustomer({ phoneNumber }),
      ]);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateDetails = createAsyncThunk(
  'user/updateDetails',
  async (details) => {
    let result = null;

    try {
      result = await saveDetails(details);
    } catch (error) {
      throw new CustomError('[User] Unable to update user details.', {
        cause: error,
      });
    }

    return result;
  },
);

export const getOrders = createAsyncThunk(
  'user/getOrders',
  () => {
    return getOrdersAPI();
  },
);

export const saveOrganization = createAsyncThunk(
  'user/saveOrganization',
  ({
    uuid,
    name,
    surname,
    givenName,
    phone,
    email,
  }) => {
    return saveOrganizationAPI({
      uuid,
      name,
      surname,
      givenName,
      phone,
      email,
    });
  },
);

export const signOut = createAsyncThunk(
  'user/signOut',
  () => {
    destroyProductFruits();

    return Auth.signOut();
  },
);

export const authenticate = createAsyncThunk(
  'user/authenticate',
  async () => {
    await Auth.currentAuthenticatedUser();
  },
  {
    condition: (_payload, { getState }) => {
      const authInProgress = selectAuthenticationInProgress(getState());

      return !authInProgress;
    },
  },
);

export const getUserTotalArea = createAsyncThunk(
  'user/getUserTotalArea',
  async () => {
    const userData = await getUserTotalAreaAPI();

    return userData.totalArea;
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    prependOrder(state, action) {
      const order = {
        plan: action.payload.plan,
        currency: action.payload.currency,
        billingPeriodInMonths: action.payload.billingPeriodInMonths,
        paymentMethod: action.payload.paymentMethod,
        referralCode: action.payload.referralCode,
        uuid: action.payload.uuid,
        status: action.payload.status,
      };

      state.data.orders = [
        order,
        ...state.data.orders,
      ];
    },
    updateOrder(state, action) {
      state.data.orders = state.data.orders.map((order) => {
        if (action.payload.uuid !== order.uuid) {
          return order;
        }

        return {
          ...order,
          plan: action.payload.plan,
          currency: action.payload.currency,
          billingPeriodInMonths: action.payload.billingPeriodInMonths,
          paymentMethod: action.payload.paymentMethod,
          referralCode: action.payload.referralCode,
          status: action.payload.status,
        };
      });
    },
    updateOrganizationUser(state, action) {
      state.data.organizations = state.data.organizations.map((organization) => {
        if (organization.uuid === action.payload.organizationUuid) {
          return {
            ...organization,
            users: organization.users.map((user) => {
              if (user.userUuid === action.payload.userUuid) {
                return {
                  ...user,
                  allFarms: action.payload.allFarms,
                };
              }

              return user;
            }),
          };
        }

        return organization;
      });
    },
    addUsersToOrganization(state, action) {
      state.data.organizations = state.data.organizations.map((organization) => {
        if (organization.uuid === action.payload.organizationUuid) {
          return {
            ...organization,
            users: [
              ...organization.users,
              ...action.payload.users,
            ],
          };
        }

        return organization;
      });
    },
    deleteUsersFromOrganization(state, action) {
      state.data.organizations = state.data.organizations.map((organization) => {
        if (organization.uuid === action.payload.organizationUuid) {
          return {
            ...organization,
            users: organization.users.filter(({ userUuid }) => {
              return !action.payload.usersUuids.includes(userUuid);
            }),
          };
        }

        return organization;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setupUserIdentity.fulfilled, (state, action) => {
        state.data.identity = action.payload;
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.isLoaded = true;
        state.data = {
          uuid: action.payload.data.uuid,
          email: action.payload.data.email,
          vatNumber: action.payload.data.vatNumber,
          language: action.payload.data.language,
          areaUnit: action.payload.data.areaUnit,
          identity: action.payload.data.identity,
          totalArea: action.payload.data.totalArea,
          maxArea: action.payload.data.maxArea,
          orders: action.payload.data.orders,
          details: action.payload.data.details,
          organizations: action.payload.data.organizations,
          acceptedTermsAndConditions: action.payload.data.acceptedTermsAndConditions,
          apiKey: action.payload.data.apiKey,
          reachedAreaLimit: action.payload.data.reachedAreaLimit,
          actualCreditsLimit: action.payload.data.actualCreditsLimit,
          country: action.payload.data.country,
          companyType: action.payload.data.companyType,
          phoneNumber: action.payload.data.phoneNumber,
          stripeCustomerId: action.payload.data.stripeCustomerId,
        };
        state.cognitoGroups = action.payload.cognitoGroups || state.cognitoGroups;
      })
      .addCase(getOrders.pending, (state) => {
        state.isLoaded = false;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.isLoaded = true;
        state.data.orders = action.payload;
      })
      .addCase(updateDetails.pending, (state) => {
        state.isLoaded = false;
      })
      .addCase(updateDetails.fulfilled, (state, action) => {
        state.isLoaded = true;
        state.data.details = action.payload;
      })
      .addCase(updateDetails.rejected, (state) => {
        state.isLoaded = true;
      })
      .addCase(saveOrganization.fulfilled, (state, action) => {
        const index = state.data.organizations.findIndex(({ uuid }) => {
          return action.payload.uuid === uuid;
        });

        if (index === -1) {
          state.data.organizations = [
            {
              uuid: action.payload.uuid,
              name: action.payload.name,
              surname: action.payload.surname,
              givenName: action.payload.givenName,
              phone: action.payload.phone,
              email: action.payload.email,
              users: [],
            },
            ...state.data.organizations,
          ];
        } else {
          state.data.organizations[index] = {
            ...state.data.organizations[index],
            uuid: action.payload.uuid,
            name: action.payload.name,
            surname: action.payload.surname,
            givenName: action.payload.givenName,
            phone: action.payload.phone,
            email: action.payload.email,
          };
        }
      })
      .addCase(authenticate.pending, (state) => {
        state.auth.inProgress = true;
      })
      .addCase(authenticate.fulfilled, (state) => {
        state.auth.inProgress = false;
        state.auth.authenticated = true;
      })
      .addCase(authenticate.rejected, (state) => {
        state.auth.inProgress = false;
        state.auth.authenticated = false;
      })
      .addCase(signOut.pending, (state, action) => {
        state.auth.inProgress = true;
        state.auth.withRedirect = action.meta.arg;
      })
      .addCase(signOut.fulfilled, (_state, action) => {
        return {
          ...initialState,
          auth: {
            ...initialState.auth,
            withRedirect: action.meta.arg,
          },
        };
      })
      .addCase(getUserTotalArea.fulfilled, (state, action) => {
        state.data.totalArea = action.payload;
      })
      .addCase(updateData.fulfilled, (state, action) => {
        const {
          language,
          areaUnit,
          totalArea,
          maxArea,
        } = action.payload;

        state.data.language = language;
        state.data.areaUnit = areaUnit;
        state.data.totalArea = totalArea;
        state.data.maxArea = maxArea;
      })
      .addCase(updateAdditionalUserData.fulfilled, (state, action) => {
        const {
          country,
          companyType,
          acceptedTermsAndConditions,
        } = action.payload[0];
        const { id, phone, currency } = action.payload[1];

        state.data.country = country;
        state.data.companyType = companyType;
        state.data.acceptedTermsAndConditions = acceptedTermsAndConditions;
        state.data.phoneNumber = phone;
        state.data.stripeCustomerId = id;
        state.data.currency = currency;
      });
  },
});

export const {
  prependOrder,
  updateOrder,
  updateOrganizationUser,
  addUsersToOrganization,
  deleteUsersFromOrganization,
} = userSlice.actions;

export default userSlice.reducer;

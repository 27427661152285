import { useState } from 'react';

export default function useLocalStorage(key, initialValue) {
  const [stateValue, setStateValue] = useState(() => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  });

  const setValue = (value) => {
    setStateValue(value);
    localStorage.setItem(key, JSON.stringify(value));
  };

  return [stateValue, setValue];
}

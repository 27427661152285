import { createSelector } from '@reduxjs/toolkit';

import { transformToObjectMap } from './helpers/functions/labels';

export const selectLabels = ({ labels }) => labels.labels;

export const selectLoaded = ({ labels }) => labels.loaded;

export const selectLoading = ({ labels }) => labels.loading;

export const selectLabelsMap = createSelector(
  selectLoaded,
  selectLabels,
  (loaded, labels) => (loaded ? transformToObjectMap(labels) : null),
);

import { KeyboardEvent } from 'react';

import { EventKey } from '../../constants/eventKey';

export const isEnterKeyPressed = (event: KeyboardEvent): boolean => {
  return event?.key === EventKey.Enter;
};

export const isEnterKeyPressedOnCurrentTarget = (event: KeyboardEvent): boolean => {
  return isEnterKeyPressed(event) && event?.target === event?.currentTarget;
};

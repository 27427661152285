import { hasConnectionLinkToOrganization } from './helpers/functions';

export const selectOrganizationsHasError = ({ jdOrganizations }) => jdOrganizations.orgs === null
    && jdOrganizations.isLoading === false;

export const selectOrganizationsIsLoading = ({ jdOrganizations }) => jdOrganizations?.isLoading === true;

export const selectOrganizationsList = ({ jdOrganizations }) => jdOrganizations.orgs || [];

export const selectConnectedOrganizations = ({ jdOrganizations }) => (jdOrganizations.orgs || [])
  .filter((org) => !hasConnectionLinkToOrganization(org));

export const selectOrganizationsTotalCount = ({ jdOrganizations }) => jdOrganizations.totalCount;

export const selectOrganizationsPage = ({ jdOrganizations }) => jdOrganizations.page;

export const selectOrganizationsPageSize = ({ jdOrganizations }) => jdOrganizations.pageSize;

export const selectOrganizationsOrgName = ({ jdOrganizations }) => jdOrganizations.orgName;

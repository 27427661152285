import React from 'react';
import i18n from 'i18next';
import Typography from '@material-ui/core/Typography';

import Tooltip from '../../../../../../../components/Tooltip';

import './index.scss';

export default function PlanetClearPercentTooltip({
  clearPercent,
}: {
  clearPercent: string,
}) {
  return (
    <Tooltip
      classes={{
        label: 'planet-clear-percent__tooltip-label',
      }}
      tooltip={(
        <div className="planet-clear-percent__tooltip">
          <Typography
            variant="subtitle2"
            className="planet-clear-percent__tooltip-title"
          >
            {i18n.t('field.assets.planet-image-items.tooltip.title', {
              value: clearPercent,
            })}
          </Typography>
          <Typography variant="body2">
            {i18n.t('field.assets.planet-image-items.tooltip.description')}
          </Typography>
        </div>
      )}
    >
      <span className="planet-clear-percent__text">
        {i18n.t('field.assets.planet-image-items.clear-percent', {
          value: clearPercent,
        })}
      </span>
    </Tooltip>
  );
}

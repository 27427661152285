import type {
  ModeConfig,
  Mode,
  DataLayersTreeNode,
} from '../../types/dataLayersTree';
import type { AssetGroupType } from '../../../../../helpers/constants/entities/asset';
import { CustomError } from '../../../../../helpers/functions/utils/errorHandling';

export const getModeValue = (assetGroupType: AssetGroupType | null, modeConfig: ModeConfig): Mode => {
  let result: Mode;

  if (typeof modeConfig === 'string') {
    result = modeConfig;
  } else if (assetGroupType) {
    result = modeConfig[assetGroupType] || 'default';
  } else {
    result = 'default';
  }

  return result;
};

export const isDatasetsGroupingEnabled = (
  grouping: boolean,
  assetGroupType: AssetGroupType,
  modeConfig: ModeConfig,
): boolean => {
  const mode = getModeValue(assetGroupType, modeConfig);
  let result: boolean;

  if (mode === 'default') {
    result = grouping;
  } else if (mode === 'multiSelect') {
    result = false;
  } else {
    throw new CustomError(`[DataLayersTree] Unknown mode ${mode}`);
  }

  return result;
};

export const isAttributesDisplayed = (
  withAttributes: boolean,
  assetGroupType: AssetGroupType | null,
  modeConfig: ModeConfig,
) => {
  const mode = getModeValue(assetGroupType, modeConfig);

  return withAttributes && mode === 'multiSelect';
};

export const generateAssetNodeId = (uuid: string, assetGroupType: AssetGroupType) => {
  return `${uuid}_${assetGroupType}`;
};

export const generateAttributeNodeId = (assetUuid: string, attribute: string, viewType?: string) => {
  return viewType ? `${assetUuid}_${attribute}_${viewType}` : `${assetUuid}_${attribute}`;
};

export const generateFilterNodeId = (assetGroupType: AssetGroupType) => {
  return `${assetGroupType}_filter`;
};

export const getParentAssetId = (item: DataLayersTreeNode): string | undefined => {
  if (item?.type === 'asset') {
    return item.uuid;
  } if (item?.parent) {
    return getParentAssetId(item.parent as DataLayersTreeNode);
  }
  return '';
};

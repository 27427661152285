import useDidMount from '../../../hooks/useDidMount';
import { getSubscriptionObservable } from '../subscriptionSlice';
import { ParsedEvent } from '../types/event';

export function useSubscription(next: (e: ParsedEvent) => void) {
  useDidMount(() => {
    const subscribe = async () => {
      return (await getSubscriptionObservable()).subscribe({
        next,
        error: subscribe,
      });
    };
    const subscription = subscribe();

    return async () => {
      (await subscription).unsubscribe();
    };
  });
}

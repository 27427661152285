export enum CompanyType {
  AgCooperative = 'Ag Cooperative',
  AgConsultancy = 'Ag Consultancy',
  AgtechSoftware = 'Agtech Software',
  Agroholding = 'Agroholding',
  FoodProducer = 'Food Producer',
  AgMachineryProducer = 'Ag Machinery producer',
  AgMachineryDealer = 'Ag Machinery dealer',
  AgInputsProducer = 'Ag Inputs producer',
  AgInputsDistributor = 'Ag Inputs distributor',
  UniversityResearch = 'University / Research',
  Farmer = 'Farmer',
  Consulting = 'Consulting / Services for GeoPard',
}

export const COMPANY_TYPE_I18N_KEY = {
  [CompanyType.AgCooperative]: 'ag-cooperative',
  [CompanyType.AgConsultancy]: 'ag-consultancy',
  [CompanyType.AgtechSoftware]: 'agtech-software',
  [CompanyType.Agroholding]: 'agroholding',
  [CompanyType.FoodProducer]: 'food-producer',
  [CompanyType.AgMachineryProducer]: 'ag-machinery-producer',
  [CompanyType.AgMachineryDealer]: 'ag-machinery-dealer',
  [CompanyType.AgInputsProducer]: 'ag-inputs-producer',
  [CompanyType.AgInputsDistributor]: 'ag-inputs-distributor',
  [CompanyType.UniversityResearch]: 'university-research',
  [CompanyType.Farmer]: 'farmer',
  [CompanyType.Consulting]: 'consulting',
};

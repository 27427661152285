import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import JDLogoIcon from '../../../jdIntegration/components/Icons/jd-logo.svg';
import Link from '../../../../../components/Link';
import BackdropLoading from '../../../../../components/Loading/BackdropLoading';
import { PAGES_ROOTS, getRootLink } from '../../../../../helpers/navigation';
import {
  getHelpLink,
  getBrandName,
} from '../../../../../helpers/functions/utils/appConfig';

import './index.scss';

export default function JdQuickStart({
  accountName,
  isFetching,
}: {
  accountName: string,
  isFetching: boolean,
}) {
  const { t } = useTranslation();

  return (
    <div className="jd-quick-start">
      {isFetching && <BackdropLoading />}
      <JDLogoIcon className="jd-quick-start__icon" />
      <div className="jd-quick-start__content">
        <div className="jd-quick-start__main">
          <Typography variant="h6">
            {t('homepage.jd-quick-start.title')}
          </Typography>
          <div>
            <Typography className="jd-quick-start__main-text">
              <Trans i18nKey="homepage.jd-quick-start.description">
                text
                <a
                  className="link"
                  target="_blank"
                  href={getHelpLink()}
                >
                  link
                </a>
                {{ brandName: getBrandName() }}
              </Trans>
            </Typography>
            {
              accountName
                && (
                  <Typography className="jd-quick-start__main-text">
                    <Trans i18nKey="homepage.jd-quick-start.connected-account">
                      <span className="jd-quick-start__main-text_secondary">
                        text
                      </span>
                      {{ accountName }}
                    </Trans>
                  </Typography>
                )
            }
          </div>
        </div>
        <div className="jd-quick-start__actions">
          <Link to={getRootLink(PAGES_ROOTS.uploadDataJohnDeere)}>
            {t('general.navigation.import-data')}
          </Link>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { connect } from 'react-redux';

import { selectWorkflow } from '../../zonesOpsSelectors';
import config from './config';

const LegendZonesOps = ({ Component }) => {
  return Component ? <Component /> : null;
};

const mapStateToProps = (state) => {
  return {
    Component: config[selectWorkflow(state)],
  };
};

export default connect(mapStateToProps)(LegendZonesOps);

import { useDispatch, useSelector } from 'react-redux';

import {
  generateMultiLayerVectorAnalysisMap,
} from '../../../createAnalysis/createAnalysisSlice';
import {
  selectDataLayers,
  selectSettings,
} from '../createMultiLayerAnalysisSelectors';

export default function useGenerateMultiLayerVamap() {
  const dispatch = useDispatch();
  const settings = useSelector(selectSettings);
  const dataLayers = useSelector(selectDataLayers);

  return () => dispatch(generateMultiLayerVectorAnalysisMap({
    dataLayers,
    ...settings,
  }));
}

import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';

import ComboBox, { Option } from '../../../../../../../components/ComboBox';
import Button from '../../../../../../../components/Button';

import './index.scss';

export default function SelectFiltersStep({
  filterType,
  filterTypeOptions,
  filterPeriod,
  filterPeriodOptions,
  filterPeriodFrom,
  filterPeriodFromOptions,
  filterPeriodTill,
  filterPeriodTillOptions,
  isAvailablePeriodRange,
  isValidForm,
  onFilterTypeChange = () => {},
  onFilterPeriodChange = () => {},
  onFilterPeriodFromChange = () => {},
  onFilterPeriodTillChange = () => {},
  onBack = () => {},
  onConfirm = () => {},
}: {
  filterType: Option[],
  filterTypeOptions: Option[],
  filterPeriod: Option<number | string>[],
  filterPeriodOptions: Option<number | string>[],
  filterPeriodFrom: Option<number> | null,
  filterPeriodFromOptions: Option<number>[],
  filterPeriodTill: Option<number> | null,
  filterPeriodTillOptions: Option<number>[],
  isAvailablePeriodRange: boolean,
  isValidForm: boolean,
  onFilterTypeChange: (v: Option[]) => void,
  onFilterPeriodChange: (v: Option<number | string>[]) => void,
  onFilterPeriodFromChange: (v: Option<number>) => void,
  onFilterPeriodTillChange: (v: Option<number>) => void,
  onBack: () => void,
  onConfirm: () => void,
}) {
  const { t } = useTranslation();

  return (
    <Fragment>
      <DialogContent classes={{ root: 'import-settings-john-deere-popup__content' }} >
        <div>
          <span>{t('general.popups.import-settings-john-deere.select-filters.description')}</span>
        </div>
        <FormControl
          component="fieldset"
          classes={{ root: 'filters-control' }}
        >
          <ComboBox
            multiple
            limitTags={2}
            title={t('general.popups.import-settings-john-deere.select-filters.type.label')}
            value={filterType}
            options={filterTypeOptions}
            getOptionSelected={(option, value) => {
              return option.value === value.value;
            }}
            onChange={(_event, value) => {
              onFilterTypeChange(value);
            }}
            classes={{
              autocomplete: 'autocomplete',
            }}
          />
          <ComboBox<number | string, true>
            multiple
            limitTags={2}
            title={t('general.popups.import-settings-john-deere.select-filters.period.label')}
            value={filterPeriod}
            options={filterPeriodOptions}
            getOptionSelected={(option, value) => {
              return option.value === value.value;
            }}
            onChange={(_event, value) => {
              onFilterPeriodChange(value);
            }}
            classes={{
              autocomplete: 'autocomplete',
            }}
          />
          { isAvailablePeriodRange
            && (
              <div className="filters-control__period">
                <div className="filters-control__period-range">
                  <ComboBox<number>
                    title={t('general.popups.import-settings-john-deere.select-filters.period.from-label')}
                    value={filterPeriodFrom}
                    options={filterPeriodFromOptions}
                    getOptionSelected={(option, value) => {
                      return option.value === value.value;
                    }}
                    onChange={(_event, value) => {
                      onFilterPeriodFromChange(value!);
                    }}
                    classes={{
                      autocomplete: 'autocomplete',
                    }}
                  />
                  <ComboBox<number>
                    title={t('general.popups.import-settings-john-deere.select-filters.period.till-label')}
                    value={filterPeriodTill}
                    options={filterPeriodTillOptions}
                    getOptionSelected={(option, value) => {
                      return option.value === value.value;
                    }}
                    onChange={(_event, value) => {
                      onFilterPeriodTillChange(value!);
                    }}
                    classes={{
                      autocomplete: 'autocomplete',
                    }}
                  />
                </div>
              </div>
            )}
        </FormControl>
      </DialogContent>
      <DialogActions classes={{ root: 'select-organizations-john-deere-popup__actions' }}>
        <Button
          variant="outlined"
          onClick={onBack}
        >
          {t('general.stepper.back')}
        </Button>
        <Button
          disabled={!isValidForm}
          variant="contained"
          color="primary"
          onClick={onConfirm}
        >
          {t('general.popups.done')}
        </Button>
      </DialogActions>
    </Fragment>
  );
}

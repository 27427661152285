import React, {
  forwardRef,
  ReactElement,
  Ref,
  useState,
} from 'react';
import { Divider } from '@material-ui/core';
import { Virtuoso } from 'react-virtuoso';
import clsx from 'clsx';

import { AutocompleteItem } from '../../features/ui/autocomplete/types/autocomplete';
import Autocomplete from '../Autocomplete';

import './index.scss';

const ITEM_HEIGHT = 37;
const DEFAULT_LISTBOX_HEIGHT = 300;

const calculateListboxHeight = (element: HTMLDivElement | null) => {
  const {
    maxHeight = 0,
    paddingTop = 0,
    paddingBottom = 0,
  } = element ? getComputedStyle(element) : {};

  const maxHeightValue = maxHeight ? parseFloat(maxHeight) : 0;
  const paddingTopValue = paddingTop ? parseFloat(paddingTop) : 0;
  const paddingBottomValue = paddingBottom ? parseFloat(paddingBottom) : 0;

  return maxHeightValue - paddingTopValue - paddingBottomValue;
};

const ListboxComponent = (
  {
    children,
    ...listboxProps
  } : {
    children: ReactElement[],
  },
  ref: Ref<HTMLDivElement>,
) => {
  const [height, setHeight] = useState(DEFAULT_LISTBOX_HEIGHT);

  const handleRefCallback = (element: HTMLDivElement | null) => {
    const maxHeight = calculateListboxHeight(element);

    if (children.length * ITEM_HEIGHT < maxHeight) {
      setHeight(children.length * ITEM_HEIGHT);
    } else if (maxHeight) {
      setHeight(maxHeight);
    }

    if (typeof ref === 'function') {
      ref(element);
    }
  };

  return (
    <div ref={handleRefCallback} {...listboxProps}>
      <Virtuoso
        style={{ height }}
        totalCount={children.length}
        data={children}
        itemContent={(_i, child) => child}
      />
    </div>
  );
};

export default function LabelsSelect({
  options,
  value,
  limitTags,
  placeholder,
  placeholderWithValue,
  searchLabel,
  allValuesLabel,
  emptyLabel,
  disableCustomLabel,
  classes,
  loading,
  disabled,
  onGetOptions = () => {},
  onChange,
}: {
  options: Record<string, string[]>,
  value: AutocompleteItem[],
  limitTags: number,
  placeholder: string,
  placeholderWithValue: string,
  searchLabel?: string,
  allValuesLabel?: string,
  emptyLabel?: string,
  disableCustomLabel?: boolean,
  loading?: boolean,
  disabled?: boolean,
  classes?: {
    list?: string;
  },
  onGetOptions?: () => void;
  onChange: (value: AutocompleteItem[]) => void;
}) {
  const renderOption = ({ title, withDivider }: AutocompleteItem) => {
    return (
      <span
        className='labels-select__option'
        title={title}
      >
        {title}
        {withDivider && <Divider absolute /> }
      </span>
    );
  };

  return (
    <Autocomplete
      limitTags={limitTags}
      placeholder={placeholder}
      placeholderWithValue={placeholderWithValue}
      searchLabel={searchLabel}
      allValuesLabel={allValuesLabel}
      emptyLabel={emptyLabel}
      disableCustomLabel={disableCustomLabel}
      options={options}
      value={value}
      loading={loading}
      disabled={disabled}
      classes={{
        root: clsx('labels-select__labels-list', classes?.list),
      }}
      data-test="labels-filter"
      renderOption={renderOption}
      onChange={onChange}
      onGetOptions={onGetOptions}
      ListboxComponent={forwardRef(ListboxComponent)}
    />
  );
}

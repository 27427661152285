import i18n from 'i18next';

export const getVectorAnalysisMapErrorMessage = (statusMessage?: string): string => {
  const ERROR_MESSAGES = {
    SatimageHasNotEnoughBandsException: i18n.t('zones-ops.common.notifications.exceptions.sat-image-has-not-enough-bands'),
    AnalysisMapExecutionException: i18n.t('zones-ops.common.notifications.exceptions.analysis-map-execution'),
    TypeNotSupportedException: i18n.t('zones-ops.common.notifications.exceptions.type-not-supported'),
    IndexNotSupportedException: i18n.t('zones-ops.common.notifications.exceptions.index-not-supported'),
    ClassificationNotSupportedException: i18n.t('zones-ops.common.notifications.exceptions.classification-not-supported'),
    InputCompatibilityException: i18n.t('zones-ops.common.notifications.exceptions.input-compatibility'),
    FieldGridNotFoundException: i18n.t('zones-ops.common.notifications.exceptions.field-grid-not-found'),
    ProductUnitNotSupportedException: i18n.t('zones-ops.common.notifications.exceptions.product-unit-not-supported'),
    EquationNotDefinedException: i18n.t('zones-ops.common.notifications.exceptions.equation-not-defined'),
    ResultVariableNotDefinedException: i18n.t('zones-ops.common.notifications.exceptions.result-variable-not-defined'),
  };

  let result: string;

  if (statusMessage && statusMessage in ERROR_MESSAGES) {
    const typedStatusMessage = statusMessage as keyof typeof ERROR_MESSAGES;
    result = ERROR_MESSAGES[typedStatusMessage];
  } else {
    result = ERROR_MESSAGES.AnalysisMapExecutionException;
  }

  return result;
};

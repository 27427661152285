export const selectSelectionMode = ({ userFarms }) => {
  return userFarms.selectionMode;
};

export const selectSelectedFarms = ({ userFarms }) => {
  return userFarms.selectedFarms;
};

export const selectActiveTab = ({ userFarms }) => {
  return userFarms.activeTab;
};

export const selectOwnFarmsQuery = ({ userFarms }) => {
  return userFarms.ownFarmsQuery;
};

export const selectSharedFarmsQuery = ({ userFarms }) => {
  return userFarms.sharedFarmsQuery;
};

import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import './index.scss';

const ImagePreview = ({
  images = [],
  imageIndex = 0,
}) => {
  const [activeImage, setActiveImage] = useState({});
  const [activeImageIndex, setActiveImageIndex] = useState(imageIndex);
  const maxSteps = images.length - 1;

  const handleNext = () => {
    setActiveImageIndex((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveImageIndex((prev) => prev - 1);
  };

  useEffect(() => {
    setActiveImage(images[activeImageIndex]);
  }, [activeImageIndex, images]);

  return (
    <div className="image-preview">
      <IconButton
        className="image-preview__navigation-button image-preview__navigation-button_back"
        component="span"
        onClick={handleBack}
        disabled={activeImageIndex === 0}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      {
        activeImage
        && (
          <div
            className="image-preview__image-slide"
            style={{
              backgroundImage: `url(${activeImage.url})`,
            }}
          />
        )
      }
      <IconButton
        className="image-preview__navigation-button image-preview__navigation-button_forward"
        component="span"
        onClick={handleNext}
        disabled={activeImageIndex === maxSteps}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </div>
  );
};

export default ImagePreview;

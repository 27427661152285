import React, { useEffect } from 'react';

import { useCreateFarmMutation } from '../../farmsAPI';
import CreateEntity from '../../../../components/Popups/CreateEntity';
import { CreateFarmResult } from '../../types/api';
import AmplitudeAnalytics from '../../../../helpers/classes/amplitudeAnalytics';
import useCurrentPage from '../../../../hooks/useCurrentPage';

const CreateFarmPopup = ({
  farmName = '',
  onFarmCreated,
  onCancel,
  ...popup
}: {
  farmName?: string,
  onFarmCreated?: (v: CreateFarmResult) => void;
  onCancel: () => void,
}) => {
  const page = useCurrentPage();
  const [createFarm, { isLoading }] = useCreateFarmMutation();

  useEffect(() => {
    if (page) {
      AmplitudeAnalytics.trackCreateEntityPopupOpened({
        page,
        entityType: 'farm',
      });
    }
  }, [page]);

  async function handleCreateClick(value: string) {
    const response = await createFarm({ name: value });

    AmplitudeAnalytics.trackEntityCreated({
      page,
      entityType: 'farm',
    });

    if ('data' in response) {
      onFarmCreated?.(response.data);
    }

    onCancel();
  }

  return (
    <CreateEntity
      {...popup}
      textValue={farmName}
      i18nKey="create-farm"
      processing={isLoading}
      onCancel={onCancel}
      onConfirm={handleCreateClick}
    />
  );
};

export default CreateFarmPopup;

// TODO: convert to enum
export const INSTRUMENTS = {
  SPLIT_ZONE: 'split-zone',
  MANUALLY_DRAW_POLYGON: 'manually-draw-polygon',
  MERGE_POLYGONS: 'merge-polygons',
  CHANGE_COLOR: 'change-color',
  CLONE_POLYGONS: 'clone-polygons',
} as const;

export const MAX_GEOMETRIES = 1000;

import React from 'react';
import { useLocation } from 'react-router-dom';

import { getPlanSelectAppUrl } from '../helpers/functions/utils/vendorConfigs/pricing';

export default function Pricing() {
  const location = useLocation();
  const planSelectAppUrl = `${getPlanSelectAppUrl()}${location.search}`;

  return (
    <iframe
      style={{
        width: '100%',
        height: '100%',
        border: 'none',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: '#ffffff',
      }}
      src={planSelectAppUrl}
      title="pricing"
    />
  );
}

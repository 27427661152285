import type { Option } from '../../../../../../../components/ComboBox';
import { ImportType } from '../../../../helpers/constants/importType';
import { FOR_PERIOD_LABEL } from '../../common';

export const getImportSettings = ({
  importType,
  filterType,
  filterPeriod,
  filterPeriodFrom,
  filterPeriodTill,
}: {
  importType: ImportType,
  filterType: Option[],
  filterPeriod: Option<number | string>[],
  filterPeriodFrom: Option<number> | null,
  filterPeriodTill: Option<number> | null,
}) => {
  let availableFilterPeriod = [];

  if (filterPeriod.length === 1 && filterPeriod[0].value === FOR_PERIOD_LABEL) {
    availableFilterPeriod.push(filterPeriodFrom!.value);

    for (let year = 1; year <= filterPeriodTill!.value - filterPeriodFrom!.value; year++) {
      availableFilterPeriod.push(filterPeriodFrom!.value + year);
    }
  } else {
    availableFilterPeriod = filterPeriod.map((period) => period.value).filter((period) => period !== 'all');
  }

  return {
    importType,
    filterType: filterType.map((type) => type.value).filter((type) => type !== 'all'),
    filterPeriod: availableFilterPeriod,
  };
};

import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../../app/store/helpers/functions';
import AssignVariablePopupComponent from '../../../components/AssignVariablePopup';
import {
  selectFieldAssetEntity,
  selectFieldAssetsEntities,
  selectIsFieldSatelliteImagesLoading,
} from '../../../../../assets/assetsSelectors';
import { selectEquationMapsData } from '../../../createBatchEquationBasedAnalysisSelectors';
import { fetchFieldSatelliteImages } from '../../../../../assets/assetsSlice';
import { getAssetVariableUuid } from '../../../helpers/functions/variables';
import {
  AssetVariableData,
  AssignableAsset,
} from '../../../types/variables';
import { assignEquationMapData } from '../../../createBatchEquationBasedAnalysisSlice';
import { AssetType } from '../../../../../../helpers/constants/entities/asset';
import {
  isDataset,
  isSatelliteImage,
  isTopographyMap,
} from '../../../../../../helpers/functions/entities/assets';
import { getFullAttribute } from '../../../../../../helpers/functions/entities/dataset';
import { getFieldUseInterpolatedDataValue } from '../../../helpers/functions/settings';

export default function AssignVariablePopup({
  fieldUuid,
  variable,
  onCancel,
  onConfirm,
}: {
  fieldUuid: string,
  variable: string,
  onCancel: () => void,
  onConfirm: () => void,
}) {
  const dispatch = useAppDispatch();
  const assets = useAppSelector((state) => selectFieldAssetsEntities(state, fieldUuid));
  const satellitesLoading = useAppSelector((state) => selectIsFieldSatelliteImagesLoading(state, fieldUuid));
  const {
    [fieldUuid]: equationMapData,
  } = useAppSelector(selectEquationMapsData);
  const assignedVariable = equationMapData.assignedVariables[variable];
  const assignedAsset = useAppSelector((state) => {
    return selectFieldAssetEntity(state, getAssetVariableUuid(assignedVariable));
  });

  useEffect(() => {
    dispatch(fetchFieldSatelliteImages({ fieldUuid }));
  }, [fieldUuid, dispatch]);

  const handleConfirm = ({
    asset,
    attribute,
    satelliteImageUuids,
    variableName = '',
  }: {
    asset: AssignableAsset | null,
    attribute: string,
    satelliteImageUuids: string[] | null,
    variableName?: string,
  }) => {
    let data: AssetVariableData | null = null;

    if (satelliteImageUuids) {
      data = {
        satelliteImageUuids,
        type: AssetType.satelliteImage,
        name: variableName,
        attribute,
      };
    } else if (asset && !isSatelliteImage(asset)) {
      if (isDataset(asset) || isTopographyMap(asset)) {
        const fullAttribute = getFullAttribute(attribute, asset.fullAttributes);

        if (fullAttribute) {
          data = {
            uuid: asset.uuid,
            type: asset._type,
            name: variableName,
            attribute: fullAttribute,
          };
        }
      } else {
        data = {
          uuid: asset.uuid,
          type: asset._type,
          name: variableName,
        };
      }
    }

    if (data) {
      const useInterpolatedData = getFieldUseInterpolatedDataValue({
        fieldDatasets: [
          ...assets.yieldDatasets,
          ...assets.asAppliedDatasets,
        ],
        assignedVariables: {
          ...equationMapData.assignedVariables,
          [variable]: data,
        },
      });

      dispatch(assignEquationMapData({
        assignedVariable: {
          fieldUuid,
          variable,
          data,
        },
        useInterpolatedData,
      }));
    }

    onConfirm();
  };

  return (
    <AssignVariablePopupComponent
      fieldUuid={fieldUuid}
      assets={assets}
      assignedAsset={assignedAsset}
      assignedVariable={assignedVariable}
      satellitesLoading={satellitesLoading}
      onConfirm={handleConfirm}
      onCancel={onCancel}
    />
  );
}

import React, {
  useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { isVectorAnalysis } from '../../../../../../../helpers/functions/entities/assets';
import {
  addLayers,
  resetLegend,
  setLegend,
} from '../../../../compareLayersSlice';
import {
  selectLayers,
  selectIsLegendOpen,
} from '../../../../compareLayersSelectors';
import {
  selectField,
  selectAssets,
  selectAllLoading,
  selectSatelliteLoading,
  selectVectorAnalysisMaps,
} from '../../../../../../field/fieldSelectors';
import useDataLayersTree from '../../../../../dataLayersView/hooks/useDataLayersTree';
import { ASSET_TYPE_TO_ASSET_GROUP } from '../../../../../../../helpers/constants/entities/asset';
import LayersSelectedPanel from '../../../../../dataLayersView/components/DataLayersTree/Panels/LayersSelectedPanel';
import LayersLimitDataLayersTreePanel from '../../../../components/LayersLimitDataLayersTreePanel';
import {
  transformCompareLayersToCheckedData,
  transformSelectedLayersToCompareLayersData,
} from '../../../../helpers/functions/layers';
import { compareLayersDataLayersTreeMode } from '../../../../helpers/constants';
import { getSelectedItem } from '../../../../../../../helpers/components/legend';
import { getParentAssetId } from '../../../../../dataLayersView/helpers/functions/dataLayersTree';

import './index.scss';

const AddDataLayersPanel = ({
  selectedItem,
  onViewTypeControlChange,
  onRawProcessedClick,
  onAssetItemClick = () => {},
  onConfirm = () => {},
  onCancel = () => {},
}) => {
  const fieldAssets = useSelector(selectAssets);
  const vectorAnalysisMaps = useSelector(selectVectorAnalysisMaps);
  const loading = useSelector(selectAllLoading);
  const satellitesLoading = useSelector(selectSatelliteLoading);
  const isLegendOpen = useSelector(selectIsLegendOpen);
  const selectedLayers = useSelector(selectLayers);
  const field = useSelector(selectField);
  const [checkedLayers, setCheckedLayers] = useState(transformCompareLayersToCheckedData(selectedLayers, field));
  const dispatch = useDispatch();

  const {
    threeDimensionalMaps,
    ...cleanedFieldAssets
  } = fieldAssets;

  const selectedAssetGroupType = ASSET_TYPE_TO_ASSET_GROUP[selectedItem?._type];
  const selectedItemUuid = selectedItem?.uuid;

  const handleAssetItemClick = (_type, item) => {
    if (item.uuid === selectedItemUuid) {
      return;
    }

    onAssetItemClick(item);

    if (isLegendOpen) {
      dispatch(resetLegend());

      if (!isVectorAnalysis(item)) {
        return;
      }

      dispatch(setLegend(item.uuid));
    }
  };

  const handleAttributeNodeClick = (itemNode) => {
    if (!itemNode || !itemNode.attributes) {
      return;
    }

    const parentAssetId = getParentAssetId(itemNode);
    const parentAsset = getSelectedItem(parentAssetId, field);

    if (selectedItem.uuid !== parentAssetId) {
      onAssetItemClick(parentAsset);
    }

    const { attribute, rawProcessed } = itemNode.attributes;

    onViewTypeControlChange(attribute, parentAsset);
    onRawProcessedClick(rawProcessed, parentAsset);
  };

  const handleLayersPanelConfirmClick = (compareAssetsData) => {
    const layers = transformSelectedLayersToCompareLayersData(compareAssetsData, field, selectedLayers);
    dispatch(addLayers({ layers }));
    onConfirm();
  };

  const children = [
    <LayersSelectedPanel
      key="top-panel"
      mode={compareLayersDataLayersTreeMode}
      checked={checkedLayers}
      onSelectAllChange={setCheckedLayers}
    />,
    <LayersLimitDataLayersTreePanel
      key="bottom-panel"
      classes={{
        panel: 'add-data-layer-panel__bottom-panel',
      }}
      checked={checkedLayers}
      onConfirmClick={handleLayersPanelConfirmClick}
      onCancelClick={onCancel}
    />,
  ];

  const { dataLayersTreeComponent } = useDataLayersTree({
    assets: {
      ...cleanedFieldAssets,
      vectorAnalysisMaps,
    },
    mode: compareLayersDataLayersTreeMode,
    children,
    checked: checkedLayers,
    collapsible: true,
    publishedDatasetsClickable: true,
    satelliteImagesLoading: satellitesLoading && !loading,
    selectedAssetUuid: selectedItemUuid,
    selectedAssetGroupType,
    hasGroupPlaceholderAction: false,
    withAttributes: true,
    withRawProcessed: true,
    withGeoMapAttributes: true,
    onAttributeNodeClick: handleAttributeNodeClick,
    onCheckedChange: setCheckedLayers,
    onAssetNodeClick: handleAssetItemClick,
  });

  return (
    <div className="add-data-layer-panel">
      {dataLayersTreeComponent}
    </div>
  );
};

export default AddDataLayersPanel;

import { getPatternsMatch } from '../../../../../helpers/functions/utils/fuzzySearch';
import type { FullAttribute } from '../../../../../helpers/types/dataset';
import {
  CLEAN_YIELD_ATTRIBUTES,
  MACHINE_ID_PATTERNS,
  YIELD_PATTERNS,
} from '../constants/patterns';

export const findYieldAttributes = (fullAttributes: FullAttribute[]): FullAttribute[] => {
  const matches = getPatternsMatch(
    fullAttributes,
    YIELD_PATTERNS,
    {
      keys: [
        'fullName',
        'transliteratedName',
      ],
    },
  );

  return [...matches].map((match) => match.item);
};

export const findYieldCleanAttribute = (fullAttributes: FullAttribute[]): FullAttribute | undefined => {
  const results = getPatternsMatch(
    fullAttributes,
    CLEAN_YIELD_ATTRIBUTES,
    {
      keys: [
        'fullName',
        'transliteratedName',
      ],
    },
  );

  return [...results][0]?.item;
};

export const findMachineIdAttribute = (fullAttributes: FullAttribute[]): FullAttribute | undefined => {
  const results = getPatternsMatch(
    fullAttributes,
    MACHINE_ID_PATTERNS,
    {
      keys: [
        'fullName',
        'transliteratedName',
      ],
    },
  );

  return [...results][0]?.item;
};

export const getAttributeString = (fullAttribute: FullAttribute): string => {
  return fullAttribute.transliteratedName;
};

import type { GetFieldAssetsResponse, TransformedFieldAssetsResponse } from '../../types/api';
import { transformFields } from './assets';
import { isInUnsupportedRegion } from './field';

export const transformFieldAssetsResponse = (
  response: GetFieldAssetsResponse,
): TransformedFieldAssetsResponse => {
  let result: TransformedFieldAssetsResponse;

  const farm = response.data?.getFarms[0];

  if (farm?.fields.length) {
    const field = transformFields(farm.fields)[0];

    result = {
      field,
      noMonitoring: isInUnsupportedRegion(field),
    };
  } else {
    result = null;
  }

  return result;
};

export const isTokenExpiredSoonError = (error: any): boolean => {
  const regexp = /token is invalid/ig;
  const message = error?.errors[0].message;

  return regexp.test(message);
};

import { API, graphqlOperation } from '@aws-amplify/api';

import { isValidUrl } from '../../../helpers/functions/utils/url';
import exportDataMutation from '../../exportData/graphql/mutations/exportData.gql';
import exportToJohnDeereAsFilesMutation from './graphql/mutations/exportToJohnDeereAsFiles.gql';
import exportToJohnDeereAsMapsLayersMutation from './graphql/mutations/exportToJohnDeereAsMapsLayers.gql';
import exportGeoPardFieldsToJohnDeereAsFilesMutation from './graphql/mutations/exportGeoPardFieldsToJohnDeereAsFiles.gql';
import exportToJohnDeereAsAdmMutation from './graphql/mutations/exportAdm.gql';
import {
  ResourceToExportAsFile,
  ResourceToExport,
  BoundaryToExportAsFile,
  BoundaryToExport,
} from './types/api';
import { getArchiveName } from './helpers/functions/archive';
import { AssetType } from '../../../helpers/constants/entities/asset';

export const getResourceArchiveUrl = async <T extends {
  uuid: string;
  fieldUuid: string;
  farmUuid?: string;
  resourceType: AssetType.vectorAnalysisMap | AssetType.equationMap | AssetType.field;
}>({
  client = '',
  farm = '',
  field = '',
  ratesOnly,
  resource,
}: {
  client?: string;
  farm?: string;
  field? : string;
  ratesOnly : boolean;
  resource: T;
}) => {
  const { uuid, fieldUuid } = resource;
  const {
    data: {
      exportData: {
        archiveUrl = '',
      },
    },
  } = await API.graphql(graphqlOperation(exportDataMutation, {
    input: {
      ...(resource.resourceType === AssetType.vectorAnalysisMap
        ? { vectorAnalysisMaps: [{ uuid, fieldUuid }], onlyProductColumns: ratesOnly }
        : {}),
      ...(resource.resourceType === AssetType.equationMap
        ? { equationMaps: [{ uuid, fieldUuid }], asMultiGeometry: true, onlyProductColumns: ratesOnly }
        : {}),
      ...(resource.resourceType === AssetType.field
        ? {
          fieldBoundariesToJohnDeere: [
            {
              farmUuid: resource.farmUuid,
              fieldUuid,
              clientNameInJohnDeere: client,
              farmNameInJohnDeere: farm,
              fieldNameInJohnDeere: field,
            },
          ],
        }
        : {}),
    },
  }));

  return archiveUrl;
};

export const executeExportToJohnDeereAsFiles = async ({
  orgId = '',
  client = '',
  farm = '',
  field = '',
  vectorAnalysisMaps = [],
  equationMaps = [],
  boundaries = [],
  ratesOnly = false,
}: {
  orgId: string;
  client: string;
  farm: string;
  field : string;
  vectorAnalysisMaps: ResourceToExport[];
  equationMaps: ResourceToExport[];
  boundaries: BoundaryToExport[];
  ratesOnly: boolean;
}) => {
  const exportResources = [
    ...vectorAnalysisMaps.map((vectorAnalysisMap) => ({
      ...vectorAnalysisMap,
      resourceType: AssetType.vectorAnalysisMap,
    }) as ResourceToExportAsFile),
    ...equationMaps.map((equationMap) => ({
      ...equationMap,
      resourceType: AssetType.equationMap,
    }) as ResourceToExportAsFile),
    ...boundaries.map((boundary) => ({ ...boundary, resourceType: AssetType.field }) as BoundaryToExportAsFile),
  ];

  const executeExportResource = async (resource: ResourceToExportAsFile | BoundaryToExportAsFile) => {
    const { name } = resource;
    let success = false;

    const archiveUrl = await getResourceArchiveUrl({
      resource,
      client,
      farm,
      field,
      ratesOnly,
    });

    if (isValidUrl(archiveUrl)) {
      const archiveName = getArchiveName(name);

      const response = await API.graphql(graphqlOperation(exportToJohnDeereAsFilesMutation, {
        input: {
          orgId,
          archiveName,
          archiveUrl,
        },
      }));

      if (response.data.exportToJohnDeereAsFiles) {
        const {
          data: {
            exportToJohnDeereAsFiles: {
              status = '',
            },
          },
        } = response;

        if (status === '204') {
          success = true;
        }
      }
    }

    return success;
  };

  const resultExportResources = await Promise.all(exportResources.map((resource) => executeExportResource(resource)));
  return resultExportResources.every((resultExportResource) => (resultExportResource));
};

export const executeExportToJohnDeereAsMapsLayers = async ({
  fieldUuid = '',
  vectorAnalysisMapUuid = '',
  topographyMapUuid = '',
  equationMapUuid = '',
  soilDatasetUuid = '',
  yieldDatasetUuid = '',
  satelliteImageUuid = '',
}: {
  fieldUuid: string;
  vectorAnalysisMapUuid: string;
  topographyMapUuid: string;
  equationMapUuid: string;
  soilDatasetUuid: string;
  yieldDatasetUuid: string;
  satelliteImageUuid: string;
}) => {
  let success = false;

  const response = await API.graphql(graphqlOperation(exportToJohnDeereAsMapsLayersMutation, {
    input: {
      fieldUuid,
      vectorAnalysisMapUuid,
      topographyMapUuid,
      equationMapUuid,
      soilDatasetUuid,
      yieldDatasetUuid,
      satelliteImageUuid,
    },
  }));

  if (response.data.exportToJohnDeereAsMapsLayers) {
    const {
      data: {
        exportToJohnDeereAsMapsLayers: {
          status = '',
          details = [],
        },
      },
    } = response;

    if (status === '201' && details.length > 0) {
      success = true;
    }
  }

  return success;
};

export const executeExportGeoPardFieldsToJohnDeereAsFiles = async (fieldUuids: string[]) => {
  const response = await API.graphql(graphqlOperation(exportGeoPardFieldsToJohnDeereAsFilesMutation, {
    input: { fieldUuids },
  }));

  return response.data?.exportGeoPardFieldsToJohnDeereAsFiles?.status === '201';
};

export const getAdmResourceArchiveUrl = async ({
  client = '',
  farm = '',
  field = '',
  resource,
}: {
  client: string;
  farm: string;
  field: string;
  resource: ResourceToExport;
}) => {
  const {
    data: {
      exportAdm: {
        archiveUrl = '',
      },
    },
  } = await API.graphql(graphqlOperation(exportToJohnDeereAsAdmMutation, {
    input: {
      uuid: resource.uuid,
      fieldUuid: resource.fieldUuid,
      clientNameInJohnDeere: client,
      farmNameInJohnDeere: farm,
      fieldNameInJohnDeere: field,
    },
  }));

  return archiveUrl;
};

export const executeExportToJohnDeereAsAdmFiles = async ({
  orgId = '',
  client = '',
  farm = '',
  field = '',
  exportResources = [],
}: {
  orgId: string;
  client: string;
  farm: string;
  field : string;
  exportResources: ResourceToExport[];
}) => {
  const executeExportResource = async (resource: ResourceToExport) => {
    let success = false;

    const archiveUrl = await getAdmResourceArchiveUrl({
      resource,
      client,
      farm,
      field,
    });

    if (isValidUrl(archiveUrl)) {
      const archiveName = getArchiveName(resource.name);

      const response = await API.graphql(graphqlOperation(exportToJohnDeereAsFilesMutation, {
        input: {
          orgId,
          archiveName,
          archiveUrl,
        },
      }));

      if (response.data.exportToJohnDeereAsFiles) {
        const {
          data: {
            exportToJohnDeereAsFiles: {
              status = '',
            },
          },
        } = response;

        if (status === '204') {
          success = true;
        }
      }
    }

    return success;
  };

  const resultExportResources = await Promise.all(exportResources.map((resource) => executeExportResource(resource)));
  return resultExportResources.every((resultExportResource) => (resultExportResource));
};

import React from 'react';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import PopupHeader from '../../../../../components/Popups/PopupHeader';
import Button from '../../../../../components/Button';

import './index.scss';

const DeleteColorSchemaPopup = ({
  t,
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  return (
    <Dialog
      open
      classes={{
        paper: 'delete-color-schema-popup',
      }}
    >
      <PopupHeader
        title={t('general.popups.delete-color-schema.title')}
        onCancel={onCancel}
      />
      <DialogContent>
        <DialogContentText
          classes={{
            root: 'delete-color-schema-popup__content-text',
          }}
        >
          {t('general.popups.delete-color-schema.description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        classes={{
          root: 'delete-color-schema-popup__actions',
        }}
      >
        <Button
          variant="outlined"
          onClick={onCancel}
        >
          {t('general.controls.cancel')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={onConfirm}
        >
          {t('general.controls.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withTranslation()(DeleteColorSchemaPopup);

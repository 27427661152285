import React from 'react';

import Checkbox from '../../Checkbox';
import TreeText from '../TreeText';
import { isEnterKeyPressedOnCurrentTarget } from '../../../helpers/functions/utils/navigation';

const ariaCheckedMap = {
  0: false,
  1: 'mixed',
  2: true,
};

const TreeCheckbox = ({
  item,
  onClick,
  onSelect,
}) => {
  return (
    <div
      role="checkbox"
      tabIndex={0}
      aria-checked={ariaCheckedMap[item.value]}
      className="tree-checkbox"
      onClick={() => onClick(item)}
      onKeyDown={(event) => {
        if (isEnterKeyPressedOnCurrentTarget(event)) {
          onClick(item);
        }
      }}
    >
      <Checkbox
        value={item.value}
        onChange={(_e, value) => onSelect(item, value)}
      />
      <TreeText
        item={item}
      />
    </div>
  );
};

export default TreeCheckbox;

import React from 'react';

import { getEquationMapTableData } from '../../../../helpers/functions/entities/equationMap';
import GeoMapsTable from '../GeoMapsTable';

const EquationMapTable = ({
  pixels,
}) => {
  if (!pixels) {
    return null;
  }

  const {
    headers,
    rows,
  } = getEquationMapTableData({
    geoMap: pixels,
  });

  return (
    <GeoMapsTable
      headers={headers}
      rows={rows}
    />
  );
};

export default EquationMapTable;

import React from 'react';
import {
  createSlice,
  nanoid,
  PayloadAction,
} from '@reduxjs/toolkit';
import i18n from 'i18next';
import { Trans } from 'react-i18next';

import {
  errorNotify,
  successNotify,
} from '../../notifications/helpers/functions/notify';
import { openPopup } from '../popups/popupsSlice';
import { POPUPS } from '../popups/helpers/constants/popups';
import { getJohnDeereWorkPlanLink } from './helpers/functions/products';
import { AppDispatch } from '../../../app/store/helpers/types';
import {
  executeExportToJohnDeereAsApplicationWorkPlan,
  executeExportToJohnDeereAsSeedingWorkPlan,
} from './jdWorkPlanAPI';
import { createAppAsyncThunk } from '../../../app/store/helpers/functions';
import {
  ApplicationSettings,
  AssetToExport,
  SeedingWorkPlanSettings,
  WorkPlanSettings,
} from './types/workPlan';
import {
  JD_WORK_PLAN_PROCESSING_DELAY,
  WORK_PLAN_EXPORT_ERROR_MESSAGES_I118KEY,
  WorkPlanType,
} from './helpers/constants/workPlan';
import { JDProductType } from '../../jdProducts/helpers/constants/product';
import { ExportWorkPlanData } from './types/api';
import { getDefaultWorkPlanType } from './helpers/functions/workPlanType';
import { getWorkPlanUint } from './helpers/functions/unit';
import { VARIETY_UNITS } from './helpers/constants/workPlanUnits';
import { AreaUnit } from '../../user/helpers/constants/user';

interface JDWorkPlansState {
  type: WorkPlanType;
  settings: WorkPlanSettings;
  isLoading: boolean;
}

const initialState: JDWorkPlansState = {
  type: WorkPlanType.application,
  settings: {
    [WorkPlanType.seeding]: {
      varietyId: '',
      applications: [],
      selectedAsset: null,
      productType: JDProductType.variety,
      unit: null,
    },
    [WorkPlanType.application]: {
      applications: [],
    },
  },
  isLoading: false,
};

export const openPopupExportToJohnDeereAsWorkPlan = ({
  areaUnit,
  selectedAsset,
}: {
  areaUnit: AreaUnit;
  selectedAsset?: AssetToExport;
}) => (dispatch: AppDispatch) => {
  dispatch(openPopup({
    type: POPUPS.exportToJohnDeereAsWorkPlan,
    selectedAsset,
  }));

  dispatch(initWorkPlan({ asset: selectedAsset ?? null, areaUnit }));
};

export const exportToJohnDeereAsSeedingWorkPlan = createAppAsyncThunk(
  'jdExport/exportToJohnDeereAsSeedingWorkPlan',
  async ({
    exportData = [],
    fieldUuid,
    orgId,
  }: {
    exportData: ExportWorkPlanData[];
    fieldUuid: string;
    orgId: string;
  }, {
    dispatch,
  }: {
    dispatch: AppDispatch,
  }) => {
    try {
      const workPlanIds = await executeExportToJohnDeereAsSeedingWorkPlan({
        exportData,
        fieldUuid,
      });

      await new Promise((resolve) => { // Additional timeout to show the link to the work plan only when all work plan data has been fully processed in John Deere account
        setTimeout(resolve, JD_WORK_PLAN_PROCESSING_DELAY);
      });

      successNotify({
        messageElement: (
          <Trans i18nKey="general.popups.export-work-plan.work-plan-exported">
            The data has been successfully exported to John Deere Ops Center.
          </Trans>
        ),
        actions: [
          {
            title: `${i18n.t('general.popups.export-work-plan.go-to-work-plan')}`,
            href: getJohnDeereWorkPlanLink({
              orgId,
              id: workPlanIds[0],
            }),
          },
        ],
      });
    } catch (error) {
      const statusCode = (error as unknown as { cause?: { status?: number } })?.cause?.status;
      const errorMessageI118Key = statusCode ? WORK_PLAN_EXPORT_ERROR_MESSAGES_I118KEY[statusCode] : null;

      errorNotify({
        error,
        message: errorMessageI118Key
          ? i18n.t(errorMessageI118Key)
          : i18n.t('export-data-john-deere.notifications.not-exported'),
        dispatch,
      });
    }
  },
);

export const exportToJohnDeereAsApplicationWorkPlan = createAppAsyncThunk(
  'jdExport/exportToJohnDeereAsApplicationWorkPlan',
  async ({
    exportData = [],
    fieldUuid,
    orgId,
  }: {
    exportData: ExportWorkPlanData[];
    fieldUuid: string;
    orgId: string;
  }, {
    dispatch,
  }: {
    dispatch: AppDispatch,
  }) => {
    try {
      const workPlanIds = await executeExportToJohnDeereAsApplicationWorkPlan({
        exportData,
        fieldUuid,
      });

      await new Promise((resolve) => { // Additional timeout to show the link to the work plan only when all work plan data has been fully processed in John Deere account
        setTimeout(resolve, JD_WORK_PLAN_PROCESSING_DELAY);
      });

      successNotify({
        messageElement: (
          <Trans i18nKey="general.popups.export-work-plan.work-plan-exported">
            The data has been successfully exported to John Deere Ops Center.
          </Trans>
        ),
        actions: [
          {
            title: `${i18n.t('general.popups.export-work-plan.go-to-work-plan')}`,
            href: getJohnDeereWorkPlanLink({
              orgId,
              id: workPlanIds[0],
            }),
          },
        ],
      });
    } catch (error) {
      const statusCode = (error as unknown as { cause?: { status?: number } })?.cause?.status;
      const errorMessageI118Key = statusCode ? WORK_PLAN_EXPORT_ERROR_MESSAGES_I118KEY[statusCode] : null;

      errorNotify({
        error,
        message: errorMessageI118Key
          ? i18n.t(errorMessageI118Key)
          : i18n.t('export-data-john-deere.notifications.not-exported'),
        dispatch,
      });
    }
  },
);

export const jdWorkPlansSlice = createSlice({
  name: 'jdExport',
  initialState,
  reducers: {
    initWorkPlan(state, action: PayloadAction<{
      asset: AssetToExport | null;
      areaUnit: AreaUnit;
    }>) {
      state.type = getDefaultWorkPlanType(action.payload.asset);
      state.settings = {
        [WorkPlanType.seeding]: {
          varietyId: '',
          applications: [],
          selectedAsset: action.payload.asset,
          productType: JDProductType.variety,
          unit: getWorkPlanUint(VARIETY_UNITS[action.payload.areaUnit], action.payload?.asset?.unit),
        },
        [WorkPlanType.application]: {
          applications: [{
            selectedAsset: action.payload.asset,
            productType: null,
            productId: null,
            unit: null,
            id: nanoid(),
          }],
        },
      };
    },
    setWorkPlanType(state, action: PayloadAction<WorkPlanType>) {
      state.type = action.payload;
    },
    setSeedingWorkPlanSettings(state, { payload }: PayloadAction<Partial<SeedingWorkPlanSettings>>) {
      state.settings.seeding = {
        ...state.settings.seeding,
        ...payload,
      };
    },
    setApplicationSettings(state, { payload }: PayloadAction<{
      id: string;
      workPlanType: WorkPlanType;
      application: Partial<ApplicationSettings>;
    }>) {
      const { applications } = state.settings[payload.workPlanType];
      state.settings[payload.workPlanType].applications = applications.map((item) => {
        if (item.id !== payload.id) {
          return item;
        }

        return {
          ...item,
          ...payload.application,
        };
      });
    },
    addApplication(state, { payload }: PayloadAction<{
      workPlanType: WorkPlanType;
      application: Pick<ApplicationSettings, 'selectedAsset' | 'productType' | 'productId' | 'unit'>
    }>) {
      state.settings[payload.workPlanType].applications.push({
        ...payload.application,
        id: nanoid(),
      });
    },
    removeApplication(state, { payload }: PayloadAction<{
      id: string;
      workPlanType: WorkPlanType;
    }>) {
      const { applications } = state.settings[payload.workPlanType];
      state.settings[payload.workPlanType].applications = applications.filter((item) => {
        return item.id !== payload.id;
      });
    },
    resetWorkPlan(state) {
      return {
        ...initialState,
        isLoading: state.isLoading,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(exportToJohnDeereAsSeedingWorkPlan.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(exportToJohnDeereAsSeedingWorkPlan.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(exportToJohnDeereAsApplicationWorkPlan.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(exportToJohnDeereAsApplicationWorkPlan.fulfilled, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  initWorkPlan,
  setWorkPlanType,
  setSeedingWorkPlanSettings,
  setApplicationSettings,
  addApplication,
  removeApplication,
  resetWorkPlan,
} = jdWorkPlansSlice.actions;

export default jdWorkPlansSlice.reducer;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import RadioGroup from '../../../../../components/RadioGroup';
import { CalibrationType } from '../../helpers/constants/ui';
import ExpandableText from '../ExpandableText';

import './index.scss';

const TYPE_TO_DESCRIPTIONS_MAP = {
  [CalibrationType.pathwise]: {
    short: 'clean-calibrate.yield-popup.tabs.calibrate.configure.calibration-type.pathwise.short-description',
    additional: 'clean-calibrate.yield-popup.tabs.calibrate.configure.calibration-type.pathwise.additional-description',
  },
  [CalibrationType.averageTotal]: {
    short: 'clean-calibrate.yield-popup.tabs.calibrate.configure.calibration-type.avg-total.short-description',
    additional: 'clean-calibrate.yield-popup.tabs.calibrate.configure.calibration-type.avg-total.additional-description',
  },
  [CalibrationType.conditional]: {
    short: 'clean-calibrate.yield-popup.tabs.calibrate.configure.calibration-type.conditional.short-description',
    additional: 'clean-calibrate.yield-popup.tabs.calibrate.configure.calibration-type.conditional.additional-description',
  },
};

export default function CalibrationTypeSelector({
  value,
  onTypeChange,
}: {
  value: CalibrationType,
  onTypeChange: (v: CalibrationType) => void,
}) {
  const { t } = useTranslation();

  return (
    <div className="calibration-type-selector">
      <Typography className="calibration-type-selector__title">
        {t('clean-calibrate.yield-popup.tabs.calibrate.configure.calibration-type.title')}
      </Typography>
      <RadioGroup
        classes={{
          root: 'calibration-type-selector__radio-group',
        }}
        horizontal
        options={[
          {
            title: t('clean-calibrate.yield-popup.tabs.calibrate.configure.calibration-type.pathwise.title'),
            value: CalibrationType.pathwise,
          },
          {
            title: t('clean-calibrate.yield-popup.tabs.calibrate.configure.calibration-type.avg-total.title'),
            value: CalibrationType.averageTotal,
          },
          {
            title: t('clean-calibrate.yield-popup.tabs.calibrate.configure.calibration-type.conditional.title'),
            value: CalibrationType.conditional,
          },
        ]}
        value={value}
        onChange={onTypeChange}
      />
      <ExpandableText
        shortTextI18nKey={TYPE_TO_DESCRIPTIONS_MAP[value].short}
        additionalTextI18nKey={TYPE_TO_DESCRIPTIONS_MAP[value].additional}
      />
    </div>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import { selectProcessingPanelFiles } from '../../../uploadDataSelectors';
import { FileStatus } from '../../../helpers/constants/upload';

import './index.scss';

export default function UploadFilesPopupStatusList() {
  const { t } = useTranslation();
  const files = useSelector(selectProcessingPanelFiles);

  const succeededCount = files.filter(({ status }) => status === FileStatus.verified).length;
  const failedCount = files.filter(({ status }) => status === FileStatus.failed).length;
  const inProgressCount = files.filter(({ status }) => {
    return status === FileStatus.uploading || status === FileStatus.complete;
  }).length;

  return (
    <div className='upload-files-popup-status-list'>
      <Typography
        variant='body2'
        className="upload-files-popup-status-list__item"
      >
        {t('operations.statuses.total')}:{' '}
        <span className="upload-files-popup-status-list__item-amount upload-files-popup-status-list__item_total">
          {files.length}
        </span>
      </Typography>
      <Typography
        variant='body2'
        className="upload-files-popup-status-list__item"
      >
        {t('operations.statuses.in-progress')}: {inProgressCount}
      </Typography>
      <Typography
        variant='body2'
        className="upload-files-popup-status-list__item upload-files-popup-status-list__item_success"
      >
        {t('operations.statuses.success')}:{' '}
        <span className="upload-files-popup-status-list__item-amount">
          {succeededCount}
        </span>
      </Typography>
      <Typography
        variant='body2'
        className="upload-files-popup-status-list__item upload-files-popup-status-list__item_error"
      >
        {t('operations.statuses.error')}:{' '}
        <span className="upload-files-popup-status-list__item-amount">
          {failedCount}
        </span>
      </Typography>
    </div>
  );
}

import { API, graphqlOperation } from '@aws-amplify/api';

import { BATCH_SIZE } from '../../helpers/constants/utils/batchOperations';
import { readFileAsArrayBuffer } from '../../helpers/functions/utils/uploadFiles';
import { transformPinToInput } from './helpers/functions/backend';
import addPinCommentMutation from './graphql/mutations/addPinComment.gql';
import deletePhotosMutation from './graphql/mutations/deletePhotos.gql';
import generatePresignedPostPhotoData from './graphql/mutations/generatePresignedPostPhotoData.gql';
import savePinMutation from './graphql/mutations/savePin.gql';
import deletePinMutation from './graphql/mutations/deletePin.gql';
import savePinsMutation from './graphql/mutations/savePins.gql';
import { batchOperations } from '../../helpers/functions/utils/batchOperations';
import { escapeMultilineText } from '../../helpers/functions/utils/string';

export const saveComment = async (pinUuid, fieldUuid, text) => {
  return API.graphql(graphqlOperation(addPinCommentMutation, {
    input: {
      fieldUuid,
      noteUuid: pinUuid,
      text: escapeMultilineText(text),
    },
  }))
    .then(({ data }) => {
      return data?.addNoteComment;
    });
};

export const deletePhotos = async (photos) => {
  return API.graphql(graphqlOperation(deletePhotosMutation, {
    input: {
      photos: photos.map(({ uuid, noteUuid, commentUuid }) => ({
        uuid,
        noteUuid,
        commentUuid,
      })),
    },
  }))
    .then(({ data }) => {
      return (data.deletePhotos || []).map((photo) => photo.uuid);
    });
};

const uploadPhoto = async (file) => {
  const {
    uuid,
    url,
    formData,
  } = await API.graphql(graphqlOperation(generatePresignedPostPhotoData, {
    input: {
      farmUuid: file.farmUuid,
      fieldUuid: file.fieldUuid,
      noteUuid: file.noteUuid,
      commentUuid: file.commentUuid,
      fileExtention: file.type,
    },
  }))
    .then(({ data }) => {
      const {
        s3Fields,
        s3Url,
        uuid: fileUuid,
      } = data.generatePresignedPostPhotoData;
      const fields = JSON.parse(s3Fields);
      const uploadFormData = new FormData();

      Object.keys(fields).forEach((key) => {
        uploadFormData.append(key, fields[key]);
      });
      uploadFormData.append('file', new Blob([file.arrayBuffer]));

      return {
        url: s3Url,
        uuid: fileUuid,
        formData: uploadFormData,
      };
    });

  await fetch(
    url,
    {
      method: 'POST',
      body: formData,
    },
  );

  return uuid;
};

const processFileUpload = async (file) => {
  try {
    const uuid = await uploadPhoto(file);
    return {
      ...file,
      uuid,
    };
  } catch (error) {
    console.error(`Unable to upload asset ${file.name}:`, error);
  }
};

const processPhotos = (files) => {
  const PHOTO_EXTENSIONS_MAP = {
    'image/jpeg': 'jpeg',
    'image/jpg': 'jpg',
    'image/png': 'png',
  };

  return Promise.all(files.map(({ file }) => {
    return readFileAsArrayBuffer(file)
      .then((arrayBuffer) => {
        return {
          arrayBuffer,
          name: file.name,
          type: PHOTO_EXTENSIONS_MAP[file.type],
          tempId: Math.random(),
        };
      });
  }));
};

export const uploadPhotos = async (fieldUuid, pinUuid, photos, farmUuid, commentUuid) => {
  try {
    const filesToUpload = await processPhotos(photos);
    const filesToAdd = filesToUpload
      .map(({
        name,
        type,
        tempId,
        arrayBuffer,
      }) => ({
        name,
        type,
        uuid: tempId,
        fieldUuid,
        noteUuid: pinUuid,
        farmUuid,
        commentUuid,
        arrayBuffer,
      }));

    return await batchOperations(processFileUpload, filesToAdd, BATCH_SIZE);
  } catch (e) {
    console.error('Unable to upload photos', e);
  }
};

export const savePin = async (pin) => {
  const pinInput = transformPinToInput(pin);

  return API.graphql(graphqlOperation(savePinMutation, {
    input: pinInput,
  }))
    .then(({ data }) => {
      return data.saveNote;
    });
};

export const deletePin = async (uuid, fieldUuid) => {
  return API.graphql(graphqlOperation(deletePinMutation, {
    input: {
      uuid,
      fieldUuid,
    },
  }))
    .then(({ data }) => {
      return data.deleteNote;
    });
};

export const savePins = async (pins) => {
  const pinsInput = pins.map((pin) => transformPinToInput(pin));

  return API.graphql(graphqlOperation(savePinsMutation, {
    input: {
      notes: pinsInput,
    },
  }))
    .then(({ data }) => {
      return data.saveNotes;
    });
};

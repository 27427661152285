export enum FieldOperation {
  manageFieldLabels = 'manageFieldLabels',
  editFieldBoundary = 'editFieldBoundary',
  downloadBoundary = 'downloadBoundary',
  exportBoundaryToJDAsFiles = 'exportBoundaryToJDAsFiles',
  renameField = 'renameField',
  deleteField = 'deleteField',
  exportWorkPlan = 'exportWorkPlan',
  exportPdf = 'exportPdf',
}

import { createApi } from '@reduxjs/toolkit/query/react';

import { graphqlBaseQuery } from './helpers/functions/api';

export enum TagType {
  farm = 'Farm',
  field = 'Field',
  johnDeereVariety = 'JohnDeereVariety',
  johnDeereFertilizer = 'JohnDeereFertilizer',
  johnDeereChemical = 'JohnDeereChemical',
  johnDeereTankMix = 'JohnDeereTankMix',
  johnDeereDryBlend = 'JohnDeereDryBlend',
}

export const LIST_ID = 'LIST';

export const emptyAPI = createApi({
  reducerPath: 'api',
  baseQuery: graphqlBaseQuery(),
  tagTypes: [
    TagType.farm,
    TagType.field,
    TagType.johnDeereVariety,
    TagType.johnDeereChemical,
    TagType.johnDeereFertilizer,
    TagType.johnDeereTankMix,
    TagType.johnDeereDryBlend,
  ],
  endpoints: () => ({}),
});

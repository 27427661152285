import React, { ReactElement } from 'react';

import Tooltip from '../../../../../../components/Tooltip';
import { GeoMap } from '../../../../../../helpers/types/api';
import {
  getDatasetAttributeNameHandler,
  getDatasetLegendColors,
  getFullAttribute,
  getUnitLabel,
} from '../../../../../../helpers/functions/entities/dataset';
import ColorsLegend from '../../../../../../components/Map/Controls/MiniLegend/ColorsLegend';
import { TransformedDataset } from '../../../../../../helpers/types/dataset';

import './index.scss';

export default function DatasetLegendTooltip({
  dataset,
  attribute,
  geoMap,
  children,
}: {
  dataset: TransformedDataset,
  attribute: string,
  geoMap: GeoMap,
  children: ReactElement,
}) {
  const items = getDatasetLegendColors(geoMap);
  const nameHandler = getDatasetAttributeNameHandler(dataset.fullAttributes);
  const fullAttribute = getFullAttribute(attribute, dataset.fullAttributes);

  return (
    <Tooltip
      classes={{
        label: 'dataset-preview-legend-tooltip__label',
        tooltip: 'dataset-preview-legend-tooltip__tooltip',
      }}
      placement="bottom"
      tooltip={(
        <div className="dataset-preview-legend-tooltip">
          <ColorsLegend
            direction="horizontal"
            items={items}
            name={nameHandler(attribute)}
            unit={getUnitLabel(fullAttribute?.unit)}
          />
        </div>
      )}
    >
      {children}
    </Tooltip>
  );
}

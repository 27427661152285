import React, { Suspense } from 'react';

import Stepper, { Step } from '../../../../../../../components/Stepper';
import Loading from '../../../../../../../components/Loading';
import Panel from '../../../Panel/ShapeDataSource';
import { selectShape } from '../../../../createThreeDMapSelectors';
import { useAppDispatch, useAppSelector } from '../../../../../../../app/store/helpers/functions';
import { goToNextStep, goToPrevStep } from '../../../../../zonesOps/zonesOpsSlice';

const Map = React.lazy(() => import('../../../Map'));

const ShapeDataSource = ({
  steps,
  stepIndex,
  step,
}: {
  steps: Step[],
  stepIndex: number,
  step: string,
}) => {
  const dispatch = useAppDispatch();

  const { uuid, attribute } = useAppSelector(selectShape);

  const handleClickNext = () => {
    dispatch(goToNextStep());
  };

  const handleClickBack = () => {
    dispatch(goToPrevStep());
  };

  return (
    <Stepper
      activeStep={stepIndex}
      steps={steps}
      nextDisabled={!uuid || !attribute}
      onClickNext={handleClickNext}
      onClickBack={handleClickBack}
    >
      <Panel key="panel" />
      <Suspense key="map" fallback={<Loading />}>
        <Map step={step} />
      </Suspense>
    </Stepper>
  );
};

export default ShapeDataSource;

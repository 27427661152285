import { CurrentEquation } from '../../types/equation';
import { EMPTY_EQUATION } from '../constants/equations';

export const isNewEquation = (uuid: string | null) => {
  return uuid === EMPTY_EQUATION.uuid;
};

export const filterEmptyVariables = (dataVariables: string[] = []) => {
  return dataVariables.filter((variable) => variable.length !== 0);
};

export const isEquationChanged = (
  currentEquation: CurrentEquation,
  updatedEquation: CurrentEquation,
) => {
  return JSON.stringify(currentEquation.dataVariables) !== JSON.stringify(updatedEquation.dataVariables)
    || currentEquation.equationResultVariable !== updatedEquation.equationResultVariable
    || currentEquation.equationAsText !== updatedEquation.equationAsText
    || currentEquation.productUnit !== updatedEquation.productUnit
    || currentEquation.useNumpy !== updatedEquation.useNumpy;
};

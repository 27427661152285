import { JDProductType } from '../helpers/constants/product';
import {
  useGetJohnDeereChemicalsQuery,
  useGetJohnDeereDryBlendsQuery,
  useGetJohnDeereFertilizersQuery,
  useGetJohnDeereTankMixesQuery,
  useGetJohnDeereVarietiesQuery,
} from '../jdExportAPI';
import { JohnDeereProduct } from '../types/products';

export const useJohnDeereProducts = ({
  orgId,
  productType,
}: {
  orgId: string;
  productType?: JDProductType | null;
}): {
  products: JohnDeereProduct[];
  isSuccess: boolean;
  isLoading: boolean;
} => {
  const {
    isFetching: isVarietiesLoading,
    isSuccess: isVarietiesSuccess,
    data: varieties = [],
  } = useGetJohnDeereVarietiesQuery({ orgId }, { skip: !orgId });
  const {
    isFetching: isFertilizersLoading,
    isSuccess: isFertilizersSuccess,
    data: fertilizers = [],
  } = useGetJohnDeereFertilizersQuery({ orgId }, { skip: !orgId });
  const {
    isFetching: isChemicalsLoading,
    isSuccess: isChemicalsSuccess,
    data: chemicals = [],
  } = useGetJohnDeereChemicalsQuery({ orgId }, { skip: !orgId });
  const {
    isFetching: isTankMixesLoading,
    isSuccess: isTankMixesSuccess,
    data: tankMixes = [],
  } = useGetJohnDeereTankMixesQuery({ orgId }, { skip: !orgId });
  const {
    isFetching: isDryBlendsLoading,
    isSuccess: isDryBlendsSuccess,
    data: dryBlends = [],
  } = useGetJohnDeereDryBlendsQuery({ orgId }, { skip: !orgId });

  if (productType === JDProductType.variety) {
    return {
      products: varieties,
      isSuccess: isVarietiesSuccess,
      isLoading: isVarietiesLoading,
    };
  }

  if (productType === JDProductType.fertilizer) {
    return {
      products: fertilizers,
      isSuccess: isFertilizersSuccess,
      isLoading: isFertilizersLoading,
    };
  }

  if (productType === JDProductType.chemical) {
    return {
      products: chemicals,
      isSuccess: isChemicalsSuccess,
      isLoading: isChemicalsLoading,
    };
  }

  if (productType === JDProductType.tankMix) {
    return {
      products: tankMixes,
      isSuccess: isTankMixesSuccess,
      isLoading: isTankMixesLoading,
    };
  }

  if (productType === JDProductType.dryBlend) {
    return {
      products: dryBlends,
      isSuccess: isDryBlendsSuccess,
      isLoading: isDryBlendsLoading,
    };
  }

  return {
    products: [],
    isSuccess: false,
    isLoading: false,
  };
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import Label from '../../../../../../../components/Label';
import type { DatasetAction } from '../../../../../cleanCalibrate/types/actions';

import './index.scss';

const DATASET_OPERATION_TO_I18N_KEY_MAP: Record<DatasetAction, string> = {
  CLEAN: 'field.assets.dataset-items.cleaned',
  CALIBRATE: 'field.assets.dataset-items.calibrated',
};

export default function DatasetItemCorrections({
  corrections,
  version,
}: {
  corrections: DatasetAction[],
  version: number,
}) {
  const { t } = useTranslation();

  return (
    <div className="dataset-item-corrections">
      {
        corrections.map((correction, index) => {
          return (
            <Label
              key={index}
              text={t(DATASET_OPERATION_TO_I18N_KEY_MAP[correction])}
            />
          );
        })
      }
      <Label
        text={t('field.assets.dataset-items.version', { version })}
      />
    </div>
  );
}

import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';

import DataType from '../../containers/Stepper/Steps/DataType';
import DataLayers from '../../containers/Stepper/Steps/DataLayers';
import Settings from '../../containers/Stepper/Steps/Settings';
import { getCommonSteps } from '../../../zonesOps/helpers/functions/steps';
import { PAGES_ROOTS, getRootLink } from '../../../../../helpers/navigation';
import Link from '../../../../../components/Link';
import { AssetType } from '../../../../../helpers/constants/entities/asset';
import Tooltip from '../../../../../components/Tooltip';
import TopographyIndexesTooltip from '../../components/Tooltips/TopographyIndexesTooltip';
import SatelliteIndexesTooltip from '../../../zonesOps/components/Tooltips/SatelliteIndexesTooltip';

export const getSteps = (assetType, withTypeSelection) => (withFieldSelection) => {
  let firstSteps = [];

  if (withFieldSelection) {
    firstSteps = getCommonSteps();
  }

  if (withTypeSelection) {
    firstSteps.push({
      name: i18n.t('zones-ops.single-layer.stepper.data-type'),
      description: (
        <Trans i18nKey="zones-ops.single-layer.steps.3.description">
          text <Link to={getRootLink(PAGES_ROOTS.upload)}>link</Link>
        </Trans>
      ),
      id: 'dataType',
      component: DataType,
    });
  }

  let dataLayersStepDescription;

  if (assetType === AssetType.topographyMap) {
    dataLayersStepDescription = (
      <Trans i18nKey="zones-ops.single-layer.steps.4.topography-description">
        text
        <Tooltip tooltip={<TopographyIndexesTooltip />}>tooltip</Tooltip>
        text
      </Trans>
    );
  } else if (assetType === AssetType.satelliteImage) {
    dataLayersStepDescription = (
      <Trans i18nKey="zones-ops.single-layer.steps.4.satellite-description">
        text
        <Tooltip tooltip={<SatelliteIndexesTooltip />}>text</Tooltip>
        text
      </Trans>
    );
  } else {
    dataLayersStepDescription = i18n.t('zones-ops.single-layer.steps.4.dataset-description');
  }

  return [
    ...firstSteps,
    {
      name: i18n.t('zones-ops.common.stepper.data-layers'),
      description: dataLayersStepDescription,
      id: 'dataLayers',
      component: DataLayers,
    },
    {
      name: i18n.t('general.shared.settings'),
      description: (
        <>
          {i18n.t('zones-ops.common.processing-description')}
          {' '}
          {i18n.t('zones-ops.common.charge-description')}
        </>
      ),
      id: 'settings',
      component: Settings,
    },
  ];
};

import i18n from 'i18next';

import { ZonesOpsWorkflow } from '../../../zonesOps/helpers/constants/workflows';
import ZonesMap from '../../containers/Stepper/Steps/ZonesMap';
import { getCommonSteps } from '../../../zonesOps/helpers/functions/steps';
import {
  getSteps as getCreateSingleLayerAnalysisSteps,
} from '../../../createSingleLayerAnalysis/helpers/functions/steps';
import {
  getSteps as getCreateFieldPotentialZonesSteps,
} from '../../../createFieldPotentialZones/helpers/functions/steps';
import {
  getSteps as getCreateStabilityZonesSteps,
} from '../../../createStabilityZones/helpers/functions/steps';
import {
  getSteps as getCrossLayerSteps,
} from '../../../crossLayer/helpers/functions/steps';
import {
  getSteps as getCreateMultiLayerAnalysisSteps,
} from '../../../createMultiLayerAnalysis/helpers/functions/steps';
import {
  getSteps as getDrawManuallySteps,
} from '../../../drawManuallyWorkflow/helpers/functions/steps';

export const getSteps = (workflow, singleLayerAssetType) => (withFieldSelection) => {
  let firstSteps = [];

  if (withFieldSelection) {
    firstSteps = getCommonSteps();
  }

  const baseSteps = [
    ...firstSteps,
    {
      name: i18n.t('general.shared.zones-map'),
      description: i18n.t('zones-ops.clone-zones-map.steps.3.description'),
      id: 'zonesMap',
      component: ZonesMap,
    },
  ];

  if (workflow === ZonesOpsWorkflow.createSingleLayerAnalysis) {
    return [
      ...baseSteps,
      ...getCreateSingleLayerAnalysisSteps(singleLayerAssetType)(),
    ];
  }

  if (workflow === ZonesOpsWorkflow.createFieldPotentialZones) {
    return [
      ...baseSteps,
      ...getCreateFieldPotentialZonesSteps(),
    ];
  }

  if (workflow === ZonesOpsWorkflow.createStabilityZones) {
    return [
      ...baseSteps,
      ...getCreateStabilityZonesSteps(),
    ];
  }

  if (workflow === ZonesOpsWorkflow.crossLayer) {
    return [
      ...baseSteps,
      ...getCrossLayerSteps(),
    ];
  }

  if (workflow === ZonesOpsWorkflow.createMultiLayerAnalysis) {
    return [
      ...baseSteps,
      ...getCreateMultiLayerAnalysisSteps(),
    ];
  }

  if (workflow === ZonesOpsWorkflow.drawManually) {
    return [
      ...baseSteps,
      ...getDrawManuallySteps(),
    ];
  }

  return baseSteps;
};

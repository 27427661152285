export enum ActivityLogPeriod {
  TODAY = 'today',
  LAST_7_DAYS = 'last-7-days',
  CURRENT_MONTH = 'current-month',
  THREE_MONTHS = '3-months',
  FOR_PERIOD = 'for-period',
}

export const PERIODS_TO_I18N_KEY_MAP = {
  [ActivityLogPeriod.TODAY]: 'activity-log.periods.today',
  [ActivityLogPeriod.LAST_7_DAYS]: 'activity-log.periods.last-7-days',
  [ActivityLogPeriod.CURRENT_MONTH]: 'activity-log.periods.current-month',
  [ActivityLogPeriod.THREE_MONTHS]: 'activity-log.periods.3-months',
  [ActivityLogPeriod.FOR_PERIOD]: 'activity-log.periods.for-period',
};

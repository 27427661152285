import React, { ReactNode, MouseEvent } from 'react';
import { Link, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import './index.scss';

export default function GroupParentItem({
  children,
  groupAmount,
  isExpanded,
  onMoreButtonClick,
}: {
  children: ReactNode,
  groupAmount?: number,
  isExpanded: boolean,
  onMoreButtonClick: () => void,
}) {
  const { t } = useTranslation();

  const handleMoreButtonClick = (event: MouseEvent) => {
    event.stopPropagation();
    onMoreButtonClick();
  };

  return (
    <div className="group-parent-item">
      {children}
      <Link
        className="group-parent-item__more-button"
        component="button"
        onClick={handleMoreButtonClick}
      >
        <Typography className="link">
          {
            isExpanded
              ? t('field.assets.hide-related', { amount: groupAmount })
              : t('field.assets.show-related', { amount: groupAmount })
          }
        </Typography>
      </Link>
    </div>
  );
}

import clsx from 'clsx';

export const getCellClasses = (className, column, row) => {
  return clsx(
    className,
    {
      [`${className}_align-right`]: column.horizontalAlign === 'right',
      [`${className}_align-top`]: column.verticalAlign !== 'middle',
      [`${className}_leading`]: column.leading,
      [`${className}_disabled`]: row.disabled,
      [`${className}_with-nested-row`]: row.hasNestedRow && !column.rowSpan,
      text_secondary: column.secondary,
    },
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { LegendItem } from '../types';

import './index.scss';

export default function ColorsLegend({
  name,
  unit,
  items,
  direction = 'vertical',
}: {
  name: string,
  unit?: string,
  items: LegendItem[],
  direction?: 'horizontal' | 'vertical',
}) {
  const { t } = useTranslation();
  const title = name || t('general.controls.map-legend.mini-legend.legend.title');

  return (
    <div
      className={clsx('colors-legend', {
        'colors-legend_vertical': direction === 'vertical',
        'colors-legend_horizontal': direction === 'horizontal',
      })}
    >
      <div
        className={clsx('colors-legend__header', {
          'colors-legend__header_vertical': direction === 'vertical',
        })}
      >
        <span className="colors-legend__header-title" title={title}>
          {title}
          {unit && <span className="colors-legend__header-unit" title={unit}> ({unit})</span>}
          {direction === 'horizontal' && ':'}
        </span>
      </div>
      {
        items.map((item, index) => {
          return (
            <div className="colors-legend__item" key={index}>
              <span
                className="colors-legend__item-color"
                style={{ backgroundColor: item.color }}
              />
              <span>{item.quantity}</span>
            </div>
          );
        })
      }
    </div>
  );
}

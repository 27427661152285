import React, { useEffect, useRef } from 'react';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';

import BackdropLoading from '../Loading/BackdropLoading';
import ScrollContainer from '../ScrollContainer';
import AmplitudeAnalytics from '../../helpers/classes/amplitudeAnalytics';
import useCurrentPage from '../../hooks/useCurrentPage';

import './index.scss';

const Legend = ({
  loading,
  title,
  type,
  className,
  content,
  actions,
  onClose = () => {},
}) => {
  const closeButtonRef = useRef();
  const page = useCurrentPage();

  useEffect(() => {
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (page && type) {
      AmplitudeAnalytics.trackLegendOpened({
        type,
        page,
      });
    }
  }, [page, type]);

  return (
    <Drawer
      open
      anchor="right"
      variant="persistent"
      classes={{
        ...(className ? { root: className } : {}),
        paper: 'legend',
      }}
    >
      <div className="legend__header">
        <Typography
          variant="h6"
          classes={{
            root: 'legend__header-text',
          }}
          title={title}
        >
          {title}
        </Typography>
        <IconButton
          className="legend__header-icon"
          onClick={onClose}
          ref={closeButtonRef}
        >
          <ClearIcon />
        </IconButton>
      </div>
      <div className="legend__wrapper">
        <ScrollContainer>
          {content}
        </ScrollContainer>
        <div className="legend__actions">
          {actions}
        </div>
        { loading && <BackdropLoading /> }
      </div>
    </Drawer>
  );
};

export default Legend;

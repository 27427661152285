import React, {
  useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import ComboBox from '../../../../../components/ComboBox';
import NDVISlider from '../NDVISlider';
import Select from '../../../../../components/Select';
import ResetFiltersButton from '../ResetFiltersButton';
import { SatelliteFilterValue, SatelliteFilters } from '../../types/satelliteImage';
import { getCloudFilterOptions } from '../../helpers/functions/satelliteImage';
import { SatelliteImage } from '../../../../satelliteImages/types/satelliteImage';

import './index.scss';

function mapToOptions<T>(value: T): SatelliteFilterValue<T> {
  return {
    title: String(value),
    value,
  };
}

const SatelliteFilter = ({
  images = [],
  value,
  classes = {},
  onChange,
  onFiltersClear,
}: {
  images?: SatelliteImage[];
  value: SatelliteFilters;
  isExpanded?: boolean;
  classes?: { root?: string };
  onChange: (v: SatelliteFilters) => void;
  onFiltersClear?: () => void;
}) => {
  const [cloudFilterOptions] = useState(getCloudFilterOptions());
  const [monthOptions, setMonthOptions] = useState<SatelliteFilterValue<number>[]>([]);
  const [yearOptions, setYearOptions] = useState<SatelliteFilterValue<number>[]>([]);
  const [providerOptions, setProviderOptions] = useState<SatelliteFilterValue<string>[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    const monthSet = new Set<number>();
    const yearSet = new Set<number>();
    const providerSet = new Set<string>();

    images.forEach(({ satelliteImage }) => {
      const { acquisitionDate, provider = '' } = satelliteImage;

      if (acquisitionDate) {
        const imageDate = new Date(acquisitionDate);

        if (monthSet.size < 12) {
          monthSet.add(imageDate.getMonth());
        }

        yearSet.add(imageDate.getFullYear());
      }

      providerSet.add(provider);
    });

    const newMonthOptions = [...monthSet.values()].map((month) => {
      return {
        title: t(`general.date.months.${month}`),
        value: month,
      };
    });

    const newYearOptions = [...yearSet.values()].map(mapToOptions);
    const newProviderOptions = [...providerSet.values()].map(mapToOptions);

    setMonthOptions(newMonthOptions);
    setYearOptions(newYearOptions);
    setProviderOptions(newProviderOptions);
  }, [images, t]);

  function handleChange<T>(prop: string, newValue: T) {
    onChange({
      ...value,
      [prop]: newValue,
    });
  }

  return (
    <div className={clsx('satellite-filters', classes.root)}>
      <Select
        id="cloudFilter"
        classes={{
          root: 'satellite-filters__control-cloud',
          label: 'satellite-filters__control-label',
        }}
        label={t('general.controls.satellite-filters.cloud')}
        options={cloudFilterOptions}
        value={value.cloudFilterValue}
        onChange={(cloudFilterValue: number | null) => handleChange('cloudFilterValue', cloudFilterValue)}
      />
      <ComboBox
        title={t('general.controls.satellite-filters.provider')}
        placeholder={t('general.controls.select')}
        multiple
        options={providerOptions}
        value={value.providerFilterValue}
        getOptionSelected={(option, selectedValue) => option.value === selectedValue.value}
        onChange={(_event, providerValue) => handleChange('providerFilterValue', providerValue)}
        classes={{
          root: 'satellite-filters__control-provider',
          label: 'satellite-filters__control-label',
          autocomplete: 'satellite-filters__control-autocomplete',
        }}
      />
      <ComboBox
        title={t('general.controls.satellite-filters.month')}
        placeholder={t('general.controls.select')}
        multiple
        options={monthOptions}
        value={value.monthFilterValue}
        getOptionSelected={(option, selectedValue) => option.value === selectedValue.value}
        onChange={(_event, monthFilterValue) => handleChange('monthFilterValue', monthFilterValue)}
        classes={{
          root: 'satellite-filters__control-period',
          label: 'satellite-filters__control-label',
          autocomplete: 'satellite-filters__control-autocomplete',
        }}
      />
      <ComboBox
        title={t('general.controls.satellite-filters.year')}
        placeholder={t('general.controls.select')}
        multiple
        options={yearOptions}
        value={value.yearFilterValue}
        getOptionSelected={(option, selectedValue) => option.value === selectedValue.value}
        onChange={(_event, yearFilterValue) => handleChange('yearFilterValue', yearFilterValue)}
        classes={{
          root: 'satellite-filters__control-period',
          label: 'satellite-filters__control-label',
          autocomplete: 'satellite-filters__control-autocomplete',
        }}
      />
      <div className="satellite-filters__control">
        <NDVISlider
          classes={{
            label: 'satellite-filters__control-label',
          }}
          value={value.ndviFilterValue}
          onChange={(selectedValue: number[]) => handleChange('ndviFilterValue', selectedValue)}
        />
      </div>
      {onFiltersClear && <ResetFiltersButton classes={{ root: 'satellite-filters__reset-button' }} onClick={onFiltersClear}/>}
    </div>
  );
};

export default SatelliteFilter;

import { featureCollection } from '@turf/helpers';
import area from '@turf/area';

import { getRatesData } from '../../analysis';
import { getUserUnitArea } from '../..';

export const normalizeZoneNumbers = (ftrCol) => {
  if (!ftrCol) {
    return null;
  }

  const newFtr = featureCollection([]);
  const maxZone = Math.max(...ftrCol.features.map((f) => f.properties.zone));
  const sortedFeatures = ftrCol.features
    .map((ftr) => {
      const { zone } = ftr.properties;

      return {
        ...ftr,
        properties: {
          ...ftr.properties,
          zone: zone > 0 ? zone : maxZone - zone,
        },
      };
    })
    .sort((ftrA, ftrB) => {
      return ftrA.properties.zone - ftrB.properties.zone;
    })
    .map((ftr, ind) => {
      return {
        ...ftr,
        properties: {
          ...ftr.properties,
          zone: ind + 1,
        },
      };
    });

  newFtr.features.push(...sortedFeatures);

  return newFtr;
};

export const normalizeFeatureProperties = (aFeatureCollection, areaUnit) => {
  if (!aFeatureCollection) {
    return null;
  }

  const newFtr = featureCollection([]);
  const ratesHeaders = getRatesData(aFeatureCollection, 'ratesHeaders');
  const ratesUnits = getRatesData(aFeatureCollection, 'ratesUnits');
  const ratesPrices = getRatesData(aFeatureCollection, 'ratesPrices');
  const ratesMap = aFeatureCollection.features.reduce((acc, ftr) => {
    const {
      zone,
      attributes: {
        rates,
      } = {},
    } = ftr.properties;

    if (
      !acc.has(zone)
      && Array.isArray(rates)
      && rates.length === ratesHeaders.length
    ) {
      acc.set(zone, rates);
    }

    return acc;
  }, new Map());

  newFtr.features.push(...aFeatureCollection.features.map((ftr) => {
    return {
      ...ftr,
      properties: {
        ...ftr.properties,
        attributes: {
          ...ftr.properties.attributes,
          rates: ratesMap.get(ftr.properties.zone) || Array(ratesHeaders.length).fill(0),
          ratesHeaders,
          ratesUnits,
          ratesPrices,
          area: getUserUnitArea(area(ftr), areaUnit),
        },
      },
    };
  }));

  return newFtr;
};

export const normalizeZonesMapGeojson = (geojson, areaUnit) => {
  return normalizeZoneNumbers(normalizeFeatureProperties(geojson, areaUnit)) || null;
};

export const isAttributesValid = (attributes) => {
  return attributes && attributes.features.length > 0;
};

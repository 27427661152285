import React, {
  ChangeEvent,
  useState,
} from 'react';
import Typography from '@material-ui/core/Typography';

import Button from '../../../../../components/Button';
import TextField from '../../../../../components/TextField';
import {
  getMultilineHelperText,
  hasErrors,
} from '../../helpers/functions/authentication';
import { getPasswordHelperTexts } from '../../helpers/functions/password';
import { HELPER_TEXTS, HelperTextType } from '../../helpers/constants/helperTexts';
import { HelperText } from '../../types/ui';

import '../common/index.scss';

enum NewPasswordFormField {
  password = 'password',
  passwordConfirm = 'passwordConfirm',
}

const NewPasswordForm = ({
  onChangePasswordClick,
}: {
  onChangePasswordClick: (password: string) => void;
}) => {
  const [formState, setFormState] = useState({
    password: '',
    passwordConfirm: '',
  });
  const [formHelperTexts, setFormHelperTexts] = useState({
    password: [],
    passwordConfirm: [],
  });

  const handleFieldChange = (field: string, value: string) => {
    let fieldHelperTexts: HelperText[] = [];

    if (field === NewPasswordFormField.password) {
      fieldHelperTexts = getPasswordHelperTexts(value);
    } else {
      fieldHelperTexts = formState.password !== value
        ? [
          {
            label: HELPER_TEXTS.passwordsMismatch,
            type: HelperTextType.error,
          },
        ]
        : [];
    }

    setFormState((prevFormState) => {
      return {
        ...prevFormState,
        [field]: value,
      };
    });
    setFormHelperTexts((prevFormHelperTexts) => {
      return {
        ...prevFormHelperTexts,
        [field]: fieldHelperTexts,
      };
    });
  };

  const handleChangePasswordClick = () => {
    setFormHelperTexts({
      password: [],
      passwordConfirm: [],
    });

    onChangePasswordClick(formState.password);
  };

  const changePasswordButtonDisabled = () => {
    const hasRequiredFields = formState.password && formState.passwordConfirm;

    return !hasRequiredFields || hasErrors(formHelperTexts);
  };

  const getFieldErrorState = (field: NewPasswordFormField) => {
    return formHelperTexts[field].some(({ type }) => {
      return type === HelperTextType.error;
    });
  };

  return (
    <div className="authentication-panel">
      <Typography
        classes={{
          root: 'authentication-panel__header',
        }}
        variant="h1"
      >
        Password Reset
      </Typography>
      <form
        name='Password Reset'
        className="authentication-panel__form"
      >
        <Typography
          classes={{
            root: 'authentication-panel__tip',
          }}
        >
          To reset your current password, please enter new password below.
        </Typography>
        <TextField
          title="New password"
          value={formState.password}
          error={getFieldErrorState(NewPasswordFormField.password)}
          helperText={getMultilineHelperText(formHelperTexts.password)}
          placeholder="Enter a new password"
          type="password"
          InputProps={{
            classes: {
              root: 'authentication-panel__input',
              error: 'textfield__input_error',
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: 'authentication-panel__input-helper-text',
            },
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleFieldChange(NewPasswordFormField.password, e.target.value);
          }}
        />
        <TextField
          title="New password again"
          value={formState.passwordConfirm}
          error={getFieldErrorState(NewPasswordFormField.passwordConfirm)}
          helperText={getMultilineHelperText(formHelperTexts.passwordConfirm)}
          placeholder="Enter a new password"
          type="password"
          InputProps={{
            classes: {
              root: 'authentication-panel__input',
              error: 'textfield__input_error',
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: 'authentication-panel__input-helper-text',
            },
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleFieldChange(NewPasswordFormField.passwordConfirm, e.target.value);
          }}
        />
        <Button
          className="authentication-panel__button"
          variant="contained"
          color="primary"
          disabled={changePasswordButtonDisabled()}
          onClick={handleChangePasswordClick}
        >
          Change password
        </Button>
      </form>
    </div>
  );
};

export default NewPasswordForm;

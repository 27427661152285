import React from 'react';

import { useAppSelector } from '../../../../../app/store/helpers/functions';
import { selectStepIndex } from '../../../batchAnalytics/batchAnalyticsSelectors';
import { getSteps } from '../../helpers/functions/steps';

export default function StepperEquationBased() {
  const stepIndex = useAppSelector(selectStepIndex);
  const steps = getSteps();
  const step = steps[stepIndex].id;
  const activeSteps = steps.slice(0, stepIndex + 1);
  const StepComponent = steps[stepIndex].component;

  return (
    <StepComponent
      steps={activeSteps}
      stepIndex={stepIndex}
      step={step}
    />
  );
}

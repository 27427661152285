import React from 'react';
import { withTranslation } from 'react-i18next';

import './index.scss';

const IntersectionSourcesTable = ({
  t,
  items = [],
}) => {
  return (
    <div className="intersection-sources">
      <table className="table">
        <thead className="table__header">
          <tr className="header-row">
            <th className="header-cell cell">{t('general.controls.map-legend.name')}</th>
            <th className="header-cell cell">{t('general.controls.map-legend.zones')}</th>
          </tr>
        </thead>
        <tbody>
          {
            items.map((item, index) => {
              return (
                <tr
                  key={index}
                  className="row"
                >
                  <td className="cell">
                    {
                      item.name
                        ? item.name
                        : (
                          <span className="text_disabled">
                            {t('general.controls.data-removed')}
                          </span>
                        )
                    }
                  </td>
                  <td className="cell">
                    {
                      item.zones.map((zone) => {
                        return (
                          <span
                            key={zone}
                            className="color"
                            style={{
                              backgroundColor: zone,
                            }}
                          >
                          </span>
                        );
                      })
                    }
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
};

export default withTranslation()(IntersectionSourcesTable);

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../app/store/helpers/types';

export const selectWorkflow = ({ batchAnalytics }: RootState) => batchAnalytics.workflow;

export const selectStepIndex = ({ batchAnalytics }: RootState) => batchAnalytics.stepIndex;

export const selectSelectedFields = ({ batchAnalytics }: RootState) => batchAnalytics.selectedFields;

export const selectSelectedFieldsUuids = createSelector(
  selectSelectedFields,
  (selectedFields) => {
    return Object.keys(selectedFields);
  },
);

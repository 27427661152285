export const batchOperations = async <T, K>(
  operation: (v: K) => T | Promise <T>,
  items: K[],
  batchSize: number,
  shift = 0,
): Promise<T[]> => {
  if (shift >= items.length) {
    return [];
  }

  const batch = items.slice(shift, shift + batchSize)
    .map((item) => operation(item));
  const result = await Promise.all(batch);
  const tail = await batchOperations(operation, items, batchSize, shift + batchSize);

  return [...result, ...tail];
};

export async function* batchOperationsGenerator<T, K, R>(
  operation: (v: K) => Promise<T>,
  items: K[],
  batchSize: number,
  process: (b: T[]) => R,
  shift = 0,
): AsyncGenerator<R, R | void, void> {
  if (shift >= items.length) {
    return;
  }

  const batch = items.slice(shift, shift + batchSize)
    .map((item) => operation(item));
  const result: T[] = await Promise.all(batch);

  yield process(result);
  yield* await batchOperationsGenerator(operation, items, batchSize, process, shift + batchSize);
}

/**
 * Splits array into smaller arrays with `chunkSize` length at most.
 * @param items
 * @param chunkSize
 * @returns Array of chunks
 */
export const splitIntoChunks = <T>(items: T[], chunkSize: number): T[][] => {
  return items.reduce<T[][]>((acc, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!acc[chunkIndex]) {
      acc[chunkIndex] = [];
    }

    acc[chunkIndex].push(item);

    return acc;
  }, []);
};

import i18n from 'i18next';
import {
  sortGeojsonFeatures,
  ISOXML_STATUSES,
  ISOXML_PURPOSE_TO_PRODUCT_UNITS,
} from '../../analysis';
import { VectorAnalysisMapType } from '../../constants/entities/vectorAnalysisMap';

const getEquationMapTableHeaders = () => {
  return [
    {
      id: 'color',
      label: i18n.t('zones-ops.multi-layer.steps.4.results-table.color'),
      align: 'left',
    },
    {
      id: 'value',
      label: i18n.t('zones-ops.equation-based.steps.5.results-table.value'),
      align: 'right',
    },
  ];
};

const getEquationMapTableRows = (headers, geoMap) => {
  const {
    classes,
    hexColorCodes,
  } = geoMap;
  const isRangeValues = (hexColorCodes.length + 1) === classes.length;

  return hexColorCodes.map((color, index) => {
    return {
      id: index + 1,
      data: headers.reduce((acc, { id }) => {
        if (id === 'color') {
          acc[id] = {
            color,
          };
        } else {
          const value = classes[index];
          let text;

          if (value == null) {
            text = '-';
          } else if (isRangeValues) {
            text = `${value.toFixed(3)} - ${classes[index + 1].toFixed(3)}`;
          } else {
            text = value.toFixed(3);
          }

          acc[id] = {
            text,
          };
        }

        return acc;
      }, {}),
    };
  });
};

export const getEquationMapTableData = ({
  geoMap,
}) => {
  const headers = getEquationMapTableHeaders();

  return {
    headers,
    rows: getEquationMapTableRows(headers, geoMap),
  };
};

export const prepareEquationMapJson = (geojson) => {
  let result;

  try {
    result = {
      ...geojson,
      features: sortGeojsonFeatures(geojson.features),
    };
  } catch (error) {
    console.error('Unable to prepare equation map geojson. ', error);
    result = geojson;
  }

  return result;
};

export const getPixelsGeoMap = (equationMap = {}) => {
  return (equationMap?.geoMaps || []).find((geoMap) => {
    return geoMap.shortName === 'pixels';
  });
};

export const isInvalid = (anEquationMap) => {
  return !anEquationMap
    || anEquationMap.status === 'ERROR'
    || !anEquationMap.geoMaps
    || anEquationMap.geoMaps.length === 0;
};

export const calculateEquationMapIsoXmlStatus = (equationMap = {}) => {
  const { type, productUnit } = equationMap;

  const isTypeValid = type === VectorAnalysisMapType.seeding
  || type === VectorAnalysisMapType.spraying
  || type === VectorAnalysisMapType.fertilizing;

  const isProductUnitValid = productUnit && ISOXML_PURPOSE_TO_PRODUCT_UNITS[type]?.includes(productUnit);

  return !isProductUnitValid || !isTypeValid ? ISOXML_STATUSES.INVALID : ISOXML_STATUSES.VALID;
};

export const getEquationMapThumbnailGeoMap = (geoMaps = []) => {
  return geoMaps.find(({ shortName }) => {
    return shortName === 'thumbnail_pixels';
  });
};

import { featureCollection } from '@turf/helpers';

import { AssetType } from '../../../../../helpers/constants/entities/asset';
import { normalizeZoneNumbers } from '../../../../../helpers/functions/entities/geojson';

export const mapUuidsToZoneNumbers = (zonesOperationMaps) => {
  return zonesOperationMaps.reduce((acc, { map, zoneNumbers }) => {
    acc[map.uuid] = zoneNumbers;

    return acc;
  }, {});
};

export const prepareMultiLayerDataLayers = (dataLayers) => {
  return dataLayers
    .map((dataLayer) => {
      let result = {
        weight: dataLayer.factor,
      };

      if (dataLayer.satelliteImages) {
        const satelliteImageUuids = dataLayer.satelliteImages.map(({ uuid }) => uuid);

        result = {
          ...result,
          type: AssetType.satelliteImage,
          index: dataLayer.index,
          satelliteImageUuids,
        };
      } else if (dataLayer.soilAttribute) {
        result = {
          ...result,
          type: AssetType.soilDataset,
          attribute: dataLayer.soilAttribute,
          datasetUuid: dataLayer.soilDataset.uuid,
        };
      } else if (dataLayer.yieldAttribute) {
        result = {
          ...result,
          type: AssetType.yieldDataset,
          attribute: dataLayer.yieldAttribute,
          datasetUuid: dataLayer.yieldDataset.uuid,
        };
      } else if (dataLayer.asAppliedAttribute) {
        result = {
          ...result,
          type: AssetType.asAppliedDataset,
          attribute: dataLayer.asAppliedAttribute,
          datasetUuid: dataLayer.asAppliedDataset.uuid,
        };
      } else if (dataLayer.topographyAttribute) {
        result = {
          ...result,
          type: AssetType.topographyMap,
          attribute: dataLayer.topographyAttribute,
          datasetUuid: dataLayer.topographyMap.uuid,
        };
      } else {
        result = null;
      }

      return result;
    })
    .filter(Boolean);
};

export const prepareDrawManuallyFeature = ({ features }) => {
  const preparedFeatures = features.map(({
    type,
    geometry,
    properties,
  }) => {
    return {
      type,
      geometry,
      properties: {
        zone: properties.zone,
        attributes: {
          ...properties.attributes,
          rates: [],
          ratesHeaders: [],
          ratesPrices: [],
          ratesUnits: [],
        },
      },
    };
  });

  return normalizeZoneNumbers(featureCollection(preparedFeatures));
};

import React from 'react';

import { getZonesMapData } from '../../../../helpers/analysis';
import { isAttributesValid } from '../../../../helpers/functions/entities/geojson';
import GeoMapsTable from '../GeoMapsTable';

const ZonesMapTable = ({
  forwardedRef,
  areaUnit,
  attributes,
  colors,
  selectedZones,
  activeZone,
  includeRates,
  includeSelects,
  onZoneClick = () => {},
}) => {
  if (!isAttributesValid(attributes)) {
    return null;
  }

  const {
    headers,
    rows,
  } = getZonesMapData({
    features: attributes.features,
    colors,
    areaUnit,
    selectedItems: selectedZones,
    withRates: includeRates,
    withSelects: includeSelects,
  });

  return (
    <GeoMapsTable
      ref={forwardedRef}
      activeRow={activeZone}
      includeSelects={includeSelects}
      headers={headers}
      rows={rows}
      onRowClick={onZoneClick}
    />
  );
};

export default React.forwardRef((props, ref) => (
  <ZonesMapTable
    forwardedRef={ref}
    {...props}
  />
));

import React, { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';

import PopupHeader from '../../../../../../../../components/Popups/PopupHeader';
import ComboBox from '../../../../../../../../components/ComboBox';
import ItemsPicker from '../../../../../../../../components/ItemsPicker';
import { selectYieldDataset } from '../../../../../../../field/fieldSelectors';
import {
  selectCleanTargetAttribute,
  selectCleanMinMaxConditions,
  selectCleanExcludedAttributes,
  selectDatasetUuid,
} from '../../../../../cleanCalibrateSelectors';
import {
  createFullAttributeItem,
  getComboBoxOptionSelected,
  transformCleanMinMaxConditions,
} from '../../../../../helpers/functions/ui';
import type { FullAttributeItem } from '../../../../../types/ui';
import type { FullAttribute } from '../../../../../../../../helpers/types/dataset';
import {
  addEmptyCleanMinMaxCondition,
  setCleanTargetAttribute,
  setCleanExcludedAttributes,
  updateCleanMinMaxCondition,
  removeCleanMinMaxCondition,
  initCleanMinMaxConditions,
  resetCleanMinMaxConditions,
} from '../../../../../cleanCalibrateSlice';
import MinMaxConditions from '../../../../../components/MinMaxConditions';
import type { NullableMinMaxCleanCondition } from '../../../../../types/actions';
import ToggleAdvancedConfigButton from '../../../../../components/ToggleAdvancedConfigButton';
import { useAppSelector } from '../../../../../../../../app/store/helpers/functions';

import './index.scss';

export default function CleanTabContent({
  onCancel,
}: {
  onCancel: () => void,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const datasetUuid = useAppSelector(selectDatasetUuid);
  const dataset = useAppSelector((state) => selectYieldDataset(state, datasetUuid));
  const cleanAttribute = useAppSelector(selectCleanTargetAttribute);
  const cleanExcludedAttributes = useAppSelector(selectCleanExcludedAttributes);
  const cleanConditions = useAppSelector(selectCleanMinMaxConditions);
  const [configExpanded, setConfigExpanded] = useState(!!cleanExcludedAttributes?.length || !!cleanConditions.length);

  const allAttributesItems = useMemo<FullAttributeItem[]>(() => {
    return (dataset?.fullAttributes || []).map((fullAttribute: FullAttribute) => {
      return createFullAttributeItem(fullAttribute);
    });
  }, [dataset]);

  const cleanAttributeItem = useMemo<FullAttributeItem | undefined>(() => {
    return allAttributesItems.find((item) => item.value === cleanAttribute);
  }, [allAttributesItems, cleanAttribute]);

  const excludedAttributesValues = useMemo<Set<string>>(() => {
    return new Set(cleanExcludedAttributes);
  }, [cleanExcludedAttributes]);

  const handleCleanAttributeChange = (_e: unknown, item: FullAttributeItem) => {
    dispatch(setCleanTargetAttribute(item.value));
  };

  const handleToggleConfigClick = () => {
    setConfigExpanded(!configExpanded);

    if (configExpanded) {
      dispatch(resetCleanMinMaxConditions());
    } else {
      dispatch(initCleanMinMaxConditions());
    }
  };

  const handleExcludedAttributesChange = (itemsValues: Set<string>) => {
    dispatch(setCleanExcludedAttributes([...itemsValues]));
  };

  const handleAddCondition = () => {
    dispatch(addEmptyCleanMinMaxCondition());
  };

  const handleConditionAttributeChange = (attributeItem: FullAttributeItem, index: number) => {
    const update: Partial<NullableMinMaxCleanCondition> = {
      cleanAttribute: attributeItem?.value ?? null,
    };

    if (attributeItem?.value) {
      update.min = null;
      update.max = null;
    }

    dispatch(updateCleanMinMaxCondition({
      index,
      condition: update,
    }));
  };

  const handleConditionMinChange = (min: number | null, index: number) => {
    dispatch(updateCleanMinMaxCondition({
      index,
      condition: { min },
    }));
  };

  const handleConditionMaxChange = (max: number | null, index: number) => {
    dispatch(updateCleanMinMaxCondition({
      index,
      condition: { max },
    }));
  };

  const handleConditionDelete = (index: number) => {
    dispatch(removeCleanMinMaxCondition(index));
  };

  return (
    <>
      <PopupHeader
        classes={{
          root: 'clean-tab-content__header',
        }}
        title={t('clean-calibrate.yield-popup.clean-title')}
        onCancel={onCancel}
      />
      <DialogContent className="clean-tab-content__content">
        <DialogContentText className="clean-tab-content__content-description">
          {t('clean-calibrate.yield-popup.tabs.clean.configure.description')}
        </DialogContentText>
        <ComboBox
          title={t('clean-calibrate.yield-popup.tabs.clean.configure.yield-attribute')}
          placeholder={t('general.controls.select')}
          options={allAttributesItems}
          value={cleanAttributeItem}
          getOptionSelected={getComboBoxOptionSelected}
          disableCloseOnSelect={false}
          disableClearable
          required
          onChange={handleCleanAttributeChange}
        />
        {
          configExpanded
            ? (
              <>
                <Divider className="clean-tab-content__content-divider" />
                <DialogContentText>
                  {t('clean-calibrate.yield-popup.tabs.clean.configure.attributes-instruction')}
                </DialogContentText>
                <ItemsPicker
                  availableItemsTitle={t('clean-calibrate.yield-popup.attributes')}
                  addedItemsTitle={t('clean-calibrate.yield-popup.tabs.clean.configure.excluded-attributes')}
                  items={allAttributesItems}
                  addedItemsValues={excludedAttributesValues}
                  emptyAddedItemsLabel={t('clean-calibrate.yield-popup.no-added-attributes')}
                  onAddedItemsChange={handleExcludedAttributesChange}
                />
                <Divider className="clean-tab-content__content-divider" />
                <DialogContentText>
                  {t('clean-calibrate.yield-popup.tabs.clean.configure.conditions-instruction')}
                </DialogContentText>
                <MinMaxConditions
                  attributesItems={allAttributesItems}
                  conditions={transformCleanMinMaxConditions(cleanConditions)}
                  statistics={dataset?.statistics || []}
                  onAddConditionClick={handleAddCondition}
                  onConditionAttributeChange={handleConditionAttributeChange}
                  onConditionMinChange={handleConditionMinChange}
                  onConditionMaxChange={handleConditionMaxChange}
                  onConditionDelete={handleConditionDelete}
                />
              </>
            )
            : <Fragment />
        }
        <ToggleAdvancedConfigButton
          value={configExpanded}
          showLabelI18nKey="clean-calibrate.yield-popup.show-advanced-config"
          hideLabelI18nKey="clean-calibrate.yield-popup.hide-advanced-config"
          onClick={handleToggleConfigClick}
        />
      </DialogContent>
    </>
  );
}

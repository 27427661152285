import React from 'react';
import clsx from 'clsx';

import './index.scss';

const FilterSummary = ({
  filterSummary,
  classes = {},
}: {
  filterSummary: { label: string; value: string }[];
  classes?: { root?: string };
}) => {
  return (
    <div className={clsx('filter-summary', classes.root)}>
      <div className="filter-summary__items">
        {filterSummary.map(({ label, value }) => {
          return value
            ? (
              <div className="filter-summary__item" key={label}>
                <span>{label}</span>:
                <span className="filter-summary__item-value"> {value}</span>
              </div>
            )
            : (
              ''
            );
        })}
      </div>
    </div>
  );
};

export default FilterSummary;

import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Auth } from '@aws-amplify/auth';

import NewPasswordForm from '../../../../components/NewPasswordForm';
import { getAuthLink } from '../../../../../../../helpers/navigation';
import { getCode, getEmail } from '../../../../helpers/functions/navigation';
import { RECOVER_PASSWORD_ERRORS } from '../../../../helpers/constants/errors';
import { View } from '../../../../helpers/constants/authentication';
import { errorNotify } from '../../../../../../notifications/helpers/functions/notify';
import { CustomError } from '../../../../../../../helpers/functions/utils/errorHandling';
import { signOut } from '../../../../../../user/userSlice';
import useDidMount from '../../../../../../../hooks/useDidMount';

const RecoverPasswordPanel = ({
  onLoading,
}: {
  onLoading: (loading: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const code = getCode(location.search);
  const email = getEmail(location.search);

  useDidMount(() => {
    dispatch(signOut());
  });

  const onChangePasswordClick = (password: string) => {
    onLoading(true);

    Auth.forgotPasswordSubmit(email, code, password)
      .then(() => {
        history.push(getAuthLink(View.recoverPasswordConfirm));
      })
      .catch((e) => {
        const error = RECOVER_PASSWORD_ERRORS.find((err) => {
          return err.regexp.test(e.message);
        });

        errorNotify({
          error: new CustomError('[UI Authentication] Unable to recover password.', {
            cause: e,
          }),
          message: error?.helperText || e.message,
          dispatch,
        });
      })
      .finally(() => {
        onLoading(false);
      });
  };

  return (
    <NewPasswordForm
      onChangePasswordClick={onChangePasswordClick}
    />
  );
};

export default RecoverPasswordPanel;

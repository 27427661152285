import subHours from 'date-fns/subHours';
import {
  isBefore,
  differenceInMinutes,
  startOfTomorrow,
  format,
  startOfDay,
  endOfDay,
  parse,
} from 'date-fns';
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes';
import i18n from 'i18next';

import { getSearchParam } from '../../../../../helpers/navigation';
import {
  DEFAULT_TIME_RANGE,
  CUSTOM_TIME_RANGE_FILTER,
  FilterParam,
  MAX_ALLOWED_TIME_RANGE,
  MONTHS_BACK_ALLOWED,
  TIME_RANGE_DATE_FORMAT,
} from '../constants/filters';
import { comparator } from '../../../../../helpers/functions/utils/string';
import {
  TimeRange,
  TimeRangeErrors,
} from '../../types/filters';
import {
  isDateValid,
  isDateWithinAllowedRange,
} from '../../../../../helpers/functions/utils/date';
import {
  Field,
  PlatformContextEvent,
} from '../../../../asyncOperations/types/api';

export const extractFilters = (search: string): {
  timeRange: string,
  dateFrom: string,
  dateTo: string,
  farmUuid: string,
  fieldUuid: string,
} => {
  const timeRangeParam = getSearchParam(search, FilterParam.timeRange);
  const farmUuid = getSearchParam(search, FilterParam.farm);
  const fieldUuid = getSearchParam(search, FilterParam.field);
  const dateFrom = getSearchParam(search, FilterParam.dateFrom);
  const dateTo = getSearchParam(search, FilterParam.dateTo);

  const timeRange = timeRangeParam === CUSTOM_TIME_RANGE_FILTER
    ? CUSTOM_TIME_RANGE_FILTER
    : timeRangeParam || DEFAULT_TIME_RANGE;

  return {
    timeRange,
    dateFrom,
    dateTo,
    farmUuid,
    fieldUuid,
  };
};

export const timeRangeToDates = (
  timeRange: string,
): {
  dateFrom: string;
  dateTo: string;
} => {
  const parsedTimeRange = parseInt(timeRange, 10);
  const to = roundToNearestMinutes(new Date());
  const from = subHours(to, parsedTimeRange);

  return {
    dateFrom: from.toISOString(),
    dateTo: to.toISOString(),
  };
};

export const getDefaultCustomTimeRangeFilter = () => {
  const parsedTimeRange = parseInt(MAX_ALLOWED_TIME_RANGE, 10);
  const to = format(new Date(), TIME_RANGE_DATE_FORMAT);
  const from = format(subHours(startOfTomorrow(), parsedTimeRange), TIME_RANGE_DATE_FORMAT);

  return {
    to,
    from,
  };
};

export const parseTimeRangeDate = (date: string) => {
  const parsedDate = parse(date, TIME_RANGE_DATE_FORMAT, new Date());

  if (isDateValid(parsedDate)) {
    return parsedDate;
  }

  return isDateValid(new Date(date)) ? new Date(date) : null;
};

export const getOperationsFilters = (
  timeRange: string,
  dateFrom: string,
  dateTo: string,
  userUuid: string,
): {
  dateFrom: string;
  dateTo: string;
  userUuids: string[];
} => {
  if (dateFrom && dateTo) {
    const form = parseTimeRangeDate(dateFrom);
    const to = parseTimeRangeDate(dateTo);

    return {
      dateFrom: form ? startOfDay(form).toISOString() : '',
      dateTo: to ? roundToNearestMinutes(endOfDay(to)).toISOString() : '',
      userUuids: [userUuid],
    };
  }

  return {
    ...timeRangeToDates(timeRange),
    userUuids: [userUuid],
  };
};

export const getFieldsOptions = (
  fields: Field[],
  selectedFarmUuid?: string,
): Array<{ title: string; value: Field }> => {
  return fields
    .filter((field) => (selectedFarmUuid ? field.farmUuid === selectedFarmUuid : true))
    .sort((a, b) => comparator(a.name ?? '', b.name ?? ''))
    .map((field) => ({ title: field.name ?? '', value: field }));
};

export const getFarmFieldsUuids = (
  fields: Field[] = [],
  farmUuid?: string,
): string[] => {
  return fields
    .filter((field) => field.farmUuid === farmUuid)
    .map(({ uuid }) => uuid);
};

export const isFieldPresented = (
  fieldUuid: string,
  fields?: Field[],
): boolean => {
  return !!fields?.find((field) => field.uuid === fieldUuid);
};

const isMaxTimeRangeExceeded = (dateFrom: Date, dateTo: Date):boolean => {
  return Math.abs(differenceInMinutes(dateFrom, dateTo)) > parseInt(MAX_ALLOWED_TIME_RANGE, 10) * 60;
};

export const validateCustomTimeRange = (
  filterType: keyof TimeRange,
  { dateFrom, dateTo }: TimeRange,
): TimeRangeErrors => {
  const errors = { [FilterParam.dateFrom]: '', [FilterParam.dateTo]: '' };
  const isDateFromValid = isDateValid(dateFrom);
  const isDateToValid = isDateValid(dateTo);
  const isDateFromAllowed = isDateFromValid && isDateWithinAllowedRange(dateFrom, MONTHS_BACK_ALLOWED);
  const isDateToAllowed = isDateToValid && isDateWithinAllowedRange(dateTo, MONTHS_BACK_ALLOWED);

  if (!isDateFromValid) {
    errors[FilterParam.dateFrom] = dateFrom
      ? i18n.t('general.controls.datepicker.error')
      : i18n.t('operations.filters.time-range.errors.required');
  } else if (!isDateFromAllowed) {
    errors[FilterParam.dateFrom] = i18n.t('operations.filters.time-range.errors.date-not-allowed');
  }

  if (!isDateToValid) {
    errors[FilterParam.dateTo] = dateTo
      ? i18n.t('general.controls.datepicker.error')
      : i18n.t('operations.filters.time-range.errors.required');
  } else if (!isDateToAllowed) {
    errors[FilterParam.dateTo] = i18n.t('operations.filters.time-range.errors.date-not-allowed');
  }

  if (isDateFromAllowed && isDateToAllowed) {
    const oppositeFilter = filterType === FilterParam.dateTo
      ? FilterParam.dateFrom
      : FilterParam.dateTo;

    const startOfDateFrom = startOfDay(dateFrom);
    const endOfDateTo = endOfDay(dateTo);

    if (!isBefore(startOfDateFrom, endOfDateTo)) {
      errors[oppositeFilter] = i18n.t(
        'operations.filters.time-range.errors.invalid-range',
      );
    } else if (isMaxTimeRangeExceeded(startOfDateFrom, endOfDateTo)) {
      errors[oppositeFilter] = i18n.t(
        'operations.filters.time-range.errors.max-range-exceeded',
      );
    }
  }

  return errors;
};

export const geOperationsFilteredByFields = (
  events: PlatformContextEvent[],
  fieldsUuids: string[],
): PlatformContextEvent[] => {
  const fieldsSet = new Set(fieldsUuids);
  return fieldsUuids.length
    ? events.filter((event) => fieldsSet.has(event.fieldUuid))
    : events;
};

export const getFilteredFieldsUuids = (
  fields?: Field[],
  fieldUuid?: string,
  farmUuid?: string,
): string[] => {
  if (fieldUuid && isFieldPresented(fieldUuid, fields)) {
    return [fieldUuid];
  }

  if (farmUuid) {
    return getFarmFieldsUuids(fields, farmUuid);
  }

  return [];
};

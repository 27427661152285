import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Legend from '../../../../../components/Legend';
import FieldLegendContent from '../../../../../components/Legend/FieldLegendContent';
import Button from '../../../../../components/Button';
import { getFieldLink } from '../../../../../helpers/navigation';
import { selectApiKey, selectAreaUnit } from '../../../../user/userSelectors';
import { useGetFarmQuery } from '../../../../farms/farmsAPI';
import {
  selectChartScale,
  selectFieldSatelliteImagesData,
  selectFieldSatelliteImagesStatus,
  selectLegend,
} from '../../mapViewSelectors';
import {
  changeChartScale,
  closeLegend,
  fetchNDVIGraphSatelliteImages,
} from '../../mapViewSlice';
import { AssetLoadStatus, AssetType } from '../../../../../helpers/constants/entities/asset';

const MapViewLegend = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const areaUnit = useSelector(selectAreaUnit);
  const apiKey = useSelector(selectApiKey);
  const legend = useSelector(selectLegend);
  const chartScale = useSelector(selectChartScale);
  const {
    data: farm,
  } = useGetFarmQuery({
    farmUuid: legend?.farmUuid,
  });
  const satelliteImages = useSelector((state) => selectFieldSatelliteImagesData(state, legend?.uuid));
  const satelliteImagesStatus = useSelector((state) => selectFieldSatelliteImagesStatus(state, legend?.uuid));

  useEffect(() => {
    if (!legend) {
      return;
    }

    dispatch(fetchNDVIGraphSatelliteImages({
      fieldUuid: legend.uuid,
      farmUuid: legend.farmUuid,
    }));
  }, [legend, dispatch]);

  if (!legend) {
    return null;
  }

  const onLegendClose = () => {
    dispatch(closeLegend());
  };
  const onChartScaleChange = (scale) => {
    dispatch(changeChartScale(scale));
  };

  return (
    <Legend
      title={legend.name}
      type={AssetType.field}
      loading={satelliteImagesStatus === AssetLoadStatus.loading}
      content={(
        <FieldLegendContent
          field={legend}
          farm={farm}
          satelliteImages={satelliteImages}
          apiKey={apiKey}
          areaUnit={areaUnit}
          chartScale={chartScale}
          onChartScaleChange={onChartScaleChange}
        />
      )}
      actions={(
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={getFieldLink(legend.farmUuid, legend.uuid)}
          onClick={onLegendClose}
        >
          {t('field-profiler.map-view.go-to-profile')}
        </Button>
      )}
      onClose={onLegendClose}
    />
  );
};

export default MapViewLegend;

import { API, graphqlOperation } from '@aws-amplify/api';

import importFromJohnDeereAsync from './graphql/mutations/importFromJohnDeereAsync.gql';
import importAllFromJohnDeereAsync from './graphql/mutations/importAllFromJohnDeereAsync.gql';

const importDataFromJohnDeere = (query, input, output) => {
  return API.graphql(graphqlOperation(query, {
    input,
  }))
    .then((response) => {
      const { status = '' } = response.data[output];

      return {
        status,
      };
    });
};

export const executeImportFromJohnDeere = async ({
  fields,
  importType,
  fieldOperationTypes,
  cropSeasons,
}) => {
  return importDataFromJohnDeere(importFromJohnDeereAsync, {
    fields,
    importType,
    fieldOperationTypes,
    cropSeasons,
  }, 'importFromJohnDeereAsync');
};

export const executeImportAllFromJohnDeere = async ({
  organizationsIds,
  fieldName,
  importType,
  fieldOperationTypes,
  cropSeasons,
}) => {
  return importDataFromJohnDeere(importAllFromJohnDeereAsync, {
    organizationsIds,
    fieldName,
    importType,
    fieldOperationTypes,
    cropSeasons,
  }, 'importAllFromJohnDeereAsync');
};

export enum GeoFormat {
  geojson = 'GEOJSON',
  geotiff = 'GEOTIFF',
}

export enum BufferSize {
  NoBuffer = 'NoBuffer',
  PlusOnePixel = 'PlusOnePixel',
  PlusTwoPixels = 'PlusTwoPixels',
  MinusOnePixel = 'MinusOnePixel',
  MinusTwoPixels = 'MinusTwoPixels',
}

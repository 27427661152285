import React from 'react';
import Typography from '@material-ui/core/Typography';
import { OperationStatus } from '../../helpers/constants/operation';

import './index.scss';

type ExceptionType = OperationStatus.ignored | OperationStatus.error;

export default function OperationException({
  type,
  exception,
}: {
  type?: ExceptionType;
  exception: string;
}) {
  if (!exception) {
    return null;
  }

  return (
    <Typography
      variant="body2"
      className={`operation-exception operation-exception_${type}`}
    >
      {exception}
    </Typography>
  );
}

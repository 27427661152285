import { getRootLink, PAGES_ROOTS } from '../../../../../helpers/navigation';
import { UploadType } from '../../helpers/constants/upload';

const getRoutesConfig = (flags?: { isAreaLimitReached: boolean }) => {
  return [
    {
      path: getRootLink(PAGES_ROOTS.uploadAll),
      value: UploadType.allTypes,
      labelI18nKey: 'upload-data.modes.all-types',
      disabled: true,
    },
    {
      path: getRootLink(PAGES_ROOTS.uploadFieldBoundaries),
      value: UploadType.fieldBoundaries,
      labelI18nKey: 'upload-data.modes.field-boundaries',
      disabled: flags?.isAreaLimitReached,
    },
    {
      path: getRootLink(PAGES_ROOTS.uploadSoilData),
      value: UploadType.soilData,
      labelI18nKey: 'general.shared.soil-data',
    },
    {
      path: getRootLink(PAGES_ROOTS.uploadYieldData),
      value: UploadType.yieldData,
      labelI18nKey: 'general.shared.yield-data',
    },
    {
      path: getRootLink(PAGES_ROOTS.uploadAsApplied),
      value: UploadType.asApplied,
      labelI18nKey: 'upload-data.modes.as-applied',
    },
    {
      path: getRootLink(PAGES_ROOTS.uploadMachineryFormats),
      value: UploadType.machineryFormats,
      labelI18nKey: 'upload-data.modes.machinery-formats',
    },
  ];
};

export default getRoutesConfig;

import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function ImportIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M15 9.5H19L12 16.5L5 9.5H9V3.5H15V9.5ZM5 20.5V18.5H19V20.5H5Z" />
    </SvgIcon>
  );
}

import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';

import type { AttributeStatistics } from '../../../../../helpers/types/dataset';
import type { FullAttributeItem, NullableMinMaxCondition } from '../../types/ui';
import Button from '../../../../../components/Button';
import TextField from '../../../../../components/TextField';
import ComboBox from '../../../../../components/ComboBox';
import Checkbox from '../../../../../components/Checkbox';
import {
  getComboBoxOptionSelected,
  isFullAttributeItemDisabled,
} from '../../helpers/functions/ui';

import './index.scss';

export default function MinMaxConditions({
  attributesItems,
  conditions,
  statistics = [],
  conditionMinInfoTooltip,
  conditionMaxInfoTooltip,
  onAddConditionClick,
  onConditionAttributeChange,
  onConditionMinChange,
  onConditionMinIncludedChange,
  onConditionMaxChange,
  onConditionMaxIncludedChange,
  onConditionDelete,
}: {
  attributesItems: FullAttributeItem[],
  conditions: NullableMinMaxCondition[],
  statistics?: AttributeStatistics[],
  conditionMinInfoTooltip?: string,
  conditionMaxInfoTooltip?: string,
  onAddConditionClick: () => void,
  onConditionAttributeChange: (i: FullAttributeItem, index: number) => void,
  onConditionMinChange: (i: number | null, index: number) => void,
  onConditionMinIncludedChange?: (i: boolean, index: number) => void,
  onConditionMaxChange: (i: number | null, index: number) => void,
  onConditionMaxIncludedChange?: (i: boolean, index: number) => void,
  onConditionDelete: (i: number) => void,
}) {
  const { t } = useTranslation();

  const getMinValueLimits = (
    condition: NullableMinMaxCondition,
    attributeStatistics?: AttributeStatistics,
  ) => {
    const result: {
      min: number | null,
      max: number | null,
    } = {
      min: null,
      max: null,
    };

    if (attributeStatistics?.min != null) {
      result.min = attributeStatistics.min;
    }

    if (condition.max != null) {
      result.max = condition.max;
    } else if (attributeStatistics?.max != null) {
      result.max = attributeStatistics.max;
    }

    return result;
  };

  const getMaxValueLimits = (
    condition: NullableMinMaxCondition,
    attributeStatistics?: AttributeStatistics,
  ) => {
    const result: {
      min: number | null,
      max: number | null,
    } = {
      min: null,
      max: null,
    };

    if (attributeStatistics?.max != null) {
      result.max = attributeStatistics.max;
    }

    if (condition.min != null) {
      result.min = condition.min;
    } else if (attributeStatistics?.min != null) {
      result.min = attributeStatistics.min;
    }

    return result;
  };

  const handleConditionMinChange = (e: ChangeEvent<{ value: string }>, index: number) => {
    const value = e.target.value ? parseFloat(e.target.value) : null;
    onConditionMinChange(value, index);
  };

  const handleConditionMaxChange = (e: ChangeEvent<{ value: string }>, index: number) => {
    const value = e.target.value ? parseFloat(e.target.value) : null;
    onConditionMaxChange(value, index);
  };

  const isValueInvalid = (minLimit: number | null, maxLimit: number | null, value?: number | null) => {
    const parsedValue = parseFloat(value != null ? value.toString() : '');
    const parsedMinLimit = parseFloat(minLimit != null ? minLimit.toString() : '');
    const parsedMaxLimit = parseFloat(maxLimit != null ? maxLimit.toString() : '');

    return parsedMinLimit > parsedValue || parsedValue > parsedMaxLimit;
  };

  return (
    <div className="min-max-conditions">
      <div className="min-max-conditions__header">
        <Typography className="min-max-conditions__title">
          {t('clean-calibrate.yield-popup.attributes')}
        </Typography>
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={onAddConditionClick}
        >
          {t('clean-calibrate.yield-popup.add-attribute')}
        </Button>
      </div>
      <div>
        {
          conditions.map((condition, index) => {
            const conditionAttributeItem = attributesItems.find((attributeItem) => {
              return attributeItem.value === condition.attribute;
            });
            const attributeStatistics = statistics.find((statistic) => {
              return statistic.attribute === condition.attribute;
            });
            const {
              min: minValueMinLimit,
              max: minValueMaxLimit,
            } = getMinValueLimits(condition, attributeStatistics);
            const {
              min: maxValueMinLimit,
              max: maxValueMaxLimit,
            } = getMaxValueLimits(condition, attributeStatistics);

            return (
              <div
                key={index}
                className="min-max-conditions-item"
              >
                <ComboBox
                  classes={{
                    root: 'min-max-conditions-item__attribute-selector',
                  }}
                  title={t('clean-calibrate.yield-popup.tabs.calibrate.configure.attribute-title', {
                    number: index + 1,
                  })}
                  disableClearable
                  placeholder={t('general.controls.select')}
                  options={attributesItems}
                  value={conditionAttributeItem || (null as unknown as FullAttributeItem)}
                  getOptionSelected={getComboBoxOptionSelected}
                  getOptionDisabled={(o) => isFullAttributeItemDisabled(conditions, o, conditionAttributeItem)}
                  disableCloseOnSelect={false}
                  onChange={(_e, item) => onConditionAttributeChange(item, index)}
                />
                <div className="min-max-conditions-item__value-controls">
                  <TextField
                    classes={{
                      title: 'min-max-conditions-item__value-input-label',
                    }}
                    type="number"
                    title={t('general.controls.map-legend.statistics.min')}
                    infoTooltip={conditionMinInfoTooltip}
                    value={condition.min != null ? condition.min : ''}
                    disabled={!attributeStatistics}
                    helperText={(
                      <>
                        <span className="min-max-conditions-item__value-input-helper-text-line">
                          {
                            minValueMinLimit != null
                              ? t('clean-calibrate.yield-popup.min-value', { value: minValueMinLimit })
                              : ''
                          }
                        </span>
                        <span className="min-max-conditions-item__value-input-helper-text-line">
                          {
                            minValueMaxLimit != null
                              ? t('clean-calibrate.yield-popup.max-value', { value: minValueMaxLimit })
                              : ''
                          }
                        </span>
                      </>
                    )}
                    error={isValueInvalid(minValueMinLimit, minValueMaxLimit, condition.min)}
                    onChange={(e: ChangeEvent<{ value: string }>) => handleConditionMinChange(e, index)}
                  />
                  {
                    onConditionMinIncludedChange
                      && (
                        <FormControlLabel
                          className="min-max-conditions-item__value-checkbox"
                          disabled={!attributeStatistics}
                          label={t('clean-calibrate.yield-popup.tabs.calibrate.configure.min-max-conditions.included-min')}
                          control={(
                            <Checkbox
                              value={condition.minIncluded ? 2 : 0}
                              name={t('clean-calibrate.yield-popup.tabs.calibrate.configure.min-max-conditions.included-max')}
                              onChange={(_e: unknown, value: boolean) => onConditionMinIncludedChange(value, index)}
                            />
                          )}
                        />
                      )
                  }
                </div>
                <div className="min-max-conditions-item__value-controls">
                  <TextField
                    classes={{
                      title: 'min-max-conditions-item__value-input-label',
                    }}
                    type="number"
                    title={t('general.controls.map-legend.statistics.max')}
                    infoTooltip={conditionMaxInfoTooltip}
                    value={condition.max != null ? condition.max : ''}
                    disabled={!attributeStatistics}
                    helperText={(
                      <>
                        <span className="min-max-conditions-item__value-input-helper-text-line">
                          {
                            maxValueMinLimit != null
                              ? t('clean-calibrate.yield-popup.min-value', { value: maxValueMinLimit })
                              : ''
                          }
                        </span>
                        <span className="min-max-conditions-item__value-input-helper-text-line">
                          {
                            maxValueMaxLimit != null
                              ? t('clean-calibrate.yield-popup.max-value', { value: maxValueMaxLimit })
                              : ''
                          }
                        </span>
                      </>
                    )}
                    error={isValueInvalid(maxValueMinLimit, maxValueMaxLimit, condition.max)}
                    onChange={(e: ChangeEvent<{ value: string }>) => handleConditionMaxChange(e, index)}
                  />
                  {
                    onConditionMaxIncludedChange
                      && (
                        <FormControlLabel
                          className="min-max-conditions-item__value-checkbox"
                          disabled={!attributeStatistics}
                          label={t('clean-calibrate.yield-popup.tabs.calibrate.configure.min-max-conditions.included-max')}
                          control={(
                            <Checkbox
                              value={condition.maxIncluded ? 2 : 0}
                              name={t('clean-calibrate.yield-popup.tabs.calibrate.configure.min-max-conditions.included-max')}
                              onChange={(_e: unknown, value: boolean) => onConditionMaxIncludedChange(value, index)}
                            />
                          )}
                        />
                      )
                  }
                </div>
                <IconButton
                  className="min-max-conditions-item__delete-button"
                  size="small"
                  onClick={() => onConditionDelete(index)}
                >
                  <DeleteSharpIcon />
                </IconButton>
              </div>
            );
          })
        }
      </div>
    </div>
  );
}

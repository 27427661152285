import i18n from 'i18next';

import { formatAcquisitionDate } from './satellite';
import {
  getAreaUnitLabel,
  getCopyName,
} from '.';
import {
  convertStringToNumber,
} from './markup';
import { comparator } from './functions/utils/string';
import {
  AnalysisMapType,
  VectorAnalysisMapType,
} from './constants/entities/vectorAnalysisMap';
import { ProductUnit } from './constants/units/productUnit';
import { ASSET_TYPE_TO_ASSET_GROUP, AssetType } from './constants/entities/asset';

const includesVolDYieldAttribute = (yieldAttribute) => {
  return yieldAttribute && yieldAttribute.toLowerCase().includes('vol_d');
};

const getHeaders = (attributesSample, areaUnit, hasSum, withRates, withSelects) => {
  const defaultHeaders = [];

  if (withSelects) {
    defaultHeaders.push({
      id: '_select',
      isSelect: true,
    });
  }

  defaultHeaders.push(...[
    {
      id: 'color',
      label: i18n.t('zones-ops.multi-layer.steps.4.results-table.color'),
      align: 'left',
    },
    {
      id: 'id',
      label: 'ID',
      align: 'right',
    },
    {
      id: 'avg',
      label: i18n.t('zones-ops.multi-layer.steps.4.results-table.avg'),
      align: 'right',
    },
    {
      id: 'area',
      label: getAreaUnitLabel(areaUnit),
      align: 'right',
    },
    {
      id: 'min',
      label: i18n.t('zones-ops.multi-layer.steps.4.results-table.min'),
      align: 'right',
    },
    {
      id: 'max',
      label: i18n.t('zones-ops.multi-layer.steps.4.results-table.max'),
      align: 'right',
    },
  ]);

  [
    'median',
    'std',
    'sum',
  ].forEach((col) => {
    if (attributesSample[col] == null) {
      return;
    }

    defaultHeaders.push({
      id: col,
      label: i18n.t(`zones-ops.multi-layer.steps.4.results-table.${col}`),
      align: 'right',
    });
  });

  if (hasSum && !defaultHeaders.some(({ id }) => id === 'sum')) {
    defaultHeaders.push(
      {
        id: 'sum',
        label: i18n.t('zones-ops.multi-layer.steps.4.results-table.sum'),
        align: 'right',
      },
    );
  }

  if (withRates) {
    attributesSample.ratesHeaders.forEach((header, ind) => {
      defaultHeaders.push({
        id: `${ind}`,
        label: header,
        align: 'right',
        isRate: true,
      });
    });
  }

  return defaultHeaders;
};

const getZonesMapRows = (headers, features, colors, selectedItems) => {
  const totalArea = features.reduce((acc, curr) => {
    return acc + curr.properties.attributes.area;
  }, 0);

  return features.map(({
    properties: {
      attributes,
      zone,
    },
  }) => {
    return {
      id: zone,
      data: headers.reduce((acc, {
        id,
        isRate,
        isSelect,
      }) => {
        if (isSelect) {
          acc[id] = {
            isCheckbox: true,
            checked: selectedItems.includes(zone) ? 2 : 0,
          };
        } else if (isRate) {
          const value = attributes.rates[id];

          acc[id] = {
            text: value != null ? value.toFixed(3) : '-',
          };
        } else if (id === 'color') {
          acc[id] = {
            color: colors[zone - 1],
          };
        } else if (id === 'area') {
          const value = attributes.area;
          let text = '';
          let secondaryText = '';
          if (value) {
            text = value.toFixed(2);
            secondaryText = `${(100 * (value / totalArea)).toFixed(2)}%`;
          }
          acc[id] = {
            text,
            secondaryText,
          };
        } else if (id === 'id') {
          acc[id] = {
            text: zone,
          };
        } else {
          const value = attributes[id];

          acc[id] = {
            text: value != null ? value.toFixed(3) : '-',
          };
        }

        return acc;
      }, {}),
    };
  });
};

export const PURPOSES_I18N_KEY_LABEL_MAP = {
  [VectorAnalysisMapType.general]: 'general.controls.purpose-options.general',
  [VectorAnalysisMapType.spraying]: 'general.controls.purpose-options.spraying',
  [VectorAnalysisMapType.seeding]: 'general.controls.purpose-options.seeding',
  [VectorAnalysisMapType.fertilizing]: 'general.controls.purpose-options.fertilizing',
  [VectorAnalysisMapType.irrigation]: 'general.controls.purpose-options.irrigation',
};

export const TYPE_I18N_KEY_LABEL_MAP = {
  [AnalysisMapType.multilayers]: 'general.labels.multi',
  [AnalysisMapType.satellite]: 'general.labels.sat',
  [AnalysisMapType.topography]: 'general.labels.topo',
  [AnalysisMapType.yield]: 'general.labels.yield',
  [AnalysisMapType.asApplied]: 'general.labels.as-applied',
  [AnalysisMapType.soil]: 'general.labels.soil',
  [AnalysisMapType.zoneOperations]: 'general.labels.intersect',
  [AnalysisMapType.drawn]: 'general.labels.drawn',
  [AnalysisMapType.ramap]: '',
};

export const TYPE_I18N_KEY_FULL_NAME_MAP = {
  [AnalysisMapType.multilayers]: 'zones-map.data-types.multi',
  [AnalysisMapType.satellite]: 'zones-map.data-types.sat',
  [AnalysisMapType.topography]: 'zones-map.data-types.topo',
  [AnalysisMapType.yield]: 'general.shared.yield-data',
  [AnalysisMapType.asApplied]: 'zones-map.data-types.as-applied',
  [AnalysisMapType.soil]: 'general.shared.soil-data',
  [AnalysisMapType.zoneOperations]: 'zones-map.data-types.intersect',
  [AnalysisMapType.drawn]: 'zones-map.data-type.drawn',
  [AnalysisMapType.ramap]: '',
};

export const getZonesMapData = ({
  features,
  colors,
  areaUnit,
  selectedItems,
  withRates,
  withSelects,
}) => {
  const { attributes } = features[0].properties;
  // This property is needed to set the visibility of "Sum" column
  // It has to be shown only if index property includes 'vol_d'
  // This logics is inherited from the previous app
  const hasVolD = includesVolDYieldAttribute(attributes.index);
  const headers = getHeaders(attributes, areaUnit, hasVolD, withRates, withSelects);

  return {
    headers,
    rows: getZonesMapRows(headers, features, colors, selectedItems),
  };
};

export const getHexColorCodes = (geoMaps) => {
  const zonesGeoMap = (geoMaps || []).find((geoMap) => {
    return geoMap.shortName === 'zones';
  }) || {};

  return zonesGeoMap.hexColorCodes || [];
};

export const applyUpdates = (zonesMap, updates) => {
  const {
    zonesMapGeojson,
    colors,
  } = updates;

  if (!zonesMap) {
    return zonesMap;
  }

  return {
    ...zonesMap,
    geoMaps: zonesMap.geoMaps.map((geoMap) => {
      if (geoMap.shortName === 'zones') {
        return {
          ...geoMap,
          hexColorCodes: colors || geoMap.hexColorCodes,
        };
      }

      return geoMap;
    }),
    zonesMapGeojson: zonesMapGeojson || zonesMap.zonesMapGeojson,
  };
};

export const prepareDataLayers = (dataLayers, field) => {
  let uuidToAcquisitionDateMap;

  if (dataLayers.some(({ type }) => type === AssetType.satelliteImage)) {
    uuidToAcquisitionDateMap = field.satelliteImages.reduce((acc, image) => {
      acc[image.uuid] = image.satelliteImage.acquisitionDate;

      return acc;
    }, {});
  }

  return dataLayers.map((dataLayer) => {
    const { type } = dataLayer;

    switch (type) {
      case AssetType.satelliteImage:
        return {
          ...(dataLayer.weight ? { weight: dataLayer.weight } : {}),
          type,
          index: dataLayer.index,
          dateStrings: dataLayer.satelliteImageUuids.map((uuid) => (
            formatAcquisitionDate(uuidToAcquisitionDateMap[uuid])
          )),
        };
      case AssetType.topographyMap:
      case AssetType.yieldDataset:
      case AssetType.asAppliedDataset:
      case AssetType.soilDataset: {
        const key = ASSET_TYPE_TO_ASSET_GROUP[type];
        const dataset = field[key].find((d) => d.uuid === dataLayer.datasetUuid);

        return {
          ...(dataLayer.weight ? { weight: dataLayer.weight } : {}),
          type,
          name: dataset?.name || '',
          attribute: dataLayer.attribute || '',
          geometryType: dataset?.geometryType,
        };
      }
      default:
        return {};
    }
  });
};

export const updateFeature = (
  features,
  featureIndex,
  updater = () => {},
) => {
  return features.map((feature, index) => {
    if (index === featureIndex) {
      const updatedRates = feature.properties.attributes.rates.map(updater);

      return {
        ...feature,
        properties: {
          ...feature.properties,
          attributes: {
            ...feature.properties.attributes,
            rates: updatedRates,
          },
        },
      };
    }

    return feature;
  });
};

export const updateFeatures = (
  features,
  updater = () => {},
) => {
  return features.map((feature) => {
    const updatedAttributes = updater(feature.properties.attributes);

    return {
      ...feature,
      properties: {
        ...feature.properties,
        attributes: updatedAttributes,
      },
    };
  });
};

export const extendToLength = (data, length, defaultValue = 0) => {
  return Array.from({ length }, (_v, ind) => {
    return (data || [])[ind] || defaultValue;
  });
};

export const getRatesData = (attributes, prop = 'ratesHeaders') => {
  if (attributes && attributes.features) {
    return [
      ...attributes.features.reduce((acc, feature) => {
        const data = feature.properties.attributes?.[prop];

        (data || []).forEach(acc.add, acc);

        return acc;
      }, new Set()),
    ];
  }

  return [];
};

export const sortGeojsonFeatures = (features = []) => {
  return features.sort((prev, curr) => {
    const {
      properties: {
        zone: prevZone,
      },
    } = prev;
    const {
      properties: {
        zone: currZone,
      },
    } = curr;

    if (prevZone && currZone) {
      return prevZone - currZone;
    }
    return 0;
  });
};

/**
 * Extends `ratesPrices` and `ratesUnits` array to be the same length as `ratesHeaders`
 */
export const prepareZonesMapJson = (geojson) => {
  let result;

  try {
    const ratesCount = getRatesData(geojson).length;

    result = {
      ...geojson,
      features: sortGeojsonFeatures(geojson.features.map((feature) => {
        const {
          ratesPrices,
          ratesUnits,
        } = feature.properties.attributes;

        return {
          ...feature,
          properties: {
            ...feature.properties,
            attributes: {
              ...feature.properties.attributes,
              ratesPrices: extendToLength(ratesPrices, ratesCount),
              ratesUnits: extendToLength(ratesUnits, ratesCount, ProductUnit.kgHa),
            },
          },
        };
      })),
    };
  } catch (error) {
    console.error('Unable to prepare geojson for rates editor. ', error);
    result = geojson;
  }

  return result;
};

export const calculateTotalProductVolumes = (attributes) => {
  if (attributes && attributes.features) {
    return attributes.features.reduce((acc, { properties }) => {
      const {
        attributes: {
          area,
          rates = [],
        },
      } = properties;

      return rates.map((rate, rateIndex) => {
        return area * convertStringToNumber(rate) + (acc[rateIndex] || 0);
      });
    }, []);
  }

  return [];
};

export const calculateCostPerProduct = (prices, totalProductVolumes) => {
  return totalProductVolumes.map((total, totalIndex) => {
    return total * (convertStringToNumber(prices[totalIndex]));
  });
};

export const calculateTotalProductCost = (costsPerProduct) => {
  return costsPerProduct.reduce((acc, cost) => acc + cost, 0);
};

export const getPurposeItems = () => [
  {
    value: VectorAnalysisMapType.general,
    title: i18n.t(PURPOSES_I18N_KEY_LABEL_MAP[VectorAnalysisMapType.general]),
  },
  {
    value: VectorAnalysisMapType.spraying,
    title: i18n.t(PURPOSES_I18N_KEY_LABEL_MAP[VectorAnalysisMapType.spraying]),
  },
  {
    value: VectorAnalysisMapType.seeding,
    title: i18n.t(PURPOSES_I18N_KEY_LABEL_MAP[VectorAnalysisMapType.seeding]),
  },
  {
    value: VectorAnalysisMapType.fertilizing,
    title: i18n.t(PURPOSES_I18N_KEY_LABEL_MAP[VectorAnalysisMapType.fertilizing]),
  },
  {
    value: VectorAnalysisMapType.irrigation,
    title: i18n.t(PURPOSES_I18N_KEY_LABEL_MAP[VectorAnalysisMapType.irrigation]),
  },
];

export const getTypeItems = () => {
  const items = [
    {
      value: AnalysisMapType.satellite,
      title: i18n.t(TYPE_I18N_KEY_LABEL_MAP[AnalysisMapType.satellite]),
    },
    {
      value: AnalysisMapType.yield,
      title: i18n.t(TYPE_I18N_KEY_LABEL_MAP[AnalysisMapType.yield]),
    },
    {
      value: AnalysisMapType.asApplied,
      title: i18n.t(TYPE_I18N_KEY_LABEL_MAP[AnalysisMapType.asApplied]),
    },
    {
      value: AnalysisMapType.soil,
      title: i18n.t(TYPE_I18N_KEY_LABEL_MAP[AnalysisMapType.soil]),
    },
    {
      value: AnalysisMapType.topography,
      title: i18n.t(TYPE_I18N_KEY_LABEL_MAP[AnalysisMapType.topography]),
    },
    {
      value: AnalysisMapType.multilayers,
      title: i18n.t(TYPE_I18N_KEY_LABEL_MAP[AnalysisMapType.multilayers]),
    },
    {
      value: AnalysisMapType.zoneOperations,
      title: i18n.t(TYPE_I18N_KEY_LABEL_MAP[AnalysisMapType.zoneOperations]),
    },
    {
      value: AnalysisMapType.drawn,
      title: i18n.t(TYPE_I18N_KEY_LABEL_MAP[AnalysisMapType.drawn]),
    },
  ];

  return items.sort((a, b) => comparator(a.title, b.title));
};

export const ISOXML_STATUSES = {
  INVALID: 'INVALID',
  UNSUPPORTED_ASSET: 'UNSUPPORTED_ASSET',
  VALID: 'VALID',
};

export const ISOXML_PURPOSE_TO_PRODUCT_UNITS = {
  [VectorAnalysisMapType.seeding]: [
    ProductUnit.kgHa,
    ProductUnit.lbAc,
    ProductUnit.seedsHa,
    ProductUnit.seedsAc,
    ProductUnit.seedsM2,
  ],
  [VectorAnalysisMapType.spraying]: [
    ProductUnit.lHa,
    ProductUnit.lAc,
    ProductUnit.galAc,
  ],
  [VectorAnalysisMapType.fertilizing]: [
    ProductUnit.lHa,
    ProductUnit.lAc,
    ProductUnit.galAc,
    ProductUnit.kgHa,
    ProductUnit.kgAc,
    ProductUnit.kgM2,
    ProductUnit.lbAc,
  ],
};

export const calculateIsoxmlStatusValidAssets = (assets = []) => {
  const status = new Set();
  const validAssets = [];

  assets.forEach((asset) => {
    if (!asset.attributes || !asset.type) {
      status.add(ISOXML_STATUSES.UNSUPPORTED_ASSET);

      return;
    }

    const ratesAmount = asset.attributes.features[0].properties.attributes.ratesHeaders.length;
    const { ratesUnits } = asset.attributes.features[0].properties.attributes;
    const unitsMatched = ratesUnits.every((unit) => {
      return ISOXML_PURPOSE_TO_PRODUCT_UNITS[asset.type]
      && ISOXML_PURPOSE_TO_PRODUCT_UNITS[asset.type].includes(unit);
    });

    if (
      ratesAmount > 0
      && ratesAmount === ratesUnits.length
      && (
        asset.type === VectorAnalysisMapType.seeding
        || asset.type === VectorAnalysisMapType.spraying
        || asset.type === VectorAnalysisMapType.fertilizing
      )
      && unitsMatched
    ) {
      status.add(ISOXML_STATUSES.VALID);
      validAssets.push(asset);
    } else {
      status.add(ISOXML_STATUSES.INVALID);
    }
  });

  return { status, validAssets };
};

export const extractVamapSettings = (vamap) => {
  let result = {};

  if (vamap.name) {
    result = {
      ...result,
      name: getCopyName(vamap.name, true),
    };
  }

  if (vamap.parameters?.numberOfZones) {
    result = {
      ...result,
      numberOfZones: vamap.parameters.numberOfZones,
    };
  }

  if (vamap.parameters?.dataClassificationType) {
    result = {
      ...result,
      dataClassificationType: vamap.parameters.dataClassificationType,
    };
  }

  if (vamap.parameters?.polygonMinArea) {
    result = {
      ...result,
      polygonMinArea: vamap.parameters.polygonMinArea,
    };
  }

  return result;
};

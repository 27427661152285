import * as amplitude from '@amplitude/analytics-browser';

import { getAmplitudeKey } from '../functions/utils/vendorConfigs/amplitude';

export default class Amplitude {
  static configure() {
    const apiKey = getAmplitudeKey();

    if (apiKey) {
      amplitude.init(apiKey, {
        autocapture: true,
        serverZone: 'EU',
      });
    }
  }

  static initUser(identifiers: { id?: string; email?: string }) {
    const identifyEvent = new amplitude.Identify();

    if (identifiers.id) {
      amplitude.setUserId(identifiers.id);
    }
    if (identifiers.email) {
      identifyEvent.setOnce('email', identifiers.email);
    }
    amplitude.identify(identifyEvent);
  }
}

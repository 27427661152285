import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getZonesMapsTableRows } from '../../helpers/functions/tableRows';
import {
  deleteZonesMap,
  loadMoreZonesMaps,
  renameZonesMap,
  tryTogglePageRowsSelection,
  tryToggleRowSelection,
  updateFilters,
} from '../../zonesMapsSlice';
import { openPopup } from '../../../popups/popupsSlice';
import { prepareCloneWorkflow } from '../../../cloneZonesMap/cloneZonesMapSlice';
import { openExportZonesMapPopup } from '../../../../exportData/exportDataSlice';
import { ZonesOpsWorkflow } from '../../../zonesOps/helpers/constants/workflows';
import { getZonesOpsLink } from '../../../../../helpers/navigation';
import { getRowsSelection } from '../../../tableView/helpers/functions/rows';
import { useGetFarmsQuery } from '../../../../farms/farmsAPI';
import {
  selectIsLoaded,
  selectLabelsExpanded,
  selectLastEvaluatedKey,
  selectSelectionMode,
  selectZonesMaps,
} from '../../zonesMapsSelectors';
import { selectAreaUnit, selectApiKey } from '../../../../user/userSelectors';
import useDidMount from '../../../../../hooks/useDidMount';
import ZonesMapsTableComponent from '../../components/Table';
import { getTableColumns } from '../../helpers/functions/tableColumns';
import { DefaultZonesMapTableRow } from '../../types/tableRow';
import { RowMenuItem } from '../../../tableView/types/row';
import AmplitudeAnalytics from '../../../../../helpers/classes/amplitudeAnalytics';
import useCurrentPage from '../../../../../hooks/useCurrentPage';
import { ExportType } from '../../../../exportData/helpers/constants/exportTypes';

export default function ZonesMapsTable() {
  const dispatch = useDispatch();
  const history = useHistory();
  const page = useCurrentPage();

  const zonesMaps = useSelector(selectZonesMaps);
  const apiKey = useSelector(selectApiKey);
  const areaUnit = useSelector(selectAreaUnit);
  const isZonesMapsLoaded = useSelector(selectIsLoaded);
  const lastEvaluatedKey = useSelector(selectLastEvaluatedKey);
  const selectionMode = useSelector(selectSelectionMode);
  const labelsExpanded = useSelector(selectLabelsExpanded);

  const {
    isFetching: isFarmsFetching,
  } = useGetFarmsQuery();

  const handleLoadMoreRows = () => {
    dispatch(loadMoreZonesMaps());
  };

  const handleMenuItemClick = ({ id }: RowMenuItem, row: DefaultZonesMapTableRow) => {
    if (id === 'clone') {
      dispatch(prepareCloneWorkflow({
        vamap: row.data,
        farmUuid: row._farmUuid,
        fieldUuid: row._fieldUuid,
      }));
      history.push(getZonesOpsLink(
        row._farmUuid,
        row._fieldUuid,
        ZonesOpsWorkflow.cloneZonesMap,
        true,
      ));
    } else if (id === 'delete') {
      dispatch(openPopup({
        type: 'delete-zones-map',
        entityName: row.name.title,
        shouldDisplayPinsWarning: row.shouldDisplayPinsWarning,
        onConfirm: () => {
          // @ts-expect-error
          dispatch(deleteZonesMap({
            asset: row.data,
            fieldUuid: row._fieldUuid,
          }));
        },
      }));
    } else if (id === 'rename') {
      dispatch(openPopup({
        type: 'rename-zones-map',
        entityName: row.name.title,
        onConfirm: (name: string) => {
          // @ts-expect-error
          dispatch(renameZonesMap({
            asset: row.data,
            fieldUuid: row._fieldUuid,
            name,
          }));
        },
      }));
    }
  };

  const handleHeadCheckboxClick = (checked: boolean) => {
    dispatch(tryTogglePageRowsSelection(checked));
  };

  const handleRowCheckboxClick = (row: DefaultZonesMapTableRow) => {
    dispatch(tryToggleRowSelection(row._uuid));
  };

  const handleExportButtonClick = (row: DefaultZonesMapTableRow) => {
    dispatch((openExportZonesMapPopup as any)({
      zonesMaps: [{
        uuid: row._uuid,
        name: row.name.title,
        fieldUuid: row._fieldUuid,
        attributes: row._attributes,
        type: row._type,
      }],
      archiveName: row.name.title,
      onExport: (type: ExportType) => {
        if (type === ExportType.exportToJDAsFiles) {
          AmplitudeAnalytics.trackEntityExportedToJD({
            page,
            entityType: 'zonesMap',
            exportType: 'asFiles',
          });
        }
      },
    }));
  };

  const handleRatesLineClick = (row: DefaultZonesMapTableRow, lineIndex: number) => {
    // TODO: When rates content length > 1 first line is 'Preview' (to be refactored)
    if (row.rates.length > 1 && lineIndex === 0) {
      dispatch(openPopup({
        type: 'rates-preview',
        zonesMapName: row.name.title,
        attributes: row._attributes,
        geoMaps: row._geoMaps,
        farmUuid: row._farmUuid,
        fieldUuid: row._fieldUuid,
        uuid: row._uuid,
        totalArea: row._area,
        areaUnit,
        onExportZonesMap: () => {
          dispatch((openExportZonesMapPopup as any)({
            zonesMaps: [{
              uuid: row._uuid,
              fieldUuid: row._fieldUuid,
              attributes: row._attributes,
              type: row._type,
            }],
            archiveName: row.name.title,
            onExport: (type: ExportType) => {
              if (type === ExportType.exportToJDAsFiles) {
                AmplitudeAnalytics.trackEntityExportedToJD({
                  page,
                  entityType: 'zonesMap',
                  exportType: 'asFiles',
                });
              }
            },
          }));
        },
      }));
    }
  };

  useDidMount(() => {
    dispatch(updateFilters());
  });

  const rows = getZonesMapsTableRows({
    vamaps: zonesMaps,
    apiKey,
  });
  const rowsSelection = getRowsSelection(rows);
  const columns = getTableColumns({
    selectionMode,
    rowsSelection,
    onHeadCheckboxClick: handleHeadCheckboxClick,
    onRowCheckboxClick: handleRowCheckboxClick,
    onRatesLineClick: handleRatesLineClick,
    onExportButtonClick: handleExportButtonClick,
    onMenuItemClick: handleMenuItemClick,
  });

  return (
    <ZonesMapsTableComponent
      loading={!isZonesMapsLoaded || isFarmsFetching}
      rows={rows}
      columns={columns}
      loadComplete={!lastEvaluatedKey}
      labelsExpanded={labelsExpanded}
      onLoadMoreRows={handleLoadMoreRows}
    />
  );
}

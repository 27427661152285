export const PREDEFINED_PREFIXES = {
  search: '_search',
};

export const PREDEFINED_VALUES = {
  empty: '_empty',
  allValues: '_allValues',
};

export const getSelectedOptionByUuid = (option, value) => {
  return option.uuid === value.uuid;
};

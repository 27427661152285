import {
  useAppDispatch,
  useAppSelector,
} from '../../../../app/store/helpers/functions';
import {
  reset as resetSingleLayer,
} from '../../createSingleLayerAnalysis/createSingleLayerAnalysisSlice';
import {
  reset as resetFieldPotential,
} from '../../createFieldPotentialZones/createFieldPotentialZonesSlice';
import {
  reset as resetStability,
} from '../../createStabilityZones/createStabilityZonesSlice';
import {
  reset as resetCrossLayer,
} from '../../crossLayer/crossLayerSlice';
import {
  reset as resetMultiLayer,
} from '../../createMultiLayerAnalysis/createMultiLayerAnalysisSlice';
import {
  reset as resetDrawManuallyWorkflow,
} from '../../drawManuallyWorkflow/drawManuallyWorkflowSlice';
import {
  reset as resetCloneZonesMap,
} from '../../cloneZonesMap/cloneZonesMapSlice';
import {
  reset as resetEquationBasedAnalysis,
} from '../../createEquationBasedAnalysis/createEquationBasedAnalysisSlice';
import { ZonesOpsWorkflow } from '../helpers/constants/workflows';
import { selectWorkflow } from '../zonesOpsSelectors';
import { selectWorkflow as selectCloneZonesMapWorkflow } from '../../cloneZonesMap/cloneZonesMapSelectors';

export default function useResetZonesMapWorkflow() {
  const dispatch = useAppDispatch();
  const workflow = useAppSelector(selectWorkflow);
  const cloneZonesMapWorkflow = useAppSelector(selectCloneZonesMapWorkflow);

  return () => {
    if (workflow === ZonesOpsWorkflow.cloneZonesMap) {
      dispatch(resetCloneZonesMap());
    }

    const currentWorkflow = workflow === ZonesOpsWorkflow.cloneZonesMap
      ? cloneZonesMapWorkflow
      : workflow;

    if (currentWorkflow === ZonesOpsWorkflow.createSingleLayerAnalysis) {
      dispatch(resetSingleLayer());
    } else if (currentWorkflow === ZonesOpsWorkflow.createFieldPotentialZones) {
      dispatch(resetFieldPotential());
    } else if (currentWorkflow === ZonesOpsWorkflow.createStabilityZones) {
      dispatch(resetStability());
    } else if (currentWorkflow === ZonesOpsWorkflow.crossLayer) {
      dispatch(resetCrossLayer());
    } else if (currentWorkflow === ZonesOpsWorkflow.createMultiLayerAnalysis) {
      dispatch(resetMultiLayer());
    } else if (currentWorkflow === ZonesOpsWorkflow.drawManually) {
      dispatch(resetDrawManuallyWorkflow());
    } else if (currentWorkflow === ZonesOpsWorkflow.equationBased) {
      dispatch(resetEquationBasedAnalysis());
    }
  };
}

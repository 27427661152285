import type { TransformedEquationMap } from '../../../../../helpers/types/equationMap';

export const ascSumComparator = (
  equationMapA?: TransformedEquationMap,
  equationMapB?: TransformedEquationMap,
) => {
  if (equationMapA?.statistics == null || equationMapB?.statistics == null) {
    return 0;
  }

  return equationMapA.statistics.sum - equationMapB.statistics.sum;
};

export const descSumComparator = (
  equationMapA?: TransformedEquationMap,
  equationMapB?: TransformedEquationMap,
) => {
  if (equationMapA?.statistics == null || equationMapB?.statistics == null) {
    return 0;
  }

  return equationMapB.statistics.sum - equationMapA.statistics.sum;
};

export const ascAvgComparator = (
  equationMapA?: TransformedEquationMap,
  equationMapB?: TransformedEquationMap,
) => {
  if (equationMapA?.statistics == null || equationMapB?.statistics == null) {
    return 0;
  }

  return equationMapA.statistics.avg - equationMapB.statistics.avg;
};

export const descAvgComparator = (
  equationMapA?: TransformedEquationMap,
  equationMapB?: TransformedEquationMap,
) => {
  if (equationMapA?.statistics == null || equationMapB?.statistics == null) {
    return 0;
  }

  return equationMapB.statistics.avg - equationMapA.statistics.avg;
};

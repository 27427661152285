import React, { useMemo } from 'react';

import CheckboxItem from '../../../TreeView/CheckboxItem';
import NamedItem from '../../../Items/NamedItem';
import ItemActions from '../../../ItemActions';
import SimpleItem from '../../../TreeView/SimpleItem';
import type { Mode, DataLayersTreeNode } from '../../../../types/dataLayersTree';
import { getThreeDimensionalMapActions } from '../../../../helpers/functions/dataLayersTreeNodesActions';
import { AssetGroupType } from '../../../../../../../helpers/constants/entities/asset';
import type { TransformedThreeDimensionalMap } from '../../../../../../../helpers/types/threeDimensionalMap';

export default function ThreeDimensionalMapNode({
  id,
  threeDMap,
  mode,
  checked,
  treeNodeGetter,
  showItemMenu,
  offset,
  selectedItemUuid,
  selectedItemGroupType,
  onCheckboxClick,
  onMenuItemClick,
  onClick,
}: {
  id: string,
  threeDMap: TransformedThreeDimensionalMap,
  mode: Mode,
  checked: Record<string, number>,
  treeNodeGetter: () => DataLayersTreeNode | null,
  showItemMenu: boolean,
  offset: number,
  selectedItemUuid?: string,
  selectedItemGroupType?: string | null,
  onCheckboxClick: (v: Record<string, number>, item: DataLayersTreeNode) => void,
  onMenuItemClick: (v: string) => void,
  onClick: () => void,
}) {
  let result;
  const menuItems = useMemo(() => {
    return getThreeDimensionalMapActions();
  }, []);
  const item = (
    <>
      <NamedItem
        item={{
          name: threeDMap.name,
        }}
      />
      {
        showItemMenu
          && (
            <ItemActions
              menuItems={menuItems}
              onMenuItemClick={onMenuItemClick}
            />
          )
      }
    </>
  );
  const isItemSelected = threeDMap.uuid === selectedItemUuid
    && selectedItemGroupType === AssetGroupType.threeDimensionalMaps;

  if (mode === 'multiSelect') {
    result = (
      <CheckboxItem
        itemId={id}
        treeNodeGetter={treeNodeGetter}
        checked={checked}
        isSelected={isItemSelected}
        offset={offset}
        onCheckboxClick={onCheckboxClick}
        onClick={onClick}
      >
        {item}
      </CheckboxItem>
    );
  } else {
    result = (
      <SimpleItem
        onClick={onClick}
        isSelected={isItemSelected}
        offset={offset}
      >
        {item}
      </SimpleItem>
    );
  }

  return result;
}

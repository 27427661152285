import { ModeConfig } from '../../../dataLayersView/types/dataLayersTree';

export const COMPARE_LAYERS_LIMIT = 4;

export const compareLayersDataLayersTreeMode: ModeConfig = {
  satelliteImages: 'multiSelect',
  vectorAnalysisMaps: 'multiSelect',
  equationMaps: 'multiSelect',
  soilDatasets: 'multiSelect',
  yieldDatasets: 'multiSelect',
  asAppliedDatasets: 'multiSelect',
  topographyMaps: 'multiSelect',
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import clsx from 'clsx';

import FilterIcon from '../FilterIcon';

import './index.scss';

export default function ExpandFiltersButton({
  isExpanded,
  onClick,
}: {
  isExpanded: boolean,
  onClick: (isExpanded: boolean) => void
}) {
  const { t } = useTranslation();

  return (
    <Button
      startIcon={(
        <FilterIcon className='expand-filter-button__icon' />
      )}
      classes={{
        root: clsx(
          'expand-filter-button',
          {
            'expand-filter-button-open': isExpanded,
          },
        ),
        startIcon: 'expand-filter-button__icon',
      }}
      onClick={(event) => {
        event.stopPropagation();
        onClick(!isExpanded);
      }}
    >
      {isExpanded ? t('general.controls.filters.hide-filters') : t('general.controls.filters.show-filters')}
    </Button>
  );
}

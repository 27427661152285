import {
  DEFAULT_GEOMAP_TYPE_PREFIX,
  RAW_TYPE_PREFIX,
  GeoMapTypeOption,
} from '../../../features/satelliteImages/helpers/constants/geoMapType';
import { DEFAULT_SATELLITE_INDEX } from '../../../features/satelliteImages/helpers/constants/satelliteImageIndex';
import { PLANET_PROVIDER } from '../../../features/satelliteImages/helpers/constants';

export const isValid = (image) => {
  return image?.status === 'VALIDATED';
};

export const isPlanetImage = (image) => {
  return image?.satelliteImage?.provider === PLANET_PROVIDER;
};

export const isPlanetImageAvailable = (image) => {
  return isPlanetImage(image)
    && image?.status === 'VALIDATED';
};

export const isPlanetImageOrdered = (image) => {
  return isPlanetImage(image)
    && image?.status === 'ORDERED';
};

export const isPlanetImageAcquired = (image) => {
  return isPlanetImage(image)
    && image?.status === 'PUBLISHED';
};

export const comparator = (anImageA, anImageB) => {
  const aDate = new Date(anImageA.satelliteImage.acquisitionDate);
  const bDate = new Date(anImageB.satelliteImage.acquisitionDate);

  return bDate - aDate;
};

const getPrefix = ({
  isCrop,
  isContrast,
  isRawType,
}) => {
  const CROPPED_PREFIX = 'cropped_';
  const CONTRAST_PREFIX = 'contrast_';
  let result = DEFAULT_GEOMAP_TYPE_PREFIX;

  if (isCrop) {
    result = CROPPED_PREFIX;
  } if (isContrast) {
    result = CONTRAST_PREFIX;
  }

  if (isRawType) {
    result = RAW_TYPE_PREFIX + result;
  }

  return result;
};

export const getSatelliteImageViewProps = ({
  image,
  geoMapType = GeoMapTypeOption.default,
  viewType = '',
  isRawType = false,
}) => {
  const isCrop = geoMapType === GeoMapTypeOption.crop;
  const isContrast = geoMapType === GeoMapTypeOption.contrast;
  let prefix = getPrefix({
    isCrop,
    isContrast,
    isRawType,
  });
  const hasPrefixedGeoMaps = image.geoMaps.some(({ shortName }) => {
    return shortName.startsWith(prefix)
      && (prefix !== RAW_TYPE_PREFIX || shortName.split('_').length === 2);
  });

  if (!hasPrefixedGeoMaps) {
    prefix = DEFAULT_GEOMAP_TYPE_PREFIX;
  }

  const satelliteVisuals = image.geoMaps.filter(({ shortName }) => {
    if (prefix === DEFAULT_GEOMAP_TYPE_PREFIX) {
      return shortName.split('_').length === 1;
    }

    if (prefix === RAW_TYPE_PREFIX) {
      return shortName.startsWith(prefix) && shortName.split('_').length === 2;
    }

    return shortName.startsWith(prefix);
  })
    .map(({ shortName }) => {
      const name = shortName.split('_')
        .pop();

      return name;
    });
  const defaultValue = satelliteVisuals.some((vt) => {
    return vt === DEFAULT_SATELLITE_INDEX;
  })
    ? DEFAULT_SATELLITE_INDEX
    : satelliteVisuals[0];
  const selectedViewType = satelliteVisuals.some((vt) => {
    return vt === viewType;
  })
    ? viewType
    : defaultValue;

  return {
    viewTypes: satelliteVisuals,
    viewType: selectedViewType,
    viewValue: `${prefix}${selectedViewType || ''}`,
  };
};

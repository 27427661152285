import { API, graphqlOperation } from '@aws-amplify/api';

import { transformVectorAnalysisMaps } from '../../field/helpers/functions/assets';
import { getZonesMapsFilter } from './helpers/functions/backend';
import getZonesMapsListQuery from './graphql/queries/getZonesMapsList.gql';
import getZonesMapsListFields from './graphql/queries/getZonesMapsListFields.gql';

export const getZonesMapsList = async ({
  pageSize,
  lastEvaluatedKey,
  farm,
  field,
  type,
  purpose,
  labels,
  withRates,
  areaUnit,
}) => {
  const {
    data: {
      getZonesMaps: {
        lastEvaluatedKey: responseLastEvaluatedKey,
        zonesMaps: responseZonesMaps,
      },
    },
    errors,
  } = await API.graphql(graphqlOperation(getZonesMapsListQuery, getZonesMapsFilter({
    pageSize,
    lastEvaluatedKey,
    farm,
    field,
    type,
    purpose,
    labels,
    withRates,
    areaUnit,
  })))
    .catch((errorResp) => {
      return errorResp;
    });

  return {
    data: {
      lastEvaluatedKey: responseLastEvaluatedKey,
      zonesMaps: transformVectorAnalysisMaps(responseZonesMaps),
    },
    errors,
  };
};

export const getFieldsMap = async (fieldUuids, areaUnit) => {
  const {
    data: {
      getFarms,
    },
    errors,
  } = await API.graphql(graphqlOperation(getZonesMapsListFields, {
    fieldUuids,
    areaUnit,
  }))
    .catch((errorResp) => {
      return errorResp;
    });
  const { fieldsMap, farmsNamesMap } = getFarms.reduce((acc, frm) => {
    if (frm.fields.length !== 0) {
      frm.fields.forEach((fld) => {
        acc.fieldsMap.set(fld.uuid, fld);
      });

      acc.farmsNamesMap.set(frm.uuid, frm.name);
    }

    return acc;
  }, { fieldsMap: new Map(), farmsNamesMap: new Map() });

  return {
    data: {
      fieldsMap,
      farmsNamesMap,
    },
    errors: getFarms.length ? null : errors,
  };
};

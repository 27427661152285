import React from 'react';

import './index.scss';

const FieldPreview = ({
  url,
  alt,
}) => {
  return (
    <img
      className="field-preview"
      src={url}
      alt={alt}
    />
  );
};

export default FieldPreview;

import React from 'react';
import i18n from 'i18next';
import {
  endOfDay,
  isAfter,
  isBefore,
  startOfDay,
} from 'date-fns';

import {
  ASSET_TYPE_TO_I18N_KEY,
  AssetGroupType,
  AssetType,
} from '../../../../../helpers/constants/entities/asset';
import { AnalysisMapType } from '../../../../../helpers/constants/entities/vectorAnalysisMap';
import { TYPE_I18N_KEY_LABEL_MAP } from '../../../../../helpers/analysis';
import {
  formatDate,
  isDateValid,
} from '../../../../../helpers/functions/utils/date';
import { comparator } from '../../../../../helpers/functions/utils/string';
import {
  DEFAULT_PINS_GROUPS_FILTER,
  PINS_GROUPS_FILTER_KEYS_I18N_MAP,
  PinsGroupsFilterKey,
} from '../constants/pinsGroups';
import {
  SATELLITE_FILTER_KEYS_I18N_MAP,
  SatelliteFilterKey,
} from '../constants/satelliteImage';
import {
  DEFAULT_VAMAPS_FILTER,
  VAMAP_FILTER_KEYS_I18N_MAP,
  VectorAnalysisFilterKey,
} from '../constants/analysis';
import { SatelliteFilters } from '../../types/satelliteImage';
import { getCloudFilterOptions } from './satelliteImage';
import DefaultAssetGroupFilter from '../../components/DefaultAssetGroupFilter';
import ZonesMapFilter from '../../components/ZonesMapFilter';
import SatelliteFilter from '../../components/SatelliteFilter';
import { getVamapTypeFilterOptions } from './analysis';
import type { PinsGroupsFilters } from '../../types/pinsGroups';
import type { VectorAnalysisFilters } from '../../types/analysis';
import type { DefaultAssetGroupFilters } from '../../types/assetGroup';
import type { TransformedSatelliteImage } from '../../../../satelliteImages/types/satelliteImage';
import type { PinsGroup } from '../../../../pins/types';
import type { TransformedVectorAnalysisMap } from '../../../../../helpers/types/vectorAnalysisMap';
import { getPinsGroupsTypeFilterOptions } from './pinsGroups';
import {
  DEFAULT_ASSET_GROUP_FILTER_KEYS_I18N_MAP,
  DefaultAssetGroupFilterKey,
} from '../constants/assetGroup';

/**
 * groups consequent items for filter display and transforms to string
 *
 * @param {Object[]} items
 * @param {string} items[].title - name of the item, for result string construction
 * @param {string} items[].value - value of the item, for comparison
 */
const groupItems = (items: { title: string, value: number }[]) => {
  const sortedItems = [...(items || [])].sort((aItem, bItem) => comparator(String(aItem.value), String(bItem.value)));
  const groups = sortedItems.reduce((acc, curr) => {
    const lastGroup = acc[acc.length - 1];

    if (!lastGroup) {
      acc.push([curr]);
    } else if (lastGroup.length === 2) {
      const lastItem = lastGroup[1];

      if ((lastItem.value + 1) === curr.value) {
        lastGroup[1] = curr;
      } else {
        acc.push([curr]);
      }
    } else {
      const item = lastGroup[0];

      if ((item.value + 1) === curr.value) {
        lastGroup.push(curr);
      } else {
        acc.push([curr]);
      }
    }

    return acc;
  }, [] as { title: string, value: number }[][]);

  return groups.map((element) => {
    if (element.length === 2) {
      return `${element[0].title}-${element[1].title}`;
    }

    return element[0].title;
  });
};

export const getFilterValue = ({
  [AssetGroupType.satelliteImages]: satelliteFilter,
  [AssetGroupType.vectorAnalysisMaps]: vectorAnalysisMapsFilter,
  [AssetGroupType.pinsGroups]: pinsGroupsFilter,
  defaultFilter,
}: {
  [AssetGroupType.satelliteImages]?: SatelliteFilters,
  [AssetGroupType.vectorAnalysisMaps]?: VectorAnalysisFilters,
  [AssetGroupType.pinsGroups]?: PinsGroupsFilters,
  defaultFilter?: DefaultAssetGroupFilters,
}): { label:string, value: string }[] => {
  let result: ({ label:string, value: string } | null)[] = [];

  if (satelliteFilter) {
    result = Object.values(SatelliteFilterKey)
      .map((key) => {
        switch (key) {
          case SatelliteFilterKey.cloudFilterValue: {
            const options = getCloudFilterOptions();
            const value = options.find((option) => option.value === satelliteFilter[key]);

            if (!value) {
              return null;
            }

            return {
              label: i18n.t(SATELLITE_FILTER_KEYS_I18N_MAP[SatelliteFilterKey.cloudFilterValue]),
              value: value.title,
            };
          }
          case SatelliteFilterKey.monthFilterValue: {
            const value = satelliteFilter[SatelliteFilterKey.monthFilterValue];

            if (!value?.length) {
              return null;
            }

            return {
              label: i18n.t(SATELLITE_FILTER_KEYS_I18N_MAP[SatelliteFilterKey.monthFilterValue]),
              value: groupItems(value)
                .join(', '),
            };
          }
          case SatelliteFilterKey.yearFilterValue: {
            const value = satelliteFilter[SatelliteFilterKey.yearFilterValue];

            if (!value?.length) {
              return null;
            }

            return {
              label: i18n.t(SATELLITE_FILTER_KEYS_I18N_MAP[SatelliteFilterKey.yearFilterValue]),
              value: groupItems(value)
                .join(', '),
            };
          }
          case SatelliteFilterKey.providerFilterValue: {
            const value = satelliteFilter[SatelliteFilterKey.providerFilterValue];

            if (!value?.length) {
              return null;
            }

            return {
              label: i18n.t(SATELLITE_FILTER_KEYS_I18N_MAP[SatelliteFilterKey.providerFilterValue]),
              value: value.map(({ title }) => title)
                .join(', '),
            };
          }
          case SatelliteFilterKey.ndviFilterValue: {
            const value = satelliteFilter[SatelliteFilterKey.ndviFilterValue];

            return {
              label: i18n.t(SATELLITE_FILTER_KEYS_I18N_MAP[SatelliteFilterKey.ndviFilterValue]),
              value: i18n.t('general.controls.satellite-filters.from-to', {
                from: value[0],
                to: value[1],
              }),
            };
          }
          default:
            return null;
        }
      });
  } else if (vectorAnalysisMapsFilter) {
    result = Object.values(VectorAnalysisFilterKey)
      .map((key) => {
        switch (key) {
          case VectorAnalysisFilterKey.typeFilterValue: {
            const value = vectorAnalysisMapsFilter[VectorAnalysisFilterKey.typeFilterValue] as AnalysisMapType;

            if (value === DEFAULT_VAMAPS_FILTER[VectorAnalysisFilterKey.typeFilterValue]) {
              return null;
            }

            return {
              label: i18n.t(VAMAP_FILTER_KEYS_I18N_MAP[VectorAnalysisFilterKey.typeFilterValue]),
              value: i18n.t(TYPE_I18N_KEY_LABEL_MAP[value]),
            };
          }
          case VectorAnalysisFilterKey.nameFilterValue: {
            const name = vectorAnalysisMapsFilter[VectorAnalysisFilterKey.nameFilterValue];

            if (!name) {
              return null;
            }

            return {
              label: i18n.t(VAMAP_FILTER_KEYS_I18N_MAP[VectorAnalysisFilterKey.nameFilterValue]),
              value: name,
            };
          }
          default:
            return null;
        }
      });
  } else if (pinsGroupsFilter) {
    result = Object.values(PinsGroupsFilterKey)
      .map((key) => {
        switch (key) {
          case PinsGroupsFilterKey.typeFilterValue: {
            const value = pinsGroupsFilter[PinsGroupsFilterKey.typeFilterValue] as AssetType;

            if (value === DEFAULT_PINS_GROUPS_FILTER[PinsGroupsFilterKey.typeFilterValue]) {
              return null;
            }

            return {
              label: i18n.t(PINS_GROUPS_FILTER_KEYS_I18N_MAP[PinsGroupsFilterKey.typeFilterValue]),
              value: i18n.t(ASSET_TYPE_TO_I18N_KEY[value]),
            };
          }
          case PinsGroupsFilterKey.nameFilterValue: {
            const name = pinsGroupsFilter[PinsGroupsFilterKey.nameFilterValue];

            if (!name) {
              return null;
            }

            return {
              label: i18n.t(PINS_GROUPS_FILTER_KEYS_I18N_MAP[PinsGroupsFilterKey.nameFilterValue]),
              value: name,
            };
          }
          default:
            return null;
        }
      });
  } else if (defaultFilter) {
    result = Object.values(DefaultAssetGroupFilterKey)
      .map((key) => {
        switch (key) {
          case DefaultAssetGroupFilterKey.nameFilterValue: {
            const name = defaultFilter[DefaultAssetGroupFilterKey.nameFilterValue];

            if (!name) {
              return null;
            }

            return {
              label: i18n.t(DEFAULT_ASSET_GROUP_FILTER_KEYS_I18N_MAP[DefaultAssetGroupFilterKey.nameFilterValue]),
              value: name,
            };
          }
          case DefaultAssetGroupFilterKey.createdFrom: {
            const date = defaultFilter[DefaultAssetGroupFilterKey.createdFrom];

            if (!date) {
              return null;
            }

            return {
              label: i18n.t(DEFAULT_ASSET_GROUP_FILTER_KEYS_I18N_MAP[DefaultAssetGroupFilterKey.createdFrom]),
              value: formatDate(date),
            };
          }
          case DefaultAssetGroupFilterKey.createdTo: {
            const date = defaultFilter[DefaultAssetGroupFilterKey.createdTo];

            if (!date) {
              return null;
            }

            return {
              label: i18n.t(DEFAULT_ASSET_GROUP_FILTER_KEYS_I18N_MAP[DefaultAssetGroupFilterKey.createdTo]),
              value: formatDate(date),
            };
          }
          default:
            return null;
        }
      });
  }

  return result.filter((v) => !!v) as { label: string, value: string }[];
};

export const getFilters = (type: AssetGroupType, {
  satelliteImages,
  satelliteFilter,
  vectorAnalysisMaps,
  vectorAnalysisMapsFilter,
  pinsGroups,
  pinsGroupsFilter,
  equationMapFilter,
  onSatelliteFilterChange,
  onVectorAnalysisMapsFilterChange,
  onPinsGroupsFilterChange,
  onEquationMapFilterChange,
  onSatelliteFilterClear,
  onVectorAnalysisFilterClear,
  onPinsFilterClear,
  onEquationMapFilterClear,
  classes = {},
}: {
  satelliteImages?: TransformedSatelliteImage[],
  pinsGroups?: PinsGroup[],
  satelliteFilter?: SatelliteFilters,
  vectorAnalysisMaps?: TransformedVectorAnalysisMap[],
  vectorAnalysisMapsFilter?: VectorAnalysisFilters,
  pinsGroupsFilter?: PinsGroupsFilters,
  equationMapFilter?: DefaultAssetGroupFilters,
  onSatelliteFilterChange: (v: SatelliteFilters) => void,
  onVectorAnalysisMapsFilterChange:(v: VectorAnalysisFilters) => void,
  onPinsGroupsFilterChange:(v: PinsGroupsFilters) => void,
  onEquationMapFilterChange:(v: DefaultAssetGroupFilters) => void,
  onSatelliteFilterClear: () => void,
  onVectorAnalysisFilterClear:() => void,
  onPinsFilterClear:() => void,
  onEquationMapFilterClear:() => void,
  classes?: Partial<Record<AssetGroupType, Record<string, string>>>,
}) => {
  let result = null;

  if (type === AssetGroupType.satelliteImages && satelliteFilter) {
    result = (
      <SatelliteFilter
        classes={classes.satelliteImages}
        value={satelliteFilter}
        images={satelliteImages}
        onChange={onSatelliteFilterChange}
        onFiltersClear={onSatelliteFilterClear}
      />
    );
  } else if (type === AssetGroupType.vectorAnalysisMaps && vectorAnalysisMapsFilter) {
    const options = getVamapTypeFilterOptions(
      vectorAnalysisMaps,
      DEFAULT_VAMAPS_FILTER.typeFilterValue,
    );

    result = (
      <ZonesMapFilter
        classes={classes.vectorAnalysisMaps}
        value={vectorAnalysisMapsFilter}
        typeOptions={options}
        onChange={onVectorAnalysisMapsFilterChange}
        onFiltersClear={onVectorAnalysisFilterClear}
      />
    );
  } else if (type === AssetGroupType.pinsGroups && pinsGroupsFilter) {
    const options = getPinsGroupsTypeFilterOptions(
      pinsGroups,
      DEFAULT_PINS_GROUPS_FILTER.typeFilterValue,
    );

    result = (
      <ZonesMapFilter
        classes={classes.pinsGroups}
        value={pinsGroupsFilter}
        typeOptions={options}
        onChange={onPinsGroupsFilterChange}
        onFiltersClear={onPinsFilterClear}
      />
    );
  } else if (type === AssetGroupType.equationMaps && equationMapFilter) {
    result = (
      <DefaultAssetGroupFilter
        classes={classes.equationMaps}
        value={equationMapFilter}
        onChange={onEquationMapFilterChange}
        onFiltersClear={onEquationMapFilterClear}
      />
    );
  }

  return result;
};

export const isDateMatch = (dateString: string, dateFrom: Date | null, dateTo: Date | null): boolean => {
  const date = new Date(dateString);

  const dateFromMatched = !isDateValid(dateFrom) || isAfter(date, startOfDay(dateFrom));
  const dateToMatched = !isDateValid(dateTo) || isBefore(date, endOfDay(dateTo));

  return dateFromMatched && dateToMatched;
};

import i18n from 'i18next';

export const OWNERSHIP = {
  own: 'OWN',
  shared: 'SHARED',
};

export const isOwned = (ownership) => ownership === OWNERSHIP.own;

export const isShared = (ownership) => ownership === OWNERSHIP.shared;

export const isCleanedStatus = (status) => status === 'CLEANED';

export const isProcessedStatus = (status) => status === 'PROCESSED';

export const isPublishedStatus = (status) => status === 'PUBLISHED';

export const isInteractableStatus = (status) => {
  return isCleanedStatus(status)
    || isProcessedStatus(status);
};

export const isViewableStatus = (status, isPublishedDatasetViewable) => {
  return isPublishedDatasetViewable
    ? isInteractableStatus(status) || isPublishedStatus(status)
    : isInteractableStatus(status);
};

export const isInvalidStatus = (status) => status === 'INVALID';

export const getOwnedText = (ownership) => (
  isOwned(ownership)
    ? i18n.t('general.own-types.own')
    : i18n.t('general.own-types.shared')
);

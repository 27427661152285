import React, { ReactElement, useMemo } from 'react';
import clsx from 'clsx';
import Select, { SelectProps } from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ListSubheader } from '@material-ui/core';

import './index.scss';

interface DefaultOption<T> {
  type?: 'option';
  value: T;
  title: string;
  description?: string;
  beta?: boolean;
}

interface Subheader {
  type: 'subheader';
  title: string;
}

export type SelectOption<T> = DefaultOption<T> | Subheader;

interface EnhancedSelectProps<T> extends Omit<SelectProps, 'onChange'> {
  id: string;
  value: T;
  label?: string;
  labelPosition?: 'top' | 'left';
  required?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  options: SelectOption<T>[];
  classes?: {
    root?: string;
    label?: string;
    selectInput?: string;
    selectInputContent?: string;
  };
  onChange?: (v: T | null) => void;
}

export const isOption = (value: SelectOption<unknown>): value is DefaultOption<unknown> => {
  return !value.type || value.type === 'option';
};

export const isSubheader = (value: SelectOption<unknown>): value is Subheader => {
  return value.type === 'subheader';
};

export default function EnhancedSelect<T extends string | number>({
  id,
  value,
  label,
  labelPosition = 'top',
  required,
  disabled,
  clearable,
  options,
  classes,
  onChange,
  ...props
}: EnhancedSelectProps<T>) {
  const title = useMemo(() => {
    const selectedOption = options.find((option) => {
      return isOption(option) && option.value === value;
    });

    return selectedOption?.title;
  }, [options, value]);
  const withSubheader = useMemo(() => {
    const subheaderIndex = options.findIndex((option) => isSubheader(option));

    return subheaderIndex !== -1;
  }, [options]);

  return (
    <div className={clsx('select-wrapper', classes?.root, {
      'select-wrapper_label-top': labelPosition === 'top',
      'select-wrapper_label-left': labelPosition === 'left',
    })}
    >
      {
        label
          && (
            <label
              htmlFor={id}
              className={clsx('select-wrapper__label', classes?.label, {
                'select-wrapper__label_disabled': disabled,
                'select-wrapper__label_top': labelPosition === 'top',
              })}
            >
              {label}
              {required && <span className="required-asterisk"> *</span>}
            </label>
          )
      }
      <Select
        id={id}
        title={title}
        disabled={disabled}
        variant="outlined"
        value={value}
        IconComponent={ExpandMoreIcon}
        className={clsx('select-wrapper__select-input', classes?.selectInput)}
        classes={{
          root: clsx('select-wrapper__select', classes?.selectInputContent),
          icon: 'select-wrapper__icon',
        }}
        endAdornment={(
          <div className="select-wrapper__clear-button-wrapper">
            {
              (clearable && value)
              && (
                <IconButton
                  size="small"
                  className="select-wrapper__clear-button"
                  onClick={() => onChange?.(null)}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              )
            }
          </div>
        )}
        onChange={(e) => onChange?.(e.target.value as T)}
        {...props}
      >
        {
          options.map((option) => {
            let result: ReactElement;

            if (isSubheader(option)) {
              result = (
                <ListSubheader
                  key={option.title}
                  className="select-wrapper__item-subheader"
                >
                  {option.title}
                </ListSubheader>
              );
            } else {
              result = (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  classes={{
                    root: clsx('select-wrapper__menu-item', {
                      'select-wrapper__menu-item_with-subheader': withSubheader,
                    }),
                  }}
                >
                  <Typography
                    className={clsx('select-wrapper__menu-item-title', {
                      'select-wrapper__menu-item-title_beta': option.beta,
                    })}
                  >
                    {option.title}
                  </Typography>
                  <Typography className="select-wrapper__menu-item-secondary-text">
                    {option.description}
                  </Typography>
                </MenuItem>
              );
            }

            return result;
          })
        }
      </Select>
    </div>
  );
}

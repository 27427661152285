import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';

import ZonesMapItem from '../../../features/ui/dataLayersView/components/Items/ZonesMapItem';
import { getVamapThumbnailGeoMap } from '../../../helpers/functions/entities/vectorAnalysisMap';
import { getThumbnailUrl } from '../../../helpers/functions/utils/url';
import ZonesMapTable from '../common/ZonesMapTable';

import './index.scss';

class VectorAnalysisMap extends Component {
  componentDidMount() {
    const {
      vamap,
      onFetchAttributes,
    } = this.props;

    if (!vamap.attributes && typeof onFetchAttributes === 'function') {
      onFetchAttributes(vamap.uuid);
    }
  }

  render() {
    const {
      t,
      vamap,
      details,
      apiKey,
      areaUnit,
      TableComponent = ZonesMapTable,
      onGetDetails,
      onClose,
      onZoneClick = () => {},
    } = this.props;
    const zones = vamap.geoMaps.find((geoMap) => {
      return geoMap.shortName === 'zones';
    });
    const thumbnail = getVamapThumbnailGeoMap(vamap.geoMaps);

    return (
      <div className="vector-analysis-map">
        <div className="card-header">
          <div className="card-header__container">
            <ZonesMapItem zonesMap={vamap} />
            <IconButton
              size="small"
              className="btn"
              disabled={!vamap.attributes}
              onClick={() => onGetDetails(vamap)}
            >
              <InfoIcon
                fontSize="inherit"
                className="icon icon_primary"
              />
            </IconButton>
          </div>
          <IconButton
            size="small"
            className="btn"
            disabled={!vamap.attributes}
            onClick={() => onClose(vamap.uuid)}
          >
            <CloseIcon
              fontSize="inherit"
              className="icon"
            />
          </IconButton>
        </div>
        <div className="vector-analysis-map__container">
          <img
            className="preview"
            src={getThumbnailUrl({ thumbnail, size: 250, apiKey })}
            alt={t('general.controls.zones-map-preview')}
          />
          <div className="vector-analysis-map__zones">
            {details}
            <TableComponent
              areaUnit={areaUnit}
              attributes={vamap.attributes}
              colors={zones.hexColorCodes}
              selectedZones={vamap.selectedZones}
              onZoneClick={(zone) => onZoneClick(vamap.uuid, zone)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(VectorAnalysisMap);

import React from 'react';

import { prepareFieldLegendGroup } from '../../../helpers/components/legend';
import FieldLegendGroup from '../Groups/Field';

export default function FieldLegendContent({
  field,
  farm,
  satelliteImages,
  apiKey,
  areaUnit,
  chartScale,
  onChartScaleChange = () => {},
}) {
  const legendGroup = prepareFieldLegendGroup({
    field,
    farm,
    satelliteImages,
    chartScale,
    areaUnit,
    apiKey,
  });

  return (
    <FieldLegendGroup
      {...legendGroup}
      onChartScaleChange={onChartScaleChange}
    />
  );
}

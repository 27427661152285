import { TABS } from '../../helpers/constants/ui';
import OwnFarms from './Tabs/OwnFarms';
import SharedFarms from './Tabs/SharedFarms';

const config = {
  [TABS.ownFarms]: OwnFarms,
  [TABS.sharedFarms]: SharedFarms,
};

export default config;

import React, { MouseEvent, useMemo } from 'react';

import CheckboxItem from '../../../TreeView/CheckboxItem';
import NamedItem from '../../../Items/NamedItem';
import ItemActions from '../../../ItemActions';
import SimpleItem from '../../../TreeView/SimpleItem';
import type { Mode, DataLayersTreeNode } from '../../../../types/dataLayersTree';
import { getEquationMapActions } from '../../../../helpers/functions/dataLayersTreeNodesActions';
import {
  isInvalid as isEquationMapInvalid,
} from '../../../../../../../helpers/functions/entities/equationMap';
import { AssetGroupType } from '../../../../../../../helpers/constants/entities/asset';
import { TransformedEquationMap } from '../../../../../../../helpers/types/equationMap';

export default function EquationMapNode({
  id,
  equationMap,
  mode,
  checked,
  expanded,
  treeNodeGetter,
  jdProfileIsHealth,
  jdProfileIsAuthorized,
  isSynchronizedJohnDeereField,
  jdWorkPlanExportAvailable,
  showItemMenu,
  withAttributes,
  offset,
  selectedItemUuid,
  selectedItemGroupType,
  onCheckboxClick,
  onMenuItemClick,
  onExpandMoreClick,
  onClick,
}: {
  id: string,
  equationMap: TransformedEquationMap,
  mode: Mode,
  checked: Record<string, number>,
  expanded: Record<string, boolean>,
  treeNodeGetter: () => DataLayersTreeNode | null,
  jdProfileIsHealth: boolean,
  jdProfileIsAuthorized: boolean,
  isSynchronizedJohnDeereField: boolean,
  jdWorkPlanExportAvailable: boolean,
  showItemMenu: boolean,
  withAttributes?: boolean,
  offset: number,
  selectedItemUuid?: string,
  selectedItemGroupType?: string | null,
  onCheckboxClick: (v: Record<string, number>, item: DataLayersTreeNode) => void,
  onMenuItemClick: (v: string) => void,
  onExpandMoreClick?: (e: MouseEvent) => void,
  onClick: () => void,
}) {
  let result;
  const menuItems = useMemo(() => {
    return getEquationMapActions(
      equationMap,
      {
        jdProfileIsHealth,
        jdProfileIsAuthorized,
        jdWorkPlanExportAvailable,
        isSynchronizedJohnDeereField,
      },
    );
  }, [
    equationMap,
    jdProfileIsHealth,
    jdProfileIsAuthorized,
    jdWorkPlanExportAvailable,
    isSynchronizedJohnDeereField,
  ]);
  const item = (
    <>
      <NamedItem
        item={{
          name: equationMap.name,
        }}
      />
      {
        showItemMenu
          && (
            <ItemActions
              menuItems={menuItems}
              onMenuItemClick={onMenuItemClick}
            />
          )
      }
    </>
  );
  const isItemSelected = equationMap.uuid === selectedItemUuid
    && selectedItemGroupType === AssetGroupType.equationMaps;

  if (mode === 'multiSelect') {
    result = (
      <CheckboxItem
        itemId={id}
        isParent={withAttributes}
        isExpanded={expanded[id]}
        treeNodeGetter={treeNodeGetter}
        checked={checked}
        disabled={isEquationMapInvalid(equationMap)}
        isSelected={isItemSelected}
        offset={offset}
        onCheckboxClick={onCheckboxClick}
        onExpandMoreClick={onExpandMoreClick}
        onClick={onClick}
      >
        {item}
      </CheckboxItem>
    );
  } else {
    result = (
      <SimpleItem
        isSelected={isItemSelected}
        offset={offset}
        disabled={isEquationMapInvalid(equationMap)}
        onClick={onClick}
      >
        {item}
      </SimpleItem>
    );
  }

  return result;
}

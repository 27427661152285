import React from 'react';
import clsx from 'clsx';

import { Operation } from '../../types/operation';
import { OperationStatus } from '../../helpers/constants/operation';
import StatusIcon from '../StatusIcon';

import './index.scss';

export default function OperationSummaryIcons({
  operation,
}: {
  operation: Operation,
}) {
  const { subOperations } = operation;

  const statusCounter = subOperations.reduce<{ [key in OperationStatus]: number }>((acc, subOperation) => {
    acc[subOperation.status] += 1;
    return acc;
  }, {
    [OperationStatus.done]: 0,
    [OperationStatus.error]: 0,
    [OperationStatus.inProgress]: 0,
    [OperationStatus.ignored]: 0,
    [OperationStatus.all]: 0,
  });

  return (
    <div className="operation-summary-icons">
      {Object.entries(statusCounter).map(([status, totalItems]) => {
        return (
          totalItems
            ? (
              <div
                key={status}
                className={clsx('operation-summary-icons__item', `operation-summary-icons__item_${status}`)}
              >
                <span>{totalItems}</span>
                <StatusIcon status={status as OperationStatus}/>
              </div>
            )
            : null
        );
      })}
    </div>
  );
}

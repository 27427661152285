import { comparator } from '../utils/string';

export const getValueOptionsArray = (valueOptions, titleProcessor) => {
  return valueOptions.map((valueOption) => {
    return {
      title: titleProcessor(valueOption),
      value: valueOption,
    };
  });
};

export const getSortedValueOptionsArray = (valueOptions, titleProcessor) => {
  return getValueOptionsArray(valueOptions, titleProcessor).sort((a, b) => comparator(a.title, b.title));
};

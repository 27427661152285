import React, { Fragment } from 'react';
import i18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import ZonesMapTable from '../../../../../../components/Views/common/ZonesMapTable';
import SatelliteZonesMapLegendGroup from '../../../../../../components/Legend/Groups/SatelliteZonesMap';
import DatasetZonesMapLegendGroup from '../../../../../../components/Legend/Groups/DatasetZonesMap';
import MultilayerZonesMapLegendGroup from '../../../../../../components/Legend/Groups/MultilayerZonesMap';
import ZonesIntersectionMapLegendGroup from '../../../../../../components/Legend/Groups/ZonesIntersectionMap';
import DrawnManuallyMapLegendGroup from '../../../../../../components/Legend/Groups/DrawManuallyMap';
import InfoMessage from '../../../../../../components/Messages/InfoMessage';
import {
  isSatelliteVectorAnalysis,
  isSoilVectorAnalysis,
  isYieldVectorAnalysis,
  isAsAppliedVectorAnalysis,
  isTopographyVectorAnalysis,
  isMultiLayerVectorAnalysis,
  isZonesOperationsIntersectVectorAnalysis,
  isDrawnManuallyVectorAnalysis,
} from '../../../../../../helpers/functions/entities/assets';
import {
  prepareMultiLayerZonesMapLegendGroup,
  prepareSatelliteZonesMapLegendGroup,
  prepareSoilZonesMapLegendGroup,
  prepareTopographyZonesMapLegendGroup,
  prepareYieldZonesMapLegendGroup,
  prepareAsAppliedZonesMapLegendGroup,
  prepareZonesOperationsIntersectZonesMapLegendGroup,
  prepareDrawnManuallyZoneMapLegendGroup,
} from '../../../../../../helpers/components/legend';
import {
  refreshStatistics,
  setSelectedZone,
} from '../../../zonesMapSlice';
import { selectApiKey, selectAreaUnit } from '../../../../../user/userSelectors';
import {
  selectSelectedZone,
  selectStatisticsOutdated,
  selectUpdates,
} from '../../../zonesMapSelectors';
import { selectField } from '../../../../../field/fieldSelectors';
import { applyUpdates } from '../../../../../../helpers/analysis';
import useZonesMap from '../../../hooks/useZonesMap';
import { useGetFarmQuery } from '../../../../../farms/farmsAPI';

import './index.scss';

const extendLegendGroup = ({
  legendGroup,
  field,
  farm,
}) => {
  return {
    ...legendGroup,
    overview: {
      ...legendGroup.overview,
      items: [
        {
          title: i18n.t('zones-map.description.field-name'),
          value: field.name,
        },
        {
          title: i18n.t('zones-map.description.farm-name'),
          value: farm?.name,
        },
        ...legendGroup.overview.items,
      ],
    },
  };
};
const getProps = ({
  farm,
  field,
  zonesMap,
  areaUnit,
  apiKey,
  processor,
}) => {
  return extendLegendGroup({
    legendGroup: processor(zonesMap, field, areaUnit, apiKey),
    farm,
    field,
  });
};

const Description = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    farmUuid,
    fieldUuid,
    uuid,
  } = useParams();
  const panelRef = React.createRef();
  const areaUnit = useSelector(selectAreaUnit);
  const apiKey = useSelector(selectApiKey);
  const field = useSelector(selectField);
  const selectedZone = useSelector(selectSelectedZone);
  const statisticsOutdated = useSelector(selectStatisticsOutdated);
  const updates = useSelector(selectUpdates);
  const {
    data: farm,
  } = useGetFarmQuery({ farmUuid });
  const initialZonesMap = useZonesMap(farmUuid, fieldUuid, uuid);
  const zonesMap = applyUpdates(initialZonesMap, updates);
  let Component = Fragment;
  let processor = () => null;

  if (!zonesMap) {
    return null;
  }

  const handleSelectedZoneChange = (zone) => {
    dispatch(setSelectedZone(zone));
  };

  const handleRefreshClick = () => {
    dispatch(refreshStatistics());
  };

  const handleClickAway = (event) => {
    if (!panelRef.current.contains(event.target)) {
      return;
    }

    handleSelectedZoneChange(null);
  };

  if (isSatelliteVectorAnalysis(zonesMap)) {
    Component = SatelliteZonesMapLegendGroup;
    processor = prepareSatelliteZonesMapLegendGroup;
  } else if (isSoilVectorAnalysis(zonesMap)) {
    Component = DatasetZonesMapLegendGroup;
    processor = prepareSoilZonesMapLegendGroup;
  } else if (isYieldVectorAnalysis(zonesMap)) {
    Component = DatasetZonesMapLegendGroup;
    processor = prepareYieldZonesMapLegendGroup;
  } else if (isAsAppliedVectorAnalysis(zonesMap)) {
    Component = DatasetZonesMapLegendGroup;
    processor = prepareAsAppliedZonesMapLegendGroup;
  } else if (isTopographyVectorAnalysis(zonesMap)) {
    Component = DatasetZonesMapLegendGroup;
    processor = prepareTopographyZonesMapLegendGroup;
  } else if (isMultiLayerVectorAnalysis(zonesMap)) {
    Component = MultilayerZonesMapLegendGroup;
    processor = prepareMultiLayerZonesMapLegendGroup;
  } else if (isZonesOperationsIntersectVectorAnalysis(zonesMap)) {
    Component = ZonesIntersectionMapLegendGroup;
    processor = prepareZonesOperationsIntersectZonesMapLegendGroup;
  } else if (isDrawnManuallyVectorAnalysis(zonesMap)) {
    Component = DrawnManuallyMapLegendGroup;
    processor = prepareDrawnManuallyZoneMapLegendGroup;
  }

  return (
    <div
      className="zones-map-description"
      ref={panelRef}
    >
      {statisticsOutdated
        && (
          <InfoMessage
            key="info-message"
            classes={{
              root: 'zones-map-description__info-message',
            }}
            buttonLabel={t('zones-map.description.refresh-info-message.button-label')}
            onButtonClick={handleRefreshClick}
          >
            <Trans i18nKey="zones-map.description.refresh-info-message.text">
              The zones were amended and the statistics are outdated.
              Please, click the refresh button to recalculate them.
            </Trans>
          </InfoMessage>
        )}
      <Component
        TableComponent={(p) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <ZonesMapTable
              {...p}
              activeZone={selectedZone}
              onZoneClick={handleSelectedZoneChange}
            />
          </ClickAwayListener>
        )}
        {...getProps({
          farm,
          field,
          zonesMap,
          areaUnit,
          apiKey,
          processor,
        })}
      />
    </div>
  );
};

export default Description;

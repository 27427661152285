import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import PopupHeader from '../../../../components/Popups/PopupHeader';
import ImagePreview from '../ImagePreview';

import './index.scss';

const PinPhotosPopup = ({
  pinName,
  currentPhotoIndex = 0,
  photos = [],
  onCancel = () => {},
}) => {
  return (
    <Dialog
      open
      classes={{
        paperScrollPaper: 'pin-photos-popup__paper-scroll-paper',
        paper: 'pin-photos-popup__paper',
      }}
      onClose={onCancel}
    >
      <PopupHeader
        title={pinName}
        onCancel={onCancel}
      />
      <DialogContent
        classes={{
          root: 'pin-photos-popup__content',
        }}
      >
        <ImagePreview
          images={photos}
          imageIndex={currentPhotoIndex}
        />
      </DialogContent>
    </Dialog>
  );
};

export default PinPhotosPopup;

import React, {
  MouseEvent,
} from 'react';

import CheckboxItem from '../../../TreeView/CheckboxItem';
import NamedItem from '../../../Items/NamedItem';
import SimpleItem from '../../../TreeView/SimpleItem';
import type { Mode, DataLayersTreeNode } from '../../../../types/dataLayersTree';

import './index.scss';

export default function AttributeNode({
  id,
  name,
  mode,
  checked,
  offset,
  isParent = false,
  isExpanded,
  treeNodeGetter,
  onCheckboxClick,
  onAttributeClick,
  onExpandMoreClick,
}: {
  id: string,
  name: string,
  mode: Mode,
  checked: Record<string, number>,
  offset: number,
  isParent?: boolean,
  isExpanded?: boolean,
  treeNodeGetter: () => DataLayersTreeNode | null,
  onCheckboxClick: (v: Record<string, number>, item: DataLayersTreeNode) => void,
  onAttributeClick?: (itemNode: DataLayersTreeNode | null) => void,
  onExpandMoreClick?: (e: MouseEvent) => void,
}) {
  let result;
  const item = (
    <NamedItem
      item={{
        name,
      }}
    />
  );

  if (mode === 'multiSelect') {
    result = (
      <CheckboxItem
        classes={{
          wrapper: 'attribute-node__wrapper',
        }}
        itemId={id}
        offset={offset}
        checked={checked}
        isParent={isParent}
        isExpanded={isExpanded}
        treeNodeGetter={treeNodeGetter}
        onCheckboxClick={onCheckboxClick}
        onClick={onAttributeClick}
        onExpandMoreClick={onExpandMoreClick}
      >
        {item}
      </CheckboxItem>
    );
  } else {
    result = (
      <SimpleItem offset={offset}>
        {item}
      </SimpleItem>
    );
  }

  return result;
}

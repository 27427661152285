import { JDProductType } from '../../../../jdProducts/helpers/constants/product';
import { getArchiveName } from '../../../jdExport/helpers/functions/archive';
import { ExportWorkPlanData } from '../../types/api';
import {
  ApplicationSettings,
  SeedingWorkPlanSettings,
} from '../../types/workPlan';

export const getApplicationsPayload = (
  applications: ApplicationSettings[],
): ExportWorkPlanData => {
  return applications.reduce((acc, {
    productType,
    selectedAsset,
    productId,
    unit,
  }) => {
    if (!selectedAsset || !productId || !unit) {
      return acc;
    }

    switch (productType) {
      case JDProductType.chemical:
        return {
          ...acc,
          chemicalId: productId,
          chemicalUnit: unit,
          chemicalResource: selectedAsset,
        };
      case JDProductType.fertilizer:
        return {
          ...acc,
          fertilizerId: productId,
          fertilizerUnit: unit,
          fertilizerResource: selectedAsset,
        };
      case JDProductType.tankMix:
        return {
          ...acc,
          tankMixId: productId,
          tankMixUnit: unit,
          tankMixResource: selectedAsset,
        };
      case JDProductType.dryBlend:
        return {
          ...acc,
          dryBlendId: productId,
          dryBlendUnit: unit,
          dryBlendResource: selectedAsset,
        };
      default:
        return acc;
    }
  }, {} as ExportWorkPlanData);
};

export const getSeedingWorkPlanPayload = (
  {
    varietyId,
    selectedAsset,
    unit,
    applications,
  }: SeedingWorkPlanSettings,
): ExportWorkPlanData => {
  return {
    ...(varietyId && selectedAsset && unit
      ? {
        varietyId,
        varietyResource: selectedAsset,
        varietyUnit: unit,
      }
      : {}
    ),
    ...getApplicationsPayload(applications),
  };
};

export const getWorkPlanInputData = (
  exportData: ExportWorkPlanData,
  archiveUrlsData: {
    uuid: string;
    archiveUrl: string;
    archiveResourceName: string;
  }[],
) => {
  let result = {};

  if ('varietyId' in exportData) {
    const {
      varietyId,
      varietyUnit,
      varietyResource,
    } = exportData;

    const varietyArchive = archiveUrlsData.find(({ uuid }) => varietyResource?.uuid === uuid);

    result = {
      ...result,
      varietyId,
      varietyArchiveName: getArchiveName(varietyArchive?.archiveResourceName),
      varietyArchiveUrl: varietyArchive?.archiveUrl,
      varietyUnit,
    };
  }

  if ('chemicalId' in exportData) {
    const {
      chemicalId,
      chemicalUnit,
      chemicalResource,
    } = exportData;

    const chemicalArchive = archiveUrlsData.find(({ uuid }) => chemicalResource?.uuid === uuid);

    result = {
      ...result,
      chemicalId,
      chemicalArchiveName: getArchiveName(chemicalArchive?.archiveResourceName),
      chemicalArchiveUrl: chemicalArchive?.archiveUrl,
      chemicalUnit,
    };
  }

  if ('fertilizerId' in exportData) {
    const {
      fertilizerId,
      fertilizerUnit,
      fertilizerResource,
    } = exportData;

    const fertilizerArchive = archiveUrlsData.find(({ uuid }) => fertilizerResource?.uuid === uuid);

    result = {
      ...result,
      fertilizerId,
      fertilizerArchiveName: getArchiveName(fertilizerArchive?.archiveResourceName),
      fertilizerArchiveUrl: fertilizerArchive?.archiveUrl,
      fertilizerUnit,
    };
  }

  if ('tankMixId' in exportData) {
    const {
      tankMixId,
      tankMixUnit,
      tankMixResource,
    } = exportData;

    const tankMixArchive = archiveUrlsData.find(({ uuid }) => tankMixResource?.uuid === uuid);

    result = {
      ...result,
      tankMixId,
      tankMixArchiveName: getArchiveName(tankMixArchive?.archiveResourceName),
      tankMixArchiveUrl: tankMixArchive?.archiveUrl,
      tankMixUnit,
    };
  }

  if ('dryBlendId' in exportData) {
    const {
      dryBlendId,
      dryBlendUnit,
      dryBlendResource,
    } = exportData;

    const dryBlendArchive = archiveUrlsData.find(({ uuid }) => dryBlendResource?.uuid === uuid);

    result = {
      ...result,
      dryBlendId,
      dryBlendArchiveName: getArchiveName(dryBlendArchive?.archiveResourceName),
      dryBlendArchiveUrl: dryBlendArchive?.archiveUrl,
      dryBlendUnit,
    };
  }

  return result;
};

import React, { ReactElement, useEffect, useState } from 'react';

import PreviewTileHeader from './Header';
import PreviewTileActions from './Actions';
import PreviewTileFieldView from './FieldView';
import PreviewTileSettingsView from './SettingsView';
import PreviewTileLoaderView from './LoaderView';
import { useAppSelector } from '../../../../../app/store/helpers/functions';
import { selectEquationMapData } from '../../createBatchEquationBasedAnalysisSelectors';
import { LoadStatus } from '../../../../../helpers/constants/utils/loadStatus';
import PreviewTileEquationMapView from './EquationMapView';

import './index.scss';

export default function PreviewTile({
  fieldUuid,
}: {
  fieldUuid: string,
}) {
  const equationMapData = useAppSelector((state) => selectEquationMapData(state, fieldUuid));
  const [showSettings, setShowSettings] = useState(false);

  // Hide the settings view when the map is generated
  // so that the equation map is shown when the generation is finished
  useEffect(() => {
    if (equationMapData?.generationStatus === LoadStatus.loading) {
      setShowSettings(false);
    }
  }, [equationMapData?.generationStatus]);

  let view: ReactElement;

  if (equationMapData?.generationStatus === LoadStatus.loading) {
    view = (
      <>
        <PreviewTileHeader fieldUuid={fieldUuid} />
        <PreviewTileLoaderView />
      </>
    );
  } else if (showSettings) {
    view = (
      <PreviewTileSettingsView
        fieldUuid={fieldUuid}
      />
    );
  } else if (equationMapData?.generationStatus === LoadStatus.success) {
    view = (
      <>
        <PreviewTileHeader fieldUuid={fieldUuid} />
        <PreviewTileEquationMapView fieldUuid={fieldUuid} />
      </>
    );
  } else {
    view = (
      <>
        <PreviewTileHeader fieldUuid={fieldUuid} />
        <PreviewTileFieldView fieldUuid={fieldUuid} />
      </>
    );
  }

  return (
    <div className="preview-tile">
      <div className="preview-tile__content">
        {view}
      </div>
      <PreviewTileActions
        fieldUuid={fieldUuid}
        showSettings={showSettings}
        onSetSettingsView={setShowSettings}
      />
    </div>
  );
}

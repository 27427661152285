import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';

import ComboBox from '../../../../../../../../components/ComboBox';
import ItemsPicker from '../../../../../../../../components/ItemsPicker';
import PopupHeader from '../../../../../../../../components/Popups/PopupHeader';
import { selectYieldDataset } from '../../../../../../../field/fieldSelectors';
import {
  selectCalibrateCalibrationAttributes,
  selectCalibratePathwiseCalibrationBasis,
  selectCleanTargetAttribute,
  selectDatasetUuid,
} from '../../../../../cleanCalibrateSelectors';
import {
  setCalibrateCalibrationAttributes,
  setCalibratePathwiseCalibrationBasis,
  setCleanTargetAttribute,
} from '../../../../../cleanCalibrateSlice';
import { getAttributeString } from '../../../../../helpers/functions/attributes';
import type { FullAttributeItem } from '../../../../../types/ui';
import type { FullAttribute } from '../../../../../../../../helpers/types/dataset';
import {
  createFullAttributeItem,
  getComboBoxOptionSelected,
} from '../../../../../helpers/functions/ui';
import { useAppSelector } from '../../../../../../../../app/store/helpers/functions';

import './index.scss';

export default function FastTabContent({
  onCancel,
}: {
  onCancel: () => void,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const datasetUuid = useAppSelector(selectDatasetUuid);
  const dataset = useAppSelector((state) => selectYieldDataset(state, datasetUuid));
  const calibrateBasis = useAppSelector(selectCalibratePathwiseCalibrationBasis);
  const calibrateAttributes = useAppSelector(selectCalibrateCalibrationAttributes);
  const cleanAttribute = useAppSelector(selectCleanTargetAttribute);

  const handleMachineIdChange = (_e: unknown, item: FullAttributeItem) => {
    dispatch(setCalibratePathwiseCalibrationBasis(item.value));
  };

  const handleYieldAttributesChange = (itemsValues: Set<string>) => {
    dispatch(setCalibrateCalibrationAttributes([...itemsValues]));
  };

  const handleCleanAttributeChange = (_e: unknown, item: FullAttributeItem) => {
    dispatch(setCleanTargetAttribute(item.value));
  };

  const items = useMemo<FullAttributeItem[]>(() => {
    return (dataset?.fullAttributes || []).map((fullAttribute: FullAttribute) => {
      return createFullAttributeItem(fullAttribute);
    });
  }, [dataset]);

  const machineIdItem = useMemo<FullAttributeItem | undefined>(() => {
    return items.find((item) => item.value === calibrateBasis);
  }, [items, calibrateBasis]);

  const yieldAttributes = useMemo<Set<string>>(() => {
    return new Set(calibrateAttributes);
  }, [calibrateAttributes]);

  const yieldAttributesItems = useMemo<FullAttributeItem[]>(() => {
    return (dataset?.fullAttributes || []).reduce((
      acc: FullAttributeItem[],
      fullAttribute: FullAttribute,
    ) => {
      // TODO: refactor to use createFullAttributeItem instead of getAttributeString
      if (yieldAttributes.has(getAttributeString(fullAttribute))) {
        acc.push(createFullAttributeItem(fullAttribute));
      }

      return acc;
    }, []);
  }, [dataset, yieldAttributes]);

  const cleanAttributeItem = useMemo<FullAttributeItem | undefined>(() => {
    return items.find((item) => item.value === cleanAttribute);
  }, [items, cleanAttribute]);

  return (
    <>
      <PopupHeader
        classes={{
          root: 'fast-tab-content__header',
        }}
        title={t('clean-calibrate.yield-popup.clean-calibrate-title')}
        onCancel={onCancel}
      />
      <DialogContent className="fast-tab-content__content">
        <DialogContentText className="fast-tab-content__content-description">
          {t('clean-calibrate.yield-popup.tabs.fast.check-attributes.description')}
        </DialogContentText>
        <ComboBox
          title={t('clean-calibrate.yield-popup.tabs.fast.check-attributes.machine-id')}
          placeholder={t('general.controls.select')}
          options={items}
          value={machineIdItem}
          getOptionSelected={getComboBoxOptionSelected}
          disableCloseOnSelect={false}
          disableClearable
          required
          onChange={handleMachineIdChange}
        />
        <Divider className="fast-tab-content__content-divider" />
        <DialogContentText>
          {t('clean-calibrate.yield-popup.tabs.fast.check-attributes.attributes-instruction')}
        </DialogContentText>
        <ItemsPicker
          availableItemsTitle={t('clean-calibrate.yield-popup.attributes')}
          addedItemsTitle={t('clean-calibrate.yield-popup.tabs.fast.check-attributes.selected-attributes')}
          items={items}
          addedItemsValues={yieldAttributes}
          required
          emptyAddedItemsLabel={t('clean-calibrate.yield-popup.no-added-attributes')}
          onAddedItemsChange={handleYieldAttributesChange}
        />
        <Divider className="fast-tab-content__content-divider" />
        <DialogContentText>
          {t('clean-calibrate.yield-popup.tabs.fast.check-attributes.cleaning-attribute-instruction')}
        </DialogContentText>
        <ComboBox
          title={t('clean-calibrate.yield-popup.tabs.fast.check-attributes.cleaning-attribute')}
          placeholder={t('general.controls.select')}
          options={yieldAttributesItems}
          value={cleanAttributeItem}
          getOptionSelected={getComboBoxOptionSelected}
          disableCloseOnSelect={false}
          disableClearable
          required
          onChange={handleCleanAttributeChange}
        />
      </DialogContent>
    </>
  );
}

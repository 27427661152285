import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Button from '../../../../../components/Button';
import ToolsPanel from '../../../../../components/ToolsPanel';
import { uploadFiles } from '../../uploadDataSlice';
import { selectFarm, selectFiles } from '../../uploadDataSelectors';
import { UploadType } from '../../helpers/constants/upload';

import './index.scss';

const UploadDataToolsPanel = ({
  disableUploadButton,
  onClickUpload,
}) => {
  const { t } = useTranslation();

  return (
    <ToolsPanel
      classes={{
        root: 'upload-data-tools-panel',
      }}
      actionsLeft={<p className="description">{t('upload-data.description')}</p>}
      actionsRight={(
        <Button
          disabled={disableUploadButton}
          classes={{
            root: 'button',
          }}
          variant="contained"
          color="primary"
          onClick={onClickUpload}
        >
          {t('general.controls.upload')}
        </Button>
      )}
    />
  );
};

const mapStateTopProps = (state) => {
  const farm = selectFarm(state);
  const {
    [UploadType.fieldBoundaries]: boundaries,
    ...otherFiles
  } = selectFiles(state);

  const hasFilesToUpload = Object.values(otherFiles).some((filesPerTab) => {
    return filesPerTab.length !== 0;
  });

  return {
    disableUploadButton: !hasFilesToUpload
      && (boundaries.length === 0 || (boundaries.length > 0 && !farm)),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClickUpload: () => {
    dispatch(uploadFiles());
  },
});

export default connect(mapStateTopProps, mapDispatchToProps)(UploadDataToolsPanel);

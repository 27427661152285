import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function ExportFileIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.5 3L19.5 9V13.207C19.0232 13.0722 18.52 13 18 13C14.9624 13 12.5 15.4624 12.5 18.5C12.5 19.4002 12.7163 20.2499 13.0997 21H4.5V3H13.5ZM12.5 10H18L12.5 4.5V10Z" />
      <path d="M17.8197 22.0568V23.5337L19.878 21.4754L19.4026 21L17.8197 19.4171V20.8822C16.6603 20.7901 15.7459 19.818 15.7459 18.6352C15.7459 18.2552 15.84 17.8953 16.0081 17.5851L16.0723 17.4668L15.2255 16.6201L15.1049 16.8102C14.771 17.3368 14.5738 17.9625 14.5738 18.6352C14.5738 19.5524 14.9337 20.3852 15.5202 21C16.1054 21.6135 16.9161 22.01 17.8197 22.0568Z" />
      <path d="M18.1803 16.3883V17.8534L16.122 15.7951L18.1803 13.7368V15.2137C18.6518 15.2381 19.0979 15.3577 19.5 15.5537C20.6407 16.1097 21.4262 17.2803 21.4262 18.6352C21.4262 19.308 21.229 19.9337 20.8951 20.4603L20.7745 20.6504L19.9277 19.8037L19.9919 19.6854C20.16 19.3752 20.2541 19.0153 20.2541 18.6352C20.2541 17.9674 19.9626 17.3668 19.5 16.9537C19.1434 16.6353 18.6851 16.4284 18.1803 16.3883Z" />
    </SvgIcon>
  );
}

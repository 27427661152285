import React from 'react';
import { useTranslation } from 'react-i18next';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import ButtonMenu from '../../../../components/ButtonMenu';
import Radio from '../../../../components/Radio';

import { ButtonMenuItem } from '../../../../components/ButtonMenu/types';
import Label from '../../../../components/Label';
import { EquationListItem } from '../../types/ui';

import './index.scss';

export default function EquationsListItem({
  item,
  menuItems,
  onEquationSelect,
  onSetUrlClick = () => {},
  onMenuItemClick = () => {},
}: {
  item: EquationListItem,
  menuItems?: ButtonMenuItem[],
  onEquationSelect: (i: EquationListItem) => void,
  onMenuItemClick?: (id: string, i: EquationListItem) => void,
  onSetUrlClick?: (i: EquationListItem) => void,
}) {
  const { t } = useTranslation();

  return (
    <div className="equations-list-item">
      <div className="equations-list-item__content">
        <Radio
          value={item.uuid}
          checked={item.selected}
          classes={{
            radioRoot: 'equations-list-item__radio-root',
            label: 'equations-list-item__label',
          }}
          label={(
            <>
              <span className="equations-list-item__label-title">
                {item.title}
              </span>
              <span className="equations-list-item__label-description">
                {item.description}
              </span>
            </>
          )}
          onChange={() => onEquationSelect(item)}
        />
        {
          item.useNumpy
            && (
              <div className="equations-list-item__labels-container">
                <Label
                  text={t('zones-ops.equation-based.steps.4.panel.numpy')}
                  color="primary"
                />
              </div>
            )
        }
      </div>
      {
        item.sourceUrl
          && (
            <div className="equations-list-item__link-container">
              <a
                className="link"
                href={item.sourceUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('zones-ops.equation-based.steps.4.panel.source-url')}
              </a>
            </div>
          )
      }
      {
        item.allowSetSourceUrl
          && (
            <div className="equations-list-item__link-container">
              <Link
                component="button"
                onClick={() => onSetUrlClick(item)}
              >
                <Typography
                  variant="body2"
                  className="link"
                >
                  {t('zones-ops.equation-based.steps.4.panel.set-source-url')}
                </Typography>
              </Link>
            </div>
          )
      }
      {
        !!menuItems?.length
          && (
            <ButtonMenu
              classes={{
                iconButton: {
                  root: 'equations-list-item__button-menu',
                },
              }}
              isIconButton
              edge="end"
              size="small"
              items={menuItems}
              onItemClick={(menuItem) => onMenuItemClick(menuItem.id, item)}
            >
              <MoreVertIcon fontSize="small" />
            </ButtonMenu>
          )
      }
    </div>
  );
}

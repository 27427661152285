import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { noop } from '../../helpers/functions/utils/noop';

import './index.scss';

const getTabLabelNode = (label) => {
  let result;
  const textElement = (
    <Fragment>
      {
        label.secondary
          && (
            <Typography
              className="tabs__tab-wrapper-secondary-label"
              variant="body2"
            >
              {label.secondary}
            </Typography>
          )
      }
      <Typography
        className="tabs__tab-wrapper-primary-label"
        variant="body2"
      >
        {label.primary} {label.primaryPostfix && ` (${label.primaryPostfix})`}
      </Typography>
    </Fragment>
  );

  if (label.icon) {
    result = (
      <>
        <div className="tabs__tab-wrapper-icon">
          {label.icon}
        </div>
        {textElement}
      </>
    );
  } else {
    result = textElement;
  }

  return result;
};

const EnhancedTabs = ({
  hasClose = false,
  tabs = [],
  component = 'div',
  value,
  classes = {},
  tabClasses = {},
  centered = false,
  variant = 'scrollable',
  onTabChange,
  onTabClose = () => {},
}) => {
  const { root, ...otherClasses } = classes;
  const {
    root: tabRoot,
    selected: tabSelected,
    wrapper: tabWrapper,
    ...otherTabClasses
  } = tabClasses;
  const v = value != null ? value : tabs[0].value || 0;

  return (
    <Tabs
      value={v}
      onChange={onTabChange || noop}
      indicatorColor="primary"
      variant={variant}
      centered={centered}
      classes={{
        root: clsx('tabs', root),
        ...otherClasses,
      }}
    >
      {
        tabs.map((tab, index) => {
          const icon = hasClose
            ? (
              <IconButton
                size="small"
                className="tab__close-btn"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  onTabClose(event, index);
                }}
              >
                <CloseIcon className="tab__close-icon"/>
              </IconButton>
            )
            : null;

          return (
            <Tab
              key={index}
              value={tab.value || index}
              disableRipple={hasClose}
              disabled={tab.disabled}
              component={component}
              to={component === Link ? tab.to : null}
              icon={icon}
              label={getTabLabelNode(tab.label)}
              classes={{
                root: clsx('tabs__tab', tabRoot),
                selected: clsx('tabs__tab_selected', tabSelected),
                wrapper: clsx('tabs__tab-wrapper', tabWrapper),
                ...otherTabClasses,
              }}
            />
          );
        })
      }
    </Tabs>
  );
};

export default EnhancedTabs;

import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../../../../app/store/helpers/functions';
import BackdropLoader from '../../../../../../../components/Loading/BackdropLoading';
import Stepper, { Step } from '../../../../../../../components/Stepper';
import Panel from '../../../SelectFieldPanel';
import { goToNextStep } from '../../../../zonesOpsSlice';
import { getZonesOpsLink } from '../../../../../../../helpers/navigation';
import { useGetFarmsQuery } from '../../../../../../farms/farmsAPI';
import { selectSelectedField, selectWorkflow } from '../../../../zonesOpsSelectors';

export default function SelectFieldStepper({
  steps,
  stepIndex,
}: {
  steps: Step[],
  stepIndex: number,
}) {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { isFetching: isFarmsFetching } = useGetFarmsQuery();
  const { farmUuid, fieldUuid } = useAppSelector(selectSelectedField);
  const workflow = useAppSelector(selectWorkflow);

  const nextDisabled = !fieldUuid || !farmUuid;

  const handleClickNext = () => {
    dispatch(goToNextStep());
    history.replace(getZonesOpsLink(farmUuid, fieldUuid, workflow));
  };

  return (
    <Stepper
      activeStep={stepIndex}
      steps={steps}
      backDisabled
      nextDisabled={nextDisabled}
      onClickNext={handleClickNext}
    >
      <Panel key="panel" />
      {
        isFarmsFetching
          ? <BackdropLoader key="loader" />
          : <Fragment/>
      }
    </Stepper>
  );
}

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AvgTotalCalibrateConditions from '../../../../../../AvgTotalCalibrateConditions';
import { useAppSelector } from '../../../../../../../../../../app/store/helpers/functions';
import { selectYieldDataset } from '../../../../../../../../../field/fieldSelectors';
import { selectDatasetUuid } from '../../../../../../../cleanCalibrateSelectors';
import { createFullAttributeItem } from '../../../../../../../helpers/functions/ui';

export default function CalibrateTabAverageTotalContent() {
  const { t } = useTranslation();

  const datasetUuid = useAppSelector(selectDatasetUuid);
  const dataset = useAppSelector((state) => selectYieldDataset(state, datasetUuid));
  const datasetAttributes = useMemo(() => {
    return (dataset?.fullAttributes || []).map((fullAttribute) => {
      return createFullAttributeItem(fullAttribute).value;
    });
  }, [dataset]);

  return (
    <AvgTotalCalibrateConditions
      title={t('clean-calibrate.yield-popup.attributes')}
      typeSelectorTitle={t('clean-calibrate.yield-popup.tabs.calibrate.configure.avg-total-conditions.types.title-avg-total')}
      availableAttributes={datasetAttributes}
    />
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';

import { OperationType } from '../../helpers/constants/operation';
import { getOperationTypeLink } from '../../helpers/functions/navigation';
import { formatDateTime } from '../../../../../helpers/functions/utils/date';

import './index.scss';

export default function OperationTypeItem({
  type,
  lastUpdate,
  operations: {
    inProgress,
    done,
    error,
    ignored,
  },
}: {
  type: OperationType,
  lastUpdate: string,
  operations: {
    inProgress: number,
    done: number,
    error: number,
    ignored: number,
  },
}) {
  const { t } = useTranslation();
  const { search } = useLocation();
  const total = inProgress + done + error + ignored;

  return (
    <li>
      <NavLink
        className="operations-type-item"
        activeClassName="operations-type-item_selected"
        to={getOperationTypeLink(type, search)}
      >
        <div className="operations-type-item__header">
          <Typography className="operations-type-item__header-title">
            {t(`operations.types.${type}`)}
          </Typography>
          <Typography className="operations-type-item__header-info">
            {formatDateTime(lastUpdate)}
          </Typography>
        </div>
        <div className="operations-type-item__summary">
          <div className={clsx('operations-type-item__summary-item', {
            'operations-type-item__summary-item_total': total > 0,
          })}
          >
            <Typography className="operations-type-item__summary-item-header">
              {t('operations.statuses.total')}
            </Typography>
            <Typography className="operations-type-item__summary-item-amount">
              {total}
            </Typography>
          </div>
          <div className="operations-type-item__summary-item">
            <Typography className="operations-type-item__summary-item-header">
              {t('operations.statuses.in-progress')}
            </Typography>
            <Typography className="operations-type-item__summary-item-amount">
              {inProgress}
            </Typography>
          </div>
          <div className={clsx('operations-type-item__summary-item', {
            'operations-type-item__summary-item_success': done > 0,
          })}
          >
            <Typography className="operations-type-item__summary-item-header">
              {t('operations.statuses.success')}
            </Typography>
            <Typography className="operations-type-item__summary-item-amount">
              {done}
            </Typography>
          </div>
          <div className={clsx('operations-type-item__summary-item', {
            'operations-type-item__summary-item_ignored': ignored > 0,
          })}
          >
            <Typography className="operations-type-item__summary-item-header">
              {t('operations.statuses.ignored')}
            </Typography>
            <Typography className="operations-type-item__summary-item-amount">
              {ignored}
            </Typography>
          </div>
          <div className={clsx('operations-type-item__summary-item', {
            'operations-type-item__summary-item_error': error > 0,
          })}
          >
            <Typography className="operations-type-item__summary-item-header">
              {t('operations.statuses.error')}
            </Typography>
            <Typography className="operations-type-item__summary-item-amount">
              {error}
            </Typography>
          </div>
        </div>
      </NavLink>
    </li>
  );
}

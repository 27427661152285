import React, {
  Suspense,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Panel from '../../../Panel/Settings';
import Loading from '../../../../../../../components/Loading';
import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import Stepper from '../../../../../../../components/Stepper';
import ZonesCreation from '../../../../../../../components/OpsPanel/ZonesCreation';
import {
  updateSettings,
} from '../../../../createSingleLayerAnalysisSlice';
import {
  goToPrevStep,
} from '../../../../../zonesOps/zonesOpsSlice';
import {
  updateVectorAnalysisMap,
} from '../../../../../../createAnalysis/createAnalysisSlice';
import { selectSettings } from '../../../../createSingleLayerAnalysisSelectors';
import {
  selectGeneratedVectorAnalysisMap,
  selectSaveInProgress,
} from '../../../../../../createAnalysis/createAnalysisSelectors';
import useGenerateSingleLayerVamap from '../../../../hooks/useGenerateSingleLayerVamap';
import { selectAreaUnit } from '../../../../../../user/userSelectors';
import { selectArea } from '../../../../../../field/fieldSelectors';

const Map = React.lazy(() => import('../../../Map'));

const StepperSettings = ({
  steps,
  stepIndex,
  step,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    name,
    numberOfZones,
    polygonMinArea,
    dataClassificationType,
    useInterpolatedData,
  } = useSelector(selectSettings);
  const generatedVamap = useSelector(selectGeneratedVectorAnalysisMap);
  const saveInProgress = useSelector(selectSaveInProgress);
  const areaUnit = useSelector(selectAreaUnit);
  const fieldArea = useSelector(selectArea);
  const generateVamap = useGenerateSingleLayerVamap();
  const runAnalysisDisabled = !name || !numberOfZones;

  const onClickBack = () => {
    dispatch(goToPrevStep());
  };
  const onClickNext = () => {
    dispatch(updateVectorAnalysisMap({ name }));
  };
  const onRunAnalysis = () => {
    generateVamap();
  };
  const onZonesOpsPropChange = useCallback((prop, value) => {
    dispatch(updateSettings({
      prop,
      value,
    }));
  }, [dispatch]);

  return (
    <>
      <Stepper
        activeStep={stepIndex}
        steps={steps}
        nextDisabled={!generatedVamap}
        nextLabel={t('zones-ops.common.finish')}
        onClickBack={onClickBack}
        onClickNext={onClickNext}
      >
        <ZonesCreation
          key="tools-panel"
          name={name}
          areaUnit={areaUnit}
          fieldArea={fieldArea}
          numberOfZones={numberOfZones}
          dataClassificationType={dataClassificationType}
          polygonMinArea={polygonMinArea}
          useInterpolatedData={useInterpolatedData}
          runAnalysisDisabled={runAnalysisDisabled}
          onPropChange={onZonesOpsPropChange}
          onRunAnalysis={onRunAnalysis}
        />
        <Panel key="panel" />
        <Suspense
          key="map"
          fallback={<Loading />}
        >
          <Map step={step} />
        </Suspense>
      </Stepper>
      {saveInProgress && <BackdropLoading />}
    </>
  );
};

export default StepperSettings;

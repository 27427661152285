import { JohnDeereOperationType } from '../../../jdIntegration/helpers/constants';

export const FOR_PERIOD_LABEL = 'for-the-period';

export const getAvailableFilterTypeOptions = (selectedFilterType, t) => {
  const allFilterTypes = [
    { title: t('integrations.jd.field-operations.types.all'), value: 'all' },
  ];
  const filterTypes = [
    {
      title: t('integrations.jd.field-operations.types.application'),
      value: JohnDeereOperationType.application,
    },
    {
      title: t('integrations.jd.field-operations.types.harvest'),
      value: JohnDeereOperationType.harvest,
    },
    {
      title: t('integrations.jd.field-operations.types.seeding'),
      value: JohnDeereOperationType.seeding,
    },
    // Tillage data is disabled
    // { title: t('integrations.jd.field-operations.types.tillage'), value: 'TILLAGE' },
  ];

  let availableFilterTypes;
  if (selectedFilterType.length > 0) {
    if (selectedFilterType[0].value === allFilterTypes[0].value) {
      availableFilterTypes = allFilterTypes;
    } else {
      availableFilterTypes = filterTypes;
    }
  } else {
    availableFilterTypes = [...allFilterTypes, ...filterTypes];
  }

  return availableFilterTypes;
};

const filterPeriods = (() => {
  const DEFAULT_START_RANGE_YEAR = 2015;
  const DEFAULT_END_RANGE_YEAR = 2022;
  const currentYear = new Date().getFullYear();
  const endRangeYear = currentYear > DEFAULT_END_RANGE_YEAR ? currentYear : DEFAULT_END_RANGE_YEAR;

  const yearFilterPeriods = [];

  for (let year = endRangeYear, index = 0; year >= DEFAULT_START_RANGE_YEAR; year--, index++) {
    yearFilterPeriods.push({ index, title: `${year}`, value: year });
  }

  return yearFilterPeriods;
})();

export const getAvailableFilterPeriodOptions = (selectedFilterPeriod, t) => {
  const allFilterPeriods = [
    { title: t('general.popups.import-settings-john-deere.select-filters.period.periods.all'), value: 'all' },
  ];
  const rangeFilterPeriods = [
    {
      title: t('general.popups.import-settings-john-deere.select-filters.period.periods.for-the-period'),
      value: FOR_PERIOD_LABEL,
    },
  ];

  let availableFilterPeriods;
  if (selectedFilterPeriod.length > 0) {
    if (selectedFilterPeriod[0].value === allFilterPeriods[0].value) {
      availableFilterPeriods = allFilterPeriods;
    } else if (selectedFilterPeriod[0].value === rangeFilterPeriods[0].value) {
      availableFilterPeriods = rangeFilterPeriods;
    } else {
      availableFilterPeriods = [...filterPeriods];
    }
  } else {
    availableFilterPeriods = [...allFilterPeriods, ...filterPeriods, ...rangeFilterPeriods];
  }

  return availableFilterPeriods;
};

export const isAvailableFilterPeriodRange = (selectedFilterPeriod) => {
  return selectedFilterPeriod.length === 1 && selectedFilterPeriod[0].value === FOR_PERIOD_LABEL;
};

export const getAvailableFilterPeriodFromOptions = (filterPeriodTill) => {
  let availableFilterPeriodFrom;
  if (filterPeriodTill) {
    availableFilterPeriodFrom = filterPeriods.filter((filterPeriod) => filterPeriod.index > filterPeriodTill.index);
  } else {
    availableFilterPeriodFrom = [...filterPeriods];
  }

  return availableFilterPeriodFrom;
};

export const getAvailableFilterPeriodTillOptions = (filterPeriodFrom) => {
  let availableFilterPeriodTill;
  if (filterPeriodFrom) {
    availableFilterPeriodTill = filterPeriods.filter((filterPeriod) => filterPeriod.index < filterPeriodFrom.index);
  } else {
    availableFilterPeriodTill = [...filterPeriods];
  }

  return availableFilterPeriodTill;
};

export const isValidFilterForm = (filterType, filterPeriod, filterPeriodFrom, filterPeriodTill) => {
  return filterType.length > 0
    && filterPeriod.length > 0
    && (
      filterPeriod[0].value !== FOR_PERIOD_LABEL
      || (filterPeriod[0].value === FOR_PERIOD_LABEL && filterPeriodFrom && filterPeriodTill)
    );
};

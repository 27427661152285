import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import TablePanel from '../../../../../components/TablePanel';
import Button from '../../../../../components/Button';
import { selectSelectedFarms } from '../../userFarmsSelectors';
import { toggleSelectionMode } from '../../userFarmsSlice';
import { openPopup } from '../../../popups/popupsSlice';
import { selectUserAdminOrAdvisor } from '../../../../user/userSelectors';

import './index.scss';

const UserFarmsTablePanel = ({
  selectedUserFarms,
  onCancelClick,
  onShareClick,
}) => {
  const { t } = useTranslation();

  return (
    <TablePanel
      elementsLeft={[
        <Typography
          key="description"
          classes={{
            root: 'user-farms-table-panel__description',
          }}
        >
          {t('user-farms.own-farms.table-panel.description')}
        </Typography>,
      ]}
      elementsRight={[
        <Button
          key="cancel"
          variant="outlined"
          classes={{
            root: 'user-farms-table-panel__button user-farms-table-panel__button_cancel',
          }}
          onClick={onCancelClick}
        >
          {t('general.controls.cancel')}
        </Button>,
        <Button
          key="export"
          variant="contained"
          color="primary"
          disabled={selectedUserFarms.length === 0}
          classes={{
            root: 'user-farms-table-panel__button',
          }}
          onClick={onShareClick}
        >
          {t('general.controls.share')}
        </Button>,
      ]}
      classes={{
        root: 'user-farms-table-panel',
      }}
    />
  );
};

const mapStateToProps = (state) => {
  const selectedUserFarms = selectSelectedFarms(state);
  const isAdminOrAdvisor = selectUserAdminOrAdvisor(state);

  return {
    selectedUserFarms,
    isAdminOrAdvisor,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCancelClick: () => {
    dispatch(toggleSelectionMode());
  },
  onShareClick: (selectedUserFarms, isAdminOrAdvisor) => {
    if (isAdminOrAdvisor) {
      dispatch(openPopup({
        type: 'share-farms',
        farmUuids: selectedUserFarms,
        onConfirm: () => {
          dispatch(toggleSelectionMode());
        },
      }));
    } else {
      dispatch(openPopup({
        type: 'share-farms-external',
        farmUuids: selectedUserFarms,
        onConfirm: () => {
          dispatch(toggleSelectionMode());
        },
      }));
    }
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,
  onShareClick: () => propsFromDispatch.onShareClick(
    propsFromState.selectedUserFarms,
    propsFromState.isAdminOrAdvisor,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(UserFarmsTablePanel);

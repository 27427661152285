export enum GeoMapTypeOption {
  default = 'DEFAULT',
  crop = 'CROPPED',
  contrast = 'CONTRAST',
}

export const DEFAULT_GEOMAP_TYPE_PREFIX = '';

export const RAW_TYPE_PREFIX = 'raw_';

export enum GeoMapTypeVariant {
  cropStatus = 'cropStatus',
  contrastStatus = 'contrastStatus',
  rawStatus = 'rawStatus',
  rawCropStatus = 'rawCropStatus',
  rawContrastStatus = 'rawContrastStatus',
}

import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function EditFieldBoundaryIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M2.75 4.58333C2.75 5.26193 3.11868 5.85441 3.66667 6.1714V15.8286C3.11868 16.1456 2.75 16.7381 2.75 17.4167C2.75 18.4292 3.57081 19.25 4.58333 19.25C5.26192 19.25 5.8544 18.8813 6.1714 18.3333H11.4999V16.3333H6.06251C5.95154 16.1821 5.81793 16.0485 5.66667 15.9375V6.06251C5.81793 5.95154 5.95154 5.81793 6.06251 5.66667H15.9375C16.0485 5.81791 16.1821 5.95151 16.3333 6.06247L16.3333 10.0833L16.3333 10.7857L18.3333 9.07137L18.3333 6.17143C18.8814 5.85445 19.2501 5.26195 19.2501 4.58333C19.2501 3.57081 18.4292 2.75 17.4167 2.75C16.7381 2.75 16.1456 3.11868 15.8287 3.66667H6.1714C5.85441 3.11868 5.26193 2.75 4.58333 2.75C3.57081 2.75 2.75 3.57081 2.75 4.58333ZM19.7925 11.4056L20.8467 12.4598C21.3875 12.9915 21.3875 13.8623 20.8467 14.4031L15.9975 19.2523H13V16.2548L17.8492 11.4056C18.3808 10.8648 19.2517 10.8648 19.7925 11.4056ZM14.4208 17.8315H15.365L18.5275 14.669L17.5833 13.7248L14.4208 16.8873V17.8315Z" />
    </SvgIcon>
  );
}

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getPricingPageLink } from '../../../../../helpers/navigation';
import { convertNumberToFormattedString } from '../../../../../helpers/markup';
import { getI18nAreaAmount } from '../../../../user/helpers/functions/user';
import { selectMaxArea, selectAreaUnit, selectLastPaidOrder } from '../../../../user/userSelectors';
import { useAppSelector } from '../../../../../app/store/helpers/functions';
import { PricingPlan } from '../../../../user/helpers/constants/order';
import { getDemoFormLink } from '../../../../../helpers/functions/utils/appConfig';
import Link from '../../../../../components/Link';

export default function AreaLimitMessage() {
  const { t } = useTranslation();

  const maxArea = useAppSelector(selectMaxArea);
  const areaUnit = useAppSelector(selectAreaUnit);
  // @ts-expect-error
  const lastPaidOrder = useAppSelector(selectLastPaidOrder);
  const areaUnitLabel = getI18nAreaAmount(areaUnit).toLowerCase();
  const maxAreaLabel = `${convertNumberToFormattedString(maxArea.toFixed(2))}`;
  const plan = lastPaidOrder?.plan;
  let actionLabel;
  let action;

  if (plan === PricingPlan.MonthlyPayAsYouGo || plan === PricingPlan.Enterprise) {
    actionLabel = t('general.notifications.max-area-reached.contact-us');
    action = (
      <a href={getDemoFormLink()} className="link" target="_blank">
        {t('general.notifications.max-area-reached.contact-us')}
      </a>
    );
  } else {
    actionLabel = t('general.notifications.max-area-reached.upgrade-plan');
    action = (
      <Link to={getPricingPageLink()}>
        {t('general.notifications.max-area-reached.upgrade-plan')}
      </Link>
    );
  }

  return (
    <Trans
      i18nKey="general.notifications.max-area-reached.notification"
      components={{ 5: action }}
      values={{
        area: maxAreaLabel,
        areaUnit: areaUnitLabel,
        actionLabel,
      }}
    >
    </Trans>
  );
}

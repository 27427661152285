import React from 'react';

import TreeCheckbox from './TreeCheckbox';
// rule disable added due to component structure (recursive definition)
import TreeGroup from './TreeGroup'; // eslint-disable-line import/no-cycle
import TreeText from './TreeText';

import './index.scss';

const Tree = ({
  items,
  onClick = () => {},
  onSelect = () => {},
}) => {
  return (
    <div className="tree">
      {
        items.map((item) => {
          const itemProps = {
            key: item.uuid,
            item,
            onClick,
            onSelect,
          };

          if (Array.isArray(item.items)) {
            return (<TreeGroup key={item.uuid} {...itemProps} />);
          }

          if (item.hasCheckbox) {
            return (<TreeCheckbox key={item.uuid} {...itemProps} />);
          }

          return (<TreeText key={item.uuid} {...itemProps} />);
        })
      }
    </div>
  );
};

export default Tree;

export enum View {
  logIn = 'log-in',
  signUp = 'sign-up',
  signUpSent = 'sign-up-sent',
  signUpConfirm = 'sign-up-confirm',
  forgotPassword = 'forgot-password',
  forgotPasswordSent = 'forgot-password-sent',
  recoverPassword = 'recover-password',
  recoverPasswordConfirm = 'recover-password-confirm',
  newPassword = 'new-password',
}

export const DEFAULT_VIEW = View.logIn;

export const MIN_PASSWORD_LENGTH = 8;

export enum AuthChallenge {
  newPasswordRequired = 'NEW_PASSWORD_REQUIRED',
}

export enum Provider {
  google = 'Google',
  johnDeere = 'JohnDeere',
  mhp = 'MHP',
}

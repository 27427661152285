import React from 'react';
import { connect } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ZonesMapResultsPlaceholder from '../../../../../../components/ZonesMapResultsPlaceholder';
import Tooltip from '../../../../../../components/Tooltip';
import ImpactedAreaTooltip from '../../../components/Tooltips/ImpactedAreaTooltip';
import { getI18nAreaUnit } from '../../../../../../helpers';
import { selectAreaUnit } from '../../../../../user/userSelectors';
import {
  selectNotEmptySelectedVectorAnalysisMaps,
  selectSelectedVamaps,
} from '../../../crossLayerSelectors';
import {
  selectGeneratedVectorAnalysisMap,
} from '../../../../../createAnalysis/createAnalysisSelectors';

import './index.scss';

const CrossLayerSettingsPanel = ({
  loaded,
  vamapRows = [],
  totalArea,
  areaUnit,
}) => {
  const { t } = useTranslation();

  if (!loaded) {
    return (
      <div className="cross-layer-settings-panel">
        <ZonesMapResultsPlaceholder />
      </div>
    );
  }

  return (
    <div className="cross-layer-settings-panel">
      <div className="cross-layer-settings-panel__table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('general.controls.map-legend.name')}</TableCell>
              <TableCell align="right">
                <Tooltip tooltip={<ImpactedAreaTooltip />}>
                  <Trans i18nKey="general.controls.map-legend.impacted-area">
                    Impacted <span className="nowrap">Area %</span>
                  </Trans>
                </Tooltip>
              </TableCell>
              <TableCell
                align="right"
                className="color-head"
              >
                {t('general.controls.map-legend.zones')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vamapRows.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell align="right">{row.impactedArea}</TableCell>
                  <TableCell
                    align="right"
                    className="color-cell cell"
                  >
                    {row.zones.map((color, ind) => {
                      return (
                        <span
                          key={ind}
                          className="color"
                          style={{ backgroundColor: color }}
                        ></span>
                      );
                    })}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div className="cross-layer-settings-panel__data">
        <div className="data-item">
          <span className="data-item__name">{t('zones-ops.cross-layer.steps.4.operation-result-area')}</span>
          <span>{totalArea.toFixed(2)} {t(`general.area-unit.${getI18nAreaUnit(areaUnit)}`)}</span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const generatedVectorAnalysisMap = selectGeneratedVectorAnalysisMap(state);
  const selectedVamaps = selectSelectedVamaps(state);
  const totalArea = generatedVectorAnalysisMap?.area;
  const loaded = generatedVectorAnalysisMap?.zonesMapGeojson;
  let vamapRows;

  if (loaded) {
    vamapRows = selectNotEmptySelectedVectorAnalysisMaps(state)
      .map((vamap) => {
        const zones = vamap.geoMaps.find((geoMap) => {
          return geoMap.shortName === 'zones';
        });
        const selectedArea = vamap.attributes.features
          .filter((feature) => {
            return selectedVamaps[vamap.uuid].includes(feature.properties.zone);
          })
          .reduce((acc, curr) => {
            return acc + curr.properties.attributes.area;
          }, 0);

        return {
          name: vamap.name,
          impactedArea: (100 * (totalArea / selectedArea)).toFixed(2),
          zones: zones.hexColorCodes.filter((_color, ind) => {
            return selectedVamaps[vamap.uuid].includes(ind + 1);
          }),
        };
      });
  }

  return {
    loaded,
    vamapRows,
    totalArea,
    areaUnit: selectAreaUnit(state),
  };
};

export default connect(mapStateToProps)(CrossLayerSettingsPanel);

import i18n from 'i18next';
import { featureCollection } from '@turf/helpers';
import turfBbox from '@turf/bbox';
import turfBboxPolygon from '@turf/bbox-polygon';
import turfCombine from '@turf/combine';

import { getI18nAreaUnit } from '../../..';
import {
  convertSourcesToMapBoxFormat,
  encode,
} from '../../../mapbox/source';
import { formatAcquisitionDate } from '../../../satellite';
import { isPublishedStatus } from '../../../data';
import {
  isSatelliteImage,
  isVectorAnalysis,
  isSoilDataset,
  isYieldDataset,
  isAsAppliedDataset,
  isTopographyMap,
  isField,
  isEquationMap,
  isDataset,
  isAssetAvailable,
} from '../../entities/assets';
import {
  DEFAULT_GEOMAP,
  GEOMAP_CONTROL_ITEMS,
} from '../../../constants/entities/equationMap';
import {
  getDatasetViewProps,
  getDatasetGeoMapName,
  getUnitLabel,
  getDatasetAttributeNameHandler,
  getFullAttribute,
  getDatasetLegendColors,
} from '../../entities/dataset';
import {
  convertAttributeToGeoMapName,
  getTopographyMapAttributeNameHandler,
  getTopographyMapViewProps,
} from '../../entities/topographyMap';
import { VIEW_TO_I18N_LABEL } from '../../../constants/entities/dataset';
import { isPlanetImage, getSatelliteImageViewProps } from '../../entities/satelliteImage';
import { getValueOptionsArray } from '../uiList';
import { comparator } from '../../utils/string';
import { GeoMapTypeOption } from '../../../../features/satelliteImages/helpers/constants/geoMapType';
import { PLANET_PROVIDER } from '../../../../features/satelliteImages/helpers/constants';
import { convertDateToDisplayFormat } from '../../utils/date';
import { convertNumberToFormattedString } from '../../../markup';
import { AssetGroupType } from '../../../constants/entities/asset';
import { isNumber } from '../../utils/number';
import { TileSize } from '../../../constants/map';
import { getSatelliteImageLegendColors } from '../../../../features/satelliteImages/helpers/functions/ui';

const PADDING_BOUNDS = {
  xl: { farm: 80, field: 80 },
  lg: { farm: 80, field: 80 },
  md: { farm: 60, field: 60 },
  sm: { farm: 40, field: 40 },
  es: { farm: 20, field: 20 },
};

export const getPaddingBounds = (type = 'field', mapWidth = window.innerWidth) => {
  let paddingBounds = PADDING_BOUNDS.md;

  if (mapWidth >= 1200) {
    paddingBounds = PADDING_BOUNDS.xl;
  } else if (mapWidth >= 992) {
    paddingBounds = PADDING_BOUNDS.lg;
  } else if (mapWidth >= 768) {
    paddingBounds = PADDING_BOUNDS.md;
  } else if (mapWidth >= 576) {
    paddingBounds = PADDING_BOUNDS.sm;
  } else if (mapWidth < 576) {
    paddingBounds = PADDING_BOUNDS.es;
  }

  return paddingBounds[type];
};

export const generateConfig = (type, mapWidth) => ({
  center: [-50.07432111635774, 42.76133233705622],
  zoom: 0.8,
  maxPitch: 85,
  map: {
    fitBounds: [
      [-128, -46],
      [152, 82],
    ],
    fitBoundsOptions: {
      padding: getPaddingBounds(type, mapWidth),
    },
  },
  rasterSources: [],
  vectorSources: [],
  tileSize: TileSize.large,
});

export const prepareToDisplayBorderLayer = (field, provideBounds, mapWidth) => {
  const config = generateConfig('field', mapWidth);

  if (field.status !== 'INVALID') {
    const {
      centroid = {},
      boundingBox = [],
      geoMaps = [],
    } = field;

    if (centroid.longitude && centroid.latitude) {
      config.center = [centroid.longitude, centroid.latitude];
      config.zoom = 12;
    }

    if (boundingBox.length === 4) {
      config.map.fitBounds = [[boundingBox[0], boundingBox[1]], [boundingBox[2], boundingBox[3]]];
      config.map.fitBoundsOptions.padding = getPaddingBounds();

      if (provideBounds) {
        config.bounds = boundingBox;
      }
    }

    const geoMapData = geoMaps.find((geoMap) => {
      return geoMap.shortName === 'default';
    });
    if (geoMapData && geoMapData.url) {
      config.rasterSources.push(geoMapData.url);
      config.rasterSources = convertSourcesToMapBoxFormat(config.rasterSources, TileSize.large)
        .map((url) => ({ url }));
    }
  }

  return config;
};

export const prepareToDisplayFarmLayer = (isFarmSelected, fields = []) => {
  const config = generateConfig('farm');
  const rasterSourcesUuids = [];
  const bboxPolygons = [];

  fields.forEach((field) => {
    if (field.status === 'INVALID') {
      return;
    }

    if (field._polygon && field._bbox) {
      config.vectorSources.push(field._polygon);
      bboxPolygons.push(turfBboxPolygon(field._bbox));
    } else if (field.uuid && field.boundingBox) {
      rasterSourcesUuids.push(field.uuid);
      bboxPolygons.push(turfBboxPolygon(field.boundingBox));
    }
  });

  if (rasterSourcesUuids.length > 0) {
    const uuidsList = rasterSourcesUuids.map((uuid) => (`'${uuid}'`));
    const cqlFilter = encode([`CQL_FILTER=uuid IN (${uuidsList.join(', ')})`], 'CQL_FILTER');

    config.rasterSources.push(
      'https://api.geopard.tech/geo/map?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=image/png&TRANSPARENT=true'
      + `&${cqlFilter}&LAYERS=agri:field&exceptions=application/vnd.ogc.se_inimage`
      + `&SRS=EPSG:3857&STYLES=&WIDTH=${TileSize.large}&HEIGHT=${TileSize.large}&BBOX=template`,
    );
    config.rasterSources = convertSourcesToMapBoxFormat(config.rasterSources, TileSize.large)
      .map((url) => ({ url }));
  }

  if (bboxPolygons.length > 0) {
    const boundingBox = turfBbox(turfCombine(featureCollection(bboxPolygons)));
    config.map.fitBounds = [[boundingBox[0], boundingBox[1]], [boundingBox[2], boundingBox[3]]];
  }

  if (isFarmSelected && bboxPolygons.length === 0) {
    config.map.fitBounds = null;
    config.center = null;
    config.zoom = null;
  }

  return config;
};

const getSatelliteOverviewItems = (viewType, statistics, compareToStatistics = []) => {
  if (!statistics) {
    return [];
  }

  const {
    index: _index,
    ...statistic
  } = statistics.find(({ index }) => {
    return index.toLowerCase() === viewType.toLowerCase();
  }) || {};
  const {
    index: _indexCompareTo,
    ...compareToStatistic
  } = compareToStatistics.find(({ index }) => {
    return index.toLowerCase() === viewType.toLowerCase();
  }) || {};

  return Object.entries(statistic)
    .reduce((acc, [key, value]) => {
      acc.push({
        label: i18n.t(`general.controls.map-legend.statistics.${key}`),
        value: convertNumberToFormattedString(parseFloat(value.toFixed(3)).toString()),
        delta: compareToStatistic?.[key]
          ? (value - compareToStatistic[key]).toFixed(3)
          : null,
      });

      return acc;
    }, []);
};

const getSatelliteImageToCompare = (activeImageUuid, satelliteImages = []) => {
  const activeImageIndex = satelliteImages.findIndex(({ uuid }) => uuid === activeImageUuid);
  const prevItems = satelliteImages.slice(activeImageIndex + 1);

  return prevItems.find((item) => !!item.statistics);
};

const isRawDisabled = (activeImage, geoMapType) => {
  if (geoMapType === GeoMapTypeOption.default) {
    return !isAssetAvailable(activeImage?.rawStatus);
  } if (geoMapType === GeoMapTypeOption.crop) {
    return !isAssetAvailable(activeImage?.rawCropStatus);
  } if (geoMapType === GeoMapTypeOption.contrast) {
    return !isAssetAvailable(activeImage?.rawContrastStatus);
  }

  return false;
};

const isNoCropData = (activeImage, isRawType) => {
  return isRawType
    ? !isAssetAvailable(activeImage?.rawCropStatus)
    : !isAssetAvailable(activeImage?.cropStatus);
};

const isNoContrastData = (activeImage, isRawType) => {
  return isRawType
    ? !isAssetAvailable(activeImage?.rawContrastStatus)
    : !isAssetAvailable(activeImage?.contrastStatus);
};

export const getSatelliteImageGeoMapTypeControl = ({
  activeImage,
  geoMapType,
  isRawType,
}) => ({
  enabled: true,
  cropHidden: isPlanetImage(activeImage),
  cropDisabled: isPlanetImage(activeImage) || isNoCropData(activeImage, isRawType),
  contrastDisabled: isNoContrastData(activeImage, isRawType) || !activeImage?.statistics,
  rawDisabled: isRawDisabled(activeImage, geoMapType),
  value: geoMapType,
});

export const getSatelliteImageMapProps = (activeImage, {
  geoMapType,
  isRawType,
  activeViewType,
  satelliteImages,
}, {
  isCreationView,
  isCompareView,
  showOverlayLayersControls,
} = {}) => {
  if (!activeImage) {
    return {};
  }

  const {
    viewTypes,
    viewType,
    viewValue,
  } = getSatelliteImageViewProps({
    image: activeImage,
    geoMapType,
    viewType: activeViewType,
    isRawType,
  });

  if (!viewType) {
    return {};
  }

  const selectedGeoMap = activeImage.geoMaps.find((geoMap) => {
    return geoMap.shortName === viewValue;
  });
  const legendItems = getSatelliteImageLegendColors(selectedGeoMap, viewType);
  const shouldHaveComparison = !isCompareView && !isCreationView;
  let imageToCompare;

  if (shouldHaveComparison) {
    imageToCompare = getSatelliteImageToCompare(activeImage.uuid, satelliteImages);
  }

  const overviewItems = getSatelliteOverviewItems(
    viewType,
    activeImage.statistics,
    imageToCompare?.statistics,
  );

  const attribution = activeImage.satelliteImage.provider === PLANET_PROVIDER
    ? i18n.t('general.planet-license.powered-by-planet')
    : '';

  return {
    indexesControl: {
      enabled: true,
      items: getValueOptionsArray(
        viewTypes,
        (vt) => vt.toUpperCase(),
      ),
      selectedItem: viewType,
    },
    showHideControl: {
      enabled: true,
    },
    miniLegendControl: {
      legend: {
        enabled: !!legendItems,
        items: legendItems,
      },
      overview: {
        enabled: overviewItems.length !== 0,
        items: overviewItems,
        ...(
          (imageToCompare && shouldHaveComparison)
            ? {
              compareTo: {
                item: imageToCompare,
                type: AssetGroupType.satelliteImages,
                text: formatAcquisitionDate(imageToCompare.satelliteImage.acquisitionDate),
              },
            }
            : null
        ),
      },
    },
    geoMapTypeControl: getSatelliteImageGeoMapTypeControl({
      activeImage,
      geoMapType,
    }),
    overlayLayersControls: {
      enabled: showOverlayLayersControls,
    },
    ...(
      selectedGeoMap
        ? {
          rasterSources: convertSourcesToMapBoxFormat([selectedGeoMap.url], TileSize.large),
          tileSize: TileSize.large,
        }
        : {}
    ),
    attribution,
  };
};

export const getZonesMapMapProps = (zonesMap, {
  showOverlayLayersControls,
  showHideControlEnabled = true,
} = {}) => {
  const overlayLayersControls = {
    enabled: showOverlayLayersControls,
  };

  if (!zonesMap || !zonesMap.geoMaps) {
    return { overlayLayersControls };
  }

  const rasterSources = zonesMap.geoMaps.find((geoMap) => {
    return geoMap.shortName === 'zones';
  });

  return {
    showHideControl: {
      enabled: showHideControlEnabled,
    },
    legendControl: {
      enabled: true,
    },
    scoutingAndSamplingControl: {
      enabled: true,
    },
    overlayLayersControls,
    ...(
      rasterSources
        ? {
          rasterSources: convertSourcesToMapBoxFormat([rasterSources.url], TileSize.large),
          tileSize: TileSize.large,
        }
        : {}
    ),
  };
};

export const getEquationMapMapProps = (equationMap, {
  activeGeoMap,
  showOverlayLayersControls,
  showHideControlEnabled = true,
}) => {
  if (!equationMap || !equationMap.geoMaps) {
    return {};
  }

  const selectedItem = activeGeoMap || DEFAULT_GEOMAP;
  const rasterSources = equationMap.geoMaps.find((geoMap) => {
    return geoMap.shortName === selectedItem;
  });

  return {
    showHideControl: {
      enabled: showHideControlEnabled,
    },
    legendControl: {
      enabled: true,
    },
    geoMapsControl: {
      enabled: true,
      items: GEOMAP_CONTROL_ITEMS,
      selectedItem,
    },
    overlayLayersControls: {
      enabled: showOverlayLayersControls,
    },
    ...(
      rasterSources
        ? {
          rasterSources: convertSourcesToMapBoxFormat([rasterSources.url], TileSize.small),
          tileSize: TileSize.small,
        }
        : {}
    ),
  };
};

const getStatisticsItems = (viewType, statistics) => {
  if (!statistics) {
    return [];
  }

  const {
    attribute: _attribute,
    ...statistic
  } = statistics.find(({ attribute }) => {
    return attribute.toLowerCase() === viewType.toLowerCase();
  }) || {};

  return Object.entries(statistic)
    .reduce((acc, [key, value]) => {
      if (isNumber(value)) {
        acc.push({
          label: i18n.t(`general.controls.map-legend.statistics.${key}`),
          value: convertNumberToFormattedString(parseFloat(value.toFixed(3)).toString()),
        });
      }

      return acc;
    }, []);
};

const getDatasetOverviewItems = (dataset, areaUnit) => {
  const overviewItems = [
    {
      label: i18n.t('general.controls.map-legend.dataset-creation-date'),
      value: dataset.operationFileDate ? convertDateToDisplayFormat(dataset.operationFileDate) : '',
    },
    {
      label: i18n.t('general.controls.map-legend.operation-start-date'),
      value: dataset.operationStartDate ? convertDateToDisplayFormat(dataset.operationStartDate) : '',
    },
    {
      label: i18n.t('general.controls.map-legend.percent-of-field-area'),
      value: isNumber(dataset.fieldCoverage) ? `${(dataset.fieldCoverage * 100).toFixed(2)}%` : '',
    },
    {
      label: i18n.t('general.controls.map-legend.completed-area'),
      value: dataset.fieldCoveredArea && dataset.fieldCoveredArea !== -1
        ? `${convertNumberToFormattedString(dataset.fieldCoveredArea.toFixed(3))} ${getI18nAreaUnit(areaUnit)}`
        : '',
    },
    {
      label: i18n.t('general.controls.map-legend.amount-of-geometries'),
      value: dataset.number ? convertNumberToFormattedString(dataset.number.toString()) : '',
    },
  ];

  return overviewItems.filter((item) => item.value);
};

export const getFieldDatasetMapProps = (activeDataset, {
  attribute,
  view,
  areaUnit,
  isCreationView,
  showOverlayLayersControls,
  enableScoutingAndSamplingControl,
}) => {
  if (!activeDataset) {
    return {};
  }

  const {
    attributes: processedAttributes,
    attribute: processedAttribute,
    views: processedViews,
    view: processedView,
  } = getDatasetViewProps(activeDataset, attribute, view);

  let result = {
    showHideControl: {
      enabled: true,
    },
    scoutingAndSamplingControl: {
      enabled: enableScoutingAndSamplingControl,
    },
    overlayLayersControls: {
      enabled: showOverlayLayersControls,
    },
  };

  if (processedAttribute) {
    const selectedGeoMap = activeDataset.geoMaps.find((geoMap) => {
      return geoMap.shortName === getDatasetGeoMapName(processedView, processedAttribute);
    });
    const legendItems = getDatasetLegendColors(selectedGeoMap);
    const nameHandler = getDatasetAttributeNameHandler(activeDataset.fullAttributes);
    const fullAttribute = getFullAttribute(processedAttribute, activeDataset.fullAttributes);
    const statisticsItems = getStatisticsItems(processedAttribute, activeDataset.statistics);
    const overviewItems = getDatasetOverviewItems(activeDataset, areaUnit);

    result = {
      ...result,
      datasetViewControl: {
        enabled: true,
        data: {
          attributes: isCreationView
            ? []
            : getValueOptionsArray(
              processedAttributes,
              nameHandler,
            )
              .sort((aAttr, bAttr) => {
                return comparator(aAttr.title, bAttr.title);
              }),
          selectedAttribute: processedAttribute,
          views: getValueOptionsArray(
            processedViews,
            (v) => i18n.t(VIEW_TO_I18N_LABEL[v]),
          ),
          selectedView: processedView,
        },
      },
      miniLegendControl: {
        legend: {
          enabled: !!legendItems,
          name: nameHandler(processedAttribute),
          unit: getUnitLabel(fullAttribute?.unit),
          items: legendItems,
        },
        statistics: {
          enabled: !!statisticsItems.length,
          items: statisticsItems,
        },
        overview: {
          enabled: !!overviewItems.length,
          items: overviewItems,
        },
      },
      rasterSources: convertSourcesToMapBoxFormat([selectedGeoMap.url], TileSize.small),
      tileSize: TileSize.small,
    };
  } else if (isPublishedStatus(activeDataset.status)) {
    const defaultSource = activeDataset.geoMaps.find((source) => source.shortName === 'default');

    result.rasterSources = defaultSource
      ? convertSourcesToMapBoxFormat([defaultSource.url], TileSize.small)
      : [];
    result.tileSize = TileSize.small;
  }

  return result;
};

export const getTopographyMapProps = (activeTopographyMap, {
  activeViewType,
}, {
  isCreationView,
  showViewTypeControl,
  showOverlayLayersControls,
} = {}) => {
  if (!activeTopographyMap) {
    return {};
  }

  const {
    attributes,
    attribute,
  } = getTopographyMapViewProps(activeTopographyMap, activeViewType, isCreationView);
  const selectedGeoMap = activeTopographyMap.geoMaps.find((geoMap) => {
    return geoMap.shortName === convertAttributeToGeoMapName(attribute);
  });
  const legendItems = getDatasetLegendColors(selectedGeoMap);
  const nameHandler = getTopographyMapAttributeNameHandler(activeTopographyMap.fullAttributes);
  const fullAttribute = getFullAttribute(attribute, activeTopographyMap.fullAttributes);

  return {
    ...(!isCreationView || showViewTypeControl
      ? {
        indexesControl: {
          enabled: true,
          items: getValueOptionsArray(
            attributes,
            nameHandler,
          ),
          selectedItem: attribute,
        },
      }
      : {}),
    showHideControl: {
      enabled: true,
    },
    miniLegendControl: {
      legend: {
        enabled: !!legendItems,
        name: nameHandler(attribute),
        unit: getUnitLabel(fullAttribute?.unit),
        items: legendItems,
      },
    },
    overlayLayersControls: {
      enabled: showOverlayLayersControls,
    },
    rasterSources: convertSourcesToMapBoxFormat([selectedGeoMap.url], TileSize.large),
    tileSize: TileSize.large,
    attribution: activeTopographyMap.source,
  };
};

const getFieldProps = () => {
  return {
    scoutingAndSamplingControl: {
      enabled: true,
    },
  };
};

export const prepareToDisplaySelectedItem = (item, {
  isCreationView = false,
  isCompareView = false,
  showTopographyViewTypeControl = false,
  showOverlayLayersControls = false,
  geoMapType = null,
  isRawType = false,
  satelliteViewType = '',
  soilRawProcessed = '',
  soilAttribute = '',
  yieldRawProcessed = '',
  yieldAttribute = '',
  asAppliedRawProcessed = '',
  asAppliedAttribute = '',
  topographyMapViewType = '',
  equationMapGeoMap = null,
  items = [],
  areaUnit,
}) => {
  let result = {};

  if (isSatelliteImage(item)) {
    result = getSatelliteImageMapProps(item, {
      geoMapType,
      isRawType,
      activeViewType: satelliteViewType,
      satelliteImages: items,
    }, {
      isCreationView,
      isCompareView,
      showOverlayLayersControls,
    });
  } else if (isVectorAnalysis(item)) {
    result = getZonesMapMapProps(item, { showOverlayLayersControls });
  } else if (isDataset(item)) {
    let attribute;
    let view;
    let additionalProps = {};

    if (isSoilDataset(item)) {
      attribute = soilAttribute;
      view = soilRawProcessed;
      additionalProps = {
        enableScoutingAndSamplingControl: true,
      };
    } else if (isYieldDataset(item)) {
      attribute = yieldAttribute;
      view = yieldRawProcessed;
    } else if (isAsAppliedDataset(item)) {
      attribute = asAppliedAttribute;
      view = asAppliedRawProcessed;
    }

    result = getFieldDatasetMapProps(item, {
      attribute,
      view,
      areaUnit,
      showOverlayLayersControls,
      ...additionalProps,
    });
  } else if (isTopographyMap(item)) {
    result = getTopographyMapProps(item, {
      activeViewType: topographyMapViewType,
    }, {
      isCreationView,
      showOverlayLayersControls,
      showViewTypeControl: showTopographyViewTypeControl,
    });
  } else if (isEquationMap(item)) {
    result = getEquationMapMapProps(item, {
      activeGeoMap: equationMapGeoMap,
      showOverlayLayersControls,
    });
  } else if (isField(item)) {
    result = getFieldProps();
  }

  return result;
};

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// rule disable added due to component structure (recursive definition)
import Tree from '..'; // eslint-disable-line import/no-cycle

const TreeGroup = ({
  item,
  onClick,
  onSelect,
}) => {
  return (
    <Accordion
      classes={{
        root: 'tree-item',
        expanded: 'tree-item_expanded',
      }}
      TransitionProps={{
        timeout: 300,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{
          root: 'tree-item-summary',
          content: 'tree-item-summary__content',
          expanded: 'tree-item-summary_expanded',
          expandIcon: 'tree-item-summary__icon',
        }}
        onClick={() => onClick(item)}
      >
        {item.title} {item.loading && <CircularProgress size={20} />}
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: 'tree-item-details',
        }}
      >
        <Tree
          items={item.items}
          onClick={onClick}
          onSelect={onSelect}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default TreeGroup;

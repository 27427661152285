import React from 'react';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import ButtonMenu from '../../../../../components/ButtonMenu';

import './index.scss';

const transformColors = (colors = {}) => {
  return Object.values(colors);
};

const ColorSchemaViewer = ({
  t,
  colorSchema,
  onSchemaActionClick = () => {},
}) => {
  return (
    <div className="color-schema-viewer">
      <Typography
        variant="body2"
        title={colorSchema.title}
        classes={{
          root: 'color-schema-viewer__title',
        }}
      >
        {colorSchema.title}
      </Typography>
      <div className="color-schema-viewer__content">
        <ul className="color-schema-viewer__content__colors">
          {
            transformColors(colorSchema.colors).map((color, index) => (
              <li
                key={index}
                className="color-schema-viewer__content__colors__color"
                style={{ backgroundColor: color }}
              >
              </li>
            ))
          }
        </ul>
        <ButtonMenu
          isIconButton
          size="small"
          items={[
            {
              id: 'edit',
              label: t('general.popups.edit'),
              IconComponent: EditIcon,
            },
            {
              id: 'delete',
              label: t('general.controls.delete'),
              IconComponent: DeleteIcon,
            },
          ]}
          classes={{
            iconButton: {
              root: 'color-schema-viewer__content__edit-button',
            },
            buttonMenuItem: {
              buttonLabel: 'color-schema-viewer__content__edit-menu-item',
            },
          }}
          onItemClick={(e) => onSchemaActionClick(e.id, colorSchema)}
        >
          <EditIcon color="primary" />
        </ButtonMenu>
      </div>
    </div>
  );
};

export default withTranslation()(ColorSchemaViewer);

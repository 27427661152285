export const getZonesMapsFilter = ({
  pageSize,
  lastEvaluatedKey,
  farm,
  field,
  type,
  purpose,
  labels,
  withRates,
  areaUnit,
}) => {
  return {
    filter: {
      pageSize,
      lastEvaluatedKey,
      farmUuid: (farm || {}).uuid,
      fieldUuid: (field || {}).uuid,
      type,
      purpose,
      labels: (labels || []).map(({ name, value }) => {
        return `${name}=${value}`;
      }),
      withRatesOnly: withRates,
    },
    areaUnit,
  };
};

import React from 'react';

import { getTopographyMapAttributeNameHandler } from '../../../helpers/functions/entities/topographyMap';
import DatasetList from '../DatasetList';

export default function TopographyMapList(props) {
  return (
    <DatasetList
      {...props}
      attributeNameHandler={getTopographyMapAttributeNameHandler}
    />
  );
}

import { useDispatch } from 'react-redux';

import { resetStepIndex as resetZonesOpsStepIndex } from '../zonesOpsSlice';
import { reset as resetCreateAnalysis } from '../../../createAnalysis/createAnalysisSlice';
import { resetOverlayLayers } from '../../overlayLayers/overlayLayersSlice';
import useResetZonesMapWorkflow from './useResetZonesMapWorkflow';

export default function useCreateAnotherAnalysis() {
  const dispatch = useDispatch();
  const resetZonesMapWorkflow = useResetZonesMapWorkflow();

  return () => {
    dispatch(resetZonesOpsStepIndex());
    dispatch(resetCreateAnalysis());
    dispatch(resetOverlayLayers());
    resetZonesMapWorkflow();
  };
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { closeSnackbar } from 'notistack';

import {
  selectFarmUuid,
  selectFieldUuid,
  selectIsZonesMapsLoaded,
  selectVectorAnalysisMap,
} from '../../field/fieldSelectors';
import {
  fetchVamapAssets,
  fetchVamapGeojson,
  fetchFieldVamapPins,
} from '../../field/fieldSlice';
import { selectAreaUnit } from '../../user/userSelectors';
import { getUserDataFetcher } from '../applicationShell/applicationShellSlice';
import { INSTRUMENTS } from './helpers/constants/instruments';
import { prepareFeatures } from './helpers/functions/geojson';
import { refreshVamapStatistics } from './zonesMapAPI';
import {
  selectLoading,
  selectUpdateInProgress,
  selectUpdates,
  selectUuid,
} from './zonesMapSelectors';
import { prepareZonesMapJson } from '../../../helpers/analysis';
import { hasGeojsonUpdates } from './helpers/functions/updates';
import { normalizeZonesMapGeojson } from '../../../helpers/functions/entities/geojson';
import { saveVamap } from './zonesMapActions';
import { CustomError } from '../../../helpers/functions/utils/errorHandling';
import {
  errorNotify,
  successNotify,
} from '../../notifications/helpers/functions/notify';
import { isTimeoutError } from './helpers/functions/api';
import { PlatformEventAction } from '../../subscription/helpers/constants/action';

const initialState = {
  notFound: false,
  loading: false,
  isLoaded: false,
  statisticsOutdated: false,
  updateInProgress: false,
  backTo: 'zonesMaps', // 'zonesMaps' | 'field'
  uuid: null,
  selectedZone: null,
  instrument: null,
  updates: {
    type: null,
    zonesMapGeojson: null,
    colors: null,
  },
};

export const fetchVamap = createAsyncThunk(
  'zonesMap/fetchVamap',
  ({
    farmUuid,
    fieldUuid,
    uuid,
  }, { dispatch, getState }) => {
    const state = getState();
    const zonesMap = selectVectorAnalysisMap(state, uuid);
    let promise = Promise.resolve();

    if (zonesMap) {
      if (!zonesMap.zonesMapGeojson) {
        promise = dispatch(fetchVamapGeojson({ uuid }));
      }
    } else if (
      selectIsZonesMapsLoaded(state)
        && !selectLoading(state)
        && selectFieldUuid(state) === fieldUuid
        && selectFarmUuid(state) === farmUuid
    ) {
      dispatch(setNotFound());
    } else {
      promise = dispatch(fetchFieldVamapPins({
        farmUuid,
        fieldUuid,
        uuid,
      }))
        .then(() => {
          const newState = getState();
          const fetchedVamap = selectVectorAnalysisMap(newState, uuid);

          if (!fetchedVamap) {
            dispatch(setNotFound());

            return Promise.resolve();
          }

          return dispatch(fetchVamapAssets({
            farmUuid,
            fieldUuid,
            vamap: fetchedVamap,
          }));
        });
    }

    return promise.catch((error) => {
      errorNotify({
        error: new CustomError('[UI Zones Map] Unable fetch zones map.', {
          cause: error,
        }),
        dispatch,
      });
    });
  },
  {
    condition: (_, { getState }) => {
      const state = getState();
      const loading = selectLoading(state);

      return !loading;
    },
  },
);

export const refreshStatistics = createAsyncThunk(
  'zonesMap/refreshStatistics',
  async (_payload, { getState, dispatch }) => {
    try {
      await getUserDataFetcher();

      const state = getState();
      const uuid = selectUuid(state);
      const {
        zonesMapGeojson: zonesMapGeojsonUpdate,
      } = selectUpdates(state);
      const fieldUuid = selectFieldUuid(state);
      const areaUnit = selectAreaUnit(state);
      const zonesMapGeojson = {
        ...zonesMapGeojsonUpdate,
        features: prepareFeatures(zonesMapGeojsonUpdate.features),
      };

      const updatedZonesMapGeojson = await refreshVamapStatistics({
        fieldUuid,
        uuid,
        zonesMapGeojson,
        areaUnit,
      });

      successNotify({
        message: i18n.t('zones-map.notifications.zones-map-statistics-refreshed'),
      });

      return updatedZonesMapGeojson;
    } catch (error) {
      errorNotify({
        error: new CustomError('[UI Zones Map] Unable to refresh statistics.', {
          cause: error,
        }),
        dispatch,
      });

      throw error;
    }
  },
);

export const finalizeInstrument = ({ geojson, colors = null }) => (dispatch, getState) => {
  const state = getState();
  const uuid = selectUuid(state);
  const zonesMap = selectVectorAnalysisMap(state, uuid);
  const areaUnit = selectAreaUnit(state);
  const zonesMapGeojson = normalizeZonesMapGeojson(geojson, areaUnit);

  dispatch(finishInstrument({
    update: {
      zonesMapGeojson,
      colors,
    },
    geojsonUpdated: hasGeojsonUpdates(zonesMap, zonesMapGeojson),
  }));
};

export const subscription = (parsedEvent) => async (dispatch, getState) => {
  const {
    action,
    pathLength,
    vectorAnalysisMapUuid,
  } = parsedEvent;

  const state = getState();
  const uuid = selectUuid(state);
  const inProgress = selectUpdateInProgress(state);

  if (
    pathLength !== 3
    || action !== PlatformEventAction.modify
    || vectorAnalysisMapUuid !== uuid
  ) {
    return;
  }

  if (inProgress) {
    dispatch(finishSaving());
    closeSnackbar(uuid);
    successNotify({
      message: i18n.t('zones-map.notifications.zones-map-saved'),
    });
  }
};

export const zonesMapSlice = createSlice({
  name: 'zonesMap',
  initialState,
  reducers: {
    setInstrument(state, action) {
      state.instrument = action.payload;
    },
    setSelectedZone(state, action) {
      state.selectedZone = action.payload;
    },
    setNotFound(state) {
      state.notFound = true;
    },
    setBackTo(state, action) {
      state.backTo = action.payload;
    },
    setTypeUpdate(state, action) {
      state.updates.type = action.payload;
    },
    setZonesMapGeojsonUpdate(state, action) {
      state.updates.zonesMapGeojson = action.payload;
    },
    finishInstrument(state, action) {
      if (
        action.payload.geojsonUpdated
          && (
            state.instrument === INSTRUMENTS.MERGE_ZONES
                || state.instrument === INSTRUMENTS.DRAW_POLYGON
                || state.instrument === INSTRUMENTS.CREATE_ZONE
          )
      ) {
        state.statisticsOutdated = true;
      }

      state.instrument = initialState.instrument;
      state.updates.zonesMapGeojson = action.payload.update.zonesMapGeojson;
      state.updates.colors = action.payload.update.colors;
    },
    finishSaving(state) {
      state.updateInProgress = false;
    },
    resetUpdates(state) {
      state.updates = initialState.updates;
      state.statisticsOutdated = false;
    },
    resetInstrument(state) {
      state.instrument = initialState.instrument;
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVamap.pending, (state, action) => {
        state.loading = true;
        state.isLoaded = false;
        state.uuid = action.meta.arg.uuid;
      })
      .addCase(fetchVamap.fulfilled, (state, action) => {
        if (action.meta.arg.uuid !== state.uuid) {
          return state;
        }

        state.loading = false;
        state.isLoaded = true;
      })
      .addCase(refreshStatistics.pending, (state) => {
        state.updateInProgress = true;
      })
      .addCase(refreshStatistics.fulfilled, (state, action) => {
        state.updateInProgress = false;
        state.statisticsOutdated = false;
        state.updates.zonesMapGeojson = prepareZonesMapJson(JSON.parse(action.payload));
      })
      .addCase(refreshStatistics.rejected, (state) => {
        state.updateInProgress = false;
      })
      .addCase(saveVamap.pending, (state) => {
        state.updateInProgress = true;
      })
      .addCase(saveVamap.fulfilled, (state) => {
        state.updates = initialState.updates;
        state.statisticsOutdated = false;
        state.updateInProgress = false;
      })
      .addCase(saveVamap.rejected, (state, action) => {
        if (!isTimeoutError(action.payload)) {
          state.updateInProgress = false;
        }
      });
  },
});

const {
  finishSaving,
} = zonesMapSlice.actions;

export const {
  setInstrument,
  setSelectedZone,
  setNotFound,
  setBackTo,
  setTypeUpdate,
  setZonesMapGeojsonUpdate,
  finishInstrument,
  resetUpdates,
  resetInstrument,
  reset,
} = zonesMapSlice.actions;

export default zonesMapSlice.reducer;

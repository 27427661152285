import React, { ReactNode, KeyboardEvent } from 'react';
import clsx from 'clsx';

import { isEnterKeyPressedOnCurrentTarget } from '../../../../../../helpers/functions/utils/navigation';

import './index.scss';

export default function SimpleItem({
  children,
  isSelected,
  disabled,
  classes,
  onClick,
}: {
  children: ReactNode,
  isSelected?: boolean,
  disabled?: boolean,
  classes?: {
    wrapper?: string,
    button?: string,
    icon?: string,
  },
  onClick?: () => void,
}) {
  const handleClick = () => {
    if (disabled) {
      return;
    }

    onClick?.();
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!disabled && isEnterKeyPressedOnCurrentTarget(event)) {
      onClick?.();
    }
  };

  const itemButtonProps = onClick && !disabled
    ? { onClick: handleClick, onKeyDown: handleKeyDown, tabIndex: 0 }
    : {};

  return (
    <div className={clsx('list-simple-item__wrapper', classes?.wrapper)}>
      <div
        role="button"
        className={clsx('list-simple-item', classes?.button, {
          'list-simple-item_selected': isSelected,
          'list-simple-item_disabled': disabled,
        })}
        {...itemButtonProps}
      >
        {children}
      </div>
    </div>
  );
}

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Select, { SelectOption } from '../../../../../../../components/Select';
import { DataLayersListContext } from '../../../../hooks/useDataLayersList';
import { TransformedTopographyMap } from '../../../../../../../helpers/types/dataset';
import {
  extractTopographyMapGeoMapAttributes,
  getTopographyMapAttributeNameHandler,
} from '../../../../../../../helpers/functions/entities/topographyMap';

import './index.scss';

export default function CreateAnalysisTopographyMapsPanel({
  selectedAttribute,
  onAttributeChange,
}: {
  selectedAttribute: string,
  onAttributeChange: (attribute: string | null) => void,
}) {
  const { t } = useTranslation();

  const context = useContext(DataLayersListContext);
  const datasets = context.assets as TransformedTopographyMap[];
  const { selectedUuid } = context;
  const selectedTopographyMap = datasets.find((dataset) => dataset.uuid === selectedUuid);
  let options: SelectOption<string>[] = [];

  if (selectedTopographyMap) {
    const attributes = extractTopographyMapGeoMapAttributes(selectedTopographyMap);
    const nameHandler = getTopographyMapAttributeNameHandler(selectedTopographyMap.fullAttributes);
    options = attributes.map((attribute) => {
      return {
        value: attribute,
        title: nameHandler(attribute),
      };
    });
  }

  return (
    <div className="create-analysis-topography-maps-panel">
      <Select
        id="topography-map-attribute-selector"
        required
        disabled={!selectedTopographyMap}
        labelPosition="left"
        label={t('zones-ops.multi-layer.steps.4.settings-table.attribute')}
        value={selectedAttribute}
        options={options}
        onChange={onAttributeChange}
      />
    </div>
  );
}

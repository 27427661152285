import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT_VAMAPS_FILTER } from '../filters/helpers/constants/analysis';
import { DEFAULT_POLYGON_MIN_AREA, getCopyName } from '../../../helpers';
import {
  selectChanged,
  selectCloned,
  selectNotEmptySelectedVectorAnalysisMaps,
  selectSelectedVamaps,
  selectSettings,
} from './crossLayerSelectors';
import { ZonesOperationType } from '../../../helpers/constants/entities/vectorAnalysisMap';

const initialState = {
  _changed: false,
  _cloned: false,
  selectedVamaps: {},
  vamapsFilter: DEFAULT_VAMAPS_FILTER,
  legend: null,
  settings: {
    name: '',
    operation: ZonesOperationType.intersect,
    polygonMinArea: DEFAULT_POLYGON_MIN_AREA,
  },
};

export const navigateToSettings = () => (dispatch, getState) => {
  const state = getState();
  const changed = selectChanged(state);
  const cloned = selectCloned(state);
  const selectedVamaps = selectSelectedVamaps(state);
  const selectedMaps = selectNotEmptySelectedVectorAnalysisMaps(state);
  let { name } = selectSettings(state);

  if (changed) {
    const nameFromSelection = selectedMaps.reduce((acc, curr) => {
      return `${acc} ${curr.name} ${selectedVamaps[curr.uuid].join()}`;
    }, 'ZO');

    name = getCopyName(nameFromSelection, cloned);
  }

  dispatch(goToSettings({
    name,
    changed,
  }));
};

export const crossLayerSlice = createSlice({
  name: 'crossLayer',
  initialState,
  reducers: {
    goToSettings(state, action) {
      state._changed = false;
      state.settings.name = action.payload.name;
    },
    unselectVamap(state, action) {
      const {
        [action.payload]: vamapToUnselect,
        ...otherSelectedVamaps
      } = state.selectedVamaps;

      state._changed = true;
      state.selectedVamaps = otherSelectedVamaps;
    },
    toggleVamap(state, action) {
      const {
        [action.payload]: vamapToToggle,
        ...otherSelectedVamaps
      } = state.selectedVamaps;
      let update = null;

      if (!vamapToToggle) {
        update = {
          [action.payload]: [],
        };
      }
      state._changed = true;
      state.selectedVamaps = {
        ...otherSelectedVamaps,
        ...update,
      };
    },
    toggleVamapZone(state, action) {
      let {
        [action.payload.uuid]: selectedZones,
      } = state.selectedVamaps;
      const zoneIndexToRemove = selectedZones.indexOf(action.payload.zone);

      if (zoneIndexToRemove === -1) {
        selectedZones = [
          ...selectedZones,
          action.payload.zone,
        ];
      } else {
        selectedZones = selectedZones.filter((_zone, zoneIndex) => {
          return zoneIndex !== zoneIndexToRemove;
        });
      }

      state._changed = true;
      state.selectedVamaps[action.payload.uuid] = selectedZones;
    },
    setLegend(state, action) {
      state.legend = action.payload;
    },
    setVamapsFilter(state, action) {
      state.vamapsFilter = action.payload;
    },
    reset() {
      return initialState;
    },
    updateSettings(state, action) {
      state.settings[action.payload.prop] = action.payload.value;
    },
    cloneWorkflow(state, action) {
      state._cloned = true;
      state.settings = {
        ...state.settings,
        ...action.payload.settings,
      };
      state.index = action.payload.index;
      state.selectedVamaps = action.payload.zonesOperationMaps;
    },
  },
});

export default crossLayerSlice.reducer;

export const {
  goToSettings,
  unselectVamap,
  toggleVamap,
  toggleVamapZone,
  setLegend,
  setVamapsFilter,
  reset,
  updateSettings,
  cloneWorkflow,
} = crossLayerSlice.actions;

export const isAssetJDExportDisabled = ({
  jdProfileIsHealth,
  jdProfileIsAuthorized,
  isSynchronizedJohnDeereField,
}: {
  jdProfileIsHealth: boolean;
  jdProfileIsAuthorized: boolean;
  isSynchronizedJohnDeereField: boolean;
}) => {
  return !jdProfileIsHealth || !jdProfileIsAuthorized || !isSynchronizedJohnDeereField;
};

import React, {
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import ProductSelector from '../../components/ProductSelector';
import {
  selectApplication,
  selectJDWorkPlanType,
} from '../../jdWorkPlanSelectors';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';
import { setApplicationSettings } from '../../jdWorkPlanSlice';
import { selectJDField } from '../../../../field/fieldSelectors';
import { getJDFieldOrgId } from '../../../../field/helpers/functions/field';
import { useJohnDeereProducts } from '../../../../jdProducts/hooks/useJohnDeereProducts';
import { resetJohnDeereProductsCache } from '../../../../jdProducts/jdExportAPI';
import { getJohnDeereProductsManagerLink } from '../../helpers/functions/products';

const ApplicationProductSelector = ({
  applicationId,
}: {
  applicationId: string;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const workPlanType = useAppSelector(selectJDWorkPlanType);
  const {
    productId,
    productType,
  } = useAppSelector((state) => selectApplication(state, applicationId)) ?? {};
  const jdField = useAppSelector(selectJDField);
  const orgId = getJDFieldOrgId(jdField);

  const {
    products, isSuccess, isLoading,
  } = useJohnDeereProducts({
    productType,
    orgId,
  });

  const handleProductChange = (id: string | null) => {
    dispatch(setApplicationSettings({
      id: applicationId,
      application: { productId: id },
      workPlanType,
    }));
  };

  const handleProductsLinkClick = useCallback(() => {
    dispatch(resetJohnDeereProductsCache());
  }, [dispatch]);

  return (
    <ProductSelector
      products={products}
      selectedProductId={productId}
      isProductsLoading={isLoading}
      isProductsLoaded={isSuccess}
      productType={productType}
      noOptionsText={!productType
        ? t('general.popups.export-work-plan.application-work-plan.select-application-type-first')
        : ''}
      productManagerLink={getJohnDeereProductsManagerLink({
        orgId,
        productType,
      })}
      onProductsLinkClick={handleProductsLinkClick}
      onProductChange={handleProductChange}
    />
  );
};

export default ApplicationProductSelector;

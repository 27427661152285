import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import { Typography } from '@material-ui/core';

import TwoSidePanel from '../../../../components/TwoSidePanel';
import FederatedLogInPanel from '../common/FederatedLogIn';
import LogInForm from '../../../../components/LogInForm';
import { isSignUpEnabled } from '../../../../../../../helpers/functions/utils/appConfig';
import { prepareEmailAttribute } from '../../../../helpers/functions/authentication';
import {
  buildRedirectedFrom,
  getDefaultRoute,
  getRedirectedFrom,
} from '../../../../helpers/functions/navigation';
import { getAuthLink } from '../../../../../../../helpers/navigation';
import {
  AuthChallenge,
  View,
} from '../../../../helpers/constants/authentication';
import { errorNotify } from '../../../../../../notifications/helpers/functions/notify';
import { CustomError } from '../../../../../../../helpers/functions/utils/errorHandling';
import { LoginFormFields } from '../../../../types/ui';

import '../../../../components/common/index.scss';
import './index.scss';

const LogInPanel = ({
  onLoading,
}: {
  onLoading: (loading: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isSignUpAllowed = isSignUpEnabled();

  const handleLogInClick = async (formState: LoginFormFields) => {
    const preparedEmail = prepareEmailAttribute(formState.email);

    onLoading(true);

    try {
      const user = await Auth.signIn(preparedEmail, formState.password);
      const redirectedFrom = getRedirectedFrom(location.search);

      if (user.challengeName === AuthChallenge.newPasswordRequired) {
        onLoading(false);
        history.push({
          pathname: getAuthLink(View.newPassword),
          search: redirectedFrom ? buildRedirectedFrom(redirectedFrom) : '',
          state: {
            user,
          },
        });
      } else {
        const url = redirectedFrom || getDefaultRoute();

        history.replace(url);
      }
    } finally {
      onLoading(false);
    }
  };

  const handleLogInUnknownError = (e: Error) => {
    errorNotify({
      error: new CustomError('[UI Login] Unable to login user.', {
        cause: e,
      }),
      message: e.message,
      dispatch,
    });
  };

  const renderPanel = () => {
    if (isSignUpAllowed) {
      return (
        <TwoSidePanel
          left={<FederatedLogInPanel />}
          right={(
            <LogInForm
              isSignUpAllowed={isSignUpAllowed}
              onLogInClick={handleLogInClick}
              onLogInUnknownError={handleLogInUnknownError}
            />
          )}
        />
      );
    }
    return (
      <LogInForm
        isSignUpAllowed={isSignUpAllowed}
        onLogInClick={handleLogInClick}
        onLogInUnknownError={handleLogInUnknownError}
      />
    );
  };

  return (
    <div className="authentication-panel log-in-panel">
      <Typography
        classes={{
          root: 'authentication-panel__header',
        }}
        variant="h1"
      >
        Log in
      </Typography>
      {renderPanel()}
    </div>
  );
};

export default LogInPanel;

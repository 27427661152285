import React from 'react';

import { getZonesMapData } from '../../../../helpers/analysis';
import { isAttributesValid } from '../../../../helpers/functions/entities/geojson';
import LegendTable from '../Table';

import './index.scss';

const LegendZonesMapTable = ({
  areaUnit,
  attributes,
  colors,
}) => {
  if (!isAttributesValid(attributes)) {
    return null;
  }

  const {
    headers,
    rows,
  } = getZonesMapData({
    features: attributes.features,
    colors,
    areaUnit,
  });
  const [
    fixedCol,
    ...otherCols
  ] = headers;

  return (
    <div className="zones-map-legend-table">
      <div className="fixed-container">
        <LegendTable
          columns={[fixedCol]}
          rows={rows}
        />
      </div>
      <div className="scroll-container">
        <LegendTable
          columns={otherCols}
          rows={rows}
        />
      </div>
    </div>
  );
};

export default LegendZonesMapTable;

export const DEFAULT_GRID_SIZE = 30;

export enum TitleTemplateKey {
  equationName = 'equation-name',
  fieldName = 'field-name',
  currentDate = 'current-date',
  farmName = 'farm-name',
  custom = 'custom',
}

export const EQUATION_MAP_TITLE_TEMPLATE_OPTIONS = [
  { key: TitleTemplateKey.equationName, id: 'equation-name-template-option' },
  { key: TitleTemplateKey.fieldName, id: 'field-name-template-option' },
  { key: TitleTemplateKey.currentDate, id: 'currentDate-template-option' },
  { key: TitleTemplateKey.farmName, id: 'farm-template-option' },
];

export const DEFAULT_EQUATION_MAP_TITLE_TEMPLATE = [
  EQUATION_MAP_TITLE_TEMPLATE_OPTIONS[0],
  EQUATION_MAP_TITLE_TEMPLATE_OPTIONS[1],
  EQUATION_MAP_TITLE_TEMPLATE_OPTIONS[2],
];

import { useGetFarmsQuery } from '../farmsAPI';

export default function useSingleFarmFromList({
  farmUuid,
}: {
  farmUuid: string,
}) {
  return useGetFarmsQuery(undefined, {
    selectFromResult: ({ data }) => {
      return {
        farm: data?.find(({ uuid }) => uuid === farmUuid) || null,
      };
    },
  });
}

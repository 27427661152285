import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';

import { TransformedSatelliteImage } from '../../features/satelliteImages/types/satelliteImage';
import Button from '../Button';

import './index.scss';

type Mode = 'all' | 'last6yrs' | 'last10yrs';

const getDiffInYears = (date: string): number => {
  return (new Date()).getFullYear() - (new Date(date)).getFullYear();
};

const getRecommendedUuids = (images: TransformedSatelliteImage[], mode: Mode): string[] => {
  return images.filter((image) => {
    const yearsDiff = getDiffInYears(image.satelliteImage.acquisitionDate || '');

    return mode === 'all'
      || (mode === 'last10yrs' && yearsDiff <= 10)
      || (mode === 'last6yrs' && yearsDiff <= 6);
  })
    .map((image) => image.uuid);
};

export default function RecommendedSatelliteImagesPicker({
  allImages = [],
  filteredImages = [],
  onSelect,
}: {
  allImages?: TransformedSatelliteImage[],
  filteredImages?: TransformedSatelliteImage[],
  onSelect: (uuids: string[]) => void,
}) {
  const { t } = useTranslation();

  const isEmpty = useMemo(() => {
    return allImages.every((image) => !image.recomendedForMutliYearAnalytics);
  }, [allImages]);
  const recommendedImages = useMemo(() => {
    return filteredImages.filter((image) => image.recomendedForMutliYearAnalytics);
  }, [filteredImages]);
  const oldestRecommendedImage = recommendedImages[recommendedImages.length - 1];

  if (isEmpty) {
    return (
      <div className="recommended-satellite-images-picker">
        <p className="recommended-satellite-images-picker__empty-message">
          {t('general.controls.satellite-filters.no-recommended-for-field')}
        </p>
      </div>
    );
  }

  if (!oldestRecommendedImage) {
    return (
      <div className="recommended-satellite-images-picker">
        <p className="recommended-satellite-images-picker__empty-message">
          {t('general.controls.satellite-filters.no-recommended-for-filter')}
        </p>
      </div>
    );
  }

  const yearsDiff = getDiffInYears(oldestRecommendedImage.satelliteImage.acquisitionDate || '');
  const modes: { label: string, value: Mode }[] = [
    {
      label: t('general.controls.satellite-filters.for-all-time'),
      value: 'all',
    },
  ];

  if (yearsDiff >= 6) {
    modes.push({
      label: t('general.controls.satellite-filters.last-6-years'),
      value: 'last6yrs',
    });
  }

  if (yearsDiff >= 10) {
    modes.push({
      label: t('general.controls.satellite-filters.last-10-years'),
      value: 'last10yrs',
    });
  }

  return (
    <div className="recommended-satellite-images-picker">
      <div className="recommended-satellite-images-picker__title">
        <StarIcon color="primary" fontSize="small" />
        <Typography>{t('general.controls.satellite-filters.recommended')}:</Typography>
      </div>
      <div>
        {
          modes.map((mode) => {
            return (
              <Button
                key={mode.value}
                className="recommended-satellite-images-picker__button"
                onClick={() => onSelect(getRecommendedUuids(recommendedImages, mode.value))}
              >
                {mode.label}
              </Button>
            );
          })
        }
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from '@material-ui/core/Slider';
import clsx from 'clsx';

import { getDebouncer } from '../../../../../helpers/functions/utils/debouncer';
import TextField from '../../../../../components/TextField';

import './index.scss';

const marks = [...Array(11).keys()].map((v) => {
  const value = v / 10;

  return {
    value,
    label: value.toFixed(1),
  };
});

const MIN = 0;
const MAX = 1;
const STEP = 0.01;
const TIMEOUT = 15; // ms
const minInputDebouncer = getDebouncer(TIMEOUT);
const maxInputDebouncer = getDebouncer(TIMEOUT);

const NDVISlider = ({
  value,
  onChange,
  classes = {},
}) => {
  const { t } = useTranslation();
  const [innerValue, setInnerValue] = useState(value);
  const [isChanging, setIsChanging] = useState(false);

  useEffect(() => {
    if (isChanging) {
      return;
    }

    const [nextMin, nextMax] = value;

    if (
      nextMin !== innerValue[0]
      || nextMax !== innerValue[1]
    ) {
      setInnerValue([
        nextMin,
        nextMax,
      ]);
    }
  }, [value, isChanging, innerValue]);

  const handleInputChange = (v, debouncer) => {
    setIsChanging(true);
    setInnerValue(v);
    debouncer(() => {
      onChange(v);
      setIsChanging(false);
    });
  };

  return (
    <div className="ndvi-slider">
      <span className={clsx('ndvi-slider__label', classes.label)}>{t('general.controls.satellite-filters.ndvi')}</span>
      <div className="ndvi-slider__inputs">
        <TextField
          variant="outlined"
          type="number"
          InputProps={{
            inputProps: {
              min: MIN,
              max: MAX,
              step: STEP,
            },
          }}
          value={innerValue[0]}
          onChange={(e) => {
            const val = +e.target.value;

            if (val > innerValue[1]) {
              return;
            }

            const v = [
              val,
              innerValue[1],
            ];

            handleInputChange(v, minInputDebouncer);
          }}
        />
        <TextField
          variant="outlined"
          type="number"
          InputProps={{
            inputProps: {
              min: MIN,
              max: MAX,
              step: STEP,
            },
          }}
          value={innerValue[1]}
          onChange={(e) => {
            const val = +e.target.value;

            // WORKAROUND
            // use of min: innerValue[0] prop doesn't allow to change value (probably bag)
            if (val < innerValue[0]) {
              return;
            }

            const v = [
              innerValue[0],
              val,
            ];

            handleInputChange(v, maxInputDebouncer);
          }}
        />
      </div>
      <div className="ndvi-slider__slider-wrapper">
        <Slider
          max={MAX}
          step={STEP}
          classes={{
            root: 'ndvi-slider__slider',
            mark: 'mark',
            markLabel: 'mark-label',
            thumb: 'thumb',
            valueLabel: 'value-label',
          }}
          marks={marks}
          value={innerValue}
          onChange={(_e, v) => {
            setInnerValue(v);
            setIsChanging(true);
          }}
          onChangeCommitted={(_e, v) => {
            setIsChanging(false);
            setInnerValue(v);
            onChange(v);
          }}
        />
      </div>
    </div>
  );
};

export default NDVISlider;

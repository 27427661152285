import React, { MouseEvent } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import ButtonMenu from '../../../../../components/ButtonMenu';
import { ButtonMenuItem } from '../../../../../components/ButtonMenu/types';

import './index.scss';

export default function ItemActions({
  menuItems,
  onMenuItemClick,
}: {
  menuItems: ButtonMenuItem[],
  onMenuItemClick: (id: string) => void,
}) {
  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
  };

  const handleMenuItemClick = (menuItem: ButtonMenuItem, event: MouseEvent) => {
    event.stopPropagation();
    onMenuItemClick(menuItem.id);
  };

  if (menuItems.length === 0) {
    return <></>;
  }

  return (
    <ButtonMenu
      isIconButton
      edge="end"
      size="small"
      items={menuItems}
      classes={{
        iconButton: {
          root: 'tree-view-item-actions__menu-button',
          edgeEnd: 'tree-view-item-actions__menu-button_edge-end',
        },
      }}
      onClick={handleClick}
      onItemClick={handleMenuItemClick}
    >
      <MoreVertIcon fontSize="small" />
    </ButtonMenu>
  );
}

import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectStep } from '../../../../../cleanCalibrateSelectors';
import { setStep } from '../../../../../cleanCalibrateSlice';
import Button from '../../../../../../../../components/Button';

import './index.scss';

export default function FastTabLeftActions() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const step = useSelector(selectStep);

  const handleConfigProcessingClick = () => {
    dispatch(setStep(step + 1));
  };

  if (step !== 0) {
    return <Fragment />;
  }

  return (
    <Button
      className="fast-tab-left-actions__configure-button"
      variant="text"
      color="primary"
      onClick={handleConfigProcessingClick}
    >
      {t('clean-calibrate.yield-popup.tabs.fast.config-processing')}
    </Button>
  );
}

import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';

import CloneIcon from '../../components/Icons/clone.svg';
import ExportIcon from '../../../../../components/Icons/export';
import RenameIcon from '../../../../../components/Icons/rename';
import Button from '../../../../../components/Button';
import ButtonMenu from '../../../../../components/ButtonMenu';
import Tabs from '../../../../../components/Tabs';
import ToolsPanel from '../../../../../components/ToolsPanel';
import { resetUpdates } from '../../zonesMapSlice';
import { saveVamap } from '../../zonesMapActions';
import { prepareCloneWorkflow } from '../../../cloneZonesMap/cloneZonesMapSlice';
import {
  getRootLink,
  getZonesOpsLink,
  PAGES_ROOTS,
  updateSearchParam,
} from '../../../../../helpers/navigation';
import { openPopup } from '../../../popups/popupsSlice';
import { getViewType } from '../../helpers/functions/view';
import { hasUpdates } from '../../helpers/functions/updates';
import { VIEWS } from '../../helpers/constants/view';
import {
  selectFarmUuid,
  selectFieldUuid,
  selectItemPins,
} from '../../../../field/fieldSelectors';
import {
  deleteAsset,
  renameAsset,
} from '../../../../field/fieldSlice';
import {
  selectIsInstrumentEmpty,
  selectUpdates,
  selectUuid,
} from '../../zonesMapSelectors';
import { openExportZonesMapPopup } from '../../../../exportData/exportDataSlice';
import { ZonesOpsWorkflow } from '../../../zonesOps/helpers/constants/workflows';
import useZonesMap from '../../hooks/useZonesMap';
import { POPUPS } from '../../../popups/helpers/constants/popups';

import './index.scss';

const getTabs = (t) => {
  return [
    {
      label: {
        primary: t('zones-map.tabs.description'),
      },
      value: VIEWS.description,
    },
    {
      label: {
        primary: t('zones-map.tabs.rates'),
      },
      value: VIEWS.rates,
    },
  ];
};

const ZonesMapToolsPanel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const {
    farmUuid: routerFarmUuid,
    fieldUuid: routerFieldUuid,
    uuid: routerUuid,
  } = useParams();
  const farmUuid = useSelector(selectFarmUuid);
  const fieldUuid = useSelector(selectFieldUuid);
  const uuid = useSelector(selectUuid);
  const isInstrumentActive = !useSelector(selectIsInstrumentEmpty);
  const shouldDisplayPinsWarning = useSelector((state) => selectItemPins(state, uuid)).length > 0;
  const zonesMap = useZonesMap(routerFarmUuid, routerFieldUuid, routerUuid);
  const updated = hasUpdates(zonesMap, useSelector(selectUpdates));
  const viewType = getViewType(location.search);

  const handleTabChange = (_event, newValue) => {
    updateSearchParam(location, history, [{ name: 'view', value: newValue }]);
  };

  const handleMoreItemClick = ({ id }) => {
    const name = zonesMap?.name;

    if (id === 'rename') {
      dispatch(openPopup({
        type: 'rename-zones-map',
        entityName: zonesMap?.name,
        onConfirm: (newValue) => {
          dispatch(renameAsset({
            name: newValue,
            uuid,
          }));
        },
      }));
    } else if (id === 'delete') {
      dispatch(openPopup({
        type: POPUPS.deleteZonesMap,
        entityName: name,
        shouldDisplayPinsWarning,
        onConfirm: () => {
          dispatch(deleteAsset(uuid))
            .then(() => {
              history.replace(getRootLink(PAGES_ROOTS.zonesMaps));
            });
        },
      }));
    }
  };

  const handleExportClick = () => {
    dispatch(openExportZonesMapPopup({
      zonesMaps: [{
        ...zonesMap,
        fieldUuid,
      }],
      archiveName: zonesMap.name,
    }));
  };

  const handleCloneClick = () => {
    dispatch(prepareCloneWorkflow({
      vamap: zonesMap,
      farmUuid,
      fieldUuid,
    }));
    history.push(getZonesOpsLink(
      farmUuid,
      fieldUuid,
      ZonesOpsWorkflow.cloneZonesMap,
      true,
    ));
  };

  const handleCancel = () => {
    dispatch(resetUpdates());
  };

  const handleSave = () => {
    dispatch(saveVamap());
  };

  return (
    <ToolsPanel
      classes={{
        root: 'zones-map-tools-panel',
      }}
      actionsLeft={(
        <Fragment>
          <Button
            startIcon={<CloneIcon />}
            onClick={handleCloneClick}
          >
            {t('zones-map.tools-panel.clone')}
          </Button>
          <Button
            startIcon={<ExportIcon />}
            onClick={handleExportClick}
          >
            {t('export.export')}
          </Button>
          <ButtonMenu
            isIconButton
            key="more-button"
            items={[
              {
                id: 'rename',
                label: t('general.controls.rename'),
                IconComponent: RenameIcon,
              },
              {
                id: 'delete',
                label: t('general.controls.delete'),
                IconComponent: DeleteIcon,
              },
            ]}
            onItemClick={handleMoreItemClick}
          />
        </Fragment>
      )}
      actionsRight={
        updated
        && (
          <div className="zones-map-tools-panel__updates-controls">
            <Button
              disabled={isInstrumentActive}
              variant="outlined"
              onClick={handleCancel}
            >
              {t('general.controls.cancel')}
            </Button>
            <Button
              disabled={isInstrumentActive}
              variant="contained"
              color="primary"
              onClick={handleSave}
            >
              {t('general.controls.save')}
            </Button>
          </div>
        )
      }
      filtersLeft={(
        <Tabs
          tabs={getTabs(t)}
          value={viewType}
          onTabChange={handleTabChange}
        />
      )}
    />
  );
};

export default ZonesMapToolsPanel;

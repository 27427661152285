import { ModeConfig } from '../../../dataLayersView/types/dataLayersTree';

export const PDF_EXPORT_LIMIT = 60;

export const exportPdfDataLayersTreeMode: ModeConfig = {
  vectorAnalysisMaps: 'multiSelect',
  equationMaps: 'multiSelect',
  soilDatasets: 'multiSelect',
  yieldDatasets: 'multiSelect',
  asAppliedDatasets: 'multiSelect',
  topographyMaps: 'multiSelect',
};

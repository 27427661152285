import React from 'react';

import EquationDetails from '../../Controls/EquationDetails';
import EquationMapTable from '../../Controls/EquationsMapTable';
import Overview from '../../Controls/Overview';

const EquationMapLegendGroup = ({
  geoMapTable,
  statistics,
  overview,
  equationDetails,
  apiKey,
}) => {
  return (
    <>
      <EquationMapTable {...geoMapTable} />
      {
        statistics && <Overview {...statistics} />
      }
      <Overview {...overview} />
      <EquationDetails {...equationDetails} apiKey={apiKey} />
    </>
  );
};

export default EquationMapLegendGroup;

import React, { useState } from 'react';
import i18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import DoneIcon from '@material-ui/icons/CheckCircleOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import PersistentPopup from '../../../../components/PersistentPopup';
import { close } from '../../planetMetadataSlice';
import { selectRequests } from '../../planetMetadataSelectors';
import { formatAcquisitionDate } from '../../../../helpers/satellite';

import './index.scss';

const getHeader = (inProgress) => {
  let title = i18n.t('general.popups.planet-metadata-status.finished-title');
  let icon = <DoneIcon />;

  if (inProgress) {
    title = i18n.t('general.popups.planet-metadata-status.in-progress-title');
    icon = (
      <CircularProgress
        color="inherit"
        size={24}
      />
    );
  }

  return (
    <>
      {icon}
      {title}
    </>
  );
};

const hasInProgressRequests = (requests) => {
  return requests.some((request) => {
    return request.totalCount !== request.satelliteImages.length;
  });
};

const PlanetMetadataStatusPopup = ({
  onCancel,
}) => {
  const dispatch = useDispatch();
  const requests = useSelector(selectRequests);
  const [openedInd, setOpenedInd] = useState(null);
  const inProgress = hasInProgressRequests(requests);

  const handleCancel = () => {
    dispatch(close());
    onCancel();
  };

  const handleOpenedInd = (ind) => {
    setOpenedInd(ind === openedInd ? null : ind);
  };

  return (
    <PersistentPopup
      classes={{
        root: 'planet-metadata-status',
      }}
      onClose={handleCancel}
    >
      <div
        key="header"
        className="planet-metadata-status__header"
      >
        {getHeader(inProgress)}
      </div>
      <div
        key="content"
        className="planet-metadata-status__content"
      >
        {requests.map((request, ind) => {
          const {
            field,
            totalCount,
            satelliteImages,
          } = request;

          return (
            <Accordion
              expanded={openedInd === ind}
              key={ind}
              classes={{
                root: 'accordion-root',
                expanded: 'accordion-root_expanded',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{
                  root: 'accordion-summary',
                  content: 'accordion-summary__text',
                  expanded: 'accordion-summary_expanded',
                  expandIcon: 'accordion-summary__icon',
                }}
                onClick={() => handleOpenedInd(ind)}
              >
                <span className="request-info__name">
                  {field.name}
                </span>
                <span className="request-info__count">
                  {satelliteImages.length} / {totalCount}
                </span>
              </AccordionSummary>
              {
                satelliteImages.length > 0
                && (
                  <AccordionDetails className="accordion-details">
                    <ul className="images-list">
                      {satelliteImages.map((satelliteImage) => {
                        return (
                          <li key={satelliteImage.uuid}>
                            {formatAcquisitionDate(satelliteImage.satelliteImage.acquisitionDate)}
                          </li>
                        );
                      })}
                    </ul>
                  </AccordionDetails>
                )
              }
            </Accordion>
          );
        })}
      </div>
    </PersistentPopup>
  );
};

export default PlanetMetadataStatusPopup;

import React, { Fragment } from 'react';

import ZonesMapTable from '../../Controls/ZonesMapTable';
import DataLayersLegendList from '../../Controls/DataLayersList';
import Overview from '../../Controls/Overview';
import LabelsList from '../../Controls/LabelsList';
import RatesTable from '../../Controls/RatesTable';

const MultilayerZonesMapLegendGroup = ({
  TableComponent = ZonesMapTable,
  zonesMapTable,
  ratesTable,
  dataLayersList,
  overview,
  labels,
  apiKey,
}) => {
  return (
    <Fragment>
      <TableComponent {...zonesMapTable} />
      {ratesTable && <RatesTable {...ratesTable} />}
      <Overview {...overview} />
      <DataLayersLegendList {...dataLayersList} apiKey={apiKey} />
      <LabelsList {...labels} />
    </Fragment>
  );
};

export default MultilayerZonesMapLegendGroup;

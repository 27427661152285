import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDidMount from '../../../../../hooks/useDidMount';
import { fetchProfileJohnDeere } from '../../../../jdProfile/jdProfileSlice';
import { fetchOrganizationsJohnDeereWithNewOrgName } from '../../../../jdOrganizations/jdOrganizationsSlice';
import {
  selectProfileHasError,
  selectProfileIsLoading,
  selectProfileIsReadyToAuthorization,
  selectProfileIsAuthorizedAndReady,
} from '../../../../jdProfile/jdProfileSelectors';
import {
  selectOrganizationsHasError,
  selectOrganizationsOrgName,
} from '../../../../jdOrganizations/jdOrganizationsSelectors';
import { selectImportStep } from '../../jdImportSelectors';
import ConnectionToAccountPanel from '../../components/ConnectionToAccountPanel';
import config from './config';

const StepperImportDataJohnDeere = () => {
  const dispatch = useDispatch();

  const hasErrorProfile = useSelector(selectProfileHasError);
  const isLoadingProfile = useSelector(selectProfileIsLoading);
  const isReadyToAuthorizationProfile = useSelector(selectProfileIsReadyToAuthorization);
  const isAuthorizedAndReadyProfile = useSelector(selectProfileIsAuthorizedAndReady);

  const hasErrorOrganizations = useSelector(selectOrganizationsHasError);
  const orgName = useSelector(selectOrganizationsOrgName);

  const Stepper = config[useSelector(selectImportStep)];

  useDidMount(() => {
    dispatch(fetchProfileJohnDeere());
    dispatch(fetchOrganizationsJohnDeereWithNewOrgName({ orgName }));
  });

  return (
    <ConnectionToAccountPanel
      hasError={hasErrorProfile || hasErrorOrganizations}
      isLoading={isLoadingProfile}
      isReadyToAuthorization={isReadyToAuthorizationProfile}
      isAuthorizedAndReady={isAuthorizedAndReadyProfile}
      content={<Stepper />}
    />
  );
};

export default StepperImportDataJohnDeere;

import type { Equation } from '../../types/equation';

export const EQUATIONS_PAGE_SIZE = 200;

export enum EquationCategory {
  customUser = 'customUser',
  customOrganization = 'customOrganization',
  master = 'master',
  johnDeere = 'johnDeere',
}

export const EMPTY_EQUATION: Equation = {
  uuid: '',
  title: '',
  description: '',
  equationAsText: '',
  equationResultVariable: '',
  dataVariables: [],
  sourceUrl: '',
  productUnit: null,
  useNumpy: false,
};

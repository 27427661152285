import i18n from 'i18next';

import type { SatelliteFilters } from '../../types/satelliteImage';
import type { TransformedSatelliteImage } from '../../../../satelliteImages/types/satelliteImage';
import { isNumber } from '../../../../../helpers/functions/utils/number';

export const ALL_IMAGES_VALUE = 0;

export const getCloudFilterOptions = () => {
  return [
    {
      title: i18n.t('general.controls.satellite-filters.cloud-free'),
      value: 1,
    },
    {
      title: i18n.t('general.controls.satellite-filters.partially-cloud'),
      value: 0.5,
    },
    {
      title: i18n.t('general.controls.satellite-filters.all-images'),
      value: ALL_IMAGES_VALUE,
    },
  ];
};

export const filterSatelliteImages = (filter: SatelliteFilters, images: TransformedSatelliteImage[] = []) => {
  const {
    cloudFilterValue,
    monthFilterValue,
    yearFilterValue,
    ndviFilterValue,
    providerFilterValue,
  } = filter || {};

  return images.filter((image) => {
    const { ndviAverage, cloudFreeLand, satelliteImage: { acquisitionDate, provider } } = image;

    if (ndviFilterValue) {
      const [
        minNdvi,
        maxNdvi,
      ] = ndviFilterValue;

      if (isNumber(ndviAverage) && (ndviAverage < minNdvi || ndviAverage > maxNdvi)) {
        return false;
      }
    }

    if (cloudFilterValue != null) {
      if (isNumber(cloudFreeLand) && cloudFreeLand < cloudFilterValue) {
        return false;
      }
    }

    if (acquisitionDate && Array.isArray(monthFilterValue) && monthFilterValue.length > 0) {
      const date = new Date(acquisitionDate);
      const month = date.getMonth();

      if (!monthFilterValue.find((item) => {
        return item.value === month;
      })) {
        return false;
      }
    }

    if (acquisitionDate && Array.isArray(yearFilterValue) && yearFilterValue.length > 0) {
      const date = new Date(acquisitionDate);
      const year = date.getFullYear();

      if (!yearFilterValue.find((item) => {
        return item.value === year;
      })) {
        return false;
      }
    }

    if (Array.isArray(providerFilterValue) && providerFilterValue.length > 0) {
      if (!providerFilterValue.find((item) => {
        return item.value === provider;
      })) {
        return false;
      }
    }

    return true;
  });
};

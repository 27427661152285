import { VARIABLES_ERRORS_I18N_KEYS } from '../../constants/entities/equation';

export const variableNameFormatter = (value) => {
  return value.trim();
};

const validateVariablesUniqueness = (items) => {
  const duplicates = {};

  items.forEach((item) => {
    if (!item) {
      return;
    }

    duplicates[item] = duplicates[item] ? duplicates[item] + 1 : 1;
  });

  return Object.values(duplicates).some((value) => value > 1) ? VARIABLES_ERRORS_I18N_KEYS.duplicate : '';
};

export const validateVariables = (items) => {
  return validateVariablesUniqueness(items);
};

export const prepareEquationData = (equation = {}, dataVariables = []) => {
  return {
    uuid: equation.uuid,
    title: equation.title,
    formula: equation.equationAsText,
    resultVariable: equation.equationResultVariable,
    dataVariables: (equation.dataVariables || []).map((dataVariable) => {
      const prevValue = dataVariables.find(({ variable }) => variable === dataVariable) ?? {};

      return {
        ...prevValue,
        variable: dataVariable,
      };
    }),
    sourceUrl: equation.sourceUrl,
    description: equation.description,
    productUnit: equation.productUnit || '',
    useNumpy: equation.useNumpy || false,
  };
};

export enum DatasetViewType {
  raw = 'raw',
  processed = 'processed',
}

export const VIEW_TO_I18N_LABEL = {
  [DatasetViewType.raw]: 'general.controls.map.raw-view-type',
  [DatasetViewType.processed]: 'general.controls.map.processed-view-type',
};

export enum GeometryType {
  Points = 'POINTS',
  MultiPoints = 'MULTIPOINTS',
  Polygons = 'POLYGONS',
  MultiPolygons = 'MULTIPOLYGONS',
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import './index.scss';

export default function TitleExample({
  value,
}:{
  value: string;
}) {
  const { t } = useTranslation();

  return (
    <div className='title-example'>
      <Typography className="title-example__label">
        {t('batch-equation-based.steps.preview.title-template.example')}:
      </Typography>
      <Typography className="title-example__value">
        {value}
      </Typography>
    </div>
  );
}

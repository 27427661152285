import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Legend from '../../../../../../components/Legend';
import AssetLegendContent from '../../../../../../components/Legend/AssetLegendContent';
import { isRequiredDataLoaded } from '../../../../../../helpers/components/legend';
import { resetLegend, setChartScaleLegend } from '../../../fieldWorkflowSlice';
import { selectField, selectFullyLoaded } from '../../../../../field/fieldSelectors';
import { selectLegend } from '../../../fieldWorkflowSelectors';
import { selectApiKey, selectAreaUnit } from '../../../../../user/userSelectors';

const FieldLegend = ({
  legend,
}) => {
  const dispatch = useDispatch();
  const field = useSelector(selectField);
  const {
    _chartScale: chartScale,
  } = useSelector(selectLegend);

  const loading = !isRequiredDataLoaded(legend, field, useSelector(selectFullyLoaded));
  const apiKey = useSelector(selectApiKey);
  const areaUnit = useSelector(selectAreaUnit);

  const handleLegendClose = () => {
    dispatch(resetLegend());
  };

  const handleChartScaleChange = (scale) => {
    dispatch(setChartScaleLegend(scale));
  };

  if (!legend) {
    return null;
  }

  return (
    <Legend
      loading={loading}
      title={legend.name}
      type={legend._type}
      content={(
        <AssetLegendContent
          item={legend}
          field={field}
          apiKey={apiKey}
          areaUnit={areaUnit}
          chartScale={chartScale}
          onChartScaleChange={handleChartScaleChange}
        />
      )}
      onClose={handleLegendClose}
    />
  );
};

export default FieldLegend;

import { ReactElement } from 'react';
import { enqueueSnackbar } from 'notistack';
import i18n from 'i18next';

import { NotificationType } from '../constants/types';
import { captureException } from '../../../../helpers/functions/utils/errorHandling';
import { openPopup } from '../../../ui/popups/popupsSlice';
import { POPUPS } from '../../../ui/popups/helpers/constants/popups';
import { isApplicationShellReportIssueEnabled } from '../../../../helpers/functions/utils/appConfig';
import { NotificationAction } from '../../types/action';
import { AppDispatch } from '../../../../app/store/helpers/types';

export const errorNotify = ({
  error,
  message,
  actions = [],
  preventDuplicate = false,
  key,
  dispatch,
}: {
  error: unknown | Error;
  message?: string;
  actions?: NotificationAction[];
  preventDuplicate?: boolean;
  key?: string;
  dispatch: AppDispatch;
}) => {
  const additionalActions = [];

  captureException({
    message,
    error,
  });

  if (isApplicationShellReportIssueEnabled()) {
    additionalActions.push({
      title: i18n.t('general.navigation.report-issue'),
      handler: () => {
        dispatch(openPopup({
          type: POPUPS.reportIssue,
        }));
      },
    });
  }

  const snackbarId = enqueueSnackbar(message ?? i18n.t('general.notifications.something-went-wrong'), {
    variant: NotificationType.error,
    action: [
      ...actions,
      ...additionalActions,
    ],
    preventDuplicate,
    key,
  });

  return snackbarId;
};

export const successNotify = ({
  message = '',
  messageElement,
  actions,
  preventDuplicate = false,
  key,
}: {
  message?: string;
  messageElement?: ReactElement;
  actions?: NotificationAction[];
  preventDuplicate?: boolean;
  key?: string;
}) => {
  const snackbarId = enqueueSnackbar(message, {
    variant: NotificationType.success,
    preventDuplicate,
    action: actions,
    messageElement,
    key,
  });

  return snackbarId;
};

export const warningNotify = ({
  message = '',
  messageElement,
  actions,
  preventDuplicate = false,
  key,
  onClose,
}: {
  message?: string;
  messageElement?: ReactElement;
  actions?: NotificationAction[];
  preventDuplicate?: boolean;
  key?: string,
  onClose?: () => void;
}) => {
  const snackbarId = enqueueSnackbar(message, {
    variant: NotificationType.warning,
    preventDuplicate,
    action: actions,
    key,
    messageElement,
    onClose,
  });

  return snackbarId;
};

export const infoNotify = ({
  message = '',
  actions,
  preventDuplicate = false,
  key,
}: {
  message?: string;
  actions?: NotificationAction[];
  preventDuplicate?: boolean;
  key?: string;
}) => {
  const snackbarId = enqueueSnackbar(message, {
    variant: NotificationType.info,
    preventDuplicate,
    action: actions,
    key,
  });

  return snackbarId;
};

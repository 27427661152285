import React from 'react';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../app/store/helpers/functions';
import { selectStepIndex } from '../zonesOpsSelectors';
import { PAGES_ROOTS, SEARCH_PARAMS, getSearchParam } from '../../../../helpers/navigation';
import useDidMount from '../../../../hooks/useDidMount';
import { resetOverlayLayers } from '../../overlayLayers/overlayLayersSlice';
import { Step } from '../../../../components/Stepper';

export default function useStepComponent({
  getStepsFn,
  resetFn,
}: {
  getStepsFn: (fromField: boolean) => Step[],
  resetFn: () => void,
}) {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const stepIndex = useAppSelector(selectStepIndex);

  const fromFieldSearchParam = getSearchParam(
    location.search,
    SEARCH_PARAMS[PAGES_ROOTS.zonesOps].fromField,
  );
  const fromField = fromFieldSearchParam === 'true';
  const steps = getStepsFn(!fromField);
  const step = steps[stepIndex].id;
  const StepComponent = steps[stepIndex].component;
  const activeSteps = steps.slice(0, stepIndex + 1);

  useDidMount(() => {
    return () => {
      resetFn();
      dispatch(resetOverlayLayers());
    };
  });

  return (
    <StepComponent
      steps={activeSteps}
      stepIndex={stepIndex}
      step={step}
    />
  );
}

import Big from 'bignumber.js';

export const isNumber = (input: any): input is number => {
  return typeof input === 'number' && !Number.isNaN(input);
};

export const getSum = (num1: number | string, num2: number | string): number => {
  const sum = new Big(num1).plus(num2).toFixed();
  return Number(sum);
};

export const isNumberValueValid = (value: number | null, min: number, max: number) => {
  return isNumber(value) && value >= min && value <= max;
};

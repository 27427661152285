import { createSelector } from '@reduxjs/toolkit';

import { COGNITO_GROUPS } from './helpers/constants/user';
import { getLastPaid } from './helpers/functions/order';

export const selectIsLoaded = ({ user }) => {
  return user.isLoaded;
};

export const selectUuid = ({ user }) => {
  return user.data.uuid;
};

export const selectReachedAreaLimit = ({ user }) => {
  return user.data.reachedAreaLimit;
};

export const selectApiKey = ({ user }) => {
  return user.data.apiKey;
};

export const selectEmail = ({ user }) => {
  return user.data.email;
};

export const selectAreaUnit = ({ user }) => {
  return user.data.areaUnit;
};

export const selectTotalArea = ({ user }) => {
  return user.data.totalArea;
};

export const selectMaxArea = ({ user }) => {
  return user.data.maxArea;
};

export const selectLanguage = ({ user }) => {
  return user.data.language;
};

export const selectIdentity = ({ user }) => {
  return user.data.identity;
};

export const selectOrders = ({ user }) => {
  return user.data.orders;
};

export const selectAdditionalUserData = ({ user }) => {
  const {
    country,
    companyType,
    acceptedTermsAndConditions,
    phoneNumber,
  } = user.data;

  return {
    country,
    companyType,
    acceptedTermsAndConditions,
    phoneNumber,
  };
};

export const selectPricingData = ({ user }) => {
  const { currency, stripeCustomerId } = user.data;

  return { currency, stripeCustomerId };
};

export const selectAdditionalUserDataProvided = ({ user }) => {
  const {
    country,
    companyType,
    acceptedTermsAndConditions,
    phoneNumber,
  } = user.data;
  return country && companyType && acceptedTermsAndConditions && phoneNumber;
};

export const selectLastOrder = (state) => {
  return selectOrders(state)[0];
};

export const selectLastPaidOrder = createSelector(
  selectOrders,
  (orders) => {
    return getLastPaid(orders);
  },
);

export const selectCognitoGroups = ({ user }) => {
  return user.cognitoGroups;
};

export const selectPermissionedOrganizationAdmin = ({ user }) => {
  return user.cognitoGroups.includes(COGNITO_GROUPS.organization);
};

export const selectPermissionedSmartSampling = ({ user }) => {
  return user.cognitoGroups.includes(COGNITO_GROUPS.pfeiferLangen);
};

export const selectPermissionedOrigin = ({ user }) => {
  return user.cognitoGroups.includes(COGNITO_GROUPS.origin);
};

export const selectPermissionedPlanet = ({ user }) => {
  return user.cognitoGroups.includes(COGNITO_GROUPS.planet);
};

export const selectOrganizations = ({ user }) => user.data.organizations;

export const selectDebitedOrganization = createSelector(
  selectOrganizations,
  (organizations) => organizations[0],
);

export const selectOwnedOrganizations = createSelector(
  selectUuid,
  selectOrganizations,
  (uuid, orgs) => orgs.filter(({ ownerUuid }) => uuid === ownerUuid),
);

export const selectIsUserOrganizationsAccessible = (state) => {
  const isAdmin = selectPermissionedOrganizationAdmin(state);
  const orgs = selectOrganizations(state);

  return isAdmin || orgs.length > 0;
};

const selectUserAdvisor = createSelector(
  selectOrganizations,
  selectUuid,
  (organizations, uuid) => {
    return organizations.some(({ users }) => {
      return users.find(({ userUuid, allFarms }) => {
        return allFarms && userUuid === uuid;
      });
    });
  },
);

export const selectUserAdminOrAdvisor = (state) => {
  return selectPermissionedOrganizationAdmin(state)
    || selectUserAdvisor(state);
};

export const selectAuthenticationFulfilled = ({ user }) => {
  return user.auth.authenticated && !user.auth.inProgress;
};

export const selectAuthenticationRejected = ({ user }) => {
  return !user.auth.authenticated && !user.auth.inProgress;
};

export const selectAuthenticationInProgress = ({ user }) => {
  return user.auth.inProgress;
};

export const selectWithRedirect = ({ user }) => {
  return user.auth.withRedirect;
};

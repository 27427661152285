import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { getComponent } from '../../../helpers/markup';
import PanelMapLayout from '../../PanelMapLayout';
import Button from '../../Button';
import PopupHeader from '../PopupHeader';
import BackdropLoading from '../../Loading/BackdropLoading';

import './index.scss';

const GeneralPopup = ({
  classes = {},
  loading = false,
  title,
  children,
  onCancel,
  withActions = true,
  onConfirm = () => {},
}) => {
  const { t } = useTranslation();
  const notification = getComponent('notification', children);
  const toolsPanel = getComponent('tools-panel', children);
  const panel = getComponent('panel', children);
  const map = getComponent('map', children);
  const actions = getComponent('actions', children) || (
    <Button
      onClick={onConfirm}
      variant="contained"
      color="primary"
    >
      {t('general.popups.done')}
    </Button>
  );

  return (
    <Dialog
      open
      maxWidth={false}
      classes={{
        root: clsx('general-popup', classes?.root),
        paper: clsx('general-popup__paper', classes?.paper),
      }}
    >
      {notification}
      <PopupHeader
        title={title}
        onCancel={onCancel}
      />
      <DialogContent className="general-popup__content">
        {toolsPanel}
        <PanelMapLayout
          panel={panel}
          map={map}
        />
      </DialogContent>
      {withActions && (
        <DialogActions className={clsx('general-popup__actions', classes?.actions)}>
          {actions}
        </DialogActions>
      )}
      {loading && <BackdropLoading />}
    </Dialog>
  );
};

export default GeneralPopup;

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { useAppSelector } from '../../../../../app/store/helpers/functions';
import { selectSelectedFields } from '../../../batchAnalytics/batchAnalyticsSelectors';
import { prettifyNumber } from '../../../../../helpers/markup';
import { selectAreaUnit } from '../../../../user/userSelectors';
import { getI18nAreaUnit } from '../../../../../helpers';
import SummaryPanel from '../../components/SummaryPanel';

const getFieldsSummary = ({
  fieldsAmount,
  fieldsArea,
  areaUnitLabel,
}: {
  fieldsAmount: number;
  fieldsArea: string | null;
  areaUnitLabel: string;
}) => [
  [
    {
      text: `${i18n.t('batch-equation-based.steps.preview.summary.selected-fields')}:`,
    },
    {
      text: fieldsAmount,
      status: 'success' as const,
    },
  ],
  [
    {
      text: `${i18n.t('batch-equation-based.steps.preview.summary.selected-fields-area')}:`,
    },
    {
      text: `${fieldsArea} ${areaUnitLabel}`,
      status: 'success' as const,
    },
  ],
];

export default function FieldsSummary() {
  const { t } = useTranslation();

  const selectedFields = useAppSelector(selectSelectedFields);
  const areaUnit = useAppSelector(selectAreaUnit);
  const areaUnitLabel = t(getI18nAreaUnit(areaUnit));

  const selectedFieldsData = useMemo(() => {
    return Object.values(selectedFields);
  }, [selectedFields]);

  const fieldsAreaSum = useMemo(() => {
    return selectedFieldsData.reduce((acc, field) => {
      return acc + (field.area || 0);
    }, 0);
  }, [selectedFieldsData]);

  const summary = useMemo(() => {
    return getFieldsSummary({
      fieldsAmount: selectedFieldsData.length,
      fieldsArea: prettifyNumber(fieldsAreaSum, 3),
      areaUnitLabel,
    });
  }, [areaUnitLabel, fieldsAreaSum, selectedFieldsData.length]);

  return <SummaryPanel summary={summary}/>;
}

import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../../app/store/helpers/functions';
import Button from '../../../../../../components/Button';
import useVerifyEquationMapData from '../../../hooks/useVerifyEquationMapData';
import useGenerateEquationMaps from '../../../hooks/useGenerateEquationMaps';
import { selectEquationMapData } from '../../../createBatchEquationBasedAnalysisSelectors';
import { LoadStatus } from '../../../../../../helpers/constants/utils/loadStatus';
import { openExportEquationMapsPopup } from '../../../../../exportData/exportDataSlice';
import { selectEquationMapEntity } from '../../../../../assets/assetsSelectors';

import './index.scss';

export default function PreviewTileActions({
  fieldUuid,
  showSettings,
  onSetSettingsView,
}: {
  fieldUuid: string,
  showSettings: boolean,
  onSetSettingsView: (v: boolean) => void,
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { hasMissingVariable } = useVerifyEquationMapData({ fieldUuid });
  const generateEquationMaps = useGenerateEquationMaps();

  const equationMapData = useAppSelector((state) => selectEquationMapData(state, fieldUuid));
  const equationMapEntity = useAppSelector((state) => {
    return selectEquationMapEntity(state, equationMapData?.uuid || '');
  });

  const handleCancelSettingsClick = () => {
    onSetSettingsView(false);
  };

  const handleAdjustSettingsClick = () => {
    onSetSettingsView(true);
  };

  const handleRunClick = () => {
    generateEquationMaps([fieldUuid]);
  };

  const handleDownloadClick = () => {
    if (!equationMapEntity) {
      return;
    }

    dispatch(openExportEquationMapsPopup({
      equationMaps: [equationMapEntity],
      archiveName: equationMapEntity.name,
    }));
  };

  let controls: ReactElement;

  if (showSettings) {
    controls = (
      <>
        <Button
          color="primary"
          onClick={handleCancelSettingsClick}
        >
          {t('general.controls.cancel')}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          disabled={hasMissingVariable}
          onClick={handleRunClick}
        >
          {t('zones-ops.equation-based.steps.5.run-equation')}
        </Button>
      </>
    );
  } else if (equationMapData?.generationStatus === LoadStatus.success) {
    controls = (
      <>
        <Button
          color="primary"
          onClick={handleAdjustSettingsClick}
        >
          {t('batch-equation-based.steps.preview.preview-tile.adjust-settings')}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleDownloadClick}
        >
          {t('export.download')}
        </Button>
      </>
    );
  } else if (hasMissingVariable) {
    controls = (
      <Button
        color="primary"
        variant="outlined"
        onClick={handleAdjustSettingsClick}
      >
        {t('batch-equation-based.steps.preview.preview-tile.adjust-settings')}
      </Button>
    );
  } else {
    controls = (
      <>
        <Button
          color="primary"
          onClick={handleAdjustSettingsClick}
        >
          {t('batch-equation-based.steps.preview.preview-tile.adjust-settings')}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleRunClick}
        >
          {t('zones-ops.equation-based.steps.5.run-equation')}
        </Button>
      </>
    );
  }

  return (
    <div className={clsx('preview-tile-actions', {
      'preview-tile-actions_hidden': equationMapData?.generationStatus === LoadStatus.loading,
    })}
    >
      {controls}
    </div>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import AuthenticationRedirector from '../router/redirectors/Authentication';
import UserDataRedirector from '../router/redirectors/UserData';
import PricingRedirector from '../router/redirectors/Pricing';
import ToolsPanel from '../features/ui/uploadData/containers/ToolsPanel';
import Panel from '../features/ui/uploadData/containers/Panel';
import BaseTemplate from './BaseTemplate.jsx';
import { getDocumentTitle } from '../helpers';
import useDidMount from '../hooks/useDidMount';

const UploadData = () => {
  const { t } = useTranslation();

  useDidMount(() => {
    document.title = getDocumentTitle(t('field-profiler.tools-panel.upload-data'));
  });

  return (
    <AuthenticationRedirector>
      <UserDataRedirector>
        <PricingRedirector>
          <BaseTemplate
            headerProps={{
              text: t('upload-data.title'),
              withBackAction: true,
            }}
          >
            <ToolsPanel key="tools-panel" />
            <Panel key="panel" />
          </BaseTemplate>
        </PricingRedirector>
      </UserDataRedirector>
    </AuthenticationRedirector>
  );
};

export default UploadData;

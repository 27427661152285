import { RootState } from '../../app/store/helpers/types';

export const selectGeneratedVectorAnalysisMap = ({ createAnalysis }: RootState) => {
  return createAnalysis.generatedVectorAnalysisMap;
};

export const selectGeneratedEquationMap = ({ createAnalysis }: RootState) => {
  return createAnalysis.generatedEquationMap;
};

export const selectSaveInProgress = ({ createAnalysis }: RootState) => {
  return createAnalysis.saveInProgress;
};

import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Select from '../../../../components/Select';
import TextField from '../../../../components/TextField';
import { getCategoryOptions } from '../../helpers/functions/filters';
import {
  selectFilterCategory,
  selectFilterSearch,
} from '../../equationsSelectors';
import {
  fetchCurrentCategoryEquations,
  setFilterCategory,
  setFilterSearch,
} from '../../equationsSlice';
import { getDebouncer } from '../../../../helpers/functions/utils/debouncer';
import { EquationCategory } from '../../helpers/constants/equations';

import './index.scss';

const TIMEOUT = 500; // ms
const searchTextfieldDebouncer = getDebouncer(TIMEOUT);

export default function EquationsFilters() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const category = useSelector(selectFilterCategory);
  const filterSearch = useSelector(selectFilterSearch);
  const categoryOptions = getCategoryOptions();

  const handleCategoryChange = (value: EquationCategory | null) => {
    if (value == null) {
      return;
    }

    dispatch(setFilterCategory(value));
  };

  const handleFilterSearchChange = (value: string) => {
    dispatch(setFilterSearch(value));

    searchTextfieldDebouncer(() => {
      dispatch(fetchCurrentCategoryEquations({
        reset: true,
      }));
    });
  };

  return (
    <div className="equations-filters">
      <Select
        id="category"
        label={t('zones-ops.equation-based.steps.3.panel.category')}
        value={category}
        options={categoryOptions}
        onChange={handleCategoryChange}
      />
      <TextField
        type="search"
        value={filterSearch}
        placeholder={t('zones-ops.equation-based.steps.3.panel.search-placeholder')}
        onChange={(e: ChangeEvent<{ value: string }>) => handleFilterSearchChange(e.target.value)}
        onEndAdornmentClick={() => handleFilterSearchChange('')}
      />
    </div>
  );
}

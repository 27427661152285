import { AssetType } from '../../../../helpers/constants/entities/asset';
import { GeometryType } from '../../../../helpers/constants/entities/dataset';

export const getUseInterpolatedDataValue = (
  dataLayers: {
    type?: AssetType | null;
    geometryType?: GeometryType | null;
  }[] = [],
) => {
  return dataLayers.some(({ type, geometryType }) => {
    if (type === AssetType.satelliteImage || type === AssetType.soilDataset) {
      return true;
    }

    const isYieldOrAsAppliedDataset = type === AssetType.yieldDataset || type === AssetType.asAppliedDataset;

    if (isYieldOrAsAppliedDataset && geometryType === GeometryType.Points) {
      return true;
    }

    return false;
  });
};

import React, {
  Suspense,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import Loading from '../../../../../../../components/Loading';
import Stepper from '../../../../../../../components/Stepper';
import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import Panel from '../../../Panel';
import { initializeWorkflow } from '../../../../drawManuallyWorkflowSlice';
import { goToPrevStep } from '../../../../../zonesOps/zonesOpsSlice';
import { selectIsFieldLoaded } from '../../../../../../field/fieldSelectors';
import useDidMount from '../../../../../../../hooks/useDidMount';
import {
  selectFeature,
  selectInstrument,
} from '../../../../drawManuallyWorkflowSelectors';
import { openPopup } from '../../../../../popups/popupsSlice';
import { formatAcquisitionDate } from '../../../../../../../helpers/satellite';
import { selectSaveInProgress } from '../../../../../../createAnalysis/createAnalysisSelectors';
import useSaveNewVamap from '../../../../hooks/useSaveNewVamap';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../app/store/helpers/functions';

const Map = React.lazy(() => import('../../../Map'));

const DrawStep = ({
  steps,
  stepIndex,
  step,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const feature = useAppSelector(selectFeature);
  const instrument = useAppSelector(selectInstrument);
  const isMapCreating = useAppSelector(selectSaveInProgress);
  const fieldLoaded = useAppSelector(selectIsFieldLoaded);

  const nextDisabled = !feature || !!instrument || isMapCreating;

  const panelRef = useRef(null);

  const saveNewVamap = useSaveNewVamap();

  const handleClickNext = () => {
    dispatch(
      openPopup({
        type: 'name-zones-map',
        textValue: formatAcquisitionDate(Date.now()),
        title: t('general.popups.name-zones-map.title'),
        description: t('general.popups.name-zones-map.description'),
        confirmText: t('general.controls.save'),
        onConfirm: (name) => {
          saveNewVamap(name);
        },
      }),
    );
  };

  const handleClickBack = () => {
    dispatch(goToPrevStep());
  };

  useDidMount(() => {
    dispatch(initializeWorkflow());
  });

  let result;

  if (fieldLoaded) {
    result = (
      <Stepper
        activeStep={stepIndex}
        steps={steps}
        nextDisabled={nextDisabled}
        nextLabel={t('zones-ops.common.finish')}
        onClickNext={handleClickNext}
        onClickBack={handleClickBack}
      >
        <Panel
          key="panel"
          ref={panelRef}
          step={step}
        />
        <Suspense
          key="map"
          fallback={<Loading />}
        >
          {!!feature && <Map instrumentPanelRef={panelRef} />}
        </Suspense>
        {isMapCreating && <BackdropLoading key="loader" />}
      </Stepper>
    );
  } else {
    result = <Loading />;
  }

  return result;
};

export default DrawStep;

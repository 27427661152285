import i18n from 'i18next';

import { EquationCategory } from '../constants/equations';
import {
  getEquationsAdditionalCategories,
} from '../../../../helpers/functions/utils/appConfig';

export const getCategoryOptions = () => {
  const categories = [
    {
      value: EquationCategory.customUser,
      title: i18n.t('zones-ops.equation-based.steps.3.panel.custom-user-presets'),
    },
    {
      value: EquationCategory.customOrganization,
      title: i18n.t('zones-ops.equation-based.steps.3.panel.custom-organization-presets'),
    },
    {
      value: EquationCategory.master,
      title: i18n.t('zones-ops.equation-based.steps.3.panel.recommendations-presets'),
    },
  ];
  const additionalCategories = getEquationsAdditionalCategories();

  if (additionalCategories.johnDeere) {
    categories.push({
      value: EquationCategory.johnDeere,
      title: i18n.t('zones-ops.equation-based.steps.3.panel.john-deere-presets'),
    });
  }

  return categories;
};

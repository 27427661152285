import React,
{
  MouseEvent,
  ReactNode,
  KeyboardEvent,
} from 'react';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';

import Checkbox from '../../../../../../components/Checkbox';
import type { TreeNode } from '../../../types/node';
import { getParentSelectedValue } from '../../../helpers/functions/checkboxItem';
import { isEnterKeyPressedOnCurrentTarget } from '../../../../../../helpers/functions/utils/navigation';
import { getTreeViewItemOffset, traverseTree } from '../../../helpers/functions/tree';

import './index.scss';

export const updateParentSelection = (
  node: TreeNode | null,
  checked: Record<string, number>,
): Record<string, number> => {
  let result: Record<string, number> = {};

  if (!node) {
    return result;
  }

  const parentSelectedValue = getParentSelectedValue(node, checked);

  result[node?.id] = parentSelectedValue;

  if (node.parent) {
    result = {
      ...result,
      ...updateParentSelection(
        node.parent,
        {
          ...checked,
          ...result,
        },
      ),
    };
  }

  return result;
};

const updateChildrenSelection = (
  node: TreeNode,
  newValue: number,
  checked: Record<string, number>,
): Record<string, number> => {
  const result: Record<string, number> = {
    ...checked,
  };

  if (!node.children) {
    return result;
  }

  for (const childNode of traverseTree(node.children)) {
    result[childNode.id] = childNode.selectable ? newValue : 0;
  }

  return result;
};

export default function CheckboxItem<T extends TreeNode>({
  itemId,
  treeNodeGetter,
  checked,
  children,
  offset,
  isParent,
  isExpanded,
  isSelected,
  disabled,
  checkboxDisabled,
  classes,
  onCheckboxClick,
  onClick,
  onExpandMoreClick = () => {},
}: {
  itemId: string,
  treeNodeGetter: () => T | null,
  checked: Record<string, number>,
  children: ReactNode,
  offset: number,
  isParent?: boolean,
  isExpanded?: boolean,
  isSelected?: boolean,
  disabled?: boolean,
  checkboxDisabled?: boolean,
  classes?: {
    wrapper?: string,
    button?: string,
    icon?: string,
    checkbox?: string,
  },
  onCheckboxClick: (v: Record<string, number>, item: T) => void,
  onClick?: (itemNode: T | null) => void,
  onExpandMoreClick?: (e: MouseEvent) => void,
}) {
  const handleClick = () => {
    if (disabled) {
      return;
    }

    const itemNode = treeNodeGetter();
    onClick?.(itemNode);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!disabled && isEnterKeyPressedOnCurrentTarget(event)) {
      const itemNode = treeNodeGetter();
      onClick?.(itemNode);
    }
  };

  const handleCheckboxClick = (e: MouseEvent) => {
    e.stopPropagation();

    const itemNode = treeNodeGetter();

    if (!itemNode) {
      return;
    }

    const newValue = checked[itemNode.id] ? 0 : 2;
    const childrenSelection = updateChildrenSelection(itemNode, newValue, checked);

    const update = {
      ...checked,
      ...childrenSelection,
      [itemNode.id]: newValue,
    };

    let parentSelection = {};

    if (itemNode.parent) {
      parentSelection = updateParentSelection(itemNode.parent, update);
    }

    onCheckboxClick(
      {
        ...update,
        ...parentSelection,
      },
      itemNode,
    );
  };

  const itemButtonProps = onClick && !disabled ? { onClick: handleClick, onKeyDown: handleKeyDown, tabIndex: 0 } : {};

  return (
    <div className={clsx('tree-checkbox-item__wrapper', classes?.wrapper)}>
      <div
        role="button"
        style={{ paddingLeft: getTreeViewItemOffset(offset, !isParent) }}
        className={clsx('tree-checkbox-item', classes?.button, {
          'tree-checkbox-item_parent': isParent,
          'tree-checkbox-item_selected': isSelected,
          'tree-checkbox-item_disabled': disabled,
          'tree-checkbox-item_clickable': !!onClick && !disabled,
        })}
        {...itemButtonProps}
      >
        {
          isParent
          && (
            <IconButton
              className={clsx('tree-checkbox-item_parent__expand-button', classes?.icon, {
                'tree-checkbox-item_parent__expand-button_expanded': isExpanded,
              })}
              edge="end"
              size="small"
              disabled={disabled}
              onClick={onExpandMoreClick}
            >
              <ExpandMoreIcon color="primary" />
            </IconButton>
          )
        }
        <Checkbox
          value={checked[itemId]}
          disabled={disabled || checkboxDisabled}
          onClick={handleCheckboxClick}
          classes={{ root: classes?.checkbox }}
        />
        {children}
      </div>
    </div>
  );
}

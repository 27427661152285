import React, {
  ChangeEvent,
  useMemo,
  useState,
} from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import EmailSharpIcon from '@material-ui/icons/EmailSharp';
import LockSharpIcon from '@material-ui/icons/LockSharp';

import Button from '../../../../../components/Button';
import TextField from '../../../../../components/TextField';
import Link from '../../../../../components/Link';
import { getAuthLink } from '../../../../../helpers/navigation';
import { View } from '../../helpers/constants/authentication';
import type {
  LogInFormField,
  LoginFormFields,
} from '../../types/ui';
import { LOG_IN_ERRORS } from '../../helpers/constants/errors';

import '../common/index.scss';

const LogInForm = ({
  isSignUpAllowed,
  onLogInClick,
  onLogInUnknownError,
}: {
  isSignUpAllowed: boolean;
  onLogInClick: (formState: LoginFormFields) => Promise<void>;
  onLogInUnknownError: (error: Error) => void;
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formState, setFormState] = useState({
    password: '',
    email: '',
  });
  const [formErrors, setFormErrors] = useState({
    password: '',
    email: '',
  });

  const handlePasswordInputButtonClick = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleFieldChange = (field: LogInFormField, value: string) => {
    setFormState((prevFormState) => {
      return {
        ...prevFormState,
        [field]: value,
      };
    });
    setFormErrors((prevFormErrors) => {
      return {
        ...prevFormErrors,
        [field]: '',
      };
    });
  };

  const handleLoginClick = async () => {
    setFormErrors({
      password: '',
      email: '',
    });

    try {
      await onLogInClick(formState);
    } catch (e) {
      const error = e as Error;
      const loginError = LOG_IN_ERRORS.find((err) => {
        return err.regexp.test((error).message);
      });

      if (loginError) {
        setFormErrors((prevFormErrors) => {
          return {
            ...prevFormErrors,
            [loginError.field]: loginError.helperText,
          };
        });
      } else {
        onLogInUnknownError(error);
      }
    }
  };

  const logInButtonDisabled = useMemo(() => {
    const hasRequiredFields = formState.password && formState.email;
    const hasErrors = Object.values(formErrors).some((error) => !!error.length);

    return !hasRequiredFields || hasErrors;
  }, [formErrors, formState]);

  return (
    <form
      name='Login'
      className="authentication-panel__form"
    >
      <TextField
        value={formState.email}
        error={!!formErrors.email}
        helperText={formErrors.email}
        placeholder="Email"
        InputProps={{
          classes: {
            root: 'authentication-panel__input',
            error: 'textfield__input_error',
          },
          startAdornment: (
            <EmailSharpIcon
              fontSize="small"
              className="authentication-panel__input-icon authentication-panel__input-icon_start"
            />
          ),
        }}
        FormHelperTextProps={{
          classes: {
            root: 'authentication-panel__input-helper-text',
          },
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleFieldChange('email', e.target.value);
        }}
      />
      <div>
        <TextField
          value={formState.password}
          error={!!formErrors.password}
          helperText={formErrors.password}
          placeholder="Password"
          type={passwordVisible ? 'text' : 'password'}
          autoComplete="off"
          InputProps={{
            classes: {
              root: 'authentication-panel__input',
              adornedEnd: 'textfield__input_adorned-end',
              error: 'textfield__input_error',
            },
            startAdornment: (
              <LockSharpIcon
                fontSize="small"
                className="authentication-panel__input-icon authentication-panel__input-icon_start"
              />
            ),
            endAdornment: (
              <IconButton
                className="authentication-panel__input-icon-button"
                size="small"
                disableRipple
                onClick={handlePasswordInputButtonClick}
              >
                {passwordVisible
                  ? (
                    <VisibilityOffIcon
                      fontSize="small"
                      className="authentication-panel__input-icon"
                    />
                  )
                  : (
                    <VisibilityIcon
                      fontSize="small"
                      className="authentication-panel__input-icon"
                    />
                  )}
              </IconButton>
            ),
          }}
          FormHelperTextProps={{
            classes: {
              root: 'authentication-panel__input-helper-text',
            },
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleFieldChange('password', e.target.value)}
        />
        <Link
          className="authentication-panel__link"
          to={getAuthLink(View.forgotPassword)}
        >
          Forgot password?
        </Link>
      </div>
      <Button
        data-test="log-in-button"
        className="authentication-panel__button"
        variant="contained"
        color="primary"
        disabled={logInButtonDisabled}
        onClick={handleLoginClick}
      >
        Log in
      </Button>
      {isSignUpAllowed && (
        <p className="authentication-panel__account-option">
          <Typography component="span" className="authentication-panel__tip">
            Don’t have an account?
          </Typography>
          <Link to={getAuthLink(View.signUp)}>Sign up</Link>
        </p>
      )}
    </form>
  );
};

export default LogInForm;

export enum ContextEventStatus {
  start = 'Start',
  done = 'Done',
  error = 'Error',
  warning = 'Warning',
  fileSaved = 'FileSaved',
  geoDataSaved = 'GeoDataSaved',
  metaDataSaved = 'MetaDataSaved',
  parsed = 'Parsed',
  linked = 'Linked',
  ignored = 'Ignored',
  statisticsCalculated = 'StatisticsCalculated',
  publishToJohnDeere = 'PublishToJohnDeere',
  collectedFromJohnDeere = 'CollectedFromJohnDeere',
  publishToMapEngine = 'PublishToMapEngine',
  sendToProcessElevation = 'SendToProcessElevation',
  sendToCollectContext = 'SendToCollectContext',
  validated = 'Validated',
  cancelled = 'Cancelled',
  created = 'Created',
  ordered = 'Ordered',
  notDelivered = 'NotDelivered',
  genericSatelliteImageArchiveReady = 'GenericSatelliteImageArchiveReady',
  planetSatelliteImageArchiveReady = 'PlanetSatelliteImageArchiveReady',
  planetMaskArchiveReady = 'PlanetMaskArchiveReady',
  inProgress = 'InProgress',
  unknown = 'UNKNOWN',
}

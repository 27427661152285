import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/store/helpers/functions';
import { selectSelectedFieldsUuids } from '../../batchAnalytics/batchAnalyticsSelectors';
import {
  selectFarmsEntitiesByFieldUuids,
  selectFieldsEntitiesList,
  selectSortedFieldsDatasets,
} from '../../../assets/assetsSelectors';
import { selectCurrentEquation } from '../../../equations/equationsSelectors';
import { generateEquationMapsTitles } from '../helpers/functions/equationMap';
import {
  populatePreviewData,
  setTitles,
} from '../createBatchEquationBasedAnalysisSlice';
import { assignFieldVariables } from '../helpers/functions/variables';
import {
  selectEquationMapsData,
  selectTitleTemplate,
} from '../createBatchEquationBasedAnalysisSelectors';
import { getFieldsUseInterpolatedDataValues } from '../helpers/functions/settings';

export default function usePreviewSetup() {
  const dispatch = useAppDispatch();

  const selectedFieldsUuids = useAppSelector(selectSelectedFieldsUuids);
  const fieldsDatasets = useAppSelector((state) => selectSortedFieldsDatasets(state, selectedFieldsUuids));
  const fieldsEntitiesList = useAppSelector((state) => selectFieldsEntitiesList(state, selectedFieldsUuids));
  const farmsEntities = useAppSelector((state) => selectFarmsEntitiesByFieldUuids(state, selectedFieldsUuids));
  const titleTemplate = useAppSelector(selectTitleTemplate);
  const { dataVariables, title } = useAppSelector(selectCurrentEquation);
  const equationMapsData = useAppSelector(selectEquationMapsData);

  const setupPreview = () => {
    const filteredDataVariables = selectedFieldsUuids.reduce<Record<string, string[]>>((acc, fieldUuid) => {
      acc[fieldUuid] = dataVariables.filter((dataVariable) => {
        return !equationMapsData[fieldUuid]?.assignedVariables[dataVariable];
      });

      return acc;
    }, {});
    const fieldsAssignedVariables = assignFieldVariables(fieldsDatasets, filteredDataVariables);
    const fieldsUseInterpolatedDataValues = getFieldsUseInterpolatedDataValues({
      equationMapsData,
      fieldsAssignedVariables,
      fieldsDatasets,
    });

    dispatch(populatePreviewData({
      fieldsUuids: selectedFieldsUuids,
      assignedVariables: fieldsAssignedVariables,
      fieldsUseInterpolatedDataValues,
    }));
  };

  const setEquationsMapsTitles = useCallback(() => {
    const equationMapsTitles = generateEquationMapsTitles({
      fieldsEntitiesList,
      farmsEntities,
      equationTitle: title,
      titleTemplate,
    });

    dispatch(setTitles({
      fieldsUuids: selectedFieldsUuids,
      titles: equationMapsTitles,
    }));
  }, [
    fieldsEntitiesList,
    farmsEntities,
    selectedFieldsUuids,
    title,
    titleTemplate,
    dispatch,
  ]);

  return {
    setupPreview,
    setEquationsMapsTitles,
  };
}

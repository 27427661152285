import { createSlice } from '@reduxjs/toolkit';

import { extractVamapSettings } from '../../../helpers/analysis';
import {
  ASSET_TYPE_TO_ASSET_GROUP,
  AssetType,
} from '../../../helpers/constants/entities/asset';
import {
  isAsAppliedVectorAnalysis,
  isDrawnManuallyVectorAnalysis,
  isMultiLayerVectorAnalysis,
  isSatelliteVectorAnalysis,
  isSoilVectorAnalysis,
  isTopographyVectorAnalysis,
  isYieldVectorAnalysis,
  isZonesOperationsIntersectVectorAnalysis,
} from '../../../helpers/functions/entities/assets';
import {
  fetchAssetsGroup,
  fetchFieldWithSatelliteImages,
} from '../../field/fieldSlice';
import {
  cloneSatelliteWorkflow as cloneSingleLayerSatelliteWorkflow,
  cloneDatasetWorkflow as cloneSingleLayerDatasetWorkflow,
} from '../createSingleLayerAnalysis/createSingleLayerAnalysisSlice';
import {
  cloneWorkflow as cloneFieldPotentialWorkflow,
} from '../createFieldPotentialZones/createFieldPotentialZonesSlice';
import {
  cloneWorkflow as cloneStabilityWorkflow,
} from '../createStabilityZones/createStabilityZonesSlice';
import {
  cloneWorkflow as cloneCrossLayerWorkflow,
} from '../crossLayer/crossLayerSlice';
import {
  cloneWorkflow as cloneMultiLayerWorkflow,
} from '../createMultiLayerAnalysis/createMultiLayerAnalysisSlice';
import {
  cloneWorkflow as cloneDrawManuallyWorkflow,
} from '../drawManuallyWorkflow/drawManuallyWorkflowSlice';
import { ZonesOpsWorkflow } from '../zonesOps/helpers/constants/workflows';
import { isStabilityVamap } from './helpers/functions/vamap';
import {
  mapUuidsToZoneNumbers,
  prepareDrawManuallyFeature,
  prepareMultiLayerDataLayers,
} from './helpers/functions/redux';
import { selectSelectedVamapUuid } from './cloneZonesMapSelectors';
import { selectAreaUnit } from '../../user/userSelectors';
import { fetchVamapGeojson } from '../../field/fieldAPI';

const initialState = {
  workflow: ZonesOpsWorkflow.cloneZonesMap,
  selectedVamapUuid: null,
  legend: {
    open: false,
  },
};

const setupSingleLayerAnalysis = ({
  assetType,
  settings,
  imageUuid,
  index,
  attribute,
  datasetUuid,
  farmUuid,
  fieldUuid,
}) => (dispatch) => {
  dispatch(setWorkflow(ZonesOpsWorkflow.createSingleLayerAnalysis));

  if (assetType === AssetType.satelliteImage) {
    dispatch(fetchFieldWithSatelliteImages({
      fieldUuid,
      farmUuid,
      requiredSatImages: [imageUuid],
    }));
    dispatch(cloneSingleLayerSatelliteWorkflow({
      settings,
      imageUuid,
      index,
    }));
  } else {
    dispatch(fetchAssetsGroup({
      farmUuid,
      fieldUuid,
      assetsGroupType: ASSET_TYPE_TO_ASSET_GROUP[assetType],
    }));
    dispatch(cloneSingleLayerDatasetWorkflow({
      settings,
      assetType,
      datasetUuid,
      attribute,
    }));
  }
};

const setupFieldPotentialZones = ({
  settings,
  imageUuids,
  index,
}) => (dispatch) => {
  dispatch(setWorkflow(ZonesOpsWorkflow.createFieldPotentialZones));
  dispatch(cloneFieldPotentialWorkflow({
    settings,
    imageUuids,
    index,
  }));
};

const setupFieldStabilityZones = ({
  settings,
  imageUuids,
}) => (dispatch) => {
  dispatch(setWorkflow(ZonesOpsWorkflow.createStabilityZones));
  dispatch(cloneStabilityWorkflow({
    settings,
    imageUuids,
  }));
};

const setupCrossLayer = ({
  settings,
  zonesOperationMaps,
}) => (dispatch) => {
  const uuidsToZoneNumbersMap = mapUuidsToZoneNumbers(zonesOperationMaps);

  dispatch(setWorkflow(ZonesOpsWorkflow.crossLayer));
  dispatch(cloneCrossLayerWorkflow({
    settings,
    zonesOperationMaps: uuidsToZoneNumbersMap,
  }));
};

const setupMultiLayerAnalysis = ({
  settings,
  dataLayers,
}) => (dispatch) => {
  const multiLayerDataLayers = prepareMultiLayerDataLayers(dataLayers);

  dispatch(setWorkflow(ZonesOpsWorkflow.createMultiLayerAnalysis));
  dispatch(cloneMultiLayerWorkflow({
    settings,
    dataLayers: multiLayerDataLayers,
  }));
};

const setupDrawManually = ({
  geoMaps,
  farmUuid,
  fieldUuid,
}) => (dispatch, getState) => {
  const state = getState();

  dispatch(setWorkflow(ZonesOpsWorkflow.drawManually));

  return fetchVamapGeojson({
    farmUuid,
    fieldUuid,
    uuid: selectSelectedVamapUuid(state),
    areaUnit: selectAreaUnit(state),
  })
    .then((zonesMapGeojson) => {
      const colors = geoMaps[0].hexColorCodes;
      const feature = prepareDrawManuallyFeature(zonesMapGeojson);

      dispatch(cloneDrawManuallyWorkflow({
        feature,
        colors,
      }));
    });
};

export const setupCloneWorkflow = ({
  vamap,
  farmUuid,
  fieldUuid,
}) => (dispatch) => {
  const settings = extractVamapSettings(vamap);

  if (isSatelliteVectorAnalysis(vamap)) {
    if (vamap.satelliteImages.length === 1) {
      const imageUuid = vamap.satelliteImages[0].uuid;

      dispatch(setupSingleLayerAnalysis({
        assetType: AssetType.satelliteImage,
        imageUuid,
        settings,
        index: vamap.parameters.index,
        farmUuid,
        fieldUuid,
      }));
    } else {
      const imageUuids = vamap.satelliteImages.map(({ uuid }) => uuid);

      if (isStabilityVamap(vamap)) {
        dispatch(setupFieldStabilityZones({
          imageUuids,
          settings,
        }));
      } else {
        dispatch(setupFieldPotentialZones({
          imageUuids,
          settings,
          index: vamap.parameters.index,
        }));
      }
    }
  } else if (isSoilVectorAnalysis(vamap)) {
    const attribute = vamap.parameters.soilAttribute;
    const datasetUuid = vamap.soilDataset.uuid;

    dispatch(setupSingleLayerAnalysis({
      assetType: AssetType.soilDataset,
      attribute,
      datasetUuid,
      settings,
      farmUuid,
      fieldUuid,
    }));
  } else if (isYieldVectorAnalysis(vamap)) {
    const attribute = vamap.parameters.yieldAttribute;
    const datasetUuid = vamap.yieldDataset.uuid;

    dispatch(setupSingleLayerAnalysis({
      assetType: AssetType.yieldDataset,
      attribute,
      datasetUuid,
      settings,
      farmUuid,
      fieldUuid,
    }));
  } else if (isAsAppliedVectorAnalysis(vamap)) {
    const attribute = vamap.parameters.asAppliedAttribute;
    const datasetUuid = vamap.asAppliedDataset.uuid;

    dispatch(setupSingleLayerAnalysis({
      assetType: AssetType.asAppliedDataset,
      attribute,
      datasetUuid,
      settings,
      farmUuid,
      fieldUuid,
    }));
  } else if (isTopographyVectorAnalysis(vamap)) {
    const attribute = vamap.parameters.topographyAttribute;
    const datasetUuid = vamap.topographyMap.uuid;

    dispatch(setupSingleLayerAnalysis({
      assetType: AssetType.topographyMap,
      attribute,
      datasetUuid,
      settings,
      farmUuid,
      fieldUuid,
    }));
  } else if (isZonesOperationsIntersectVectorAnalysis(vamap)) {
    const { zonesOperationMaps } = vamap;

    dispatch(setupCrossLayer({
      settings,
      zonesOperationMaps,
    }));
  } else if (isMultiLayerVectorAnalysis(vamap)) {
    const { dataLayers } = vamap;

    dispatch(setupMultiLayerAnalysis({
      settings,
      dataLayers,
    }));
  } else if (isDrawnManuallyVectorAnalysis(vamap)) {
    const { geoMaps } = vamap;

    dispatch(setupDrawManually({
      geoMaps,
      farmUuid,
      fieldUuid,
    }));
  }
};

export const prepareCloneWorkflow = ({
  vamap,
  farmUuid,
  fieldUuid,
}) => (dispatch) => {
  dispatch(setSelectedVamapUuid(vamap.uuid));
  dispatch(setupCloneWorkflow({
    vamap,
    farmUuid,
    fieldUuid,
  }));
};

export const cloneZonesMapSlice = createSlice({
  name: 'cloneZonesMap',
  initialState,
  reducers: {
    setWorkflow(state, action) {
      state.workflow = action.payload;
    },
    setSelectedVamapUuid(state, action) {
      state.selectedVamapUuid = action.payload;
    },
    openLegend(state) {
      state.legend.open = true;
    },
    closeLegend(state) {
      state.legend.open = false;
    },
    reset() {
      return initialState;
    },
  },
});

export const {
  setWorkflow,
  setSelectedVamapUuid,
  openLegend,
  closeLegend,
  reset,
} = cloneZonesMapSlice.actions;

export default cloneZonesMapSlice.reducer;

import i18n from 'i18next';

import { PinsGroup } from '../../../../pins/types';
import { PinsGroupsFilters } from '../../types/pinsGroups';
import { DEFAULT_PINS_GROUPS_FILTER } from '../constants/pinsGroups';
import { comparator } from '../../../../../helpers/functions/utils/string';
import { ASSET_TYPE_TO_I18N_KEY } from '../../../../../helpers/constants/entities/asset';

export const getPinsGroupsTypeFilterOptions = (pinsGroups: PinsGroup[] = [], allValue: string = '') => {
  const availableTypes = new Set(pinsGroups.map(({ entity }) => entity._type));
  const options = [...availableTypes].map((type) => ({
    title: i18n.t(ASSET_TYPE_TO_I18N_KEY[type]),
    value: type,
  }));

  return [
    {
      title: i18n.t('general.controls.zones-map-filters.all'),
      value: allValue,
    },
    ...options.sort((aOption, bOption) => comparator(aOption.title, bOption.title)),
  ];
};

export const filterPinsGroups = (aFilter: PinsGroupsFilters, pinsGroups: PinsGroup[] = []) => {
  const {
    typeFilterValue,
    nameFilterValue = '',
  } = aFilter || {};
  const preparedNameFilterValue = nameFilterValue.toLowerCase();

  return pinsGroups.filter((pinsGroup) => {
    const nameMatched = pinsGroup.name.toLowerCase().includes(preparedNameFilterValue);

    return typeFilterValue === DEFAULT_PINS_GROUPS_FILTER.typeFilterValue
      ? nameMatched
      : pinsGroup.entity._type === typeFilterValue && nameMatched;
  });
};

import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';

import { getCommonSteps } from '../../../zonesOps/helpers/functions/steps';
import { Step } from '../../../../../components/Stepper';
import ShapeDataType from '../../containers/Stepper/Steps/ShapeDataType';
import ShapeDataSource from '../../containers/Stepper/Steps/ShapeDataSource';
import ZonesMap from '../../containers/Stepper/Steps/ZonesMap';
import Preview from '../../containers/Stepper/Steps/Preview';
import Link from '../../../../../components/Link';
import { PAGES_ROOTS, getRootLink } from '../../../../../helpers/navigation';

export const getSteps = (withFieldSelection: boolean): Step[] => {
  let firstSteps: Step[] = [];

  if (withFieldSelection) {
    firstSteps = getCommonSteps();
  }

  return [
    ...firstSteps,
    {
      name: i18n.t('create-3d.stepper.shape-data-type'),
      description: (
        <Trans i18nKey="create-3d.steps.1.description">
          text <Link to={getRootLink(PAGES_ROOTS.upload)}>link</Link>
        </Trans>
      ),
      id: 'shapeDataType',
      component: ShapeDataType,
    },
    {
      name: i18n.t('create-3d.stepper.shape-data-source'),
      description: i18n.t('create-3d.steps.2.description'),
      id: 'shapeDataSource',
      component: ShapeDataSource,
    },
    {
      name: i18n.t('general.shared.zones-map'),
      description: i18n.t('create-3d.steps.3.description'),
      id: 'zonesMap',
      component: ZonesMap,
    },
    {
      name: i18n.t('general.shared.preview'),
      id: 'preview',
      component: Preview,
    },
  ];
};

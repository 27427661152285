export enum FilterParam {
  timeRange = 'timeRange',
  farm = 'farm',
  field = 'field',
  dateFrom = 'dateFrom',
  dateTo = 'dateTo',
}

export const MONTHS_BACK_ALLOWED = 6;

export const CUSTOM_TIME_RANGE_FILTER = 'custom';

export const TIME_RANGES = ['3', '6', '12', '24', '72', CUSTOM_TIME_RANGE_FILTER] as const;

export const DEFAULT_TIME_RANGE = TIME_RANGES[3];

export const MAX_ALLOWED_TIME_RANGE = TIME_RANGES[4];

export const TIME_RANGE_DATE_FORMAT = 'dd/MM/yyyy';

import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';

import './index.scss';

const MIN_VALUE = 0;
const MAX_VALUE = 32;

export default function WindowSizeSlider({
  value,
  onChange,
}: {
  value: number,
  onChange: (v: number) => void,
}) {
  const { t } = useTranslation();

  const [innerValue, setInnerValue] = useState(value);

  const marks = useMemo(() => {
    return [
      {
        value: MIN_VALUE,
        label: t('clean-calibrate.yield-popup.tabs.calibrate.configure.window-size.marks.low'),
      },
      {
        value: (MIN_VALUE + MAX_VALUE) / 2,
        label: t('clean-calibrate.yield-popup.tabs.calibrate.configure.window-size.marks.medium'),
      },
      {
        value: MAX_VALUE,
        label: t('clean-calibrate.yield-popup.tabs.calibrate.configure.window-size.marks.high'),
      },
    ];
  }, [t]);

  return (
    <div className="window-size-slider">
      <Typography className="window-size-slider__title">
        {t('clean-calibrate.yield-popup.tabs.calibrate.configure.window-size.title')}
      </Typography>
      <Slider
        classes={{
          rail: 'window-size-slider__slider-rail',
          mark: 'window-size-slider__slider-mark',
        }}
        value={innerValue}
        track={false}
        step={1}
        marks={marks}
        min={MIN_VALUE}
        max={MAX_VALUE}
        onChange={(_e, v) => setInnerValue(v as number)}
        onChangeCommitted={(_e, v) => onChange(v as number)}
      />
    </div>
  );
}

import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useDispatch } from 'react-redux';

import ImageList from '../../../components/ImageList';
import AntSwitch from '../../../../../components/AntSwitch';
import { openPopup } from '../../../../ui/popups/popupsSlice';
import PinCommentInput from '../../../components/PinCommentInput';
import PinCommentDisplay from '../../../components/PinCommentDisplay';

import './index.scss';

const ReadMode = ({
  pin,
  assetsLoaded,
  onChangePinCompleted = () => {},
  onWarning = () => {},
  onSaveComment = () => {},
}) => {
  const { t } = useTranslation();
  const [isPinCompleted, setIsPinCompleted] = useState(pin.completed);
  const [shouldDisplayPinPhotos, setShouldDisplayPinPhotos] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!pin) {
      return;
    }
    setShouldDisplayPinPhotos(pin.photos?.length > 0);
  }, [pin]);

  const handleChangePinCompleted = useCallback(
    (value) => {
      onChangePinCompleted(value);
      setIsPinCompleted(value);
    },
    [onChangePinCompleted],
  );

  const handlePhotoClick = useCallback(
    (currentPhotoIndex, photos) => {
      dispatch(openPopup({
        type: 'pin-photos',
        pinName: pin.title,
        photos: photos || pin.photos,
        currentPhotoIndex,
      }));
    },
    [dispatch, pin],
  );

  return (
    <>
      <DialogContent className="pin-read-mode">
        <Typography
          variant="h6"
          className="pin-read-mode__title"
        >
          {pin.title || t('general.popups.pin-details.no-title')}
        </Typography>
        <Typography
          variant="body2"
          className={clsx('pin-read-mode__description', {
            'pin-read-mode__description_empty': !pin.comment,
          })}
        >
          {pin.comment || t('general.popups.pin-details.no-description')}
        </Typography>
        {
          shouldDisplayPinPhotos
          && (
            <div className="pin-read-mode__photos-container">
              {
              assetsLoaded
              && (
                <ImageList
                  images={pin.photos}
                  onImageClick={handlePhotoClick}
                />
              )
            }
            </div>
          )
        }
        <div className="pin-read-mode__switch">
          <AntSwitch
            isChecked={pin.completed}
            label={
              isPinCompleted
                ? t('general.popups.pin-details.completed')
                : t('general.popups.pin-details.not-completed')
            }
            onValueChange={handleChangePinCompleted}
          />
        </div>
        {
          pin.comments?.length > 0 && (
            <>
              <Typography
                variant="h6"
                className="pin-read-mode__subtitle"
              >
                {t('general.popups.pin-details.comments')}
              </Typography>
              <div className="pin-read-mode__comments">
                {
                  pin.comments.map((_comment, i) => (
                    <PinCommentDisplay
                      comment={pin.comments[pin.comments.length - 1 - i]}
                      key={`comment_${i}`}
                      onPhotoClick={handlePhotoClick}
                    />
                  ))
                }
              </div>
            </>
          )
        }
      </DialogContent>
      <DialogActions
        classes={{
          root: 'pin-read-mode__actions',
        }}
      >
        <PinCommentInput onSave={onSaveComment} onWarning={onWarning} />
      </DialogActions>
    </>
  );
};

export default ReadMode;

import React, {
  Fragment,
  ReactNode,
} from 'react';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

import './index.scss';

type SummeryItemStatus = 'success' | 'error' | 'neutral';

type SummaryItem = {
  text?: string | number;
  status?: SummeryItemStatus;
  withSeparator?: boolean;
  loading?: boolean;
  icon?: ReactNode;
};

const SummaryPanel = ({ summary }: { summary: SummaryItem[][] }) => (
  <div className="summary-panel">
    {summary.map((summarySection, sectionIndex) => (
      <div
        key={sectionIndex}
        className={clsx('summary-panel__section', {
          'summary-panel__section_half-width': summary.length === 2,
        })}
      >
        {summarySection.map((item, itemIndex) => {
          const {
            text,
            status,
            withSeparator,
            loading,
            icon,
          } = item;

          return (
            <Fragment key={itemIndex}>
              {loading
                ? <CircularProgress size={20} />
                : (
                  <>
                    {icon}
                    <Typography
                      className={clsx(
                        'summary-panel__section-text',
                        {
                          [`summary-panel__section-text_${status}`]: status,
                          'summary-panel__section-text_with-separator': withSeparator,
                        },
                      )}
                    >
                      {text}
                    </Typography>
                  </>
                )}
            </Fragment>
          );
        })}
      </div>
    ))}
  </div>
);

export default SummaryPanel;

import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import BackdropLoading from '../../../../../components/Loading/BackdropLoading';
import ScrollContainer from '../../../../../components/ScrollContainer';
import { getViewType } from '../../helpers/functions/view';
import { VIEWS } from '../../helpers/constants/view';
import { selectUpdateInProgress } from '../../zonesMapSelectors';
import Description from './Description';
import Rates from './Rates';

import './index.scss';

const getTabContent = (view) => {
  if (view === VIEWS.description) {
    return (
      <Description />
    );
  }

  if (view === VIEWS.rates) {
    return (
      <Rates />
    );
  }

  return null;
};

const ZonesMapPanel = () => {
  const location = useLocation();
  const updateInProgress = useSelector(selectUpdateInProgress);
  const viewType = getViewType(location.search);

  return (
    // TODO need to move loader to components for better readability
    <ScrollContainer classes={{ root: 'zones-map-panel' }}>
      {getTabContent(viewType)}
      {updateInProgress && <BackdropLoading />}
    </ScrollContainer>
  );
};

export default ZonesMapPanel;

import { useSelector } from 'react-redux';

import { isPricingEnabled } from '../helpers/functions/utils/appConfig';
import {
  selectOrganizations,
  selectPermissionedOrganizationAdmin,
} from '../features/user/userSelectors';

/**
 * Determines whether subscription-related features
 * (e.g. /welcome-screen and /user/subscription pages) are available.
 * @returns subscriptionAvailable
 */
export default function useSubscriptionAvailable(): boolean {
  const orgAdmin = useSelector(selectPermissionedOrganizationAdmin);
  const orgs = useSelector(selectOrganizations);

  return isPricingEnabled() && (orgAdmin || orgs.length === 0);
}

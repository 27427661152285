import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Select from '../../../../../components/Select';
import TextField from '../../../../../components/TextField';
import ResetFiltersButton from '../ResetFiltersButton';
import { VectorAnalysisFilters } from '../../types/analysis';
import { PinsGroupsFilters } from '../../types/pinsGroups';

import './index.scss';

type ZonesMapFilters = VectorAnalysisFilters | PinsGroupsFilters;

export default function ZonesMapFilter({
  typeOptions,
  value,
  classes,
  onChange,
  onFiltersClear,
}: {
  typeOptions: {
    title: string;
    value: string;
  }[];
  value: ZonesMapFilters;
  isExpanded?: boolean;
  classes?: {
    root?: string,
    nameFilter?: string,
    typeFilter?: string,
  };
  onChange: (v: ZonesMapFilters) => void;
  onFiltersClear?: () => void;
}) {
  const { t } = useTranslation();

  const { typeFilterValue, nameFilterValue } = value;

  const handleTypeFilterValueChange = (filterValue: string | null) => {
    if (filterValue == null) {
      return;
    }

    onChange({
      ...value,
      typeFilterValue: filterValue,
    });
  };

  const handleNameFilterValueChange = (nameValue: string) => {
    onChange({
      ...value,
      nameFilterValue: nameValue,
    });
  };

  return (
    <div className={clsx('zones-map-filters', classes?.root)}>
      <TextField
        placeholder={t(
          'general.controls.zones-map-filters.name-filter-placeholder',
        )}
        classes={{
          title: 'zones-map-filters__control-label',
          wrapper: clsx('zones-map-filters__control', classes?.nameFilter),
        }}
        value={nameFilterValue}
        type="search"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          handleNameFilterValueChange(event.target.value);
        }}
        onEndAdornmentClick={() => handleNameFilterValueChange('')}
      />
      <Select
        id="typeFilter"
        classes={{
          root: clsx('zones-map-filters__control', classes?.typeFilter),
        }}
        label={t('general.controls.zones-map-filters.type-label')}
        options={typeOptions}
        value={typeFilterValue}
        onChange={handleTypeFilterValueChange}
      />
      {
        onFiltersClear
          && (
            <ResetFiltersButton
              classes={{ root: 'zones-map-filters__reset-button' }}
              onClick={onFiltersClear}
            />
          )
      }
    </div>
  );
}

import React, { Suspense } from 'react';

import Loading from '../../../../../components/Loading';
import { isJohnDeereWorkflowEnabled } from '../../../../../helpers/functions/utils/appConfig';

const JDIntegrationCard = React.lazy(() => import('./JDIntegrationCard'));

export default function IntegrationCard() {
  if (!isJohnDeereWorkflowEnabled()) {
    return null;
  }

  return (
    <Suspense fallback={<Loading />}>
      <JDIntegrationCard />
    </Suspense>
  );
}

import React, { useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../../app/store/helpers/functions';
import { getThumbnailUrl } from '../../../../../../helpers/functions/utils/url';
import { selectEquationMapEntity } from '../../../../../assets/assetsSelectors';
import { selectApiKey } from '../../../../../user/userSelectors';
import { selectEquationMapData } from '../../../createBatchEquationBasedAnalysisSelectors';
import {
  ProductUnit,
  PRODUCT_UNIT_TO_I18N_LABEL,
} from '../../../../../../helpers/constants/units/productUnit';
import {
  getEquationMapTableData,
  getEquationMapThumbnailGeoMap,
  getPixelsGeoMap,
} from '../../../../../../helpers/functions/entities/equationMap';
import { prettifyNumber } from '../../../../../../helpers/markup';
import { convertProductUnitToAbsoluteUnit } from '../../../../../../helpers/functions/units/absoluteUnit';
import { ABSOLUTE_UNIT_TO_I18N_LABEL } from '../../../../../../helpers/constants/units/absoluteUnit';
import { TILE_MAP_SIZE } from '../../../helpers/constants/ui';

import './index.scss';

const getProductUnitI18nKey = (productUnit: ProductUnit) => {
  return PRODUCT_UNIT_TO_I18N_LABEL[productUnit];
};

const getAbsoluteUnitI18nKey = (productUnit: ProductUnit) => {
  const absoluteUnit = convertProductUnitToAbsoluteUnit(productUnit);
  return ABSOLUTE_UNIT_TO_I18N_LABEL[absoluteUnit];
};

export default function PreviewTileEquationMapView({
  fieldUuid,
}: {
  fieldUuid: string,
}) {
  const { t } = useTranslation();

  const apiKey = useAppSelector(selectApiKey);
  const equationMapData = useAppSelector((state) => selectEquationMapData(state, fieldUuid));
  const equationMapEntity = useAppSelector((state) => {
    return selectEquationMapEntity(state, equationMapData?.uuid || '');
  });

  const preview = useMemo(() => {
    const thumbnail = getEquationMapThumbnailGeoMap(equationMapEntity?.geoMaps);

    return getThumbnailUrl({ thumbnail, size: TILE_MAP_SIZE, apiKey });
  }, [equationMapEntity?.geoMaps, apiKey]);

  const legend = useMemo(() => {
    const geoMap = getPixelsGeoMap(equationMapEntity);

    if (!geoMap) {
      return [];
    }

    const { rows } = getEquationMapTableData({ geoMap });

    return rows;
  }, [equationMapEntity]);

  return (
    <div className="preview-tile-equation-map-view">
      <div className="preview-tile-equation-map-view__map-container">
        <div className="preview-tile-equation-map-view__map">
          <img
            className="preview-tile-equation-map-view__map-image"
            src={preview}
            alt={equationMapEntity?.name}
          />
        </div>
        <div className="preview-tile-equation-map-view__legend">
          {
            equationMapEntity?.productUnit
              && (
                <Typography className="preview-tile-equation-map-view__legend-title">
                  {t(getProductUnitI18nKey(equationMapEntity.productUnit))}
                </Typography>
              )
          }
          {
            // TODO: fix typings when helper function refactored
            legend.map(({ data }: any, index: number) => {
              return (
                <div
                  key={index}
                  className="preview-tile-equation-map-view-legend-item"
                >
                  <div
                    style={{ backgroundColor: data.color.color }}
                    className="preview-tile-equation-map-view-legend-item__color"
                  >
                  </div>
                  <Typography className="preview-tile-equation-map-view-legend-item__value">
                    {prettifyNumber(parseFloat(data.value.text))}
                  </Typography>
                </div>
              );
            })
          }
        </div>
      </div>
      <div className="preview-tile-equation-map-view__statistics">
        {
          equationMapEntity?.statistics?.avg != null
            && (
              <div className="preview-tile-equation-map-view-statistics-item">
                <Typography className="preview-tile-equation-map-view-statistics-item__title">
                  {t('general.controls.map-legend.statistics.avg')}
                </Typography>
                <Typography>
                  {prettifyNumber(equationMapEntity.statistics.avg)}
                  {
                    equationMapEntity.productUnit
                      && ` ${t(getProductUnitI18nKey(equationMapEntity.productUnit))}`
                  }
                </Typography>
              </div>
            )
      }
        {
        equationMapEntity?.statistics?.sum != null
          && (
            <>
              <div className="preview-tile-equation-map-view-statistics-item__separator"></div>
              <div className="preview-tile-equation-map-view-statistics-item">
                <Typography className="preview-tile-equation-map-view-statistics-item__title">
                  {t('general.controls.map-legend.statistics.sum')}
                </Typography>
                <Typography>
                  {prettifyNumber(equationMapEntity.statistics.sum)}
                  {
                    equationMapEntity.productUnit
                      && ` ${t(getAbsoluteUnitI18nKey(equationMapEntity.productUnit))}`
                  }
                </Typography>
              </div>
            </>
          )
      }
      </div>
    </div>
  );
}

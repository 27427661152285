import React, { Ref, forwardRef } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

import type { FlattenNode } from '../../types/node';

const TreeView = ({
  nodes = [],
  offset,
}: {
  nodes: FlattenNode[],
  offset: number,
}, ref: Ref<VirtuosoHandle>) => {
  const itemRenderer = (node: FlattenNode) => {
    return node.getElement?.(node.level * offset);
  };

  return (
    <Virtuoso
      className="tree-view"
      ref={ref}
      data={nodes}
      itemContent={(_i, node) => itemRenderer(node)}
    />
  );
};

export default forwardRef(TreeView);

import {
  ascSumComparator,
  descSumComparator,
  ascAvgComparator,
  descAvgComparator,
} from '../functions/ui';

export enum SortOrder {
  ascSum = 'ascSum',
  descSum = 'descSum',
  ascAvg = 'ascAvg',
  descAvg = 'descAvg',
  none = 'none',
}

export const SORT_ORDER_TO_I18N_KEYS = {
  [SortOrder.ascSum]: 'batch-equation-based.steps.preview.sort-order.asc-sum',
  [SortOrder.descSum]: 'batch-equation-based.steps.preview.sort-order.desc-sum',
  [SortOrder.ascAvg]: 'batch-equation-based.steps.preview.sort-order.asc-avg',
  [SortOrder.descAvg]: 'batch-equation-based.steps.preview.sort-order.desc-avg',
  none: 'batch-equation-based.steps.preview.sort-order.none',
} as const;

export const SORT_ORDER_TO_COMPARATOR_FN = {
  [SortOrder.ascSum]: ascSumComparator,
  [SortOrder.descSum]: descSumComparator,
  [SortOrder.ascAvg]: ascAvgComparator,
  [SortOrder.descAvg]: descAvgComparator,
  [SortOrder.none]: () => 0,
} as const;

export const TILE_MAP_SIZE = 186;

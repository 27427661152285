import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function D2Icon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.0049 0L11.3449 0.03L15.1549 3.84L16.4849 2.51C19.7549 4.06 22.0949 7.23 22.4449 10.99H23.9449C23.4449 4.84 18.2949 0 12.0049 0ZM16.9649 9.04C16.6449 8.71 16.2649 8.45 15.8249 8.27C15.3849 8.09 14.8949 8 14.3549 8H11.9949V16H14.2949C14.8449 16 15.3549 15.91 15.8049 15.73C16.2549 15.55 16.6449 15.3 16.9649 14.97C17.2849 14.64 17.5349 14.24 17.7049 13.78C17.8749 13.31 17.9649 12.79 17.9649 12.21V11.81C17.9649 11.23 17.8749 10.71 17.7049 10.24C17.5349 9.77 17.2849 9.37 16.9649 9.04ZM7.52493 21.48C4.25493 19.94 1.91493 16.76 1.55493 13H0.0549316C0.564932 19.16 5.71493 24 12.0049 24L12.6649 23.97L8.85493 20.16L7.52493 21.48ZM16.4349 13.33C16.5249 12.99 16.5749 12.62 16.5749 12.2V11.8C16.5749 10.93 16.3849 10.27 16.0049 9.81C15.6349 9.35 15.0849 9.12 14.3649 9.12H13.3949V14.89H14.3049C14.6749 14.89 15.0049 14.83 15.2949 14.71C15.5749 14.59 15.8149 14.41 16.0049 14.18C16.1949 13.95 16.3349 13.66 16.4349 13.33ZM6.15176 16H11.458V14.9485H7.79404L9.72358 12.8835C10.2294 12.3342 10.5926 11.8446 10.813 11.4146C11.037 10.981 11.1491 10.5673 11.1491 10.1734C11.1491 9.49413 10.9268 8.96296 10.4824 8.57995C10.0416 8.19332 9.43812 8 8.67209 8C8.14092 8 7.67118 8.10659 7.26287 8.31978C6.85818 8.53297 6.54562 8.83288 6.3252 9.21951C6.1084 9.60253 6 10.0343 6 10.5149H7.31707C7.31707 10.0632 7.43451 9.70732 7.66938 9.44715C7.90425 9.18699 8.23487 9.05691 8.66125 9.05691C9.01897 9.05691 9.30262 9.17254 9.51219 9.40379C9.72538 9.63505 9.83198 9.93857 9.83198 10.3144C9.83198 10.5998 9.75068 10.8853 9.58808 11.1707C9.42909 11.4526 9.15989 11.8049 8.78049 12.2276L6.15176 15.0949V16Z" />
    </SvgIcon>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import CodeEditor from '../../../../../components/CodeEditor';
import CodeEditorErrorsPanel from '../../../../../components/CodeEditorErrorsPanel';
import FormulaEditorInstructions from '../FormulaEditorInstructions';

import './index.scss';

export default function FormulaEditor({
  uuid,
  equationAsText,
  useNumpy,
  fullScreenMode,
  errorsLoading,
  errorMessage,
  onFullScreenModeChange,
  onUseNumpyChange,
  onEquationChange,
  onTestRunClick,
}: {
  uuid: string,
  equationAsText: string,
  useNumpy: boolean,
  fullScreenMode: boolean,
  errorsLoading: boolean,
  errorMessage: string,
  onFullScreenModeChange: () => void,
  onUseNumpyChange: () => void,
  onEquationChange: (s: string) => void,
  onTestRunClick: () => void,
}) {
  const { t } = useTranslation();

  const testRunDisabled = errorsLoading || !!errorMessage || !equationAsText;

  return (
    <div className="equation-editor">
      <FormulaEditorInstructions uuid={uuid} />
      <CodeEditor
        title={t('zones-ops.equation-based.steps.4.panel.textarea-title')}
        placeholder={t('zones-ops.equation-based.steps.4.panel.textarea-placeholder')}
        value={equationAsText}
        testRunDisabled={testRunDisabled}
        fullScreen={{
          mode: fullScreenMode,
          onClick: onFullScreenModeChange,
        }}
        numpy={{
          mode: useNumpy,
          onClick: onUseNumpyChange,
        }}
        onChange={onEquationChange}
        onTestRunClick={onTestRunClick}
      />
      <CodeEditorErrorsPanel
        errorMessage={errorMessage}
        loading={errorsLoading}
      />
    </div>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getI18nAreaUnit } from '../../../../helpers';
import { selectAreaUnit } from '../../../user/userSelectors';

import './index.scss';

export default function FieldTitle({
  fieldArea,
  fieldName,
}: {
  fieldArea: number,
  fieldName: string,
}) {
  const areaUnit = useSelector(selectAreaUnit);
  const { t } = useTranslation();

  return (
    <>
      {
        fieldName
          && (
            <span>{fieldName}</span>
          )
      }
      {
        fieldArea && areaUnit
          && (
            <span className="field-title__area">
              ({fieldArea.toFixed(2)} {t(getI18nAreaUnit(areaUnit))})
            </span>
          )
      }
    </>
  );
}

import { getHexColorCodes } from '../../../../../helpers/analysis';

export const hasColorsUpdates = (zonesMap, colorsUpdate) => {
  if (!colorsUpdate) {
    return false;
  }

  const oldColors = getHexColorCodes(zonesMap.geoMaps);

  return colorsUpdate.length !== oldColors.length
    || oldColors.some((oldColor, oldColorIndex) => {
      return oldColor !== colorsUpdate[oldColorIndex];
    });
};

export const hasGeojsonUpdates = (zonesMap, geojsonUpdate) => {
  if (!geojsonUpdate) {
    return false;
  }

  return geojsonUpdate.features.length !== zonesMap.zonesMapGeojson.features.length
    || JSON.stringify(geojsonUpdate) !== JSON.stringify(zonesMap.zonesMapGeojson);
};

export const hasUpdates = (zonesMap, updates) => {
  if (!updates) {
    return false;
  }

  let result = false;

  const {
    type,
    zonesMapGeojson,
    colors,
  } = updates;

  if (
    (type && type !== zonesMap.type)
    || (colors && hasColorsUpdates(zonesMap, colors))
    || (zonesMapGeojson && hasGeojsonUpdates(zonesMap, zonesMapGeojson))
  ) {
    result = true;
  }

  return result;
};

import { getSearchParam } from '../../../../../helpers/navigation';
import { MapView } from '../constants/view';

export const getViewType = (search) => {
  return getSearchParam(search, 'view', MapView.table);
};

export const isMapView = (type) => {
  return type === MapView.map;
};

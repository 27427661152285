import { connect } from 'react-redux';

import { selectAreaUnit } from '../../../../../user/userSelectors';
import {
  selectGeneratedVectorAnalysisMap,
} from '../../../../../createAnalysis/createAnalysisSelectors';
import SettingsPanel from '../../../../zonesOps/components/SettingsPanel';

const mapStateToProps = (state) => {
  const {
    zonesMapGeojson,
    geoMaps,
  } = selectGeneratedVectorAnalysisMap(state) || {};

  return {
    areaUnit: selectAreaUnit(state),
    zonesMapGeojson,
    geoMaps,
  };
};

export default connect(mapStateToProps)(SettingsPanel);

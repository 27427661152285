import React from 'react';

import JDIntegrationCard from '../../../jdIntegration/containers/IntegrationCard';
import MarketplaceLayout from '../../components/MarketplaceLayout';

export default function Marketplace() {
  return (
    <MarketplaceLayout>
      <JDIntegrationCard />
    </MarketplaceLayout>
  );
}

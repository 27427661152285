import type {
  FullAttribute,
  TransformedYieldDataset,
} from '../../../../../helpers/types/dataset';
import {
  NullableAvgTotalCalibrateCondition,
  NullableMinMaxCalibrateCondition,
  NullableMinMaxCleanCondition,
} from '../../types/actions';
import type { FullAttributeItem, NullableMinMaxCondition } from '../../types/ui';
import { getAttributeString } from './attributes';

export const createFullAttributeItem = (fullAttribute: FullAttribute): FullAttributeItem => {
  return {
    value: getAttributeString(fullAttribute),
    title: fullAttribute.fullName || fullAttribute.transliteratedName,
  };
};

export const getComboBoxOptionSelected = (
  option: FullAttributeItem,
  value: FullAttributeItem,
) => {
  return option.value === value.value;
};

export const isFullAttributeItemDisabled = (
  conditions: { attribute: string | null }[],
  attribute: FullAttributeItem,
  selectedAttribute?: FullAttributeItem,
) => {
  const conditionAttributes = new Set(conditions.map((c) => c.attribute));

  return attribute.value !== selectedAttribute?.value
   && conditionAttributes.has(attribute.value);
};

export const getAvailableFullAttributeItems = (
  availableAttributes: string[] | null,
  dataset?: TransformedYieldDataset,
) => {
  const availableAttributesValues = new Set(availableAttributes);

  return (dataset?.fullAttributes || [])
    .filter((fullAttribute) => {
      const fullAttributeItem = createFullAttributeItem(fullAttribute);

      return availableAttributesValues.has(fullAttributeItem.value);
    })
    .map((fullAttribute) => {
      return createFullAttributeItem(fullAttribute);
    });
};

export const transformCalibrateMinMaxConditions = (
  conditions: NullableMinMaxCalibrateCondition[],
): NullableMinMaxCondition[] => {
  return conditions.map((condition) => {
    return {
      attribute: condition.calibrationAttribute,
      min: condition.min,
      minIncluded: condition.minIncluded,
      max: condition.max,
      maxIncluded: condition.maxIncluded,
    };
  });
};

export const transformCalibrateAvgTotalConditions = (
  conditions: NullableAvgTotalCalibrateCondition[],
): { attribute: string | null }[] => {
  return conditions.map((condition) => {
    return {
      attribute: condition.calibrationAttribute,
    };
  });
};

export const transformCleanMinMaxConditions = (
  conditions: NullableMinMaxCleanCondition[],
): NullableMinMaxCondition[] => {
  return conditions.map((condition) => {
    return {
      attribute: condition.cleanAttribute,
      min: condition.min,
      max: condition.max,
    };
  });
};

import React, { useCallback, useRef } from 'react';
import { VirtuosoHandle } from 'react-virtuoso';

import { ListNode } from '../types/node';
import ListView from '../components/ListView';

export default function useListView({
  nodes,
}: {
  nodes: ListNode[],
}) {
  const ref = useRef<VirtuosoHandle>(null);

  const getNodeIndexById = useCallback((id: string) => {
    return nodes.findIndex((node) => node.id === id);
  }, [nodes]);

  return {
    listView: (
      <ListView
        nodes={nodes}
        ref={ref}
      />
    ),
    ref,
    getNodeIndexById,
  };
}

import { useDispatch, useSelector } from 'react-redux';

import {
  selectAssetType,
  selectDataLayer,
  selectSettings,
} from '../createSingleLayerAnalysisSelectors';
import { AssetType } from '../../../../helpers/constants/entities/asset';
import {
  generateAsAppliedVectorAnalysisMap,
  generateSatelliteVectorAnalysisMap,
  generateSoilVectorAnalysisMap,
  generateTopographyVectorAnalysisMap,
  generateYieldVectorAnalysisMap,
} from '../../../createAnalysis/createAnalysisSlice';
import { CustomError } from '../../../../helpers/functions/utils/errorHandling';

export default function useGenerateSingleLayerVamap() {
  const dispatch = useDispatch();
  const assetType = useSelector(selectAssetType);
  const settings = useSelector(selectSettings);
  const dataLayer = useSelector(selectDataLayer);

  let result;

  if (assetType === AssetType.satelliteImage) {
    result = () => dispatch(generateSatelliteVectorAnalysisMap({
      index: dataLayer.satelliteIndex,
      satelliteImageUuids: [dataLayer.satelliteImageUuid],
      ...settings,
    }));
  } else if (assetType === AssetType.soilDataset) {
    result = () => dispatch(generateSoilVectorAnalysisMap({
      soilAttribute: dataLayer.datasetAttribute,
      soilDatasetUuid: dataLayer.datasetUuid,
      ...settings,
    }));
  } else if (assetType === AssetType.yieldDataset) {
    result = () => dispatch(generateYieldVectorAnalysisMap({
      yieldAttribute: dataLayer.datasetAttribute,
      yieldDatasetUuid: dataLayer.datasetUuid,
      ...settings,
    }));
  } else if (assetType === AssetType.asAppliedDataset) {
    result = () => dispatch(generateAsAppliedVectorAnalysisMap({
      asAppliedAttribute: dataLayer.datasetAttribute,
      asAppliedDatasetUuid: dataLayer.datasetUuid,
      ...settings,
    }));
  } else if (assetType === AssetType.topographyMap) {
    result = () => dispatch(generateTopographyVectorAnalysisMap({
      topographyAttribute: dataLayer.datasetAttribute,
      topographyMapUuid: dataLayer.datasetUuid,
      ...settings,
    }));
  } else {
    throw new CustomError(`[Create Single Layer Analysis] Incorrect asset type ${assetType}`);
  }

  return result;
}

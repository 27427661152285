import { createSlice } from '@reduxjs/toolkit';

import {
  getCopyName,
  getSatelliteImagesZonesMapName,
} from '../../../helpers';
import { RVI_INDEX } from '../../../helpers/satellite';
import { selectSatelliteImages } from '../../field/fieldSelectors';
import {
  selectChanged,
  selectCloned,
  selectSelectedUuids,
  selectSettings,
} from './createStabilityZonesSelectors';
import { DEFAULT_ZONES_CREATION_SETTINGS } from '../../createAnalysis/helpers/constants/vamap';

const initialState = {
  _cloned: false,
  _changed: false,
  selectedUuids: [],
  activeUuid: '',
  settings: {
    ...DEFAULT_ZONES_CREATION_SETTINGS,
    useInterpolatedData: true,
  },
};

export const navigateToSettings = () => (dispatch, getState) => {
  const state = getState();
  const changed = selectChanged(state);
  const cloned = selectCloned(state);
  let { name } = selectSettings(state);

  if (changed) {
    const selectedUuids = selectSelectedUuids(state);
    const satelliteImages = selectSatelliteImages(state).filter((img) => {
      return selectedUuids.includes(img.uuid);
    });
    const zonesMapName = getSatelliteImagesZonesMapName(satelliteImages, RVI_INDEX.value);

    name = getCopyName(zonesMapName, cloned);
  }

  dispatch(goToSettings({
    name,
    changed,
  }));
};

export const createStabilityZonesSlice = createSlice({
  name: 'createStabilityZones',
  initialState,
  reducers: {
    goToSettings(state, action) {
      state._changed = false;
      state.settings.name = action.payload.name;
    },
    reset() {
      return initialState;
    },
    activateImage(state, action) {
      state.activeUuid = action.payload;
    },
    setImagesSelection(state, action) {
      state._changed = true;
      state.selectedUuids = action.payload;
    },
    selectImages(state, action) {
      state._changed = true;
      state.selectedUuids = [
        ...state.selectedUuids,
        ...action.payload,
      ];
    },
    unselectImages(state, action) {
      state._changed = true;
      state.selectedUuids = state.selectedUuids.filter((uuid) => {
        return !action.payload.includes(uuid);
      });
    },
    updateSettings(state, action) {
      state.settings[action.payload.prop] = action.payload.value;
    },
    cloneWorkflow(state, action) {
      state._cloned = true;
      state.settings = {
        ...state.settings,
        ...action.payload.settings,
      };
      state.selectedUuids = action.payload.imageUuids;
    },
  },
});

export default createStabilityZonesSlice.reducer;

export const {
  goToSettings,
  reset,
  activateImage,
  setImagesSelection,
  selectImages,
  unselectImages,
  updateSettings,
  cloneWorkflow,
} = createStabilityZonesSlice.actions;

import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function ExportIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M15 10.5V16.5H9V10.5H5L12 3.5L19 10.5H15ZM19 20.5V18.5H5V20.5H19Z" />
    </SvgIcon>
  );
}

import { createSelector } from '@reduxjs/toolkit';
import { selectVectorAnalysisMaps } from '../../field/fieldSelectors';

export const selectSelectedVamapUuid = ({ cloneZonesMap }) => cloneZonesMap.selectedVamapUuid;

export const selectSelectedZonesMap = createSelector(
  selectSelectedVamapUuid,
  selectVectorAnalysisMaps,
  (selectedUuid, vamaps) => vamaps.find(({ uuid }) => {
    return uuid === selectedUuid;
  }),
);

export const selectLegendOpen = ({ cloneZonesMap }) => cloneZonesMap.legend.open;

export const selectWorkflow = ({ cloneZonesMap }) => cloneZonesMap.workflow;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectEmail } from '../../../../user/userSelectors';
import ReportIssuePopupComponent from '../../components/ReportIssuePopup';
import { sendUserReport } from '../../helpers/functions/sentry';

export default function ReportIssuePopup({
  onCancel = () => {},
  onConfirm = () => {},
}: {
  eventId?: string,
  onCancel: () => void,
  onConfirm: () => void,
}) {
  const userEmail = useSelector(selectEmail);
  const [name, setName] = useState('');
  const [email, setEmail] = useState(userEmail);
  const [comment, setComment] = useState('');
  const [files, setFiles] = useState<File[]>([]);

  const handleFilesChange = (newFiles: File[]) => {
    setFiles([
      ...files,
      ...newFiles,
    ]);
  };

  const handleConfirm = () => {
    sendUserReport({
      name,
      email,
      comment,
      files,
    });
    onConfirm();
  };

  const handleFileRemoved = (fileIndex: number) => {
    const newFiles = files.filter((_f, index) => {
      return index !== fileIndex;
    });

    setFiles(newFiles);
  };

  return (
    <ReportIssuePopupComponent
      name={name}
      email={email}
      comment={comment}
      files={files}
      onNameChange={setName}
      onEmailChange={setEmail}
      onCommentChange={setComment}
      onFilesChanged={handleFilesChange}
      onFileRemoved={handleFileRemoved}
      onCancel={onCancel}
      onConfirm={handleConfirm}
    />
  );
}

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../app/store/helpers/types';
import { isInUnsupportedRegion } from './helpers/functions/field';
import { isPlanetImage } from '../../helpers/functions/entities/satelliteImage';
import { isLoading } from '../../helpers/functions/redux/loading';
import { AssetGroupType, AssetType } from '../../helpers/constants/entities/asset';

export const selectNotFound = ({ field }: RootState) => field.notFound;

export const selectAllLoading = ({ field }: RootState) => isLoading(field.loading.all);

export const selectSatelliteLoading = ({ field }: RootState) => {
  return isLoading(field.loading[AssetGroupType.satelliteImages]);
};

export const selectZonesMapsLoading = ({ field }: RootState) => {
  return isLoading(field.loading[AssetGroupType.vectorAnalysisMaps]);
};

export const selectCategoryLoading = ({ field }: RootState, category: AssetGroupType) => {
  return isLoading(field.loading[category]);
};

export const selectAttributesLoading = ({ field }: RootState) => {
  return isLoading(field.loading.attributes);
};

export const selectIsSomethingLoading = ({ field }: RootState) => {
  const {
    all,
    [AssetGroupType.satelliteImages]: satelliteImages,
    [AssetGroupType.vectorAnalysisMaps]: zonesMaps,
    [AssetGroupType.soilDatasets]: soilDatasets,
    [AssetGroupType.yieldDatasets]: yieldDatasets,
    [AssetGroupType.asAppliedDatasets]: asAppliedDatasets,
    [AssetGroupType.topographyMaps]: topographyMaps,
    attributes,
  } = field.loading;

  return isLoading(all)
  || isLoading(satelliteImages)
  || isLoading(zonesMaps)
  || isLoading(soilDatasets)
  || isLoading(yieldDatasets)
  || isLoading(asAppliedDatasets)
  || isLoading(topographyMaps)
  || isLoading(attributes);
};

export const selectFullyLoaded = ({ field }: RootState) => field.fullyLoaded;

export const selectIsFieldAndSomethingLoaded = ({ field }: RootState) => {
  const {
    metadata,
    [AssetGroupType.satelliteImages]: satelliteImages,
    [AssetGroupType.vectorAnalysisMaps]: zonesMaps,
    [AssetGroupType.soilDatasets]: soilDatasets,
    [AssetGroupType.yieldDatasets]: yieldDatasets,
    [AssetGroupType.asAppliedDatasets]: asAppliedDatasets,
    [AssetGroupType.topographyMaps]: topographyMaps,
    [AssetGroupType.pinsGroups]: pinsGroups,
  } = field.fullyLoaded;

  return metadata
    && (
      satelliteImages
      || zonesMaps
      || soilDatasets
      || yieldDatasets
      || asAppliedDatasets
      || topographyMaps
      || pinsGroups
    );
};

export const selectIsDatasetsLoaded = ({ field }: RootState) => {
  const {
    [AssetGroupType.soilDatasets]: soilDatasets,
    [AssetGroupType.yieldDatasets]: yieldDatasets,
    [AssetGroupType.asAppliedDatasets]: asAppliedDatasets,
    [AssetGroupType.topographyMaps]: topographyMaps,
  } = field.fullyLoaded;

  return soilDatasets
    && yieldDatasets
    && asAppliedDatasets
    && topographyMaps;
};

export const selectIsDatasetsSatellitesLoaded = createSelector(
  selectIsDatasetsLoaded,
  ({ field }: RootState) => {
    const {
      [AssetGroupType.satelliteImages]: satelliteImages,
    } = field.fullyLoaded;

    return satelliteImages;
  },
  (datasetsLoaded, satellitesLoaded) => datasetsLoaded && satellitesLoaded,
);

export const selectIsFieldVamapsDatasetsPinsGroupsLoaded = ({ field }: RootState) => {
  const {
    metadata,
    [AssetGroupType.vectorAnalysisMaps]: zonesMaps,
    [AssetGroupType.soilDatasets]: soilDatasets,
    [AssetGroupType.yieldDatasets]: yieldDatasets,
    [AssetGroupType.asAppliedDatasets]: asAppliedDatasets,
    [AssetGroupType.topographyMaps]: topographyMaps,
    [AssetGroupType.pinsGroups]: pinsGroups,
  } = field.fullyLoaded;

  return metadata
    && zonesMaps
    && soilDatasets
    && yieldDatasets
    && asAppliedDatasets
    && topographyMaps
    && pinsGroups;
};

export const selectIsAllAssetsLoaded = (state: RootState) => {
  return selectIsFieldVamapsDatasetsPinsGroupsLoaded(state)
    && state.field.fullyLoaded[AssetGroupType.satelliteImages];
};

export const selectIsFieldAndSatellitesLoaded = ({ field }: RootState) => {
  const {
    metadata,
    [AssetGroupType.satelliteImages]: satelliteImages,
  } = field.fullyLoaded;

  return metadata
    && satelliteImages;
};

export const selectIsFieldAndZonesMapsLoaded = ({ field }: RootState) => {
  const {
    metadata,
    [AssetGroupType.vectorAnalysisMaps]: zonesMaps,
  } = field.fullyLoaded;

  return metadata
    && zonesMaps;
};

export const selectIsFieldLoaded = ({ field }: RootState) => field.fullyLoaded.metadata;

export const selectIsSatelliteImagesLoaded = ({ field }: RootState) => {
  return field.fullyLoaded[AssetGroupType.satelliteImages];
};

export const selectIsZonesMapsLoaded = ({ field }: RootState) => {
  return field.fullyLoaded[AssetGroupType.vectorAnalysisMaps];
};

export const selectIsCategoryLoaded = ({ field }: RootState, category: AssetGroupType) => {
  return field.fullyLoaded[category];
};

export const selectField = ({ field }: RootState) => field.field;

export const selectName = ({ field }: RootState) => field.field?.name;

export const selectFieldUuid = ({ field }: RootState) => field.field?.uuid;

export const selectFarmUuid = ({ field }: RootState) => field.field?.farmUuid;

export const selectBoundingBox = ({ field }: RootState) => field.field?.boundingBox || [];

export const selectArea = ({ field }: RootState) => field.field?.area;

export const selectIsInUnsupportedRegion = ({ field }: RootState) => isInUnsupportedRegion(field.field);

export const selectSatelliteImages = ({ field }: RootState) => field.field?.satelliteImages || [];

export const selectCreateAnalysisSatelliteImages = createSelector(
  selectSatelliteImages,
  (images) => images.filter((image) => {
    return !isPlanetImage(image) || image.ndviAverage != null;
  }),
);

export const selectSatelliteImage = createSelector(
  selectSatelliteImages,
  (_: RootState, uuid: string) => uuid,
  (images, uuid) => images.find((image) => image.uuid === uuid),
);

export const selectVectorAnalysisMaps = createSelector(
  ({ field }: RootState) => field.field?.vectorAnalysisMaps,
  (vectorAnalysisMaps) => vectorAnalysisMaps || [],
);

export const selectVectorAnalysisMap = createSelector(
  selectVectorAnalysisMaps,
  (_: RootState, uuid: string) => uuid,
  (vamaps, uuid) => vamaps.find((vamap) => vamap.uuid === uuid),
);

export const selectSoilDatasets = ({ field }: RootState) => field.field?.soilDatasets || [];

export const selectYieldDatasets = ({ field }: RootState) => field.field?.yieldDatasets || [];

export const selectYieldDataset = createSelector(
  selectYieldDatasets,
  (_: RootState, uuid: string | null) => uuid,
  (datasets, uuid) => datasets.find((dataset) => dataset.uuid === uuid),
);

export const selectAsAppliedDatasets = ({ field }: RootState) => field.field?.asAppliedDatasets || [];

export const selectTopographyMaps = ({ field }: RootState) => field.field?.topographyMaps || [];

export const selectPinsGroups = ({ field }: RootState) => field.field?.pinsGroups || [];

export const selectBoundaryUrl = ({ field }: RootState) => field.field?.boundaryUrl;

export const selectFieldFeatureEdit = ({ field }: RootState) => field.feature.edit;

export const selectFieldFeatureRecord = ({ field }: RootState) => field.feature.record;

export const selectFieldFeatureSaving = ({ field }: RootState) => field.feature.saving;

export const selectLabels = ({ field }: RootState) => field.field?.labels;

export const selectEquationMaps = ({ field }: RootState) => field.field?.equationMaps || [];

export const selectJDField = ({ field }: RootState) => field.jdField;

export const selectEquationMap = createSelector(
  selectEquationMaps,
  (_: RootState, uuid: string) => uuid,
  (equationMaps, uuid) => equationMaps.find((equationMap) => equationMap.uuid === uuid),
);

export const selectThreeDimensionalMaps = createSelector(
  ({ field }: RootState) => field.field?.threeDimensionalMaps,
  (threeDimensionalMaps) => threeDimensionalMaps || [],
);

export const selectThreeDimensionalMap = createSelector(
  selectThreeDimensionalMaps,
  (_: RootState, uuid: string) => uuid,
  (maps3d, uuid) => maps3d.find((map3d) => map3d.uuid === uuid),
);

export const selectThreeDimensionalMapAttribute = createSelector(
  selectThreeDimensionalMap,
  (map3d) => {
    const dataSourceLayer = map3d?.dataSourceLayer;

    return dataSourceLayer?.soilAttribute
    || dataSourceLayer?.yieldAttribute
    || dataSourceLayer?.asAppliedAttribute
    || dataSourceLayer?.topographyAttribute;
  },
);

export const selectThreeDimensionalMapDataset = createSelector(
  selectThreeDimensionalMap,
  (map3d) => {
    const dataSourceLayer = map3d?.dataSourceLayer;

    return dataSourceLayer?.soilDataset
    || dataSourceLayer?.yieldDataset
    || dataSourceLayer?.asAppliedDataset
    || dataSourceLayer?.topographyMap;
  },
);

export const selectIsFieldHasNoAssets = createSelector(
  selectIsAllAssetsLoaded,
  selectSatelliteImages,
  selectSoilDatasets,
  selectYieldDatasets,
  selectAsAppliedDatasets,
  selectTopographyMaps,
  (
    isFieldLoaded,
    satelliteImages,
    soilDatasets,
    yieldDatasets,
    asAppliedDatasets,
    topographyMaps,
  ) => (
    isFieldLoaded
        && satelliteImages.length === 0
        && soilDatasets.length === 0
        && yieldDatasets.length === 0
        && asAppliedDatasets.length === 0
        && topographyMaps.length === 0
  ),
);

export const selectAssetsByType = createSelector(
  selectSatelliteImages,
  selectSoilDatasets,
  selectYieldDatasets,
  selectAsAppliedDatasets,
  selectTopographyMaps,
  (_: RootState, type: AssetType) => type,
  (
    satelliteImages,
    soilDatasets,
    yieldDatasets,
    asAppliedDatasets,
    topographyMaps,
    type,
  ) => {
    let result;

    if (type === AssetType.satelliteImage) {
      result = satelliteImages;
    } else if (type === AssetType.soilDataset) {
      result = soilDatasets;
    } else if (type === AssetType.yieldDataset) {
      result = yieldDatasets;
    } else if (type === AssetType.asAppliedDataset) {
      result = asAppliedDatasets;
    } else if (type === AssetType.topographyMap) {
      result = topographyMaps;
    }

    return result;
  },
);

export const selectAssets = createSelector(
  selectSatelliteImages,
  selectSoilDatasets,
  selectYieldDatasets,
  selectAsAppliedDatasets,
  selectTopographyMaps,
  selectEquationMaps,
  selectThreeDimensionalMaps,
  (
    satelliteImages,
    soilDatasets,
    yieldDatasets,
    asAppliedDatasets,
    topographyMaps,
    equationMaps,
    threeDimensionalMaps,
  ) => ({
    satelliteImages,
    soilDatasets,
    yieldDatasets,
    asAppliedDatasets,
    topographyMaps,
    equationMaps,
    threeDimensionalMaps,
  }),
);

export const selectBackgroundLayerAssets = createSelector(
  selectSatelliteImages,
  selectSoilDatasets,
  selectYieldDatasets,
  selectAsAppliedDatasets,
  selectTopographyMaps,
  selectEquationMaps,
  (
    satelliteImages,
    soilDatasets,
    yieldDatasets,
    asAppliedDatasets,
    topographyMaps,
    equationMaps,
  ) => ({
    satelliteImages,
    soilDatasets,
    yieldDatasets,
    asAppliedDatasets,
    topographyMaps,
    equationMaps,
  }),
);

export const selectCreateAnalysisAssets = createSelector(
  selectCreateAnalysisSatelliteImages,
  selectSoilDatasets,
  selectYieldDatasets,
  selectAsAppliedDatasets,
  selectTopographyMaps,
  (satelliteImages, soilDatasets, yieldDatasets, asAppliedDatasets, topographyMaps) => ({
    satelliteImages,
    soilDatasets,
    yieldDatasets,
    asAppliedDatasets,
    topographyMaps,
  }),
);

export const selectItemPins = ({ field }: RootState, itemUuid: string) => {
  const currentField = field?.field;
  const uuid = itemUuid || currentField?.uuid;
  const pinsGroups = currentField?.pinsGroups;

  if (!pinsGroups || !pinsGroups.length) {
    return [];
  }

  const pinsGroup = pinsGroups.find((pg) => {
    return pg.uuid === uuid;
  });

  return pinsGroup?.pins || [];
};

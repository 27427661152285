import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import { isNewEquation } from '../../../../equations/helpers/functions/equations';

import './index.scss';

export default function EquationTitle({
  uuid,
  equationTitle,
  editMode,
}: {
  uuid: string,
  equationTitle: string,
  editMode: boolean,
}) {
  const { t } = useTranslation();

  const title = useMemo(() => {
    const newEquation = isNewEquation(uuid);
    let result: string;

    if (editMode) {
      if (newEquation) {
        result = t('batch-analytics.steps.equation.create-new-equation');
      } else {
        result = t('batch-analytics.steps.equation.adjust-equation-title', {
          title: equationTitle,
        });
      }
    } else if (newEquation) {
      result = t('batch-analytics.steps.equation.unsaved-preset');
    } else {
      result = equationTitle;
    }

    return result;
  }, [editMode, equationTitle, uuid, t]);

  return (
    <Typography
      className="equation-title"
      variant="h6"
    >
      {title}
    </Typography>
  );
}

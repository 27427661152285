import React from 'react';

import {
  isSatelliteVectorAnalysis,
  isSoilVectorAnalysis,
  isYieldVectorAnalysis,
  isAsAppliedVectorAnalysis,
  isTopographyVectorAnalysis,
  isMultiLayerVectorAnalysis,
  isZonesOperationsIntersectVectorAnalysis,
  isDrawnManuallyVectorAnalysis,
  isEquationMap,
  is3dMap,
} from '../../../helpers/functions/entities/assets';
import {
  prepareSatelliteZonesMapLegendGroup,
  prepareSoilZonesMapLegendGroup,
  prepareYieldZonesMapLegendGroup,
  prepareAsAppliedZonesMapLegendGroup,
  prepareTopographyZonesMapLegendGroup,
  prepareMultiLayerZonesMapLegendGroup,
  prepareZonesOperationsIntersectZonesMapLegendGroup,
  prepareDrawnManuallyZoneMapLegendGroup,
  prepareEquationMapLegendGroup,
  prepare3DMapLegendGroup,
} from '../../../helpers/components/legend';
import SatelliteZonesMapLegendGroup from '../Groups/SatelliteZonesMap';
import DatasetZonesMapLegendGroup from '../Groups/DatasetZonesMap';
import MultilayerZonesMapLegendGroup from '../Groups/MultilayerZonesMap';
import ZonesIntersectionMapLegendGroup from '../Groups/ZonesIntersectionMap';
import DrawnManuallyZonesMapLegendGroup from '../Groups/DrawManuallyMap';
import EquationMapLegendGroup from '../Groups/EquationMap';
import Map3DLegendGroup from '../Groups/Map3D';

const AssetLegendContent = ({
  item,
  field,
  areaUnit,
  apiKey,
}) => {
  if (isSatelliteVectorAnalysis(item)) {
    const legendGroup = prepareSatelliteZonesMapLegendGroup(
      item,
      field,
      areaUnit,
    );

    return (
      <SatelliteZonesMapLegendGroup {...legendGroup} />
    );
  }

  if (isSoilVectorAnalysis(item)) {
    const legendGroup = prepareSoilZonesMapLegendGroup(
      item,
      field,
      areaUnit,
    );

    return (
      <DatasetZonesMapLegendGroup {...legendGroup} />
    );
  }

  if (isYieldVectorAnalysis(item)) {
    const legendGroup = prepareYieldZonesMapLegendGroup(
      item,
      field,
      areaUnit,
    );

    return (
      <DatasetZonesMapLegendGroup {...legendGroup} />
    );
  }

  if (isAsAppliedVectorAnalysis(item)) {
    const legendGroup = prepareAsAppliedZonesMapLegendGroup(
      item,
      field,
      areaUnit,
    );

    return (
      <DatasetZonesMapLegendGroup {...legendGroup} />
    );
  }

  if (isTopographyVectorAnalysis(item)) {
    const legendGroup = prepareTopographyZonesMapLegendGroup(
      item,
      field,
      areaUnit,
    );

    return (
      <DatasetZonesMapLegendGroup {...legendGroup} />
    );
  }

  if (isMultiLayerVectorAnalysis(item)) {
    const legendGroup = prepareMultiLayerZonesMapLegendGroup(item, field, areaUnit, apiKey);

    return (
      <MultilayerZonesMapLegendGroup {...legendGroup} />
    );
  }

  if (isZonesOperationsIntersectVectorAnalysis(item)) {
    const legendGroup = prepareZonesOperationsIntersectZonesMapLegendGroup(item, field, areaUnit);

    return (
      <ZonesIntersectionMapLegendGroup {...legendGroup} />
    );
  }

  if (isDrawnManuallyVectorAnalysis(item)) {
    const legendGroup = prepareDrawnManuallyZoneMapLegendGroup(item, field, areaUnit);

    return (
      <DrawnManuallyZonesMapLegendGroup {...legendGroup} />
    );
  }

  if (isEquationMap(item)) {
    const legendGroup = prepareEquationMapLegendGroup(item, field, areaUnit, apiKey);

    return (
      <EquationMapLegendGroup {...legendGroup} />
    );
  }

  if (is3dMap(item)) {
    const legendGroup = prepare3DMapLegendGroup(item, areaUnit);

    return (
      <Map3DLegendGroup {...legendGroup} />
    );
  }

  return (
    <div>
      <p>Legend group not implemented for that type of information.</p>
      <code>{JSON.stringify(item, null, 2)}</code>
    </div>
  );
};

export default AssetLegendContent;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';

import Table from '../../../../../tableView/components/Table';
import {
  getRowsSelection,
} from '../../../../../tableView/helpers/functions/rows';
import { filterByKey } from '../../../../../../../helpers';
import { openPopup } from '../../../../../popups/popupsSlice';
import {
  selectSelectionMode,
  selectSelectedFarms,
  selectOwnFarmsQuery,
} from '../../../../userFarmsSelectors';
import { ACTIONS } from '../../../../helpers/constants/ui';
import { getOwnFarmsTableRows } from '../../../../helpers/functions/tableRows';
import {
  useDeleteFarmMutation,
  useGetFarmsQuery,
  useRenameFarmMutation,
} from '../../../../../../farms/farmsAPI';
import { selectUserAdminOrAdvisor } from '../../../../../../user/userSelectors';
import { selectFarms, toggleFarmSelect } from '../../../../userFarmsSlice';
import RenameIcon from '../../../../../../../components/Icons/rename';
import { POPUPS } from '../../../../../popups/helpers/constants/popups';

import './index.scss';

const prepareMenuItems = (t) => [
  {
    id: 'rename',
    label: t('general.controls.rename'),
    IconComponent: RenameIcon,
  },
  {
    id: 'delete',
    label: t('general.controls.delete'),
    IconComponent: DeleteIcon,
  },
];

const getTableColumns = ({
  t,
  selectionMode,
  rowsSelection,
  onHeadCheckboxClick,
  onFarmMenuItemClick,
  onRowCheckboxClick,
  onActionClick,
}) => {
  const baseCells = [
    {
      id: 'name',
      label: t('user-farms.own-farms.table.farm'),
      element: 'default',
      classes: [
        'shared-farms-table__name-cell',
      ],
    },
    {
      id: 'users',
      label: t('user-farms.own-farms.table.shared-with-user'),
      element: 'default',
    },
    {
      id: 'actions',
      element: 'actions',
      label: t('user-farms.own-farms.table.actions'),
      onActionClick,
    },
    {
      id: 'menu',
      label: '',
      element: 'menu',
      horizontalAlign: 'right',
      menuItems: prepareMenuItems(t),
      onMenuItemClick: onFarmMenuItemClick,
    },
  ];

  return selectionMode
    ? [
      {
        id: 'rowSelect',
        labelElement: 'checkbox',
        handler: onHeadCheckboxClick,
        value: rowsSelection,
        element: 'checkbox',
        onCheckboxClick: onRowCheckboxClick,
      },
      ...baseCells,
    ]
    : baseCells;
};

const UserFarmsOwnFarmsPanel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedFarmsUuids = useSelector(selectSelectedFarms);
  const selectionMode = useSelector(selectSelectionMode);
  const filterQuery = useSelector(selectOwnFarmsQuery);
  const isAdminOrAdvisor = useSelector(selectUserAdminOrAdvisor);
  const {
    data: farms = [],
  } = useGetFarmsQuery();
  const [renameFarm] = useRenameFarmMutation();
  const [deleteFarm] = useDeleteFarmMutation();
  const tableRows = getOwnFarmsTableRows(farms, selectedFarmsUuids);
  const rowsSelection = getRowsSelection(tableRows);

  const onFarmMenuItemClick = ({ id }, row) => {
    if (id === 'delete') {
      dispatch(openPopup({
        type: POPUPS.deleteFarm,
        entityName: row.name,
        onConfirm: async () => {
          await deleteFarm({
            farmUuid: row.id,
          });
        },
      }));
    } else if (id === 'rename') {
      dispatch(openPopup({
        type: 'rename-farm',
        entityName: row.name,
        onConfirm: async (name) => {
          await renameFarm({
            farmUuid: row.id,
            name,
          });
        },
      }));
    }
  };

  const onActionClick = (action, row) => {
    if (action === ACTIONS.share) {
      if (isAdminOrAdvisor) {
        dispatch(openPopup({
          type: 'share-farms',
          farmUuids: [row.id],
        }));
      } else {
        dispatch(openPopup({
          type: 'share-farms-external',
          farmUuids: [row.id],
        }));
      }
    } else if (action === ACTIONS.stopSharing) {
      dispatch(openPopup({
        type: 'stop-farm-share',
        farmUuid: row.id,
        farmName: row.name,
      }));
    }
  };

  const onRowCheckboxClick = (row) => {
    dispatch(toggleFarmSelect(row.id));
  };

  const onHeadCheckboxClick = (checked) => {
    let farmToSelect = [];

    if (checked) {
      farmToSelect = farms.map(({ uuid }) => uuid);
    }

    dispatch(selectFarms(farmToSelect));
  };

  return (
    <Table
      classes={{
        root: 'own-farms-table',
      }}
      columns={getTableColumns({
        t,
        selectionMode,
        rowsSelection,
        onHeadCheckboxClick,
        onFarmMenuItemClick,
        onRowCheckboxClick,
        onActionClick,
      })}
      rows={filterByKey('name', tableRows, filterQuery)}
      showPagination={false}
    />
  );
};

export default UserFarmsOwnFarmsPanel;

import React from 'react';

import './index.scss';

const DrawManuallyPanel = (
  _props: unknown,
  ref: React.Ref<HTMLDivElement>,
) => {
  return (
    <div className="draw-manually-panel" ref={ref}></div>
  );
};

export default React.forwardRef(DrawManuallyPanel);

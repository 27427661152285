import React from 'react';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';

import './index.scss';

export default function Label({
  text = '',
  deletable = false,
  classes,
  color = 'default',
  onDelete = () => {},
  ...props
}: {
  text: string,
  deletable?: boolean,
  classes?: {
    label: string,
  },
  color?: 'default' | 'primary',
  onDelete?: () => void,
}) {
  return (
    <div
      {...props}
      className={clsx('label', classes?.label, {
        label_primary: color === 'primary',
      })}
    >
      <span className="label__text" title={text}>{text}</span>
      {
        deletable
          && (
            <CloseIcon
              fontSize="inherit"
              className="label__icon"
              onClick={onDelete}
            />
          )
      }
    </div>
  );
}

import {
  RVI_INDEX,
  SATELLITE_INDEXES,
} from '../../../../../helpers/satellite';

export enum VariableMenuAction {
  deleteVariable = 'delete-variable',
  changeVariable = 'change-variable',
}

export const EQUATION_BASED_ANALYSIS_SATELLITE_INDEXES = [
  ...SATELLITE_INDEXES,
  RVI_INDEX,
];

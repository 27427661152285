import React, { useMemo, ReactNode } from 'react';
import clsx from 'clsx';

import FilterSummary from '../FilterSummary';
import ResetFiltersButton from '../ResetFiltersButton';
import { getFilterValue } from '../../helpers/functions';
import { AssetGroupType } from '../../../../../helpers/constants/entities/asset';
import { VectorAnalysisFilters } from '../../types/analysis';
import { SatelliteFilters } from '../../types/satelliteImage';
import { PinsGroupsFilters } from '../../types/pinsGroups';
import { DefaultAssetGroupFilters } from '../../types/assetGroup';

import './index.scss';

const DEFAULT_FILTER_TYPE = 'defaultFilter';

type FiltersPanelType =
  AssetGroupType.satelliteImages |
  AssetGroupType.vectorAnalysisMaps |
  AssetGroupType.pinsGroups |
  typeof DEFAULT_FILTER_TYPE;

type FilterValue =
  SatelliteFilters |
  VectorAnalysisFilters |
  PinsGroupsFilters |
  DefaultAssetGroupFilters;

const FiltersPanel = ({
  type = DEFAULT_FILTER_TYPE,
  value,
  isExpanded,
  classes = {},
  children,
  onFiltersClear = () => {},
}: {
  value: FilterValue,
  type?: FiltersPanelType;
  isExpanded?: boolean;
  classes?: { root?: string };
  children?: ReactNode,
  onFiltersClear?: () => void;
}) => {
  const filterSummary = useMemo(() => {
    return getFilterValue({ [type]: value });
  }, [value, type]);
  const isFilterSet = useMemo(() => filterSummary.some((filter) => filter.value), [filterSummary]);

  return (
    <div className={clsx('filters-panel', classes.root, {
      'filters-panel-expanded': isExpanded,
    })}
    >
      {isExpanded
        ? (
          children
        )
        : (
          <>
            <FilterSummary
              filterSummary={filterSummary}
            />
            {isFilterSet && (
              <ResetFiltersButton classes={{ root: 'filters-panel__reset-button' }} onClick={onFiltersClear}/>
            )}
          </>
        )}
    </div>
  );
};

export default FiltersPanel;

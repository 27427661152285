import React from 'react';
import Typography from '@material-ui/core/Typography';

import './index.scss';

export enum OperationLabelType {
  johnDeere = 'john-deere',
}

const OPERATION_LABEL_TEXT_MAPPER = {
  [OperationLabelType.johnDeere]: 'John Deere',
};

export default function OperationLabel({
  type,
}: {
  type: OperationLabelType;
}) {
  return (
    <Typography variant="body2" className={`operation-label operation-label_${type}`}>
      {OPERATION_LABEL_TEXT_MAPPER[type]}
    </Typography>
  );
}

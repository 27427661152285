import { AssetType } from '../../../../../helpers/constants/entities/asset';

export const getIsValidSetup = (dataLayers = []) => {
  return dataLayers.length >= 1 && dataLayers.every((layer) => {
    const { type } = layer;

    if (type === AssetType.satelliteImage) {
      return layer.satelliteImageUuids.length > 0;
    }

    if (
      type === AssetType.soilDataset
      || type === AssetType.yieldDataset
      || type === AssetType.asAppliedDataset
      || type === AssetType.topographyMap
    ) {
      return layer.datasetUuid && layer.attribute;
    }

    return false;
  });
};

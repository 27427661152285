import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectFieldImportFromJohnDeere, selectFieldsImportFromJohnDeere } from '../../jdImportSlice';
import { fetchFieldsJohnDeereWithNewPage, fetchFieldsJohnDeereWithNewPageSize } from '../../../../jdFields/jdFieldsSlice';
import { openPopup } from '../../../popups/popupsSlice';
import { isValidSynchronizedFieldJohnDeereAccount } from '../../../../jdFields/helpers/functions';
import { getAreaUnitLabel } from '../../../../../helpers';
import {
  selectFieldsTotalCount,
  selectFieldsPage,
  selectFieldsPageSize,
  selectFieldsList,
  selectFieldsAvailable,
} from '../../../../jdFields/jdFieldsSelectors';
import {
  selectImportSelectedOrganizations,
  selectImportSelectedFields,
  selectImportIsSelectedAvailableFields,
} from '../../jdImportSelectors';
import Table from '../../../tableView/components/Table';
import { AreaUnit } from '../../../../user/helpers/constants/user';
import { formatDate } from '../../../../../helpers/functions/utils/date';

const getStatusesAndMessages = (t) => {
  return {
    statuses: {
      imported: {
        title: t('upload-data-john-deere.steps.selectData.statuses.imported'),
        style: 'light-green',
      },
      'not-imported': {
        title: t('upload-data-john-deere.steps.selectData.statuses.not-imported'),
        style: 'light-grey',
      },
      failed: {
        title: t('upload-data-john-deere.steps.selectData.statuses.failed'),
        style: 'light-red',
      },
    },
    statusesMessages: {
      imported: {
        title: t('upload-data-john-deere.steps.selectData.statuses-messages.imported'),
      },
      'not-imported': {
        title: '',
      },
      failed: {
        title: t('general.shared.see-details'),
      },
      'not-available-for-import': {
        title: t('upload-data-john-deere.steps.selectData.statuses-messages.not-available-for-import'),
      },
    },
  };
};

const getTableColumns = ({
  t,
  allRowsSelection,
  onHeadCheckboxClick,
  onRowCheckboxClick,
  onOpenOperationPopup,
}) => (
  [
    {
      id: 'rowSelect',
      labelElement: 'checkbox',
      handler: onHeadCheckboxClick,
      value: allRowsSelection,
      element: 'checkbox',
      verticalAlign: 'middle',
      onCheckboxClick: onRowCheckboxClick,
    },
    {
      id: 'name',
      label: t('upload-data-john-deere.steps.selectData.table.field'),
      element: 'default',
      verticalAlign: 'middle',
    },
    {
      id: 'orgName',
      label: t('upload-data-john-deere.steps.selectData.table.organization'),
      element: 'default',
      verticalAlign: 'middle',
    },
    {
      id: 'area',
      label: getAreaUnitLabel(AreaUnit.hectares),
      element: 'default',
      verticalAlign: 'middle',
      secondary: true,
    },
    {
      id: 'status',
      label: t('upload-data-john-deere.steps.selectData.table.status'),
      element: 'status',
      verticalAlign: 'middle',
    },
    {
      id: 'imported',
      label: t('upload-data-john-deere.steps.selectData.table.imported'),
      element: 'default',
      verticalAlign: 'middle',
    },
    {
      id: 'statusMessage',
      label: t('upload-data-john-deere.steps.selectData.table.import-details'),
      element: 'click-operation',
      verticalAlign: 'middle',
      onOpenPopup: onOpenOperationPopup,
    },
  ]
);

const transformFieldStatus = (statuses, field) => {
  if (field.synchronized && field.synchronized.status) {
    if (isValidSynchronizedFieldJohnDeereAccount(field.synchronized)) {
      return {
        value: statuses.imported.title,
        type: statuses.imported.style,
      };
    }

    return {
      value: statuses.failed.title,
      type: statuses.failed.style,
    };
  }

  return {
    value: statuses['not-imported'].title,
    type: statuses['not-imported'].style,
  };
};

const transformFieldStatusMessage = (statusesMessages, field) => {
  if (field.synchronized && field.synchronized.status) {
    if (isValidSynchronizedFieldJohnDeereAccount(field.synchronized)) {
      return {
        text: statusesMessages.imported.title,
        link: `/field/${field.synchronized.farmUuid}/${field.synchronized.fieldUuid}`,
        type: 'link',
      };
    }

    return {
      text: statusesMessages.failed.title,
      popup: {
        type: 'view-details-john-deere',
        tableRows: [
          {
            id: 1,
            rowType: 'default',
            name: field.name,
            message: field.synchronized.statusMessage,
          },
        ],
      },
      type: 'popup',
    };
  }

  if (!(field.area && field.area > 0)) {
    return {
      text: statusesMessages['not-available-for-import'].title,
      type: 'text',
    };
  }

  return {
    text: '',
    type: 'text',
  };
};

const getTableRows = ({
  t,
  organizations,
  selectedFields,
  fields,
  totalCount,
  page,
  pageSize,
}) => {
  const {
    statuses,
    statusesMessages,
  } = getStatusesAndMessages(t);
  const emptyField = {
    _disabled: true,
    status: transformFieldStatus(statuses, {}),
    statusMessage: transformFieldStatusMessage(statusesMessages, {}),
  };
  const getEmptyFields = (length, offset = 0) => {
    return Array.from(Array(length), (_e, i) => {
      return {
        id: i + offset,
        rowType: 'default',
        ...emptyField,
      };
    });
  };
  const firstOffset = (page - 1) * pageSize;
  const endOffset = firstOffset + fields.length;

  return [
    ...getEmptyFields(firstOffset),
    ...fields.map((field) => ({
      rowType: 'default',
      id: field.id,
      name: field.name,
      orgId: field.orgId,
      orgName: organizations.find((organization) => organization.id === field.orgId).name,
      area: field.area > 0 ? field.area.toFixed(2) : '',
      status: transformFieldStatus(statuses, field),
      statusMessage: transformFieldStatusMessage(statusesMessages, field),
      imported: field.synchronized?.updatedDate ? formatDate(field.synchronized.updatedDate) : '',
      _selected: !!selectedFields.find((selectedField) => selectedField.id === field.id),
      disabled: !field.area > 0,
    })),
    ...getEmptyFields(totalCount - endOffset, endOffset),
  ];
};

const FieldsJohnDeereTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const organizations = useSelector(selectImportSelectedOrganizations);
  const selectedFields = useSelector(selectImportSelectedFields);
  const fields = useSelector(selectFieldsList);
  const availableFields = useSelector(selectFieldsAvailable);
  const totalCount = useSelector(selectFieldsTotalCount);
  const page = useSelector(selectFieldsPage);
  const pageSize = useSelector(selectFieldsPageSize);
  const isSelectedAvailableFields = useSelector(selectImportIsSelectedAvailableFields);

  const rows = getTableRows({
    t,
    organizations,
    selectedFields,
    fields,
    totalCount,
    page,
    pageSize,
  });

  const onPageChange = (newPage) => {
    dispatch(fetchFieldsJohnDeereWithNewPage({ page: newPage + 1 }));
  };

  const onPageSizeChange = (newPageSize) => {
    dispatch(fetchFieldsJohnDeereWithNewPageSize({ pageSize: newPageSize }));
  };

  const onHeadCheckboxClick = (selected) => {
    dispatch(selectFieldsImportFromJohnDeere({
      selected,
      selectedFields: availableFields,
    }));
  };

  const onRowCheckboxClick = (row) => {
    const selectedField = availableFields.find((field) => field.id === row.id);
    if (selectedField) {
      dispatch(selectFieldImportFromJohnDeere({ field: selectedField }));
    }
  };

  const onOpenOperationPopup = (popup) => {
    if (popup) {
      dispatch(openPopup(popup));
    }
  };

  return (
    <Table
      columns={getTableColumns({
        t,
        allRowsSelection: isSelectedAvailableFields ? 2 : 0,
        onHeadCheckboxClick,
        onRowCheckboxClick,
        onOpenOperationPopup,
      })}
      rows={rows}
      showPagination
      hasNext={page * pageSize < totalCount}
      page={page - 1}
      pageSize={pageSize}
      totalCount={totalCount}
      getDisplayedRowsLabel={({ to, from, count }) => {
        return t('general.controls.pagination.displayed-rows-with-count', {
          to,
          from,
          count,
          entity: t('general.controls.pagination.entities.fields'),
        });
      }}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
    />
  );
};

export default FieldsJohnDeereTable;

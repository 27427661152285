import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function RenameIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M14 4H11V6H14V18H11V20H14H16H19V18H16V6H19V4H16H14ZM2 8H12V10V14V16H2V8ZM18 10V8H22V16H18V14V10Z"/>
    </SvgIcon>
  );
}

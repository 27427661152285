import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';

import { getCommonSteps } from '../../../zonesOps/helpers/functions/steps';
import Equation from '../../containers/Stepper/Steps/Equation';
import CreateFormulaAdjustment from '../../containers/Stepper/Steps/CreateFormulaAdjustment';
import Results from '../../containers/Stepper/Steps/Results';

const getFormulaAdjustmentStepDescription = (equationSelected) => {
  const i18nKey = equationSelected
    ? 'zones-ops.equation-based.steps.4.description.adjustment'
    : 'zones-ops.equation-based.steps.4.description.create-formula';

  return (
    <Trans i18nKey={i18nKey}>
      Text
      <a className="link" target="_blank" href="https://docs.python.org/3/tutorial/">link</a>
      text
    </Trans>
  );
};

export const getSteps = (equationSelected) => (withFieldSelection) => {
  let firstSteps = [];

  if (withFieldSelection) {
    firstSteps = getCommonSteps();
  }

  return [
    ...firstSteps,
    {
      name: i18n.t('zones-ops.equation-based.stepper.equation'),
      description: i18n.t('zones-ops.equation-based.steps.3.description'),
      id: 'equation',
      component: Equation,
    },
    {
      name: equationSelected
        ? i18n.t('zones-ops.equation-based.stepper.adjustment')
        : i18n.t('zones-ops.equation-based.stepper.create-formula'),
      description: getFormulaAdjustmentStepDescription(equationSelected),
      id: 'createFormulaAdjustment',
      component: CreateFormulaAdjustment,
    },
    {
      name: i18n.t('zones-ops.equation-based.stepper.result'),
      description: i18n.t('zones-ops.equation-based.steps.5.description'),
      id: 'results',
      component: Results,
    },
  ];
};

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Select, { SelectOption } from '../../../../../../../components/Select';
import { DataLayersListContext } from '../../../../hooks/useDataLayersList';
import { TransformedDataset } from '../../../../../../../helpers/types/dataset';
import {
  extractDatasetGeoMapAttributes,
  getDatasetAttributeNameHandler,
} from '../../../../../../../helpers/functions/entities/dataset';

import './index.scss';

export default function CreateAnalysisDatasetsPanel({
  selectedAttribute,
  onAttributeChange,
}: {
  selectedAttribute: string,
  onAttributeChange: (attribute: string | null) => void,
}) {
  const { t } = useTranslation();

  const context = useContext(DataLayersListContext);
  const datasets = context.assets as TransformedDataset[];
  const { selectedUuid } = context;
  const selectedDataset = datasets.find((dataset) => dataset.uuid === selectedUuid);
  let options: SelectOption<string>[] = [];

  if (selectedDataset) {
    const attributes = extractDatasetGeoMapAttributes(selectedDataset);
    const nameHandler = getDatasetAttributeNameHandler(selectedDataset.fullAttributes);
    options = attributes.map((attribute) => {
      return {
        value: attribute,
        title: nameHandler(attribute),
      };
    });
  }

  return (
    <div className="create-analysis-datasets-panel">
      <Select
        id="dataset-attribute-selector"
        required
        disabled={!selectedDataset}
        labelPosition="left"
        label={t('zones-ops.multi-layer.steps.4.settings-table.attribute')}
        value={selectedAttribute}
        options={options}
        onChange={onAttributeChange}
      />
    </div>
  );
}

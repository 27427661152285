import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import LogoHugeIcon from '../Icons/logoHuge.svg';
import Button from '../../../../../components/Button';
import { PAGES_ROOTS, getRootLink } from '../../../../../helpers/navigation';

import './index.scss';

export default function WelcomePanel() {
  const { t } = useTranslation();

  return (
    <div className="welcome-panel__wrapper">
      <div className="welcome-panel__bg-image"></div>
      <div className="welcome-panel">
        <LogoHugeIcon />

        <div>
          <Typography className="welcome-panel__title">
            {t('welcome-screen.paid.title')}
          </Typography>
          <Typography className="welcome-panel__info">
            {t('welcome-screen.paid.info')}
          </Typography>
        </div>

        <div>
          <Typography className="welcome-panel__subtitle">
            {t('welcome-screen.next.title')}
          </Typography>
          <Typography className="welcome-panel__info">
            {t('welcome-screen.next.info')}
          </Typography>

          <div className="welcome-panel__buttons">
            <Button
              className="welcome-panel__button"
              variant="contained"
              color="primary"
              component={Link}
              to={getRootLink(PAGES_ROOTS.createField)}
            >
              {t('welcome-screen.draw-field')}
            </Button>
            <Button
              className="welcome-panel__button"
              variant="contained"
              color="primary"
              component={Link}
              to={getRootLink(PAGES_ROOTS.upload)}
            >
              {t('welcome-screen.upload-fields')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default class InMemoryStorageService<T = any> {
  _items: { [id: string]: T } = {};

  addItem(id: string, item: T): void {
    this._items[id] = item;
  }

  removeItem(id: string): void {
    const {
      [id]: itemToRemove,
      ...otherItems
    } = this._items;

    this._items = otherItems;
  }

  getItem(id: string): T | undefined {
    return this._items[id];
  }
}

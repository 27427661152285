export const enrichFieldAssets = (field) => {
  const {
    vectorAnalysisMaps,
    pinsGroups,
    ...restField
  } = field;

  if (vectorAnalysisMaps) {
    restField.vectorAnalysisMaps = vectorAnalysisMaps.map((vamap) => {
      return {
        ...vamap,
        farmUuid: field.farmUuid,
      };
    });
  }

  if (pinsGroups) {
    restField.pinsGroups = pinsGroups.map((pinsGroup) => {
      return {
        ...pinsGroup,
        farmUuid: field.farmUuid,
      };
    });
  }

  return restField;
};

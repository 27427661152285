import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import AuthenticationRedirector from '../router/redirectors/Authentication';
import UserDataRedirector from '../router/redirectors/UserData';
import PricingRedirector from '../router/redirectors/Pricing';
import ToolsPanel from '../features/ui/downloadFiles/containers/ToolsPanel';
import Panel from '../features/ui/downloadFiles/containers/Panel';
import View from '../features/ui/downloadFiles/containers/View';
import Legend from '../features/ui/downloadFiles/containers/Legend';
import BackdropLoading from '../components/Loading/BackdropLoading';
import { reset } from '../features/exportData/exportDataSlice';
import { getDocumentTitle } from '../helpers';
import BaseTemplate from './BaseTemplate.jsx';
import { useGetFarmsQuery } from '../features/farms/farmsAPI';
import useDidMount from '../hooks/useDidMount';

const ExportData = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    isFetching: isFarmsFetching,
  } = useGetFarmsQuery();

  useDidMount(() => {
    document.title = getDocumentTitle(t('general.navigation.export-data'));

    return () => {
      dispatch(reset());
    };
  });

  return (
    <AuthenticationRedirector>
      <UserDataRedirector>
        <PricingRedirector>
          <BaseTemplate
            headerProps={{
              text: t('general.navigation.export-data'),
            }}
          >
            <ToolsPanel key="tools-panel" />
            <Panel key="panel" />
            <View key="map" />
            <Legend key="legend" />
            {
           isFarmsFetching && <BackdropLoading key="loading" />
          }
          </BaseTemplate>
        </PricingRedirector>
      </UserDataRedirector>
    </AuthenticationRedirector>
  );
};

export default ExportData;

import { API, graphqlOperation } from '@aws-amplify/api';
import i18n from 'i18next';

import getFarmsFieldsDatasetsAnalysesQuery from './graphql/queries/getFarmsFieldsDatasetsAnalyses.gql';
import { AreaUnit } from '../user/helpers/constants/user';
import type {
  GetFarmsFieldsDatasetsResponse,
} from './types/api';
import type { TransformedFarm } from '../farms/types/farm';
import { transformFarms } from '../field/helpers/functions/assets';
import { CustomError, captureException } from '../../helpers/functions/utils/errorHandling';
import { TransformedField } from '../field/types/field';

export const fetchFarmsFieldsDatasetsAnalyses = async ({
  farmUuids,
  fieldUuids,
  areaUnit,
}: {
  farmUuids: string[],
  fieldUuids: string[],
  areaUnit: AreaUnit,
}) => {
  let result: (Omit<TransformedFarm, 'fields'> & {
    fields: Omit<TransformedField, 'satelliteImages'>[];
  })[];

  try {
    const response = await API.graphql(graphqlOperation(getFarmsFieldsDatasetsAnalysesQuery, {
      farmUuids,
      fieldUuids,
      areaUnit,
    })) as GetFarmsFieldsDatasetsResponse;

    result = transformFarms(response.data?.getFarms);
  } catch (error) {
    const typedError = error as GetFarmsFieldsDatasetsResponse;

    if (typedError.data) {
      result = transformFarms(typedError.data?.getFarms);
    } else {
      captureException({
        message: i18n.t('assets.notifications.fetch-farms-fields-datasets-error'),
        error: new CustomError('[Assets] fetchFarmsFieldsDatasets', {
          cause: error,
        }),
      });

      throw error;
    }
  }

  return result;
};

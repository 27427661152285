import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import FieldLegendContent from '../../../../../components/Legend/FieldLegendContent';
import BackdropLoading from '../../../../../components/Loading/BackdropLoading';
import useAllFieldAssets from '../../../../field/hooks/useAllFieldAssets';
import { selectSatelliteLoading } from '../../../../field/fieldSelectors';
import { selectAreaUnit, selectApiKey } from '../../../../user/userSelectors';
import { useGetFarmQuery } from '../../../../farms/farmsAPI';

import './index.scss';

const FieldDetailsPanel = () => {
  const {
    farmUuid,
    fieldUuid,
  } = useParams();
  const [chartScale, setChartScale] = useState();
  const areaUnit = useSelector(selectAreaUnit);
  const apiKey = useSelector(selectApiKey);
  const isSatellitesLoading = useSelector(selectSatelliteLoading);
  const field = useAllFieldAssets({
    farmUuid,
    fieldUuid,
  });
  const {
    data: farm,
  } = useGetFarmQuery({ farmUuid });

  return (
    <div className="field-details-panel">
      <FieldLegendContent
        farm={farm}
        satelliteImages={field.satelliteImages}
        field={field}
        apiKey={apiKey}
        areaUnit={areaUnit}
        chartScale={chartScale}
        onChartScaleChange={setChartScale}
      />
      {isSatellitesLoading && <BackdropLoading />}
    </div>
  );
};

export default FieldDetailsPanel;

import React from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '../../TextField';
import ComboBox from '../../ComboBox';
import Button from '../../Button';
import { ZonesOperationType } from '../../../helpers/constants/entities/vectorAnalysisMap';
import {
  MIN_POLYGON_AREA_MIN,
  MIN_POLYGON_AREA_MAX,
} from '../../../helpers/constants/app';

import './index.scss';

const Panel = ({
  name,
  polygonMinArea,
  operation,
  runAnalysisDisabled,
  onPropChange = () => {},
  onRunAnalysis = () => {},
}) => {
  const { t } = useTranslation();
  const operationOptions = [
    {
      title: t('zones-ops.cross-layer.steps.4.operations.intersection'),
      value: ZonesOperationType.intersect,
    },
  ];
  const option = operationOptions.find((opt) => {
    return opt.value === operation;
  });

  return (
    <div className="ops-operation-panel">
      <TextField
        value={name}
        className="ops-operation-panel__title"
        title={t('zones-ops.common.title')}
        onChange={(e) => onPropChange('name', e.target.value)}
      />
      <ComboBox
        classes={{
          root: 'ops-operation-panel__select',
        }}
        disableClearable
        disableCloseOnSelect={false}
        title={t('zones-ops.cross-layer.steps.4.operation')}
        options={operationOptions}
        value={option || operationOptions[0]}
        getOptionSelected={(opt, value) => {
          return opt.value === value.value;
        }}
        onChange={(_event, { value }) => onPropChange('operation', value)}
      />
      <TextField
        title={t('zones-ops.common.min-polygon-area')}
        type="number"
        value={polygonMinArea}
        onChange={(e) => onPropChange('polygonMinArea', parseInt(e.target.value, 10))}
        InputProps={{
          inputProps: {
            min: MIN_POLYGON_AREA_MIN,
            max: MIN_POLYGON_AREA_MAX,
          },
        }}
      />
      <Button
        disabled={runAnalysisDisabled}
        className="ops-operation-panel__run-btn"
        variant="contained"
        color="primary"
        onClick={onRunAnalysis}
      >
        {t('zones-ops.cross-layer.steps.4.execute-operation')}
      </Button>
    </div>
  );
};

export default Panel;

import React from 'react';

import GroupPlaceholderEmptyItem from '../../Items/GroupPlaceholderEmptyItem';
import GroupPlaceholderLoadingItem from '../../Items/GroupPlaceholderLoadingItem';
import { AssetGroupType } from '../../../../../../../helpers/constants/entities/asset';

export default function GroupPlaceholderNode({
  farmUuid,
  fieldUuid,
  assetGroupType,
  satelliteImagesLoading,
  withAction,
  onActionClick,
}: {
  farmUuid: string,
  fieldUuid: string,
  assetGroupType: AssetGroupType,
  satelliteImagesLoading: boolean,
  withAction: boolean,
  onActionClick: <T>(type: AssetGroupType, action?: T) => void,
}) {
  let result;

  if (
    assetGroupType === AssetGroupType.satelliteImages
      && satelliteImagesLoading
  ) {
    result = (
      <GroupPlaceholderLoadingItem assetGroupType={assetGroupType} />
    );
  } else {
    result = (
      <GroupPlaceholderEmptyItem
        farmUuid={farmUuid}
        fieldUuid={fieldUuid}
        assetGroupType={assetGroupType}
        withAction={withAction}
        onActionClick={onActionClick}
      />
    );
  }

  return result;
}

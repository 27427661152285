import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { successNotify } from '../../../notifications/helpers/functions/notify';
import ReportIssuePopup from '../containers/ReportIssuePopup';
import ErrorBoundaryFallback from '../components/ErrorBoundaryFallback';

export default function useErrorBoundaryFallback() {
  const { t } = useTranslation();

  const [showReportIssuePopup, setShowReportIssuePopup] = useState(false);

  const handleReportIssueClick = () => {
    setShowReportIssuePopup(true);
  };

  const handleReportIssuePopupCancel = () => {
    setShowReportIssuePopup(false);
  };

  const handleReportIssuePopupConfirm = () => {
    setShowReportIssuePopup(false);
    successNotify({
      message: t('general.notifications.report-issue-success'),
    });
  };

  return {
    fallback: (
      <ErrorBoundaryFallback
        onReportIssueClick={handleReportIssueClick}
      />
    ),
    reportIssuePopup: showReportIssuePopup
      ? (
        <ReportIssuePopup
          onCancel={handleReportIssuePopupCancel}
          onConfirm={handleReportIssuePopupConfirm}
        />
      )
      : null,
  };
}

import { useEffect, useCallback } from 'react';

export default function useDidMount(callback = () => {}) {
  const stableCallback = useCallback(callback, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const unsubscribeFn = stableCallback();

    return typeof unsubscribeFn === 'function' ? unsubscribeFn : () => {};
  }, [stableCallback]);
}

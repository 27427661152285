import { createSelector } from '@reduxjs/toolkit';

export const selectZonesMapsAsyncEntities = ({ smartSampling }) => smartSampling.zonesMapsAsync;

export const selectZonesMapsAsync = createSelector(
  selectZonesMapsAsyncEntities,
  (_, fieldUuid) => fieldUuid,
  (zonesMapsEntities, fieldUuid) => zonesMapsEntities[fieldUuid],
);

export const selectFieldsStatusesEntities = ({ smartSampling }) => smartSampling.fieldsStatuses;

export const selectFieldStatus = createSelector(
  selectFieldsStatusesEntities,
  (_, fieldUuid) => fieldUuid,
  (fieldStatuses, fieldUuid) => fieldStatuses[fieldUuid],
);

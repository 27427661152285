import CheckboxCell from './CheckboxCell';
import MenuCell from './MenuCell';
import EditableLinkCell from './EditableLinkCell';
import ImageCell from './ImageCell';
import IconButtonCell from './IconButtonCell';
import ColorCell from './ColorCell';
import MultilineCell from './MultilineCell';
import ZoneTypeCell from './ZoneTypeCell';
import InputCell from './InputCell';
import StatusCell from './StatusCell';
import ClickOperationCell from './ClickOperationCell';
import LabeledTextCell from './LabeledTextCell';
import ActionsCell from './ActionsCell';
import CollapsibleLabelsCell from './CollapsibleLabelsCell';
import DefaultCell from './DefaultCell';

const config = {
  default: DefaultCell,
  'labeled-text': LabeledTextCell,
  menu: MenuCell,
  'editable-link': EditableLinkCell,
  image: ImageCell,
  'collapsible-labels': CollapsibleLabelsCell,
  checkbox: CheckboxCell,
  'icon-button': IconButtonCell,
  color: ColorCell,
  multiline: MultilineCell,
  'zone-type': ZoneTypeCell,
  input: InputCell,
  status: StatusCell,
  'click-operation': ClickOperationCell,
  actions: ActionsCell,
} as const;

export type CellType = keyof typeof config;

export default config;

export const getUpdatedFilter = (filter) => {
  return {
    ...filter,
    pageNumber: 0,
  };
};

export const getLoadMoreFilter = (filter) => {
  return {
    ...filter,
    pageNumber: filter.pageNumber + 1,
  };
};

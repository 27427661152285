import React, { useState } from 'react';

import AdditionalUserDataForm from '../../components/AdditionalUserDataForm';
import Panel from '../../components/AuthenticationPanel';
import { errorNotify } from '../../../../notifications/helpers/functions/notify';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../app/store/helpers/functions';
import { CustomError } from '../../../../../helpers/functions/utils/errorHandling';
import {
  selectAdditionalUserData,
  selectEmail,
} from '../../../../user/userSelectors';
import {
  signOut,
  updateAdditionalUserData,
} from '../../../../user/userSlice';
import { AdditionalUserDataFields } from '../../types/ui';
import { tryGetApiErrors } from '../../../../emptyApi/helpers/functions/api';
import { useGetCountiesListQuery } from '../../../../countries/countriesAPI';

const AdditionalUserDataPanel = () => {
  const [saveInProgress, setSaveInProgress] = useState(false);
  const userData = useAppSelector(selectAdditionalUserData);
  const email = useAppSelector(selectEmail);
  const dispatch = useAppDispatch();

  const {
    data: countriesData,
    isFetching: countriesIsFetching,
  } = useGetCountiesListQuery();

  const handleSave = async (data: AdditionalUserDataFields) => {
    let errors: Error[] | null = null;
    setSaveInProgress(true);

    try {
      // @ts-expect-error
      await dispatch(updateAdditionalUserData(data)).unwrap();
    } catch (error) {
      errors = tryGetApiErrors(error);
      setSaveInProgress(false);
    }

    return errors;
  };

  const handleSaveUnknownError = (error: Error[]) => {
    errorNotify({
      error: new CustomError(
        '[Additional User Info] Unable to save user data.',
        { cause: error },
      ),
      dispatch,
    });
  };

  const handleLogOutClick = () => {
    dispatch(signOut());
  };

  return (
    <Panel loading={saveInProgress || countriesIsFetching}>
      <AdditionalUserDataForm
        email={email}
        userData={{
          country: userData.country,
          companyType: userData.companyType,
          acceptedTermsAndConditions: userData.acceptedTermsAndConditions,
          phoneNumber: userData.phoneNumber,
        }}
        countries={countriesData?.countries || []}
        onSave={handleSave}
        onSaveUnknownError={handleSaveUnknownError}
        onLogOutClick={handleLogOutClick}
      />
    </Panel>
  );
};

export default AdditionalUserDataPanel;

import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from '../..';
import ColorsLegend from '../../../Map/Controls/MiniLegend/ColorsLegend';
import type { GeoMap } from '../../../../helpers/types/api';
import { getDatasetLegendColors, getUnitLabel } from '../../../../helpers/functions/entities/dataset';
import type { FullAttribute } from '../../../../helpers/types/dataset';
import { getThumbnailUrl } from '../../../../helpers/functions/utils/url';

import './index.scss';

export default function GeoMapPreviewTooltip({
  children,
  apiKey,
  geoMap,
  fullAttribute,
  hideLegend,
}: {
  children: ReactElement,
  apiKey: string,
  geoMap: GeoMap,
  fullAttribute?: FullAttribute,
  hideLegend?: boolean,
}) {
  const { t } = useTranslation();

  const url = getThumbnailUrl({ thumbnail: geoMap, size: 128, apiKey });
  const legendItems = getDatasetLegendColors(geoMap);

  return (
    <Tooltip
      classes={{
        tooltip: 'dataset-preview-tooltip__tooltip',
        label: 'dataset-preview-tooltip__label',
      }}
      tooltip={(
        <>
          <img
            className="dataset-preview-tooltip__image"
            alt={t('general.shared.preview')}
            src={url}
          />
          {
            !hideLegend
              && (
                <ColorsLegend
                  name={fullAttribute?.fullName || geoMap.shortName}
                  unit={fullAttribute && getUnitLabel(fullAttribute.unit)}
                  items={legendItems}
                />
              )
          }
        </>
      )}
    >
      {children}
    </Tooltip>
  );
}

import { CustomError } from '../../../helpers/functions/utils/errorHandling';
import { emptyAPI } from '../../emptyApi/emptyAPI';
import { extractFields, getUniqueFieldUuids } from '../../farms/helpers/functions/farms';
import { transformVectorAnalysisMaps } from '../../field/helpers/functions/assets';
import { errorNotify } from '../../notifications/helpers/functions/notify';
import getHomepageDataQuery from './graphql/queries/getHomepageData.gql';
import getHomepageFieldsQuery from './graphql/queries/getHomepageFields.gql';
import type {
  Field,
  GetHomepageDataResponse,
  GetHomepageDataResult,
  GetHomepageFieldsResponse,
} from './types/api';

const homepageAPI = emptyAPI.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getHomepageData: builder.query<GetHomepageDataResult, void>({
      queryFn: async (
        _arg,
        { dispatch },
        _extraOptions,
        baseQuery,
      ) => {
        const getHomepageDataResult = await baseQuery({
          document: getHomepageDataQuery,
        });

        if (getHomepageDataResult.error) {
          errorNotify({
            error: new CustomError('[UI Homepage] Unable to fetch homepage data.', {
              cause: getHomepageDataResult.error,
            }),
            dispatch,
          });

          return {
            error: getHomepageDataResult.error,
          };
        }

        const {
          getZonesMaps,
        } = getHomepageDataResult.data as GetHomepageDataResponse;
        const zonesMaps = transformVectorAnalysisMaps(getZonesMaps.zonesMaps);
        const fieldUuids = getUniqueFieldUuids(zonesMaps);

        if (fieldUuids.length === 0) {
          return {
            data: {
              zonesMaps,
              fields: [],
            },
          };
        }

        const getHomepageFieldsResult = await baseQuery({
          document: getHomepageFieldsQuery,
          variables: {
            fieldUuids,
          },
        });

        if (getHomepageFieldsResult.error) {
          errorNotify({
            error: new CustomError('[UI Homepage] Unable to fetch fields.', {
              cause: getHomepageFieldsResult.error,
            }),
            dispatch,
          });

          return {
            error: getHomepageFieldsResult.error,
          };
        }

        const { getFarms } = getHomepageFieldsResult.data as GetHomepageFieldsResponse;
        const fields = extractFields<Field>(getFarms);

        return {
          data: {
            zonesMaps,
            fields,
          },
        };
      },
    }),
  }),
});

export const { useGetHomepageDataQuery } = homepageAPI;

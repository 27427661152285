export enum FieldPipelineStatus {
  failed = 'failed',
  // statuses for generatePfeiferLangenPinsGroups
  fieldInvalid = 'field-invalid',
  satImageNotFound = 'sat-image-not-found',
  pinsGroupsExist = 'pins-groups-exist',
  vamapsOrdering = 'vamaps-ordering',
  noIntersections = 'no-intersections',
  pinsGroupsOrdering = 'pins-groups-ordering',
  pinsGroupsCreated = 'pins-groups-created',
  // statuses for generatePfeiferLangenZonesMapsAsync
  vamapsOrdered = 'vamaps-ordered',
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import WelcomePanelComponent from '../../components/WelcomePanel';
import BackdropLoading from '../../../../../components/Loading/BackdropLoading';
import useUserDataPoller from '../../hooks/useUserDataPoller';

export default function WelcomePanel() {
  const { t } = useTranslation();
  const userDataLoaded = useUserDataPoller();

  return userDataLoaded
    ? <WelcomePanelComponent />
    : <BackdropLoading label={t('welcome-screen.loader-label')} />;
}

import React, {
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import OperationsFiltersComponent from '../../components/OperationsFilters';
import OperationTypesPanel from '../OperationTypesPanel';
import TimeRangeFilter from '../../components/TimeRangeFilter';
import useOperationsFilters from '../../hooks/useOperationsFilters';
import FarmFieldFilters from '../../components/FarmFieldFilters';
import { useGetFarmsQuery } from '../../../../farms/farmsAPI';
import { MONTHS_BACK_ALLOWED } from '../../helpers/constants/filters';
import { parseTimeRangeDate, validateCustomTimeRange } from '../../helpers/functions/filters';
import useOperations from '../../hooks/useOperations';

export default function OperationsFilters() {
  const {
    timeRange,
    farmUuid,
    fieldUuid,
    dateFrom,
    dateTo,
    onFarmChange,
    onFieldChange,
    onTimeRangeChange,
    onCustomTimeRangeChange,
  } = useOperationsFilters();

  const { fieldsWithOperations, isLoading } = useOperations();
  const { data: farms = [] } = useGetFarmsQuery();

  const farmsWithOperations = useMemo(() => {
    const uniqueFarmsUuids = new Set<string>();

    fieldsWithOperations.forEach((field) => {
      if (field.farmUuid) {
        uniqueFarmsUuids.add(field.farmUuid);
      }
    });

    return farms.filter((farm) => uniqueFarmsUuids.has(farm.uuid));
  }, [fieldsWithOperations, farms]);

  const { t } = useTranslation();

  return (
    <OperationsFiltersComponent>
      <TimeRangeFilter
        label={t('operations.filters.time-range.label')}
        timeRange={timeRange}
        dateFrom={parseTimeRangeDate(dateFrom)}
        dateTo={parseTimeRangeDate(dateTo)}
        monthsBackAllowed={MONTHS_BACK_ALLOWED}
        onTimeRangeChange={onTimeRangeChange}
        onCustomTimeRangeChange={onCustomTimeRangeChange}
        validateCustomTimeRange={validateCustomTimeRange}
      />
      <FarmFieldFilters
        farmUuid={farmUuid}
        fieldUuid={fieldUuid}
        farms={farmsWithOperations}
        fields={fieldsWithOperations}
        loading={isLoading}
        onFarmChange={onFarmChange}
        onFieldChange={onFieldChange}
      />
      <OperationTypesPanel />
    </OperationsFiltersComponent>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import RadioGroup from '../../../../../../components/RadioGroup';
import { setSelectedAssetType } from '../../../createSingleLayerAnalysisSlice';
import { selectAssets } from '../../../../../field/fieldSelectors';
import { selectAssetType } from '../../../createSingleLayerAnalysisSelectors';
import { AssetType } from '../../../../../../helpers/constants/entities/asset';

const Body = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const assetType = useSelector(selectAssetType);
  const {
    satelliteImages,
    soilDatasets,
    yieldDatasets,
    asAppliedDatasets,
    topographyMaps,
  } = useSelector(selectAssets);

  const onAssetTypeChange = (value) => {
    dispatch(setSelectedAssetType(value));
  };

  return (
    <RadioGroup
      options={[
        {
          value: AssetType.satelliteImage,
          disabled: satelliteImages.length === 0,
          title: t('general.shared.satellite-monitoring'),
        },
        {
          value: AssetType.soilDataset,
          disabled: soilDatasets.length === 0,
          title: t('general.shared.soil-data'),
        },
        {
          value: AssetType.yieldDataset,
          disabled: yieldDatasets.length === 0,
          title: t('general.shared.yield-data'),
        },
        {
          value: AssetType.asAppliedDataset,
          disabled: asAppliedDatasets.length === 0,
          title: t('general.shared.as-applied-data'),
        },
        {
          value: AssetType.topographyMap,
          disabled: topographyMaps.length === 0,
          title: t('general.shared.topography'),
        },
      ]}
      value={assetType}
      onChange={onAssetTypeChange}
    />
  );
};

export default Body;

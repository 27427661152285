import { AssetType } from '../../../../../helpers/constants/entities/asset';

export enum MenuAction {
  goToMap = 'go-to-map',
  assignRates = 'assign-rates',
  exportMap = 'export-map',
  downloadSourceData = 'download-source-data',
  downloadOriginalData = 'download-original-data',
  exportToJDAsFiles = 'export-to-jd-as-files',
  exportToJDAsMapsLayers = 'export-to-jd-as-maps-layers',
  exportToJDAsWorkPlan = 'export-to-jd-as-work-plan',
  exportToJDAsYieldOperation = 'export-to-jd-as-yield-operation',
  cloneZonesMap = 'clone-zones-map',
  renameZonesMap = 'rename-zones-map',
  deleteZonesMap = 'delete-zones-map',
  downloadEquationMap = 'download-equation-map',
  renameEquationMap = 'rename-equation-map',
  deleteEquationMap = 'delete-equation-map',
  renameSoilDataset = 'rename-soil-dataset',
  deleteSoilDataset = 'delete-soil-dataset',
  renameYieldDataset = 'rename-yield-dataset',
  deleteYieldDataset = 'delete-yield-dataset',
  renameAsAppliedDataset = 'rename-as-applied-dataset',
  deleteAsAppliedDataset = 'delete-as-applied-dataset',
  renameTopographyMap = 'rename-topography-map',
  deleteTopographyMap = 'delete-topography-map',
  orderPlanetImage = 'order-planet-image',
  orderProcessingPlanetImage = 'order-processing-planet-image',
  rename3DMap = 'rename-3d-map',
  delete3DMap = 'delete-3d-map',
}

// Left offset of the TreeViewItem according to the layout
export const TREE_VIEW_ITEM_OFFSET = 5;
export const CHILD_TREE_VIEW_ITEM_OFFSET = 37;

export const DATASET_ASSET_TYPE_TO_RENAME_MENU_ACTION = {
  [AssetType.soilDataset]: MenuAction.renameSoilDataset,
  [AssetType.yieldDataset]: MenuAction.renameYieldDataset,
  [AssetType.asAppliedDataset]: MenuAction.renameAsAppliedDataset,
  [AssetType.topographyMap]: MenuAction.renameTopographyMap,

};

export const DATASET_ASSET_TYPE_TO_DELETE_MENU_ACTION = {
  [AssetType.soilDataset]: MenuAction.deleteSoilDataset,
  [AssetType.yieldDataset]: MenuAction.deleteYieldDataset,
  [AssetType.asAppliedDataset]: MenuAction.deleteAsAppliedDataset,
  [AssetType.topographyMap]: MenuAction.deleteTopographyMap,

};

import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from '../..';

import './index.scss';

const getCoverage = (fieldCoverage: number): number => {
  return parseFloat((fieldCoverage * 100).toFixed(2));
};

export default function CoveredAreaTooltip({
  fieldCoverage,
  children,
}: {
  fieldCoverage: number,
  children: ReactElement,
}) {
  const { t } = useTranslation();
  const coverage = getCoverage(fieldCoverage);

  return (
    <Tooltip
      classes={{
        label: 'covered-area-tooltip__label',
        tooltip: 'covered-area-tooltip__tooltip',
      }}
      placement="right"
      tooltip={(
        <p className="covered-area-tooltip__tooltip-line">
          {t('field.assets.dataset-items.covered-area-tooltip.area-covered', { coverage })}
        </p>
      )}
    >
      {children}
    </Tooltip>
  );
}

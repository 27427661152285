import { useEffect, useState } from 'react';

import { useLazyGetFieldsPageQuery } from '../fieldsAPI';
import { VALID_FIELD_STATUSES } from '../../field/helpers/constants/field';
import { FieldFragment } from '../helpers/constants/fragments';
import { useAppSelector } from '../../../app/store/helpers/functions';
import { selectAreaUnit } from '../../user/userSelectors';
import { Label } from '../../field/types/field';

const PAGE_SIZE = 25;

export default function useInfiniteFieldsList({
  farmUuid,
  fieldName,
  labels,
  skip,
}: {
  farmUuid?: string,
  fieldName?: string,
  labels?: Label[],
  skip?: boolean,
}) {
  const areaUnit = useAppSelector(selectAreaUnit);
  const [trigger, result] = useLazyGetFieldsPageQuery();
  const [filtersChanged, setFiltersChanged] = useState(false);

  useEffect(() => {
    if (skip) {
      return;
    }

    setFiltersChanged(false);
    trigger({
      fieldFragment: FieldFragment.tableViewField,
      areaUnit,
      lastEvaluatedKey: null,
      filter: {
        pageSize: PAGE_SIZE,
        farmUuid,
        fieldName,
        labels,
        fieldStatuses: VALID_FIELD_STATUSES,
      },
    }, true);
  }, [
    areaUnit,
    farmUuid,
    fieldName,
    labels,
    skip,
    trigger,
  ]);

  useEffect(() => {
    setFiltersChanged(true);
  }, [
    farmUuid,
    fieldName,
    labels,
  ]);

  const triggerNextPage = () => {
    if (result.isFetching || result.isLoading || !result.data?.lastEvaluatedKey) {
      return;
    }

    setFiltersChanged(false);
    trigger({
      fieldFragment: FieldFragment.tableViewField,
      areaUnit,
      lastEvaluatedKey: result.data.lastEvaluatedKey,
      filter: {
        pageSize: PAGE_SIZE,
        farmUuid,
        fieldName,
        labels,
        fieldStatuses: VALID_FIELD_STATUSES,
      },
    });
  };

  return {
    fields: result.data?.fields || [],
    fieldsLoading: result.isLoading || (result.isFetching && filtersChanged),
    fieldsLoadComplete: !result.data?.lastEvaluatedKey,
    triggerNextPage,
  };
}

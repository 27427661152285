import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getUserDataFetcher } from '../ui/applicationShell/applicationShellSlice';
import { parseEvent } from './helpers/functions/subscription';
import { subscribe as subscribeAPI } from './subscriptionAPI';
import { AssetGroupType } from '../../helpers/constants/entities/asset';
import type { Action } from './types/action';

interface SubscriptionState {
  [AssetGroupType.satelliteImages]: Action[];
  [AssetGroupType.vectorAnalysisMaps]: Action[];
  [AssetGroupType.soilDatasets]: Action[];
  [AssetGroupType.yieldDatasets]: Action[];
  [AssetGroupType.asAppliedDatasets]: Action[];
  [AssetGroupType.topographyMaps]: Action[];
  [AssetGroupType.equationMaps]: Action[];
}

type SubscriptionStateKeys = keyof SubscriptionState;

const initialState: SubscriptionState = {
  satelliteImages: [],
  vectorAnalysisMaps: [],
  soilDatasets: [],
  yieldDatasets: [],
  asAppliedDatasets: [],
  topographyMaps: [],
  equationMaps: [],
};

export const getSubscriptionObservable = async () => {
  const userData = await getUserDataFetcher();
  const observable = await subscribeAPI(userData?.uuid);

  return observable
    .filter((event) => !!event)
    .map((event) => parseEvent(event!));
};

const getAddHandler = (prop: SubscriptionStateKeys) => (
  state: SubscriptionState,
  action: PayloadAction<Action>,
) => {
  state[prop].push(action.payload);
};

const getDeleteHandler = (prop: SubscriptionStateKeys) => (
  state: SubscriptionState,
  action: PayloadAction<string[]>,
) => {
  const uuidsSet = new Set(action.payload);

  state[prop] = state[prop].filter(({ uuid }) => {
    return !uuidsSet.has(uuid);
  });
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    addAsAppliedDataset: getAddHandler(AssetGroupType.asAppliedDatasets),
    addSatelliteImage: getAddHandler(AssetGroupType.satelliteImages),
    addSoilDataset: getAddHandler(AssetGroupType.soilDatasets),
    addTopographyMap: getAddHandler(AssetGroupType.topographyMaps),
    addVectorAnalysisMap: getAddHandler(AssetGroupType.vectorAnalysisMaps),
    addEquationMap: getAddHandler(AssetGroupType.equationMaps),
    addYieldDataset: getAddHandler(AssetGroupType.yieldDatasets),
    deleteAsAppliedDatasets: getDeleteHandler(AssetGroupType.asAppliedDatasets),
    deleteSatelliteImages: getDeleteHandler(AssetGroupType.satelliteImages),
    deleteSoilDatasets: getDeleteHandler(AssetGroupType.soilDatasets),
    deleteTopographyMaps: getDeleteHandler(AssetGroupType.topographyMaps),
    deleteVectorAnalysisMaps: getDeleteHandler(AssetGroupType.vectorAnalysisMaps),
    deleteEquationMaps: getDeleteHandler(AssetGroupType.equationMaps),
    deleteYieldDatasets: getDeleteHandler(AssetGroupType.yieldDatasets),
  },
});

export const {
  addAsAppliedDataset,
  addSatelliteImage,
  addSoilDataset,
  addTopographyMap,
  addVectorAnalysisMap,
  addEquationMap,
  addYieldDataset,
  deleteAsAppliedDatasets,
  deleteSatelliteImages,
  deleteSoilDatasets,
  deleteTopographyMaps,
  deleteVectorAnalysisMaps,
  deleteYieldDatasets,
  deleteEquationMaps,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;

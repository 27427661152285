export const GEOMAP_CONTROL_ITEMS = [
  {
    title: 'Zones',
    value: 'zones',
  },
  {
    title: 'Pixels',
    value: 'pixels',
  },
];

export const DEFAULT_GEOMAP = GEOMAP_CONTROL_ITEMS[1].value;

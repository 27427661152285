import { API, graphqlOperation } from '@aws-amplify/api';

import getColorSchemasQuery from './graphql/queries/getColorSchemas.gql';
import addSchemaMutation from './graphql/mutations/addColorSchemas.gql';
import saveColorSchemaMutation from './graphql/mutations/saveColorSchema.gql';
import deleteColorSchemaMutation from './graphql/mutations/deleteColorSchemas.gql';

export const getAll = async () => {
  return API.graphql(graphqlOperation(getColorSchemasQuery))
    .then(({ data }) => {
      return data.getUserData.colorSchemas;
    });
};

export const add = async (schema) => {
  return API.graphql(graphqlOperation(addSchemaMutation, {
    input: {
      schemas: [schema],
    },
  }))
    .then(({ data }) => {
      return data.addColorSchemas[0];
    });
};

export const update = async (schema) => {
  return API.graphql(graphqlOperation(saveColorSchemaMutation, {
    input: schema,
  }))
    .then(({ data }) => {
      return data.saveColorSchema;
    });
};

export const remove = async (uuid) => {
  return API.graphql(graphqlOperation(deleteColorSchemaMutation, {
    input: {
      schemas: [{ uuid }],
    },
  }))
    .then(({ data }) => {
      return data.deleteColorSchemas;
    });
};

export const selectFeature = ({ drawManuallyWorkflow }) => drawManuallyWorkflow.feature;

export const selectColors = ({ drawManuallyWorkflow }) => drawManuallyWorkflow.colors;

export const selectInstrument = ({ drawManuallyWorkflow }) => drawManuallyWorkflow.instrument;

export const selectFieldFeature = ({ drawManuallyWorkflow }) => drawManuallyWorkflow.fieldFeature;

export const selectPolygonsTransparency = ({ drawManuallyWorkflow }) => drawManuallyWorkflow.polygonsTransparency;

export const selectPriceCalculator = ({ drawManuallyWorkflow }) => drawManuallyWorkflow.priceCalculator;

export const selectClonePolygonsLoading = ({ drawManuallyWorkflow }) => drawManuallyWorkflow.clonePolygons.loading;

export const selectClonePolygonsUuid = ({ drawManuallyWorkflow }) => drawManuallyWorkflow.clonePolygons.uuid;

export const selectClonePolygonsAssetType = ({ drawManuallyWorkflow }) => {
  return drawManuallyWorkflow.clonePolygons.assetType;
};

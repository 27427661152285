import { fieldQueryBuilder } from './graphql/queries/queryBuilder';
import {
  SomeField,
  GetFieldArg,
  GetAllFieldsArg,
  GetFieldReturnValue,
  GetFieldsReturnValue,
  CompositePrimaryKey,
  GetFieldsPageArg,
  QueryResult,
  GetFieldsPageResult,
  DeleteFieldsArg,
  RenameFieldArg,
  SetFieldLabelsArg,
  AddFieldsLabelsArg,
} from './types/api';
import { Label, TransformedField } from '../field/types/field';
import { errorNotify } from '../notifications/helpers/functions/notify';
import { FIELD_FRAGMENTS } from './graphql/fragments/field';
import { getSubscriptionObservable } from '../subscription/subscriptionSlice';
import { PlatformEventAction } from '../subscription/helpers/constants/action';
import { LIST_ID, TagType, emptyAPI } from '../emptyApi/emptyAPI';
import { CustomError, captureException } from '../../helpers/functions/utils/errorHandling';
import { transformFields } from '../field/helpers/functions/assets';
import deleteFieldsMutation from './graphql/mutations/deleteFields.gql';
import renameFieldMutation from './graphql/mutations/renameField.gql';
import setFieldLabelsMutation from './graphql/mutations/setFieldLabels.gql';
import addFieldsLabelsMutation from './graphql/mutations/addFieldsLabels.gql';
import deleteFieldsLabelsMutation from './graphql/mutations/deleteFieldsLabels.gql';

const PAGINATED_LIST_ID = 'PAGINATED_LIST';

export const fieldsAPI = emptyAPI.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getAllFields: builder.query<SomeField[], GetAllFieldsArg>({
      queryFn: async (
        {
          fieldFragment,
          areaUnit,
          pageSize,
        },
        { dispatch },
        _extraOptions,
        baseQuery,
      ) => {
        let result: QueryResult<SomeField[]>;

        const fetchAllFieldsPages = async (
          lastEvaluatedKey?: CompositePrimaryKey,
        ): Promise<QueryResult<SomeField[]>> => {
          const { data, error } = await baseQuery({
            document: fieldQueryBuilder('fieldsPage', FIELD_FRAGMENTS[fieldFragment](areaUnit)),
            variables: {
              filter: {
                fieldStatuses: ['TILES_REGISTERED', 'GRIDS_CREATED'],
                lastEvaluatedKey: lastEvaluatedKey ?? null,
                pageSize,
              },
            },
          }) as GetFieldsReturnValue;

          const fieldsPage = data?.getFields || error?.data?.getFields;
          let fieldsPageResult: QueryResult<SomeField[]>;

          if (fieldsPage) {
            const nextFieldsPage = fieldsPage?.lastEvaluatedKey
              ? await fetchAllFieldsPages(fieldsPage.lastEvaluatedKey)
              : null;

            fieldsPageResult = nextFieldsPage && 'error' in nextFieldsPage
              ? { error: nextFieldsPage.error }
              : {
                data: [
                  ...fieldsPage.fields,
                  ...(nextFieldsPage && 'data' in nextFieldsPage ? nextFieldsPage.data : []),
                ],
              };
          } else {
            captureException({
              error: new CustomError('[All fields] Unable to fetch all farms fields at once', {
                cause: error,
              }),
            });

            fieldsPageResult = { error };
          }

          return fieldsPageResult;
        };

        try {
          result = await fetchAllFieldsPages();
        } catch (error) {
          errorNotify({ error, dispatch });
          result = { error };
        }

        return result;
      },
      providesTags: (result) => {
        return result
          ? [
            ...result.map(({ uuid }) => ({ type: TagType.field, id: uuid })),
            { type: TagType.field, id: LIST_ID },
          ]
          : [{ type: TagType.field, id: LIST_ID }];
      },
      onCacheEntryAdded: async (
        { fieldFragment, areaUnit },
        {
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          dispatch,
        },
      ) => {
        await cacheDataLoaded;

        const subscribe = async () => {
          return (await getSubscriptionObservable()).subscribe({
            next: async ({
              action,
              pathLength,
              farmUuid,
              fieldUuid,
            }) => {
              if (!farmUuid || !fieldUuid || pathLength !== 2) {
                return;
              }

              if (action === PlatformEventAction.insert || action === PlatformEventAction.modify) {
                const { data: field } = await dispatch(
                  fieldsAPI.endpoints.getField.initiate({
                    fieldFragment,
                    areaUnit,
                    farmUuid,
                    fieldUuid,
                  }, { forceRefetch: true }),
                );

                if (!field) {
                  return;
                }

                try {
                  updateCachedData((draft) => {
                    const fieldToUpdateIndex = draft.findIndex(({ uuid }) => uuid === fieldUuid);

                    if (fieldToUpdateIndex === -1) {
                      draft.push(field);
                    } else {
                      draft[fieldToUpdateIndex] = field;
                    }
                  });
                } catch {
                  // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`
                }
              } else if (action === PlatformEventAction.remove) {
                try {
                  updateCachedData((draft) => draft.filter(({ uuid }) => uuid !== fieldUuid));
                } catch {
                  // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`
                }
              }
            },
            error: subscribe,
          });
        };

        const subscription = subscribe();

        await cacheEntryRemoved;
        (await subscription).unsubscribe();
      },
    }),
    getField: builder.query<SomeField, GetFieldArg>({
      queryFn: async (
        {
          fieldFragment,
          areaUnit,
          farmUuid,
          fieldUuid,
        },
        _api,
        _extraOptions,
        baseQuery,
      ) => {
        let result: QueryResult<SomeField>;

        const { data, error } = await baseQuery({
          document: fieldQueryBuilder('single', FIELD_FRAGMENTS[fieldFragment](areaUnit)),
          variables: {
            filter: {
              farmUuid,
              fieldUuid,
            },
          },
        }) as GetFieldReturnValue;

        if (data) {
          result = {
            data: data.getFields.fields[0],
          };
        } else {
          result = { error };
        }

        return result;
      },
      providesTags: (_result, _error, { fieldUuid }) => {
        return [{ type: TagType.field, id: fieldUuid }];
      },
    }),
    getFieldsPage: builder.query<GetFieldsPageResult, GetFieldsPageArg>({
      queryFn: async (
        {
          fieldFragment,
          areaUnit,
          lastEvaluatedKey,
          filter,
        },
        { dispatch },
        _extraOptions,
        baseQuery,
      ) => {
        let result: QueryResult<GetFieldsPageResult>;

        const { data, error } = await baseQuery({
          document: fieldQueryBuilder('fieldsPage', FIELD_FRAGMENTS[fieldFragment](areaUnit)),
          variables: {
            filter: {
              ...filter,
              lastEvaluatedKey,
            },
          },
        }) as GetFieldsReturnValue;

        const fieldsPage = data?.getFields || error?.data?.getFields;

        if (fieldsPage?.fields) {
          result = {
            data: {
              fields: transformFields(fieldsPage.fields),
              lastEvaluatedKey: fieldsPage.lastEvaluatedKey,
            },
          };
        } else {
          result = { error };
          errorNotify({ error, dispatch });
        }

        return result;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        // Skip `lastEvaluatedKey` from serialization
        // to avoid creating new cache entry for each page
        const { lastEvaluatedKey, ...restQueryArgs } = queryArgs;

        return `${endpointName}(${JSON.stringify(restQueryArgs)})`;
      },
      merge: (currentCache, responseData) => {
        return {
          // Append response to the existing list of fields
          fields: [
            ...(currentCache?.fields ?? []),
            ...(responseData.fields ?? []),
          ],
          // Always keep most recent lastEvaluatedKey for the single cache entry
          lastEvaluatedKey: responseData.lastEvaluatedKey,
        };
      },
      providesTags: () => {
        return [{ type: TagType.field, id: PAGINATED_LIST_ID }];
      },
    }),
    deleteFields: builder.mutation<void, DeleteFieldsArg>({
      queryFn: async (
        fields,
        { dispatch },
        _extraOptions,
        baseQuery,
      ) => {
        const deleteFieldsResult = await baseQuery({
          document: deleteFieldsMutation,
          variables: { input: fields },
        });

        if (deleteFieldsResult.error) {
          errorNotify({
            error: new CustomError('[Fields] Unable to delete fields.', {
              cause: deleteFieldsResult.error,
            }),
            dispatch,
          });

          return { error: deleteFieldsResult.error };
        }

        return { data: undefined };
      },
      // Function to manually update cache entries after deletion
      onQueryStarted: async (patch, { getState, dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          // Get args part of cache entries' keys for
          const cacheEntriesToInvalidate = fieldsAPI.util.selectInvalidatedBy(getState(), [{
            type: TagType.field,
            // PAGINATED_LIST_ID designated for getFieldsPage endpoint
            id: PAGINATED_LIST_ID,
          }]);

          // Iterates over 'getFieldsPage' cache entries and manually remove fields
          for (const { originalArgs } of cacheEntriesToInvalidate) {
            dispatch(
              fieldsAPI.util.updateQueryData('getFieldsPage', originalArgs, (draft) => {
                return {
                  lastEvaluatedKey: draft.lastEvaluatedKey,
                  fields: draft.fields.filter((field) => {
                    return !patch.some((fieldToDelete) => fieldToDelete.uuid === field.uuid);
                  }),
                };
              }),
            );
          }
        } catch {
          // no-op
        }
      },
      invalidatesTags: (_result, _error, args) => {
        return [
          ...args.map(({ uuid }) => ({ type: TagType.field, id: uuid })),
          { type: TagType.field, id: LIST_ID },
        ];
      },
    }),
    renameField: builder.mutation<void, RenameFieldArg>({
      queryFn: async (
        arg,
        { dispatch },
        _extraOptions,
        baseQuery,
      ) => {
        const renameFieldResult = await baseQuery({
          document: renameFieldMutation,
          variables: { input: arg },
        });

        if (renameFieldResult.error) {
          errorNotify({
            error: new CustomError('[Fields] Unable to rename field.', {
              cause: renameFieldResult.error,
            }),
            dispatch,
          });

          return { error: renameFieldResult.error };
        }

        return { data: undefined };
      },
      onQueryStarted: async (patch, { getState, dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          const cacheEntriesToInvalidate = fieldsAPI.util.selectInvalidatedBy(getState(), [{
            type: TagType.field,
            id: PAGINATED_LIST_ID,
          }]);

          for (const { originalArgs } of cacheEntriesToInvalidate) {
            dispatch(
              fieldsAPI.util.updateQueryData('getFieldsPage', originalArgs, (draft) => {
                return {
                  lastEvaluatedKey: draft.lastEvaluatedKey,
                  fields: draft.fields.map((field) => {
                    return patch.uuid === field.uuid ? { ...field, name: patch.name } : field;
                  }),
                };
              }),
            );
          }
        } catch {
          // no-op
        }
      },
      invalidatesTags: (_result, _error, args) => {
        return [{ type: TagType.field, id: args.uuid }];
      },
    }),
    setFieldLabels: builder.mutation<void, SetFieldLabelsArg>({
      queryFn: async (
        arg,
        { dispatch },
        _extraOptions,
        baseQuery,
      ) => {
        const setFieldLabelsResult = await baseQuery({
          document: setFieldLabelsMutation,
          variables: { input: arg },
        });

        if (setFieldLabelsResult.error) {
          errorNotify({
            error: new CustomError('[Fields] Unable to set field labels.', {
              cause: setFieldLabelsResult.error,
            }),
            dispatch,
          });

          return { error: setFieldLabelsResult.error };
        }

        return { data: undefined };
      },
      onQueryStarted: async (patch, { getState, dispatch, queryFulfilled }) => {
        const cacheEntriesToInvalidate = fieldsAPI.util.selectInvalidatedBy(getState(), [{
          type: TagType.field,
          id: PAGINATED_LIST_ID,
        }]);
        const patchResults = [];

        for (const { originalArgs } of cacheEntriesToInvalidate) {
          patchResults.push(
            dispatch(
              fieldsAPI.util.updateQueryData('getFieldsPage', originalArgs, (draft) => {
                return {
                  lastEvaluatedKey: draft.lastEvaluatedKey,
                  fields: draft.fields.map((field: TransformedField) => {
                    return patch.uuid === field.uuid ? { ...field, labels: patch.labels } : field;
                  }),
                };
              }),
            ),
          );
        }

        try {
          await queryFulfilled;
        } catch {
          patchResults.forEach((patchResult) => {
            patchResult.undo();
          });
        }
      },
      invalidatesTags: (_result, _error, args) => {
        return [{ type: TagType.field, id: args.uuid }];
      },
    }),
    addFieldsLabels: builder.mutation<void, AddFieldsLabelsArg>({
      queryFn: async (
        arg,
        { dispatch },
        _extraOptions,
        baseQuery,
      ) => {
        const addFieldsLabelsResult = await baseQuery({
          document: addFieldsLabelsMutation,
          variables: { input: { fields: arg } },
        });

        if (addFieldsLabelsResult.error) {
          errorNotify({
            error: new CustomError('[Fields] Unable to add fields labels.', {
              cause: addFieldsLabelsResult.error,
            }),
            dispatch,
          });

          return { error: addFieldsLabelsResult.error };
        }

        return { data: undefined };
      },
      onQueryStarted: async (patch, { getState, dispatch, queryFulfilled }) => {
        const cacheEntriesToInvalidate = fieldsAPI.util.selectInvalidatedBy(getState(), [{
          type: TagType.field,
          id: PAGINATED_LIST_ID,
        }]);
        const patchResults = [];
        const updatedLabelsMap = patch.reduce((acc, curr) => {
          acc[curr.uuid] = curr.labels;

          return acc;
        }, {} as Record<string, Label[]>);

        for (const { originalArgs } of cacheEntriesToInvalidate) {
          patchResults.push(
            dispatch(
              fieldsAPI.util.updateQueryData('getFieldsPage', originalArgs, (draft) => {
                return {
                  lastEvaluatedKey: draft.lastEvaluatedKey,
                  fields: draft.fields.map((field: TransformedField) => {
                    return updatedLabelsMap[field.uuid]
                      ? {
                        ...field,
                        labels: [
                          ...field.labels || [],
                          ...updatedLabelsMap[field.uuid],
                        ],
                      }
                      : field;
                  }),
                };
              }),
            ),
          );
        }

        try {
          await queryFulfilled;
        } catch {
          patchResults.forEach((patchResult) => {
            patchResult.undo();
          });
        }
      },
      invalidatesTags: (_result, _error, args) => {
        return args.map(({ uuid }) => ({ type: TagType.field, id: uuid }));
      },
    }),
    deleteFieldsLabels: builder.mutation<void, AddFieldsLabelsArg>({
      queryFn: async (
        arg,
        { dispatch },
        _extraOptions,
        baseQuery,
      ) => {
        const deleteFieldsLabelsResult = await baseQuery({
          document: deleteFieldsLabelsMutation,
          variables: { input: { fields: arg } },
        });

        if (deleteFieldsLabelsResult.error) {
          errorNotify({
            error: new CustomError('[Fields] Unable to delete fields labels.', {
              cause: deleteFieldsLabelsResult.error,
            }),
            dispatch,
          });

          return { error: deleteFieldsLabelsResult.error };
        }

        return { data: undefined };
      },
      onQueryStarted: async (patch, { getState, dispatch, queryFulfilled }) => {
        const cacheEntriesToInvalidate = fieldsAPI.util.selectInvalidatedBy(getState(), [{
          type: TagType.field,
          id: PAGINATED_LIST_ID,
        }]);
        const patchResults = [];
        const labelsToDeleteMap = patch.reduce((acc, curr) => {
          acc[curr.uuid] = curr.labels;

          return acc;
        }, {} as Record<string, Label[]>);

        for (const { originalArgs } of cacheEntriesToInvalidate) {
          patchResults.push(
            dispatch(
              fieldsAPI.util.updateQueryData('getFieldsPage', originalArgs, (draft) => {
                return {
                  lastEvaluatedKey: draft.lastEvaluatedKey,
                  fields: draft.fields.map((field: TransformedField) => {
                    if (labelsToDeleteMap[field.uuid]) {
                      const labelsToDeleteSet = new Set(
                        labelsToDeleteMap[field.uuid]?.map((deleteLabel) => {
                          return `${deleteLabel.name}:${deleteLabel.value}`;
                        }) ?? [],
                      );
                      const filteredLabels = field.labels?.filter((label) => {
                        return !labelsToDeleteSet.has(`${label.name}:${label.value}`);
                      });

                      return { ...field, labels: filteredLabels };
                    }

                    return field;
                  }),
                };
              }),
            ),
          );
        }

        try {
          await queryFulfilled;
        } catch {
          patchResults.forEach((patchResult) => {
            patchResult.undo();
          });
        }
      },
      invalidatesTags: (_result, _error, args) => {
        return args.map(({ uuid }) => ({ type: TagType.field, id: uuid }));
      },
    }),
  }),
});

export const {
  useGetAllFieldsQuery,
  useLazyGetFieldsPageQuery,
  useDeleteFieldsMutation,
  useRenameFieldMutation,
  useSetFieldLabelsMutation,
  useAddFieldsLabelsMutation,
  useDeleteFieldsLabelsMutation,
} = fieldsAPI;

import React, { ReactNode } from 'react';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Typography from '@material-ui/core/Typography';

import './index.scss';

export default function InfoPanel({
  children,
}: {
  children: ReactNode,
}) {
  return (
    <div className="info-panel">
      <InfoIcon
        className="info-panel__icon"
        fontSize="small"
      />
      <Typography className="info-panel__text">
        {children}
      </Typography>
    </div>
  );
}

export function getProductsFilteredByName<T extends { name: string }>(products: T[] = [], name = '') {
  if (!name) {
    return products;
  }

  const preparedNameFilterValue = name.toLowerCase();

  return products.filter((product) => {
    return (product.name ?? '').toLowerCase().includes(preparedNameFilterValue);
  });
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';

import { FilterParam } from '../../helpers/constants/filters';
import {
  TimeRange,
  TimeRangeErrors,
} from '../../types/filters';
import Datepicker from '../../../../../components/Datepicker';

import './index.scss';

export default function CustomTimeRange({
  dateFrom,
  dateTo,
  minDate,
  validate,
  onTimeRangeChange,
}: {
  dateFrom: Date | null;
  dateTo: Date | null;
  minDate?: Date;
  validate: (filterType: keyof TimeRange, timeRange: TimeRange) => TimeRangeErrors
  onTimeRangeChange: (timeRange: TimeRange) => void;
}) {
  const { t } = useTranslation();

  const [timeRange, setTimeRange] = useState<TimeRange>({ dateFrom, dateTo });
  const [errors, setErrors] = useState<TimeRangeErrors>({});

  const handleChange = (filterType: keyof TimeRange, value: Date | null) => {
    const newTimeRange = { ...timeRange, [filterType]: value };
    setTimeRange(newTimeRange);

    const validationErrors = validate(filterType, newTimeRange);
    setErrors(validationErrors);

    if (Object.values(validationErrors).every((err) => !err)) {
      onTimeRangeChange(newTimeRange);
    }
  };

  return (
    <div className="date-time-range-filter">
      <Datepicker
        value={timeRange.dateFrom}
        classes={{ root: 'date-time-range-filter__item' }}
        placeholder={t('operations.filters.time-range.placeholder')}
        minDate={minDate}
        onChange={(value: Date | null) => handleChange(FilterParam.dateFrom, value)}
        helperText={errors[FilterParam.dateFrom]}
      />
      <Divider className="date-time-range-filter__divider" />
      <Datepicker
        value={timeRange.dateTo}
        classes={{ root: 'date-time-range-filter__item' }}
        placeholder={t('operations.filters.time-range.placeholder')}
        minDate={minDate}
        onChange={(value: Date | null) => handleChange(FilterParam.dateTo, value)}
        helperText={errors[FilterParam.dateTo]}
      />
    </div>
  );
}

import { AssetType } from '../../../../helpers/constants/entities/asset';
import { CustomError } from '../../../../helpers/functions/utils/errorHandling';
import type { DataLayer } from '../../../ui/createMultiLayerAnalysis/types/ui';
import type { DataLayerInput, GenerationResult } from '../../types/api';

export const verifyGenerateVamapResponse = (response?: GenerationResult): void | never => {
  const DEFAULT_ERROR_MESSAGE = `Vamap generation status code: ${response?.statusCode}`;

  if (response?.statusCode !== 202) {
    throw new CustomError(response?.statusMessage || DEFAULT_ERROR_MESSAGE);
  }
};

export const getDataLayersInput = (dataLayers: DataLayer[]) => {
  return dataLayers.map((layer) => {
    const {
      type,
      weight,
    } = layer;
    const dataLayerInput: DataLayerInput = {
      factor: parseFloat(weight),
    };

    if (type === AssetType.satelliteImage) {
      dataLayerInput.index = layer.index;
      dataLayerInput.satelliteImageUuids = layer.satelliteImageUuids;
    } else if (type === AssetType.soilDataset) {
      dataLayerInput.soilAttribute = layer.attribute;
      dataLayerInput.soilDatasetUuid = layer.datasetUuid;
    } else if (type === AssetType.yieldDataset) {
      dataLayerInput.yieldAttribute = layer.attribute;
      dataLayerInput.yieldDatasetUuid = layer.datasetUuid;
    } else if (type === AssetType.asAppliedDataset) {
      dataLayerInput.asAppliedAttribute = layer.attribute;
      dataLayerInput.asAppliedDatasetUuid = layer.datasetUuid;
    } else if (type === AssetType.topographyMap) {
      dataLayerInput.topographyAttribute = layer.attribute;
      dataLayerInput.topographyMapUuid = layer.datasetUuid;
    }

    return dataLayerInput;
  });
};

import { API, graphqlOperation } from '@aws-amplify/api';

import { fetchAllSatelliteImages } from '../satelliteImages/satelliteImagesAPI';

import getFarmFields from './graphql/queries/getFarmFields.gql';
import getFieldWithExportAssets from './graphql/queries/getFieldWithExportAssets.gql';
import getFieldLegendData from './graphql/queries/getFieldLegendData.gql';
import exportDataMutation from './graphql/mutations/exportData.gql';
import exportIsoXmlMutation from './graphql/mutations/exportIsoXml.gql';

export const fetchFarmFields = async (uuid) => {
  return API.graphql(graphqlOperation(getFarmFields, {
    farmUuids: [uuid],
  }))
    .then(({ data: { getFarms } }) => {
      return getFarms[0].fields;
    });
};

export const fetchFieldWithExportAssets = async (farmUuid, fieldUuid, areaUnit) => {
  return API.graphql(graphqlOperation(getFieldWithExportAssets, {
    farmUuids: [farmUuid],
    fieldUuids: [fieldUuid],
    areaUnit,
  }))
    .then(({ data: { getFarms } }) => {
      return getFarms[0].fields[0];
    });
};

export const fetchFieldLegendData = async (farmUuid, fieldUuid) => {
  return Promise.all([
    API.graphql(graphqlOperation(getFieldLegendData, {
      farmUuids: [farmUuid],
      fieldUuids: [fieldUuid],
    })),
    fetchAllSatelliteImages(farmUuid, fieldUuid),
  ])
    .then(([fieldAssets, satelliteImages]) => {
      return {
        satelliteImages,
        ...fieldAssets.data.getFarms[0].fields[0],
      };
    });
};

export const exportData = async ({
  filePostfix,
  vectorAnalysisMaps,
  fieldBoundaries,
  notes,
  equationMaps,
  asMultiGeometry,
  onlyProductColumns,
  archiveName,
}) => {
  return API.graphql(graphqlOperation(exportDataMutation, {
    input: {
      filePostfix,
      vectorAnalysisMaps,
      fieldBoundaries,
      notes,
      equationMaps,
      asMultiGeometry,
      onlyProductColumns,
      archiveName,
    },
  }))
    .then(({ data: { exportData: { archiveUrl } } }) => {
      return archiveUrl;
    });
};

export const exportIsoXmlData = async ({
  applicationMaps,
  equationMaps,
  filePostfix,
  archiveName,
}) => {
  return API.graphql(graphqlOperation(exportIsoXmlMutation, {
    input: {
      applicationMaps,
      equationMaps,
      filePostfix,
      archiveName,
    },
  }))
    .then(({ data: { exportIsoXml: { archiveUrl } } }) => {
      return archiveUrl;
    });
};

import React from 'react';
import clsx from 'clsx';

import './index.scss';

const ToolsPanel = ({
  classes = {},
  actionsLeft,
  actionsRight,
  filtersLeft,
  filtersRight,
  'data-test': dataTest,
}) => {
  const classRoot = classes.root || '';
  const classActions = classes.actions || '';
  const classActionsLeft = classes.actionsLeft || '';
  const classActionsRight = classes.actionsRight || '';
  const classFilters = classes.filters || '';
  const classFiltersLeft = classes.filtersLeft || '';
  const classFiltersRight = classes.filtersRight || '';

  return (
    <div
      className={classRoot}
      data-test={dataTest}
    >
      <div className={`tools-panel__actions ${classActions}`}>
        <div className={`tools-panel__actions_left ${classActionsLeft}`}>
          {actionsLeft}
        </div>
        <div className={`tools-panel__actions_right ${classActionsRight}`}>
          {actionsRight}
        </div>
      </div>
      <div className={clsx('tools-panel__filters', classFilters, {
        'tools-panel__filters_empty': !filtersLeft && !filtersRight,
      })}
      >
        <div className={`tools-panel__filters_left ${classFiltersLeft}`}>
          {filtersLeft}
        </div>
        <div className={`tools-panel__filters_right ${classFiltersRight}`}>
          {filtersRight}
        </div>
      </div>
    </div>
  );
};

export default ToolsPanel;

import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import { useAppSelector } from '../../../../../../app/store/helpers/functions';
import { getThumbnailUrl } from '../../../../../../helpers/functions/utils/url';
import { selectFieldEntity } from '../../../../../assets/assetsSelectors';
import { selectApiKey } from '../../../../../user/userSelectors';
import { DEFAULT_GRID_SIZE } from '../../../helpers/constants/equationMap';
import useVerifyEquationMapData from '../../../hooks/useVerifyEquationMapData';
import { TILE_MAP_SIZE } from '../../../helpers/constants/ui';
import { selectEquationMapData } from '../../../createBatchEquationBasedAnalysisSelectors';
import { LoadStatus } from '../../../../../../helpers/constants/utils/loadStatus';
import { getFieldThumbnailGeoMap } from '../../../../../field/helpers/functions/field';

import './index.scss';

export default function PreviewTileFieldView({
  fieldUuid,
}: {
  fieldUuid: string,
}) {
  const { t } = useTranslation();

  const apiKey = useAppSelector(selectApiKey);
  const fieldEntity = useAppSelector((state) => selectFieldEntity(state, fieldUuid));
  const equationMapData = useAppSelector((state) => selectEquationMapData(state, fieldUuid));
  const { hasMissingVariable } = useVerifyEquationMapData({ fieldUuid });

  const preview = useMemo(() => {
    const thumbnail = getFieldThumbnailGeoMap(fieldEntity?.geoMaps);

    return getThumbnailUrl({ thumbnail, size: TILE_MAP_SIZE, apiKey });
  }, [fieldEntity?.geoMaps, apiKey]);

  let instructions: ReactElement;

  if (equationMapData?.generationStatus === LoadStatus.error) {
    instructions = (
      <>
        <Typography className="preview-tile-field-view__instructions_error">
          {t('batch-equation-based.steps.preview.field-view.error.title')}
        </Typography>
        <Typography className="preview-tile-field-view__instructions_sub">
          {t('batch-equation-based.steps.preview.field-view.error.message')}
        </Typography>
      </>
    );
  } else if (hasMissingVariable) {
    instructions = (
      <>
        <Typography className="preview-tile-field-view__instructions_error">
          {t('batch-equation-based.steps.preview.preview-tile.dataset-not-defined')}
        </Typography>
        <Typography className="preview-tile-field-view__instructions_sub">
          {t('batch-equation-based.steps.preview.field-view.adjust-instructions')}
        </Typography>
      </>
    );
  } else {
    instructions = (
      <>
        <Typography>
          {t('batch-equation-based.steps.preview.field-view.run-instructions')}
        </Typography>
        <Typography className="preview-tile-field-view__instructions_sub">
          {t(
            'batch-equation-based.steps.preview.field-view.default-grid-size',
            { size: DEFAULT_GRID_SIZE },
          )}
        </Typography>
      </>
    );
  }

  return (
    <div className="preview-tile-field-view">
      <img
        className="preview-tile-field-view__map"
        src={preview}
        alt={fieldEntity?.name}
      />
      <div className="preview-tile-field-view__instructions">
        {instructions}
      </div>
    </div>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import './index.scss';

export default function PreviewTileLoaderView() {
  const { t } = useTranslation();

  return (
    <div className="preview-tile-loader-view">
      <CircularProgress size={92} thickness={2} />
      <Typography className="preview-tile-loader-view__text text_secondary">
        {t('batch-equation-based.steps.preview.loader-view.loader')}
      </Typography>
    </div>
  );
}

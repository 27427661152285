import { createSelector } from '@reduxjs/toolkit';

import { isField, isPinsGroup, isVectorAnalysis } from '../../helpers/functions/entities/assets';
import { exportDataAdapter } from './exportDataAdapter';

const farmsSelectors = exportDataAdapter.getSelectors(({ exportData }) => {
  return exportData.farms;
});

const fieldsSelectors = exportDataAdapter.getSelectors(({ exportData }) => {
  return exportData.fields;
});

const vectorAnalysisMapsSelectors = exportDataAdapter.getSelectors(({ exportData }) => {
  return exportData.vectorAnalysisMaps;
});

const pinsGroupsSelectors = exportDataAdapter.getSelectors(({ exportData }) => {
  return exportData.pinsGroups;
});

export const selectAllFarms = farmsSelectors.selectAll;
export const selectFarmsEntities = farmsSelectors.selectEntities;
export const selectFarm = farmsSelectors.selectById;

export const selectAllFields = fieldsSelectors.selectAll;
export const selectFieldsEntities = fieldsSelectors.selectEntities;
export const selectField = fieldsSelectors.selectById;

export const selectAllVectorAnalysisMaps = vectorAnalysisMapsSelectors.selectAll;
export const selectVectorAnalysisMapsEntities = vectorAnalysisMapsSelectors.selectEntities;

export const selectAllPinsGroups = pinsGroupsSelectors.selectAll;
export const selectPinsGroupsEntities = pinsGroupsSelectors.selectEntities;

export const selectFieldsToFarmUuids = createSelector(
  selectAllFields,
  (allFields) => {
    return allFields.reduce((acc, field) => {
      if (!acc[field.farmUuid]) {
        acc[field.farmUuid] = [];
      }

      acc[field.farmUuid].push(field);

      return acc;
    }, {});
  },
);

export const selectVamapsToFieldUuids = createSelector(
  selectAllVectorAnalysisMaps,
  (allVamaps) => {
    return allVamaps.reduce((acc, vamap) => {
      if (!acc[vamap.fieldUuid]) {
        acc[vamap.fieldUuid] = [];
      }

      acc[vamap.fieldUuid].push(vamap);

      return acc;
    }, {});
  },
);

export const selectPinsGroupsToFieldUuids = createSelector(
  selectAllPinsGroups,
  (allPinsGroups) => {
    return allPinsGroups.reduce((acc, pinsGroup) => {
      if (!acc[pinsGroup.fieldUuid]) {
        acc[pinsGroup.fieldUuid] = [];
      }

      acc[pinsGroup.fieldUuid].push(pinsGroup);

      return acc;
    }, {});
  },
);

export const selectAsset = createSelector(
  selectFieldsEntities,
  selectVectorAnalysisMapsEntities,
  selectPinsGroupsEntities,
  (_, uuid, type) => ({ uuid, type }),
  (fields, vectorAnalysisMaps, pinsGroups, { uuid, type }) => {
    if (!type || !uuid) {
      return null;
    }

    let result;
    const typedEntity = { _type: type };

    if (isField(typedEntity)) {
      result = fields[uuid];
    } else if (isVectorAnalysis(typedEntity)) {
      result = vectorAnalysisMaps[uuid];
    } else if (isPinsGroup(typedEntity)) {
      result = pinsGroups[uuid];
    }

    return result;
  },
);

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchVamap } from '../zonesMapSlice';
import {
  selectVectorAnalysisMap,
  selectAllLoading,
} from '../../../field/fieldSelectors';

export default function useZonesMap(farmUuid, fieldUuid, uuid) {
  const dispatch = useDispatch();
  const zonesMap = useSelector((state) => selectVectorAnalysisMap(state, uuid));
  const allAssetsLoading = useSelector(selectAllLoading);

  useEffect(() => {
    if (!allAssetsLoading) {
      dispatch(fetchVamap({
        farmUuid,
        fieldUuid,
        uuid,
      }));
    }
  }, [farmUuid, fieldUuid, uuid, allAssetsLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return zonesMap;
}

export enum AbsoluteUnit {
  kg = 'KG',
  lb = 'LB',
  l = 'L',
  gal = 'GAL',
  seeds = 'SEEDS',
  bu = 'BU',
  t = 'T',
  eur = 'EUR',
  usd = 'USD',
}

export const ABSOLUTE_UNIT_TO_I18N_LABEL = {
  [AbsoluteUnit.kg]: 'general.controls.absolute-units.kg',
  [AbsoluteUnit.lb]: 'general.controls.absolute-units.lb',
  [AbsoluteUnit.l]: 'general.controls.absolute-units.l',
  [AbsoluteUnit.gal]: 'general.controls.absolute-units.gal',
  [AbsoluteUnit.seeds]: 'general.controls.absolute-units.seeds',
  [AbsoluteUnit.bu]: 'general.controls.absolute-units.bu',
  [AbsoluteUnit.t]: 'general.controls.absolute-units.t',
  [AbsoluteUnit.eur]: 'general.controls.absolute-units.eur',
  [AbsoluteUnit.usd]: 'general.controls.absolute-units.usd',
};

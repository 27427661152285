import i18n from 'i18next';

import { getCommonSteps } from '../../../zonesOps/helpers/functions/steps';
import Draw from '../../containers/Stepper/Steps/Draw';

export const getSteps = (withFieldSelection) => {
  let firstSteps = [];

  if (withFieldSelection) {
    firstSteps = getCommonSteps();
  }

  return [
    ...firstSteps,
    {
      name: i18n.t('zones-ops.draw-manually.stepper.draw'),
      id: 'draw',
      component: Draw,
    },
  ];
};
